
export const getItCommitteeLanding = () => {
    return defaultData;
};

const defaultData = [
    {
        id: 0,
        img: "https://api.rotary.org.ua/uploads/clubsitecreator/it_committe_page/home/1704904326471-346306272.jpg",
        header: 'ІТ Комітет:',
        content: [
            {LandingMessage: `Ми не просто команда "комп'ютерних гурманів". Ми – місток між традиціями Ротарі та новим світом технологій. І ми тут, щоб цей перехід був легким і корисним для всіх!`},
            
            {LandingMessage: `У ці часи цифрової трансформації, ми прагнемо не лише йти в ногу з часом, а й випереджати його, використовуючи інноваційні технології для підтримки наших добрих справ.`},
        ],
    },
    {
        id: 1,
        img: "https://kakoy-smysl.ru/wp-content/uploads/2021/08/115874201.jpg",
        header: 'Місія:',
        content: [
            {LandingMessage: `Об'єднуємо фахівців у галузі ІТ з метою підвищення технологічної обізнаності та допомоги ротарійській спільноті. Через інновації та навчання ми прагнемо створити основу для ефективної реалізації соціально важливих проектів.`},

            {LandingList: [
                {"LandingListItem": "Підтримка Діяльності: Працювати рука об руку з ротарійцями, клубами та Дистриктом для поліпшення їхньої діяльності.", url: ""},
                {"LandingListItem": "Ефективність та Інновації: Впровадження передових цифрових інструментів для оптимізації нашої роботи.", url: ""},
                {"LandingListItem": "Співпраця без Конкуренції: Наша мета - сприяти взаємодії, а не конкурувати з іншими комітетами.", url: ""},
            ]},
        ],
    },
    {
        id: 2,
        header: 'Візія:',
        content: [
            {LandingMessage: `Бути лідерами в технологічному розвитку ротарійської спільноти, інтегруючи сучасні ІТ-рішення у щоденну діяльність ротарі. `},
            {LandingMessage: `Ми прагнемо забезпечити, щоб кожен ротарієць володів необхідними ІТ-навичками для реалізації своїх ініціатив та зміцнення взаємодії в громадах.`},
        ],
    },
    {
        id: 3,
        header: 'Наші задачі та цілі:',
        img: "https://bizmag.com.ua/wp-content/uploads/2021/03/startup-idea-730x427.jpg",
        content: [
            {LandingMessage: "Ми прагнемо до того, щоб технології служили на благо ротарійської спільноти, допомагали реалізовувати ідеї та приносили користь суспільству. Кожна наша задача та ціль націлена на створення сильної, об'єднаної та технологічно розвиненої ротарійської сім'ї!"},
            {LandingList: [
                {"LandingListItem": "Об'єднання однодумців: Створення команди з ротарійських айтівців, яка стане платформою для обміну досвідом та реалізації спільних проектів.", url: ""},
                {"LandingListItem": "Допомога в реалізації: Надання підтримки та ресурсів для ротарійців, яким потрібна допомога у ІТ-задачах або проектах.", url: ""},
                {"LandingListItem": "Освітні ініціативи: Проведення навчальних зумів, майстер-класів та воркшопів для покращення комп'ютерної грамотності серед ротарійців.", url: ""},
                {"LandingListItem": "Робота з молоддю: Співпраця з Інтерактом для втілення ІТ проектів, а також для заохочення молодого покоління до технологічних викликів майбутнього.", url: ""},
                {"LandingListItem": "Оновлення веб-ресурсів: Перетворення сайту Дистрикту у сучасний, зручний та інформативний ресурс для всіх членів Ротарі.", url: ""},
                {"LandingListItem": "Підтримка стартапів: Пошук та підтримка ІТ стартапів, які можуть принести користь громадам та ротарійській спільноті.", url: ""},
            ]},
        ]
    },
    {
        id: 3,
        header: 'Наша команда:  ',
        arrcontent: [
            {
                content: [
                    {LandingMessage: "У Діловій раді є правління, яке визначає стратегії та ключові напрямки розвитку її діяльності, здійснює нагляд за дотриманням принципів та кодексу етики, відбір кандидатів та затвердження нових членів, представлення інтересів спільноти у відносинах з іншими організаціями, ініціювання і розвиток нових проектів та програм Ради."},
                ],
            },
            {
                id: 4,
                cards: [
                    {
                        img: 'https://res.cloudinary.com/tyko/image/upload/v1689000839/AvatarMaker/snfy3wdgjtl3dy3llcdi.jpg',
                        content: [
                            {LandingMessage: "- голова ІТ Комітету"},
                            {LandingMessage: "- член Комітету Публічного Іміджу"},
                            {LandingMessage: "- член Координаційного Комітету"},
                        ],
                        name: "Олег Косьмина",
                        phone: "+38099-6332351",
                        email: "o.kosmyna.rotary@gmail.com",
                    },
                    {
                        rotarianId: 1,
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/it_committe_page/photo/1704910578422-222626339.jpg',
                        content: [
                            {LandingMessage: `- Напрямок в ІТ Комітеті: "Public Relations`},
                            {LandingMessage: "- Голова Комітету Публічного Іміджу"},
                        ],
                        name: "Лариса Коба",
                        phone: "+380 50 862 99 98",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/it_committe_page/photo/1704910578427-260766418.jpg',
                        content: [
                            {LandingMessage: `- Напрямок в ІТ Комітеті: "Впровадження інноваційних технологій`},
                            {LandingMessage: "- Національний Координатор Міждержавних комітетів в Україні 2021-2024"},
                        ],
                        name: "Віра Сиряміна",
                        phone: "+380677466521",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/it_committe_page/photo/1704905480300-221990141.jpg',
                        content: [
                            {LandingMessage: `- Напрямок в ІТ Комітеті: "Візуальне оформлення інформаційних документів`},
                            {LandingMessage: "- Ротаракт Клубу Львів"},
                        ],
                        name: "Олена Лукачук",
                        phone: "+380976686936",
                        email: "olena0708lukachuk@gmail.com",
                    },
                    {
                        img: 'https://res.cloudinary.com/tyko/image/upload/v1689011979/AvatarMaker/uxijntjvqeihjpxppgve.jpg',
                        content: [
                            {LandingMessage: `- Напрямок в ІТ Комітеті: "Підтримка ІТ спільноти в Форум форматі"`},
                        ],
                        name: "Євген Лоза",
                        phone: "+380 96 565 72 22",
                        email: "",
                    },
                    
                ],
            },
        ],
    },
    {
        id: 4,
        header: 'Нам потрібні:',
        img: "https://api.rotary.org.ua/uploads/clubsitecreator/it_committe_page/home/1704904326475-85359829.png",
        content: [
            {LandingMessage: "Для охоплення ключових ІТ напрямків:"},
            {LandingList: [
                {"LandingListItem": "Розробка та впровадження цифрових інструментів", url: ""},
                {"LandingListItem": "PR напрямок", url: ""},
                {"LandingListItem": "Дизайн напрямок", url: ""},
                {"LandingListItem": "Навчальні та медійні ресурси", url: ""},
                {"LandingListItem": "Напрямок Безпеки в цифровому середовищі ", url: ""},
                {"LandingListItem": "Командоутворення в комітеті", url: ""},
                {"LandingListItem": "Взаємодія з Ротарактом", url: ""},
                {"LandingListItem": "Взаємодія з Інтерактом", url: ""},
                {"LandingListItem": "Команда експертів по доцільності впровадження комерційного Програмного Забезпечення", url: ""},
                {"LandingListItem": "Напрямок Штучного Інтелекту", url: ""},
                {"LandingListItem": "Робота з фінансами та контрактами", url: ""},
                {"LandingListItem": "Адміністрування Соцмереж, месенджерів, чат-ботів, форумів", url: ""},
                {"LandingListItem": "Напрямок Впровадження інноваційних технологій", url: ""},
                {"LandingListItem": "Візуальне  оформлення інформаційного матеріалу", url: ""},
            ]},
            
            {LandingMessage: "Запрошуємо:"},
            {LandingList: [
                {"LandingListItem": "Веб дизайнери", url: ""},
                {"LandingListItem": "Контентмейкери", url: ""},
                {"LandingListItem": "Веброзробники", url: ""},
                {"LandingListItem": "Менеджери проектів", url: ""},
                {"LandingListItem": "Тестувальники", url: ""},
                {"LandingListItem": "ШІ спеціалісти", url: ""},
                {"LandingListItem": "та інші...", url: ""},
            ]},
            {LandingButtonLink: "Стати членом комітету", link: "https://docs.google.com/forms/d/e/1FAIpQLScctueVCjTwQv2YD9lZa6gXzqs1t836F03x0KRZF9r1R4HTuA/viewform"},
            {BR: "true"},
            {BR: "true"},
            {BR: "true"},
            {LandingMessage: "Також запрошуємо в ІТ спільноту всіх бажучих, хто цікавиться ІТ, або має потребу в ІТ рішеннях:"},
            {LandingButtonLink: "Доєднатися до Telegram", link: "https://t.me/+GRj38AFYUH9kZjhi"},
        ]
    },
]
