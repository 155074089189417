

import axios from 'axios'; 

const BASE_URL = 'https://api.rotary.org.ua/apibd';

export const sendlog = async (logData) => {
    console.log('=== create log...');
    try {
        axios.post(`${BASE_URL}/logging`, logData);
    } catch (error) {
        throw error;
    }
};

export const getalllogs = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/getlogs`);
        return response.data;
    } catch (error) {
        throw error;
    }
};