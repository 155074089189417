import * as React from 'react';
import { Container, Box, Typography, Avatar, Tab, Tabs, Paper } from '@mui/material';
import { List, ListItem, ListItemText, Divider } from '@mui/material';

import { blue, yellow } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import MyMainPage from "../../modules/MYMain";

const MemberProfilePage = () => {
    const [tabValue, setTabValue] = React.useState(0);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));


    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <MyMainPage>
            <Container sx={{ my: 4 }}>
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'space-between', 
                    border: 1, 
                    borderColor: blue[500], 
                    bgcolor: yellow[100], 
                    borderRadius: 2, 
                    p: 2 
                }}>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>Сорінка ротарійця</Typography>
                    <IconButton aria-label="edit" size="small" sx={{ ml: 'auto' }}>
                        <EditIcon />
                    </IconButton>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: matches ? 'column' : 'row',
                        alignItems: matches ? 'center' : 'flex-start',
                        border: 1,
                        borderColor: blue[500],
                        bgcolor: 'white', 
                        p: 2,
                        my: 2
                    }}
                    >
                    <Avatar
                        src="https://res.cloudinary.com/tyko/image/upload/v1697711415/AvatarMaker/acpe3dceqin9syuctrzw.jpg"
                        sx={{ bgcolor: blue[500], width: 300, height: 300 }}
                        alt="Profile Avatar"
                    />
                    <Box sx={{ ml: matches ? 0 : 2, mt: matches ? 2 : 0, width: matches ? '100%' : 'auto' }}>
                        <Typography>Email: example@email.com</Typography>
                        <Typography>Phone: +123456789</Typography>
                        <Typography>Facebook: fb.com/example</Typography>
                        <Typography>LinkedIn: linkedin.com/in/example</Typography>
                    </Box>
                </Box>

                <Paper square>
                    <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                        <Tab label="Rotary Information" />
                        <Tab label="Business Information 1" />
                        <Tab label="Business Information 2" />
                        <Tab label="Interests & Hobbies" />
                    </Tabs>
                </Paper>

                <Box sx={{ border: 1, borderColor: blue[500], p: 2, my: 2 }}>
                    {tabValue === 0 && 
                    
                    
                    <Box sx={{ border: 1, borderColor: 'primary.main', p: 2, bgcolor: 'background.paper' }}>
                    <Typography variant="h6" component="div" sx={{ mb: 2 }}>
                        Rotary Information
                    </Typography>
                    <List>
                        <ListItem>
                        <ListItemText primary="Ротарі Клуб" secondary="Запоріжжя-Хортиця" />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                        <ListItemText primary="Членство" secondary="Чартерний член з 2021 року" />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                        <ListItemText primary="Ролі в клубі" secondary="2021-2023рр - голова ПР комітету" />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                        <ListItemText primary="Інформація дистриктна" secondary="22-23 роки - офіцер клубу по збору та систематизації інформації" />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                        <ListItemText primary="" secondary="22-23 роки - член PR комітету" />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                        <ListItemText primary="" secondary="22-23 роки - координатор проекту Зима" />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                        <ListItemText primary="Ролі в наступному році" secondary="23-24 роки - голова ІТ комітету" />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                        <ListItemText primary="" secondary="23-24 роки - член PR комітету" />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                        <ListItemText primary="" secondary="23-24 роки - член КК комітету" />
                        </ListItem>
                    </List>
                    </Box>
                    
                    }
                    {tabValue === 1 && 
                    
                    <Box sx={{ border: 1, borderColor: 'primary.main', p: 2, bgcolor: 'background.paper', my: 2 }}>
                    <Typography variant="h6" component="div" sx={{ mb: 2 }}>
                        Бізнесова Інформація 1
                    </Typography>
                    <List>
                        <ListItem>
                        <ListItemText primary="Категорія" secondary="ІТ, програмування" />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                        <ListItemText primary="2005-2008" secondary="Розробка ПО в ЧП" />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                        <ListItemText primary="2008-2015" secondary="Інженер-програміст на С++ в Livestream" />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                        <ListItemText primary="2015-2016" secondary="Інженер-програміст на React в Vimeo" />
                        </ListItem>
                    </List>
                    </Box>
                    
                    }
                    {tabValue === 2 && 
                    
                    <Box sx={{ border: 1, borderColor: 'primary.main', p: 2, bgcolor: 'background.paper', my: 2 }}>
                    <Typography variant="h6" component="div" sx={{ mb: 2 }}>
                        Бізнесова Інформація 2
                    </Typography>
                    <List>
                        <ListItem>
                        <ListItemText primary="Категорія" secondary="Підприємництво, стартапи" />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                        <ListItemText primary="2016-2019" secondary="Навчання підприємництву" />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                        <ListItemText primary="2016-2018" secondary="Стартап TYKO:360Studio" />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                        <ListItemText primary="2017-2019" secondary="Стартап TYKO:DevStudio" />
                        </ListItem>
                    </List>
                    </Box>
                    
                    }
                    {tabValue === 3 && <Typography>Interests & Hobbies Content</Typography>}
                </Box>
            </Container>
        </MyMainPage>
    );
}

export default MemberProfilePage;
