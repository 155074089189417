import React, { useState } from 'react';
  
const LandingYoutubeFrame = ({ title, linkid }) => {
  
    return (
        <div style={{width: '100%', minHeight: '350px'}}>
            <iframe
                title="YouTube Video Player"
                width="100%"
                height="350px"
                src={`https://www.youtube.com/embed/${linkid}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default LandingYoutubeFrame;
