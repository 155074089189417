import React, {useState} from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const SERVERURL = 'https://apifs.rotaryd2232ukraine.org/storage';

const Login = () => {
    const [token, setToken] = useState(null);
  
    const formik = useFormik({
      initialValues: {
        username: '',
        password: ''
      },
      validationSchema: Yup.object({
        username: Yup.string().required('Required'),
        password: Yup.string().required('Required')
      }),
      onSubmit: async (values) => {
        try {
          const response = await axios.post(`${SERVERURL}/login`, values);
          
          // Отримуємо токен з response.data.callback.token
          const callback = response.data;
  
          // Зберігаємо токен в localStorage та в state
          localStorage.setItem('tokenFS', callback.token);
          setToken(callback.token);
  
          console.log('=== Login successful!');
          console.log(`token = ${JSON.stringify(callback)}`);
        } catch (error) {
          console.log('=== Login failed! ' + JSON.stringify(error));
        }
      }
    });

  return (
    <form onSubmit={formik.handleSubmit}>
        
      <div>
        <label>Username</label>
        <input
          type="text"
          name="username"
          onChange={formik.handleChange}
          value={formik.values.username}
        />
        {formik.errors.username ? <div>{formik.errors.username}</div> : null}
      </div>
      <div>
        <label>Password</label>
        <input
          type="password"
          name="password"
          onChange={formik.handleChange}
          value={formik.values.password}
        />
        {formik.errors.password ? <div>{formik.errors.password}</div> : null}
      </div>
      <button type="submit">Login</button>
    </form>
  );
};

export default Login;
