import React, { useState } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';

//const API_SERVER = 'https://api.rotary.org.ua/ai/trang';
const API_SERVER = 'http://localhost:3001/ai/trang';

export const translateToEng = async (text) => {
    //console.log('====== translateToEng text=', text)
    const targetLanguage = 'anglish';
    try {
      const response = await fetch(API_SERVER, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text, targetLanguage }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data.translatedText;
    } catch (error) {
      console.error('Error translating text:', error);
    }
  };

function TranslateComponent() {
  const [text, setText] = useState('');
  const [targetLanguage, setTargetLanguage] = useState('');
  const [translatedText, setTranslatedText] = useState('');

  const handleTranslate = async() => {
      const data = await translateToEng(text);
      setTranslatedText(data);
  };

  return (
    <Box sx={{ '& > :not(style)': { m: 1 } }}>
      <TextField
        label="Текст для перекладу"
        variant="outlined"
        fullWidth
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <FormControl fullWidth>
        <InputLabel id="target-language-label">Мова перекладу</InputLabel>
        <Select
          labelId="target-language-label"
          value={targetLanguage}
          label="Мова перекладу"
          onChange={(e) => setTargetLanguage(e.target.value)}
        >
          <MenuItem value="english">Англійська</MenuItem>
          {/* Додайте інші мови за необхідності */}
        </Select>
      </FormControl>
      <Button variant="contained" onClick={handleTranslate}>Перекласти</Button>
      {translatedText && (
        <TextField
          label="Перекладений текст"
          variant="outlined"
          fullWidth
          value={translatedText}
          InputProps={{
            readOnly: true,
          }}
        />
      )}
    </Box>
  );
}

export default TranslateComponent;
