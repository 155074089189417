import axios from 'axios';

//const API_URL = 'http://localhost:3001/openai';
const API_URL = 'https://apitools.rotaryd2232ukraine.org/openai';

// Функція для перекладу з української на англійську
export const translateToEnglish = async (text) => {
  try {
    const response = await axios.post(`${API_URL}/trtoeng`, { text });
    return response.data.translatedText;
  } catch (error) {
    console.error('Error translating to English:', error);
    throw error;
  }
};

// Функція для перекладу з англійської на українську
export const translateToUkrainian = async (text) => {
  try {
    const response = await axios.post(`${API_URL}/tr2ukr`, { text });
    return response.data.translatedText;
  } catch (error) {
    console.error('Error translating to Ukrainian:', error);
    throw error;
  }
};
