
import DocumentsFrame from './DocumentsFrame';
import WordTypePage_TempShare from '../../components/WordTypePage_TempShare';

const DocumentsPage_TempShare = () => {
    return (
        <WordTypePage_TempShare>
            <DocumentsFrame />
        </WordTypePage_TempShare>
  );
}

export default DocumentsPage_TempShare;