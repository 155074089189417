import React, { useEffect, useState } from "react";
import { Box, Container, Typography, Button, Tooltip, Paper, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useMediaQuery, useTheme } from '@mui/material';

import { getAllCommuniques } from '../../api/ts/documents_communique';

const DocumentsFrame = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  const [loading, setLoading] = useState(true);
  const [communiques, setCommuniques] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllCommuniques();
        console.log("Fetched data:", data);
        setCommuniques(data.reverse());
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const toFileClick = (url) => {
    window.open(url, '_blank');
  }

  const getRotaryYear = (data) => {
    if (!data) {
      console.log("Processing data: null or undefined, assigning to 23-24");
      return { rotaryYear: '23-24', special: true };
    }
    const [day, month, year] = data.split('.').map(Number);
    console.log("Parsed data - day:", day, "month:", month, "year:", year);
    if (year && month && day) {
      if (month > 6 || (month === 7 && day >= 1)) {
        const rotaryYear = `${year}-${(year + 1).toString().slice(-2)}`;
        console.log("Rotary year:", rotaryYear);
        return { rotaryYear, special: false };
      } else {
        const rotaryYear = `${year - 1}-${year.toString().slice(-2)}`;
        console.log("Rotary year:", rotaryYear);
        return { rotaryYear, special: false };
      }
    } else {
      console.log("Invalid date format, assigning to 23-24");
      return { rotaryYear: '23-24', special: true };
    }
  }

  const groupedByYear = communiques.reduce((acc, curr) => {
    console.log("Processing comminique:", curr);
    const { rotaryYear, special } = getRotaryYear(curr.data);
    if (!acc[rotaryYear]) acc[rotaryYear] = [];
    acc[rotaryYear].push({ ...curr, special });
    return acc;
  }, {});

  // Об'єднання подій з null датами з подіями для року "23-24"
  if (groupedByYear['23-24']) {
    groupedByYear['23-24'] = groupedByYear['23-24'].filter(event => !event.special).concat(groupedByYear['23-24'].filter(event => event.special));
  }

  console.log("Grouped by year:", groupedByYear);

  // Конкатенація подій з null датами в події для "23-24"
  if (groupedByYear['23-24'] && groupedByYear['23-24'].some(event => event.special)) {
    groupedByYear['23-24'] = groupedByYear['23-24'].concat(groupedByYear['23-24'].filter(event => event.special));
    delete groupedByYear.null;
  }

  return (
    <Container>
      {loading && <div>Loading...</div>}
      {Object.keys(groupedByYear).map((year) => (
        <Paper key={year} style={{ marginBottom: '24px', padding: '12px', paddingLeft: isSmallScreen ? '12px' : '96px', paddingRight: isSmallScreen ? '8px' : '24px' }}>
          <Typography variant="h3" className="page-header" align="center" style={{ color: 'darkblue', paddingBottom: '16px' }}>
            Комюніке {year}
          </Typography>
          <List component="nav">
            {groupedByYear[year].map((row, index) => (
              <React.Fragment key={index}>
                {/* <Typography variant="h6" style={{ paddingLeft: '12px' }}>
                  {row.special ? 'special' : `Місяць: ${row.data.split('.')[1]}, Рік: ${row.data.split('.')[2]}`}
                </Typography> */}
                <ListItem button onClick={() => toFileClick(row.url)} style={{ paddingTop: '4px', paddingBottom: '4px', borderTop: isSmallScreen ? '1px solid #ccc' : 'none' }}>
                  <ListItemText>
                    <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="top" color="darkblue">
                      <Box display="flex" alignItems="top">
                        <ListItemIcon>
                          <PictureAsPdfIcon color="primary" fontSize="large" style={{ marginRight: '0px', marginLeft: '10px' }} />
                        </ListItemIcon>
                        <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Комюніке №{row.number}</span>
                      </Box>
                      <span style={{ paddingLeft: '12px' }}>{row.title}</span>
                    </Box>
                  </ListItemText>
                </ListItem>
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="flex-start" style={{ paddingLeft: '64px' }}>
                  {row.urls && row.urls.map((r, i) => (
                    <Tooltip key={r.url} title={r.title} arrow>
                      <Box display="flex" alignItems="flex-start" justifyContent="flex-start" paddingBottom='8px' marginTop='-8px'>
                        <Button style={{ textTransform: 'none' }} onClick={() => toFileClick(r.url)}>
                          <PictureAsPdfIcon color="primary" fontSize="small" style={{ marginRight: '4px' }} />
                          <Typography variant="subtitle2">
                            {r.title.length > (matchesMd ? 15 : matchesSm ? 40 : r.title.length)
                              ? `${r.title.slice(0, (matchesMd ? 15 : matchesSm ? 40 : r.title.length))}...`
                              : r.title}
                          </Typography>
                        </Button>
                      </Box>
                    </Tooltip>
                  ))}
                </Box>
              </React.Fragment>
            ))}
          </List>
        </Paper>
      ))}
    </Container>
  );
}

export default DocumentsFrame;
