
export const getInteractCommitteeLanding = () => {
    return defaultData;
};

const defaultData = [
    {
        id: 0,
        img: "https://api.rotary.org.ua/uploads/clubsitecreator/interact_committe_page/home/1704973284977-385789007.png",
        header: 'Про Комітет з питань Інтеракту:',
        content: [
            {LandingMessage: `Комітет з питань Інтеракту в Дистрикті Ротарі – це ключова структура, призначена для підтримки, координації та розвитку Інтеракт клубів у рамках Дистрикту. Ми прагнемо до того, щоб кожен клуб був осередком енергії, інновацій та лідерства для молоді. Наша робота зосереджена на забезпеченні молодих лідерів ресурсами, навчальними можливостями та платформами для обміну досвідом. Ми віримо, що сильні Інтеракт клуби – це основа для розвитку сильних громад, і тому наша робота є надзвичайно важливою для майбутнього нашої країни.`},
        ],
    },
    {
        id: 1,
        header: 'Місія:',
        content: [
            {LandingMessage: `Місія Комітету полягає у вихованні нового покоління лідерів, здатних служити своїй громаді та вносити позитивні зміни в світ. Ми прагнемо виховати молодь, здатну керувати змінами, бути емпатичною, відповідальною та активною. Наша мета – розвивати у молодих людей необхідні навички, такі як критичне мислення, комунікаційні здібності, лідерство та командна робота, які допоможуть їм стати впливовими членами суспільства.`},

        ],
    },
    {
        id: 2,
        header: 'Візія:',
        content: [
            {LandingMessage: `Наша візія – створити сильне та динамічне співтовариство молодих лідерів, які будуть діяти як каталізатори позитивних змін у своїх громадах та в усьому світі. Ми бачимо Інтеракт не просто як молодіжний рух, а як платформу для розвитку відповідальних громадян, які будуть формувати майбутнє нашої країни і світу. Наша мета – створення мережі активних, освічених та мотивованих молодих людей, які будуть працювати разом для досягнення сталого розвитку та миру.`},
        ],
    },
    {
        id: 3,
        header: 'Наші задачі та цілі:',
        content: [
            
            {LandingList: [
                {"LandingListItem": "Підтримка Існуючих Інтеракт Клубів: Надання допомоги та підтримки існуючим клубам для їх розвитку та зміцнення. Це включає навчання, ресурси та методологічну підтримку.", url: ""},
                {"LandingListItem": "Створення Нових Клубів: Активізація роботи з відкриття нових Інтеракт клубів, особливо у регіонах, де їхня присутність є обмеженою.", url: ""},
                {"LandingListItem": "Освітні та Соціальні Проекти: Розробка та реалізація різноманітних освітніх та соціальних проектів для молоді.", url: ""},
                {"LandingListItem": "Співпраця з Іншими Структурами Ротарі: Встановлення міцних відносин і співпраці з іншими структурами Ротарі, включаючи Ротаракт та Ротарі клуби.", url: ""},
                {"LandingListItem": "Популяризація Ідеалів Ротарі серед Молоді: Поширення знань про цінності та ідеали Ротарі серед молоді та залучення їх до активної участі у волонтерській діяльності.", url: ""},
            ]},
        ]
    },
    {
        id: 31,
        img: "https://api.rotary.org.ua/uploads/clubsitecreator/interact_committe_page/home/1704973284991-674861090.png",
        header: 'Співпраця з Громадами:',
        content: [
            {LandingMessage: `Комітет з питань Інтеракту Ротарі активно взаємодіє з громадами, освітніми закладами та некомерційними організаціями, щоб створити ефективні та впливові проекти. Ми віримо, що активна участь молоді у вирішенні місцевих проблем не лише сприяє їх особистому розвитку, але й має позитивний вплив на суспільство. Наша робота включає ініціювання спільних проектів, які адресують місцеві потреби, такі як покращення освітньої інфраструктури, заходи з охорони навколишнього середовища, та соціальні кампанії. Ця співпраця дає молоді чудову можливість навчитися практичних навичок управління проектами, розвитку спільноти та лідерства.`},
        ],
    },
    {
        id: 32,
        header: 'Розвиток Лідерських Здібностей:',
        img: "https://api.rotary.org.ua/uploads/clubsitecreator/interact_committe_page/home/1704973284987-185003151.png",
        content: [
            {LandingMessage: `Розвиток лідерських здібностей є одним з ключових пріоритетів Комітету. Ми розуміємо, що майбутнє наших громад та суспільства в цілому залежить від підготовки відповідальних і здатних до керівництва молодих людей. Основою нашої програми є організація різноманітних освітніх заходів, включаючи тренінги з лідерства, семінари з підвищення комунікаційних навичок, майстер-класи від успішних професіоналів різних сфер. Ці заходи спрямовані на розвиток критичного мислення, вирішення проблем, та ефективної командної роботи серед молоді.`},
        ],
    },
    {
        id: 33,
        img: "https://api.rotary.org.ua/uploads/clubsitecreator/interact_committe_page/home/1704973284995-84358404.png",
        header: 'Комунікація та Нетворкінг:',
        content: [
            {LandingMessage: `Комунікація та мережування є життєво важливими компонентами для будь-якої організації, особливо для такої, як Ротарі, яка покладається на сильні зв'язки між своїми членами. Наша мета – створити потужну мережу Інтеракт клубів, де молоді лідери можуть обмінюватися досвідом, ідеями та найкращими практиками. Ми організовуємо регулярні зустрічі, які надають молоді можливість зустрітися, обговорити свої проекти та ініціативи, а також створювати нові партнерства. Це не тільки зміцнює відносини між клубами, але й допомагає у формуванні нового покоління лідерів, здатних працювати разом для досягнення спільних цілей.`},
        ],
    },
    {
        id: 4,
        header: 'Наша команда:  ',
        arrcontent: [
            {
                content: [
                    {LandingMessage: ""},
                ],
            },
            {
                id: 4,
                cards: [
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/interact_committe_page/photo/1704963676474-391392097.jpg',
                        content: [
                            {LandingMessage: "- голова Комітету з питань Інтеракту"},
                            {LandingMessage: "- ..."},
                            {LandingMessage: "- ..."},
                        ],
                        name: "Юлія Гринчук",
                        phone: "+380 95 833 08 27",
                        email: "test@gmail.com",
                    },
                    
                ],
            },
        ],
    },
    {
        id: 5,
        header: 'Інтеракт клуби:',
        content: [
            {LandingList: [
                {"LandingListItem": "Rotary Club Kharkiv Mriya, Interact Club Kharkiv Mriya, v_19@ukr.net,   або -  v.seriakova.19@gmail.com,   SERYAKOVA  VALENTYNA", url: ""},
                {"LandingListItem": `Интеракт "Kharkiv NEW LEVEL ", "Kharkiv NEW LEVEL ", Kseniia Spiridonova toothmagics@gmail.com`, url: ""},
                {"LandingListItem": `Інтеракт "Zaporizhzhia- Khortytsia" , Gubina Oksana, gubina72@ukr.net;  097-362-88-28; 095-111-76-00`, url: ""},
                {"LandingListItem": `Інтеракт " Kharkiv United"
                Stasiuk Tatiana
                tatianaopariy@gmail.com
                066-522-11-07`, url: ""},
                {"LandingListItem": `Інтеракт-Черкаси
                Спонсорує РК Черкаси-Центр
                Опікун
                Сергій Саєнко
                Serte@i.ua`, url: ""},
                {"LandingListItem": `Інтеракт "Rivne"
                Спонсор RC Rivne
                Президент спонсор клубу Olena Korsun
                cfpoglad@gmail.com`, url: ""},
                {"LandingListItem": `Інтеракт Луцьк Лучеськ
                Спонсор : РК Луцьк Лучеськ
                Президент: Гринчук Юлія
                yulia_nsnu@ukr.net`, url: ""},
            ]},
        ]
    },
]
