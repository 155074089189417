import React from 'react';
import { Grid, Avatar, Box, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const LandingAreaOfFocusBlock = ({ landItem }) => {
    const navigate = useNavigate();

    const handleClick = (url) => {
        if (url) {
            if (url.startsWith('http')) {
                window.open(url, '_blank');
            } else {
                navigate(url);
            }
        }
      };

    return (
        <Grid container spacing={2} alignItems="center" >
        {landItem && landItem.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} sx={{ textAlign: 'center' }}>
                <Box onClick={() => handleClick(item.url)} sx={{ cursor: item.url ? 'pointer' : 'default' }}>
                <Avatar alt={item.LandingMessage} src={item.img} sx={{ width: 300, height: 300, margin: 'auto' }} />
                <Typography variant="h5" align="center">{item.LandingMessage}</Typography>
                </Box>
            </Grid>
        ))}
        </Grid>
    );
};

export default LandingAreaOfFocusBlock;
