import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from '@mui/material'
import '../Pages.css';

import MyMainPage from "../../modules/MYMain";

import LandingArrContentBlock from '../../components/LandingArrContentBlock';
import LandingContentBlock from '../../components/LandingContentBlock';

import {getBrandCentrPage} from '../../api/getBrandCentrPage';

const MyBrandCenterPage = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
              const d = await getBrandCentrPage();
              setData(d);
  
              console.log("DanatePage get data list success. length="+d.length);
          } catch (error) {
              console.log("DanatePage get data list error="+error);
          }
        };
        fetchData();
      }, [])

    return (
        <>
        <MyMainPage>
            
        {data && data.map((item,index) => (
                <>
                {(item.content || item.img || item.cards) && <LandingContentBlock id={index} landItem={item} designType='default' />}
                {item.arrcontent && <LandingArrContentBlock id={index} arrItem={item} designType='default' />}
                </>
            ))}

        </MyMainPage>
        </>
  );
}

export default MyBrandCenterPage;