import { Box, TextField, Button, Typography } from '@mui/material';

const FormFieldsComponent = ({selectedCommunique, communiqueTableTitles, setSelectedCommunique, handleSubmit}) => {
    return (
        <>
        <div>
            <Typography variant="body1" color="textSecondary">
                data={JSON.stringify(selectedCommunique)}<br/>
                titles={communiqueTableTitles && JSON.stringify(communiqueTableTitles)}
            </Typography>

            {communiqueTableTitles && Object.values(communiqueTableTitles).map((item) => (
                <>
                {item!=='id' && <Box mt={3} mb={3}>
                    <TextField
                        fullWidth
                        label={item}
                        size="small"
                        variant="outlined"
                        value={selectedCommunique[item]}
                        onChange={e => setSelectedCommunique({ ...selectedCommunique, [item]: e.target.value })}
                    />
                </Box>}
                </>
            ))}

            <Box mt={3} display="flex" gap={1}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {selectedCommunique.id ? 'Update' : 'Create'}
                </Button>
                <Button variant="contained" color="primary" onClick={() => setSelectedCommunique(null)}>Cancel</Button>
            </Box>
        </div>
        </>
    )
}

export default FormFieldsComponent;