import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, FormGroup, Checkbox, Box, Typography } from '@mui/material';

const EventFilters = ({ filters, setFilters, eventCounts }) => {
  const handleChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.checked
    });
  };

  return (
    <Box sx={{ mb: 2 }}>
      
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            control={<Checkbox
              checked={filters.district}
              onChange={handleChange}
              name="district"
              sx={{ color: 'blue', '&.Mui-checked': { color: 'blue' } }}
            />}
            label={`Дистриктні події (${eventCounts.district})`}
          />
          <FormControlLabel
            control={<Checkbox
              checked={filters.club}
              onChange={handleChange}
              name="club"
              sx={{ color: 'green', '&.Mui-checked': { color: 'green' } }}
            />}
            label={`Клубні події (${eventCounts.club})`}
          />
          <FormControlLabel
            control={<Checkbox
              checked={filters.governor}
              onChange={handleChange}
              name="governor"
              sx={{ color: 'orange', '&.Mui-checked': { color: 'orange' } }}
            />}
            label={`Візити губернатора (${eventCounts.governor})`}
          />
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default EventFilters;
