import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Typography, Box, Paper, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { fetchUserSkils, createUserSkils, updateUserSkils, deleteUserSkils } from '../api/api_user_skils';

import CrudTable from './CrudTable';
import AdminDialogCrud from './AdminDialogCrud';

const userSkilsColumns = [
    { field: 'category', headerName: 'category', type: 'id', default: '' },
    { field: 'sector', headerName: 'sector', type: 'lineedit', default: '' },
    { field: 'description', headerName: 'description', type: 'multilineedit', default: '' },
];

const generateDefaultObject = (columns) => {
    return columns.reduce((acc, column) => {
        acc[column.field] = column.default;
        return acc;
    }, {});
};

const UserSlilsCRUD = ({userId, handleSkilsClose}) => {
    const [userSkils, setUserSkils] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentUserSkils, setCurrentUserSkils] = useState(generateDefaultObject(userSkilsColumns));
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                loadUserSkils();
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                loadUserSkils();
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [userId]);

    const loadUserSkils = async () => {
        const fetchedUserSkils = await fetchUserSkils(userId);
        setUserSkils(fetchedUserSkils);
    }

    const handleEditUser = (skil = generateDefaultObject(userSkilsColumns)) => {
        setCurrentUserSkils(skil);
        setIsEditMode(!!skil.id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        console.log('=== handleSubmit...');
        try {
            if (isEditMode) {
                await updateUserSkils(currentUserSkils.id, currentUserSkils);
            } else {
                currentUserSkils.user_id = userId;
                await createUserSkils(currentUserSkils);
            }
            handleClose();
            loadUserSkils();
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = async (userId) => {
        console.log('=== handleDelete...');
        const confirmDelete = window.confirm('Чи дійсно хочете видалити цю картку ротарійця?');
        if (!confirmDelete) return;
    
        try {
            await deleteUserSkils(userId);
            loadUserSkils();
        } catch (error) {
            console.error(error);
        }
    };


    const handleSkilsSubmit = async () => {
    }

    return (
        <div>
            <Button variant="contained" color="primary" onClick={() => handleEditUser()}>
                Add Skil
            </Button>

            <CrudTable data={userSkils} columns={userSkilsColumns} onEdit={handleEditUser} onDelete={handleDelete} />

            <AdminDialogCrud
                open={open}
                onClose={handleClose}
                onSubmit={handleSubmit}
                isEditMode={isEditMode}
                columns={userSkilsColumns}
                currentItem={currentUserSkils}
                setCurrentItem={setCurrentUserSkils}
            />
    
        </div>
    );
}

export default UserSlilsCRUD;
