
import DocumentsFrame from './DocumentsFrame';
import WordTypePage from '../../components/WordTypePage';

const DocumentsPage = () => {
    return (
        <WordTypePage>
            <DocumentsFrame />
        </WordTypePage>
  );
}

export default DocumentsPage;