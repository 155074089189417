import React, { useEffect, useState, useRef } from "react";

import MyMainPage from "../../modules/MYMain";
import Header from "../../modules/Header";
import LandingContentBlock from '../../components/LandingContentBlock';
import {getAdminPageContent} from '../../api/getAdminPageContent';

const MyProfileD2232 = () => {
    const [data, setData] = useState([]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const d = await getAdminPageContent();
                setData(d);
            } catch (error) {
                console.log("getHomeLanding get list error="+error);
            }
        };
        fetchData();
      }, [])

    return (
        <MyMainPage>
            
            {data && data.map((item,index) => (
                <LandingContentBlock landItem={item} image={item.img} key={index} />
            ))}
        </MyMainPage>
    );
};

export default MyProfileD2232;
