import React, { useState } from 'react';
import axios from 'axios';

const SERVERURL = 'https://apifs.rotaryd2232ukraine.org/storage';

const FileList = () => {
  const [files, setFiles] = useState([]);
  const [path, setPath] = useState('/');

  const handlePathChange = (e) => {
    setPath(e.target.value);
  };

  const fetchFiles = async () => {
    const token = localStorage.getItem('tokenFS'); // Виправте назву токена на 'tokenFS'
    try {
      const response = await axios.post(`${SERVERURL}/list`, { path }, {
        headers: {
          'Storage-Token': token,
          'Content-Type': 'application/json'
        }
      });
      console.log('Response from server:', JSON.stringify(response.data));

      const arrFiles = response.data.callback;
      // Перевірте, чи відповідь є масивом
      if (Array.isArray(arrFiles)) {
        setFiles(arrFiles);
      } else {
        console.error('Response data is not an array:', response.data);
        //alert('Failed to fetch files! Response data is not an array.');
      }
    } catch (error) {
      console.error('Failed to fetch files:', error);
      //alert('Failed to fetch files!');
    }
  };

  return (
    <div>
      <input type="text" placeholder="Path" value={path} onChange={handlePathChange} />
      <button onClick={fetchFiles}>Fetch Files</button>
      <ul>
        {files && files.map(file => (
          <li key={file.name}>{file.name}</li>
        ))}
      </ul>
    </div>
  );
};

export default FileList;
