import React, { useEffect, useState, useContext } from "react";
import { Button, Box, Container, Grid, Typography } from '@mui/material'

import MainGlobal from '../../modules/MainGlobal';
import {getEvent1Landing, getPhotoForLanding} from '../../api/getEvent1Landing'

import { FormattedMessage } from 'react-intl';
import {tr} from '../../i18n/tr';
import LandingContentBlock from '../../components/LandingContentBlock';
import { LOCALES } from '../../i18n/locales';
import {LocalContext} from '../../tools/LanguageContextProvider';

import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import LandingArrContentBlock from '../../components/LandingArrContentBlock';

import Header from "../../modules/Header";
import PhotoCarusel from "../../modules/PhotoCarusel";

import { getPresentations, getPresentationsBySemId } from '../../api/ts/materials_seminars_presentations';
import { List, ListItem, ListItemText, } from '@mui/material'
import LandingBlock from '../../components/LandingBlock';
import LandingHeader from '../../components/LandingHeader';
import { Card, CardContent, Link, CardMedia } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import { styled } from '@mui/system';

import HOME1_1 from '../../assets/content/business_council/business_council.jpg';
import HOME1_2 from '../../assets/content/business_council/Lviv3.png';
import HOME1_4 from '../../assets/content/business_council/business5.jpg';
import IMG_PNG_1 from '../../assets/img/icons/zoom.jpg';

const NoWrapTypography = styled(Typography)(({ theme }) => ({
    '&.MuiTypography-root': {
      [theme.breakpoints.up('sm')]: {
        whiteSpace: 'nowrap',
      },
    },
}));
  
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    // Застосовуємо стилі тільки для sm екранів
    [theme.breakpoints.down('sm')]: {
        paddingLeft: '2px',
        paddingRight: '0px',
        _border: '1px solid red',
        _color: 'red',
    },
}));

const Event1Page = () => {
    const { currentLocale, setCurrentLocale } = useContext(LocalContext);
    const images = [ HOME1_2, HOME1_4];
    const [data, setData] = useState([]);
    const [dataEng, setDataEng] = useState([]);
    const [dataUkr, setDataUkr] = useState([]);
    const [presentations, setPresentations] = useState(null);
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const d_eng = await getEvent1Landing(false);
          setDataEng(d_eng);
          const d_ukr = await getEvent1Landing(true);
          setDataUkr(d_ukr);
          setData( currentLocale===LOCALES.UKRAINIAN ? d_ukr : d_eng);
          const f = await getPhotoForLanding();
          setPhotos(f);
          console.log("Event1Page get data list success. const="+d_ukr.length);

            const dt2 = await getPresentationsBySemId(14);
            //const dt2 = await getPresentations();
            setPresentations(dt2);
        } catch (error) {
          console.log("Event1Page get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        setData( currentLocale===LOCALES.UKRAINIAN ? dataUkr : dataEng);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale])
    
    const showCard = (desc, img, url, pwd) => {
        return (
            <Card variant="outlined" sx={{ maxWidth: 345, margin: '20px auto' }}>
                <CardMedia
                    component="img"
                    height="140"
                    image={img}
                    alt="Зображення семінару"
                />
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        Семінар з міжнародної діяльності
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {desc}
                    </Typography>
                    <Button variant="contained" color="primary" href={url} >
                        Перейти до запису
                    </Button>
                    <Typography variant="body1" color="textPrimary" style={{marginTop: '10px'}}>
                        Passcode: {pwd}
                    </Typography>
                </CardContent>
            </Card>
        )
    }

    return (

        <MainGlobal noWarningMode="hide" >
            <div style={{ position: 'relative' }}>
                <Header images={["https://res.cloudinary.com/tyko/image/upload/v1694701239/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/0-02-05-8dc380c7c6750c8215a1d02f0037acf868fa8f5e9b0df194bfdc3cf2a6bb6b1e_f1d14f0f255c9a65_zmg63o.jpg",
                                    "https://res.cloudinary.com/tyko/image/upload/v1694690937/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/Screenshot_11_2_tvysos.jpg"]} /> 
                
                <Typography 
                variant="h3" 
                style={{
                    zIndex: 1000,
                    position: 'absolute',
                    width: '100%',
                    top: '15%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    padding: 2,
                    paddingTop: '50px',
                    color: 'white', // Задає колір тексту, якщо потрібно
                    backgroundColor: 'rgba(0, 0, 0, 0.2)', // Задає фон, якщо потрібно
                    fontSize: '6vw',
                    fontWeight: 'bold',
                }}
                >
                    <br/><br/> 
                    {tr("Програма заходів Дистрикту 2232")}
                </Typography>
                <Typography 
                variant="h3" 
                style={{
                    zIndex: 1000,
                    position: 'absolute',
                    width: '100%',
                    top: '1%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    padding: 2,
                    paddingTop: '100px',
                    color: 'white', // Задає колір тексту, якщо потрібно
                    backgroundColor: 'rgba(0, 0, 0, 0.0)', // Задає фон, якщо потрібно
                    fontSize: 'max(2vw, 14pt)',
                }}
                >
                {tr("на 29 вересня – 1 жовтня, 2023 р., м. Черкаси")}
                </Typography>
                <Button 
                    variant='contained'
                    size='large'
                    sx={{ textTransform: 'none' }} 
                    onClick={() => {
                        
                            window.open('https://forms.gle/WMWTurcijYVXTYjPA', '_blank');
                            //window.scrollTo(0, 0);
                            console.log("LandingButtonLink click="+'https://forms.gle/WMWTurcijYVXTYjPA')
                        
                    }}
                    style={{
                        zIndex: 1000,
                        position: 'absolute',
                        _width: '30%',
                        bottom: '50px',
                        right: '50px',
                        _transform: 'translate(-50%, -50%)',
                        background: 'rgba(255, 215, 0, 0.8)',
                        fontSize: 'max(2vw, 14pt)',
                    }}
                >
                    {tr("Реєстрація учасників")}
                </Button>
            </div>

            {data && data.map((item,index) => (
                <React.Fragment key={index}>
                    {!item.arrcontent && <LandingContentBlock id={index} landItem={item} designType='busines' />}
                    {item.arrcontent && <LandingArrContentBlock id={index} arrItem={item} designType='busines' />}
                </React.Fragment>
            ))}
            
            <LandingBlock mode={'dark2'}>
                <Box className="section text-left">
                <Container>
                    <Box>
                        <LandingHeader>
                            <Typography variant="h4" className="page-header">
                                <FormattedMessage id="Презентації семінару" />
                            </Typography>
                        </LandingHeader>
                    </Box>

                    <Table>
                        <TableBody>
                            {Array.isArray(presentations) && presentations.map((item, index) => (
                                <TableRow key={item.id}>
                                    <StyledTableCell>
                                        {item.url 
                                        ? <Link href={item.url} target="_blank" rel="noopener noreferrer">
                                            <InsertDriveFileIcon color="yellow" fontSize="large" />
                                        </Link>
                                        : <Typography>Файл відсутній</Typography>
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <NoWrapTypography variant="subtitle2" color="white">{item.adv1}</NoWrapTypography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Typography variant="h8" color="lightblue">{item.name}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Typography variant="subtitle1" color="white">{item.speaker}</Typography>
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>


                    </Container>
                </Box>
            </LandingBlock>

            <LandingBlock mode={'light2'}>
                <Box className="section text-left">
                <Container>
                    <Box>
                        <LandingHeader>
                            <Typography variant="h4" className="page-header">
                                <FormattedMessage id="Запис Зум семінару" />
                            </Typography>
                        </LandingHeader>
                    </Box>
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={4}>
                    {showCard(
                        "Посилання на відеозапис онлайн події про культурні традиції українців 29.09:",
                        IMG_PNG_1,
                        "https://us02web.zoom.us/rec/share/5ts7--RKOj8OXZWClprr0aDSk3lx8IizqK7NevkpoW_jq1ef3nYSNHXDQ3e9fnEI.az4aJddmvUCr6_sZ?startTime=1696003463000",
                        "Y0$Z%B6i"
                    )}
                    {showCard(
                        "Посилання на запис англомовної 2 секції семінару з міжнародної діяльності 30.09:",
                        IMG_PNG_1,
                        "https://us02web.zoom.us/rec/share/otVgw7Glha9U44h2fezwGaL1OEdI3z8O_7nVLQgcOOF7OYCpTve6q4QbWranI9Ty.pIfkKbjfNLh5rMa3?startTime=1696058210000",
                        "s7BM3A@5"
                    )}
                    </Box>
                    <Box>
                        <LandingHeader>
                            <Typography variant="h4" className="page-header">
                                <FormattedMessage id="Медіа матеріали:" />
                            </Typography>
                        </LandingHeader>
                        <Box display="flex" flexDirection='row' gap={1} >
                            <Typography variant="h6">- </Typography>
                            <Typography variant="h6"> "Melody in a minor" Скорик</Typography>
                            <a href="https://res.cloudinary.com/tyko/video/upload/v1696860645/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%80%D0%BE%D0%BB%D0%B8%D0%BA%D0%B8/0-02-05-4bf9b621e82214035545b6880ab4467751b2fc8b2106efc9382c90687ae7d47c_62c1c6214a4c55cb_q9n95r.mp4"
                            > <Typography variant="h6">відео ролик</Typography> </a>
                            
                        </Box>
                    </Box>
                    
                    
                    </Container>
                </Box>
            </LandingBlock>
            
            <LandingBlock mode={'dark2'}>
                <Box className="section text-left">
                <Container>
                    <Box>
                        <LandingHeader>
                            <Typography variant="h4" className="page-header">
                                <FormattedMessage id="Фотогалерея семінару" />
                            </Typography>
                        </LandingHeader>
                    </Box>
                    
                    <PhotoCarusel 
                        images={photos} 
                    /> 

                    </Container>
                </Box>
            </LandingBlock>


        </MainGlobal>
  );
}

export default Event1Page;