import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';

const SkillsTabs = ({ skills }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                value={selectedTab}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
            >
                {skills.map((skill, index) => (
                    <Tab key={index} label={skill.category} />
                ))}
            </Tabs>
            {skills.map((skill, index) => (
                <TabPanel key={index} value={selectedTab} index={index}>
                    
                    <Typography style={{ whiteSpace: 'pre-line' }} >{skill.description}</Typography>
                </TabPanel>
            ))}
        </Box>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`skill-tabpanel-${index}`}
            aria-labelledby={`skill-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default SkillsTabs;
