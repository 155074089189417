import React, { useState } from "react";
import { Box, Typography, Container } from '@mui/material';
import ScrollTrigger from 'react-scroll-trigger';
import './LandingContentBlock2.css';

import LandingBlock from '../../../components/LandingBlock';
import LandingContentItems from '../../../components/LandingContentItems';
import LandingCards2List from '../../../components/LandingCards2List';
import LandingFilters from '../fixcomponents/LandingFilters';
import LandingProjects from '../components/LandingProjects';
import LandingProjects2 from '../components/LandingProjects2';
import LandingProjects3_eclub from '../components/LandingProjects3_eclub';
import LandingProjects2_Singapore from '../components/LandingProjects2_Singapore';
import LandingCardEvent from '../components/LandingCardEvent';
import LandingStatisticsBlock from '../components/LandingStatisticsBlock';
import LandingAreaOfFocusBlock from '../components/LandingAreaOfFocusBlock';
import LandingAreaOfClubFocusBlock from '../components/LandingAreaOfClubFocusBlock';
import LandingChartProjects from '../components/LandingChartProjects';
import LandingBdRotarians from '../components/LandingBdRotarians';
import LandingSubcardsRotarians from '../components/LandingSubcardsRotarians/LandingSubcardsRotarians';
import LandingSubcardsRotarians_eclub from '../components/LandingSubcardsRotarians/LandingSubcardsRotarians_eclub';
import EventsFrame2 from '../../EventsFrame2';

import PhotoCarusel from '../../../modules/PhotoCarusel';

import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button } from '@mui/material';
import { MdEmail } from 'react-icons/md';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const LandingContentBlock2 = ({ id, landItem, designType, children }) => {
    const [isImageVisible, setImageVisible] = useState(false);
    const [isContentVisible, setContentVisible] = useState(false);
    const [cardView, setCardView] = useState(0);
    const [selectedCategories, setSelectedCategories] = useState(["Діти", "Медицина", "Вода"]);
    const [selectedStatuses, setSelectedStatuses] = useState([
        "Запропоновано, шукає глобальних грантів-партнерів",
        "Запропонував, шукає інших партнерів",
        "В процесі", 
        "Завершено"]);
    
    const isEvenId = id % 2 === 0;
    let mode;
    switch (designType) {
        case 'business':
            mode = isEvenId ? "light2" : "dark2";
            break;
        case 'chicago':
            mode = isEvenId ? "light3" : "dark3";
            break;
        case 'simple':
        case 'simple2':
            mode = isEvenId ? "light5" : "dark3";
            break;
        default:
            mode = isEvenId ? "light2" : "light";
    }
    const imagePosition = (id % 2 === 0 ? "left" : "right");

    const handleImageScrollProgress = (progress) => {
        if (progress.progress > 0.2) {
            setImageVisible(true);
        }
    };

    const handleContentScrollProgress = (progress) => {
        if (progress.progress > 0.1) {
            setContentVisible(true);
        }
    };

    const cardViewChanged = (v) => {
        setCardView(v);
    }

    const cbCategories = (cat) => {
        setSelectedCategories(cat);
    }

    const cbStatuses = (st) => {
        setSelectedStatuses(st);
    }

    const showCards3 = (cards) => {
        return (
            <>
            Показати як: 
            <Button variant='contained' onClick={() => cardViewChanged(0)}>cards</Button>
            <Button variant='contained' onClick={() => cardViewChanged(1)} >list</Button>
            <Button variant='contained' disabled >table</Button>
            <LandingCards2List cards={cards} />
            </>
        )
    }

    const showCards = (cards) => {
        return (
            <>
            Показати як: 
            <Button variant='contained' onClick={() => cardViewChanged(0)}>cards</Button>
            <Button variant='contained' onClick={() => cardViewChanged(1)} >list</Button>
            <Button variant='contained' disabled >table</Button>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}  gap={2} alignItems="top" paddingTop='2em' flexWrap='wrap'>
            {cards && cards.map((card, index) => (
                <Card sx={{ maxWidth: 360, minWidth: 200 }} key={index} >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    sx={{ maxWidth: 400 }}
                    image={card.img}
                    alt="Без фото"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                    {card.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    <lu>
                    <LandingContentItems content={card.content} />
                    </lu>
                    </Typography>
                    
                  </CardContent>
                </CardActionArea>
                <CardActions>
                
                    {card.email && <Tooltip title={'Пошта'}>
                    <IconButton onClick={() => copyToClipboard(card.email)} aria-label="Facebook">
                        <MdEmail color="blue" size='1.5em' />
                    </IconButton>
                    </Tooltip>}

                    {card.phone && <Tooltip title={'Телефон'}>
                    <IconButton onClick={() => copyToClipboard(card.phone)} aria-label="Facebook">
                        <PhoneIcon color="primary" size='1.5em' />
                    </IconButton>
                    </Tooltip>
                    }
                    
                </CardActions>
                </Card>
            ))}
            </Box>
            </>
        )
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            alert('Зміст скопійовано в буфер обміну');
          })
          .catch((error) => {
            console.error('Помилка при копіюванні в буфер обміну:', error);
          });
    };

    const showContentBlock = (landItem) => {

        const cards4 = landItem.cards3 && landItem.cards3.filter((card) => 
            //selectedCategories.some((category) => card.category === category) 
            //&& 
            selectedStatuses.some((status) => card.status === status)
            //true
        );


        return (
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="flex-start">

                <Box flex={1} padding={2}>
                    <ScrollTrigger onProgress={handleContentScrollProgress}>
                        <div className={`slide-in-content ${isContentVisible ? 'active' : ''}`}>
                            {cards4 && cardView===1 ? showCards3(cards4) : showCards(cards4)}
                        </div>
                    </ScrollTrigger>
                </Box>
            </Box>
        )
    }

    const showPhotoCaruselBlock = (imgs) => {
        return (
            <PhotoCarusel images={imgs} />
        )
    }

    return (
        <LandingBlock mode={mode}>
            <Box className="section text-left">
                <Container>
                    {landItem.cards3 && 
                    
                    <>
                    {/*categories222={selectedCategories} */}

                    <LandingFilters 
                            selectedCategories={selectedCategories} 
                            cbCategories={cbCategories} 
                            selectedStatuses={selectedStatuses}
                            cbStatuses={cbStatuses}
                    /> 
                    </>}
                    
                    {(landItem.cards3) && showContentBlock(landItem)}

                    
                    {(landItem.imgs2) && showPhotoCaruselBlock(landItem.imgs)}

                    {(landItem.LandingProjects) 
                        && <LandingProjects landItem={landItem} />
                    }

                    {(landItem.LandingProjects2) 
                        && <LandingProjects2 landItem={landItem} />
                    }

                    {(landItem.LandingProjects3_eclub) 
                        && <LandingProjects3_eclub landItem={landItem} />
                    }
                    
                    {(landItem.LandingProjects2_Singapore) 
                        && <LandingProjects2_Singapore landItem={landItem} />
                    }
                    
                    {(landItem.LandingCardEvent) 
                        && <LandingCardEvent landItem={landItem} />
                    }

                    {(landItem.LandingStatisticsBlock) 
                        && <LandingStatisticsBlock landItem={landItem.LandingStatisticsBlock} />
                    }

                    {(landItem.LandingAreaOfClubFocusBlock) 
                        && <LandingAreaOfClubFocusBlock landItem={landItem.LandingAreaOfClubFocusBlock} />
                    }

                    {(landItem.LandingAreaOfFocusBlock) 
                        && <LandingAreaOfFocusBlock landItem={landItem.LandingAreaOfFocusBlock} />
                    }

                    {(landItem.LandingChartProjects) 
                        && <LandingChartProjects landItem={landItem} />
                    }

                    {(landItem.LandingBdRotarians) 
                        && <LandingBdRotarians tags={landItem.LandingBdRotarians} />
                    }
                    {(landItem.LandingBdRotariansPublic) 
                        && <LandingBdRotarians tags={landItem.LandingBdRotariansPublic} ispublic='true' />
                    }

                    {(landItem.LandingCardsRotarians)
                        && <LandingSubcardsRotarians cards={landItem.LandingCardsRotarians} ispublic='true' isemailpublic='true' />}
                    {(landItem.LandingCardsRotarians_eclub)
                        && <LandingSubcardsRotarians_eclub cards={landItem.LandingCardsRotarians_eclub} ispublic='true' isemailpublic='true' />}
                    
                    {(landItem.LandingEventFrame)
                        && <EventsFrame2 dates={landItem.LandingEventFrame} />}

                </Container>
            </Box>
        </LandingBlock>
    );
    
};

export default LandingContentBlock2;
