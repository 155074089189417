
import { LOCALES } from '../../../i18n/locales';

export const getRCCherkasyBoard = (local) => {
    const cherkasyData = local === LOCALES.UKRAINIAN ? cherkasyData_ukr : cherkasyData_eng;
    return cherkasyData;
};
            
const cherkasyData_ukr = [
    {
        id: 0,
        img: "",
        header: 'Правління та посадові особи:',
        content: [
            {LandingMessage: `[Правління - карточки та присяга президента]`},
        ],
    },
];

const cherkasyData_eng = [
    {
        id: 0,
        img: "",
        header: 'Board & Officers:',
        content: [
            {LandingMessage: `[Правління - карточки та присяга президента]`},
        ],
    },
];