import React, { useState } from 'react';
  
const LandingPdf = ({ title, link }) => {
  
    return (
        <div>
            <iframe
                title="PDF Viewer"
                src={link}
                style={{ width: '100%', height: '1200px', border: 'none' }}
            />
        </div>
    );
};

export default LandingPdf;
