export const getGovernors2 = () => {
    return governors;
}

export const getOverDistrict2 = () => {
    return overDistrict;
}
export const getPastGovernors2 = () => {
    return pastGovernors;
}
export const getAssistents2 = () => {
    return assistents;
}
export const getCommittees2 = () => {
    return committees;
}

export const titles2 = {
    title1: "ROTARY INTERNATIONAL DISTRICT 2232 TEAM 2024/25",
    title2: "ROTARY INTERNATIONAL DISTRICT 2232 TEAM 2024/24 - Офіцерська Команда Дистрикту 2232 2024-25",
    title3: "",
}

const governors = [
    {
      "Function": "District Governor",
      "Name": "Oleksandr Khardin",
      "Rotary Club": "RC Poltava",
      "Phone": "+380503045443, +380675340104",
      "E-mail": "okhardin@ukr.net",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/governor"
    },
    {
      "Function": "District Governor Elect (2024-2025)",
      "Name": "Olha Paliychuk",
      "Rotary Club": "RC Cherkasy",
      "Phone": "+380505207352",
      "E-mail": "oncology@2upost.com",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/governor"
    },
    {
      "Function": "District Governor Nominee (2025-2026)",
      "Name": "-",
      "Rotary Club": "-",
      "Phone": "-",
      "E-mail": "-",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/governor"
    }
  ]
  
    const overDistrict = [
        {
            "Function": "Representative in Council of Legislation",
            "Name": "Hennadii Kroichyk",
            "Rotary Club": "RC Lviv",
            "Phone": "380676711433",
            "E-mail": "hkroichyk@gmail.com",
            "My rotary account": "Y",
            "Learning center": "https://my.rotary.org/en/learning-reference/about-rotary/councils"
        },
        {
            "Function": "Assistant Rotary Coordinator, Region 24 (Zone 21A)",
            "Name": "Vira Syriamina",
            "Rotary Club": "RC Lviv-Zamok",
            "Phone": "+380677466521",
            "E-mail": "rotaryd2232ua@gmail.com",
            "My rotary account": "Y",
            "Learning center": ""
        },
        {
            "Function": "Assistant Rotary Foundation Coordinator, Region 24 (Zone 21A)",
            "Name": "Sergii Zavadskyi",
            "Rotary Club": "RC Kyiv-City",
            "Phone": "+380678471695",
            "E-mail": "serhiy_zavadsky@yahoo.com",
            "My rotary account": "Y",
            "Learning center": ""
        },
        {
            "Function": "Zone 21A Public Image Coordinator",
            "Name": "Mykola Steblianko",
            "Rotary Club": "ReC Ukraine",
            "Phone": "+380676503018",
            "E-mail": "sniko@ukr.net",
            "My rotary account": "Y",
            "Learning center": ""
        }
    ]



  const pastGovernors = [
    {
      "Name": "Pavlo Kashkadamov, D2230",
      "Year": "2002-03",
      "Rotary Club": "RC Lviv",
      "Phone": "380677100097",
      "E-mail": "Pavlo@mail.lviv.ua",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Name": "Myroslav Gavryliv, D2230",
      "Year": "2011-12",
      "Rotary Club": "RC Lviv",
      "Phone": "380503172280",
      "E-mail": "myroslavgavryliv@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Name": "Olexiy Kozhenkin, D2230",
      "Year": "2014-15",
      "Rotary Club": "RC Kyiv",
      "Phone": "-",
      "E-mail": "-",
      "My rotary account": "RIP",
      "Learning center": ""
    },
    {
      "Name": "Hennadii Kroichyk, D2232",
      "Year": "2016-17",
      "Rotary Club": "RC Lviv",
      "Phone": "380676711433",
      "E-mail": "hkroichyk@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Name": "Siarhei Filipau, D2232",
      "Year": "2017-18",
      "Rotary Club": "RC Minsk-Ratusha",
      "Phone": "375296223211",
      "E-mail": "s.filippov@esplus.by",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Name": "Sergii Zavadskyi, D2232",
      "Year": "2018-19",
      "Rotary Club": "RC Kyiv-City",
      "Phone": "380504690579",
      "E-mail": "serhiy_zavadsky@yahoo.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Name": "Mykola Steblianko, D2232",
      "Year": "2019-20",
      "Rotary Club": "ReC Ukraine",
      "Phone": "380676503018",
      "E-mail": "sniko@ukr.net",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Name": "Vira Syriamina, D2232",
      "Year": "2020-21",
      "Rotary Club": "RC Lviv-Zamok",
      "Phone": "380677466521",
      "E-mail": "rotaryd2232ua@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Name": "Volodymyr Bondarenko, D2232",
      "Year": "2021-22",
      "Rotary Club": "RC Ivano-Frankivsk",
      "Phone": "380505415179",
      "E-mail": "volbondarenko@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Name": "Vitalii Lesko, D2232",
      "Year": "2022-23",
      "Rotary Club": "RC Rivne",
      "Phone": "380678000790  380505451222",
      "E-mail": "leskovit@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Name": "Myron Uhryn, D2232",
      "Year": "2023-24",
      "Rotary Club": "RC Lviv",
      "Phone": "+380673544442",
      "E-mail": "mm_clinic@ukr.net",
      "My rotary account": "Y",
      "Learning center": ""
    }
  ]

  const assistents = [
    {
        "AREA & ROTARY CLUBS TO SERVE": "1. Lviv, Lviv-Centre. Lviv International, Lviv-Kniazhyi, Lviv-Leopolis, Lviv Zamok, Lviv Ratusha, Lviv Innovational, Truskavets, PRC Ukraine Unity",
        "Name": "Bohdan Parashchuk",
        "Rotary Club": "RC Lviv-Zamok",
        "Phone": "+380677130736",
        "E-mail": "pb02@ukr.net",
        "": "Y",
        "": "https://my.rotary.org/en/learning-reference/learn-role/assistant-governor"
    },
    {
        "AREA & ROTARY CLUBS TO SERVE": "2. Rivne, Dubno, Lutsk Luchesk, Lutsk Volyn Pride, Korosten, Ternipil City",
        "Name": "Olha Novytska",
        "Rotary Club": "RC Rivne",
        "Phone": "+380501846830",
        "E-mail": "o.s.novytska@gmail.com",
        "": "Y",
        "": "https://my.rotary.org/en/learning-reference/learn-role/assistant-governor"
    },
    {
        "AREA & ROTARY CLUBS TO SERVE": "3. Kyiv, Kyiv-Centre, Kyiv-City, Kyiv-Capital, Kyiv Multinational, Kyiv Synergy, Kyiv-Sophia, Kyiv-Balzac, Kyiv-Advance, Kyiv International Business, Kyiv Golden Pectoral",
        "Name": "Lesia Dzhadan",
        "Rotary Club": "RC Kyiv - Capital",
        "Phone": "+380504102258",
        "E-mail": "l.dzhadan@ukr.net",
        "": "Y",
        "": "https://my.rotary.org/en/learning-reference/learn-role/assistant-governor"
    },
    {
        "AREA & ROTARY CLUBS TO SERVE": "4. Cherkasy, Cherkasy-Centre, Cherkasy International, Cherkasy Synergy, Poltava, Kobylyaky, Vinnytsia Postup",
        "Name": "Andrii Gyzhko",
        "Rotary Club": "Vinnytsia Postup",
        "Phone": "+380972807236",
        "E-mail": "apgyzhko@gmail.com",
        "": "Y",
        "": "https://my.rotary.org/en/learning-reference/learn-role/assistant-governor"
    },
    {
        "AREA & ROTARY CLUBS TO SERVE": "5. Kharkiv, Kharkiv-City,Kharkiv Direct Help International, Kharkiv-Mriya, Kharkiv-Multinational, Kharkiv-Nadiya, Kharkiv First Capital, Kharkiv International, Kharkiv New Level, Kharkiv Phoenix, Kharkiv Renaissance, Kharkiv Victory, Kharkiv Spirit, Slovyansk",
        "Name": "Oleksandr Chupylko",
        "Rotary Club": "RC Kharkiv City",
        "Phone": "+380675702919",
        "E-mail": "avch1306@gmail.com",
        "": "Y",
        "": "https://my.rotary.org/en/learning-reference/learn-role/assistant-governor"
    },
    {
        "AREA & ROTARY CLUBS TO SERVE": "6. Dnipro City, Dnipro Novyi, Dnipropetrovsk-Dnipro-Region, Zaporizhzhia, Zaporizhzhia Khortytsya, Kropyvnytskyi",
        "Name": "Valerii Pavlovskyi",
        "Rotary Club": "RC Dnipro City",
        "Phone": "380504201630",
        "E-mail": "Pvn.rotary@gmail.com",
        "": "Y",
        "": "https://my.rotary.org/en/learning-reference/learn-role/assistant-governor"
    },
    {
        "AREA & ROTARY CLUBS TO SERVE": "7. Kherson, Mykolaiv, Odesa, Odesa-Rishelye, Odesa International, Odesa Sea Energy, Izmail, Alushta, E-Club of Ukraine, Simferopol, Vizirka",
        "Name": "Oksana Chymber",
        "Rotary Club": "RC Izmail",
        "Phone": "+380677738608",
        "E-mail": "chimberok@gmail.com",
        "": "Y",
        "": "https://my.rotary.org/en/learning-reference/learn-role/assistant-governor"
    },
    {
        "AREA & ROTARY CLUBS TO SERVE": "8. Chernivtsi, Ivano-Frankivsk, Ivano-Frankivsk-Stanislaviv, Uzhgorod, Uzhgorod-Skala, Rakhiv Center Europe,  Velyatyno International",
        "Name": "Tetiana Andrus",
        "Rotary Club": "RC Uzhgorod",
        "Phone": "+380638196573",
        "E-mail": "hillform@gmail.com    tanyaandrus10@gmail.com",
        "": "Y",
        "": "https://my.rotary.org/en/learning-reference/learn-role/assistant-governor"
    }
]


  const committees = [
    
  ]

  //========================
  const committees_members = [
    {
      "Committee": "Membership Committee - Комітет з питань членства",
      "Role": "Chair",
      "Name": "Olga Steblianko",
      "Club": "ReC of Ukraine",
      "Phone": "380679199810",
      "E-mail": "tarakchi@ukr.net",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/district-committees"
    },
    {
      "Committee": "Membership Committee - Комітет з питань членства",
      "Role": "Inсubator",
      "Name": "Mykola Steblianko",
      "Club": "ReC Ukraine",
      "Phone": "380676503018",
      "E-mail": "sniko@ukr.net",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "Membership Committee - Комітет з питань членства",
      "Role": "New Club Development",
      "Name": "Sergii Ivaglo",
      "Club": "RC Kharkiv New Level",
      "Phone": "380953162872",
      "E-mail": "serseriii@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    }, 
    {
      "Committee": "Finance Committee - Фінансовий Комітет",
      "Role": "Chair",
      "Name": "Yuriy Melnyk",
      "Club": "RC Lviv",
      "Phone": "380676761232",
      "E-mail": "yuriy.melnyk@rotary.lviv.ua",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/district-committees"
    },
    {
      "Committee": "Finance Committee - Фінансовий Комітет",
      "Role": "Public union accountant",
      "Name": "Olha Chaykivska",
      "Club": "",
      "Phone": "380672725602",
      "E-mail": "finance.district2232@gmail.com",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "Finance Committee - Фінансовий Комітет",
      "Role": "Member",
      "Name": "Yevgeniy Ritter",
      "Club": "RC Poltava",
      "Phone": "380666180020",
      "E-mail": "eugenritter83@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "Finance Committee - Фінансовий Комітет",
      "Role": "Treasurer for UA",
      "Name": "Yuriy Fedak",
      "Club": "RC Rakhiv - Center of Europe",
      "Phone": "380672982609",
      "E-mail": "fyuura01@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "Interact Committee - Комітет з питань Інтеракту",
      "Role": "Chair",
      "Name": "Yuliia Hrynchuk",
      "Club": "Lutsk Luchesk",
      "Phone": "380958330827",
      "E-mail": "Yulia_NSNU@ukr.net",
      "My rotary account": "N",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/district-committees"
    },
    {
      "Committee": "Rotaract Committee - Комітет з питань Ротаракту",
      "Role": "Chair",
      "Name": "Yuriy Mkrtchyan",
      "Club": "RC Kharkiv Mriya",
      "Phone": "380507778787",
      "E-mail": "Mkrtchian1991@gmail.com",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/district-committees"
    },
    {
      "Committee": "Rotaract Committee - Комітет з питань Ротаракту",
      "Role": "District Rotaract Representative",
      "Name": "Darya Shevtsova",
      "Club": "RAC Odesa",
      "Phone": "",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "RYLA Committee - Комітет з питань Ротарійської відзнаки молодим лідерам",
      "Role": "Chair",
      "Name": "Olena Moroz",
      "Club": "PRC Ukraine Unity",
      "Phone": "380675000021",
      "E-mail": "ellen.cdf@gmail.com",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/district-committees"
    },
    {
      "Committee": "Youth Exchange Committee - Комітет з питань молодіжних обмінів",
      "Role": "Chair",
      "Name": "Tetyana Prylipko",
      "Club": "ReC of Ukraine",
      "Phone": "380977190792",
      "E-mail": "p.tetyana.ua@gmail.com",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/district-committees"
    },
    {
      "Committee": "Youth Exchange Committee - Комітет з питань молодіжних обмінів",
      "Role": "Member",
      "Name": "",
      "Club": "",
      "Phone": "",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "Rotary Friendship Exchange - Комітет з питань дружніх обмінів",
      "Role": "Chair",
      "Name": "Svitlana Petrova",
      "Club": "RC Cherkasy-Centre",
      "Phone": "380672360611",
      "E-mail": "psvitlan@gmail.com",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/district-committees"
    },
    {
      "Committee": "Rotary Friendship Exchange - Комітет з питань дружніх обмінів",
      "Role": "Member",
      "Name": "",
      "Club": "",
      "Phone": "",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "Community Service Committee - Комітет з питань служіння громаді",
      "Role": "Chair",
      "Name": "Igor Savchykevych",
      "Club": "RC Lviv",
      "Phone": "380676734441",
      "E-mail": "savchykevych@gmail.com",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/district-committees"
    },
    {
      "Committee": "Community Service Committee - Комітет з питань служіння громаді",
      "Role": "Member",
      "Name": "Павло Філліпенко",
      "Club": "RC Kharkiv New Level",
      "Phone": "",
      "E-mail": "",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "Community Service Committee - Комітет з питань служіння громаді",
      "Role": "Member",
      "Name": "Костянтин Микичук",
      "Club": "RC Chernivtsi",
      "Phone": "30666669319",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "Community Service Committee - Комітет з питань служіння громаді",
      "Role": "Member",
      "Name": "Василь Полонський",
      "Club": "PRC Ukraine Unity",
      "Phone": "380974988503",
      "E-mail": "vpolonskyy@hallindustries.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "Community Service Committee - Комітет з питань служіння громаді",
      "Role": "Member",
      "Name": "Василь Думин",
      "Club": "RC Rakhiv - Center of Europe",
      "Phone": "380673121711",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "International Service Committee - Комітет з питань міжнародного служіння",
      "Role": "Chair",
      "Name": "Olha Paliychuk",
      "Club": "RC Cherkasy",
      "Phone": "380505207352",
      "E-mail": "oncology@2upost.com",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/district-committees"
    },
    {
      "Committee": "International Service Committee - Комітет з питань міжнародного служіння",
      "Role": "IRFHP",
      "Name": "Roman Mitsoda",
      "Club": "RC Uzhgorod-Skala",
      "Phone": "380505291336",
      "E-mail": "rmitsoda@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "International Service Committee - Комітет з питань міжнародного служіння",
      "Role": "IYFR",
      "Name": "Andriy Cherepanov",
      "Club": "RC Mykolaiv",
      "Phone": "380503183138",
      "E-mail": "cherepanov12a@yahoo.co.uk",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "International Service Committee - Комітет з питань міжнародного служіння",
      "Role": "IFFR",
      "Name": "Ihor Zembovych",
      "Club": "RC Lviv",
      "Phone": "380676708292",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "International Service Committee - Комітет з питань міжнародного служіння",
      "Role": "інші Ротарійські Товариства",
      "Name": "Тарас Гергелюк",
      "Club": "RC Ivano-Frankivsk",
      "Phone": "",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "International Service Committee - Комітет з питань міжнародного служіння",
      "Role": "бізнес та МДК",
      "Name": "Артем Ващиленко",
      "Club": "RC Mykolaiv",
      "Phone": "",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "International Service Committee - Комітет з питань міжнародного служіння",
      "Role": "культурні міжнародні зв’язки",
      "Name": "Олеся Телиженко",
      "Club": "RC Cherkasy",
      "Phone": "",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "International Service Committee - Комітет з питань міжнародного служіння",
      "Role": "дружні міжнародні зв’язки",
      "Name": "Світлана Белоус",
      "Club": "RC Cherkasy",
      "Phone": "",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "International Service Committee - Комітет з питань міжнародного служіння",
      "Role": "навчальні Ротарійські програми за кордоном",
      "Name": "Ірина Кобець",
      "Club": "RC Cherkasy",
      "Phone": "",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "International Service Committee - Комітет з питань міжнародного служіння",
      "Role": "професійні міжнародні програми",
      "Name": "Ігор Гайда",
      "Club": "RC Cherkasy",
      "Phone": "",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "International Service Committee - Комітет з питань міжнародного служіння",
      "Role": "молодіжні міжнародні зв’язки, допомога у комунікаціях з Ротаракт та Інтеракт клубами за кордоном",
      "Name": "Христина Лоза",
      "Club": "RC Ternopil",
      "Phone": "",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "International Service Committee - Комітет з питань міжнародного служіння",
      "Role": "залучення міжнародних донорів для програм післявоєнної розбудови, медійна підтримка роботи комітету",
      "Name": "Леся Джадан",
      "Club": "RC Kyiv Capital",
      "Phone": "",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "International Service Committee - Комітет з питань міжнародного служіння",
      "Role": "Task force",
      "Name": "Борис Боднар",
      "Club": "РК Україна Єдність",
      "Phone": "",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "Alumni Committee - Комітет випускників Ротарійських програм",
      "Role": "Chair",
      "Name": "Tamara Nedobor",
      "Club": "RC Kyiv-Sophia",
      "Phone": "380503133463",
      "E-mail": "ntamara@ukr.net",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/district-committees"
    },
    {
      "Committee": "Alumni Committee - Комітет випускників Ротарійських програм",
      "Role": "Member",
      "Name": "",
      "Club": "",
      "Phone": "",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "Public Image Committee - Комітет з питань публічного іміджу",
      "Role": "Chair",
      "Name": "Larysa Koba",
      "Club": "RC Poltava",
      "Phone": "380508629998",
      "E-mail": "kobalarysa8@gmail.com",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/district-committees"
    },
    {
      "Committee": "Public Image Committee - Комітет з питань публічного іміджу",
      "Role": "Member",
      "Name": "",
      "Club": "",
      "Phone": "",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "2024 District Conference Committee - Комітет з підготовки Конференції Д2232 2024р",
      "Role": "Chair",
      "Name": "Borys Bodnar",
      "Club": "PRC Ukraine Unity",
      "Phone": "380980723872",
      "E-mail": "borysbodnar@gmail.com",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/district-committees"
    },
    {
      "Committee": "District Rotary Foundation Committee - Комітет з питань Фундації Ротарі",
      "Role": "Chair",
      "Name": "Bohdan Svidovyy",
      "Club": "RC Lviv Kniazhyi",
      "Phone": "380509172078",
      "E-mail": "bohdan.svidovyy@gmail.com",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/district-committees"
    },
    {
      "Committee": "District Rotary Foundation Committee - Комітет з питань Фундації Ротарі",
      "Role": "Fundraising and Endowment Subcommittee",
      "Name": "Borys Bodnar",
      "Club": "PRC Ukraine Unity",
      "Phone": "380980723872",
      "E-mail": "borysbodnar@gmail.com",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-topic/fundraising"
    },
    {
      "Committee": "District Rotary Foundation Committee - Комітет з питань Фундації Ротарі",
      "Role": "Annual Fund Subcommittee",
      "Name": "Mark Gitelman",
      "Club": "RC Kyiv",
      "Phone": "380674016359",
      "E-mail": "mmgitelman@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "District Rotary Foundation Committee - Комітет з питань Фундації Ротарі",
      "Role": "Grants Subcommittee",
      "Name": "Olha Novytska",
      "Club": "RC Rivne",
      "Phone": "380501846830",
      "E-mail": "o.s.novytska@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "District Rotary Foundation Committee - Комітет з питань Фундації Ротарі",
      "Role": "Rotary Peace Fellowship",
      "Name": "Dirk Lustig",
      "Club": "RC Kyiv Multinational",
      "Phone": "380994707065",
      "E-mail": "dirk.lustig@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "District Rotary Foundation Committee - Комітет з питань Фундації Ротарі",
      "Role": "Polio Plus Subcommittee",
      "Name": "Sergii Zavadskyi",
      "Club": "RC Kyiv-City",
      "Phone": "380504690579",
      "E-mail": "serhiy_zavadsky@yahoo.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "District Rotary Foundation Committee - Комітет з питань Фундації Ротарі",
      "Role": "Polio Plus Subcommittee",
      "Name": "Mariana Iskiv",
      "Club": "RK Lviv Knyazhyy",
      "Phone": "380961394980",
      "E-mail": "iskivmarjana20@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "District Rotary Foundation Committee - Комітет з питань Фундації Ротарі",
      "Role": "DISTRICT SCHOLARSHIP SUBCOMMITTEE CHAIR",
      "Name": "Tetyana Prylipko",
      "Club": "ReC of Ukraine",
      "Phone": "380977190792",
      "E-mail": "p.tetyana.ua@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "District Rotary Foundation Committee - Комітет з питань Фундації Ротарі",
      "Role": "PHF Society Coordinator",
      "Name": "Svitlana Petrova",
      "Club": "RC Cherkasy-Centre",
      "Phone": "380672360611",
      "E-mail": "psvitlan@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "2024 RI Convention Promotion Committee Singapore - Комітет з промоції Конгресу РІ в Сингапурі 2024р",
      "Role": "Chair",
      "Name": "Myron Uhryn",
      "Club": "RC Lviv",
      "Phone": "380673544442",
      "E-mail": "mm_clinic@ukr.net",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/district-committees"
    },
    {
      "Committee": "District Training Committee - Комітет з питань Ротарійського навчання",
      "Role": "Chair",
      "Name": "Mark Gitelman",
      "Club": "RC Kyiv",
      "Phone": "380674016359",
      "E-mail": "mmgitelman@gmail.com",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/trainers"
    },
    {
      "Committee": "District Training Committee - Комітет з питань Ротарійського навчання",
      "Role": "Trainer PDG",
      "Name": "Mykola Steblianko",
      "Club": "ReC Ukraine",
      "Phone": "380676503018",
      "E-mail": "sniko@ukr.net",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/trainers"
    },
    {
      "Committee": "District Training Committee - Комітет з питань Ротарійського навчання",
      "Role": "Trainer PDG",
      "Name": "Vira Syriamina",
      "Club": "RC Lviv-Zamok",
      "Phone": "380677466521",
      "E-mail": "rotaryd2232ua@gmail.com",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/trainers"
    },
    {
      "Committee": "District Training Committee - Комітет з питань Ротарійського навчання",
      "Role": "Trainer, Vebinars",
      "Name": "Rostyslav Lukach",
      "Club": "RC Kyiv Capital",
      "Phone": "380503161684",
      "E-mail": "rost.lukach@gmail.com",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/trainers"
    },
    {
      "Committee": "District Training Committee - Комітет з питань Ротарійського навчання",
      "Role": "Trainer, DGE, Club trainers work",
      "Name": "Oleksandr Khardin",
      "Club": "RC Poltava",
      "Phone": "380503045443 380675340104",
      "E-mail": "okhardin@ukr.net",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/trainers"
    },
    {
      "Committee": "District Training Committee - Комітет з питань Ротарійського навчання",
      "Role": "Member",
      "Name": "Sergii Ivaglo",
      "Club": "RC Kharkiv New Level",
      "Phone": "380953162872",
      "E-mail": "serseriii@gmail.com",
      "My rotary account": "Y",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/trainers"
    },
    {
      "Committee": "District Executive Secretary - Виконавчий Секретар Дистрикту",
      "Role": "Chair",
      "Name": "Hanna Kozhyna",
      "Club": "RC Kharkiv Mriya",
      "Phone": "380677960663",
      "E-mail": "amkozhyna888@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "Rotary Bylaws and Procedure Committee - Комітет з питань правил та процедур",
      "Role": "Chair",
      "Name": "Hennadii Kroichyk",
      "Club": "RC Lviv",
      "Phone": "380676711433",
      "E-mail": "hkroichyk@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "Rotary Bylaws and Procedure Committee - Комітет з питань правил та процедур",
      "Role": "Member",
      "Name": "Olena Rynazhevska",
      "Club": "RC Lviv Zamok",
      "Phone": "380679288067",
      "E-mail": "rynazhevska@ukr.net",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "Rotary Bylaws and Procedure Committee - Комітет з питань правил та процедур",
      "Role": "Member",
      "Name": "Tetyana Kuzmich",
      "Club": "RC Kyiv",
      "Phone": "380675067535",
      "E-mail": "kuzmich432@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "Rotary Magazine \"Rotariets\" - журнал \"Ротарієць\"",
      "Role": "Editor-in-Chief",
      "Name": "Pavlo Kashkadamov",
      "Club": "RC Lviv",
      "Phone": "380677100097",
      "E-mail": "pavlo@mail.lviv.ua",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "Rotary Magazine \"Rotariets\" - журнал \"Ротарієць\"",
      "Role": "Publisher",
      "Name": "Mykola Steblianko",
      "Club": "ReC Ukraine",
      "Phone": "380676503018",
      "E-mail": "sniko@ukr.net",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "Vocational Service Committee - Rotary Business Council - Комітет з професійного служіння, Ділова Рада Д2232",
      "Role": "Chair",
      "Name": "Oleksiy Dehtyarenko",
      "Club": "RC Lviv",
      "Phone": "380673408272",
      "E-mail": "alesio79@ukr.net",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "Vocational Service Committee - Rotary Business Council - Комітет з професійного служіння, Ділова Рада Д2232",
      "Role": "Member",
      "Name": "Serhii Pylypiv",
      "Club": "RC Kharkiv International",
      "Phone": "380667120543",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
    },
    {
      "Committee": "Rotary Legacy Committee of D2232 - Комітет Ротарійської спадщини",
      "Role": "Co-Chair",
      "Name": "Myroslav Gavryliv",
      "Club": "RC Lviv",
      "Phone": "380503172280",
      "E-mail": "myroslavgavryliv@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "Rotary Legacy Committee of D2232 - Комітет Ротарійської спадщини",
      "Role": "Member",
      "Name": "Myron Uhryn",
      "Club": "RC Lviv",
      "Phone": "380673544442",
      "E-mail": "mm_clinic@ukr.net",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "Rotary Legacy Committee of D2232 - Комітет Ротарійської спадщини",
      "Role": "Co-Chair",
      "Name": "Pavlo Kashkadamov",
      "Club": "RC Lviv",
      "Phone": "380677100097",
      "E-mail": "pavlo@mail.lviv.ua",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "Rotary Art Committee - Комітет з питань мистецтва",
      "Role": "Chair",
      "Name": "Viktoriya Letenko",
      "Club": "RC Rakhiv Center Europe",
      "Phone": "380679570010",
      "E-mail": "vernigora1965@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
    },
    {
      "Committee": "District Diversity, Equity and Inclusion Committee Комітет з питань різноманітності, рівності та інклюзії",
      "Role": "Chair",
      "Name": "Maryna Gurianova",
      "Club": "RC Cherkasy International",
      "Phone": "380734701582",
      "E-mail": "respublic.ck@gmail.com",
      "My rotary account": "N",
      "Learning center": "https://my.rotary.org/en/learning-reference/learn-role/district-committees"
    },
    {
      "Committee": "Basic education, literacy and children Committee - Комітет з питань освіти, писемності та дітей",
      "Role": "Chair",
      "Name": "Iryna Odyntsova",
      "Club": "RC Dnipro Novyy",
      "Phone": "380965126310",
      "E-mail": "illy2011@i.ua",
      "My rotary account": "Y",
      "Learning center": ""
      },
      {
      "Committee": "Community economic development Committee - Комітет з питань економічного розвитку",
      "Role": "Chair",
      "Name": "Vasyl Polonskyi",
      "Club": "PRC Ukraine Unity",
      "Phone": "380974988503",
      "E-mail": "vpolonskyy@hallindustries.com",
      "My rotary account": "Y",
      "Learning center": ""
      },
      {
      "Committee": "Disease prevention and treatment Committee - Комітет з запобігання хвороб",
      "Role": "Chair",
      "Name": "Volodymyr Orlyk",
      "Club": "RC Lviv",
      "Phone": "380679844775",
      "E-mail": "orlyk49@i.ua",
      "My rotary account": "Y",
      "Learning center": ""
      },
      {
      "Committee": "Maternal and child, medical, mental and oral health Committee - Комітет з питань медицини, стоматології, ментального здоров'я та здоров'я матері та дитини",
      "Role": "Chair",
      "Name": "Hanna Kozhyna",
      "Club": "RC Kharkiv Mriya",
      "Phone": "380677960663",
      "E-mail": "amkozhyna888@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
      },
      {
      "Committee": "Peacebuilding and conflict prevention Committee - Комітет з питань миробудування та розв'язання конфліктів",
      "Role": "Chair",
      "Name": "Dirk Lustig",
      "Club": "RC Kyiv Multinational",
      "Phone": "380994707065",
      "E-mail": "dirk.lustig@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
      },
      {
      "Committee": "Water, sanitation, and hygiene Committee - Комітет з питань води, гігієни та санітарії",
      "Role": "Chair",
      "Name": "Mark Gitelman",
      "Club": "RC Kyiv",
      "Phone": "380674016359",
      "E-mail": "mmgitelman@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
      },
      {
      "Committee": "Environment Committee - Комітет з питань навколишнього середовища",
      "Role": "Chair",
      "Name": "Oksana Pelenyo",
      "Club": "PRC Ukraine Unity",
      "Phone": "380672232180",
      "E-mail": "opelenyo@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
      },
      {
      "Committee": "Environment Committee - Комітет з питань навколишнього середовища",
      "Role": "Member",
      "Name": "Serhii Pylypiv",
      "Club": "RC Kharkiv International",
      "Phone": "380667120543",
      "E-mail": "",
      "My rotary account": "",
      "Learning center": ""
      },
      {
      "Committee": "District Local authorities relationship Committee - Комітет з питань взаємодії з органами державної та місцевої влади",
      "Role": "Chair",
      "Name": "Volodymyr Bondarenko",
      "Club": "RC Ivano-Frankivsk",
      "Phone": "380505415179",
      "E-mail": "volbondarenko@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
      },
      {
      "Committee": "District Local authorities relationship Committee - Комітет з питань взаємодії з органами державної та місцевої влади",
      "Role": "Co-Chair",
      "Name": "Oleh Tuyev",
      "Club": "RC Kharkiv - Nadiya",
      "Phone": "380509088600",
      "E-mail": "o.tuyev@gmail.com",
      "My rotary account": "",
      "Learning center": ""
      },
      {
      "Committee": "District Local authorities relationship Committee - Комітет з питань взаємодії з органами державної та місцевої влади",
      "Role": "Co-Chair",
      "Name": "Mykhaylo Yermakov",
      "Club": "RC Kharkiv",
      "Phone": "380501019401",
      "E-mail": "mermakov884@gmail.com",
      "My rotary account": "",
      "Learning center": ""
      },
      {
      "Committee": "District Local authorities relationship Committee - Комітет з питань взаємодії з органами державної та місцевої влади",
      "Role": "Member",
      "Name": "Yaroslav Dumyn",
      "Club": "RC Rakhiv - Center of Europe",
      "Phone": "380673126135",
      "E-mail": "dumyn.yaroslav@gmail.com",
      "My rotary account": "",
      "Learning center": ""
      },
      {
      "Committee": "District Local authorities relationship Committee - Комітет з питань взаємодії з органами державної та місцевої влади",
      "Role": "Member",
      "Name": "Olena Sopina",
      "Club": "RC Zaporizhzhya - Khortytsya",
      "Phone": "380977893109",
      "E-mail": "Es7893109@gmail.com",
      "My rotary account": "",
      "Learning center": ""
      },
      {
      "Committee": "District Local authorities relationship Committee - Комітет з питань взаємодії з органами державної та місцевої влади",
      "Role": "Member",
      "Name": "Tamara Nedobor",
      "Club": "RC Kyiv-Sophia",
      "Phone": "380503133463",
      "E-mail": "ntamara@ukr.net",
      "My rotary account": "",
      "Learning center": ""
      },
      {
      "Committee": "District Local authorities relationship Committee - Комітет з питань взаємодії з органами державної та місцевої влади",
      "Role": "Member",
      "Name": "Andriy Hyzhko",
      "Club": "RC Vynnytsya Postup",
      "Phone": "380972807236",
      "E-mail": "apgyzhko@gmail.com",
      "My rotary account": "",
      "Learning center": ""
      },
      {
      "Committee": "District Local authorities relationship Committee - Комітет з питань взаємодії з органами державної та місцевої влади",
      "Role": "Member",
      "Name": "Serhiy Pylypiv",
      "Club": "RC Kharkiv International",
      "Phone": "380667120543",
      "E-mail": "Pilipiv@gmail.com",
      "My rotary account": "",
      "Learning center": ""
      },
      {
      "Committee": "Реєстрація та узагальнення інформації про проекти Ротарі Клубів",
      "Role": "",
      "Name": "Oleh Kosmyna",
      "Club": "RC Zaporizhzhya-Khortytsya",
      "Phone": "380996332351",
      "E-mail": "tykogm2@gmail.com",
      "My rotary account": "Y",
      "Learning center": ""
      },
      {
      "Committee": "Rotary Spirit and culture committee - Комітет духовності та культури",
      "Role": "Chair",
      "Name": "Yuliia Chabanenko",
      "Club": "RC Cherkasy",
      "Phone": "380937889999",
      "E-mail": "office@2upost.com",
      "My rotary account": "Y",
      "Learning center": ""
      }
  ]
    