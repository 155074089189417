import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import FolderIcon from '@mui/icons-material/Folder';
import { IconButton } from '@mui/material';
import { MdContentCopy } from 'react-icons/md';
import { MdEmail } from 'react-icons/md';

const API_SERVER = 'https://api.rotary.org.ua';
//const APP_SERVER = 'http://localhost:3001';
const APP_SERVER = 'https://api.rotary.org.ua';
const CERNEL_URL = '/folders';
const CERNEL_URL_3 = '/folders';
const CERNEL_URL_2 = '/photos';

const removeTrailingSlash = (url) => {
    if (url.endsWith('/')) {
      return url.slice(0, -1);
    }
    return url;
}

function ServerFolders() {
    const [folders, setFolders] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [path2, setPath2] = useState(null);
    const [urlPath, setUrlPath] = useState(null);
    //const {path} = useParams();
    const location = useLocation();
    const path = removeTrailingSlash(location.pathname).replace(CERNEL_URL, '');
    const urlPath_ = path ? `/folders/folders${path}/` : CERNEL_URL;
    const urlPathClient = path ? `/folders${path}/` : CERNEL_URL;
    const navigate = useNavigate();
    
    const photo_path = location.pathname.replace(CERNEL_URL, '');
    const photo_urlPath = photo_path ? `/folders${CERNEL_URL_2}${photo_path}` : CERNEL_URL_2;

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [path]);

    const loadData = () => {
        
        // const path2_ = '/'+path+'/';
        // const urlPath_ = path2_ ? `${CERNEL_URL}${path2_}` : CERNEL_URL;
        const path2_ = path;//location.pathname.replace(CERNEL_URL+'/', '');
        
        setPath2(path2_);
        setUrlPath(urlPath_);
        
        const url = API_SERVER+urlPath_;
        
        console.log(`fetch url=${url}`)
        
        fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log(`fetch data=${JSON.stringify(data)}`)
                setFolders(data);
            })
            .catch(error => {
                console.error('Error loading folders:', error);
            });

        fetch(`${APP_SERVER}${photo_urlPath}`)
            .then(response => response.json())
            .then(urls => {
                console.log('loadData data='+JSON.stringify(urls));
                setPhotos(urls);
            })
            .catch(error => {
                console.error('Помилка при завантаженні фотографій:', error);
            });
    }

    const handleFolderClick = (folderPath) => {
        navigate(folderPath); 
        console.log('handleFolderClick url=' + folderPath);
    };

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => alert('Copied to clipboard'))
            .catch((err) => console.error('Could not copy text: ', err));
    };

    function removeLastFolderFromUrl(url) {
        const urlWithoutTrailingSlash = url.endsWith('/') ? url.slice(0, -1) : url;
        const parts = urlWithoutTrailingSlash.split('/');
        parts.pop(); // Видаляємо останній елемент
        return parts.join('/') || '/';
    }

    const showListItemPath = (index, path_, fullPath) => {
        return (
        <ListItem button key={index} onClick={() => handleFolderClick(fullPath)}>
            <ListItemIcon>
                <FolderIcon />
            </ListItemIcon>
            <ListItemText primary={path_} />
            <IconButton onClick={() => handleCopyToClipboard(fullPath)} aria-label="copy">
                <MdContentCopy />
            </IconButton>
            {fullPath}
        </ListItem>
        )
    } 

    // Формуємо повний шлях для копіювання в буфер обміну
    const getFullPath = (folderPath) => `${urlPathClient}${folderPath}`;

    return (
        <>
        {path2 && path2 &&
            <>
            path={path}<br/>
            path2={path2}<br/>
            urlPath={urlPath}<br/>
            photo_urlPath={photo_urlPath}<br/>
            <h2>Folder Explorer</h2>
            <List>
                {path2 && showListItemPath(0, '...', removeLastFolderFromUrl(urlPathClient)) }
                {folders.map((folder, index) => showListItemPath(index,  folder, getFullPath(folder) ))}
            </List>
            </>
        }
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {photos.map((item, index) => (
                    <div key={index} style={{ margin: '10px', border: '1px solid gray' }}>
                        <img src={item} alt={`Фото ${index + 1}`} style={{ maxWidth: '200px', maxHeight: '200px' }} />
                        <IconButton onClick={() => handleCopyToClipboard(item)} aria-label="Facebook">
                        <MdEmail color="blue" size='1.5em' />
                        </IconButton>
                        url={item}
                    </div>
                ))}
                {photos && photos.length===0 && "папка без фоток"}
            </div>
        </>
    );
}

export default ServerFolders;
