import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { Box, Container, Grid, Typography, Paper} from '@mui/material'
import '../Pages.css';

import MyMainPage from "../../modules/MYMain";

const MyHomePage = () => {
    const [userProfile, setUserProfile] = useState();
    const { id } = useParams();
    const  designType='chicago';

    const onLogined = (userProfile) => {
        setUserProfile(userProfile);
        console.log("============= MyHomePage: onLogined="+(userProfile && userProfile.club));
    }

    return (
      
        <MyMainPage userProfile={userProfile} onLogined={onLogined} >
        <Container>
        <Paper>
            <Box my={4} p='24px'>
                
            <Box display="flex" flexDirection='row' gap={2} >
                <img 
                    src="https://api.rotary.org.ua/uploads/clubsitecreator/D2232/photo/1706191186331-169353556.jpg" 
                    alt="Опис зображення" 
                    style={{ width: '320px', height: '320px' }}
                />
            <Box>
                <Typography variant="h4" gutterBottom>Ласкаво просимо до Внутрішнього Сайту Ротарі в Україні!</Typography>
                <Typography variant="h6" gutterBottom>Вітаємо вас на нашому новому внутрішньому сайті Ротарі!</Typography>
                <Typography paragraph>
                    Цей сайт був розроблений з метою покращення комунікації та взаємодії між ротарійцями. Ми раді 
                    запропонувати вам першу версію сайту, яка зараз знаходиться на етапі активного тестування та розвитку.
                </Typography>
                {/*<Typography variant="subtitle1" gutterBottom>Що Ви Знайдете на Сайті?</Typography>
                <Typography paragraph>
                    - <strong>Інформаційний Центр:</strong> Останні новини, оновлення та анонси від Ротарі. <br />
                    - <strong>Ресурси для Членів:</strong> Інструменти та ресурси, що допоможуть вам у вашій ротарійській діяльності. <br />
                    - <strong>Форуми та Дискусії:</strong> Простір для обговорень та обміну думками з іншими членами спільноти. <br />
                    - <strong>Події та Зустрічі:</strong> Календар подій та інформація про зустрічі.
                </Typography>
    <Typography variant="subtitle1" gutterBottom>Ваша Участь та Відгуки</Typography>*/}
                <Typography paragraph>
                    Ваша участь та відгуки є надзвичайно цінними для нас. Ми прагнемо надавати актуальну інформацію та корисні інструменти щоб ви активно 
                    користувалися сайтом та ділитися вашими думками...
                </Typography>
                <Typography paragraph>
                    Дякуємо вам за вашу активну участь і підтримку в цьому процесі.
                </Typography>
                <Typography variant="subtitle1"><strong>З повагою, Команда Розробників Сайту</strong></Typography>
                </Box>
                </Box>
            </Box>
        </Paper>
        </Container>
        </MyMainPage>
  );
}

export default MyHomePage;