import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from '@mui/material'
import './Pages.css';

import MainPage from "../components/Main";
import LandingBlock from '../components/LandingBlock';

import RI_1 from '../assets/content/home/RI_1.png'

const ContactsPage = () => {
    return (
        <>
          <MainPage>
            <LandingBlock mode='light'>
            <Box className="section text-left" >
            
              <Container>
                  <Typography variant="h4" className="page-header">Про Ротарі Інтернейшнл</Typography>
                  <Grid container spacing={2}>
                      <Grid item md={8}>
                        <p>Ротарі Інтернейшнл є міжнародною організацією, створеною для вирішення проблем місцевих та глобальних спільнот, надання гуманітарної допомоги, просування миру та покращення життєвих умов людей. Ротарі в Україні - це частина глобальної мережі професіоналів, лідерів та людей, які бажають служити суспільству і робити світ кращим.
                        </p>
                        <h3>Наша місія
                        </h3>
                        <p>Ми об'єднуємося, щоб створити позитивні зміни. Ротарі клуби в Україні фокусуються на розвитку проектів у таких областях, як освіта, здоров'я, боротьба з бідністю, довкілля та культура. Ми прагнемо досягти реальних результатів, що можуть змінити життя людей в наших місцевих спільнотах і за їх межами.
                        </p>
                        <h3>Наше лідерство
                        </h3>
                        <p>Кожен Ротарі клуб в Україні керується принципами служіння, лідерства, інтегритету, дружби і толерантності. Ми розвиваємо лідерські якості у своїх членах, надаючи їм можливість віддавати себе служінню суспільству і приймати рішення, які формують майбутнє наших спільнот.
                        </p>
                        <h3>Наше товариство
                        </h3>
                        <p>Ми віримо в силу дружби і товариства. Ротарі клуби в Україні - це місце, де люди різного віку, професій, релігій та культур об'єднуються для реалізації спільних цілей. Ми навчаємося один від одного, обмінюємося ідеями і досвідом, робимо світ кращим разом.
                        </p>
                        <p>Якщо ви вірите в служіння, лідерство та товариство, якщо ви готові працювати заради покращення світу, ми запрошуємо вас приєднатися до нашого руху. Ротарі Інтернейшнл в Україні - це ваш шанс зробити різницю.
                        </p>
                        <p>Давайте разом робити світ кращим!
                        </p>
                      </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                      <Grid item xs={8}>
                          <img src={RI_1} width='100%' alt="" />
                      </Grid>
                  </Grid>
              </Container>
            </Box>
            </LandingBlock>
            <LandingBlock mode='dark'>
            <Box className="section section-dark2 text-center" >
              <Container>
              <Typography variant="h4" className="page-header">Про Дистрикт 2232</Typography>
                  <Grid container spacing={2}>
                  <p>Ротарі Дистрикт 2232 - це важлива частина глобального руху Ротарі Інтернейшнл в Україні.
                  Включаючи в себе багато клубів з усієї країни, Дистрикт 2232 працює заради створення позитивних змін у місцевих та міжнародних спільнотах.</p>

                  <h3>Об'єднання заради служіння</h3>
                  <p>Всі клуби Дистрикту 2232 діляться спільною місією: служінням суспільству через активізм, допомогу та покращення умов життя. Члени Ротарі клубів нашого Дистрикту працюють над вирішенням найактуальніших проблем, таких як боротьба з бідністю, підтримка освіти, здоров'я, захисту довкілля, а також просування культури і миру.</p>

                  <h3>Співпраця та товариство</h3>
                  <p>Ми віримо в силу спільної праці і важливість товариства. Ротарі Дистрикт 2232 прагне зміцнювати відносини між клубами, сприяти обміну ідеями, досвідом та ресурсами. Цей співробітничий підхід не тільки створює сильнішу мережу Ротарі, але й дозволяє нам реалізувати більші та амбітні проекти на користь наших спільнот.</p>

                  <h3>Напрацювання майбутнього</h3>
                  <p>Дистрикт 2232 прагне до виховання наступного покоління лідерів через молодіжні програми Ротарі. Ми надаємо молодим людям можливість розвивати свої лідерські навички, розуміння світу та відповідальність за довкілля і суспільство.</p>

                  <p>Ротарі Дистрикт 2232 - це не просто група клубів, а потужна мережа, яка здатна змінювати світ.
                  Ми працюємо разом, щоб досягнути спільних цілей, зберігаючи при цьому індивідуальний підхід кожного клубу до реалізації своїх проектів.</p>

                  <p>Приєднуйтесь до нас, щоб разом служити, навчатись, спілкуватись і працювати над покращенням світу. Це ваш шанс зробити різницю.</p>

                  </Grid>
                  <Grid container spacing={2}>
                      <Grid item xs={8}>
                          <img src={RI_1} width='100%' alt="" />
                      </Grid>
                  </Grid>
              </Container>
            </Box>
            </LandingBlock>
            
          </MainPage>
        </>
  );
}

export default ContactsPage;