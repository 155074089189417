import React, { useState, useEffect } from 'react';

import {  Paper} from '@mui/material';

import TDataColumnsTable from '../../components/TDataColumnsTable';

import {rotaryClubsFromRiBd} from './rotaryClubsFromRiBd';
import CrudTable from '../RC_Cherkasy/rotariansBD/adminsPages/CrudTable';

const riclubColumns = [
    { field: 'List of all clubs', headerName: 'club', type: 'lineedit', default: '' },
    { field: 'club', headerName: 'club', type: 'lineedit', default: '' },
    { field: 'clubid', headerName: 'clubid', type: 'lineedit', default: '' },
    { field: 'Is Active', headerName: 'is active', type: 'lineedit', default: '' },
    { field: 'Current Member Count', headerName: 'count', type: 'lineedit', default: '' },
];

const TestRotaryClubsFromRiBd = () => {
    const [data, setData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const d = await rotaryClubsFromRiBd();
                setData(d);
            } catch (error) {
                
                console.log("LogsMyPage error="+error);
            }
        };
        fetchData();
        
    }, [])

  const handleSubmit = async () => {
  }

  return (
    <Paper elevation={3} style={{ padding: '16px', textAlign: 'center' }}>
        LogsMyPage
        {data && <CrudTable data={data} columns={riclubColumns} />}
    </Paper>
  );
};

export default TestRotaryClubsFromRiBd;
