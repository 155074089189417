import axios from 'axios';

//const BASE_URL = 'http://localhost:3001/apibd';
const BASE_URL = 'https://api.rotary.org.ua/apibd';

export const fetchUsers = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/users`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchUser = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/user/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createUser = async (userData) => {
    console.log('=== createUser...');
    try {
        const response = await axios.post(`${BASE_URL}/users`, userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateUser = async (userId, userData) => {
    console.log('=== updateUser...');
    try {
        console.log('=== updateUser url'+`${BASE_URL}/users/${userId}`);
        const response = await axios.put(`${BASE_URL}/users/${userId}`, userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteUser = async (userId) => {
    try {
        await axios.delete(`${BASE_URL}/users/${userId}`);
    } catch (error) {
        throw error;
    }
};
