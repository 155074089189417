
import { LOCALES } from '../../../i18n/locales';

export const getRCLanding = (local) => {
    const cherkasyData = local === LOCALES.UKRAINIAN ? cherkasyData_ukr : cherkasyData_eng;
    return cherkasyData;
};

const cherkasyData_ukr = [
    {
        id: 0,
        header: 'Міссія та цінності:',
        content: [
            {LandingMessage: `"Fun Business створений, як простір розвитку дітей, підлітків та молоді . А саме для розвитку soft skills та фінансової грамотності.

            Головні цінності проекту в тому, що все навчання проходе в практичній частині, ігровій формі та на прикладах реальних бізнес- кейсів та стратегій.
            "`},
        ],
    },
    {
        id: 1,
        header: 'Напрямки:',
        content: [
            {LandingMessage: `"Простір працює одразу в декількох напрямках:
            онлайн майданчик, для мотивації та розвитку підлітків та молоді. 
            простору для проведення оффлайн тренінгів, зустрічей та майстер-майндів для спілкування дітей та молоді за інтересами.
            Заснування клубів за інтересами. Фінансові клуби, клуб Дебатів (вміння аргументувати та доносити інформацію), клуб Мафії (вміння комунікувати та керувати емоціями). Проведення ігор необхідно, як інструмент розвитку через гейміфікацію.
            проведення тематичних таборів для реабілітації, мотивації та розвитку підлітків і молоді.
            Проведення конкурсу Ідей та особистих соціальних проектів і стартапів.З менторством представників діючого бізнесу.
            "`},
        ],
    },
    {
        id: 2,
        header: 'Наші курси:',
        content: [
            {LandingMessage: `"...Від Ідеї до Стартапу
            Бізнес з Елегантністю
            Дизайн Мислення та Креативність
            Тайм Менеджмент та постановка цілі
            Комунікації та Аргументування. (відкриття клубів Дебатів та Мафії)
            Фінансова грамотність та особисті фінанси (відкриття клубів фінансової Грамотності)
            Лідерство та Бізнес етикет.
            Ораторське Мистецтво. Пітчинг та Риторика.
            Мій Перший Стартап та робота з краудфандінговими майданчиками
            Перші інвестиції
            Напрямок “Цікаві науки” (“Фізика без формул”, “Історія - це Життя”...)
            "`},
        ],
    },
    {
        id: 3,
        header: 'Найближчі заходи:',
        content: [
            {LandingMessage: "..."},
        ]
    },
    {
        id: 4,
        header: 'Наша бібліотека',
        content: [
            {LandingMessage: "..."},
        ],
    },
    {
        id: 5,
        header: 'Команда',
        content: [
            {LandingMessage: `"...Чернишова Олександра - членкіня Міжнародного Парламенту Матерів, яка представляє інтереси МПМ у Греції. Засновниця простору розвитку підлітків FUN Business. Досвід роботи в фінансовій сфері  - з 2000 року. Досвід роботи бізнес тренером з 2006 року. Робота з підлітками та молоддю, направлена на мотивацію та розвиток Soft skills з 2017 року.
            ТОП менеджер банківської системи. Автор наукових робіт по темі “Інвестиції в Іновації промисловості/фермерських господарств)
            Сертифікований працівник Європейського Банку Реконструкції та Розвитку.
            Член Rotary International Ukraine.
            "`},
        ]
    },
    {
        id: 6,
        header: 'Партнери',
        content: [
            {LandingMessage: "..."},
        ],
    },
    {
        id: 7,
        header: 'Консультація',
        content: [
            {LandingMessage: "..."},
        ],
    },
    {
        id: 8,
        header: 'Контакти',
        content: [
            {LandingMessage: "..."},
        ],
    },
];

const cherkasyData_eng = [
    {
        id: 0,
        header: 'Mission and Values:',
        content: [
            {LandingMessage: `"Fun Business is created as a space for the development of children, teenagers, and youth. Specifically for the development of soft skills and financial literacy.

            The main values of the project are that all learning takes place in a practical part, in a gaming form and with real business cases and strategies as examples.
            "`},
        ],
    },
    {
        id: 1,
        header: 'Directions:',
        content: [
            {LandingMessage: `"The space operates in several directions:
            an online platform for motivation and development of teenagers and youth.
            a space for conducting offline trainings, meetings, and masterminds for communication of children and youth by interests.
            Establishment of interest clubs. Financial clubs, Debate clubs (ability to argue and convey information), Mafia club (communication and emotion management skills). Conducting games is necessary as a development tool through gamification.
            conducting thematic camps for rehabilitation, motivation, and development of teenagers and youth.
            Conducting the Ideas and Personal Social Projects and Startups competition. With mentoring from representatives of the existing business.
            "`},
        ],
    },
    {
        id: 2,
        header: 'Our Courses:',
        content: [
            {LandingMessage: `"...From Idea to Startup
            Business with Elegance
            Design Thinking and Creativity
            Time Management and Goal Setting
            Communication and Argumentation (opening of Debate and Mafia clubs)
            Financial Literacy and Personal Finance (opening of Financial Literacy clubs)
            Leadership and Business Etiquette
            Oratory Art. Pitching and Rhetoric.
            My First Startup and working with crowdfunding platforms
            First Investments
            "Interesting Sciences" Direction ("Physics without Formulas", "History is Life"...)
            "`},
        ],
    },
    {
        id: 3,
        header: 'Upcoming Events:',
        content: [
            {LandingMessage: "..."},
        ]
    },
    {
        id: 4,
        header: 'Our Library',
        content: [
            {LandingMessage: "..."},
        ],
    },
    {
        id: 5,
        header: 'Team',
        content: [
            {LandingMessage: `"...Oleksandra Chernyshova - a member of the International Parliament of Mothers representing the interests of IPM in Greece. Founder of the FUN Business youth development space. Experience in the financial field since 2000. Business trainer experience since 2006. Work with teenagers and youth aimed at motivation and Soft skills development since 2017.
            TOP manager of the banking system. Author of scientific papers on the topic "Investments in Innovation of Industry/Farms."
            Certified employee of the European Bank for Reconstruction and Development.
            Member of Rotary International Ukraine.
            "`},
        ]
    },
    {
        id: 6,
        header: 'Partners',
        content: [
            {LandingMessage: "..."},
        ],
    },
    {
        id: 7,
        header: 'Consultation',
        content: [
            {LandingMessage: "..."},
        ],
    },
    {
        id: 8,
        header: 'Contacts',
        content: [
            {LandingMessage: "..."},
        ],
    },
];

