import { Button, Typography } from '@mui/material';

import Header from "../../../modules/Header";
import TLandingNavbar_MyStyle from "./TLandingNavbar_MyStyle";

const LandingHeader2 = ({headerData, designType, navbar}) => {

    return (
        <>
        {headerData &&
        <div style={{ position: 'relative' }}>
                {headerData.imgs && <Header images={headerData.imgs} designType={designType} />}
                <Typography 
                variant="h3" 
                style={{
                    zIndex: 1000,
                    position: 'absolute',
                    width: '100%',
                    height: '50%',
                    top: '0px',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    padding: 2,
                    _paddingTop: '50px',
                    color: 'white', // Задає колір тексту, якщо потрібно
                    backgroundColor: 'rgba(0, 0, 0, 0.2)', // Задає фон, якщо потрібно
                    fontSize: '6vw',
                    fontWeight: 'bold',
                }}
                >
                    <br/><br/> 
                    {headerData.title}
                </Typography>
                
                {headerData.button &&
                <Button 
                    variant='contained'
                    size='large'
                    sx={{ textTransform: 'none' }} 
                    onClick={() => {
                        
                            window.open(headerData.button.url, '_blank');
                            //window.scrollTo(0, 0);
                            console.log("LandingButtonLink click="+headerData.button.url)
                        
                    }}
                    style={{
                        zIndex: 1000,
                        position: 'absolute',
                        _width: '30%',
                        bottom: '5%',
                        right: '5%',
                        _transform: 'translate(-50%, -50%)',
                        background: 'rgba(255, 215, 0, 0.8)',
                        fontSize: 'max(2vw, 14pt)',
                    }}
                >
                    {headerData.button.title}
                </Button>
                }

                <img src='https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/1707318871559-665013931.png' 
                
                    style={{
                        zIndex: 1000,
                        position: 'absolute',
                        _width: '10%',
                        top: '5%',
                        left: '5%',
                        height: '20%',
                    }}
                />
                <img src='https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/1707318206588-895329449.png' 
                
                style={{
                    zIndex: 1000,
                    position: 'absolute',
                    _width: '10%',
                    top: '8%',
                    right: '5%',
                    height: '12%',
                }}
            />
            </div>
        }
        {navbar && <TLandingNavbar_MyStyle navbar={navbar} designType={'myrotary'} /> }
        </>
  );
}

export default LandingHeader2;