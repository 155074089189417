

import LandingArrContentBlock from '../../../components/LandingArrContentBlock';
import LandingContentBlock from '../../../components/LandingContentBlock';
import LandingContentBlock2 from '../fixcomponents/LandingContentBlock2';

const LandingContent = ({data, designType}) => {
    return (
        <>
        {data && data.map((item,index) => (
            <>
            {(item.content || item.img || item.cards || item.cards2) && <LandingContentBlock id={index} landItem={item} designType={designType} />}
            {item.arrcontent && <LandingArrContentBlock id={index} arrItem={item} designType={designType} />}
            {(item.content || item.img || item.imgs2 
                || item.cards3 
                || item.LandingProjects || item.LandingProjects2 || item.LandingProjects3_eclub || item.LandingProjects2_Singapore 
                || item.LandingCardEvent 
                || item.LandingChartProjects || item.LandingBdRotarians 
                || item.LandingBdRotariansPublic || item.LandingCardsRotarians || item.LandingCardsRotarians_eclub || item.LandingEventFrame 
                || item.LandingStatisticsBlock || item.LandingAreaOfFocusBlock || item.LandingAreaOfClubFocusBlock ) 
                && <LandingContentBlock2 id={index} landItem={item} designType={designType} />
            }
            </>
        ))}
        </>
    )
}

export default LandingContent;