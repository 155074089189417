import React, { useState, useEffect } from 'react';
import { Button, Box, Container, Grid, TextField, Typography } from '@mui/material';
import GoogleLoginButton from '../../modules/GoogleLogin/GoogleLoginButton';
import RegisterPage from './RegisterPage';
import PasswordReset from './PasswordReset';
import axios from 'axios'; 
import {api_is_logged_in, api_islogin} from '../../modules/RotaryLogin/rotaryAutorize';
import {sendlog} from '../../api/BdLogging/BdLogging';

const AutorizePage = ({ onLogined }) => {
    const [isRegistration, setIsRegistration] = useState(false);
    const [isResetPwd, setIsResetPwd] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState(''); // Повідомлення про статус запиту
    //const [loginError, setLoginError] = useState(null);

    const [serverResponse, setServerResponse] = useState({
        status: null,
        message: null,
        debug: null
    });

    const currentHost = onLogined ? `${window.location.protocol}//${window.location.host}` : 'https://rotary.org.ua/';

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await api_is_logged_in();
                //setServerResponse(res);
                if (res.status==='success')
                    getMembersInfo(res.email);
            } catch (error) {
                setServerResponse({
                    status: "error",
                    message: "Помилка додатку.",
                    debug: "api_is_logged_in: error="+error,
                });
                console.log("DanatePage get data list error="+error);
            }
        };
        fetchData();
        //const rotaryLoginEmail = useState(localStorage.getItem('rotaryLoginEmail'))
        
    }, [])

    const handleGoogleLogin = () => {
        // Додайте код для авторизації через Google тут
    };



    const getMembersInfo = async(email) => {
        try {
            const response2 = await axios.get(`https://api.rotary.org.ua/apibd/memberinfo?email=${email}`);
            const userProfile = {
                userName: response2.data && response2.data.length > 0 ? response2.data[0].membername : email,
                email: email,
                imgUrl: null,
                committee: null,
                club: response2.data && response2.data.length > 0 ? response2.data[0].club : null,
                logintype: "rotary.org.ua"
            };
            // Використовуйте userProfile згідно з вашою логікою
            onLogined(userProfile);
        } catch (error) {
            console.error('Помилка при запиті інформації про користувача:', error);
        }
    }

    const clearMessage = () => {
        setServerResponse({
            status: null,
            message: null,
            debug: null
        });
    }

  const handleEmailLogin = async () => { 
        clearMessage();    

        try {
            const res = await api_islogin(email, password);
            setServerResponse(res);
            if (res.status==='success')
                getMembersInfo(email);
            sendlog({project: 'Autorize', func: 'handleEmailLogin',  status: res.status, message: res.message, debud: res.debug, user: email, description: '-'});
        } catch (error) {
            setServerResponse({
                    status: "error",
                    message: "Помилка додатку.",
                    debug: "api_islogin: error="+error,
            });
            
            sendlog({project: 'Autorize', func: 'handleEmailLogin',  status: "error", message: "Помилка додатку.", debud: "api_islogin: error="+error, user: email, description: '-'});
            console.log("DanatePage get data list error="+error);
        }
  };


  const handleRegister = () => {
    // Додайте код для переходу на сторінку реєстрації тут
    setIsRegistration(true);
  };

  const handleResetPwd = () => {
    setIsResetPwd(true);
  };

  const onRegister = () => {
    setIsRegistration(false);
  };

  const onResetPwd = () => {
    setIsResetPwd(false);
  };

  const onBack = () => {
    setIsRegistration(false);
  };
  const onBackForgotPwd = () => {
    setIsResetPwd(false);
  };

  return (
    <>
        {/*<div style={{background:'lightgray'}} >
        <Typography variant="subtitle1" align="center">
            дебаг інформація
        </Typography>
        {currentHost && <div>currentHost={currentHost}</div>}
        <div>{message}</div>
        </div>*/}

      {isRegistration ? (
        <RegisterPage onRegister={onRegister} onBack={onBack} domenUrl={currentHost} />
      ) : isResetPwd ? (
        <PasswordReset onResetPwd={onResetPwd} onBack={onBackForgotPwd} domenUrl={currentHost} />
      ) : (
        <Container maxWidth="sm">
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h4" align="center" marginTop="60px">
                Авторизація
              </Typography>
            </Grid>
            {/*<Grid item xs={12}>
              <Typography variant="subtitle1" align="center">
                Швидка авторизація за допомогую гугл аккаунту:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <GoogleLoginButton onLoginSuccess={onLogined} />
              </Box>
            </Grid>
*/}
            
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="center">
                Або ввійдіть за допомогою пошти та паролю:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Пароль"
                variant="outlined"
                fullWidth
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>

            <Typography variant="subtitle1" color="red" align="center">
                {serverResponse && serverResponse.status && <>status: {serverResponse.status}<br/></>}
                {serverResponse && serverResponse.message && <>message: {serverResponse.message}<br/></>}
                {//serverResponse && serverResponse.debug && <>debug: {serverResponse.debug}<br/></>
                }
            </Typography>

            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={handleEmailLogin}
              >
                Увійти
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" align="center">
                Ще не маєте аккаунта?{' '}
                <Button color="primary" onClick={handleRegister}>
                  Зареєструйтесь
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {/* Повідомлення про статус запиту */}
              <div>{message}</div>
            </Grid>
            
            <Grid item xs={12}>
              <Typography variant="body2" align="center">
                Забули пароль?{' '}
                <Button color="primary" onClick={handleResetPwd}>
                  Відновити пароль
                </Button>
              </Typography>
            </Grid>

          </Grid>
        </Container>
      )}
    </>
  );
};

export default AutorizePage;
