import { LOCALES } from '../../../i18n/locales';
import IMG1 from '../../../assets/content/icc/Vira.jpg'

export const getEvent2Landing = (lang) => {
    return defaultData_ukr;
};

const defaultData_ukr = [
    {
        id: 0,
        header: 'Запрошення на ДТТС та ПЕТС 15-17.03.2024 в м.Полтава',
        mheader: 'Запрошення',
        murl: 'invitation',
        imgs: [
            'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Event_Pets/1707316214742-907375035.jpg',
            'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Event_Pets/1707316214743-655588866.jpg',
            'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Event_Pets/1707316214744-561323971.jpg',
        ],
        content: [
            {LandingButtonLink: "Реєстрація учасників", link: "https://forms.gle/g5KMEJYshasGqvAK7"},
            {LandingMessage2: `
            Дорогі друзі,
            
            Щиро запрошую Вас прийняти участь в навчальних заходах в місті Полтава з 15 по 17 березня 2024 року. 
            
            15 березня зранку чекаємо Голів та членів міждержавних комітетів Дистрикту на семінарі з питань роботи  МДК в Україні. Після обіду Призначених Офіцерів Ротарі та Ротаракту Д2232 на 2024-25 Ротарійський рік запрошуємо взяти участь в обов’язковому навчальному семінарі для призначених офіцерів - ДТТС (DTTS – District Training Team Seminar). 
            
            Навчальний Семінар для обраних Президентів Ротарі та Ротаракт Клубів - ПЕТС (PETS – President Elect Training Seminar) розпочнеться 16 березня о 9 ранку в конференц-залах готелю «Optima Collection  Галерея Полтава». 
            
            17 березня в неділю на заключній сесії ПЕТС буде розглядатися бюджет Дистрикту на 2024-25 рік, основні фінансові документи, підведуться підсумки семінарів та будуть вручені номінаційні грамоти Президентам Клубів. 
            
            Суботній вечір прикрасить  гала-вечеря в приємній атмосфері дружнього спілкування з благодійним аукціоном, виступом талановитих Ротарійців та Ротарійським дефіле в національному вбранні.  
            
            Хочу нагадати, що участь обраних Президентів Клубів в Семінарі ПЕТС та Призначених Офіцерів в Семінарі ДТТС є обов’язковою. У випадку поважних причин, які унеможливлюють участь наступного Президента Клубу в Семінарі, він (вона) повинні узгодити представництво та порядок індивідуального навчання з Обраним Губернатором Д2232. 
            `},
            {LandingMessage3: `ВАЖЛИВО! `},
            {LandingMessage2: `Для участі у Навчальних семінарах необхідно самостійне проходження онлайн-курсів у Ротарійському Навчальному Центрі:`},
            {LandingMessage2: `для`},
            {LandingLinkWithoutBr: "Обраних Президентів", link: "https://learn.rotary.org/members/learn/lp/442/Club%2520President%2520Basics%2520"},
            {LandingMessage2: `, для`},
            {LandingLinkWithoutBr: "Асистентів Губернатора", link: "https://learn.rotary.org/members/learn/lp/121/Assistant%2520Governor%2520Basics"},
            {LandingMessage2: `, для`},
            {LandingLinkWithoutBr: "Офіцерів Дистрикту", link: "https://learn.rotary.org/members/learn/learning_plan/view/150/district-committee-basics"},
            {LandingMessage2: `, для`},
            {LandingLinkWithoutBr: "Секретарів", link: "https://learn.rotary.org/members/learn/learning_plan/view/6/club-secretary-basics"},
            {LandingMessage2: `, для`},
            {LandingLinkWithoutBr: "Скарбників", link: "https://learn.rotary.org/members/learn/learning_plan/view/7/club-treasurer-basics"},
            {LandingMessage2: `, для`},
            {LandingLinkWithoutBr: "Офіцерів Ротарі Клубів", link: "https://learn.rotary.org/members/learn/course/internal/view/elearning/1797/vibrant-clubs-in-action"},
            {LandingMessage2: ` "Будьте Динамічним Клубом"`},
            {LandingMessage2: `
            Під час онлайн-реєстрації потрібно буде надати копію сертифіката про завершення відповідного курсу.
            
            До зустрічі в Полтаві!
            
            Сила в Єдності! Навчання в задоволення!
            
            Олександр Хардін 
            
            Губернатор Дистрикту 2232 2024-2025
            `, noCollapse: true},
            
            ],
    },
    {
        id: 1,
        header: 'Місце проведення:',
        mheader: 'Місце проведення',
        murl: 'location',
        arrcontent: [
            {
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Event_Pets/1707072019909-751354168.jpg',
                content: [
                    {LandingMessage: `
                    Бронювання номерів за телефонами:
                    +38 050 305 22 83
                    +38 0532 56 16 97
                    +38 067 531 37 21

                    Для отримання знижки - слово "Ротарі"

                    м. Полтава, готель «Галерея» - вулиця Європейська, 7, конференц зал.
                    
                    `},
                    {LandingMapFrame: "РКЧеркаси", lat:49.58934792856813, lng:34.55535215489605, zoom: 15 },
                ],
            },
            {
                header: 'Альтернативні готелі для поселення поруч',
                imgs: [
                    'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Event_Pets/1707768388455-129771562.png'
                    //'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Event_Pets/1707319871163-694465041.jpg',
                    //https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Event_Pets/1707320051150-207544229.jpg',
                ],
                content: [
                    {LandingMessage: `Готель «Atmosphere»(Атмосфера) - 200м`},
                    {LandingButtonLink: "Бронювання на сайті готелю", link: "https://atmosphere.everest.pl.ua/"},
                    {LandingMessage: `
                    Готель «Аристократ» - 500м`},
                    {LandingButtonLink: "Бронювання на сайті готелю", link: "https://aristokrat.poltava.ua/"},
                    {LandingMessage: `
                    Готель «Premier Hotel Palazzo» - 900м`},
                    {LandingButtonLink: "Бронювання на сайті готелю", link: "https://palazzo-hotel.phnr.com/ua"},
                    {LandingMessage: ` 
                    
                    `},
                    //{LandingMapFrame: "РКЧеркаси", lat:49.58934792856813, lng:34.55535215489605, zoom: 15 },
                ],
            },
        ]
    },
    {
        id: 2,
        header: 'Рамкова програма подій - 15-17.03.2024 ',
        mheader: 'Програма',
        murl: 'program',
        arrcontent: [
            {
                content: [
                    {LandingDownloadButton: `Скачати повну програму на три дні`, 
                        link: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Event_Pets/1710313474242-484790980.pdf'},
                    {LandingMessage: `
                    Місце проведення: м. Полтава, вул. Європейська,  7, готель «Optima Collection Галерея Полтава», Конференц-зали`,},
                    
                    {LandingMessage: `П’ятниця, 15 березня 2024 р. `,}, 
                    {LandingTable: "true", 
                        columns: [
                            { field: 'time', headerName: 'Час', type: 'lineedit', default: '' },
                            { field: 'title', headerName: 'Назва', type: 'lineedit', default: '' },
                            { field: 'location', headerName: 'Локація', type: 'lineedit', default: '' },
                        ],
                        data: [
                        {
                        "time": "9.00-18.00",
                        "title": "Реєстрація Учасників Семінарів",
                        "location": "Фоє готелю «Optima Collection Галерея Полтава»,"
                        },
                        {
                        "time": "10.00–13.00",
                        "title": "Семінар з питань роботи Міждержавних Комітетів в Україні (за окремою програмою)",
                        "location": "Конференц зал готелю"
                        },
                        {
                        "time": "13.00-14.00",
                        "title": "Обід",
                        "location": "Ресторан готелю"
                        },
                        {
                        "time": "14.00–18.00",
                        "title": "Семінар для призначених Офіцерів Д2232",
                        "location": "Готель"
                        },
                        {
                        "time": "17:45 –18:15",
                        "title": "Кава",
                        "location": "Конференц-зал готелю"
                        },
                        {
                        "time": "19.00-21.00",
                        "title": "Вистава «Остання любов Гетьмана»",
                        "location": "Полтавський академічний музично-драматичний театр імені М.В. Гоголя, вул. Соборності 23."
                        },
                        {
                        "time": "20.00–22.00",
                        "title": "Дружня вечеря з РК Полтава",
                        "location": "Ресторан готелю"
                        },
                        ],
                    },
                    {LandingButtonLink: "Детальніше", link: "/pets24/date15"},
                    {LandingMessage: `
                    Субота, 16 березня 2024 р. `,},
                    {LandingTable: "true", 
                        columns: [
                            { field: 'time', headerName: 'Час', type: 'lineedit', default: '' },
                            { field: 'title', headerName: 'Назва', type: 'lineedit', default: '' },
                            { field: 'location', headerName: 'Локація', type: 'lineedit', default: '' },
                        ],
                        data: [
                        {
                        "time": "08:30–09:00",
                        "title": "Реєстрація Учасників Семінарів",
                        "location": "Фоє готелю «Optima Collection Галерея Полтава»,"
                        },
                        {
                        "time": "9.00 – 13.45",
                        "title": "ПЕТС",
                        "location": "Конференц зал готелю"
                        },
                        {
                        "time": "9.00 – 13.45 ",
                        "title": "ДТТС",
                        "location": "Конференц зал готелю"
                        },
                        {
                        "time": "13.45 - 14.30",
                        "title": "Обід",
                        "location": "Ресторан готелю"
                        },
                        {
                        "time": "14.30 – 18.00",
                        "title": "ПЕТС, ДТТС",
                        "location": "Конференц-зал готелю"
                        },
                        {
                        "time": "19.00 - 22.30",
                        "title": "Гала вечір",
                        "location": "Ресторан готелю"
                        },
                        ],
                    },
                    {LandingButtonLink: "Детальніше", link: "/pets24/date16"},
                    {LandingMessage: `
                    Неділя, 17 березня 2024 р. `,},
                    {LandingTable: "true", 
                        columns: [
                            { field: 'time', headerName: 'Час', type: 'lineedit', default: '' },
                            { field: 'title', headerName: 'Назва', type: 'lineedit', default: '' },
                            { field: 'location', headerName: 'Локація', type: 'lineedit', default: '' },
                        ],
                        data: [
                        {
                        "time": " 9.00 - 9.55",
                        "title": "Реєстрація Учасників ПЕТС, видача мандатів",
                        "location": "Конференц зал готелю"
                        },
                        {
                        "time": "10.00 – 13.00",
                        "title": "ПЕТС, розгляд проекту буджету, вручення номінаційних сертифікатів Президентам",
                        "location": "Конференц зал готелю"
                        },
                        {
                        "time": "13.00 – 15.00",
                        "title": "Пішохідна екскурсія «Магія Полтави»",
                        "location": "Збір в фойє готелю "
                        },
                        ],
                    },
                    {LandingButtonLink: "Детальніше", link: "/pets24/date17"},
                ],
            },
        ]
    },
    {
        id: 3,
        header: 'Гала-вечір',
        mheader: 'Гала-вечір',
        murl: 'date16gala',
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Event_Pets/1707585722859-793879670.jpg',
                header: 'Гала-вечір',
                content: [
                    {LandingMessage: `Шановні Друзі,
        
                    Щиро запрошуємо Вас прийняти участь в Гала-вечорі, який відбудеться 16 березня 2024 року в ресторані готелю «Оптіма Галерея Полтава». Початок вечора о 19.00.  Дрес код – український національний костюм. 
                    
                    Ротарійці з РК Полтава гарантують приємний вечір, гарне спілкування, позитивні емоції, щирість та гостинність. В програмі вечора передбачено благодійний аукціон, Ротарійське дефіле, визначення найкращого жіночого та чоловічого костюму, музичні виступи та приємні сюрпризи.  
                    
                    Раді будемо всіх бачити у нас в Полтаві! До зустрічі! 
                    
                    Сила в Єдності! Відпочинок в задоволення! 
                    
                    Ваш, РК Полтава`, noCollapse: true},
                ]
    },
    {
        id: 3,
        header: 'Організартори:',
        mheader: 'Організартори',
        murl: 'organizers',
        arrcontent: [
            {
                id: 1,
                cards: [
                    {
                        rotarianId: 18,
                    },
                    {
                        rotarianId: 20,
                    },
                    {
                        rotarianId: 19,
                    },
                    {
                        id: 1,
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Event_Pets/1707083019014-230422714.jpg',
                        content: [
                            {LandingLink: ""},
                        ],
                        name: `Ротарі Клуб Полтава`,
                        fb: 'https://www.facebook.com/rotaryclubpoltava',
                    },
                ]
            },
        ]
    },
    {
        id: 3,
        header: 'П’ятниця, 15 березня 2024 р.',
        mheader: null,
        murl: 'date15',
        arrcontent: [
            {
                //header: `П’ятниця, 15 березня 2024 р. `,
                //img: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/265279946.jpg?k=6f1c4b5b01e03425ec2f78275405827bcbc0bec3348d306d8519e37e74a9eae0&o=&hp=1',
                content: [
                    
                ]
            },
            {
                content: [
                    
                    {LandingMessageTitle: `Навчальний семінар з питань роботи міждержавних комітетів  Д2232-Україна
                    `},

                    {LandingTable: "true", 
                        columns: [
                            { field: 'time', headerName: 'Час', type: 'lineedit', default: '' },
                            { field: 'title', headerName: 'Назва', type: 'lineedit', default: '' },
                            { field: 'speakers', headerName: 'Спікери', type: 'lineedit', default: '' },
                        ],
                        data: [
                        {
                        "time": "09:00 – 18:00",
                        "title": "Загальна реєстрація учасників семінарів - Готель, стійка реєстрації та інформації",
                        "speakers": ""
                        },
                        {
                        "time": "10:00 – 10:10",
                        "title": "Відкриття семінару, хвилина мовчання, гімн України, гімн Ротарі, представлення програми та доповідачів, вступне слово Губернатора Дистрикту 2232, привітання від Клубу-організатора",
                        "speakers": "Мирон Угрин, ДГ Д2232, РК Львів; Віра Сиряміна, ПДГ, АРК; Олександр Григоренко, Президент РК Полтава"
                        },
                        {
                        "time": "10:10 - 10:20",
                        "title": "Розвиток МДК – один з пріоритетів Дистрикту 2232 в Стратегічному плані 2023-26. Завдання та роль МДК в реаліях сьогодення",
                        "speakers": "Олександр Хардін, ДГЕ, Голова Координаційного Комітету Д2232"
                        },
                        {
                        "time": "10:20 – 10:35",
                        "title": "Огляд діяльності МДК в масштабах РІ. Інформаційні джерела",
                        "speakers": "Віра Сиряміна, ПДГ"
                        },
                        {
                        "time": "10:35 – 10:50",
                        "title": "Загальний огляд діяльності МДК в Україні. Аналіз, проблеми, перспективи",
                        "speakers": "Віра Сиряміна, ПДГ"
                        },
                        {
                        "time": "10:50 – 11:10",
                        "title": "Порядок утворення нового МДК – від ідеї до реалізації проектів",
                        "speakers": "Сергій Завадський, ПДГ, АРК, РК Київ-Сіті"
                        },
                        {
                        "time": "11:10 – 12:30",
                        "title": "Дискусія: Приклади злагодженої роботи, можливості, плани, партнерські Клуби",
                        "speakers": "Голови МДК в Україні"
                        },
                        {
                        "time": "12:30-12:50",
                        "title": "Дискусія: як залучити? Як активізувати? Як використати можливості?",
                        "speakers": "Модератор: Ростислав Лукач, Тренер Д2232"
                        },
                        {
                        "time": "12:50 – 13:00",
                        "title": "Підведення підсумків, спільне фото, закриття семінару",
                        "speakers": "Мирон Угрин, Віра Сиряміна"
                        },
                        {
                        "time": "13:00 – 14:00",
                        "title": "Обід в ресторані готелю",
                        "speakers": ""
                        }
                        ]
                    },
                    {LandingMessageTitle: `
                    Навчальний семінар Офіцерів Дистрикту ДТТС`},
                    {LandingTable: "true", 
                        columns: [
                            { field: 'time', headerName: 'Час', type: 'lineedit', default: '' },
                            { field: 'title', headerName: 'Назва', type: 'lineedit', default: '' },
                            { field: 'speakers', headerName: 'Спікери', type: 'lineedit', default: '' },
                        ],
                        data: [
                        {
                        "time": "14:00 – 14:10",
                        "title": `Вітальне слово Губернатора Дистрикту, 
                        Представлення Обраного Губернатора
                        `,
                        "speakers": `Губернатор Дистрикту Д2232
                        Мирон Угрин, РК Львів
                      `
                        },
                        {
                        "time": "14:10 – 14:30",
                        "title": `Представлення Асистентів Губернатора  та 
                        Голів Комітетів Д2232 на 2024—2025 Ротарійський  рік
                        `,
                        "speakers": `Обраний Губернатор Д2232
                        Олександр Хардін, РК Полтава
                        `
                        },
                        {
                        "time": "14:30 – 15:00",
                        "title": `Встановлення робочих зв'язків в команді  .
                        Інтерактивне навчання з комунікаційної та командної роботи
                        `,
                        "speakers": `Дмитро Ляховецький,
                        РК Харків -Малтінешнл
                       `
                        },
                        {
                        "time": "15:00 - 16:00",
                        "title": `ДИСКУСІЙНА СЕСІЯ №1

                        Кімната 1 : Бути Асистентом Дистрикту (сайт РІ, 
                            комунікація Асистентів з Клубами). 
                             
                        Кімната 2 : Бути Головою Дистриктного Комітету 
                            (разом з Ротарактом)    
                            
                        `,
                        "speakers": `Секретар Дистрикту Ганна Кожина, РК Харків Мрія

                        ПДГ Сергій Завадський, РК Київ Сіті
                       Тренер Дистрикту Ростислав Лукач, РК  Київ Столиця
                       `
                        },
                        {
                        "time": " 16:00 – 16:20  ",
                        "title": `Перерва на каву`,
                        "speakers": ``
                        },
                        {
                        "time": "16:30 – 17:30",
                        "title": `  ДИСКУСІЙНА СЕСІЯ №2 (спільна) 
                        Постановка цілей  :
                        - District Health Check ( Kahoot );
                        - Брейнштормінг «Мій рік».
                        `,
                        "speakers": `Ганна Кожина, Сергій Завадський, Ростислав Лукач, 
                        ПДГ Віра Сиряміна, РК Львів Замок
                        `
                        },
                        {
                        "time": "17:30 – 17:45",
                        "title": `ЗАКЛЮЧНА  ПЛЕНАРНА СЕСІЯ першого дня   

                        Підведення підсумків
                        `,
                        "speakers": `Олександр Хардін`
                        },
                        {
                        "time": " 17:45 – 18:15",
                        "title": `Перерва на каву                        
                        `,
                        "speakers": ``
                        },
                        {
                        "time": "19:00 – 21:00",
                        "title": `«Остання любов Гетьмана» - історична вистава`,
                        "speakers": `Полтавський академічний музично-драматичний театр імені М.В. Гоголя, вул. Соборності, 23`
                        },
                        {
                        "time": "20:00 -22:00",
                        "title": `Дружня вечеря з РК Полтава`,
                        "speakers": `Ресторан Готелю`
                        },
                        ]
                    }
                ],
            }
        ]
    },    
    {
        id: 3,
        header: 'Субота, 16 березня 2024 р.',
        mheader: null,
        murl: 'date16',
        arrcontent: [
            {
                content: [
                    {LandingMessageTitle: `Навчальний семінар для обраних президентів Ротарі Клубів (ПЕТС), продовження ДТТС.`},
                    {LandingTable: "true", 
                        columns: [
                            { field: 'time', headerName: 'Час', type: 'lineedit', default: '' },
                            { field: 'title', headerName: 'Назва', type: 'lineedit', default: '' },
                            { field: 'speakers', headerName: 'Спікери', type: 'lineedit', default: '' },
                        ],
                        data: [
                        {
                        "time": "08:30 – 09:00",
                        "title": `Реєстрація учасників `,
                        "speakers": ``
                        },
                        {
                        "time": "09:00 – 09:10",
                        "title": `Відкриття семінару. Гімн Ротарі. 
                        Ознайомлення з програмою та завданнями Навчальних Семінарів
                        `,
                        "speakers": `Ганна Кожина`
                        },
                        {
                        "time": "09:10 – 09:20",
                        "title": `Представлення  Олександра Хардіна, Обраного Губернатора Дистрикту 2232 (ДГЕ) на 2024-2025 Ротарійський рік 
                        `,
                        "speakers": `ДГ Мирон Угрин`
                        },
                        {
                        "time": "09:20 – 10:35",
                        "title": `Представлення Обраного Президента РІ Стефані Урчик
                        та її відео-привітання до учасників Семінару. Тема року.
                        `,
                        "speakers": `ДГЕ Олександр Хардін`
                        },
                        {
                        "time": "09:35 – 09:50",
                        "title": `Презентація пріоритетів Стратегічного плану РІ та Д2232 на 2023-2026 Ротарійські роки`,
                        "speakers": `ДГЕ Олександр Хардін`
                        },
                        {
                        "time": "09:50 – 10:20",
                        "title": `Представлення команди Офіцерів Дистрикту 2232 у 2024-2025 Ротарійському році, вручення сертифікатів`,
                        "speakers": `ДГЕ Олександр Хардін`
                        },
                        {
                        "time": "10:20 – 10:30",
                        "title": `Спільне фото`,
                        "speakers": ``
                        },
                        {
                        "time": "10:40 - 11:40",
                        "title": `ДИСКУСІЙНІ СЕСІЇ №1
                        Обрані Президенти та Офіцери  РК , Офіцери Дистрикту - 
                        4 кімнати згідно розподілу 
                        - Планування (стратегія) наступного року:
                        - План Дій Ротарі
                        - Створення плану дій для Клубу

                        Ротаракт – Стратегічна сесія №1
                        `,
                        "speakers": `Сергій Завадський, Ростислав Лукач,
                        ПДГ Геннадій Кройчик , РК Львів 
                        ПДГ Микола  Стеблянко, РеК Україна
                        за участі Асистентів Губернатора
                        
                        Віра Сиряміна, 
                        Голова Комітету з РАК, Юрій Мкртчян, РК Харків Мрія
                        ДРР Олексій Завеля, РАК Харків Малтінешнл`
                        },
                        {
                        "time": "11:40 – 12:00",
                        "title": `Перерва на каву `,
                        "speakers": ``
                        },
                        {
                        "time": "12:10 – 12:55",
                        "title": `ДИСКУСІЙНІ СЕCІЇ №2
                        
                        Обрані Президенти та Офіцери  РК та РАК  -
                        4 кімнати згідно розподілу

                        Підготовка до лідерства у Клубі :
                        - Внутрішні та зовнішні комунікації Президента
                        - Обговорення лідерських ситуацій в Клубі
                        `,
                        "speakers": `Ганна Кожина,Сергій Завадський,
                        Микола Стеблянко, Віра Сиряміна
                        `
                        },
                        {
                        "time": "12:10 – 13:45",
                        "title": `ДИСКУСІЙНІ СЕCІЇ №2
                        
                        Офіцери Дистрикту (Велика зала):
                        Досягнення та виклики поточного року
                        `,
                        "speakers": `Мирон Угрин, Олександр Хардін, Ростислав Лукач`
                        },
                        {
                        "time": "13:00 – 13:45",
                        "title": `ДИСКУСІЙНІ СЕСІЇ №3
                        
                        Обрані Президенти та Офіцери  РК та РАК  -
                        4 кімнати згідно розподілу
                        Проведення засідань: 
                        -	Ведення засідання
                        -	Обговорення формату засідань
                        `,
                        "speakers": `Ганна Кожина, Сергій Завадський, Геннадій Кройчик, Микола Стеблянко за участі Асистентів Губернатора`
                        },
                        {
                        "time": "13:45 – 14:30",
                        "title": `ОБІД`,
                        "speakers": ``
                        },
                        {
                        "time": "14:35 – 14.55",
                        "title": `ДРУГА ПЛЕНАРНА СЕСІЯ (Велика зала)
                        
                        Керівні документи Ротарі для управління Клубом`,
                        "speakers": `Геннадій Кройчик`
                        },
                        {
                        "time": "14:55 – 15.10",
                        "title": `Співпраця з молоддю: Ротаракт, Інтеракт`,
                        "speakers": `Юрій Мкртчян, 
                        Голова Комітету ІАК Юлія Гринчук, РК Луцьк Лучеськ
                        `
                        },
                        {
                        "time": "15:10 – 15.25",
                        "title": `Пожертви до Фундації Ротарі, проєкт «Хартія Солідарності»`,
                        "speakers": `Голова Комітету з ФР Богдан Свідовий, РК Львів Княжий
                        Тренер Дистрикту Сергій Івагло, РК Харків Нью Левел
                        `
                        },
                        {
                        "time": "15:25 – 15.50",
                        "title": `Комунікація з Дистриктом: досвід роботи та висновки (панельна дискусія)`,
                        "speakers": `Мирон Угрин, Асистенти Губернатора: Олександр Чупилко, Андрій Гижко, Ярослав Думин, Ольга Новицька
                        Модератор: Олександр Хардін
                        `
                        },
                        {
                        "time": "15:50 – 15.55",
                        "title": `Кредо Банк – надійний стратегічний партнер `,
                        "speakers": `Кредо Банк`
                        },
                        {
                        "time": "15:55 – 16.10",
                        "title": `Kahoot: Що ти знаєш про Ротарі?`,
                        "speakers": `Віра Сиряміна`
                        },
                        {
                        "time": "16:15 – 17:10",
                        "title": `ДИСКУСІЙНІ СЕСІЇ №4
                        Обрані Президенти та Офіцери  РК та РАК, Офіцери Дистрикту - 4 кімнати згідно розподілу
                        Членство та Фінанси Клубу
                        -       Обговорення клубних ситуацій
                        `,
                        "speakers": `Сергій Завадський, Ростислав Лукач, Микола Стеблянко, Геннадій Кройчик  за участі Асистентів Губернатора`
                        },
                        {
                        "time": "17:15 – 18:00",
                        "title": `ДИСКУСІЙНІ СЕКСІЇ №5
                        
                        Обрані Президенти та Офіцери  РК, Офіцери Дистрикту
                        3 кімнати згідно розподілу 
                        Онлайн-інструменти та ресурси Ротарі 

                        Ротаракт - Стратегічна сесія №2

                        `,
                        "speakers": `Віра Сиряміна, Ганна Кожина, Секретар Дистрикту 24-25 Олександр Стасюк, РК Харків Малтінешнл 
                        за участі Асистентів Губернатора
                        
                        Сергій Завадський
                        `
                        },
                        {
                        "time": "19.00 –22:30",
                        "title": `Гала-вечеря`,
                        "speakers": ``
                        },
                    ]
                },
                ]
            },
        ]
    },
    {
        id: 3,
        header: 'Неділя, 17 березня 2024 р.',
        mheader: null,
        murl: 'date17',
        design: 'chicago',
        content: [
            
            {LandingMessageTitle: `Продовження ПЕТС`},
            {LandingTable: "true", 
                columns: [
                    { field: 'time', headerName: 'Час', type: 'lineedit', default: '' },
                    { field: 'title', headerName: 'Назва', type: 'lineedit', default: '' },
                    { field: 'speakers', headerName: 'Спікери', type: 'lineedit', default: '' },
                ],
                data: [
                {
                "time": "09:00 – 09:55",
                "title": `Реєстрація обраних Президентів, отримання мандатів`,
                "speakers": ``
                },
            {
            "time": "10:00 – 10.10",
            "title": `Відкриття пленарної сесії, Конференція Д2232, Конгрес РІ 
            Сінгапур та Калгарі, Календар Дистрикту 2024-25 `,
            "speakers": `Віра Сиряміна`
            },
            {
            "time": "10:10 – 10.25",
            "title": `Ротарі – дітям Героїв! `,
            "speakers": `Роберт Мкртчян, РК Харків Сіті`
            },
            {
            "time": "10:25 – 10:35",
            "title": `Вибір Голови та Секретаря . Вибір лічильної комісії. Оголошення  результатів  реєстрації.`,
            "speakers": `Геннадій Кройчик`
            },
            {
            "time": "10:35 – 12:00",
            "title": `Розгляд «Правил формування Фонду Дистрикту 2232 
            та порядок використання його коштів на 2024-2025 ротарійський рік», проєкту Бюджету Д2232 та його затвердження  .
            Затвердження розміру Дистриктального внеску.
            Обрання Аудиторів Д2232 на 2024-25
            `,
            "speakers": `Голова фінансового комітету 23-24 Юрій Мельник, РК Львів
            Голова фінансового комітету 24-25 Ярослав Думин, РК Рахів Центр Європи 
            `
            },
            {
            "time": "12:00 – 12:30",
            "title": `Журнал "РОТАРІЄЦЬ" в році 2024-2025: презентація діяльності журналу та оновленої системи передплати`,
            "speakers": `Микола Стеблянко`
            },
            {
            "time": "12:30 – 12:50",
            "title": `Вручення сертифікатів Обраним Президентам Ротарі Клубів, закриття семінарів`,
            "speakers": `Олександр Хардін`
            },
            {
            "time": "12:50 - 13:00",
            "title": `Організаційні питання, оголошення
            Підведення підсумків та закриття семінарів. `,
            "speakers": `Організаційний комітет
            Мирон Угрин, Олександр Хардін
            `
            },
            {
            "time": "13.00 –15:00",
            "title": `Екскурсія « Магія Полтави»`,
            "speakers": ``
            },
        ]
        },
        {LandingMessage: `* У програмі можливі зміни. На випадок повітряної тривоги в готелі є укриття.`},
        ]
    },
]
