import React, { useEffect, useState, useContext, useRef  } from "react";
import { useParams } from 'react-router-dom';
import { Container, Box, Typography, Avatar, Tab, Tabs, Paper } from '@mui/material';

import {getRotariansBD} from './getRotariansBD';
import {getClubPageStructure} from './getClubPageStructure';
import { LOCALES } from '../../../i18n/locales';
import {LocalContext} from '../../../tools/LanguageContextProvider';

import TykoLanding from '../components/TykoLanding';
import LandingContent from '../components/LandingContent';
import TLandingFooter from '../components/TLandingFooter';
import LandingRotarianCard from '../components/LandingRotarianCard';
import LandingBlock from '../../../components/LandingBlock';
import { fetchUsers } from '../rotariansBD/api/api_users';

const RotarianContactsPage = () => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [data, setData] = useState([]);
    const [user, setUser] = useState([]);
    const [navbar, setNavbar] = useState(null);
    const [footer, setFooter] = useState(null);
    const { id } = useParams();

    const  designType='chicago';

    useEffect(() => {
      const fetchData = async () => {
        try {
            await loadData(currentLocale);
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale, id])

    const loadData = async(locale) => {
        const d = await fetchUsers(id);
        //const d = await getRotariansBD(locale);
        setData( d );

        const guid = id==='rdrpo' ? 103 : Number(id);
        const item = d && d.find(item => item.user_id === guid);
        setUser(item);

        const page = getClubPageStructure(locale);
        setNavbar(page.navbar);
        setFooter(page.footer);

        console.log("DanatePage get data list success. const="+d.length);
    }

    const isEvenId = false;
    let mode;
    switch (designType) {
        case 'business':
            mode = isEvenId ? "light2" : "dark2";
            break;
        case 'chicago':
            mode = isEvenId ? "light3" : "dark3";
            break;
        case 'simple':
        case 'simple2':
            mode = isEvenId ? "light5" : "dark3";
            break;
        default:
            mode = isEvenId ? "light2" : "light";
    }

    return (
        <>
        {/* id={id}<br/>
        user={JSON.stringify(user)}<br/>
        data={JSON.stringify(data)}<br/> */}

        {navbar ?
        <TykoLanding navbar={navbar} noWarningMode="hide" designType={"business"} >

        <LandingBlock mode={mode}>
                    <Box className="section text-left">
                        <Container>
        <LandingRotarianCard people={user} ispublic={'true'} />
        
        </Container>
            </Box>
        </LandingBlock>


        </TykoLanding> : "Loading..."}

        </>
  );
}

export default RotarianContactsPage;