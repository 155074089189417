export const menuFromLandingBlock = (data) => {
    //console.log("========= data="+JSON.stringify(data))
    
    const menu = data && data.map((item, index) => ({
        title: item.mheader,
        lref: '/pets24/'+item.murl,
    }));
    console.log("========= menu="+JSON.stringify(menu))

    return menu; // Додаємо повернення змінної menu
}
