import React, { useRef } from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

const TextFieldFile = ({ label, value, onChange, ...otherProps }) => {
  const fileInputRef = useRef();

  const handleFileOpen = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      onChange(e.target.files[0].name);
    }
  };

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <FormControl variant="outlined" fullWidth margin="normal">
      <InputLabel htmlFor="url-file">{label}</InputLabel>
      <OutlinedInput
        id="url-file"
        type="text"
        value={value}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleFileOpen}>
              <FolderOpenIcon />
            </IconButton>
          </InputAdornment>
        }
        label={label}
        {...otherProps}
      />
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </FormControl>
  );
};

export default TextFieldFile;
