import {URL_TO_API_SERVER} from './env';
const API_URL = URL_TO_API_SERVER+"materials_seminars.php"; // змініть на ваш URL

export type Seminar = {
    id?: number;
    rotaryYear: string;
    date: string;
    sity: string;
    title: string;
    fileUrl: string;
    websiteUrl: string;
};

export async function getSeminars(): Promise<Seminar[]> {
    const response = await fetch(API_URL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
}

export async function getSeminarById(id: number): Promise<Seminar> {
    const response = await fetch(`${API_URL}?id=${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
}

export async function createSeminar(data: Seminar): Promise<void> {
    const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableType: 'create',
            data: data,
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const result = await response.json();
    console.log('Server response:', result);
}

export async function updateSeminar(data: Seminar): Promise<void> {
    if (!data.id) throw new Error("ID is required to update material.");

    const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableType: 'update',
            data: data,
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
}

export async function deleteSeminar(id: number): Promise<void> {
    const response = await fetch(`${API_URL}?id=${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableType: 'delete',
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
}
