export const getSatelitClubs = () => {
    return defaultData;
};

const defaultData = [
    {
      SatelliteClubName: "Cherkasy Sky",
      SponsoringClub: "Cherkasy",
      SponsoringClubPresident: "Viktor Vovk",
      SponsoringClubPresidentEmail: "vovk-viktor@ukr.net",
      MemberCount: 1
    },
    {
      SatelliteClubName: "E-Club of Ukraine Jointly",
      SponsoringClub: "E-Club of Ukraine",
      SponsoringClubPresident: "Dr. Bogdan Bozhuk",
      SponsoringClubPresidentEmail: "b.bozhuk@gmail.com",
      MemberCount: 8
    },
    {
      SatelliteClubName: "Ivano-Frankivsk-Kolomyia",
      SponsoringClub: "Ivano-Frankivsk",
      SponsoringClubPresident: "Igor Romanovych Mykytyuk",
      SponsoringClubPresidentEmail: "mykytyuk70@gmail.com",
      MemberCount: 15
    },
    {
      SatelliteClubName: "Kharkiv-City Family",
      SponsoringClub: "Kharkiv-City",
      SponsoringClubPresident: "Vitalii Nagornyi",
      SponsoringClubPresidentEmail: "vnagorniy7770@gmail.com",
      MemberCount: 8
    },
    {
      SatelliteClubName: "Kharkiv Nadiya Kamianets",
      SponsoringClub: "Kharkiv Nadiya",
      SponsoringClubPresident: "Iuliia Pavichenko",
      SponsoringClubPresidentEmail: "yulia.pavichenko@gmail.com",
      MemberCount: 4
    },
    {
      SatelliteClubName: "Kharkiv Nadiya Skhid",
      SponsoringClub: "Kharkiv Nadiya",
      SponsoringClubPresident: "Iuliia Pavichenko",
      SponsoringClubPresidentEmail: "yulia.pavichenko@gmail.com",
      MemberCount: 2
    },
    {
      SatelliteClubName: "Kharkiv New Level Pyvden'",
      SponsoringClub: "Kharkiv New Level",
      SponsoringClubPresident: "Kseniia Spiridonova",
      SponsoringClubPresidentEmail: "toothmagics@gmail.com",
      MemberCount: 12
    },
    {
      SatelliteClubName: "Kharkiv New Level Universe",
      SponsoringClub: "Kharkiv New Level",
      SponsoringClubPresident: "Kseniia Spiridonova",
      SponsoringClubPresidentEmail: "toothmagics@gmail.com",
      MemberCount: 16
    },
    {
      SatelliteClubName: "Kyiv-Capital Kryvyi Rih",
      SponsoringClub: "Kyiv-Capital",
      SponsoringClubPresident: "Christina Famova",
      SponsoringClubPresidentEmail: "famova.advokat@gmail.com",
      MemberCount: 8
    },
    {
      SatelliteClubName: "Rivne Lutsk Lubart",
      SponsoringClub: "Rivne",
      SponsoringClubPresident: "Olena Korsun",
      SponsoringClubPresidentEmail: "cfpoglad@gmail.com",
      MemberCount: 1
    },
    {
      SatelliteClubName: "Uzhgorod-Mukachevo",
      SponsoringClub: "Uzhgorod",
      SponsoringClubPresident: "Olga Travina",
      SponsoringClubPresidentEmail: "uzhgorod.invest@ukr.net",
      MemberCount: 13
    },
    {
      SatelliteClubName: "Veliatyno International Kyiv Pectoral",
      SponsoringClub: "Veliatyno International",
      SponsoringClubPresident: "Maryna Tarkanii",
      SponsoringClubPresidentEmail: "marusja024@gmail.com",
      MemberCount: 10
    },
    {
      SatelliteClubName: "Vinnytsia Postup-Berdychiv",
      SponsoringClub: "Vinnytsia Postup",
      SponsoringClubPresident: "Evgen Popov",
      SponsoringClubPresidentEmail: "popovyeugen@gmail.com",
      MemberCount: 8
    }
  ]
  
  
  