import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const LandingListItem = ({id, type, linkText, url}) => {
    const content = (
      <div className={type === 'dot' ? 'landing_list_dot' : 'landing_list'} >
        {url && !linkText ? 
            <a href={url}>{id}</a> : 
            <>
            <FormattedMessage id={id} />
            &nbsp;
            {linkText && <a href={url}>{linkText}</a> }
            </>
        }
      </div>
    );
  
    return type === 'dot' ? (
      <li>
        {content}
      </li>
    ) : (
      <ListItem>
        {content}
      </ListItem>
    );
  };

export default LandingListItem;
