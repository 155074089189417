
import { LOCALES } from '../../../i18n/locales';

export const getRCCherkasyRotaract = (local) => {
    const cherkasyData = local === LOCALES.UKRAINIAN ? cherkasyData_ukr : cherkasyData_eng;
    return cherkasyData;
};
            
const cherkasyData_ukr = [
    {
        id: 0,
        header: 'Ротаракт Клуби:',
        arrcontent: [
            {
                header: 'Ротаракт Клуб Черкаси',
                img: "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267645854-509092424.jpg",
                content: [
                    {LandingMessage: `декілька слів про клуб...`},
                    {//LandingVideo: `Що таке Ротарі?`, link: 'https://drive.google.com/open?id=1OtJVaN1aciz-nQ-tttKLkFxM7joIKwdf'
                    },
                    
                    // {LandingLink: "Відео", link: "https://drive.google.com/open?id=1OtJVaN1aciz-nQ-tttKLkFxM7joIKwdf"},
                ]
            },
            {
                PhotoCarusel: [
                    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/rotaract_page/1.jpg", 
                    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/rotaract_page/10.jpg", 
                    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/rotaract_page/11.jpg", 
                    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/rotaract_page/12.jpg", 
                    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/rotaract_page/13.jpg", 
                    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/rotaract_page/2.jpg", 
                    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/rotaract_page/3.jpg", 
                    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/rotaract_page/4.jpg", 
                    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/rotaract_page/5.jpg", 
                    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/rotaract_page/6.jpg", 
                    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/rotaract_page/7.jpg", 
                    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/rotaract_page/8.jpg", 
                    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/rotaract_page/9.jpg"
                ]
            },
            {
                VideoCarusel: [
                    "https://api.rotary.org.ua/uploads/video/Ротари ротаракт 1.mp4",
                ]
            },
        ],
    },
    {
        id: 0,
        img: "",
        header: 'Satellite Clubs:',
        content: [
            {LandingMessage: `Rotary club Cherkasy satellite club Cherkasy-Sky (2020-2022)`},
            
            {LandingLink: "Facebook", link: "https://www.facebook.com/yaslafest"},
        ],
    },
];

const cherkasyData_eng = [
    {
        id: 0,
        img: "",
        header: 'Rotaract Clubs:":',
        content: [
            {LandingMessage: `Rotaract Club "Cherkasy`},
            {LandingLink: "Відео", link: "https://drive.google.com/open?id=1OtJVaN1aciz-nQ-tttKLkFxM7joIKwdf"},
        ],
    },
    {
        id: 0,
        img: "",
        header: 'Satellite Clubs:":',
        content: [
            {LandingMessage: `Rotary club Cherkasy satellite club Cherkasy-Sky (2020-2022)`},
            
            {LandingLink: "Facebook", link: "https://www.facebook.com/yaslafest"},
        ],
    },
];