
import React, { useEffect, useState, useContext, useRef  } from "react";
import { useParams } from 'react-router-dom';
import { LOCALES } from '../../../i18n/locales';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Box, Typography, Container, Link } from '@mui/material';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { MdEmail } from 'react-icons/md';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import {getRProjects} from '../R_ProjectsShowcase/getRProjects';
import {LocalContext} from '../../../tools/LanguageContextProvider';
import LandingFilters from '../fixcomponents/LandingFilters';
import LandingContentItems from '../../../components/LandingContentItems';
import LAccordionItem from '../../../components/LAccordionItem';
import LandingCards2List from '../../../components/LandingCards2List';
import LcButtonPhone from './LcButtonPhone';
import LcButtonEmail from './LcButtonEmail';
import LcButtonFB from './LcButtonFB';
import LcButtonYoutube from './LcButtonYoutube';
import LcButtonPdf from './LcButtonPdf';
import LandingRotarianCard from './LandingRotarianCard';
import { limitString } from '../../../modules/CRUD_system/functions/limitedString';
import { showRotarianContacts } from '../functions/showRotarianContacts';

import { fetchUsers } from '../rotariansBD/api/api_users';
import { fetchUserSkils } from '../rotariansBD/api/api_user_skils';

import EMPTY_ROTARIN from '../../../assets/img/clubscreator/tamplate-overlay_small.jpg';

function CardItemComponent({ card, index, tags, handleRowClick, currentLocale, ispublic }) {
    const [user, setUser] = useState(card);

    useEffect(() => {
        console.log('=== fetchProject fetchUser id='+card.projectId);

        const fetchData = async (id) => {
            try {
                const d = await fetchUsers(id);
                console.log('=== fetchProject json='+JSON.stringify(d[0]));
                setUser(d[0]);
            } catch (error) {
                console.error(error);
            }
        };

        if (card && card.projectId) {
            console.log('=== fetchProject id='+card.projectId);
            fetchData(card.projectId);
        }
    }, []);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            alert('Зміст скопійовано в буфер обміну');
          })
          .catch((error) => {
            console.error('Помилка при копіюванні в буфер обміну:', error);
          });
    };

return (
    <>
    {//JSON.stringify(user)
    }
        <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', maxWidth: 360, minWidth: 200 }} key={index}>
            <CardActionArea style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: '1' }}>
                <CardMedia
                component="img"
                sx={{ maxWidth: 400 }}
                image={user.avatar_url ? user.avatar_url : EMPTY_ROTARIN}
                alt="Без фото"
                onClick={() => handleRowClick(index)}
                />
                <CardContent style={{ flex: '1', alignItems: 'flex-start' }}> {/* Зміна тут */}
                <Typography gutterBottom variant="h6" color='darkblue'>{user.first_name} {user.last_name}</Typography>
                <Typography gutterBottom variant="h6" component="div">
                    {user.club_type && user.club_type === "District2232" ?
                    <>Дистрикт: Україна</> :
                    <>
                        {/* Клуб:  */}
                        {user.club_type && user.club_type === "RAC" ? <>RAC</> : user.club_type === "PRC" ? <>PRC</> : user.club_type === "ReC" ? <>ReC</> : <>RC</>} {user.club_name}
                    </>
                    }
                </Typography>
                <Typography gutterBottom variant="h6" component="div">
                    {/* Посада:  */}
                    {user.status}
                </Typography>
                {user.genlink && <Link href={card.genlink} target="_blank" rel="noopener noreferrer">Go to project page</Link>}
                {user.docs && user.docs.map((item, i) => (
                    <LcButtonPdf url={item} size='default' key={i} />
                ))}
                <Typography variant="body2" color="text.secondary">
                {user.other_positions && limitString(user.other_positions,6).split('\n').map((line, index, array) => {
                    const trimmedLine = line.trim();
                    const isBold = index > 0 && array[index - 1].trim() === '' && trimmedLine !== '';
                    return (
                    <Typography
                        key={index}
                        style={{ whiteSpace: 'pre-line', fontWeight: isBold ? 'bold' : 'normal' }}
                    >
                        {//isBold && <br/>
                        }
                        {line}
                    </Typography>
                    );
                })}
                </Typography>

                {!ispublic &&
                    <>
                    <br />
                    <Button onClick={() => handleRowClick(index)}>Details...</Button>
                    </>
                }
                </CardContent>
            </CardActionArea>
            {!ispublic &&
                <CardActions style={{ marginTop: 'auto' }}>
                <Typography gutterBottom variant="h7">Contacts: {user.primarycontact} {showRotarianContacts(user.additional_info)}</Typography>
                {user.email &&
                    <Tooltip title={'Email'}>
                    <IconButton onClick={() => copyToClipboard(user.email)} aria-label="Email">
                        <MdEmail color="blue" size='1.5em' />
                    </IconButton>
                    </Tooltip>
                }
                {user.phone &&
                    <Tooltip title={'Phone'}>
                    <IconButton onClick={() => copyToClipboard(user.phone)} aria-label="Phone">
                        <PhoneIcon color="primary" size='1.5em' />
                    </IconButton>
                    </Tooltip>
                }
                {user.youtube &&
                    <LcButtonYoutube url={user.youtube} size='default' />
                }
                {user.fb &&
                    <LcButtonFB url={user.fb} size='default' />
                }
                </CardActions>
            }
            </Card>



    </>
    );
}

const LandingBdRotarians = ({tags, ispublic}) => {
    //const tags = landItem.LandingBdRotarians;
    
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [data, setData] = useState([]);
    const [tagedData, setTagedData] = useState([]);
    const [selectedProj, setSelectedProj] = useState(-1);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

    const [cardView, setCardView] = useState(0);   

    const [userSkils, setUserSkils] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const categories = [
        "Медицина",
        "Освіта", 
        "Юристи",
        "ФІнанси",
        "ІТ",
        "Аграрний сектор",
        "Енергетика",
        "Промисловість",
        "Мистецька діяльність",
        "Науковець",
        "Оборонна сфера",
        "Інші",        
    ];

    const sector = [
        "Державні заклади",
        "Комунальні заклади",
        "Відомчі заклади", 
        "Приватні заклади",
        "Власний бізнес", 
        "ГО чи БФ",
        "Інвестиції", 
        "Інше",
    ]
    
    const statuses = [
        "В Украйні",
        "За кордоном",
        "Не визначено"
    ];

    const cities = [
        "Запоріжжя",
        "Львів",
        "Одеса",
        "Черкаси",
        "Харків",
    ];

    const clubtype = [
        "Rotary",
        "Rotaract"
    ];
    
    const [countCategories, setCountCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState(categories);
    const [selectedCities, setSelectedCities] = useState(cities);
    const [selectedStatuses, setSelectedStatuses] = useState(statuses);
    const [selectedRType, setSelectedRType] = useState(clubtype);
    const [selectedSector, setSelectedSector] = useState(sector);

    const  designType='chicago';

    useEffect(() => {
      const fetchData = async () => {
        try {
            await loadData(currentLocale);
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale])
    
    // useEffect(() => {
    //     if (tagedData && tagedData[selectedProj]) {
    //         loadUserSkils(tagedData[selectedProj].user_id);
    //     }
    // }, [selectedProj]);
    
    // const loadUserSkils = async(userId) => {
    //     const sk = await fetchUserSkils(userId);
    //     //setUserSkils(sk);
    //     return sk;
    // }

    const loadData = async (locale) => {
        setIsLoading(true);
        const d = await fetchUsers();
        if (d) {
            console.log('==== fetchUsers count=' + d.length);
            
            //const filteredData = tags==='#allrotarians' ? d : d.filter(item => item.tags === tags);
            const filteredData = tags === '#allrotarians' ? d : d.filter(item => {
                if (item.tags && typeof item.tags === 'string' && tags && typeof tags === 'string') {
                  const itemTagsArray = item.tags.split(',').map(tag => tag.trim());
                  const tagsArray = tags.split(',').map(tag => tag.trim());
              
                  return tagsArray.some(tag => itemTagsArray.includes(tag));
                }
              
                // Обробка випадку, коли item.tags або tags не є рядком
                return false;
              });

            const updatedFilteredData = [];
            for (const item of filteredData) {
                const sk = await fetchUserSkils(item.user_id);
                console.log(`========skils(${item.user_id}) count=${sk.length}`);
                updatedFilteredData.push({ ...item, skils: sk });
            }

            setData(updatedFilteredData);

            //loadFilteredData(updatedFilteredData);
            const filteredData2 = filterData(updatedFilteredData);
            setTagedData(filteredData2);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        //loadFilteredData(data);
        const filteredData2 = filterData(data);
        setTagedData(filteredData2);
        console.log("Change curent filter "+JSON.stringify(filteredData2));
    }, [data,selectedCategories, selectedStatuses, selectedRType])

    const filterData = (dt) => {
        
        const OTHER_CATEGORY = "Інші";
        const hasOtherCategory = selectedCategories.includes(OTHER_CATEGORY);

        const d1 = dt.filter((item) => {
            const f1 = item.skils && item.skils.some(skill => selectedCategories.includes(skill.category));
            //if (f1)
                console.log(`====filterData =${item.user_id} / ${item.skils ? item.skils.length : 0} / f1=${f1}`);
            return hasOtherCategory || f1;
        });
        
        // const d2 = d1.filter((item, index) => {
        //     return selectedStatuses.includes(item.status);
        // });
        // const d3 = d2.filter((item, index) => {
        //     return selectedRType.includes(item.rtype);
        // });

        return d1;
    }

    const cardViewChanged = (v) => {
        setCardView(v);
    }

    const cbCategories = (cat) => {
        setSelectedCategories(cat);
    }

    const cbStatuses = (st) => {
        setSelectedStatuses(st);
    }

    const cbRType = (st) => {
        setSelectedRType(st);
    }

    const copyToClipboard = (text) => {
        
        navigator.clipboard.writeText(text)
          .then(() => {
            alert('Зміст скопійовано в буфер обміну');
          })
          .catch((error) => {
            console.error('Помилка при копіюванні в буфер обміну:', error);
          });
    };
      
    const showCards = (cards) => {
        return (
            <>
            
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}  gap={2} alignItems="top" paddingTop='2em' flexWrap='wrap'>
            {cards && cards.map((card, index) => (
                <CardItemComponent card={card} index={index} tags={tags} handleRowClick={handleRowClick} currentLocale={currentLocale} ispublic={ispublic} />
            ))}
            </Box>
            </>
        )
    }



    const showTable = (cards) => {
        return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{fontSize: '1vw'}}>Фото</TableCell>
            <TableCell sx={{fontSize: '1vw'}}></TableCell>
            <TableCell sx={{fontSize: '1vw'}}>Клуб</TableCell>
            <TableCell sx={{fontSize: '1vw'}}>Посада</TableCell>
            {!ispublic && <TableCell sx={{fontSize: '1vw'}}>Contact</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {cards.map((card, index) => (
            (
                <TableRow
                    key={index}
                >

                <TableCell 
                    onClick={() => handleRowClick(index)}
                    onMouseEnter={() => setHoveredRowIndex(index)}
                    onMouseLeave={() => setHoveredRowIndex(null)}
                    style={getRowStyle(index)}
                    sx={{fontSize: '1vw'}}
                >
                    <img src={card.avatar_url ? card.avatar_url : EMPTY_ROTARIN} alt="" style={{ maxWidth: '100px' }} />
                </TableCell>

                <TableCell>
                {card.first_name} {card.last_name} 
                </TableCell>
                
                <TableCell sx={{fontSize: '1vw'}}>
                    {card.rtype && card.rtype==="Rotaract" ? <>RAC</> :  <>RC</>} <br/>{card.club_name}
                    
                </TableCell>

            

                <TableCell>
                    
                    {card.other_positions && limitString(card.other_positions)}
                  
                </TableCell>

                {!ispublic &&
                <TableCell>
                    {card.primarycontact}
                    <br/>
                    {card.email && (
                        <LcButtonEmail url={card.email} size='default' />
                    )}
                    {card.phone && (
                        <LcButtonPhone url={card.phone} size='small'/>
                    )}
                    {card.youtube && (
                        <LcButtonYoutube url={card.youtube} size='default' />
                    )}
                    {card.fb && (
                        <LcButtonFB url={card.fb} size='default' />
                    )}
                </TableCell>
                }
              </TableRow>
            )
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  
  )
}


    const showList = (cards) => {
        return (
            <>
            <LandingCards2List cards={cards} ispublic />
            </>
        )
    }
    const handleRowClick = (index) => {
        setUserSkils(tagedData[index].skils)
        setSelectedProj(index);
    };
    const handleBack = () => {
        setSelectedProj(-1);
    }
    const getRowStyle = (index) => {
        if (selectedProj === index) {
          return { backgroundColor: '#f5f5f5' };
        } else if (hoveredRowIndex === index) {
          return { backgroundColor: '#e0e0e077' }; // Світліший колір при наведенні
        }
        return {};
      };


    return (
        <>
        {isLoading ? 
        <CircularProgress /> :
        <>    
        {selectedProj>-1 ?
            <LandingRotarianCard people={tagedData[selectedProj]} handleBack={handleBack} userSkils={userSkils} ispublic={ispublic}/>
            :
        <>
            {/* {!ispublic && 
            <LAccordionItem title="Filters" >
            <LandingFilters 
                categories={categories}
                statuses={statuses}
                clubtype={clubtype}
                selectedCategories={selectedCategories} 
                cbCategories={cbCategories} 
                selectedStatuses={selectedStatuses}
                cbStatuses={cbStatuses}
                selectedRType={selectedRType}
                cbRType={cbRType}
                countCategories={countCategories}
                cities={cities}
                selectedCities={selectedCities}
                sector={sector}
                selectedSector={selectedSector}
            /> 
            </LAccordionItem>
            } */}

            {/* <Box display="flex" flexDirection="row" pt='8px'>
                <Box display="flex" flexDirection="row" whiteSpace="nowrap" pr='8px'>
                    <Box whiteSpace="nowrap" pr='8px'>Показати як: </Box>
                    <Button variant='contained' onClick={() => cardViewChanged(0)}>Картка</Button>
                    {/*<Button variant='contained' onClick={() => cardViewChanged(1)} >list</Button>
                    <Button variant='contained' onClick={() => cardViewChanged(2)} >Таблиця</Button>
                </Box>
                <Box width="100%">
                </Box>
            </Box> */}
            
            <Box display="flex" flexDirection="row" pt='8px'>
                {tagedData &&
                <Box display="flex" flexDirection="row" whiteSpace="nowrap" pr='8px'>
                    Rotarians: {tagedData.length}
                </Box>
                }

                <Box width="100%">
                </Box>
                {/* { !tags.includes("#allrotarians") && !ispublic &&
                <Box whiteSpace="nowrap">
                    <Link target="_blank" rel="noopener noreferrer">
                        всі ротарійці Дистрикту 2232
                    </Link>
                </Box>} */}
            </Box>

            {showCards(tagedData)}
            {/* {tagedData &&
                cardView === 1 ? (
                    showList(tagedData)
                ) : cardView === 2 ? (
                    showTable(tagedData)
                ) : (
                    cardView === 0 && showCards(tagedData)
                )
            } */}

            {//<LandingContent data={data} currentLocale={currentLocale} designType={designType} />
            }
        </>}
        </>
        }
        </>
  );
}

export default LandingBdRotarians;