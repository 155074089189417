import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Box, Container, Grid, Typography, Button, Tooltip,   } from '@mui/material'
import { List, ListItem, ListItemText, } from '@mui/material'
import { Card, CardContent, Link } from '@mui/material'
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/system';

import '../Pages.css';

import MyMainPage from "../../modules/MYMain";
import LandingBlock from '../../components/LandingBlock';
import LAccordionItem from '../../components/LAccordionItem';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; // Імпортуємо іконку файлу
import LandingHeader from '../../components/LandingHeader';

import { getSeminars, getSeminarById } from '../../api/ts/materials_seminars';
import { getPresentations, getPresentationsBySemId } from '../../api/ts/materials_seminars_presentations';

const NoWrapTypography = styled(Typography)(({ theme }) => ({
    '&.MuiTypography-root': {
      [theme.breakpoints.up('sm')]: {
        whiteSpace: 'nowrap',
      },
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    // Застосовуємо стилі тільки для sm екранів
    [theme.breakpoints.down('sm')]: {
        paddingLeft: '2px',
        paddingRight: '0px',
        _border: '1px solid red',
        _color: 'red',
    },
}));

const SeminarsPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sem_id = searchParams.get('sem_id');

    const [seminars, setSeminars] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const sems = await getSeminars();
                const seminarsWithPresentations = await Promise.all(
                    sems.map(async (sem) => {
                        const presentations = await getPresentationsBySemId(sem.id);
                        return { ...sem, presentations };
                    })
                );
                setSeminars(seminarsWithPresentations);
            } catch (error) {
                // Обробити помилку отримання даних
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const showPresentations = (presentations) => (
        <Table>
            <TableBody>
                {presentations.map((item) => (
                        <TableRow key={item.id}>
                            <StyledTableCell>
                                {item.url 
                                    ? <Link href={item.url} target="_blank" rel="noopener noreferrer">
                                        <InsertDriveFileIcon color="secondary" />
                                    </Link>
                                    : <Typography color="textSecondary">Файл відсутній</Typography>
                                }
                            </StyledTableCell>
                            <StyledTableCell>
                                <NoWrapTypography variant="subtitle2">{item.adv1}</NoWrapTypography>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Typography variant="h7" color="darkblue">{item.name}</Typography>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Typography variant="subtitle1">{item.speaker}</Typography>
                            </StyledTableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )

    return (
        <>
        <MyMainPage>
        <LandingBlock mode='light'>
        <Container>
            <Box className="section text-left" mb={4}>
                <LandingHeader><Typography variant="h4" className="page-header">Презентації семінарів Дистрикту 2232 на 2023-2024 Ротарійський рік</Typography></LandingHeader>

                {loading && <Typography variant="body1">Завантаження...</Typography>}
                
                {seminars && seminars.map((semItem) => (
                    <>
                        <Typography variant="h5" className="page-header" style={{color: 'darkblue'}}>
                            {semItem ? semItem.title+" - "+semItem.date : 'Семінар не знайдено'}
                        </Typography>
                        <LAccordionItem title={"Презентації("+semItem.presentations.length+")"} defaultExpanded={sem_id===semItem.id}>
                            {showPresentations(semItem.presentations)}
                        </LAccordionItem> 
                    </>
                ))}
                
                
            </Box>
        </Container>
        </LandingBlock>
        </MyMainPage>
        </>
    );
    
    
}

export default SeminarsPage;