
import React, { useEffect, useState, useContext, useRef  } from "react";

import { Box, Typography, Container, Link } from '@mui/material';
import { styled } from '@mui/system';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, TextField, Button } from '@mui/material';

import {getRProjects} from '../R_ProjectsShowcase/getRProjects';
import {LocalContext} from '../../../tools/LanguageContextProvider';
import LandingFilters2 from '../fixcomponents/LandingFilters2';
import LandingContentItems from '../../../components/LandingContentItems';
import LAccordionItem from '../../../components/LAccordionItem';
import LandingCards2List from '../../../components/LandingCards2List';
import LandingProjectPage_Singapore from './LandingProjectPage_Singapore';
import ProjectPhotoCarusel from './ProjectPhotoCarusel';
import { fetchProject } from '../rotariansBD/api/api_projects';
import { fetchProjects } from '../rotariansBD/api/api_projects';
import { createProject} from '../rotariansBD/api/api_projects';
import { changeMetafieldLine, getSubfieldValue } from '../../../modules/CRUD_system/functions/MetafieldEditLineTools';
import { showField, showField_json, showField_title, showFields_contacts, showField_rclub, showField_metafield, showField_smart, listDocsPages, listWebSocialPages } from '../../../modules/CRUD_system/functions/ProjectPageShowItemTools';
import {jsonToStringArray} from '../../../modules/ProjectCardGUI/toolsfunc/JsonToArrayString';
import {hasAnyCategory, calculateProjCount_2} from '../../../modules/ProjectCardGUI/toolsfunc/FilterProjectTools';
import ApprovedBox from '../../../modules/ProjectCardGUI/ApprovedBox';

const SearchContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
});

const SearchInput = styled(TextField)({
    flex: 1,
});

const SearchButton = styled(Button)({
    height: '100%',
    marginLeft: '8px', // Додайте потрібний відступ між полем пошуку та кнопкою
});

function limitString(inputString) {
    const maxChars = 300;
    const maxLines = 6;
  
    // Розділити рядок на масив рядків
    const lines = inputString.split('\n');
  
    let result = '';
  
    for (let i = 0; i < lines.length && i < maxLines; i++) {
      // Змінити поточний рядок, щоб він не перевищував максимальну кількість символів
      const currentLine = lines[i].substring(0, maxChars);
  
      // Додати поточний рядок до результату
      result += currentLine;
  
      // Якщо це не останній рядок і є ще рядки, додайте символ переносу рядка
      if (i < lines.length - 1 && i < maxLines - 1) {
        result += '\n';
      }
    }
  
    return result;
}


function CardItemComponent({ card, index, tags, handleRowClick, currentLocale }) {
    const clubLogo = "https://res.cloudinary.com/tyko/image/upload/v1704543710/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/share/RC_Cherkasy_project_edlovp.png";
    const districtLogo = "https://res.cloudinary.com/tyko/image/upload/v1704614094/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/share/D2232_project_edlovp_o1oywq.png";

    const user = card;
    // const [user, setUser] = useState(card);

    // useEffect(() => {
    //     console.log('=== fetchProject fetchUser id='+card.projectId);

    //     const fetchData = async (id) => {
    //         try {
    //             const fetchedUser = await fetchProject(id);
    //             console.log('=== fetchProject json='+JSON.stringify(fetchedUser[0]));
    //             setUser(fetchedUser[0]);
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     };

    //     if (card && card.projectId) {
    //         console.log('=== fetchProject id='+card.projectId);
    //         fetchData(card.projectId);
    //     }
    // }, []);


    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            alert('Зміст скопійовано в буфер обміну');
          })
          .catch((error) => {
            console.error('Помилка при копіюванні в буфер обміну:', error);
          });
    };
    

return (
    <>
                <Card sx={{ maxWidth: 360, minWidth: 200, display: 'flex', flexDirection: 'column' }} key={index}  >
                <CardActionArea>
                    <ProjectPhotoCarusel images={user.avatarimg && user.avatarimg.split(',')} />

                    
                  <CardContent>
                    <ApprovedBox objitem={user} metafield={'youtube:approved'} index={index} handleRowClick={handleRowClick} />
                    {showField_title(user.title, index, handleRowClick)}

                    {showField_rclub(user)}
                    {showField_json(user.category2 ? "Area of focus: " : "Категорія: ", user.category)}
                    {showField_json("Category: ", user.category2)}
                    {user.status && showField("Statuses: ", user.status)}
                    {user.budget && showField("Budget: ", user.budget)}
                    
                    {showField_metafield('Short Description:', user, 'youtube:fdesc', true)}
                    {/* <Typography gutterBottom variant="h6" color= "gray" component="div" style={{ marginBottom: '-0.5rem' }}>
                        Descriptions:</Typography>
                    <Typography variant="body1" color="text.secondary">
                        {user.content && 
                            <lu>
                            <LandingContentItems content={user.content} />
                            </lu>
                        }
                        {user.description && 
                            <Typography style={{ whiteSpace: 'pre-line' }}>
                            {limitString(getSubfieldValue(user,'youtube:fdesc'))}
                            </Typography>
                        }
                    </Typography> */}
                    {/* <br/>
                    <Button onClick={() => handleRowClick(index)} >Детальніше...</Button> */}
                    
                  </CardContent>
                </CardActionArea>
                <CardActions style={{ marginTop: 'auto' }}>
                    {showFields_contacts(user)}
                </CardActions>
                </Card>
                </>
    );
}

const LandingProjects2_Singapore = ({landItem}) => {
    const tags = landItem.LandingProjects2_Singapore;
    
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [data, setData] = useState([]);
    const [tagedData, setTagedData] = useState([]);
    const [selectedProj, setSelectedProj] = useState(-1);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
    const [searchString, setSearchString] = useState('');

    const [cardView, setCardView] = useState(0); 

    const categories = [
        "1. Promoting peace",
        "2. Fighting disease",
        "3. Providing clean water, sanitation, and hygiene",
        "4. Saving mothers and children",
        "5. Supporting education",
        "6. Growing local economies",
        "7. Protecting the environment",
        "-. other"
    ];

    const categories2 = [
        "medicine",
        "rehabilitation",
        "mental care",
        "education/children",
        "WASH",
        "humanitarian demining",
        "IDPs families",
        "community recovery",
        "generators",
        "logistic costs",
    ];
    const statuses = [
        "Offered, looking for global grant partners",
        "Offered, looking for other partners",
        "In process", 
        "Permanently active",
        "During the war in Ukraine"
    ];

    const budget = [
        "<1000$",
        "1000-2000$",
        "2000-5000$",
        "5000-10,000$",
        "10,000-20,000$",
        "20,000-50,000$",
        ">50,000$"
    ];

    const clubtype = [
        "Rotary",
        "Rotaract"
    ];
    
    const [countCategories2, setCountCategories2] = useState([]);
    const [countCategories, setCountCategories] = useState([]);
    const [countStatuses, setCountStatuses] = useState([]);
    const [countBudget, setCountBudget] = useState([]);
    const [countRType, setCountRType] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState(categories);
    const [selectedCategories2, setSelectedCategories2] = useState(categories2);
    const [selectedStatuses, setSelectedStatuses] = useState(statuses);
    const [selectedBudget, setSelectedBudget] = useState(budget);
    const [selectedRType, setSelectedRType] = useState(clubtype);

    const [seachedFilteredCount, setSeachedFilteredCount] = useState(0);

    const  designType='chicago';

    useEffect(() => {
      const fetchData = async () => {
        try {
            await loadData(currentLocale);
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale])

    const loadData = async(locale) => {
        const d = await fetchProjects();//getRProjects(locale);
        
        const filteredData = d.filter(item => item.tags === tags);
        setData( filteredData );

        const filteredData2 = filterData(filteredData)
        setTagedData(filteredData2);

        console.log("DanatePage get data list success. const="+d.length);
    }

    useEffect(() => {
        const filteredData2 = filterData(data)
        setTagedData(filteredData2);
        console.log("Change curent filter ("+filteredData2.length+") "+selectedCategories);
    }, [data,selectedCategories, selectedCategories2, selectedStatuses, selectedBudget, selectedRType, searchString])

    // const hasAnyCategory = (catList, categories) => {
    //     // Розбиваємо catList на масив окремих категорій, прибираючи зайві пробіли
    //     const catArray = catList && catList.split(',').map(cat => cat.trim());
        
    //     // Перевіряємо, чи має catArray хоча б одну категорію з categories
    //     return catArray && categories.some(category => catArray.includes(category));
    // };

    
    const filterData = (data) => {
        const data_3 = data;// && data.slice(0, 3);
        
        const d1 = data_3.filter((item, index) => {
            //console.log("======== filterData"+countCategories);
            const catList = jsonToStringArray(item.category);
            return hasAnyCategory(catList, selectedCategories);
        });
        const d1_2 = d1.filter((item, index) => {
            console.log("======== "+countCategories2);
            const catList = jsonToStringArray(item.category2);
            return hasAnyCategory(catList, selectedCategories2);
            //return selectedCategories2.includes(item.category2);
        });
        const d2 = d1_2.filter((item, index) => {
            return selectedStatuses.includes(item.status);
        });
        const d3 = d2.filter((item, index) => {
            return selectedBudget.includes(item.budget);
        });
        // const d4 = d3.filter((item, index) => {
        //     return selectedRType.includes(item.rtype);
        // });

        const fields = ['title', 'category', 'category2', 'description'];
        const d5 = d3.filter(item => {
                // Перевірка кожного поля
                return fields.some(field => {
                // Перевірка, чи містить значення поля шуканий термін
                return item[field] && item[field].toLowerCase().includes(searchString.toLowerCase());
                });
        });
        setSeachedFilteredCount(d3.length - d5.length);

        setCountCategories(calculateProjCount_2(data_3, categories, 'category'));
        setCountCategories2(calculateProjCount_2(data, categories2, 'category2'));
        setCountStatuses(calculateProjCount(data, statuses, 'status'));
        setCountBudget(calculateProjCount(data, budget, 'budget'));
        setCountRType(calculateProjCount(data, clubtype, 'rtype'));

        console.log('================= filterData ');
        d5 && d5.map(item => {
            console.log(`filterData id=${item.project_id} category=${item.category}`);
            return item; // Повертаємо item, щоб не змінити початковий масив
        });
        return d5;
    }

    const calculateProjCount = (curArr, fullFieldList, dbField) => {
        // Створюємо початковий масив countFieldList, заповнений нулями
        let countFieldList = new Array(fullFieldList.length).fill(0);
    
        // Ітеруємося по кожному елементу в curArr
        curArr.forEach(item => {
            // Отримуємо значення поля dbField поточного елемента
            const fieldValue = item[dbField];
            
            // Знаходимо індекс значення поля dbField у масиві fullFieldList
            const index = fullFieldList.indexOf(fieldValue);
            
            // Якщо індекс більше або дорівнює нулю (тобто значення знайдено в fullFieldList), збільшуємо лічильник для цього значення
            if (index >= 0) {
                countFieldList[index]++;
            }
        });
    
        return countFieldList;
    }    

    const cardViewChanged = (v) => {
        setCardView(v);
    }

    const cbCategories = (cat) => {
        setSelectedCategories(cat);
    }

    const cbCategories2 = (cat2) => {
        setSelectedCategories2(cat2);
    }
    
    const cbStatuses = (st) => {
        setSelectedStatuses(st);
    }

    const cbBudget = (st) => {
        setSelectedBudget(st);
    }

    const cbRType = (st) => {
        setSelectedRType(st);
    }

    const copyToClipboard = (text) => {
        
        navigator.clipboard.writeText(text)
          .then(() => {
            alert('Зміст скопійовано в буфер обміну');
          })
          .catch((error) => {
            console.error('Помилка при копіюванні в буфер обміну:', error);
          });
    };
      
    const showCards = (cards) => {
        return (
            <>
            
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}  gap={2} alignItems="top" paddingTop='2em' flexWrap='wrap'>
            {cards && cards.map((card, index) => (
                <CardItemComponent card={card} index={index} tags={tags} handleRowClick={handleRowClick} currentLocale={currentLocale} />
            ))}
            </Box>
            </>
        )
    }

    const getFirstImg = (imgstr) => {
        // Перевірка на пустий рядок
        if (!imgstr || imgstr.trim() === '') {
            return null; // Або ви можете повернути пустий рядок, залежно від вашого випадку використання
        }
    
        // Розділити рядок на масив за допомогою коми
        const urls = imgstr.split(',');
    
        // Повернути перший елемент масиву
        return urls[0];
    }

    


    const showList = (cards) => {
        return (
            <>
            <LandingCards2List cards={cards} />
            </>
        )
    }

    const handleRowClick = (index) => {
        setSelectedProj(index);
    };

    const handleBack = () => {
        setSelectedProj(-1);
    }

    

    const handleSearch = () => {
        console.log("handleSearch");
        setSearchString();
    };

    const filterTitle = `Filters (showed ${tagedData.length} from ${data.length})`
    return (
        <>
        {//JSON.stringify(tagedData)
        } 
        {/*data && //додавання БД з json в mysql
            <Button variant="contained" color="primary" onClick={() => handleAddAll(data)}>
                Add All Projects ({data.length})
            </Button>
    */}
            
        {selectedProj>-1 ?
            <LandingProjectPage_Singapore proj={tagedData[selectedProj]} handleBack={handleBack} />
            :
        <>
            
            <Box display="flex" flexDirection="row" pt='8px'>
                {tagedData &&
                <Box display="flex" flexDirection="row" whiteSpace="nowrap" pr='8px'>
                    Projects count: {tagedData.length}<br/>
                </Box>
                }

                {/* <Box width="100%">
                </Box>
                { !tags.includes("#showcase") &&
                <Box whiteSpace="nowrap">
                    <Link href="/project_showcase" target="_blank" rel="noopener noreferrer">
                        всі проєкти Дистрикту 2232
                    </Link>
                </Box>} */}
            </Box>

            <LAccordionItem title={filterTitle} defaultExpanded={true}>
            <SearchContainer>
            <SearchInput 
                label="Search" 
                variant="outlined" 
                onChange={(e) => setSearchString(e.target.value)} // Оновлення стану рядка пошуку під час введення
            />
            </SearchContainer>
            <Typography color='red'>
            {seachedFilteredCount>0 && `* hided ${seachedFilteredCount} project`}
            </Typography>

            <LandingFilters2 
                categories={categories} selectedCategories={selectedCategories} cbCategories={cbCategories} countCategories={countCategories}
                categories2={categories2} selectedCategories2={selectedCategories2} cbCategories2={cbCategories2} countCategories2={countCategories2}
                statuses={statuses} selectedStatuses={selectedStatuses} cbStatuses={cbStatuses} countStatuses={countStatuses}
                budget={budget} selectedBudget={selectedBudget} cbBudget={cbBudget} countBudget={countBudget}
                clubtype={clubtype} selectedRType={selectedRType} cbRType={cbRType} countRType={countRType}
            /> 
            </LAccordionItem>

            {/* <Box display="flex" flexDirection="row" pt='8px'>
                <Box display="flex" flexDirection="row" whiteSpace="nowrap" pr='8px'>
                    <Box whiteSpace="nowrap" pr='8px'>View: </Box>
                    <Button variant='contained' onClick={() => cardViewChanged(0)}>cards</Button>

                    <Button variant='contained' onClick={() => cardViewChanged(2)} >table</Button>
                </Box>
                <Box width="100%">
                </Box>
            </Box> */}

            {showCards(tagedData)}
            {/* {tagedData &&
                cardView === 1 ? (
                    showList(tagedData)
                ) : cardView === 2 ? (
                    showTable(tagedData, handleRowClick, setHoveredRowIndex )
                ) : (
                    cardView === 0 && showCards(tagedData)
                )
            } */}

            {//<LandingContent data={data} currentLocale={currentLocale} designType={designType} />
            }
        </>}
        </>
  );
}

export default LandingProjects2_Singapore;