// src/api.js
import axios from 'axios';

const API_URL = 'https://7fc65ea596c38701.cdn.express';

export const login = async (username, password) => {
  const response = await axios.post(`${API_URL}/~/action/storage/auth/login/`, {
    username,
    password
  }, {
    headers: { 'Content-Type': 'application/json' }
  });
  return response.data.token;
};

export const uploadFile = async (token, file, path) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('path', path);

  const response = await axios.post(`${API_URL}/~/upload`, formData, {
    headers: {
      'Storage-Token': token,
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.status === 200;
};

export const listFiles = async (token, path) => {
  const response = await axios.post(`${API_URL}/~/action/storage/manage/ls/`, {
    path
  }, {
    headers: {
      'Storage-Token': token,
      'Content-Type': 'application/json'
    }
  });
  return response.data;
};
