
import axios from 'axios'; 

const URL_TO_API_SERVER = "https://rotary.org.ua/template/api/";


export const api_is_logged_in = async() => {
    try {
        const response = await axios.get(URL_TO_API_SERVER+'api_is_logged_in.php', {
            withCredentials: true
        });
        console.log('Відповідь2 від сервера:', response.data);

        return response.data;
        /*if (response && response.data && response.data.email) {
            console.log('===== автологін:', response.data);
            return response.data;
            //getMembersInfo(response.data.email);
        } else {
            //setMessage(response.data);
        }*/
    }catch (error) {
        console.error('Помилка:', error);
    }
}

export const api_islogin = async(email,password) => {
    try {
        const data = {
          email,
          password
        };
        
        // Налаштування запиту
        const config = {
          headers: {
            'Content-Type': 'application/json' // Встановлюємо заголовок Content-Type на JSON
          },
          withCredentials: true
        };
        
          // Відправляємо POST-запит
          try {
              const response = await axios.post(URL_TO_API_SERVER+'api_islogin.php', data, config);
              console.log('Відповідь від сервера:', response.data);
              //setMessage(response.data);
              return response.data;
              
          } catch (error) {
              console.error('Помилка при відправці запиту на логін:', error);
          }
          
      } catch (error) {
        console.error('Помилка:', error);
      }
      return "";
}

export const api_logout = async () => {//api_logout
    const data = {
        //email,
        //password
    };

    // Налаштування запиту
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true
    };

    // Відправляємо POST-запит
    const response = await axios.post(URL_TO_API_SERVER+'api_logout.php',data,config)
    .then(response => {
        // Обробка відповіді
        console.log('Відповідь від сервера:', response.data);
        // Виводимо повідомлення про статус
        //setMessage(response.data);
        return true;
    })
    .catch(error => {
        // Обробка помилок
        console.error('Помилка при відправці запиту:', error);
        //setLoginError(true);
        return null;
    });
    return null;
}