import React, { useState, useEffect } from 'react';

import { Container, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import {  Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { Box, Grid } from '@mui/material';

import LandingEditor from '../MyAdministrations/LandingEditor';
import LandingContentShema from './LandingContentShema';

import {getBusinessLanding, getPhotoForLanding} from '../../api/getBusinessLanding'

const AdminLandings = () => {
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const d = await getBusinessLanding(true);
                setData(d);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, []);
    
    const handlerSelectItem = (item) => {
        setSelectedItem(item);
    }

    return (
        <Box>
        <Grid container spacing={2}>
            {/* 1/3 колонка */}
            <Grid item xs={4}>
            <Box border={1} height='100%'> 
            <Paper 
                sx={{
                    maxHeight: '100vh', // Ви можете змінити це значення на потрібне вам
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '10px',
                    },
                    '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 5px grey',
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'darkgrey',
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: 'grey',
                    }
                }}
            >
                    {data && <LandingContentShema data={data} handlerSelectItem={handlerSelectItem}/>}
            </Paper>
            {selectedItem && <div style={{border: '1px solid red', height: '100px' }}
            >
                selectedItem
            </div>}
            </Box>
            </Grid>

            {/* 2/3 колонка */}
            <Grid item xs={8}>
            <Box border={1} height='100%'> {/* Тут може бути доданий стиль або інший контент */}
            <Container>
            <Paper 
                sx={{
                    maxHeight: '100vh', // Ви можете змінити це значення на потрібне вам
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '10px',
                    },
                    '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 5px grey',
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'darkgrey',
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: 'grey',
                    }
                }}
            >
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    {loading && <p>Завантаження...</p>}
                    
                    <h2>Конструктор Лендінгів</h2>

                    <LandingEditor />         
                </Paper>
                </Container>
            </Box>
            </Grid>
        </Grid>
        </Box>
       
    );
};

export default AdminLandings;
