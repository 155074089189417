
import { LOCALES } from '../../../i18n/locales';

export const getRotariansBD = (local) => {
    //const cherkasyData = local === LOCALES.UKRAINIAN ? cherkasyData_ukr : cherkasyData_eng;
    return cherkasyData;
};

const cherkasyData = [
    {
        id: 0,
        header: 'Ротарійські та професійні навички ротарійців',
        content: [
            {LandingMessage: `Переглядайте або шукайте ротарійців за допомогою одного або кількох фільтрів у списку.`},
        ],
    },
    {
        id: 1,
        LandingBdRotarians: '#allrotarians',
    }
];

  