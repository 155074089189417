import React, { useEffect, useState } from "react";
import { Container, Typography } from '@mui/material'
import '../Pages.css';

import { Paper } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import {  useMediaQuery, useTheme } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import MyMainPage from "../../modules/MYMain";
import { getFindocs, getFindocs2, getFindocs3, getFindocs4 } from "../../api/finance";
import WordTypePage_TempShare from '../../components/WordTypePage_TempShare';

const FinancePage = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [data3, setData3] = useState(null);
    const [data4, setData4] = useState(null);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const dt = await getFindocs();
          const dt2 = await getFindocs2();
          const dt3 = await getFindocs3();
          const dt4 = await getFindocs4();
          //console.log('getEventsData = '+JSON.stringify(events.data, null, 2));
          setData(dt);
          setData2(dt2);
          setData3(dt3);
          setData4(dt4);
        } catch (error) {
          // Обробити помилку отримання даних
        }
      };

      fetchData();
    }, []);

    return (
        <WordTypePage_TempShare>
                <Typography variant="h3" className="page-header" align="center"
                    style={{color:'darkblue', paddingBottom: '16px' }} >Фінансові документи</Typography>

                <Typography variant="h5" className="page-header">Документи бюджету 2024-2025:</Typography>
                    <List component="nav">
                        {data3 &&
                        data3.map((row, index) => (
                            <ListItem key={index} button onClick={() => window.open(row.url, '_blank')}
                                style={{ paddingTop: '4px', paddingBottom: '4px'}} >
                                {index+1}.
                            <ListItemIcon>
                                <PictureAsPdfIcon color="primary"fontSize="large" style={{ marginRight: '0px', marginLeft: '10px' }} />
                            </ListItemIcon>
                            {row.name}
                            </ListItem>
                        ))}
                    </List>
                <br/>

                <Typography variant="h5" className="page-header">Документи бюджету 2023-2024:</Typography>
                    <List component="nav">
                        {data4 &&
                        data4.map((row, index) => (
                            <ListItem key={index} button onClick={() => window.open(row.url, '_blank')}
                                style={{ paddingTop: '4px', paddingBottom: '4px'}} >
                                {index+1}.
                            <ListItemIcon>
                                <PictureAsPdfIcon color="primary"fontSize="large" style={{ marginRight: '0px', marginLeft: '10px' }} />
                            </ListItemIcon>
                            {row.name}
                            </ListItem>
                        ))}
                    </List>
                <br/>

                <Typography variant="h5" className="page-header">Документи Громадської Спілки:</Typography>
                    <List component="nav">
                        {data2 &&
                        data2.map((row, index) => (
                            <ListItem key={index} button onClick={() => window.open(row.url, '_blank')}
                                style={{ paddingTop: '4px', paddingBottom: '4px'}} >
                                {index+1}.
                            <ListItemIcon>
                                <PictureAsPdfIcon color="primary"fontSize="large" style={{ marginRight: '0px', marginLeft: '10px' }} />
                            </ListItemIcon>
                            {row.name}
                            </ListItem>
                        ))}
                    </List>
                <br/>
                <Typography variant="h5" className="page-header">Зразки первинних документів:</Typography>
                
                    <List component="nav">
                        {data &&
                        data.map((row, index) => (
                            <ListItem key={index} button onClick={() => window.open(row.url, '_blank')}
                                style={{ paddingTop: '4px', paddingBottom: '4px'}} >
                                <div style={{ minWidth: '50px'}}>{row.name}.</div>
                            <ListItemIcon>
                                <InsertDriveFileIcon color="primary" fontSize="large" style={{ marginRight: '0px', marginLeft: '10px' }} />
                            </ListItemIcon>
                            
                                {row.title}
                            
                            </ListItem>
                        ))}
                    </List>
        </WordTypePage_TempShare>
  );
}

export default FinancePage;