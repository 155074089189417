import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from '@mui/material'
import './Pages.css';

import MainPage from "../components/Main";
import LandingBlock from '../components/LandingBlock';
import ButtonToRI from '../components/ButtonToRI';

import RI_1 from '../assets/content/home/RI_1.png'

const AboutRiPage = () => {
    return (
        <>
          <MainPage>
            <LandingBlock mode='light'>
            <Box className="section text-left" >
            
              <Container>
              <Typography variant="h4" className="page-header">Про Фундацію Ротарі Інтернейшнл</Typography>
                  ...
              </Container>
            </Box>
            </LandingBlock>
            
          </MainPage>
        </>
  );
}

export default AboutRiPage;