import axios from 'axios';

//const BASE_URL = 'http://localhost:3001/apibd';
const BASE_URL = 'https://api.rotary.org.ua/apibd';

export const fetchRClubs = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/rclubs`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchRClub = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/rclub/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createRClub = async (rclubData) => {
    console.log('=== createRClub...');
    try {
        const response = await axios.post(`${BASE_URL}/rclub`, rclubData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateRClub = async (rclubId, rclubData) => {
    console.log('=== updateRClub...');
    try {
        console.log('=== updateRClub url'+`${BASE_URL}/rclub/${rclubId}`);
        const response = await axios.put(`${BASE_URL}/rclub/${rclubId}`, rclubData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteRClub = async (rclubId) => {
    try {
        await axios.delete(`${BASE_URL}/rclub/${rclubId}`);
    } catch (error) {
        throw error;
    }
};
