import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Box, Button, Menu, MenuItem, IconButton, Drawer, useMediaQuery, useTheme, Link } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LoginIcon from '@mui/icons-material/Login';

import './TLandingNavbar.css';
import { LocalContext } from '../../../tools/LanguageContextProvider';
import MenuIcon from '@mui/icons-material/Menu';
import { LOCALES } from '../../../i18n/locales';
import D2232LOGO from '../../../assets/img/D2232.png';
import { styled } from '@mui/material/styles';

const SquareIconButton = styled(IconButton)({
  borderRadius: '4px', // задаємо радіус куточків
  padding: '2px',
  // Додайте додаткові стилі, якщо потрібно
});

const TLandingNavbar_MyStyle = ({ navbar, languages, userProfile }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [scrollPosition, setScrollPosition] = useState(0);
    const { currentLocale, setCurrentLocale } = useContext(LocalContext);
    const navigate = useNavigate();

    // Стан для контролю підменю
    const [anchorEl, setAnchorEl] = useState({});

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        setCurrentLocale(languages && languages[0]==='Eng' ? LOCALES.ENGLISH : LOCALES.UKRAINIAN);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLanguageClick = () => {
        setCurrentLocale(currentLocale === LOCALES.UKRAINIAN ? LOCALES.ENGLISH : LOCALES.UKRAINIAN);
    };

    const handleScroll = () => {
        setScrollPosition(window.pageYOffset);
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleMenuItemClick = (path) => {
        navigate(path);
    };

    const handleMenuOpen = (event, menuId) => {
        setAnchorEl({ ...anchorEl, [menuId]: event.currentTarget });
    };

    const handleMenuClose = (menuId) => {
        setAnchorEl({ ...anchorEl, [menuId]: null });
    };

    const renderMenuItem = (item, menuId) => (
        item.title && 
        <div key={item.title} className="menu-button" style={{height:'40px'}} >
            <Button color="inherit" size="large" onClick={item.arr ? (e) => handleMenuOpen(e, menuId) : () => handleMenuItemClick(item.lref)}>
                {item.title}
                {item.arr && (
                    <ArrowDropDownIcon style={{ marginRight: '-10px' }} />
                )}
            </Button>

          {item.arr && (
            <Menu
              anchorEl={anchorEl[menuId]}
              keepMounted
              open={Boolean(anchorEl[menuId])}
              onClose={() => handleMenuClose(menuId)}
            >
              {item.arr.map((subItem, index) => renderMenuItem(subItem, `${menuId}-${index}`))}
            </Menu>
          )}
        </div>
    );

    const renderMenuItems = (menu) => (
        <>
            {menu.map((item, index) => renderMenuItem(item, `menu-${index}`))}
        </>
    );

    const navBarStyle = {
        transition: '0.1s',
        _height: scrollPosition > 0 ? '60px' : '110px',
        // backgroundColor і color видалені, оскільки вони тепер в CSS
    };

    return (
        <Toolbar style={{backgroundColor: 'darkblue', color: 'white'}}>
                <div style={{width: '100%'}}>
                    {navbar.menu && navbar.menu.length>1 &&
                        <Box className="menu-buttons-container" >
                            {navbar.menu && renderMenuItems(navbar.menu)}
                        </Box>
                    }
                </div >

        </Toolbar>
    );
    
    
};

export default TLandingNavbar_MyStyle;
