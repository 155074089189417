export const getDocumentsRi = () => {
    return defaultData;
};
export const getDocumentsRi_2 = () => {
    return defaultData2;
};
export const getDocumentsRi_3 = () => {
    return defaultData3;
};
export const getDocumentsRi_4 = () => {
    return defaultData4;
};

const defaultData = [
    {
        title: "Стандартна Конституція Ротарі Клубу (2022)",
    },
    {
        title: "Правила Ротарі Інтернешнл (2022)",
    },
]

const defaultData2 = [
    {
        title: "Рекомендовані правила Ротарі Клубу (2019)",
        data: "",
        desc: "",
        url: ""
    },
    {
        title: "КОДЕКС ЗАСАД РОТАРІ жовтень 2023 року (розділи І-ІІІ,V)",
    },
    {
        title: "Конституція РІ",
    },
    {
        title: "Стандартна Конституція Ротаракт Клубу",
    },
    {
        title: "Рекомендовані Правила Ротаракт Клубу",
    },
    {
        title: "Стандартна Конституція Інтеракт Клубу",
    },
    {
        title: "Рекомендовані Правила Інтеракт Клубу",
    },
]

const defaultData3 = [
    {
        title: "Laws RI",
    },
    {
        title: "Constitution RI",
    },
    {
        title: "Rotary Code of Policies October 2023",
    },
    {
        title: "Посібник з правил (анг.)",
    },
    {
        title: "Рекомендовані Правила Ротарі клубу (анг.)",
    },
    {
        title: "Rotaract club standard constitution",
    },
    {
        title: "Standart constitution rotary club",
    },
    {
        title: "Rotaract club recommended laws",
    },
    {
        title: "Interact club standard constitution",
    },
    {
        title: "Interact club recommended laws",
    },
    {
        title: "ГЛОСАРІЙ РОТАРІЙСЬКИХ ТЕРМІНІВ (станом на 1 грудня 2022 р.)",
    },

]

const defaultData4 = [
    {
        title: "Популярні запитання щодо Клубів-сателітів NEW!",
    },
    {
        title: "Що дає створення облікового запису в My Rotary",
    },
    {
        title: "Зазначення цілей по членству в Rotary Club Central",
    },
]
