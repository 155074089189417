import React, {useState, useEffect} from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { Box, Container, Grid, Typography } from '@mui/material'
import { MdEmail } from 'react-icons/md';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import {getInternationalCommittee} from '../../api/getInternationalCommittee';
import '../Pages.css';

import MainGlobal from '../../modules/MainGlobal';
import LandingBlock from '../../components/LandingBlock';
import LandingHeader from '../../components/LandingHeader';

const InternationalPage = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const icc = await getInternationalCommittee();
          setData(icc);
          console.log("IccPage get ICC list success. const="+icc.length);
        } catch (error) {
          console.log("IccPage get ICC list error="+error);
        }
      };
      fetchData();
    }, [])

    const showLinks = (sites, title) => {
        if (!sites) {
          return title;
        }
      
        if (typeof sites === 'string') {
          // Якщо переданий один сайт (рядок), то генеруємо посилання на нього
          return (
            <a href={sites} target="_blank">
              {title}
            </a>
          );
        }
      
        if (Array.isArray(sites)) {
          return (
            <>
              {sites.map((item, index) => (
                <>
                <a key={item} href={item} target="_blank">
                  {title[index]}
                </a>,<br/>
                </>
              ))}
            </>
          );
        }
      
        return title;
      };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
        .then(() => {
            alert('Зміст скопійовано в буфер обміну');
        })
        .catch((error) => {
            console.error('Помилка при копіюванні в буфер обміну:', error);
        });
};

    return (
        <>
          <MainGlobal noWarningMode="hide" >
          <div style={{ position: 'relative' }}>
              <img src="https://res.cloudinary.com/tyko/image/upload/v1694632231/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/Screenshot_11_ujlfwd.jpg" height='100%' width='100%' alt="Background" />
              
              <Typography 
                variant="h3" 
                style={{
                  position: 'absolute',
                  width: '100%',
                  top: '12%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  padding: 2,
                  paddingTop: '50px',
                  color: 'white', // Задає колір тексту, якщо потрібно
                  backgroundColor: 'rgba(0, 0, 0, 0.3)', // Задає фон, якщо потрібно
                  fontSize: '6vw',
                  fontWeight: 'bold',
                }}
              >
                 <br/>Комітету міжнародного служіння
              </Typography>
              <Typography 
                variant="h3" 
                style={{
                  position: 'absolute',
                  width: '100%',
                  top: '2%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  padding: 2,
                  paddingTop: '50px',
                  color: 'white', // Задає колір тексту, якщо потрібно
                  _backgroundColor: 'rgba(0, 0, 0, 0.0)', // Задає фон, якщо потрібно
                  fontSize: 'max(2vw, 16pt)',
                }}
              >
                Особиста сторінка
              </Typography>
            </div>


            <LandingBlock mode='light2'>
            <Box className="section text-left" >
            <Container>
              <LandingHeader><Typography variant="h5" className="page-header">Про Ротарійський Комітет Міжнародного Служіння Д2232 </Typography></LandingHeader>
                  
                  <p>
                    ...</p>
              
              <LandingHeader><Typography variant="h5" className="page-header">Місія Комітету Міжнародного Служіння Д2232 </Typography></LandingHeader>
                                      
                                      <p>...</p>

                    
              <LandingHeader><Typography variant="h5" className="page-header">Мета Комітету Міжнародного Служіння Д2232</Typography></LandingHeader>
                                      
                                      <p>... </p>

                    <Box className="section text-left" >
            
            
            
                    <Grid container  direction="row" spacing={2}>
                        <Grid item md={3}>
                            <img src="https://res.cloudinary.com/tyko/image/upload/v1694629230/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/0-02-05-36c8f84d1800ac86c7b7fab37db52e98dc3a8f84056c4d834da8bbdf724cd43e_450a036aa21a2371_tw7wdj.jpg" width='100%'/>
                        </Grid>
                        <Grid item md={8}>
                            <h2>Ольга Палійчук, ДГН</h2>
                            <h2>Голова Комітету Міжнародного Служіння Д2232</h2>
                            
                        </Grid>
                    </Grid>
                    </Box>
                
            </Container>
            </Box>
            </LandingBlock>



            <LandingBlock mode='dark2'>
            <Box className="section text-left" >
            
                <Container>
                <Box>
                      <p>
                        
                      <h2> Члени Комітету 2023/2024:</h2>
                      </p>
                      <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Ім'я</TableCell>
                            <TableCell>Діяльність</TableCell>
                            <TableCell>Клуб</TableCell>
                            <TableCell>Контакти</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{index+1}</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{showLinks(row.site, row.role)}</TableCell>
                              <TableCell>{row.rc}</TableCell>
                              <TableCell>
                                <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                                <Tooltip title={'Пошта'}>
                                    <IconButton onClick={() => copyToClipboard(row.email)} aria-label="Facebook">
                                        <MdEmail color="blue" size='1.5em' />
                                    </IconButton>
                                    </Tooltip>
                                    <Tooltip title={'Телефон'}>
                                    <IconButton onClick={() => copyToClipboard(row.phone)} aria-label="Facebook">
                                        <PhoneIcon color="primary" size='1.5em' />
                                    </IconButton>
                                </Tooltip>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  
                  </Box>
                  </Container>



                  <Container>
                    <LandingHeader><Typography variant="h5" className="page-header">Документи:</Typography></LandingHeader>
                    <List component="nav">
                        <ListItem key={0} button onClick={() => window.open('https://drive.google.com/file/d/1fNckEQlWbKSDzZzsn8QW7Y8xbg1INw3c/view?usp=drive_link', '_blank')}
                                style={{ paddingTop: '4px', paddingBottom: '4px'}} >
                                -
                            <ListItemIcon>
                                <InsertDriveFileIcon color="primary" fontSize="large" style={{ marginRight: '0px', marginLeft: '10px' }} />
                            </ListItemIcon>
                            ABC for FAQs.pdf
                        </ListItem>
                        <ListItem key={0} button onClick={() => window.open('https://docs.google.com/document/d/1E2_mOioP8yLl-Ogp8rW74klsjcvm_M_W/edit?usp=drive_link&ouid=116503399666831052031&rtpof=true&sd=true', '_blank')}
                                style={{ paddingTop: '4px', paddingBottom: '4px'}} >
                                -
                            <ListItemIcon>
                                <InsertDriveFileIcon color="primary" fontSize="large" style={{ marginRight: '0px', marginLeft: '10px' }} />
                            </ListItemIcon>
                            Ротарійські молодіжні обміни.docx
                        </ListItem>
                    </List>

                  </Container>
            </Box>
            </LandingBlock>
            
            <LandingBlock mode='light2'>
            <Box className="section text-left" >
            <Container>
                <LandingHeader><Typography variant="h5" className="page-header">Ротарійські Товариства - Rotary Fellowships</Typography></LandingHeader>
                <p>Ротарійські Товариства обʼєднують людей за спільними інтересами до розважальних заходів, хобі, професії або культурної ідентичності. Ці групи допомагають розширювати навички, сприяти професійному розвитку та покращувати досвід Ротарі, досліджуючи інтереси та розвиваючи зв’язки по всьому світу.
                <br/><br/>ОГЛЯД:
                <lu>
                    <li>Допомагайте учасникам зав’язувати міцні дружні стосунки за межами власного клубу, району чи країни</li>
                    <li>Пропонуйте можливості міжнародного лідерства</li>
                    <li>Підвищення суспільного іміджу та ідентичності Ротарі</li>
                    <li>Познайомте друзів і членів родини з Ротарі</li>
                </lu>
                <lu>
                <br/>ОРГАНІЗАЦІЯ
                    <li>Приєднані до Ротарі Інтернешнл, але створюють власні адміністративні структури, планують і контролюють їх діяльність і заходи.</li>
                    <li>Відкриті для будь-якої зацікавленої особи.</li>
                    <li>Підтримуйте глобальну присутність і пропонуйте привабливі,
                    значущі події та заходи для членів Товариства.</li>
                </lu>
                <br/>ПОЧАТОК НОВОГО ТОВАРИСТВА
                <br/>Якщо поточні Ротарійські Товариства не відповідають вашим інтересам до відпочинку або професійної діяльності, 
                зв’яжіться з <a href='rotaryfellowships@rotary.org'>rotaryfellowships@rotary.org</a>, щоб отримати інформацію про створення нової групи.
                <br/><br/>ДОДАТКОВА ІНФОРМАЦІЯ
                <br/>Із запитаннями звертайтеся за адресою <a href='rotaryfellowships@rotary.org'>rotaryfellowships@rotary.org</a>. 
                Відвідайте <a href='www.rotary.org/fellowships'>rotary.org/fellowships</a>, щоб дізнатися більше про ресурси Ротарійських Товариств.
                <br/>
                <LandingHeader><Typography variant="h5" className="page-header">Документи:</Typography></LandingHeader>
                <List component="nav">
                    <ListItem key={0} button onClick={() => window.open('https://drive.google.com/file/d/1XbIVMxb2fBaNiCvgMM_LZq38vBattnjK/view?usp=drive_link', '_blank')}
                            style={{ paddingTop: '4px', paddingBottom: '4px'}} >
                            -
                        <ListItemIcon>
                            <InsertDriveFileIcon color="primary" fontSize="large" style={{ marginRight: '0px', marginLeft: '10px' }} />
                        </ListItemIcon>
                        rotary_fellowships_flier_en_1.pdf
                    </ListItem>
                </List>
                </p>  
            </Container>
            </Box>
            </LandingBlock>

            
            
          </MainGlobal>
        </>
  );
}

export default InternationalPage;