import { URL_TO_API_SERVER } from './env';
const API_URL = URL_TO_API_SERVER+"documents_communique.php"; // змініть на ваш URL
const API_URLs = URL_TO_API_SERVER+"documents_communique_urls.php"; // змініть на ваш URL

export interface Communique {
    id?: number;
    data: string;
    number: string;
    title: string;
    url: string;
}

export interface CommuniqueUrl {
    id?: number;
    comunique_id: number;
    title: string;
    url: string;
}

export const communiqueTableTitles = {
    id: 'id',
    data: 'data',
    number:'number',
    title: 'title',
    url: 'url',
}

export const communiqueURLsTableTitles = {
    id: 'id',
    comunique_id: 'comunique_id',
    title: 'title',
    url: 'url',
}
// Get all communiques
export async function getAllCommuniques(): Promise<Communique[]> {
    const response = await fetch(API_URL, {
        method: 'GET',
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseJson = await response.json();
    console.log('Server json:',responseJson);
    return responseJson;
}

// Get a specific communique by id
export async function getCommuniqueById(id: number): Promise<Communique> {
    const response = await fetch(`${API_URL}?id=${id}`, {
        method: 'GET',
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
}

/*export async function getAllUrls(): Promise<CommuniqueUrl[]> {
    const response = await fetch(API_URLs, {
        method: 'GET',
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseJson = await response.json();
    console.log('Server json:',responseJson);
    return responseJson;
}*/

// Create a new communique
export async function createCommunique(data: Communique): Promise<void> {
    console.log('createCommunique '+JSON.stringify(data));
    const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableType: 'create',
            data: data,
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseText = await response.text();
    console.log('Server createCommunique text:',responseText);
}

export async function createURLs(data: CommuniqueUrl): Promise<void> {
    console.log('createURLs '+JSON.stringify(data));
    const response = await fetch(API_URLs, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableType: 'create',
            data: data,
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseText = await response.text();
    console.log('Server createURLs text:',responseText);
}

// Update a communique
export async function updateCommunique(data: Communique): Promise<void> {
    console.log('createCommunique '+JSON.stringify(data));
    const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableType: 'update',
            data: data,
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseText = await response.text();
    console.log('Server updateCommunique text:',responseText);
}

export async function updateURLs(data: CommuniqueUrl): Promise<void> {
    console.log('updateURLs '+JSON.stringify(data));
    const response = await fetch(API_URLs, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableType: 'update',
            data: data,
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseText = await response.text();
    console.log('Server updateURLs text:',responseText);
}

// Delete a communique
export async function deleteCommunique(id: number): Promise<void> {
    console.log('deleteCommunique id='+id);
    const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableType: 'delete',
            id: id,
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseText = await response.text();
    console.log('Server deleteCommunique text:',responseText);
}

export async function deleteURLs(id: number): Promise<void> {
    console.log('deleteURLs id='+id);
    const response = await fetch(API_URLs, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableType: 'delete',
            id: id,
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseText = await response.text();
    console.log('Server deleteURLs text:',responseText);
}