import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, Button, Tooltip  } from '@mui/material'
import '../Pages.css';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Tabs, Tab } from '@mui/material';

import MyMainPage from "../../modules/MYMain";
import LandingBlock from '../../components/LandingBlock';
import FinancePage from './FinancePage';
import LandingHeader from '../../components/LandingHeader';

import { FormattedMessage } from 'react-intl';
import { getCommuniqueData } from "../../api/communiqueData";

import IMG_PNG_1 from '../../assets/img/icons/pdf_icon.png';

import ArchFileList from '../../components/ArchFileList';
import { getDocumentsRi, getDocumentsRi_2, getDocumentsRi_3, getDocumentsRi_4 } from "../../api/getDocumentsRi";

const TabPanel = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const TabPage = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div>
      <Tabs value={activeTab} onChange={handleTabChange} centered>
        <Tab label="Конституційні документи РІ" />
        <Tab label="Методичні матеріали" />
      </Tabs>

      <TabPanel value={activeTab} index={0}>        
            
            <LandingBlock mode='light'>
            <Box className="section text-left" >
            <Container>
            <LandingHeader><Typography variant="h4" className="page-header"><FormattedMessage id='КОНСТИТУЦІЙНІ ДОКУМЕНТИ РОТАРІ ІНТЕРНЕШНЛ' /></Typography></LandingHeader>
            <ArchFileList func={getDocumentsRi} />
            </Container>
            </Box>
            </LandingBlock>

            <LandingBlock mode='light'>
            <Box className="section text-left" >
            <Container>
            <LandingHeader><Typography variant="h4" className="page-header"><FormattedMessage id='Інщі офіційні документи:' /></Typography></LandingHeader>
            <ArchFileList func={getDocumentsRi_2} />
            </Container>
            </Box>
            </LandingBlock>

            <LandingBlock mode='light'>
            <Box className="section text-left" >
            <Container>
            <LandingHeader><Typography variant="h4" className="page-header"><FormattedMessage id='Оригінальні документами англійською:' /></Typography></LandingHeader>
            <ArchFileList func={getDocumentsRi_3} />
            </Container>
            </Box>
            </LandingBlock>

      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <Container>
        <LandingBlock mode='light'>
            <Box className="section text-left" >
            <Container>
            <LandingHeader><Typography variant="h4" className="page-header"><FormattedMessage id='Методичні матеріали РІ для Клубів' /></Typography></LandingHeader>
            <ArchFileList func={getDocumentsRi_4} />
            </Container>
            </Box>
        </LandingBlock>
        </Container>
      </TabPanel>

    </div>
  );
};

const DocumentsRiPage = () => {

  return (
    <MyMainPage>
      <LandingBlock mode='light'>
        <Box className="section text-left" >
            <TabPage />
        </Box>
      </LandingBlock>
    </MyMainPage>
  )
};

const ProjectActivePage = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const events = await getCommuniqueData();
          //console.log('getEventsData = '+JSON.stringify(events.data, null, 2));
          setData(events.data.reverse());
        } catch (error) {
          // Обробити помилку отримання даних
        }
      };

      fetchData();
    }, []);
  
    const toFileClick = (url) => {
      console.log("toFileClick");
      window.open(url, '_blank');
    }

    return (
        <>

            <Container>
            <Typography variant="h4" className="page-header">Комюніке 23-24</Typography>
                  <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#98c8ff" }}>
                        <TableCell></TableCell>
                        <TableCell><Typography variant="h6">Номер</Typography></TableCell>
                        <TableCell><Typography variant="h6">Назва комуніке</Typography></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data && data.map((row, index) => (//https://drive.google.com/file/d/1Bfx8ztWDmhvt09lk590LfswHvshFIDpL/view?usp=sharing
                        <TableRow key={index}>
                          <TableCell>
                            
                          </TableCell>
                          <TableCell>{row.number}</TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <Button onClick={() => toFileClick(row.url)}>
                                <img src={IMG_PNG_1} alt="Add" height='40px' className="icon-image" />
                              </Button>
                              <Typography variant="h6">{row.title}</Typography>
                            </Box>
                            
                            <Box display="flex" alignItems="center">
                            {row.urls && row.urls.map((r,i) => (
                              <Tooltip key={r.url} title={r.title} arrow>
                                <Box display="flex" alignItems="center">
                              <Button onClick={() => toFileClick(r.url)}>
                                <img src={IMG_PNG_1} alt="Add" height='24px' className="icon-image" />
                              </Button>
                                <Typography variant="subtitle2">{r.title.length > 15 ? `${r.title.slice(0, 15)}...` : r.title}</Typography>
                                </Box>
                              </Tooltip>
                            ))}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                  
              </Container>

        </>
  );
}

export default DocumentsRiPage;