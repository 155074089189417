
import AutorizePage from '../../LoginAndSuccess/AutorizePage';

const MyAutorizeFrame = ({userProfile, onLogined, children, isClubMember}) => {
    return (
        <>
        {userProfile 
            ? isClubMember ?
            children : <><br/> {userProfile.club}, вітаю! 
            <br/> Ви зареєстровані в клубі {userProfile.club} з логіном {userProfile.email}. 
            <br/>До цієї сторінки мають доступ тільки члени РК Черкаси.</>
            : <AutorizePage onLogined={onLogined} />
        }
        </>
    )
}

export default MyAutorizeFrame;