import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function MuiBreadcrumbs({struct1, titleLast}) {

    const breadcrumbsFromArray = (arr) => {
        return arr.join(" -> ");
    }

    const breadcrumbs = [];
    if (struct1) {
      breadcrumbs.push(
        <Link underline="hover" key="1" color="inherit" href={struct1.url}>
          {struct1.title}
        </Link>
      );
    }
    /* breadcrumbs.push(
      <Link
        underline="hover"
        key="2"
        color="inherit"
        href="/material-ui/getting-started/installation/"
        onClick={handleClick}
      >
        Core
      </Link>
    ); */
    breadcrumbs.push(
      <Typography key="3" color="text.primary">
        {Array.isArray(titleLast) ?
        "Меню: "+breadcrumbsFromArray(titleLast) : 
        "Меню: "+titleLast}
      </Typography>
    );

    
  return (
    <Stack spacing={2} sx={{background: 'white', paddingLeft: '12px'}}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
