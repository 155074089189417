import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams } from 'react-router-dom';

import {  useMediaQuery, useTheme } from '@mui/material';

import {getClubPageStructure} from './getClubPageStructure';
import {getMyClubPageStructure} from './getMyClubPageStructure';
import {LocalContext} from '../../../tools/LanguageContextProvider';
import TykoLanding from '../components/TykoLanding';

import { LOCALES } from '../../../i18n/locales';

const RcCherkasyMain = ({children, userProfile, onLogined, isClubMember}) => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [navbar, setNavbar] = useState(null);
    const [footer, setFooter] = useState(null);
    const [mynavbar, setMyNavbar] = useState(null);

    const { id } = useParams();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const  designType='chicago';

    useEffect(() => {
        setCurrentLocale(LOCALES.UKRAINIAN);

      const fetchData = async () => {
        try {
            await loadData(currentLocale);
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale])

    const loadData = async(locale) => {
        const page = getClubPageStructure(locale);
        setNavbar(page.navbar);
        setFooter(page.footer);
        
        const mypage = getMyClubPageStructure(locale);
        setMyNavbar(mypage.navbar);

        console.log("DanatePage get data list success. const="+page.length);
    }

    return (
        <>
          {navbar ? (
            <TykoLanding 
                navbar={navbar} 
                mynavbar={mynavbar} 
                noWarningMode="hide" 
                designType={designType} 
                footer={footer} 
                languages={["Укр"]} 
                userProfile={userProfile} 
                onLogined={onLogined} 
                isClubMember={isClubMember} >
              {/* props.breadcrumbs && <MuiBreadcrumbs struct1={null} titleLast={props.breadcrumbs}/> */}
              
            {children}
            </TykoLanding>
          ) : (
            "Loading..."
          )}
        </>
      );
      
      
}

export default RcCherkasyMain;