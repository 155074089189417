
import { LOCALES } from '../../../i18n/locales';

export const getEducationLandingHome = (local) => {
    const resData = local === LOCALES.UKRAINIAN ? resData_ukr : resData_eng;
    return resData;
};

const resData_eng = [
    
    {
        id: 0,
        header: 'Про феловшип:',
        arrcontent: [
            {
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/EducationFellowship/Icons/EducationFelowship.jpg',
                content: [
                    {LandingMessage: `We invite you to view the Showcase of District 2232 projects! It was created especially for the Congress in Singapore!`},
                ],
            },
        ]        
    },
    {
        id: 1,
        header: 'Мета та цілі:',
        arrcontent: [
            {
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/EducationFellowship/Icons/EducationFelowship.jpg',
                content: [
                    {LandingMessage: `Мета Rotary Education Fellowship полягає в підтримці освітніх ініціатив, сприянні розвитку лідерських якостей та підвищенні кваліфікації в глобальному масштабі. Наші цілі включають підтримку освітніх програм, надання стипендій та сприяння обміну знаннями та досвідом.`},
                ],
            },
        ]        
    },
    {
        id: 2,
        header: 'Історія:',
        arrcontent: [
            {
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/EducationFellowship/Icons/EducationFelowship.jpg',
                content: [
                    {LandingMessage: `Rotary Education Fellowship був заснований у [рік] році з метою створення спільноти освітян та професіоналів, які прагнуть сприяти розвитку освіти в усьому світі. З того часу феловшип виріс і став важливим гравцем у сфері глобальної освіти.`},
                ],
            },
        ]        
    },
    {
        id: 3,
        header: 'Проекти:',
        arrcontent: [
            {
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/EducationFellowship/Icons/EducationFelowship.jpg',
                content: [
                    {LandingMessage: ` Rotary Education Fellowship підтримує широкий спектр освітніх проектів, включаючи програми стипендій, наукові дослідження, розвиток педагогічних практик та сприяння доступності освіти для всіх. Наші проекти орієнтовані на підвищення якості освіти та розвиток лідерських навичок.`},
                ],
            },
        ]        
    },
    {
        id: 4,
        header: 'Як приєднатися:',
        arrcontent: [
            {
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/EducationFellowship/Icons/EducationFelowship.jpg',
                content: [
                    {LandingMessage: `Приєднуйтеся до нас, щоб стати частиною спільноти освітян та професіоналів, які прагнуть змінювати світ через освіту. Заповніть нашу онлайн-форму та долучайтеся до нашої програми, щоб мати можливість впливати на майбутнє освіти.`},
                ],
            },
        ]        
    },
    {
        id: 5,
        header: 'Команда:',
        arrcontent: [
            {
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/EducationFellowship/Icons/EducationFelowship.jpg',
                content: [
                    {LandingMessage: `Керівництво Rotary Education Fellowship складається з досвідчених освітян та лідерів, які прагнуть розвивати ініціативи у сфері освіти. Наша команда працює над реалізацією цілей феловшипу та підтримкою наших учасників.`},
                ],
            },
        ]        
    },
    {
        id: 6,
        header: 'Новини та події:',
        arrcontent: [
            {
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/EducationFellowship/Icons/EducationFelowship.jpg',
                content: [
                    {LandingMessage: `Відстежуйте останні новини та події Rotary Education Fellowship. Ми регулярно оновлюємо інформацію про наші проекти, заходи та досягнення, щоб ви могли бути в курсі всіх подій.`},
                ],
            },
        ]        
    },
    {
        id: 7,
        header: `Зв'яжіться з нами:`,
        arrcontent: [
            {
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/EducationFellowship/Icons/EducationFelowship.jpg',
                content: [
                    {LandingMessage: `Маєте питання або бажаєте долучитися до Rotary Education Fellowship? Зв'яжіться з нами за допомогою форми зв'язку нижче або скористайтеся контактною інформацією для отримання додаткової інформації. Будемо раді почути від вас!`},
                ],
            },
        ]        
    },
];


const resData_ukr = [
    {
        id: 0,
        header: 'Привіт, дорогі ротарійці!',
        content: [
            {LandingMessage: `Запрошуємо до перегляду Вітрини проектів Дистрикту 2232, створеної спеціально для Конгресу в Сінгапурі!`},
        ],
    },
];