import React, { useState } from "react";

import Admin_LandingMessage from '../LandingComponents/Admin_LandingMessage';
import LandingButtonDetails from '../../../../components/LandingButtonDetails';
import LandingButtonDetailsRI from '../../../../components/LandingButtonDetailsRI';
import LandingButtonLink from '../../../../components/LandingButtonLink';
import LandingList from '../../../../components/LandingList';
import LandingImage from '../../../../components/LandingImage';
import LandingImgTools from '../../../../components/LandingImgTools';
import LandingLink from '../../../../components/LandingLink';
import LandingButtonIcon from '../../../../components/LandingButtonIcon';
import LandingAccordionItem from '../../../../components/LandingAccordionItem';
import LandingMatrixPrinterPaper from '../../../../components/LandingMatrixPrinterPaper';

const Admin_LandingContentItems = ({ content }) => {

    const onChange = () => {
        
    }
    
    return (
        content && content.map((contItem, index) => (
            <React.Fragment key={index}>
            {contItem.LandingMessage && <Admin_LandingMessage id={contItem.LandingMessage} />}
            {contItem.LandingButtonDetails && <LandingButtonDetails link={contItem.link} onChange={onChange} />}
            {contItem.LandingButtonDetailsRI && <LandingButtonDetailsRI link={contItem.link} />}
            {contItem.LandingList && <LandingList arr={contItem.LandingList} type='dot' />}
            {contItem.LandingButtonLink && <LandingButtonLink link={contItem.link} text={contItem.LandingButtonLink} variant={contItem.variant} />}
            {contItem.LandingImage && <LandingImage link={contItem.link} />}
            {contItem.LandingImgTools && <LandingImgTools link={contItem.link} title={contItem.LandingImgTools} />}
            {contItem.LandingLink && <LandingLink link={contItem.link} text={contItem.LandingLink} br={contItem.br}/>}
            {contItem.LandingButtonIcon && <LandingButtonIcon text={contItem.LandingButtonIcon} action={contItem.action} desc={contItem.desc} copyText={contItem.copyText} />}
            {contItem.LandingAccordionItem && <LandingAccordionItem title={contItem.LandingAccordionItem } text={contItem.text} itemArr={contItem.itemArr} />}
            {contItem.LandingMatrixPrinterPaper && <LandingMatrixPrinterPaper bg={contItem.tebgxt} itemArr={contItem.itemArr} />}
            {contItem.BR && <br />}
            </React.Fragment>
        ))
    );
    
};

export default Admin_LandingContentItems;
