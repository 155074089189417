import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';

const LandingImage = ({link, fullsize}) => {
    const navigate = useNavigate();
    return (
        <>
        {fullsize ? 
        <img src={link} width='100%' style={{ marginTop: '8px', marginBottom: '8px'}} />
        :
        <img src={link} width='100%' style={{maxWidth: '350px', marginTop: '8px', marginBottom: '8px'}} />
        }
        </>
    );
};

export default LandingImage;
