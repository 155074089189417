import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const LAccordionItem = ({ title, children, defaultExpanded }) => {
    return (
        <Accordion defaultExpanded={defaultExpanded} sx={{ boxShadow: 'none', '&:before': { display: 'none' } }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"
                sx={{ flexDirection: "row-reverse", borderBottom: 'none' }}
            >
            <Typography variant='h6'>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    );
  };

export default LAccordionItem;
