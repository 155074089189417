
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { MdEmail } from 'react-icons/md';
import {handleCopyToClipboard} from '../functions/toolsFuncs';
import { FaGlobe } from 'react-icons/fa';

const LcButtonWeb = ({url,size}) => {

    return (
        <Tooltip title={'Website'}>
        <IconButton onClick={() => window.open(url, '_blank')} aria-label="Website">
            <FaGlobe color="blue" fontSize={size} />
        </IconButton>
        </Tooltip>
    );
}

export default LcButtonWeb;