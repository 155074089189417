import React, { useEffect, useState, useContext, createContext } from "react";
import { useNavigate } from 'react-router-dom';

import './Navbar.css';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Menu, MenuItem, Divider, IconButton, Drawer, useMediaQuery, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { LocalContext } from '../tools/LanguageContextProvider';

import MenuIcon from '@mui/icons-material/Menu';
import { LOCALES } from '../i18n/locales';

import D2232LOGO from '../assets/img/D2232.png';

const NavbarGlobal = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    const [scrollPosition, setScrollPosition] = useState(0);
    const { currentLocale, setCurrentLocale } = useContext(LocalContext);
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [anchorEl4, setAnchorEl4] = useState(null);
    const navigate = useNavigate();

    const handleLanguageClick = () => {
        console.log("Клик по кнопке языка");
        setCurrentLocale(currentLocale === LOCALES.UKRAINIAN ? LOCALES.ENGLISH : LOCALES.UKRAINIAN);
    };

    const handleLoginClick = () => {
        
    };

    const handleMenuItemClick = (page) => {
        console.log("Клик по MenuItem = "+page);
        navigate(page);
        //window.location.reload();

        /*const currentPath = window.location.pathname;
        const newPath = page;
    
        // Перезавантаження сторінки, якщо посилання активне
        if (currentPath === newPath) {
          window.location.reload();
        } else {
          navigate(newPath);
        }*/
    };

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const handleAboutRIClick = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleAboutD2232Click = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleForMembersClick = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const handleMyRotaryUaClick = (event) => {
        setAnchorEl4(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl1(null);
      setAnchorEl2(null);
      setAnchorEl3(null);
      setAnchorEl4(null);
    };
    
    const toMyRotaryUAClick = () => {
        console.log("toMyRotaryUAClick");
        window.open('/myhome', '_blank');
    }
    const toOpenNewWindowClick = (link) => {
        console.log("toOpenNewWindowClick");
        window.open(link, '_blank');
      }
    const toMeansBusinesClick = () => {
      console.log("toMeansBusinesClick");
      window.open('https://bc.rotary.org.ua', '_blank');
    }
    const toBrandCentrUAClick = () => {
      console.log("toMeansBusinesClick");
      window.open('http://imagetools.tyko.com.ua', '_blank');
    }
    const toCoordcomUAClick = () => {
        console.log("toMeansBusinesClick");
        window.open('https://rotary.tyko.com.ua/myrequests', '_blank');
    }
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
      };

    const menuItems = (
        <>
            <Button color="inherit" onClick={() => toOpenNewWindowClick('https://rotary.org.ua')}><FormattedMessage id='Ротарі в Україні' /></Button>|
            <Button color="inherit" onClick={() => handleMenuItemClick('/international')}><FormattedMessage id='Про Комітет' /></Button>|
            <Button color="inherit" onClick={handleAboutRIClick}><FormattedMessage id='Події' /></Button>
            <Menu
              anchorEl={anchorEl1}
              keepMounted
              open={Boolean(anchorEl1)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleMenuItemClick('/event1')}>
                  <FormattedMessage id='Семінар Комітету міжнародного служіння' />
              </MenuItem>
            </Menu>
        </>
    )

    const navBarStyle = {
        transition: '0.1s', // add transition
        _height: scrollPosition > 0 ? '60px' : '110px',
        backgroundColor: '#ffffff', 
        color: '#000080',
    };

    return (
        <AppBar position="sticky" sx={navBarStyle}>
            <Toolbar>
            <Box sx={{ display: 'flex', alignItems: "center", gap:'18px', width: '100%'}}>
                <Box sx={{ display: 'flex', alignItems: 'left', height: "60px"}}>
                    <img src={D2232LOGO} style={{padding: "8px", marginLeft: "0px"}}  alt="Логотип" />
                </Box>
                <Box className="text-container">
                <Box><span className="title"><FormattedMessage id='menuTitle' /></span></Box>
                {isMobile ? (
                  <>
                    <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                      <MenuIcon />
                    </IconButton>
                    <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                      {menuItems}
                    </Box>
                    </Drawer>
                  </>
                ) : (
                  menuItems
                )}
                </Box>
                <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                    <Button size="small" color="inherit" onClick={handleLanguageClick}>{currentLocale}</Button>
                </Box>
            </Box>
            </Toolbar>
        </AppBar>
    );
};

export default NavbarGlobal;

