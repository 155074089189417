
const translations = (words) => {
    return words;
}

export const getMyD2232PageMenuStructure = (local) => {

    return {
        navbar: {
            title: translations("My Rotary UA"),
            logoUrl: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/logos/1706183889947-426467125.png",
            languages: [
                "ua-UA",
            ],
            autorization: "true",
            menu: [
                {
                    title: translations("Головна"),
                    lref: '/myhome',
                    devstatus: 'testing',
                },
                {
                    title: translations("Дистрикт 2232"),
                    arr: [
                        { 
                            title: translations("Проєкти"),
                            lref: '/projects',
                        },
                        { 
                            title: translations("Події"),
                            lref: '/myevents',
                        },
                        { 
                            title: translations("Ротарі клуби"),
                            lref: '/myclubs',
                        },/*
                        { 
                            title: translations("МДК Комітети"),
                            lref: '/icc',
                        },*/
                    ]
                },
                {
                    title: translations("Спілка"),
                    arr: [
                        { 
                            title: translations("Орг структура Спілки"),
                            lref: '/committees',
                        },
                        { 
                            title: translations("Склад Координційного Комітету"),
                            lref: '/coordcom',
                        },
                    ]
                },
                {
                    title: translations("Документи"),
                    devstatus: 'testing',
                    arr: [
                        { 
                            title: translations("Документи РІ"),
                            lref: '/documentsRI',
                        },
                        { 
                            title: translations("Комюніке"),
                            lref: '/communique',
                            devstatus: 'testing',
                        },
                        { 
                            title: translations("Листи Голів Комітетів"),
                            lref: '/letters',
                        },
                        { 
                            title: translations("Фінасові документи"),
                            lref: '/finance',
                            devstatus: 'testing',
                        },
                    ]
                },
                {
                    title: translations("Матеріали"),
                    arr: [
                        { 
                            title: translations("Презентації конференцій"),
                            lref: '/conferences',
                        },
                        { 
                            title: translations("Презентації семінарів"),
                            lref: '/seminars',
                        },
                    ]
                },
                {
                    title: translations("Адміністрування"),
                    arr: [
                        { 
                            title: translations("Адміністрування ротарійця"),
                            lref: '/myprofile',
                        },
                        { 
                            title: translations("Адміністрування клубу"),
                            lref: null,
                        },
                        { 
                            title: translations("Адміністрування Д2232"),
                            lref: '/myd2232',
                        },
                    ]
                },
                {
                    title: translations("Інші сайти"),
                    devstatus: 'testing',
                    arr: [
                        { 
                            title: translations("rotary.org.ua"),
                            lref: 'https://rotary.org.ua',
                            devstatus: 'testing',
                        },
                        { 
                            title: translations("rotary.org"),
                            lref: 'https://rotary.org',
                            devstatus: 'testing',
                        },
                        { 
                            title: translations("Brand Center UA"),
                            lref: 'https://imagetools.tyko.com.ua',
                        },
                        { 
                            title: translations("Learning Center UA"),
                            lref: 'https://imagetools.tyko.com.ua',
                        },
                        { 
                            title: translations("Кабінет Координаційного Комітету"),
                            lref: 'https://rotary.tyko.com.ua/myrequests',
                        },
                    ]
                },
            ],
        },
        footer: {
            contactPerson_: {
                name: translations("Контактна особа:"),
                email: "oncology@2upost.com",
                email2: "paliychuk.oncology@gmail.com",
            },
            social: {
                // fb: "fb1",
                // insta: "insta1",
                // linkedIn: "linkedIn1",
            }
        }
    }
}
