import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { injectIntl, FormattedMessage } from 'react-intl';  // якщо ви використовуєте react-intl

const LandingMessage = ({ id, intl, noCollapse }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const defaultMessage = intl.formatMessage({ id }) || "";  
    const maxLenght = 400;
    const isTextLong = !noCollapse && defaultMessage.length > maxLenght;
    const displayedText = isTextLong && !isExpanded ? defaultMessage.substring(0, maxLenght) + '...' : defaultMessage;

    const linkify = (text) => {
        const urlRegex = /(\b(http|https):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
        const parts = text.split(urlRegex);
    
        return parts.map((part, index) => {
            if (part.match(urlRegex)) {
                return <a href={part} target="_blank" rel="noopener noreferrer" key={index}>лінк</a>;
            } else {
                // Перевірка на непотрібні частини URL (http, https)
                if(part !== "http" && part !== "https") {
                    return part;
                }
            }
        });
    };

    return (
        <div className='landing_text'>
            {linkify(displayedText)}
            {isTextLong && (
                <div>
                    <Button 
                        sx={{ textTransform: 'none' }}
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        <FormattedMessage id={isExpanded ? 'btn_less' : 'btn_more'} />
                    </Button>
                </div>
            )}
        </div>
    );
};

export default injectIntl(LandingMessage); // Огорнуто компонент HOC
