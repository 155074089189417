import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from '@mui/material'
import './Pages.css';

import MainPage from "../components/Main";
import LandingBlock from '../components/LandingBlock';
import ButtonToRI from '../components/ButtonToRI';

import RI_1 from '../assets/content/home/RI_1.png'

const AboutRiPage = () => {
    return (
        <>
          <MainPage>
            <LandingBlock mode='light'>
            <Box className="section text-left" >
            
              <Container>
              <Typography variant="h4" className="page-header">Про Ротарі Інтернейшнл</Typography>
                  <Grid container spacing={2}>
                      <Grid item md={8}>
                        <p>Ротарі Інтернейшнл є міжнародною організацією, створеною для вирішення проблем місцевих та глобальних спільнот, надання гуманітарної допомоги, просування миру та покращення життєвих умов людей. Ротарі в Україні - це частина глобальної мережі професіоналів, лідерів та людей, які бажають служити суспільству і робити світ кращим.
                        </p>
                        <h3>Наша місія
                        </h3>
                        <p>Ми об'єднуємося, щоб створити позитивні зміни. Ротарі клуби в Україні фокусуються на розвитку проектів у таких областях, як освіта, здоров'я, боротьба з бідністю, довкілля та культура. Ми прагнемо досягти реальних результатів, що можуть змінити життя людей в наших місцевих спільнотах і за їх межами.
                        </p>
                        <h3>Наше лідерство
                        </h3>
                        <p>Кожен Ротарі клуб в Україні керується принципами служіння, лідерства, інтегритету, дружби і толерантності. Ми розвиваємо лідерські якості у своїх членах, надаючи їм можливість віддавати себе служінню суспільству і приймати рішення, які формують майбутнє наших спільнот.
                        </p>
                        <h3>Наше товариство
                        </h3>
                        <p>Ми віримо в силу дружби і товариства. Ротарі клуби в Україні - це місце, де люди різного віку, професій, релігій та культур об'єднуються для реалізації спільних цілей. Ми навчаємося один від одного, обмінюємося ідеями і досвідом, робимо світ кращим разом.
                        </p>
                        <p>Якщо ви вірите в служіння, лідерство та товариство, якщо ви готові працювати заради покращення світу, ми запрошуємо вас приєднатися до нашого руху. Ротарі Інтернейшнл в Україні - це ваш шанс зробити різницю.
                        </p>
                        <p>Давайте разом робити світ кращим!
                        </p>
                      </Grid>
                  </Grid>
                  <ButtonToRI link="" text="Більше інформації на сайті РІ" />
                  <br/><br/>
                  <Grid container spacing={2}>
                      <Grid item xs={8}>
                          <img src={RI_1} width='100%' alt="" />
                      </Grid>
                  </Grid>
              </Container>
            </Box>
            </LandingBlock>
            
          </MainPage>
        </>
  );
}

export default AboutRiPage;