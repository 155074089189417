import React, { useEffect, useState, useContext, createContext } from "react";
import { useNavigate } from 'react-router-dom';

import './Navbar.css';


import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LoginIcon from '@mui/icons-material/Login';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Menu, MenuItem, Divider, Drawer, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { LocalContext } from '../tools/LanguageContextProvider';

import MenuIcon from '@mui/icons-material/Menu';
import { LOCALES } from '../i18n/locales';

import MultiAccountMenu from './MultiLogin/MultiAccountMenu';
const MyNavbar_TempShare = ({navbar, userProfile, onLogined, languages}) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [scrollPosition, setScrollPosition] = useState(0);
    const { currentLocale, setCurrentLocale } = useContext(LocalContext);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState({});

    useEffect(() => {
        setCurrentLocale(languages && languages[0]==='Eng' ? LOCALES.ENGLISH : LOCALES.UKRAINIAN);
    }, []);

    const handleLanguageClick = () => {
        console.log("Клик по кнопке языка");
        setCurrentLocale(currentLocale === LOCALES.UKRAINIAN ? LOCALES.ENGLISH : LOCALES.UKRAINIAN);
    };
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const toExternalLinkClick = (url) => {
        console.log("toMeansBusinesClick");
        window.open(url, '_blank');
    }

    const handleScroll = () => {
        setScrollPosition(window.pageYOffset);
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleMenuItemClick = (path) => {
        if (path.startsWith('http')) {
            window.open(path, '_blank');
        } else {
            navigate(path);
        }
    };

    const handleMenuOpen = (event, menuId) => {
        setAnchorEl({ ...anchorEl, [menuId]: event.currentTarget });
    };

    const handleMenuClose = (menuId) => {
        setAnchorEl({ ...anchorEl, [menuId]: null });
    };

    const renderMenuItem = (item, menuId) => (
        <div>
        {(item.devstatus==='testing' || userProfile?.email==="tykogm2@gmail.com") && 
        <div key={item.title} className="my-menu-button">
            <Button color="inherit" onClick={item.arr ? (e) => handleMenuOpen(e, menuId) : () => handleMenuItemClick(item.lref)}>
                <FormattedMessage id={item.title} />
                {item.arr && (
                    <ArrowDropDownIcon style={{ marginRight: '-10px' }} />
                )}
            </Button>

          {item.arr && (
            <Menu
              anchorEl={anchorEl[menuId]}
              keepMounted
              open={Boolean(anchorEl[menuId])}
              onClose={() => handleMenuClose(menuId)}
            >
              {item.arr.map((subItem, index) => renderMenuItem(subItem, `${menuId}-${index}`))}
            </Menu>
          )}
        </div>
        }
        </div>
      );

    const renderMenuItems = (menu) => (
        <>
            {menu.map((item, index) => renderMenuItem(item, `menu-${index}`))}
        </>
    );

    const menuItems = (
        <Box className="text-container">
                        
                        {isMobile ? (
                          <>
                            <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                              <MenuIcon />
                            </IconButton>
                            {navbar?.menu && navbar.menu.length>1 &&
                            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                                <Box className="mobile-menu-container">
                                    {navbar.menu && renderMenuItems(navbar.menu)}
                                </Box>
                            </Drawer>}
                          </>
                        ) : (
                            <>
                            {navbar?.menu && navbar.menu.length>1 &&
                            <Box className="my-menu-buttons-container" >
                                {navbar.menu && renderMenuItems(navbar.menu)}
                            </Box>}
                            </>
                        )}
        </Box>
    )
    
    const navBarStyle = {
        transition: '0.1s', // add transition
        _height: scrollPosition > 0 ? '60px' : '110px',
        _backgroundColor: '#ffffff', 
        _color: '#000080',
    };

    //==================================================
    const onLoginSuccess = (userData) => {

        //setUserProfile(userData);
        console.log("====== onLoginSuccess="+(userData && userData.email));
        onLogined(userData);
    }
    const onLogoutSuccess = () => {
        //setUserProfile(null);
        onLogined(null);
    }

    return (
        <AppBar position="sticky" sx={navBarStyle}>
            <Toolbar>
            <Box sx={{ display: 'flex', alignItems: "center", gap:'18px', width: '100%'}}>
                <Box sx={{ display: 'flex', alignItems: 'left', height: "60px"}}>
                    <img src="https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/1707318206588-895329449.png" style={{padding: "8px", marginLeft: "0px"}}  alt="Логотип" />
                </Box>
                <Box className="text-container">
                <Box><span className="title"><FormattedMessage id='My Rotary UA' /></span></Box>
                
                <>
                {isMobile ? (
                  <>
                    <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                      <MenuIcon />
                    </IconButton>
                    <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                      {menuItems}
                    </Box>
                    </Drawer>
                  </>
                ) : (
                  menuItems
                )}
                </>
                
                </Box>
                
                {/*<Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                    {//<Button size="small" color="inherit" onClick={() => handleMenuItemClick('/donate')}><FormattedMessage id='menuDonate' /></Button>
                    }
                    <Button size="small" color="inherit" onClick={() => toExternalLinkClick('https://my.rotary.org/en/club-search')}><FormattedMessage id='Пошук на РІ' /></Button>
                    { !languages &&
                    <Button size="small" color="inherit" onClick={handleLanguageClick}>{currentLocale === LOCALES.UKRAINIAN ? 'Eng' : 'Укр'}</Button>
                    }
                </Box>*/
}
                {/*<MultiAccountMenu userProfile={userProfile} onLoginSuccess={onLoginSuccess} onLogoutSuccess={onLogoutSuccess} />*/
                }
                {/*false && !userProfile &&<GoogleLoginButton onLoginSuccess={onLoginSuccess} />}
                <AccountMenu userProfile={userProfile} onLoginSuccess={onLoginSuccess} onLogoutSuccess={onLogoutSuccess} />*/}
            </Box>
            </Toolbar>
        </AppBar>
    );
};

export default MyNavbar_TempShare;
