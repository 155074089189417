import {getMenuStructure} from '../bdFunctions/getMenuStructure';
import {getPagesContent} from '../bdFunctions/getPagesContent';
import {getPagesContent_projects} from '../bdFunctions/getPagesContent_projects';

import RcClubPageMain from '../../ClubPageComponents/RcClubPageMain';

const RcLvivRatushaPage = () => {
    const  designType='chicago';

    return (
        <RcClubPageMain 
            menufunc={getMenuStructure} 
            pagesContent={getPagesContent}
            getPagesContent_projects={getPagesContent_projects}
            designType={designType}
        >
        </RcClubPageMain>
    );
}

export default RcLvivRatushaPage;