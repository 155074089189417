export const getMenuStructure = (local) => {
    return data_eng;
}

const data_eng = {

    navbar: {
        title: "РК Львів-Ратуша",
        //logoUrl: "https://res.cloudinary.com/tyko/image/upload/v1704542422/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/share/RC_Cherkasy_trimed_q9pd9g.png",
        logoUrl: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/LogoAllClubs/RC_Lviv-Ratusha.png',
        // languages: [
        //     "ua-UA",
        //     "en-US",
        // ],
        // autorization: "true",
        addlogoborder: true,
        withoutd2232url: true, 
        menu: [
            {
                title: "About Us",
                arr: [
                    { 
                        title: "Overview",
                        lref: '/rc_lvivratusha/overview',
                    },
                    { 
                        title: "History",
                        lref: '/rc_lvivratusha/history',
                    },
                    { 
                        title: "Members",
                        lref: '/rc_lvivratusha/members',
                    },
                ]
            },
            {
                title: "Projects",
                lref: '/rc_lvivratusha/projects',
            },
            {
                title: "Community",
                arr: [
                    { 
                        title: "Rotary International",
                        lref: '/rc_lvivratusha/community1',
                    },
                    { 
                        title: "Twin&Sister Clubs",
                        lref: '/rc_lvivratusha/community2',
                    },
                    { 
                        title: "District 2232",
                        lref: '/rc_lvivratusha/community3',
                    },
                ]
            },
        ],
    },
    footer: {
        contactPerson_: {
            name: "Priamry contacts:",
            email: "email1",
        },
        social: {
            fb: "fb1",
            insta: "insta1",
            linkedIn: "linkedIn1",
        }
    }
}
