import {getRotarians} from './getRotarians';

export const getBusinessCouncil = (isUkraine) => {
    return isUkraine ? defaultData_ukr : defaultData_eng;
};

const arrList1_ukr = [
    {"LandingListItem": "Територія цілковитої довіри: нетворкінґ і заходи — інструменти, а не мета", url: ""},
    {"LandingListItem": "Позитивна ділова репутація як пріоритетний критерій членства", url: ""},
    {"LandingListItem": "Формування середовища, яке об'єднує людей із спільними цінностями і життєвою філософією та розвиває професійну еліту країни", url: ""},
    {"LandingListItem": "Кожний член ради є активним учасником її життя, завдяки чому може активно розвивати свої професійні та моральні цінності", url: ""},
];

const arrList2_1_ukr = [
    {"LandingListItem": "Обмін інформацією, що становить взаємний інтерес"},
    {"LandingListItem": "Розвиток ділових контактів, проведення бізнес-форумів, виставок, В2В зустрічей, круглих столів, конференцій, вебінарів"},
    {"LandingListItem": "Організація візитів торгово-економічних делегацій та бізнесових асоціацій"},
    {"LandingListItem": "Стажування фахівців та налагодження прямих контактів в сферах економіки та фінансів"},
    {"LandingListItem": "Підписання меморандумів про співпрацю та взаєморозуміння з представниками міжнародних бізнес-структур"},
    {"LandingListItem": "Налагодження менторської, інвестиційної та грантової підтримки українського бізнесу"},
    {"LandingListItem": "Створення Кластеру ротарійського бізнесу"},
];
const arrList2_2_ukr = [
    {"LandingListItem": "Покращення економічних умов, що впливають на відносини між ротарійцями різних дистриктів"},
    {"LandingListItem": "Організація та проведення тренінгів, вокшопів, круглих столів, індивідуальних та колективних консультацій з експертами"},
    {"LandingListItem": "Подолання перешкод та обмежень, які заважають розвитку економічних відносин та веденню спільного бізнесу між ротарійцями з різних країн"},
    {"LandingListItem": "Створення Rotary Business Relocation Academy – платформи, яка об’єднає довкола себе підприємців, котрі припинили/призупинили свій бізнес через повномасштабне вторгнення росії в Україну та релокувалися до Західного регіону України"},
];
const arrList2_3_ukr = [
    {"LandingListItem": "Розробка клубних, міжклубних, дистриктальних та міжнародних бізнесових проектів"},
    {"LandingListItem": "Формування та впровадження проектів Ротарі клубів та їх дофінансування на основі краудфандингу та грантової підтримки"},
    {"LandingListItem": "Обмін інформацією про бізнеси ротарійців (готелі, ресторани, продукція, послуги) з метою отримання преференцій та знижок для ротарійців"},
];
const arrList5_1_ukr = [
    {"LandingListItem": "можливість поглибити ділові зв’язки з представниками бізнесу, громадських організацій, які представляють інтереси промисловців та підприємців"},
    {"LandingListItem": "розвиток мережі контактів із представниками міжнародного бізнесу, щоб вийти на нові ринки, залучити інвестиції, вивчити потенціал конкретної сторони, знайти партнерів"},
    {"LandingListItem": "поїздки в іноземні клуби Rotary International, щоб познайомитись з бізнесом закордонних колег, укласти контракти, вивчити найкращі бізнес-практики інших країн"},
    {"LandingListItem": "участь у форумах та переговорах, міжнародних бізнес-турах, зустрічах з іноземними делегаціями та послами"},
    {"LandingListItem": "отримання менторської підтримки та участь у навчальних сесіях топових бізнесменів"},
    {"LandingListItem": "напрацювання перспективних та інноваційних проєктів"},
    {"LandingListItem": "проведення розважальних, сімейних, корпоративних, виїзних заходів, які надають можливість ближче познайомитись, зблизитись, розвинути довготривалі відносини в ротарійській родині та поза її межами"},
    {"LandingListItem": "можливість отримати персональні умови на послуги, що надаються членами Ділової ради"},
];
const arrList7_1_ukr = [
    {"LandingListItem": "доступ до наставників та інших бізнес-професіоналів"},
    {"LandingListItem": "можливість проходити курси у навчальному центрі BNI Business Builder"},
    {"LandingListItem": "розвиток тривалих довірливих взаємин з іншими членами BNI"},
    {"LandingListItem": "підготовка та запуск спільних бізнесових проектів"},
    {"LandingListItem": "участь у заходах BNI-спільноти"},
    {"LandingListItem": "створення відділення BNI у Львові"},
];
const arrList7_3_ukr = [
    {"LandingListItem": "Доступ до наставників та інших бізнес-професіоналів"},
    {"LandingListItem": "Інформаційна платформа для реклами своїх послуг і можливостей"},
    {"LandingListItem": "Можливість створювати повноцінні дискусійні форуми та поглиблені тематичні бесіди"},
    {"LandingListItem": "Стипендіальна підтримка ротарійців"},
    {"LandingListItem": "Створення регіонального осередку Rotary Means Business Chapter"},
];

const defaultData_ukr = [
    {
        id: 0,
        header: 'Про Ділову Раду',
        img: 'https://bc.rotary.org.ua/images/bussiness_councis/1.webp',
        content: [
            {LandingMessage: "«Це спільнота особистостей, які зреалізували себе у професійному житті, створена для організації більш тісніших бізнес-знайомств, навчання та обміну досвідом між членами Ділової ради. Маючи більш ніж сторічну історію Ротарійського руху в світі, у тому числі на теренах України ми можемо похвалитися бездоганною репутацією членів наших клубів. Серед них є відомі і поважні представники різних професій та бізнесу. На наших підприємствах в Україні працюють десятки тисяч працівників ми пишаємося тим, що є частинкою всесвітньовідомої організації, членів якої поважають та шанують у всьому світі. У ротарійській спільноті у 200 країнах світу сформувалася потужна родина з різнобічними професійними інтересами. Серед ротарійців є велика кількість власників середнього та великого бізнесу, які охоче діляться історіями свого успіху, шляхами досягнення мети, принципами ділового життя, помилками і досягненнями та надають рекомендації по веденню бізнесу», – Олексій Дегтяренко, голова Ділової Ради Ротарі дистрикту 2232"},
        ],
    },
    {
        id: 1,
        header: 'Наша філософія',
        arrcontent: [
            {
                //heder: 'Ми спільнота особистостей, а не компаній і статусів:',
                img: 'https://bc.rotary.org.ua/images/filph/1.webp',
                content: [
                    {LandingMessage: "Ми спільнота особистостей, а не компаній і статусів"},
                    {LandingList: arrList1_ukr},
                ],
            },
        ]    
    },
    {
        id: 2,
        header: 'Наші цілі',
        arrcontent: [
            {
                //heder: 'Ми спільнота особистостей, а не компаній і статусів:',
                img: 'https://bc.rotary.org.ua/images/goals/1.webp',
                content: [
                    {LandingMessage: "1. Розвиток ротарійського бізнесу"},
                    {LandingList: arrList2_1_ukr},
                ],
            },
            {
                img: 'https://bc.rotary.org.ua/images/goals/2.webp',
                content: [
                    {LandingMessage: "2. Формування іміджу та розширення впливу представників ротарійського бізнесу"},
                    {LandingList: arrList2_2_ukr},
                ],
            },
            {
                img: 'https://bc.rotary.org.ua/images/goals/3.webp',
                content: [
                    {LandingMessage: "3. Реалізація спільних бізнес-проектів Ротарі"},
                    {LandingList: arrList2_3_ukr},
                ],
            },
        ]    
    },
    {
        id: 3,
        header: 'Правління Ділової Ради',
        arrcontent: [
            {
                img: 'https://bc.rotary.org.ua/images/manage/1.webp',
                content: [
                    {LandingMessage: "У Діловій раді є правління, яке визначає стратегії та ключові напрямки розвитку її діяльності, здійснює нагляд за дотриманням принципів та кодексу етики, відбір кандидатів та затвердження нових членів, представлення інтересів спільноти у відносинах з іншими організаціями, ініціювання і розвиток нових проектів та програм Ради."},
                ],
            },
            {
                id: 4,
                cards: [
                    {
                        img: 'https://res.cloudinary.com/tyko/image/upload/v1700465091/BetaRotarySite/%D0%A4%D0%BE%D1%82%D0%BE%20%D0%A0%D0%BE%D1%82%D0%B0%D1%80%D1%96%D0%B9%D1%86%D1%96%D0%B2/%D1%84%D0%BE%D1%80%D1%83%D0%BC_12_izalhq.jpg',
                        content: [
                            {LandingMessage: "- голова Ділової Ради Ротарі у Дистрикті 2223"},
                            {LandingMessage: "- президент Ротарі клубу «Львів»"},
                            {LandingMessage: "- керівник адвокатського об’єднання «Дегтяренко та партнери»"},
                        ],
                        name: "Олексій Дегтяренко",
                        phone: "+38067-340-8272",
                        email: "advokatam@i.ua",
                    },
                    {
                        img: 'https://bc.rotary.org.ua/assets/site/images/managers/OBqb2Mijw3.webp',
                        content: [
                            {LandingMessage: "- заступник голови Ділової Ради Ротарі з економічних питань"},
                            {LandingMessage: "- член Ротарі клубу «Україна Єдність»"},
                            {LandingMessage: "- керівник ТзОВ «Європейська консалтингова корпорація»"},
                        ],
                        name: "Уляна Глас",
                        phone: "+38096-960-9696",
                        email: "ecconsult@ukr.net",
                    },
                    {
                        img: 'https://bc.rotary.org.ua/assets/site/images/managers/pLOW4YE7LS.webp',
                        content: [
                            {LandingMessage: "- заступник голови Ділової Ради Ротарі з організаційних питань"},
                            {LandingMessage: "- Елект-Президент Ротарі клубу «Харків Інтернешнл»"},
                            {LandingMessage: "- керівник ТОВ «ПСВ-Антік» та компанії «Grafix»"},
                        ],
                        name: "Сергій Пилипів",
                        phone: "+38066-712-0543",
                        email: "Pilipiv@gmail.com",
                    },
                    // {
                    //     img: 'https://res.cloudinary.com/tyko/image/upload/v1701097869/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0/photo_2023-11-27_17-10-52_um5vlj.jpg',
                    //     content: [
                    //         {LandingMessage: "- заступник голови Ділової ради з міжнародних зв’язків"},
                    //         {LandingMessage: "- виконавчий директор Ротаракт Клубу Львів"},
                    //         {LandingMessage: "- керівник БІЦ «Центр комерціалізації інновацій»"},
                    //     ],
                    //     name: "Олена Лукачук",
                    //     phone: "+380976686936",
                    //     email: "olena0708lukachuk@gmail.com",
                    // },
                    {
                        img: 'https://res.cloudinary.com/tyko/image/upload/v1689000839/AvatarMaker/snfy3wdgjtl3dy3llcdi.jpg',
                        content: [
                            {LandingMessage: "- заступник голови Ділової ради з інформаційних технологій"},
                            {LandingMessage: "- голова ІТ Комітету Д2232"},
                            {LandingMessage: `- Ротарі Клуб Львів-Ратуша"`},
                        ],
                        name: "Олег Косьмина",
                        phone: "+380996332351",
                        email: "o.kosmyna.rotary@gmail.com",
                    },
                    {
                        img: "https://res.cloudinary.com/tyko/image/upload/v1703666769/BetaRotarySite/business_council/0-02-05-3e2ab8fe8dc97b4ca4cc9e9654beea0dfc81eb12dac2a8a1619b7b7d04fd0dea_61142b2eceaa19a0_ix2epw.jpg",
                        content: [
                            {LandingMessage: "- заступник голови Ділової ради з питань членства"},
                            {LandingMessage: "- Ротарі Клуб Львів"},
                            {LandingMessage: ``},
                        ],
                        name: "Микола Манюх",
                        phone: "+380964947361",
                        email: "",
                    },
                    // {
                    //     img: "https://res.cloudinary.com/tyko/image/upload/v1703666771/BetaRotarySite/business_council/photo_2023-12-27_10-39-48_2_gdes1t.jpg",
                    //     content: [
                    //         {LandingMessage: "- заступник голови Ділової ради з напряму молодіжного підприємництва"},
                    //         {LandingMessage: "- Ротаракт Клуб Львів"},
                    //         {LandingMessage: ``},
                    //     ],
                    //     name: "Анастасія Шкіль",
                    //     phone: "+380961422548",
                    //     email: "nastaskil27@gmail.com",
                    // },
                    
                ],
            },
        ],
    },
    {
        id: 4,
        header: 'Члени Ділової Ради',
        cards2: getRotarians(),
    },
    {
        id: 5,
        header: 'Чому варто приєднатися до нас?',
        arrcontent: [
            {
                imgs: [
                    'https://bc.rotary.org.ua/images/why/1.webp',
                    //'https://bc.rotary.org.ua/images/why/2.webp',
                    //'https://bc.rotary.org.ua/images/why/3.webp',
                    'https://bc.rotary.org.ua/images/why/4.webp',
                ],
                content: [
                    {LandingList: arrList5_1_ukr},
                ],
            },
        ]    
    },
    {
        id: 6,
        header: 'Наші ініціативи',
        arrcontent: [
            {
                header: 'Зустрічі, конференції, круглі столи',
                PhotoCarusel: [
                    'https://bc.rotary.org.ua/images/starts/1.webp',
                    'https://bc.rotary.org.ua/images/starts/3.webp',
                    'https://bc.rotary.org.ua/images/starts/4.webp',
                    'https://bc.rotary.org.ua/images/starts/2.webp',
                ],
            },
            {
                header: 'Міжнародні та всеукраїнські онлайн-форуми та Zoom-сесії',
                PhotoCarusel: [
                    'https://bc.rotary.org.ua/images/starts/5.webp',
                    'https://bc.rotary.org.ua/images/starts/6.webp',
                    'https://bc.rotary.org.ua/images/starts/7.webp',
                ],
            },
            {
                header: 'Організація міжнародних ротарійських бізнес-форумів',
                PhotoCarusel: [
                    'https://bc.rotary.org.ua/images/starts/8.webp',
                    'https://bc.rotary.org.ua/images/starts/10.webp',
                    'https://bc.rotary.org.ua/images/starts/10.webp',
                    'https://bc.rotary.org.ua/images/starts/9.webp',
                ],
            },
            {
                header: 'Проведення бізнес-сніданків',
                PhotoCarusel: [
                    'https://bc.rotary.org.ua/images/starts/12.webp',
                ],
            },
        ]    
    },
    {
        id: 7,
        header: 'Міжнародна співпраця та партнерство',
        arrcontent: [
            {
                header: 'Проведення бізнес-сніданків',
                img: 'https://bc.rotary.org.ua/images/world/1.webp',
                content: [
                    {LandingList: arrList7_1_ukr},
                ],
            },
            {
                header: 'Зустріч із Гремом Вайміллером, головою BNI (Business Network International) ',
                img: 'https://bc.rotary.org.ua/images/world/2.webp',
                content: [
                    {LandingMessage: '6 січня 2023 року, м. Львів'},
                ],
            },
            {
                header: 'Співпраця з Rotary Means Business',
                img: 'https://bc.rotary.org.ua/images/world/4.webp',
                content: [
                    {LandingList: arrList7_3_ukr},
                ],
            },
        ]    
    },
    {
        id: 8,
        header: 'Календар подій 2023',
        arrcontent: [
            {
                tablecontent: [
                    {
                        tablerow: [
                            {LandingMessage: "Cічень"},
                            {LandingMessage: "Зустріч із Гремом Вайміллером, головою BNI (Business Network International)"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "Cічень"},
                            {LandingMessage: "Зустріч з представниками Ротарі-клубів Ярослава та Вроцлава, Ротарійською бізнес спільнотою Польщі"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "Лютий"},
                            {LandingMessage: "Круглий стіл «Розвиток навичок у кластерах»"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "Лютий"},
                            {LandingMessage: "Організація кластеру підтримки бізнесу спільно з обласними військовими адміністраціями"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "Березень"},
                            {LandingMessage: "Засідання Ділової Ради Ротарі Інтернешнл Д2232"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "Березень"},
                            {LandingMessage: "Zoom-зустріч з представниками Rotary Means Business"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "Квітень"},
                            {LandingMessage: "Zoom-зустріч з представниками BNI (Business Network International"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "Травень"},
                            {LandingMessage: "Ротарійський бізнес-сніданок"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "Червень"},
                            {LandingMessage: "Воркшоп «Юридичні та бухгалтерські аспекти ведення бізнесу для Ротарійської спільноти та релокованого бізнесу»"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "Липень"},
                            {LandingMessage: "Круглий стіл «Інструменти підтримки малого та середнього бізнесу для зміцнення економічних відносин між Україною та ЄС»"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "Серпень"},
                            {LandingMessage: "Ротарійський бізнес-сніданок"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "Вересень"},
                            {LandingMessage: "Zoom-зустріч «Європейські експерти для українського бізнесу: досвід Польщі»"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "Жовтень"},
                            {LandingMessage: "Zoom-зустріч «Європейські експерти для українського бізнесу: досвід Німеччини»"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "Жовтень"},
                            {LandingMessage: "Форум Комітету професійного служіння", url: "/event_BusinessCouncil"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "Листопад"},
                            {LandingMessage: "Діловий Ротарійський форум-2023"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "Листопад"},
                            {LandingMessage: "Участь у Західноукраїнському форумі партнерства"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "Грудень"},
                            {LandingMessage: "Презентація програми Кластеру ротарійського бізнесу"},
                        ],
                    },
                ],
            },
        ],
    },
    // {
    //     id: 6,
    //     header: 'Майбутні події:',
    //     arrcontent: [
    //         {
    //             id: 1,
    //             LandingCardEvent: `true`,
    //         }
    //     ]
    // },
    {
        id: 7,
        header: 'Останні події:',
        arrcontent: [
            
            {
                id: 1,
                LandingCardEvent: `1`,
            },
            {
                header: '25 січня 2024 року команда Ділової ради спільно з партнерами ПАТ Кредобанк і БФ «Фундація Кредо» провели Ротарійський бізнес-сніданок на тему: «Маркетингові стратегії в умовах війни»',
                PhotoCarusel: [
                    'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/BusinessCouncil/1710427161222-859440257.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/BusinessCouncil/1710427172856-534136279.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/BusinessCouncil/1710427172858-991479118.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/BusinessCouncil/1710427172859-286077289.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/BusinessCouncil/1710427172860-150360533.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/BusinessCouncil/1710427184558-977437089.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/BusinessCouncil/1710427184559-273139548.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/BusinessCouncil/1710427184560-619423176.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/BusinessCouncil/1710427184560-933421888.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/BusinessCouncil/1710427184561-377612599.jpg',
                ],
                content: [
                {LandingMessage: `Правильний бізнес-сніданок у найкрасивішій локації одного з відділень KredoBank  у Львові дозволив об’єднати цікаві ідеї, креативні підходи, правильних людей та дружні інституції довкола теми підтримки бізнесу, інноваційних розробок, грантових програм, маркетингу та переваг банківського сервісу Craft Banking.
                Спілкувалися про програми підтримки бізнесу від ПАТ «Кредобанк», інноваційні інструменти, маркетингові підходи та особливості введення бізнесу в такий нелегкий для кожного українського підприємця.
                До заходу долучилися понад 20 Ротарійців і Ротарактівців. Кожен із учасників поділився власним досвідом запуску маркетологічної стратегії свого бізнесу. Було озвучено чимало проблем й окреслено шляхи їх вирішення.
                Приємно, що наші заходи стають цікавими для Ротарійців з різних регіонів і цього разу в нас були представниці Ротарійської родини  з Луцька: Єлена Абрамович та Юлія Гринчук, які спеціально приїхали взяти участь у Ротарійському бізнес-сніданку. Це без сумніву додає впевненості у тому, що наша команда робить правильні й потрібні заходи.
                Хочемо висловити слова вдячності нашим партнерам – представникам Кредобанку, зокрема Оксані Стецьків, виконавчій директорці ПАТ «Кредобанк», Андрію Мельнику, керівнику маркетингу Кредобанку, Соломії Говорусі, голові БФ «Фундація Кредо», за теплий прийом, цікаві доповіді, смачне частування, красиву й головне безпечну локацію.
                Вже традиційно до наших заходів долучається команда Департаменту економічної політики Львівської обласної військової адміністрації. Цього разу Орест Гринів, заступник директора департаменту-начальник управління промисловості, підприємництва та інвестиційної політики, проінформував учасників бізнес-сніданку про програми підтримки бізнесу, актуальні в 2024 році.
                `},
                ]
            },
            {
                header: 'Ділова Рада виступила партнером конференції  «Сталий розвиток ЄС: кращі практики для України» ',
                PhotoCarusel: [
                    'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/BusinessCouncil/1710427486237-433841558.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/BusinessCouncil/1710427494138-630122224.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/BusinessCouncil/1710427486238-203078801.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/BusinessCouncil/1710427486238-570688907.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/BusinessCouncil/1710427494138-402838084.jpg',
                ],
                content: [
                    {LandingMessage: `22 лютого 2024 року в Національному університеті «Львівська політехніка» в рамках реалізації проєкту «Європейські студії для технічних спеціальностей у Національному університеті «Львівська політехніка» (EUSTS) за підтримки програми ЄС Еразмус+ напряму Жан Моне було проведено інтернет-конференцію «Сталий розвиток ЄС: кращі практики для України». Серед партнерів заходу була Ділова Рада Дистрикту 2232 Ротарі Інтернешнл.
                    `},
                    {LandingFileIcon: "pdf", text: "Партнер конференції.doc", 
                         link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/BusinessCouncil/1710427754137-518343718.doc"},
                    {BR: true},
                    {LandingFileIcon: "pdf", text: "Програма конференції.pdf", 
                         link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/BusinessCouncil/1710427754137-769163713.pdf"},
                ]
            },
        ]
    },

    /*{
        id: 9,
        header: 'Наші контакти',
        cards: [
            {
                img: 'https://bc.rotary.org.ua/images/footer/1.webp',
                content: [
                    {LandingMessage: "Локація -  м. Львів, Україна"},
                ],
                name: "Олексій Дегтяренко, Голова Ділової Ради",
                phone: "+38067-340-8272",
                email: "advokatam@i.ua",
            },
            
        ],
    },*/
]

//====================================
const arrList1_eng = [
    {"LandingListItem": "A territory of complete trust: networking and events are tools, not the goal", url: ""},
    {"LandingListItem": "Positive business reputation as a priority criterion for membership", url: ""},
    {"LandingListItem": "Creating an environment that brings together people with common values and life philosophy, and develops the professional elite of the country", url: ""},
    {"LandingListItem": "Every council member is an active participant in its life, thus being able to actively develop their professional and moral values", url: ""},
];

const arrList2_1_eng = [
    {"LandingListItem": "Exchange of mutually interesting information"},
    {"LandingListItem": "Development of business contacts, organization of business forums, exhibitions, B2B meetings, round tables, conferences, webinars"},
    {"LandingListItem": "Organization of visits by trade and economic delegations and business associations"},
    {"LandingListItem": "Internship for specialists and establishing direct contacts in the fields of economy and finance"},
    {"LandingListItem": "Signing of memoranda of cooperation and understanding with representatives of international business structures"},
    {"LandingListItem": "Setting up mentorship, investment, and grant support for Ukrainian business"},
    {"LandingListItem": "Creation of the Rotary Business Cluster"},
];
const arrList2_2_eng = [
    {"LandingListItem": "Improving economic conditions affecting relations between Rotarians of different districts"},
    {"LandingListItem": "Organization and conduct of trainings, workshops, round tables, individual and group consultations with experts"},
    {"LandingListItem": "Overcoming obstacles and limitations that hinder the development of economic relations and joint business among Rotarians from different countries"},
    {"LandingListItem": "Establishment of the Rotary Business Relocation Academy – a platform that will unite entrepreneurs who ceased/suspended their business due to the full-scale invasion of Russia into Ukraine and relocated to the Western region of Ukraine"},
];
const arrList2_3_eng = [
    {"LandingListItem": "Development of club, interclub, district, and international business projects"},
    {"LandingListItem": "Formation and implementation of Rotary club projects and their co-financing based on crowdfunding and grant support"},
    {"LandingListItem": "Exchange of information about Rotarian businesses (hotels, restaurants, products, services) with the aim of obtaining preferences and discounts for Rotarians"},
];
const arrList5_1_eng = [
    {"LandingListItem": "Opportunity to deepen business ties with representatives of businesses, public organizations that represent the interests of industrialists and entrepreneurs"},
    {"LandingListItem": "Development of a contact network with representatives of international business to enter new markets, attract investments, study the potential of a particular side, and find partners"},
    {"LandingListItem": "Trips to foreign Rotary International clubs to get acquainted with the business of foreign colleagues, conclude contracts, study the best business practices of other countries"},
    {"LandingListItem": "Participation in forums and negotiations, international business tours, meetings with foreign delegations and ambassadors"},
    {"LandingListItem": "Receiving mentorship support and participation in training sessions of top businessmen"},
    {"LandingListItem": "Working on prospective and innovative projects"},
    {"LandingListItem": "Organizing entertainment, family, corporate, and outbound events that provide an opportunity to get acquainted more closely, get closer, and develop long-term relationships in the Rotary family and beyond"},
    {"LandingListItem": "Opportunity to receive personal terms for services provided by members of the Business Council"},
];

const arrList7_1_eng = [
    {"LandingListItem": "Access to mentors and other business professionals"},
    {"LandingListItem": "Opportunity to take courses at the BNI Business Builder training center"},
    {"LandingListItem": "Development of long-term trust relationships with other BNI members"},
    {"LandingListItem": "Preparation and launch of joint business projects"},
    {"LandingListItem": "Participation in BNI community events"},
    {"LandingListItem": "Establishment of a BNI branch in Lviv"},
];

const arrList7_3_eng = [
    {"LandingListItem": "Access to mentors and other business professionals"},
    {"LandingListItem": "Information platform for advertising your services and opportunities"},
    {"LandingListItem": "Opportunity to create full-fledged discussion forums and in-depth thematic conversations"},
    {"LandingListItem": "Scholarship support for Rotarians"},
    {"LandingListItem": "Creation of a regional center for Rotary Means Business Chapter"},
];


const defaultData_eng = [
    {
        "id": 0,
        "header": "About the Business Council",
        "img": "https://bc.rotary.org.ua/images/bussiness_councis/1.webp",
        "content": [
            {
                "LandingMessage": "“This is a community of individuals who have realized themselves in professional life, created to organize closer business acquaintances, training, and exchange of experience among members of the Business Council. Having more than a century-old history of the Rotary movement in the world, including in Ukraine, we can boast an impeccable reputation of our club members. Among them are well-known and respected representatives of various professions and businesses. Tens of thousands of employees work at our enterprises in Ukraine; we are proud to be a part of a globally recognized organization whose members are respected and honored around the world. In the rotary community in 200 countries worldwide, a powerful family with diverse professional interests has formed. Among Rotarians, there is a large number of medium and large business owners who willingly share stories of their success, ways to achieve goals, principles of business life, mistakes and achievements, and provide recommendations for doing business», - Oleksiy Dehtyarenko, Chairman of the Rotary Business Council District 2232."
            }
        ]
    },
    {
        "id": 1,
        "header": "Our Philosophy",
        "arrcontent": [
            {
                "img": "https://bc.rotary.org.ua/images/filph/1.webp",
                "content": [
                    {"LandingMessage": "We are a community of individuals, not companies and statuses"},
                    {"LandingList": arrList1_eng}
                ]
            }
        ]
    },
    {
        "id": 2,
        "header": "Our Goals",
        "arrcontent": [
            {
                "img": "https://bc.rotary.org.ua/images/goals/1.webp",
                "content": [
                    {"LandingMessage": "1. Development of Rotary business"},
                    {"LandingList": arrList2_1_eng}
                ]
            },
            {
                "img": "https://bc.rotary.org.ua/images/goals/2.webp",
                "content": [
                    {"LandingMessage": "2. Forming the image and expanding the influence of Rotary business representatives"},
                    {"LandingList": arrList2_2_eng}
                ]
            },
            {
                "img": "https://bc.rotary.org.ua/images/goals/3.webp",
                "content": [
                    {"LandingMessage": "3. Implementation of joint Rotary business projects"},
                    {"LandingList": arrList2_3_eng}
                ]
            }
        ]
    },
    {
        id: 3,
        header: 'Management of the Business Council',
        arrcontent: [
            {
                img: 'https://bc.rotary.org.ua/images/manage/1.webp',
                content: [
                    {LandingMessage: "The Business Council has a management that defines strategies and key development directions of its activity, supervises adherence to principles and ethics code, selects candidates and approves new members, represents the community's interests in relations with other organizations, initiates and develops new projects and programs of the Council."},
                ],
            },
            {
                id: 4,
                cards: [
                    {
                        img: 'https://bc.rotary.org.ua/assets/site/images/managers/JokQGmM3wB.webp',
                        content: [
                            {LandingMessage: "- Chairman of the Rotary Business Council in District 2223"},
                            {LandingMessage: "- President of Rotary Club “Lviv”"},
                            {LandingMessage: "- Head of the law association “Degtjarenko and partners”"},
                        ],
                        name: "Oleksiy Dehtyarenko",
                        phone: "+38067-340-8272",
                        email: "advokatam@i.ua",
                    },
                    {
                        img: 'https://bc.rotary.org.ua/assets/site/images/managers/OBqb2Mijw3.webp',
                        content: [
                            {LandingMessage: "- Deputy Chairman of the Rotary Business Council on economic issues"},
                            {LandingMessage: "- Member of Rotary Club “Ukraine Unity”"},
                            {LandingMessage: "- Head of LLC “European Consulting Corporation”"},
                        ],
                        name: "Ulyana Glas",
                        phone: "+38096-960-9696",
                        email: "ecconsult@ukr.net",
                    },
                    {
                        img: 'https://bc.rotary.org.ua/assets/site/images/managers/pLOW4YE7LS.webp',
                        content: [
                            {LandingMessage: "- Deputy Chairman of the Rotary Business Council on organizational issues"},
                            {LandingMessage: "- Elect-President of the Rotary Club “Kharkiv International”"},
                            {LandingMessage: "- Head of LLC “PSV-Antik” and “Grafix” company"},
                        ],
                        name: "Sergiy Pilipiv",
                        phone: "+38066-712-0543",
                        email: "Pilipiv@gmail.com",
                    },
                    // {
                    //     img: 'https://res.cloudinary.com/tyko/image/upload/v1701097869/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0/photo_2023-11-27_17-10-52_um5vlj.jpg',
                    //     content: [
                    //         {LandingMessage: "- Deputy Head of the Business Council for International Relations"},
                    //         {LandingMessage: "- Executive Director of the Rotaract Club Lviv"},
                    //         {LandingMessage: `- Head of BIC "Center for Innovation Commercialization"`},
                    //     ],
                    //     name: "Olena Lukachuk",
                    //     phone: "+380976686936",
                    //     email: "olena0708lukachuk@gmail.com",
                    // },
                    {
                        img: 'https://res.cloudinary.com/tyko/image/upload/v1689000839/AvatarMaker/snfy3wdgjtl3dy3llcdi.jpg',
                        content: [
                            {LandingMessage: "- Deputy Head of the Business Council for Information Technology"},
                            {LandingMessage: "- Head of IT Committee D2232"},
                            {LandingMessage: `- Rotary Club Zaporizhzhia-Khortytsia"`},
                        ],
                        name: "Oleh Kosmyna",
                        phone: "+380996332351",
                        email: "o.kosmyna.rotary@gmail.com",
                    },
                    {
                        img: "https://res.cloudinary.com/tyko/image/upload/v1703666769/BetaRotarySite/business_council/0-02-05-3e2ab8fe8dc97b4ca4cc9e9654beea0dfc81eb12dac2a8a1619b7b7d04fd0dea_61142b2eceaa19a0_ix2epw.jpg",
                        content: [
                            {LandingMessage: "- Deputy Head of the Business Council for Membership"},
                            {LandingMessage: "- Rotary Club Lviv"},
                            {LandingMessage: ``},
                        ],
                        name: "Mykola Maniukh",
                        phone: "+380964947361",
                        email: "",
                    },
                    // {
                    //     img: "https://res.cloudinary.com/tyko/image/upload/v1703666771/BetaRotarySite/business_council/photo_2023-12-27_10-39-48_2_gdes1t.jpg",
                    //     content: [
                    //         {LandingMessage: "- Deputy Head of the Business Council for Youth Entrepreneurship"},
                    //         {LandingMessage: "- Rotaract Club Lviv"},
                    //         {LandingMessage: ``},
                    //     ],
                    //     name: "Anastasiia Shkil",
                    //     phone: "+380961422548",
                    //     email: "nastaskil27@gmail.com",
                    // },
                    
                ],
            },
        ],
    },
    {
        id: 4,
        header: 'Members of the Business Council',
        cards2: getRotarians(),
    },
    {
        "id": 5,
        "header": "Why Join Us?",
        "arrcontent": [
            {
                "imgs": [
                    "https://bc.rotary.org.ua/images/why/1.webp",
                    "https://bc.rotary.org.ua/images/why/4.webp"
                ],
                "content": [
                    {
                        "LandingList": arrList5_1_eng
                    }
                ]
            }
        ]
    },
    {
        "id": 6,
        "header": "Our Initiatives",
        "arrcontent": [
            {
                "header": "Meetings, Conferences, Round Tables",
                "PhotoCarusel": [
                    "https://bc.rotary.org.ua/images/starts/1.webp",
                    "https://bc.rotary.org.ua/images/starts/3.webp",
                    "https://bc.rotary.org.ua/images/starts/4.webp",
                    "https://bc.rotary.org.ua/images/starts/2.webp"
                ]
            },
            {
                "header": "International and Nationwide Online Forums and Zoom Sessions",
                "PhotoCarusel": [
                    "https://bc.rotary.org.ua/images/starts/5.webp",
                    "https://bc.rotary.org.ua/images/starts/6.webp",
                    "https://bc.rotary.org.ua/images/starts/7.webp"
                ]
            },
            {
                "header": "Organization of International Rotary Business Forums",
                "PhotoCarusel": [
                    "https://bc.rotary.org.ua/images/starts/8.webp",
                    "https://bc.rotary.org.ua/images/starts/10.webp",
                    "https://bc.rotary.org.ua/images/starts/10.webp",
                    "https://bc.rotary.org.ua/images/starts/9.webp"
                ]
            },
            {
                "header": "Hosting Business Breakfasts",
                "PhotoCarusel": [
                    "https://bc.rotary.org.ua/images/starts/12.webp"
                ]
            }
        ]
    },
    {
        "id": 7,
        "header": "International Cooperation and Partnership",
        "arrcontent": [
            {
                "header": "Hosting Business Breakfasts",
                "img": "https://bc.rotary.org.ua/images/world/1.webp",
                "content": [
                    {
                        "LandingList": arrList7_1_eng
                    }
                ]
            },
            {
                "header": "Meeting with Graham Weihmiller, Head of BNI (Business Network International)",
                "img": "https://bc.rotary.org.ua/images/world/2.webp",
                "content": [
                    {
                        "LandingMessage": "January 6, 2023, Lviv city"
                    }
                ]
            },
            {
                "header": "Collaboration with Rotary Means Business",
                "img": "https://bc.rotary.org.ua/images/world/4.webp",
                "content": [
                    {
                        "LandingList": arrList7_3_eng
                    }
                ]
            }
        ]
    },
    {
        "id": 8,
        "header": "Events Calendar 2023",
        "arrcontent": [
            {
                "tablecontent": [
                    {
                        "tablerow": [
                            {"LandingMessage": "January"},
                            {"LandingMessage": "Meeting with Graham Weihmiller, Head of BNI (Business Network International)"}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "January"},
                            {"LandingMessage": "Meeting with representatives of Rotary clubs from Jarosław and Wrocław, and the Polish Rotary Business Community"}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "February"},
                            {"LandingMessage": "Roundtable “Skill Development in Clusters”"}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "February"},
                            {"LandingMessage": "Organization of the business support cluster in collaboration with regional military administrations"}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "March"},
                            {"LandingMessage": "Rotary International D2232 Business Council Meeting"}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "March"},
                            {"LandingMessage": "Zoom meeting with representatives of Rotary Means Business"}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "April"},
                            {"LandingMessage": "Zoom meeting with representatives of BNI (Business Network International)"}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "May"},
                            {"LandingMessage": "Rotary Business Breakfast"}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "June"},
                            {"LandingMessage": "Workshop “Legal and Accounting Aspects of Business Management for the Rotary Community and Relocated Business”"}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "July"},
                            {"LandingMessage": "Roundtable “Support Tools for Small and Medium-sized Businesses to Strengthen Economic Relations between Ukraine and the EU”"}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "August"},
                            {"LandingMessage": "Rotary Business Breakfast"}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "September"},
                            {"LandingMessage": "Zoom meeting “European Experts for Ukrainian Business: Experience of Poland”"}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "October"},
                            {"LandingMessage": "Zoom meeting “European Experts for Ukrainian Business: Experience of Germany”"}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "October"},
                            {"LandingMessage": "Professional Service Committee Forum", "url": "/event_BusinessCouncil"}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "November"},
                            {"LandingMessage": "Rotary Business Forum-2023"}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "November"},
                            {"LandingMessage": "Participation in the West Ukrainian Partnership Forum"}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "December"},
                            {"LandingMessage": "Presentation of the Rotary Business Cluster program"}
                        ]
                    }
                ]
            }
        ]
    },
    /*{
        "id": 9,
        "header": "Our Contacts",
        "cards": [
            {
                "img": "https://bc.rotary.org.ua/images/footer/1.webp",
                "content": [
                    {"LandingMessage": "Location - Lviv, Ukraine"},
                ],
                "name": "Oleksiy Dehtyarenko, Chairman of the Business Council",
                "phone": "+38067-340-8272",
                "email": "advokatam@i.ua",
            }
        ]
    }*/
];

