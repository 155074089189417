import React, { useState } from "react";
import { Box, Typography, Container } from '@mui/material';
import ScrollTrigger from 'react-scroll-trigger';
import { FormattedMessage } from 'react-intl';

import LandingBlock from '../../../../components/LandingBlock';
import LandingHeader from '../../../../components/LandingHeader';
import LandingCards2List from '../../../../components/LandingCards2List';
import LandingCardsList from '../../../../components/LandingCardsList';

import Admin_LandingContentItems from './Admin_LandingContentItems';

const Admin_LandingContentBlock = ({ id, landItem, designType, children }) => {
    const [isImageVisible, setImageVisible] = useState(false);
    const [isContentVisible, setContentVisible] = useState(false);
    
    const isEvenId = id % 2 === 0;
    let mode;
    switch (designType) {
        case 'business':
            mode = isEvenId ? "light4" : "dark4";
            break;
        case 'chicago':
            mode = isEvenId ? "light3" : "dark3";
            break;
        case 'simple':
        case 'simple2':
            mode = isEvenId ? "light5" : "dark3";
            break;
        default:
            mode = isEvenId ? "light2" : "light";
    }
    const imagePosition = (id % 2 === 0 ? "left" : "right");

    const handleImageScrollProgress = (progress) => {
        if (progress.progress > 0.2) {
            setImageVisible(true);
        }
    };

    const handleContentScrollProgress = (progress) => {
        if (progress.progress > 0.1) {
            setContentVisible(true);
        }
    };

    const showCards2 = (cards) => {
        return (
            <>
            <LandingCards2List cards={cards} />
            </>
        )
    }

    const showCards = (cards) => {
        return (
            <LandingCardsList cards={cards} />
        )
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            alert('Зміст скопійовано в буфер обміну');
          })
          .catch((error) => {
            console.error('Помилка при копіюванні в буфер обміну:', error);
          });
    };

    const showContentBlock = (landItem) => {
        return (
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="flex-start">
                {landItem.img && imagePosition === 'left' && (
                    <Box flex={1} padding={2} paddingLeft={0}>
                        <ScrollTrigger onProgress={handleImageScrollProgress}>
                            <img src={landItem.img} width='100%' className={`slide-in-left ${isImageVisible ? 'active' : ''}`} />
                        </ScrollTrigger>
                    </Box>
                )}

                <Box flex={1} padding={2}>
                    <ScrollTrigger onProgress={handleContentScrollProgress}>
                        <div className={`slide-in-content ${isContentVisible ? 'active' : ''}`}>
                            <Admin_LandingContentItems content={landItem.content} />
                            {landItem.cards && showCards(landItem.cards)}
                            {landItem.cards2 && showCards2(landItem.cards2)}
                        </div>
                    </ScrollTrigger>
                </Box>

                {landItem.img && imagePosition === 'right' && (
                    <Box flex={1} padding={2}>
                        <ScrollTrigger onProgress={handleImageScrollProgress}>
                            <img src={landItem.img} width='100%' className={`slide-in-right ${isImageVisible ? 'active' : ''}`} />
                        </ScrollTrigger>
                    </Box>
                )}
            </Box>
        )
    }

    return (
        <LandingBlock mode={mode}>
            <Box className="section text-left">
                <Container>
                    {landItem.header && <Box>
                        <LandingHeader>
                            <Typography variant="h4" className="page-header">
                                <FormattedMessage id={landItem.header} />
                            </Typography>
                        </LandingHeader>
                    </Box>}
                    
                    {(landItem.content || landItem.cards|| landItem.cards2) && showContentBlock(landItem)}

                    {(landItem.img && !landItem.content)&& 
                    <img src={landItem.img} width='100%' />}

                </Container>
            </Box>
        </LandingBlock>
    );
    
};

export default Admin_LandingContentBlock;
