
export const getPagesContent_projects = (local) => {
    return data_eng;
}

const data_eng = [
    {
        id: 0,
        header: 'Projects of ReC Ukraine',
        content: [
            {LandingMessage: `Переглядайте або шукайте проекти за допомогою одного або кількох фільтрів у списку.`},
        ],
    },
    {
        id: 1,
        LandingProjects3_eclub: `rec_ukraine`,
    }
];

