export const getRataractClubs = () => {
    return defaultData;
};

const defaultData = [
    {
      "city": "Cherkasy",
      "code": "92032",
      "status": true,
      "value": 18
    },
    {
      "city": "Cherkasy Smile",
      "code": "80735",
      "status": true,
      "value": 10
    },
    {
      "city": "Dniepropetrovsk",
      "code": "51216",
      "status": false
    },
    {
      "city": "Dnipro City Radost",
      "code": "217234",
      "status": true,
      "value": 19
    },
    {
      "city": "Dnipropetrovsk",
      "code": "50298",
      "status": false
    },
    {
      "city": "Donetsk",
      "code": "90433",
      "status": false
    },
    {
      "city": "Ivano-Frankivsk",
      "code": "89189",
      "status": true,
      "value": 24
    },
    {
      "city": "Izmail",
      "code": "8823756",
      "status": true,
      "value": 14
    },
    {
      "city": "Kharkiv First Capital",
      "code": "214049",
      "status": true,
      "value": 9
    },
    {
      "city": "Kharkiv International",
      "code": "216853",
      "status": true,
      "value": 8
    },
    {
      "city": "Kharkiv Multinational",
      "code": "212530",
      "status": true,
      "value": 12
    },
    {
      "city": "Kharkiv Shpak",
      "code": "8824479",
      "status": true,
      "value": 11
    },
    {
      "city": "Kharkov",
      "code": "42581",
      "status": true,
      "value": 5
    },
    {
      "city": "Kharkov Mriya",
      "code": "215713",
      "status": true,
      "value": 29
    },
    {
      "city": "Kherson",
      "code": "81905",
      "status": false
    },
    {
      "city": "Kherson",
      "code": "89764",
      "status": false
    },
    {
      "city": "Kiev",
      "code": "2115",
      "status": true,
      "value": 15
    },
    {
      "city": "Kiev-Pechersk",
      "code": "46434",
      "status": false
    },
    {
      "city": "Kirovograd",
      "code": "89766",
      "status": false
    },
    {
      "city": "Kyiv-Capital",
      "code": "216854",
      "status": true,
      "value": 11
    },
    {
      "city": "Kyiv-Center",
      "code": "59798",
      "status": true,
      "value": 8
    },
    {
      "city": "Kyiv-City",
      "code": "89393",
      "status": true,
      "value": 5
    },
    {
      "city": "Kyiv International",
      "code": "218114",
      "status": true,
      "value": 60
    },
    {
      "city": "Kyiv Multinational",
      "code": "89160",
      "status": true,
      "value": 15
    },
    {
      "city": "Kyiv-Sophia",
      "code": "212352",
      "status": true,
      "value": 4
    },
    {
      "city": "Lviv",
      "code": "42361",
      "status": true,
      "value": 5
    },
    {
      "city": "Lviv International",
      "code": "215880",
      "status": true,
      "value": 24
    },
    {
      "city": "Minsk",
      "code": "217974",
      "status": true,
      "value": 1
    },
    {
      "city": "Minsk",
      "code": "90439",
      "status": false
    },
    {
      "city": "Odessa",
      "code": "89765",
      "status": true,
      "value": 9
    },
    {
      "city": "Poltava",
      "code": "56458",
      "status": true,
      "value": 9
    },
    {
      "city": "Rivne",
      "code": "43605",
      "status": true,
      "value": 7
    },
    {
      "city": "Sevastopol",
      "code": "88886",
      "status": false
    },
    {
      "city": "Simferopol",
      "code": "43449",
      "status": false
    },
    {
      "city": "Sloviansk",
      "code": "90134",
      "status": false
    },
    {
      "city": "Sumy",
      "code": "43448",
      "status": false
    },
    {
      "city": "Ternopil",
      "code": "43899",
      "status": false
    },
    {
      "city": "Uzhgorod",
      "code": "58705",
      "status": true,
      "value": 6
    },
    {
      "city": "Vinnytsia",
      "code": "89286",
      "status": false
    },
    {
      "city": "Yalta",
      "code": "71541",
      "status": false
    },
    {
      "city": "Zaporizhzhia",
      "code": "82535",
      "status": false
    },
    {
      "city": "Zaporizhzhia",
      "code": "8823389",
      "status": true,
      "value": 7
    },
    {
      "city": "Zaporizhzhia-Khortytsia",
      "code": "8825583",
      "status": true,
      "value": 20
    }
  ]
  
  