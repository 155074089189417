import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, Button, Tooltip  } from '@mui/material';
import IMG_PNG_1 from '../assets/img/icons/Warning.png';

const BetaMessage = ({noWarningMode}) => {
    const [visible, setVisible] = useState(true);
    return (
        <>
        {/* {visible && noWarningMode!=="hide" && <div style={{background: !noWarningMode?'pink': noWarningMode==='test'?'#FFF6BB':'lightgreen', padding: '8px'}}>
        <Box display="flex" flexDirection='row' gap={1} >
            <Container>
                <Box display="flex" flexDirection='row' gap={1} >
                    {!noWarningMode && <img src={IMG_PNG_1} height='52px' style={{marginBottom: '-12px', marginTop: '-4px'}} />}
                    <Box>
                    <Typography variant="h7" className="page-header">
                        _Цей сайт знаходиться на стадії розробки.<br/>
                        {!noWarningMode && "Ця сторінка має обмежений функціонал на даний момент."}
                        {noWarningMode===true && "Ця сторінка має повний функціонал."}
                        {noWarningMode==='test' && "Ця сторінка має повний функціонал, що тестується."}
                    </Typography>
                    </Box>
                </Box>
            </Container>
            <Button 
                style={{ width: '20px', height: '20px', padding: '0', minWidth: 'auto' }} 
                variant='outlined' 
                onClick={() => setVisible(false)}
            >x</Button>
        </Box>
        </div>} */}
        </>
  );
}

export default BetaMessage;