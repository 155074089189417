import React, { useState, useEffect } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, 
        Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { fetchProjects, createProject, updateProject, deleteProject } from '../api/api_projects';


import CRUD_ClubProjects from './CRUD_ClubProjects';

const adminProjectDefaultFields = [
]

const projectColumns_singapore = [
    { field: 'project_id', headerName: 'id', type: 'id', default: '' },
    { field: 'category', headerName: 'Rotary Area of Focus:', type: 'checkbox', default: '' },
    { field: 'category2', headerName: 'Project category:', type: 'checkbox', default: '' },
    { field: 'status', headerName: 'Realization Stage:', type: 'combo', default: '' },
    { field: 'budget', headerName: 'Budget:', type: 'combo', default: '' },
    
    { field: 'avatarimg', headerName: 'Primary photo', type: 'img', default: '' },
    { field: 'imgs', headerName: 'Additional photo list:', type: 'multilineedit', default: '', tablehided: 'true' },
    { field: 'title', headerName: 'Title:', type: 'lineedit', default: '' },
    { field: 'youtube:fdesc', headerName: 'Short Description:', type: 'advmultilineedit', default: '', tablehided: 'true' },
    { field: 'description', headerName: 'Full Description:', type: 'multilineedit', default: '', tablehided: 'true', tablehided: 'true' },
    
    { field: 'genlink', headerName: 'Links of project: website, fb, other (by coma)', type: 'multilineedit', default: '', tablehided: 'true' },
    { field: 'docs', headerName: 'Links of project documents, presentations, other (by coma)', type: 'multilineedit', default: '', tablehided: 'true' },
    { field: 'youtube:video', headerName: 'Video links:', type: 'advlineedit', default: '', tablehided: 'true'  },

    { field: 'rtype', headerName: 'Rotary Type:', type: 'lineedit', default: '' },
    { field: 'rclub', headerName: 'Rotary Club:', type: 'lineedit', default: '' },
    { field: 'primarycontact', headerName: 'Shared primarycontact: First name and Sirname', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'email', headerName: 'Shared contact: Email', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'phone', headerName: 'Shared contact: Phone', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'fb', headerName: 'Shared contact: Other social platforms: youtube, fb, insta (by coma)', type: 'lineedit', default: '', tablehided: 'true' },

    { field: 'youtube:thanks', headerName: 'THANKS TO OVERSEAS ROTARY MEMBER FOR PARTICIPATION IN PAST PROJECTS', type: 'advmultilineedit', default: '', tablehided: 'true' },
    { field: 'youtube:invitation', headerName: 'Invitation to the interaction of Rotarians in the project', type: 'advmultilineedit', default: '', tablehided: 'true' },

    { field: 'tags', headerName: 'tags:', type: 'lineedit', default: '' },
    { field: 'youtube:approved', headerName: 'approved:', type: 'advlineedit', default: '' },
];

const ProjectsCRUD = ({tags}) => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedProjects = await fetchProjects();
                const filteredData = tags ? fetchedProjects.filter(item => item.tags === tags) : fetchedProjects;

                setProjects(filteredData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);  

    return (
        <div>
            <CRUD_ClubProjects 
                                title='Administration for D2232 Projects'
                                tags='all' 
                                adminProjectDefaultFields={adminProjectDefaultFields} 
                                projectColumns={projectColumns_singapore} 
                            />
        </div>
    );
}

export default ProjectsCRUD;
