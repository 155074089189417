
import { LOCALES } from '../../i18n/locales';

export const getD2232ProjectsPage = (local) => {
    const resData = local === LOCALES.UKRAINIAN ? resData_ukr : resData_eng;
    return resData;
};

const resData_eng = [
    
    {
        id: 0,
        header: 'Про Дистрикт 2232',
        arrcontent: [
            {
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/showcase/Icons/syngapore.jpg',
                content: [
                    {LandingMessage: `We invite you to view the Showcase of District 2232 projects! It was created especially for the Congress in Singapore!`},
                    // {LandingButtonLink: "Browse Projects", link: '/projshowcase/browse'},
                    // {LandingButtonLink: "Statistics Projects", link: '/projshowcase/stat'},
                ],
            },
        ]
    },
    {
        id: 0,
        header: 'Menu:',
        arrcontent: [
            {
                cards: [
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/showcase/Icons/browse projects.png',
                        buttonUrl: '/projshowcase/browse',
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/showcase/Icons/Statistics Projects.png',
                        buttonUrl: '/projshowcase/stat',
                    },
                ]
            },
        ]
    },
];


const resData_ukr = [
    {
        id: 0,
        header: 'Вітрини проєктів Дистрикту 2232',
        
        arrcontent: [
            {
                cards: [
                    {
                        name: "Вітрина проектів",
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/showcase/1_RA_KharkivMriya/photos/________%20____%20-%20______%20_______.jpg',
                        //buttonUrl: '/project_showcase/2',
                    },
                    {
                        name: "Вітрина проектів для конгресу в Сінгапурі",
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/showcase/Icons/syngapore.jpg',
                        buttonUrl: '/projshowcase',
                    },
                ]
            },

        ]
    },
    
    {
        id: 1,
        header: 'Збірки проєктів клубів',
        
        arrcontent: [
            {
                cards: [
                    {
                        name: "Проєкти РК Черкаси",
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/LogoAllClubs/RC_Cherkasy.png',
                        //buttonUrl: '/rc_cherkasy/projects',
                    },
                    {
                        name: "Проєкти РК Львів-Ратуша",
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/LogoAllClubs/RC_Lviv-Ratusha.png',
                        //buttonUrl: '/projshowcase/stat',
                    },
                    {
                        name: "Проєкти РeК Україна",
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/LogoAllClubs/ReC_ofUkraine.png',
                        //buttonUrl: '/projshowcase/stat',
                    },
                ]
            },

        ]
    },
    {
        id: 2,
        header: 'Всі зареєстровані проєкти Дистрикту 2232',
        
        arrcontent: [
            {
                cards: [
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/showcase/1_RA_KharkivMriya/photos/________%20____%20-%20______%20_______.jpg',
                        //buttonUrl: '/rc_cherkasy/projects',
                    },
                ]
            },

        ]
    },
    
    // {
    //     id: 2,
    //     header: 'Збірки проєктів Товариств',
        
    //     arrcontent: [
    //         {
    //             cards: [
    //                 {
    //                     name: "Товариство IYFR",
    //                     img: 'https://www.iyfr.net/wp-content/uploads/2024/02/flag-iyfr.png',
    //                     buttonUrl: '/rc_cherkasy/projects',
    //                 },
    //                 {
    //                     name: "Товариство Освітян",
    //                     img: 'https://api.rotary.org.ua/uploads/clubsitecreator/EducationFellowship/Icons/EducationFelowship.jpg',
    //                     buttonUrl: '/educationfellowship/browse',
    //                 },
    //             ]
    //         },

    //     ]
    // },
];