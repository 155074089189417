import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { injectIntl, FormattedMessage } from 'react-intl';  // якщо ви використовуєте react-intl

const LandingMessageTitle = ({ id, intl, noCollapse }) => {

    return (
        <div className='landing_text' style={{display: 'inline-block', fontSize: '22pt', width: '100%', textAlign: 'center'}}>
            {id }
        </div>
    );
};

export default LandingMessageTitle;
