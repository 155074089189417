

import Admin_LandingContentBlock from './Admin_LandingContentBlock';
//import LandingContentBlock2 from '../../fixcomponents/LandingContentBlock2';

const Admin_LandingContent = ({data, designType}) => {
    return (
        <>
        {data && data.map((item,index) => (
            <>
            {(item.content || item.img || item.cards || item.cards2) && <Admin_LandingContentBlock id={index} landItem={item} designType={designType} />}
            {/*item.arrcontent && <LandingArrContentBlock id={index} arrItem={item} designType={designType} />}
            {(item.content || item.img || item.imgs || item.cards3 || item.LandingProjects || item.LandingBdRotarians || item.LandingMapComponent) 
                && <LandingContentBlock2 id={index} landItem={item} designType={designType} />
            */}
            
            </>
        ))}
        </>
    )
}

export default Admin_LandingContent;