import React, { useEffect, useState } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { getAllUsers, createUser } from '../../api_sequelize/api_eventusers';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const data = "https://rotaryd2232ukraine.org/rotarians/vira";
const defaultData = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  avatar_url: '',
  club_type: '',
  club_name: '',
  club_position: '',
  tags: '',
};

const QRScanner = () => {
  const [res, setRes] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();

    const qrCodeScanner = new Html5QrcodeScanner(
      "reader",
      { fps: 10, qrbox: 250 },
      /* verbose= */ false
    );
    qrCodeScanner.render(onScanSuccess, onScanFailure);

    async function onScanSuccess(decodedText, decodedResult) {
      console.log(`Code matched = ${decodedText}`, decodedResult);
      setRes(decodedText);
      if (decodedText === data) {
        register();
      }
    }

    function onScanFailure(error) {
      console.warn(`Code scan error = ${error}`);
    }

    return () => {
      qrCodeScanner.clear().catch(error => {
        console.error('Failed to clear QR Code scanner', error);
      });
    };
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await getAllUsers();
      console.log('Fetched users:', response.data);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };


  const register = async () => {
    const formData = {
        ...defaultData,
        first_name: "Віра",
        last_name: "Сиряміна",
        email: 't@t.com'
      };
      try {
        const response = await createUser(formData);
        console.log('User created:', response.data);
        fetchUsers(); // Refresh user list after creating a new user
      } catch (error) {
        console.error('Error creating user:', error);
      }
  }

  const handleReg = () => {
    register();
  }
  
  return (
    <div>
      <h2>Scan QR Code</h2>
      <div id="reader" style={{ width: "100%" }}></div>
      <Typography variant="h6">Result: {res}</Typography>
      {res === data && <Typography>Учасника "Віра Сиряміна" зареєстровано</Typography>}
      <button onClick={handleReg} >reg</button>

      <Typography variant="h6" sx={{ mt: 3 }}>Users List</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Club Type</TableCell>
              <TableCell>Club Name</TableCell>
              <TableCell>Club Position</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users && users.length > 0 ? (
              users.map((user) => (
                <TableRow key={user.user_id}>
                  <TableCell>{user.user_id}</TableCell>
                  <TableCell>{user.first_name}</TableCell>
                  <TableCell>{user.last_name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phone}</TableCell>
                  <TableCell>{user.club_type}</TableCell>
                  <TableCell>{user.club_name}</TableCell>
                  <TableCell>{user.club_position}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No users found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default QRScanner;
