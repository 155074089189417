import React, { useEffect, useState } from "react";

import MainPage from "../components/Main";
import EventsFrame from "./EventsFrame";

import WordTypePage_TempShare from '../components/WordTypePage_TempShare';

const PolicyPage = () => {
    return (
       <>
       <br/>Privacy Policy
       <br/>Introduction
       <br/>Your privacy is important to us. This privacy policy explains how we collect, use, disclose, and protect your information when you use our website and services. By using our website, you agree to the terms of this policy.
       <br/>
       <br/>Information We Collect
       <br/>Personal Information
       <br/>We may collect personal information that you voluntarily provide to us when using our services. This may include:
       <br/>
       <br/>Name
       <br/>Email address
       <br/>Other contact information
       <br/>Usage Information
       <br/>We may also automatically collect information about how you interact with our website. This may include:
       
       <br/>IP address
       <br/>Browser type
       <br/>Web pages you visit
       <br/>Time and duration of visits
       <br/>Use of Information
       <br/>We may use the collected information to:
       
       <br/>Provide and maintain our services
       <br/>Improve and personalize your user experience
       <br/>Respond to your inquiries and requests
       <br/>Send you updates, news, and other information related to our services
       <br/>Disclosure of Information
       <br/>We will not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except when necessary to provide our services or as required by law.
       
       <br/>Protection of Information
       <br/>We take appropriate measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or method of electronic storage is completely secure, so we cannot guarantee absolute security.
       
       <br/>Your Rights
       <br/>You have the right to access, correct, or delete your personal information. You may also withdraw your consent to the processing of your information at any time. To do so, please contact us using the contact information provided below.
       
       <br/>Changes to This Privacy Policy
       <br/>We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes.</>
  );
}

export default PolicyPage;