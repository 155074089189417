import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography } from '@mui/material';

import { limitString } from '../../../../modules/CRUD_system/functions/limitedString';

const CrudTable_multilang = ({ data, mcolumns, columns, onEdit, onDelete, onRowClicked }) => {
    
    function findFieldById(columns) {
        // Знайдемо об'єкт в масиві, де type === 'id'
        const item = columns.find(item => item.type === 'id');
      
        // Якщо об'єкт знайдено, повернемо значення його поля field
        return item ? item.field : undefined;
    }
    const field_id = findFieldById(columns);

    const show_cell = (columns_, dataitem) => {
        return (<>
        {dataitem && columns_.map((column) => {
            if (!column.tablehided) {
                if (column.type === 'multilineedit') {
                    return (
                        <TableCell key={column.field}>
                            <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                                {limitString(dataitem[column.field], 3, 30)} 
                            </Typography>
                        </TableCell>
                    );
                } else if (column.type === 'img') {
                    return (
                        <TableCell key={column.field}>
                            <img src={dataitem[column.field]} height='70px' />
                        </TableCell>
                    );
                } else {
                    return <TableCell key={column.field}>{dataitem[column.field]}</TableCell>;
                }
            } else {
                return null;
            }
        })}
        </>)
        

    }

    return (
        <TableContainer component={Paper}>
            {//data={data && JSON.stringify(data)
            }
            <Table sx={{ marginTop: '10px' }} >
                <TableHead sx={{ backgroundColor: '#e3f2fd' }} >
                    <TableRow>
                        <TableCell>№</TableCell>

                        {mcolumns.map((column) => {
                            if (!column.tablehided) {
                                return (
                                    <TableCell key={column.field}>{column.headerName}</TableCell>
                                );
                            }
                            return null; // Додайте цей рядок, якщо стовпець має бути прихований
                        })} 

                        {columns.map((column) => {
                            if (!column.tablehided) {
                                return (
                                    <TableCell key={column.field}>{column.headerName}</TableCell>
                                );
                            }
                            return null; // Додайте цей рядок, якщо стовпець має бути прихований
                        })}
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((user, index) => (
                        <TableRow 
                            key={user[field_id]} 
                            onClick={onRowClicked ? () => onRowClicked(index) : null}
                            sx={{ 
                                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }, // Add hover style here
                                maxHeight: 100, // Set max height here
                                overflow: 'auto', // Add overflow auto to enable scrolling if content exceeds maxHeight
                            }} 
                        >
                            <TableCell>{index}</TableCell>
                            {show_cell(mcolumns, user.TykoProjectMultilang)}

                            {show_cell(columns, user)}

                            <TableCell>
                                <Button onClick={(e) => { e.stopPropagation(); onEdit(user); }}>Edit</Button>
                                <Button onClick={(e) => { e.stopPropagation(); onDelete(user[field_id]); }}>Delete</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CrudTable_multilang;
