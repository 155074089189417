import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography, Collapse, Container } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ListIcon from '@mui/icons-material/List';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import GoogleCalendar3 from './GoogleCalendar3';
import CalendarList from './CalendarList';
import CalendarCards from './CalendarCards';
import EventFilters from './EventFilters';
import MainPage from '../../components/Main';
import LandingHeader from '../../components/LandingHeader';

const RCalendar = () => {
  const [activeView, setActiveView] = useState(() => {
    const savedView = localStorage.getItem('activeView');
    return savedView || 'calendar';
  });

  const [filters, setFilters] = useState({
    district: true,
    club: true,
    governor: true
  });

  const [eventCounts, setEventCounts] = useState({
    district: 0,
    club: 0,
    governor: 0
  });

  const [filtersVisible, setFiltersVisible] = useState(() => {
    const savedState = localStorage.getItem('filtersVisible');
    return savedState ? JSON.parse(savedState) : true;
  });

  useEffect(() => {
    localStorage.setItem('activeView', activeView);
  }, [activeView]);

  useEffect(() => {
    localStorage.setItem('filtersVisible', filtersVisible);
  }, [filtersVisible]);

  const renderActiveView = () => {
    switch (activeView) {
      case 'calendar':
        return <GoogleCalendar3 filters={filters} setEventCounts={setEventCounts} />;
      case 'list':
        return <CalendarList filters={filters} setEventCounts={setEventCounts} />;
      case 'cards':
        return <CalendarCards filters={filters} setEventCounts={setEventCounts} />;
      default:
        return null;
    }
  };

  return (
    <MainPage noWarningMode={true}>
      <Container sx={{ mt: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box sx={{ position: 'sticky', top: 0, zIndex: 1000, backgroundColor: 'white', p: 2, boxShadow: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Box sx={{ p: 2, flexShrink: 0 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => setFiltersVisible(!filtersVisible)}>
                <IconButton size="small">
                  {filtersVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                <Typography variant="h6" sx={{ ml: 1 }}>
                  Фільтри подій
                </Typography>
              </Box>
              <Collapse in={filtersVisible} sx={{ mt: 2 }}>
                <EventFilters filters={filters} setFilters={setFilters} eventCounts={eventCounts} />
              </Collapse>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <img src="https://res.cloudinary.com/tyko/image/upload/v1697893520/BetaRotarySite/SitePages/events2_dglsp7.png" height="120px" />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <IconButton
                color={activeView === 'calendar' ? 'primary' : 'default'}
                onClick={() => setActiveView('calendar')}
              >
                <CalendarTodayIcon />
              </IconButton>
              <IconButton
                color={activeView === 'list' ? 'primary' : 'default'}
                onClick={() => setActiveView('list')}
              >
                <ListIcon />
              </IconButton>
              <IconButton
                color={activeView === 'cards' ? 'primary' : 'default'}
                onClick={() => setActiveView('cards')}
              >
                <ViewModuleIcon />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
            {renderActiveView()}
          </Box>
        </Box>
      </Container>
    </MainPage>
  );
};

export default RCalendar;
