

import React from "react";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { IoLogoYoutube, IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin } from 'react-icons/io';

export const showRotarianContacts = (publicInfo) => {
    if (!publicInfo || typeof publicInfo !== 'string') {
        return null; 
    }
    if ( publicInfo.trim() === '') {
        return null; 
    }

    const contactsArray = publicInfo.split(/,\s*/); // Розділяємо рядок за комами, пропускаючи можливі пробіли після коми

    if (contactsArray.length === 0) {
        return null; // Повертаємо null, якщо рядок не містить жодного контакту
    }

    const socialIcons = {
        youtube: { icon: <IoLogoYoutube />, color: '#FF0000' },
        facebook: { icon: <IoLogoFacebook />, color: '#1877F2' },
        instagram: { icon: <IoLogoInstagram />, color: '#E4405F' },
        linkedin: { icon: <IoLogoLinkedin />, color: '#0A66C2' }
    };

    const renderSocialIcon = (url, icon, color) => (
        <Tooltip title={icon}>
            <IconButton onClick={() => window.open(url, '_blank')} aria-label={icon}>
                {React.cloneElement(icon, { color, fontSize: 'extraLarge' })}
            </IconButton>
        </Tooltip>
    );

    return (
        <>
            {contactsArray.map((contact, index) => {
                if (!contact) {
                    return null; // Ігноруємо пусті елементи
                }

                const url = contact.trim(); // Відкидаємо зайві пробіли з початку та кінця рядка

                switch (true) {
                    case url.includes('www.linkedin.com'):
                        return renderSocialIcon(url, socialIcons.linkedin.icon, socialIcons.linkedin.color);
                    case url.includes('www.youtube.com'):
                        return renderSocialIcon(url, socialIcons.youtube.icon, socialIcons.youtube.color);
                    case url.includes('www.facebook.com'):
                        return renderSocialIcon(url, socialIcons.facebook.icon, socialIcons.facebook.color);
                    case url.includes('www.instagram.com'):
                        return renderSocialIcon(url, socialIcons.instagram.icon, socialIcons.instagram.color);
                    default:
                        return null; // Ігноруємо невідомі платформи
                }
            })}
        </>
    );
};