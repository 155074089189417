// src/App.js
import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import { login, uploadFile, listFiles } from '../../api_fs/api';

const FsCms = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [path, setPath] = useState('/');

  const handleLogin = async () => {
    const token = await login(username, password);
    setToken(token);
  };

  const handleUpload = async () => {
    if (file && token) {
      await uploadFile(token, file, path);
      alert('File uploaded successfully');
    } else {
      alert('Please select a file and login first');
    }
  };

  const handleListFiles = async () => {
    if (token) {
      const files = await listFiles(token, path);
      setFiles(files);
    } else {
      alert('Please login first');
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Storage Interface</Typography>
      <Box mb={2}>
        <TextField label="Username" value={username} onChange={(e) => setUsername(e.target.value)} fullWidth margin="normal" />
        <TextField label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} fullWidth margin="normal" />
        <Button variant="contained" onClick={handleLogin}>Login</Button>
      </Box>
      <Box mb={2}>
        <TextField type="file" onChange={(e) => setFile(e.target.files[0])} fullWidth margin="normal" />
        <TextField label="Path" value={path} onChange={(e) => setPath(e.target.value)} fullWidth margin="normal" />
        <Button variant="contained" onClick={handleUpload}>Upload File</Button>
      </Box>
      <Box mb={2}>
        <Button variant="contained" onClick={handleListFiles}>List Files</Button>
        <List>
          {files.map((file, index) => (
            <ListItem key={index}>
              <ListItemText primary={file.name} secondary={file.path} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  );
};

export default FsCms;
