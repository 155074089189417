import React, { useState, useEffect } from 'react';
import {Box, Button} from '@mui/material';
import Avatar from '@mui/material/Avatar';

import { Menu, MenuItem, Divider, Drawer, useMediaQuery, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
//import { GoogleLogin, GoogleLogout, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'; 
import { IUserProfile, MultiAccountMenuProps } from '../MultiLogin/DataTypes';

import {api_logout} from '../../modules/RotaryLogin/rotaryAutorize';

const RotaryAccountMenu: React.FC<MultiAccountMenuProps> = ({ userProfile, onLoginSuccess, onLogoutSuccess }) => {
/*
    const [rotaryLoginEmail, setRotaryLoginEmail] = useState(localStorage.getItem('rotaryLoginEmail'))

    useEffect(() => {
        console.log("====== localStorage set email="+ (userProfile && userProfile.email ? userProfile.email : "") )
        localStorage.setItem('rotaryLoginEmail', userProfile && userProfile.email ? userProfile.email : "" );
    }, [userProfile]);
    
    const loginSuccess = (userData: IUserProfile) => {
            //setRotaryLoginEmail(userData && userData.email ? userData.email : "");
            onLoginSuccess(userData);
            //console.log("====== "+result?.email+", ")
    }
    const handleLogout = async() => {
        const res = await api_logout();
        //if (res)
            onLogoutSuccess();
    }


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getInitials = (userName: string) => {
        const words = userName.split(' ');
        let initials = '';
    
        for (let i = 0; i < words.length && i < 2; i++) {
            if (words[i].length > 0) {
                initials += words[i][0];
            }
        }
    
        return initials.toUpperCase();
    }
*/
  return (
    <></>
    // <React.Fragment>
    //   <Box sx={{ bgcolor_: 'gray' }}>
    //     <Tooltip title="Account settings">
    //       <IconButton
    //         onClick={handleClick}
    //         size="small"
    //         aria-controls={open ? 'account-menu' : undefined}
    //         aria-haspopup="true"
    //         aria-expanded={open ? 'true' : undefined}
    //       >
            
    //         <Avatar sx={{ width: 32, height: 32 }} src={userProfile && userProfile.imgUrl ? userProfile.imgUrl : ''}>
    //             {userProfile?.userName ? getInitials(userProfile.userName) : '?'}
    //         </Avatar>
            
    //       </IconButton>
    //     </Tooltip>
    //   </Box>
      
    //   {userProfile &&
    //   <Menu
    //     anchorEl={anchorEl}
    //     id="account-menu"
    //     open={open}
    //     onClose={handleClose}
    //     onClick={handleClose}
    //     PaperProps={{
    //       elevation: 0,
    //       sx: {
    //         overflow: 'visible',
    //         filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    //         mt: 1.5,
    //         '& .MuiAvatar-root': {
    //           width: 32,
    //           height: 32,
    //           ml: -0.5,
    //           mr: 1,
    //         },
    //         '&:before': {
    //           content: '""',
    //           display: 'block',
    //           position: 'absolute',
    //           top: 0,
    //           right: 14,
    //           width: 10,
    //           height: 10,
    //           bgcolor: 'background.paper',
    //           transform: 'translateY(-50%) rotate(45deg)',
    //           zIndex: 0,
    //         },
    //       },
    //     }}
    //     transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    //     anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    //   >
    //     <Box sx={{ pl: '20px', pb: '20px' }}>
    //       {userProfile?.email}
    //     </Box>

    //     <MenuItem onClick={handleLogout}>
    //         Logout
    //     </MenuItem>
    //   </Menu>
    //     }
    // </React.Fragment>
  );
}

export default RotaryAccountMenu;