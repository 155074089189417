import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {tr} from '../i18n/tr';

const LandingButtonIcon = ({text, action, desc, copyText}) => {

    const onClick = () => {
        if (action==="copyToClipboard") {
            copyToClipboard(copyText);
        }
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            alert(('Зміст скопійовано в буфер обміну'));
          })
          .catch((error) => {
            console.error('Помилка при копіюванні в буфер обміну:', error);
          });
    };

    return (
        <div>
        <Tooltip title={tr("Скопіювати в буфер обміну")}>
        <IconButton
            onClick={onClick}
            color="primary"
        >
            <Typography variant="body2">{text}</Typography>
            <ContentCopyIcon sx={{marginLeft:'4px'}} />
        </IconButton>
        </Tooltip>
        <Typography variant="body2">{desc}</Typography>
        </div>
    );
};

export default LandingButtonIcon;
