import React from 'react';
import ReactDOM from 'react-dom';
import QRScanner from './QRScanner';

const QrReadderPage = () => {
  return (
    <div>
      <h1>QR Code Scanner</h1>
      <QRScanner />
    </div>
  );
};
export default QrReadderPage;
