import React, { useState } from "react";

import LandingMessage from './LandingMessage';
import LandingMessage2 from './LandingMessage2';
import LandingMessage3 from './LandingMessage3';
import LandingMessageTitle from './LandingMessageTitle';
import LandingButtonDetails from './LandingButtonDetails';
import LandingButtonDetailsRI from './LandingButtonDetailsRI';
import LandingButtonLink from './LandingButtonLink';
import LandingList from './LandingList';
import LandingImage from './LandingImage';
import LandingImgTools from './LandingImgTools';
import LandingLink from './LandingLink';
import LandingLinkWithoutBr from './LandingLinkWithoutBr';
import LandingButtonIcon from './LandingButtonIcon';
import LandingAccordionItem from './LandingAccordionItem';
import LandingMatrixPrinterPaper from './LandingMatrixPrinterPaper';
import LandingMapFrame from './LandingMapFrame';
import LandingTable from './LandingTable';
import LandingDownloadButton from './LandingDownloadButton';
import LandingFileIcon from './LandingFileIcon';
import LandingVideo from './LandingVideo';
import LandingPdf from './LandingPdf';
import LandingYoutubeFrame from './LandingYoutubeFrame';
import LandingLinkIcon from './LandingLinkIcon';

const LandingContentItems = ({ content }) => {

    return (
        content && content.map((contItem, index) => (
            <React.Fragment key={index}>
            {contItem.LandingMessage && <LandingMessage id={contItem.LandingMessage} noCollapse={contItem.noCollapse}/>}
            {contItem.LandingMessage2 && <LandingMessage2 id={contItem.LandingMessage2} noCollapse={contItem.noCollapse}/>}
            {contItem.LandingMessage3 && <LandingMessage3 id={contItem.LandingMessage3} noCollapse={contItem.noCollapse}/>}
            {contItem.LandingMessageTitle && <LandingMessageTitle id={contItem.LandingMessageTitle} noCollapse={contItem.noCollapse}/>}
            {contItem.LandingButtonDetails && <LandingButtonDetails link={contItem.link} />}
            {contItem.LandingButtonDetailsRI && <LandingButtonDetailsRI link={contItem.link} />}
            {contItem.LandingList && <LandingList arr={contItem.LandingList} type='dot' />}
            {contItem.LandingButtonLink && <LandingButtonLink link={contItem.link} text={contItem.LandingButtonLink} variant={contItem.variant} />}
            {contItem.LandingImage && <LandingImage link={contItem.link} fullsize={contItem.fullsize} />}
            {contItem.LandingImgTools && <LandingImgTools link={contItem.link} title={contItem.LandingImgTools} />}
            {contItem.LandingLink && <LandingLink link={contItem.link} text={contItem.LandingLink} br={contItem.br}/>}
            {contItem.LandingLinkWithoutBr && <LandingLinkWithoutBr link={contItem.link} text={contItem.LandingLinkWithoutBr} br={contItem.br}/>}
            {contItem.LandingButtonIcon && <LandingButtonIcon text={contItem.LandingButtonIcon} action={contItem.action} desc={contItem.desc} copyText={contItem.copyText} />}
            {contItem.LandingAccordionItem && <LandingAccordionItem title={contItem.LandingAccordionItem } text={contItem.text} itemArr={contItem.itemArr} />}
            {contItem.LandingMatrixPrinterPaper && <LandingMatrixPrinterPaper bg={contItem.tebgxt} itemArr={contItem.itemArr} />}
            {contItem.LandingMapFrame && <LandingMapFrame lat={contItem.lat} lng={contItem.lng} zoom={contItem.zoom} />}
            {contItem.LandingTable && <LandingTable columns={contItem.columns} data={contItem.data} />}
            {contItem.LandingDownloadButton && <LandingDownloadButton link={contItem.link} text={contItem.LandingDownloadButton} />}
            {contItem.LandingFileIcon && <LandingFileIcon link={contItem.link} text={contItem.text} type={contItem.LandingFileIcon} />}
            {contItem.LandingVideo && <LandingVideo link={contItem.link} title={contItem.LandingVideo} />}
            {contItem.LandingPdf && <LandingPdf link={contItem.link} title={contItem.LandingPdf} />}
            {contItem.LandingYoutubeFrame && <LandingYoutubeFrame linkid={contItem.linkid} title={contItem.LandingYoutubeFrame} />}
            {contItem.LandingLinkIcon && <LandingLinkIcon link={contItem.link} text={contItem.LandingLinkIcon} br={contItem.br}/>}
            {contItem.BR && <br />}
            </React.Fragment>
        ))
    );
    
};

export default LandingContentItems;
