import React from 'react';
import JsonEditor from './JsonEditor';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button, Typography } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select, FormControlLabel, Checkbox } from '@mui/material';

import { changeMetafieldLine, getSubfieldValue } from '../../../../modules/CRUD_system/functions/MetafieldEditLineTools';

//======================================
// use in rc_eclubofukraine admin page
//======================================
const AdminDialogCrud_SitePage = ({ open, onClose, onSubmit, isEditMode, columns, currentItem, currentItem2, setCurrentItem, filters }) => {
    
    const onChangeAvLine = (e, currentItem, column) => {
        console.log(`onChangeAvLine...`);
        const [field, subfield] = column.field.split(':');
        const advVal = changeMetafieldLine(e, currentItem, column);
        console.log(`advVal### = ${JSON.stringify(currentItem)}`);
        setCurrentItem({ ...currentItem, [field]: advVal });
    }

    
    const onChangeCheckBox = (e, column, category) => {
        const isChecked = e.target.checked;
        let updatedItems;
    
        // Перевірка, чи поле column.field є рядком JSON
        if (typeof currentItem[column.field] === 'string' && currentItem[column.field].trim() !== '') {

            try {
            const parsedItems = JSON.parse(currentItem[column.field]);
            
            // Перевірка, чи вже вибрано елемент
            if (isChecked && !parsedItems.includes(category)) {
                updatedItems = [...parsedItems, category];
            } else if (!isChecked && parsedItems.includes(category)) {
                updatedItems = parsedItems.filter((item) => item !== category);
            } else {
                // Якщо вибір не змінився, повертаємо поточний стан без змін
                updatedItems = parsedItems;
            }
            } catch (error) {
                updatedItems = isChecked ? [category] : [];
                //throw error;
            }
        } else {
            updatedItems = isChecked ? [category] : [];
        }
    
        // Перетворення масиву в строку JSON та оновлення стану
        setCurrentItem({ ...currentItem, [column.field]: JSON.stringify(updatedItems) });
    }

    const show_columns = (data_columns, data_item, sufix='') => {
        const data_item_ = data_item ? data_item : 'empty';

        return (
            <>
            {data_columns && data_columns.map((column) => {
                    if (column && column.type && column.field && column.headerName) {
                    //if (column.type === 'lineedit' || column.type === 'multilineedit' || column.type === 'img') {
                        switch (column.type) {
                            case 'lineedit':
                            case 'multilineedit':
                            case 'img':
                                return (
                                    <TextField
                                        key={column.field+sufix}
                                        autoFocus
                                        margin="normal"
                                        id={column.field}
                                        label={column.headerName}
                                        type={column.type === 'multilineedit' ? 'text' : column.field}
                                        fullWidth
                                        variant="outlined"
                                        value={data_item_[column.field]}
                                        onChange={(e) => setCurrentItem({ ...data_item_, [column.field]: e.target.value })}
                                        multiline={column.type === 'multilineedit'}
                                        minRows={column.type === 'multilineedit' ? 3 : 1}
                                    />
                                )


                            default:
                                return <></> 
                        }
                    //}
                    }
                    return null;
                })}
                </>
        )
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth
                maxWidth="lg">

            <DialogTitle>
                <Typography variant="h3" style={{ fontWeight: 'bold', color: 'darkblue' }}>
                    {isEditMode ? 'Edit Item' : 'Add Item'}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="h5" style={{ fontWeight: 'bold', _textDecoration: 'underline' }}>
                        {isEditMode ? 'Update the item details.' : 'Enter the details of the new item.'}
                    </Typography>
                </DialogContentText>
                <Box display="flex" flexDirection='row' width='100%' gap={1} >
                    <Box display="flex" flexDirection='column' width='50%' gap={1} >
                        Дефолтна мова 
                        {/* currentItem={JSON.stringify(currentItem)} */}
                        {show_columns(columns, currentItem)}
                    </Box>
                    <Box display="flex" flexDirection='column' width='50%' gap={1} >
                        Мова що редагується
                        {show_columns(columns, currentItem, 'sufix')}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSubmit}>{isEditMode ? 'Update' : 'Add'}</Button>
            </DialogActions>
        </Dialog>
    );

    // function getItemsByBdfield(data, bdfield) {
    //     if (data && bdfield) {
    //         // Шукаємо об'єкт з відповідним bdfield
    //         const category = data.find(item => item.bdfield === bdfield);
    //         // Перевіряємо, чи такий об'єкт був знайдений
    //         if (category) {
    //             // Повертаємо елементи, якщо такий bdfield був знайдений
    //             return category.items;
    //         } else {
    //             // Якщо не знайдено, повертаємо порожній масив
    //             return null;
    //         }
    //     } else return null;
    // }
    
    // function getTitleByBdfield(data, bdfield) {
    //     if (data && bdfield) {
    //         // Шукаємо об'єкт з відповідним bdfield
    //         const category = data.find(item => item.bdfield === bdfield);
    //         // Перевіряємо, чи такий об'єкт був знайдений
    //         if (category) {
    //             // Повертаємо елементи, якщо такий bdfield був знайдений
    //             return category.title;
    //         } else {
    //             // Якщо не знайдено, повертаємо порожній масив
    //             return null;
    //         }
    //     } else return null;
    // }
};

export default AdminDialogCrud_SitePage;
