import React, { useEffect, useState } from "react";
import './PhotoCarusel.css';

import { Button, Box, Container, Grid, Typography } from '@mui/material'

const VideoCarusel = ({videos, text}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        let interval;
        if (!isHovered) {
            interval = setInterval(() => {
                console.log("handleNext");
                    handleNext();
            }, 3000);
        }
        
        return () => {
            clearInterval(interval);
        };
    }, [isHovered, activeIndex]);

    const handleNext = () => {
            setActiveIndex((prevIndex) => videos && videos.length>0 ? (prevIndex + 1) % videos.length : 0);
            //console.log("handleNext prevIndex="+prevIndex+", videos.length="+videos.length);
        
    };

    const handlePrev = () => {
        if (videos && videos.length > 0 ) {
            setActiveIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length);
        }
    };

    const handleMiniatureClick = (index) => {
        setActiveIndex(index);
    }

    return (
        <>
        <Typography variant="h5" className="page-header" pt='30px' >Відео галерея</Typography>
        <Box className="carousel-miniatures">
            {videos.map((video, index) => (
                <video height="200px"  controls>
                <source src={video} type="video/mp4" />
                Ваш браузер не підтримує відтворення відео.
            </video>
            ))}
        </Box>
        </>
    );
}

export default VideoCarusel;
