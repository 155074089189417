

import LandingArrContentBlock from '../../../components/LandingArrContentBlock';
import LandingContentBlock from '../../../components/LandingContentBlock';
import LandingContentBlock_params from '../fixcomponents/LandingContentBlock_params';

const LandingContent_params = ({data, designType, careaArray, hasOpened}) => {
    return (
        <>
        {data && data.map((item,index) => (
            <>
            {(item.LandingProjects3_eclub ) 
                && <LandingContentBlock_params id={index} landItem={item} designType={designType} careaArray={careaArray} hasOpened={hasOpened} />
            }
            </>
        ))}
        </>
    )
}

export default LandingContent_params;