import { Box, Typography, Container, Link, Paper, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import LikeButton from '../../pages/RC_Cherkasy/components/LikeButton';
import { styled } from '@mui/material/styles';

import { changeMetafieldLine, getSubfieldValue } from '../CRUD_system/functions/MetafieldEditLineTools';

const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

const SideBox = styled(Box)({
  width: '100px', // фіксована ширина для бокових боксів
  _backgroundColor: '#cfe8fc',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const MiddleBox = styled(Box)({
  flexGrow: 1, // займає весь вільний простір
  _backgroundColor: '#e0f7fa',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ApprovedBox = ({objitem, metafield, handleLikeClicked, index, handleRowClick}) => {
    const value = getSubfieldValue(objitem, metafield);
    return (
        <MainContainer>
        <SideBox>

            {/* {(() => {
                switch (value) {
                    case 'approved':
                        return (
                            <Tooltip title={"Проект зареєстрований!"}>
                            <IconButton
                                sx={{
                                color: 'white',
                                backgroundColor: 'green',
                                borderRadius: '50%',
                                '&:hover': {
                                    backgroundColor: 'darkgreen',
                                },
                                }}
                            >
                                <CheckCircleIcon />
                            </IconButton>
                            </Tooltip>
                    );
                    case '':
                        return (
                            <Tooltip title={"Проект на стадії редагування!"}>
                            <IconButton
                                sx={{
                                color: 'white',
                                backgroundColor: 'yellow',
                                borderRadius: '50%',
                                '&:hover': {
                                    backgroundColor: 'goldenrod',
                                },
                                }}
                            >
                                <WarningIcon />
                            </IconButton>
                            </Tooltip>
                    );
                    
                    case 'hided':
                        return (
                            <IconButton
                            sx={{
                            color: 'white',
                            backgroundColor: 'red',
                            borderRadius: '50%',
                            '&:hover': {
                                backgroundColor: 'darkred',
                            },
                            }}
                        >
                            <CancelIcon />
                        </IconButton>
                    );
                }
            })()} */}
            
            ID:{objitem.project_id}
        </SideBox>
        <MiddleBox>
            
        </MiddleBox>
        <SideBox>
            {/* <LikeButton pid={objitem.project_id} /> */}
            <Button variant="text" onClick={() => handleRowClick(index)} >More info</Button>
            
        </SideBox>
        </MainContainer>
    )
};

export default ApprovedBox;