export function jsonToStringArray(jsonString) {
    try {
        // Розбираємо JSON рядок у масив
        const jsonArray = JSON.parse(jsonString);

        // Перевіряємо, чи jsonArray є масивом
        if (Array.isArray(jsonArray) && jsonArray.length>0) {
            // Повертаємо рядок, об'єднуючи елементи масиву через кому
            return jsonArray.join(', ');
        } else {
            throw new Error('Input is not a valid JSON array');
        }
    } catch (error) {
        if (typeof jsonString === 'string' && jsonString !== '') {
            // Перевіряємо, чи введений рядок не є пустим
            return jsonString; // Повертаємо введений рядок без конвертації
        } else {
            console.error('Error:', error.message);
            return null; // Повертаємо null у випадку помилки
        }
    }
}
