import React, { useRef, useEffect } from 'react';
import { Container } from '@mui/material';

function LandingHeaderParalax({ designType, bgUrl }) {
  const headerRef = useRef(null); // Додавання ref для доступу до елемента хедера

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      headerRef.current.style.backgroundPositionY = (offset * 0.5 - 70) + 'px';
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Використання inline стилів для динамічного визначення background-image
  const headerStyle = {
    width: '100%',
    height: '33.33vh',
    bgColor: 'red',
    backgroundImage: `url(${bgUrl})`, // Динамічне встановлення URL зображення
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div style={{backgroundColor: designType==='business' ? '#019fcb' : undefined }} >
    <Container>
      <div className="header" ref={headerRef} style={headerStyle}>
        {/* Тут можна додати контент хедера */}
      </div>
    </Container>
    </div>
  );
}

export default LandingHeaderParalax;
