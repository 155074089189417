import React, {useState, useEffect} from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { Box, Container, Grid, Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Code } from '@mui/icons-material';
import GetAppIcon from '@mui/icons-material/GetApp'; 

import '../pages/Pages.css';

import MainPage from "../components/Main";
import LandingBlock from '../components/LandingBlock';
import LandingHeader from '../components/LandingHeader';

import RI_1 from '../assets/content/home/RI_1.png'
import IMG1 from '../assets/content/temp_grafix/grafix.jpg'
import IMG2 from '../assets/content/icc/icc.jpg'

const VimeoFrame = (props) => {

    const embedClicked = (text) => {
        console.log("embedClicked")
        copyToClipboard(text)
    }
    const downloadClicked = (url) => {
        console.log("downloadClicked")
        window.open(url, '_blank');
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            alert('Текст скопійовано в буфер обміну');
          })
          .catch((error) => {
            console.error('Помилка при копіюванні в буфер обміну:', error);
          });
    };

    return (
        <div >
            
            <Box  display="flex" alignItems="center">
            <div className="subtitle1">{props.title}</div>
            <Button variant="contained" size="small" onClick={() => embedClicked(props.embedCode)}>
                <Code className="icon" /> 
                
            </Button>
            <Button variant="contained" size="small" onClick={() => downloadClicked(props.id)}>
                <GetAppIcon className="icon" /> 
                
            </Button>
            </Box>

            <div style={{ position: 'relative', paddingBottom: '56.25%', width: '100%', height: 0 }}>
            <iframe
                src={props.url}
                style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                }}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture; gyroscope; accelerometer"
                title={props.title}
            ></iframe>
            </div>
        </div>
    )
}

const GrafixPage = () => {
     
    return (
        <>
          <div style={{ position: 'relative' }}>
              <img src={IMG1} height='100%' width='100%' alt="Background" />
              <Typography 
                variant="h3" 
                style={{
                  position: 'absolute',
                  width: '100%',
                  top: '8%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  padding: 2,
                  paddingTop: '50px',
                  color: 'white', // Задає колір тексту, якщо потрібно
                  _backgroundColor: 'rgba(0, 0, 0, 0.3)', // Задає фон, якщо потрібно
                  fontSize: '2vw',
                }}
              >
                Презентація матеріалу в 360 форматі<br/> 
                компанії
              </Typography>
              <Typography 
                variant="h3" 
                style={{
                  position: 'absolute',
                  width: '100%',
                  top: '12%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  padding: 2,
                  paddingTop: '50px',
                  color: 'white', // Задає колір тексту, якщо потрібно
                  backgroundColor: 'rgba(0, 0, 0, 0.3)', // Задає фон, якщо потрібно
                  fontSize: '6vw',
                  fontWeight: 'bold',
                }}
              >
                 <br/>Grafix
              </Typography>
            </div>


            <LandingBlock mode='dark'>
            <Box className="section text-left" >
            <Container>
              <LandingHeader><Typography variant="h5" className="page-header">Про компанію Grafix в 360 відео</Typography></LandingHeader>

              <VimeoFrame title='Інтро' url='https://player.vimeo.com/video/855682096?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' 
                id='https://vimeo.com/user101794118/download/855682096/839be1c6db'
                download='https://vimeo.com/855688260?share=copy'
                embedCode='<div style="padding:42.86% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/855682096?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; gyroscope; accelerometer" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="p9_збірка"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>'/>
              <VimeoFrame title='Цех покраски' url='https://player.vimeo.com/video/855692046?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' 
                id='https://vimeo.com/user101794118/download/855692046/65f60fc107'
                download='https://vimeo.com/855692046?share=copy'
                embedCode='<div style="padding:42.86% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/855692046?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; gyroscope; accelerometer" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="п1_кх1"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>' />
              <VimeoFrame title='Лазерне різання' url='https://player.vimeo.com/video/855678519?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' 
                id='https://vimeo.com/user101794118/download/855678519/8e69ec3468'
                download='https://vimeo.com/855678519?share=copy'
                embedCode='<div style="padding:42.86% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/855678519?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; gyroscope; accelerometer" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="p5_laser1"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>' />              
              <VimeoFrame title='Лазерне різання' url='https://player.vimeo.com/video/855690578?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' 
                id='https://vimeo.com/user101794118/download/855690578/b3d7ff4885'
                download='https://vimeo.com/855690578?share=copy'
                embedCode='<div style="padding:42.86% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/855690578?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; gyroscope; accelerometer" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="p4_лазер"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>' />
              <VimeoFrame title='Піч полімерізації' url='https://player.vimeo.com/video/855681008?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' 
                id='https://vimeo.com/user101794118/download/855681008/6b7717a229'
                download='https://vimeo.com/855681008?share=copy'
                embedCode='<div style="padding:42.86% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/855681008?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; gyroscope; accelerometer" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="p7_нова_пічь"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>' />
              <VimeoFrame title='Піч полімерізації' url='https://player.vimeo.com/video/855681732?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' 
                id='https://vimeo.com/user101794118/download/855681732/59fd500968'
                download='https://vimeo.com/855681732?share=copy'
                embedCode='<div style="padding:42.86% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/855681732?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; gyroscope; accelerometer" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="p8_нова_пічь_штатив"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>' />
              <VimeoFrame title='Дробоструйна камера' url='https://player.vimeo.com/video/855689964?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' 
                id='https://vimeo.com/user101794118/download/855689964/0b5bec1c84'
                download='https://vimeo.com/855689964?share=copy'
                embedCode='<div style="padding:42.86% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/855689964?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; gyroscope; accelerometer" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="p2"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>' />
              <VimeoFrame title='Цех зварювання' url='https://player.vimeo.com/video/855690458?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' 
                id='https://vimeo.com/user101794118/download/855690458/aa6783915a'
                download='https://vimeo.com/855690458?share=copy'
                embedCode='<div style="padding:42.86% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/855690458?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; gyroscope; accelerometer" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="p2_цех_зварювання"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>' />

              <LandingHeader><Typography variant="h5" className="page-header">Додаткова інформація</Typography></LandingHeader>
              <lu>
                <li>всі відео надані для можливості публікації на своєму сайті, кнопка "Embed code".</li>
                <li>ще можна скачати як файл, викласти в FB, та завантажити на ютуб, кнопка "Download"</li>
                <li>на фото в 360 не бачу сенсу, бо дублюють відео</li>
                <li>як варіант, можна додати фонову музику</li>
              </lu>  
            </Container>
            </Box>
            </LandingBlock>

        </>
  );
}

export default GrafixPage;

