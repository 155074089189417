import React, { useEffect, useRef } from 'react';

function LandingMapFrame({ lat, lng, zoom }) {
  const mapRef = useRef(null);

  // Ініціалізація карти
  const initMap = () => {
    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat, lng },
      zoom: zoom,
    });

    new window.google.maps.Marker({
      position: { lat, lng },
      map: map,
    });
  };

  useEffect(() => {
    // Функція, яка перевіряє, чи Google Maps API вже завантажено
    const checkIfGoogleMapsLoaded = () => {
      if (window.google && window.google.maps) {
        initMap();
      } else {
        // Якщо API ще не завантажено, встановлюємо інтервал для перевірки
        const intervalId = setInterval(() => {
          if (window.google && window.google.maps) {
            clearInterval(intervalId);
            initMap();
          }
        }, 100); // Перевіряємо кожні 100 мс

        return () => clearInterval(intervalId); // Очищення інтервалу при анмаунті компонента
      }
    };

    checkIfGoogleMapsLoaded();
  }, [lat, lng, zoom]);

  return <div ref={mapRef} style={{ width: '100%', height: '400px' }} />;
}

export default LandingMapFrame;
