import { useParams } from 'react-router-dom';

import LandingPageV4 from '../components/LandingPageV4';
import {getEven2Menu} from './getEven2Menu';
import {getEvent3Landing} from './getEvent3Landing';
import {getHeaderV4} from './getHeaderV4';

const PersonalFolder24Page = () => {
    const { id } = useParams();
    const  designType='simple2';

    return (
        <LandingPageV4 
            designType={designType}
            navbarFunc={getEven2Menu}
            headerFunc={getHeaderV4}
            pageFunc={getEvent3Landing}
            urlid={id}
            pageDublicateMenu={true}
        /> 
  );
}

export default PersonalFolder24Page;