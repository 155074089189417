
import React, { useEffect, useState, useContext, useRef  } from "react";

import { Box, Typography, Container, Link, Paper, Button } from '@mui/material';
import { MdEmail } from 'react-icons/md';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {handleCopyToClipboard} from '../../../modules/CRUD_system/functions/toolsFuncs';
import PhotoCarusel from '../../../modules/PhotoCarusel';
import { showRotarianContacts } from '../functions/showRotarianContacts';

import SkillsTabs from '../components/SkillsTabs';

const LandingRotarianCard = ({people, handleBack, userSkils, ispublic}) => {
    const tags = "#showcase";
    const clubLogo = "https://res.cloudinary.com/tyko/image/upload/v1704543710/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/share/RC_Cherkasy_project_edlovp.png";
    const districtLogo = "https://res.cloudinary.com/tyko/image/upload/v1704614094/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/share/D2232_project_edlovp_o1oywq.png";
    
    const index = 0;
    const onlyimgs = people.imgs && people.imgs.filter((img) =>
        /\.(jpg|jpeg|png)$/i.test(img)
    );

    const showRotarianCard = (people) => {
        return (
            <>
                <Box display="flex" flexDirection="column" alignItems="center" paddingTop="2em" gap={2}>
                    <Paper style={{ padding: '20px', width: '100%', maxWidth: '680px' }}>
                        
                        {handleBack && <Box display="flex" justifyContent="space-between" alignItems="center">
                            
                            <Button variant="contained" onClick={handleBack}>Back</Button>
                        </Box>}

                        {people.avatar_url && (
                            <Box my={2} textAlign="center">
                                <img src={people.avatar_url} alt="Avatar" style={{ maxWidth: '100%', height: 'auto' }} />
                            </Box>
                        )}
    
                        {onlyimgs && (
                            <Box my={2}>
                                <PhotoCarusel images={onlyimgs} />
                            </Box>
                        )}
    
                        <Box display="flex" alignItems="center" gap={1}>
                        {/* <Typography gutterBottom variant="h6">
                            Name: 
                        </Typography> */}
                        <Typography gutterBottom variant="h6" color='darkblue'>{people.first_name} {people.last_name}</Typography>
                        </Box>

                        <Typography gutterBottom variant="h6" component="div">
                            {people.club_type && people.club_type === "District2232" ?
                            <>Дистрикт: Україна</> :
                            <>
                                {/* Клуб:  */}
                                {people.club_type && people.club_type === "RAC" ? <>RAC</> : people.club_type === "PRC" ? <>PRC</> : people.club_type === "ReC" ? <>ReC</> : <>RC</>} {people.club_name}
                            </>
                            }
                        </Typography>
                                 
                        <Typography variant="body2" color="text.secondary">
                        {people.other_positions && people.other_positions.split('\n').map((line, index, array) => {
                            const trimmedLine = line.trim();
                            const isBold = index > 0 && array[index - 1].trim() === '' && trimmedLine !== '';
                            return (
                            <Typography
                                key={index}
                                style={{ whiteSpace: 'pre-line', fontWeight: isBold ? 'bold' : 'normal' }}
                            >
                                {isBold && <br/>}
                                {line}
                            </Typography>
                            );
                        })}
                        </Typography>

                        {!ispublic && (people.email || people.phone) &&
                        <>
                        <Typography gutterBottom variant="h6" component="div">Contacts: </Typography>
                        <Box>
                            {people.email && (
                                <Tooltip title="Email">
                                    <IconButton onClick={() => handleCopyToClipboard(people.email)}>
                                        <MdEmail color="blue" size="1.5em" />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {people.phone && (
                                <Tooltip title="Phone">
                                    <IconButton onClick={() => handleCopyToClipboard(people.phone)}>
                                        <PhoneIcon color="primary" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                        </>}
    
                        <Typography gutterBottom variant="h6" component="div">
                            {userSkils && userSkils.length > 0 ? (
                                <>Професійні дані: <SkillsTabs skills={userSkils} /></>
                            ) : (
                                ''//'Професійні дані не вказані'
                            )}
                        </Typography>

                        {people.additional_info && <Typography gutterBottom variant="h7">
                            Contacts: {people.primarycontact} {showRotarianContacts(people.additional_info)}
                            </Typography>
                        }
                    </Paper>
                </Box>
            </>
        );
    }

    return (
        <>
            {showRotarianCard(people)}
            
        </>
  );
}

export default LandingRotarianCard;