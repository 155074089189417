import React, { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import MainPage from "../components/Main";
import Header from "../modules/Header";
import LandingContentBlock from '../components/LandingContentBlock';
import {getHomeLanding, getMenuHeaders} from '../api/home';

import HOME1_2 from '../assets/content/temp/dreaming.jpg';
import HOME2_1 from '../assets/content/temp/rotary1.jpg';
import HOME2_2 from '../assets/content/temp/rotary2.jpg';
import HOME2_ukr2 from '../assets/content/temp/support_ukraine.jpg';

// const struct1 = {
//     title: "Головна",
//     url: "/home"
// }
const breadcrumbs = "Головна";

const HomePage = () => {
    const images = [HOME2_1, HOME2_2, HOME2_ukr2];
    const [data, setData] = useState([]);
    const menuRefs = useRef([]);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const d = await getHomeLanding();
          setData(d);
          console.log("getHomeLanding get list success. const="+d.length);

          d.map((item, index) => {
            menuRefs.current.push(React.createRef());
            console.log("========= menuRefs="+menuRefs.current.length);
            //myRefs.current[index].current.value = "Input 1";
          })
        } catch (error) {
          console.log("getHomeLanding get list error="+error);
        }
      };
      fetchData();
    }, [])

    const location = useLocation();
    const id = location?.state?.id;
    useEffect(() => {
      if (id && menuRefs.current[id]) {
        menuRefs.current[id].current.scrollIntoView();
      }
    }, [id, menuRefs]);

    return (
      
        <MainPage noWarningMode={true} _breadcrumbs={breadcrumbs} >
            <Header images={images} text='Rotary District 2232' />
            
            {data && data.map((item,index) => (
                <div ref={menuRefs.current[item.id]}>
                <LandingContentBlock landItem={item} image={item.img} key={index} />
                </div>
            ))}
        </MainPage>
  );
}

export default HomePage;