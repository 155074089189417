import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, Button, Tooltip  } from '@mui/material'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import { FormattedMessage } from 'react-intl';

import IMG_PNG_1 from '../assets/img/icons/pdf_icon.png';


const ArchFileList = (props) => {
    const [data, setData] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const events = await props.func();
          //console.log('getEventsData =============== '+JSON.stringify(events.data, null, 2));
          console.log('getEventsData ====='+events.length);
          setData(events.reverse());
        } catch (error) {
          // Обробити помилку отримання даних
        }
      };

      fetchData();
    }, []);
  
    const toFileClick = (url) => {
      console.log("toFileClick");
      window.open(url, '_blank');
    }

    return (
        <>
        {data && data.map((row, index) => ( 
            <Box display="flex" alignItems="center">
                <Button >
                <img src={IMG_PNG_1} alt="Add" height='40px' className="icon-image" />
                </Button>
                <Typography variant="h6">{row.title}</Typography>
            </Box>
        ))}
        </>
    )
}

export default ArchFileList;