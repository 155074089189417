import React, { useState } from 'react';
import { Container, Box, Typography, Button, TextField } from '@mui/material';
import axios from 'axios'; 
import {URL_TO_API_SERVER} from '../../api/LoginAndSuccess/var';
import {sendlog} from '../../api/BdLogging/BdLogging';

const RegisterPage = ({onRegister, onBack}) => {
    //const [registerError, setRegisterError] = useState(null);
    //const [successMessage, setSuccessMessage] = useState(null); 
    //const [debusMessage, setDebugMessage] = useState(null); 
    const [formData, setFormData] = useState({
        name: '',
        lastname: '',
        club: '',
        rotaryid: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    const [errors, setErrors] = useState({
        name: '',
        lastname: '',
        club: '',
        rotaryid: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [serverResponse, setServerResponse] = useState({
        status: null,
        message: null,
        debug: null
    });

    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
        // Clear the error message when the user starts typing
        setErrors({
        ...errors,
        [name]: '',
        });

        if (name==='email') {
            const email = value;
            try {
                console.log('Check email. email='+email);
                const response = await axios.get(URL_TO_API_SERVER + 'api_members_rotarary_from_ri.php?action=reademailuser&email_str='+email);
    
                if (response.data && email) {
                    console.log('Logined in Site SUCCESS. email='+email);
                    setFormData({
                        ...formData,
                        email,
                        rotaryid: response.data.MemberID,
                        club: response.data.ClubName,
                        name: response.data.FirstName,
                        lastname: response.data.LastName,
                        });
                } else {
                    console.log('Logined in Site ERROR. not registered email='+email+', server='+response.error);
                    
                }
            } catch (err) {
                console.log('Logined ERROR. error='+err.message);
            } finally {
            }
        }
    };

    const handleRegister = async () => {
        // Perform validation here before registering
        const newErrors = {};

        if (formData.name === '') {
        newErrors.name = 'Поле "Ім\'я" обов\'язкове';
        }
        if (formData.lastname === '') {
            newErrors.lastname = 'Поле "Прізвище" обов\'язкове';
        }
        if (formData.club === '') {
            newErrors.club = 'Поле "Назва клубу" обов\'язкове';
        }
        if (formData.rotaryid === '') {
            newErrors.rotaryid = 'Поле "Ротарі ІД" обов\'язкове';
        }  

        if (!formData.email.match(/^\S+@\S+\.\S+$/)) {
        newErrors.email = 'Введіть дійсну електронну пошту';
        }

        if (formData.password.length < 6) {
        newErrors.password = 'Пароль повинен містити принаймні 6 символів';
        }

        if (formData.password !== formData.confirmPassword) {
        newErrors.confirmPassword = 'Паролі не співпадають';
        }

        // If there are errors, set them in the state and prevent registration
        if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        } else {
        // Call the onRegister function if there are no errors
        //api_registration

            try {
                console.log('formData=', JSON.stringify(formData));
                const data = {
                    name: formData.name,
                    lastname: formData.lastname,
                    club: formData.club,
                    rotaryid: formData.rotaryid,
                    email: formData.email,
                    password: formData.password,
                };
                
                // Налаштування запиту
                const config = {
                headers: {
                    'Content-Type': 'application/json' // Встановлюємо заголовок Content-Type на JSON
                }
                };
                // Відправляємо POST-запит
                const response = await axios.post(URL_TO_API_SERVER+'api_registration.php', data, config)
                .then(response => {
                    // Обробка відповіді
                    console.log('Відповідь від сервера:', response.data);
                    // Виводимо повідомлення про статус
                    //setDebugMessage(response.data);
        
                    // Ваш код обробки відповіді тут
                    // Якщо авторизація пройшла успішно, можна викликати onRegister
                    if (response.data.status==="success") {
                        console.log('onRegister = ', formData.email);
                        //setSuccessMessage('Реєстрація пройшла успішно. На вашу пошту надіслано лист підтвердження. Після схвалення вашої реєстрації адміном, на вашу пошту надійде лист активації (останній крок).');
                        setServerResponse({
                            status: response.data.status,
                            message: response.data.message,
                            debug: response.data.debug,
                        });
                        //onRegister();
                        sendlog({project: 'Register', func: 'handleRegister',  status: "success", message: "Реєстрація пройшла успішно.", debud: response.data.message+response.data.debud, user: formData.email, description: '-'});
                    }else {
                        //setRegisterError(true);
                        setServerResponse({
                            status: "error",
                            message: response.data.message,
                            debug: response.data.debug,
                        });
                        sendlog({project: 'Register', func: 'handleRegister',  status: "error", message: response.data.message, debud: response.data.debud, user: formData.email, description: '-'});
                    };
                })
                .catch(error => {
                    // Обробка помилок
                    console.error('Помилка при відправці запиту:', error);
                    //setRegisterError(true);
                    setServerResponse({
                        status: "error",
                        message: response.data.message,
                        debug: 'Помилка при відправці запиту:'+error+response.data.debug,
                    });
                    sendlog({project: 'Register', func: 'handleRegister',  status: "error", message: "Помилка при відправці запиту", debud: error, user: formData.email, description: '-'});
            
                });
            } catch (error) {
                console.error('Помилка:', error);
                //setRegisterError(true);
                setServerResponse({
                    status: "error",
                    message: 'Помилка додатку',
                    debug: 'Помилка:'+error,
                });
                sendlog({project: 'Register', func: 'handleRegister',  status: "error", message: "Помилка при відправці запиту", debud: error, user: formData.email, dedescriptionsc: '-'});
            }

        
        console.log('Реєстрація даних:', formData);
        }
    };

  const handleReturnToLogin = () => {
    onBack();
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h4" gutterBottom marginTop='60px'>
          Реєстрація
        </Typography>
        <Typography variant="body2" align="center">
            (введіть гугл пошту, з якою реєструвалися в my.rotary.org, <br />щоб не чекати етап перевірки)
        </Typography>
        <TextField
          label="Електронна пошта"
          variant="outlined"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          fullWidth
          style={{ marginBottom: '20px' }}
          error={!!errors.email}
          helperText={errors.email}
        />

        <TextField
          label="Ім'я"
          variant="outlined"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          fullWidth
          style={{ marginBottom: '20px' }}
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          label="Прізвище"
          variant="outlined"
          name="lastname"
          value={formData.lastname}
          onChange={handleInputChange}
          fullWidth
          style={{ marginBottom: '20px' }}
          error={!!errors.lastname}
          helperText={errors.lastname}
        />
        <TextField
          label="Клуб"
          variant="outlined"
          name="club"
          value={formData.club}
          onChange={handleInputChange}
          fullWidth
          style={{ marginBottom: '20px' }}
          error={!!errors.club}
          helperText={errors.club}
        />
        <TextField
          label="Ротарі ІД"
          variant="outlined"
          name="rotaryid"
          value={formData.rotaryid}
          onChange={handleInputChange}
          fullWidth
          style={{ marginBottom: '20px' }}
          error={!!errors.rotaryid}
          helperText={errors.rotaryid}
        />
        
        <TextField
          label="Пароль"
          variant="outlined"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleInputChange}
          fullWidth
          style={{ marginBottom: '20px' }}
          error={!!errors.password}
          helperText={errors.password}
        />
        <TextField
          label="Повторіть пароль"
          variant="outlined"
          name="confirmPassword"
          type="password"
          value={formData.confirmPassword}
          onChange={handleInputChange}
          fullWidth
          style={{ marginBottom: '20px' }}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
        />
        {serverResponse.status==='success' && <div>Message: {serverResponse.message}</div>}
        {serverResponse.status==='error' && <div>Error: {serverResponse.message}</div>}
        {serverResponse.debug==='error' && <div>Debug: {serverResponse.debug}</div>}
        <Button
          variant="contained"
          color="primary"
          onClick={handleRegister}
          style={{ marginBottom: '20px' }}
        >
          Зареєструватися
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={handleReturnToLogin}
        >
          Повернутися до сторінки авторизації
        </Button>
        {/*<div>debusMessage: {debusMessage}</div>*/}
      </Box>
    </Container>
  );
};

export default RegisterPage;
