import React, { useEffect, useState, useContext, useRef  } from "react";
import { useParams } from 'react-router-dom';

import {getRotariansBD} from './getRotariansBD';
import {getClubPageStructure} from './getClubPageStructure';
import { LOCALES } from '../../../i18n/locales';
import {LocalContext} from '../../../tools/LanguageContextProvider';

import TykoLanding from '../components/TykoLanding';
import LandingContent from '../components/LandingContent';
import TLandingFooter from '../components/TLandingFooter';

const RotariansCooperatorPage = () => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [data, setData] = useState([]);
    const [navbar, setNavbar] = useState(null);
    const [footer, setFooter] = useState(null);
    const { id } = useParams();

    const  designType='chicago';

    useEffect(() => {
      const fetchData = async () => {
        try {
            await loadData(currentLocale);
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale, id])

    const loadData = async(locale) => {
        const d = await getRotariansBD(locale);
        setData( d );

        const page = getClubPageStructure(locale);
        setNavbar(page.navbar);
        setFooter(page.footer);

        console.log("DanatePage get data list success. const="+d.length);
    }

    return (
        <>
        {navbar ?
        <TykoLanding navbar={navbar} noWarningMode="hide" designType={"business"} languages={["Eng"]} >
        <LandingContent data={data} currentLocale={currentLocale} designType={designType} footer={footer}/>
        </TykoLanding> : "Loading..."}
        </>
  );
}

export default RotariansCooperatorPage;