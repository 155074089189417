import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import FolderIcon from '@mui/icons-material/Folder';
import { Button } from '@mui/material';
import { Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { MdContentCopy } from 'react-icons/md';
import { MdEmail } from 'react-icons/md';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import FileUpload from './FileUpload';

const API_SERVER = 'https://api.rotary.org.ua';
//const APP_SERVER = 'http://localhost:3001';

function removeLastFolderFromUrl(url) {
    const urlWithoutTrailingSlash = url.endsWith('/') ? url.slice(0, -1) : url;
    const parts = urlWithoutTrailingSlash.split('/');
    parts.pop(); // Видаляємо останній елемент
    return parts.join('/') || '/';
}

const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
        .then(() => alert('Copied to clipboard'))
        .catch((err) => console.error('Could not copy text: ', err));
};

const removeCernelPath = (fullPath, startPath) => {
    if (fullPath.startsWith(startPath)) {
      return fullPath.replace(startPath, '');
    }
    return fullPath;
}

function ServerClubFolders({cernelClubFolder}) {
    //const cernelClubFolder = 'clubsitecreator/rc_cherkasy/photo';
    //const cernelClubFolder = 'clubsitecreator/rec_ofukraine';
    const CERNEL_CLUB_URL = `uploads/${cernelClubFolder}`;
    const CERNEL_URL = `/folders/folders/${cernelClubFolder}`;
    const CERNEL_PHOTOS_URL = `/folders/photos/${cernelClubFolder}`;
    const CERNEL_SERVER_PHOTOS_URL = `${API_SERVER}/uploads/${cernelClubFolder}`;

    const [currFolderUrl, setCurrFolderUrl] = useState(CERNEL_URL);
    const [currPhotosUrl, setCurrPhotosUrl] = useState(CERNEL_PHOTOS_URL);
    const [currSubFolderUrl, setCurrSubFolderUrl] = useState('/');
    const [folders, setFolders] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [curFolderIndex, setCurFolderIndex] = useState(-1);

    useEffect(() => {
        loadData();
    }, []);
    useEffect(() => {
        loadData();
    }, [currFolderUrl]);

    const loadData = () => {
        const url = API_SERVER+currFolderUrl;
        console.log(`fetch url=${url}`)
        fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log(`fetch data=${JSON.stringify(data)}`)
                setFolders(data);
            })
            .catch(error => {
                console.error('Error loading folders:', error);
            });

        const url2 = API_SERVER+currPhotosUrl;
        fetch(url2)
            .then(response => response.json())
            .then(urls => {
                //console.log('loadData data='+JSON.stringify(urls));
                setPhotos(urls);
            })
            .catch(error => {
                console.error('Помилка при завантаженні фотографій:', error);
            });
    }

    const handleFolderClick = (folderIndex) => {
        setCurFolderIndex(folderIndex);

        const newUrl = folderIndex===-2 
            ? removeLastFolderFromUrl(currFolderUrl)
            : `${currFolderUrl}/${folders[folderIndex]}`;
        setCurrFolderUrl(newUrl);

        const newPhotoUrl = folderIndex===-2 
            ? removeLastFolderFromUrl(currPhotosUrl)
            : `${currPhotosUrl}/${folders[folderIndex]}`;
        setCurrPhotosUrl(newPhotoUrl);

        const newSubUrl = folderIndex===-2 
            ? removeLastFolderFromUrl(currSubFolderUrl)
            : currSubFolderUrl==='/' ? `/${folders[folderIndex]}` : `${currSubFolderUrl}/${folders[folderIndex]}`;
            setCurrSubFolderUrl(newSubUrl);

        console.log('handleFolderClick index=' + folderIndex);
    };
    
    const handleUploadedFiles = () => {
        //update data
        loadData();
    }

    const handleCopyAllUrlToClipboard = () => {
        const photosList = photos.join(', ');
        handleCopyToClipboard(photosList);
    }

    const showListItemPath = (index, folderPath, ) => {
        const fullPath = `${currFolderUrl}/${folderPath}`;
        return (
        <ListItem button key={index} onClick={() => handleFolderClick(index)}>
            <ListItemIcon>
                <FolderIcon />
            </ListItemIcon>
            <ListItemText primary={folderPath} />
            <IconButton onClick={() => handleCopyToClipboard(fullPath)} aria-label="copy">
                <MdContentCopy />
            </IconButton>
            {fullPath}
            {/* <Tooltip title="Видалити папку">
                <IconButton color="secondary">
                    <DeleteIcon />
                </IconButton>
            </Tooltip> */}
        </ListItem>
        )
    } 

    return (
        <>
        currFolderUrl={currFolderUrl}<br/>
        folders={JSON.stringify(folders)}<br/>
        curFolderIndex={curFolderIndex}<br/>
        currSubFolderUrl={CERNEL_CLUB_URL+currSubFolderUrl}<br/>
        {folders &&
            <>
            <h2>Folder Explorer</h2>
            <Button
                variant="contained"
                color="primary"
                startIcon={<CreateNewFolderIcon />}
                >
                Створити папку
            </Button>
            <List>
                {currFolderUrl!==CERNEL_URL && showListItemPath(-2, '...') }
                {folders.map((folder, index) => showListItemPath(index,  folder ))}
            </List>
            </>
        }

        <FileUpload currSubFolderUrl={CERNEL_CLUB_URL+currSubFolderUrl} handleUploadedFiles={handleUploadedFiles} />
        
        
        <Tooltip title="Скопіювати перелік всіх ліків фото">
            <IconButton onClick={handleCopyAllUrlToClipboard} aria-label="copy">
                <MdContentCopy />
            </IconButton>
            </Tooltip>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {photos.map((item, index) => (
                <div key={index} style={{ margin: '10px', border: '1px solid gray' }}>
                    <img src={item} alt={`Фото ${index + 1}`} style={{ maxWidth: '200px', maxHeight: '200px' }} />
                    <IconButton onClick={() => handleCopyToClipboard(item)} aria-label="Facebook">
                    <MdEmail color="blue" size='1.5em' />
                    </IconButton>
                    url={removeCernelPath(item, CERNEL_SERVER_PHOTOS_URL)}
                    <Tooltip title="Видалити фото">
                        <IconButton style={{ color: 'blue' }}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            ))}
            {photos && photos.length===0 && "папка без фото..."}
        </div>

        </>
    );
}

export default ServerClubFolders;
