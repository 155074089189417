import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';

const LandingButtonDetails = ({link}) => {
    const navigate = useNavigate();
    return (
        <Button 
            sx={{ textTransform: 'none' }} 
            onClick={() => {
                if (link) {
                    navigate(link);
                    window.scrollTo(0, 0);
                }
            }}
        >
            <FormattedMessage id='btn_details' />
        </Button>
    );
};

export default LandingButtonDetails;
