import {jsonToStringArray} from './JsonToArrayString';

export const hasAnyCategory = (catList, categories) => {
    // Розбиваємо catList на масив окремих категорій, прибираючи зайві пробіли
    const catArray = catList && catList.split(',').map(cat => cat.trim());

    // Знаходимо всі категорії, що співпали
    const matchedCategories = catArray && categories.filter(category => catArray.includes(category));
    
    // Виводимо лог співпадінь
    if (matchedCategories && matchedCategories.length > 0) {
        //console.log('Matched categories:', matchedCategories.join(', '));
        return true;
    } else {
        console.log('No matched categories');
        return false;
    }
};

export const calculateProjCount_2 = (curArr, fullFieldList, dbField) => {
    console.log(`start ==========================`);
    // Створюємо початковий масив countFieldList, заповнений нулями
    let countFieldList = new Array(fullFieldList.length).fill(0);

    // Ітеруємося по кожному елементу в curArr
    curArr.forEach(item => {
        // Отримуємо значення поля dbField поточного елемента
        const catList = jsonToStringArray(item[dbField]);

        if (catList) {
            // Розбиваємо catList на масив категорій
            const catArray = catList.split(',').map(cat => cat.trim());

            catArray.forEach(category => {
                // Знаходимо індекс категорії у масиві fullFieldList
                const index = fullFieldList.indexOf(category);

                // Якщо індекс більше або дорівнює нулю (тобто значення знайдено в fullFieldList), збільшуємо лічильник для цього значення
                if (index >= 0) {
                    countFieldList[index]++;
                    //console.log(`Matched category: ${category}`);
                }
            });
        }
    });
    console.log(`end ==========================`);

    return countFieldList;
}

export const calculateProjCount_chart = (curArr, fullFieldList, dbField) => {
    console.log(`start ==========================`);
    // Створюємо початковий масив countFieldList, заповнений нулями
    let countFieldList = new Array(fullFieldList.length).fill(0);

    // Ітеруємося по кожному елементу в curArr
    curArr.forEach(item => {
        // Отримуємо значення поля dbField поточного елемента
        const catList = jsonToStringArray(item[dbField]);

        if (catList) {
            // Розбиваємо catList на масив категорій
            const catArray = catList.split(',').map(cat => cat.trim());

            catArray.forEach(category => {
                // Знаходимо індекс категорії у масиві fullFieldList
                const index = fullFieldList.indexOf(category);

                // Якщо індекс більше або дорівнює нулю (тобто значення знайдено в fullFieldList), збільшуємо лічильник для цього значення
                if (index >= 0) {
                    countFieldList[index]++;
                    //console.log(`Matched category: ${category}`);
                }
            });
        }
    });
    console.log(`end ==========================`);

    return countFieldList;
}