import React, { useContext, useEffect } from "react";
import { IntlProvider } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { LOCALES } from '../i18n/locales';
import { messages } from '../i18n/massages';
import {LocalContext} from '../tools/LanguageContextProvider';

import BetaMessage from '../components/BetaMessage';
import '../components/Main.css';
import NavbarGlobal from "./NavbarGlobal";
import Footer from "./Footer";
const ga4 = 'G-5P2E208YMM';

const MainGlobal = (props) => {
  const { currentLocale, setCurrentLocale } = useContext(LocalContext);
  const location = useLocation();
  useEffect(() => {
    if (true || process.env.NODE_ENV === 'production') {
        window.gtag('config', ga4, {
        page_path: location.pathname,
        });
    }
  }, [location]);

    return (
       
        <IntlProvider messages={messages[currentLocale]}
          locale={currentLocale}
          defaultLocale={LOCALES.ENGLISH}
          onError={() => {}}>


          <NavbarGlobal />
          <div style={{minHeight: 'calc(100vh - 150px)', paddingBottom: '24px'}}>
          <BetaMessage noWarningMode={props.noWarningMode} />
            {props.children}
          </div>
          <Footer />
          
      
        </IntlProvider>

  );
}

export default MainGlobal;