import { LOCALES } from '../../../i18n/locales';

export const getClubPageStructure3 = (local) => {

    const translations_ukr = {
        "Вітрина Проектів Д2232": "Вітрина Проектів Д2232",
        "Контактна особа": "Контактна особа",

        "Головна": "Головна",
        "Огляд проектів": "Огляд проектів",
        "Статистика проектів": "Статистика проектів",
        "Донати": "Донати",
    };

    const translations_eng = {
        "Вітрина Проектів Д2232": "Education Fellowship",
        "Контактна особа": "Contact Person",

        "Головна": "About Fellowship",
        "Огляд проектів": "Fellowship Projects",
        "Статистика проектів": "Statistics",
        "Донати": "Donate",
    };
    

    const translations = local === LOCALES.UKRAINIAN ? translations_ukr : translations_eng;

    return {
        navbar: {
            title: translations["Вітрина Проектів Д2232"],
            logoUrl: "https://api.rotary.org.ua/uploads/clubsitecreator/EducationFellowship/Icons/EducationFelowship.jpg",
            languages: [
                "ua-UA",
                "en-US",
            ],
            socialsocialNetworks: {
                fb: 'https://www.facebook.com/RotaryInUkraine',
                // youtube: 'https://www.instagram.com/rotary_club_cherkasy?igsh=MWlhNzRjeW0wa2RuMg%3D%3D&utm_source=qr',
                // linkedIn: 'https://youtube.com/@RotaryclubCherkasy?si=PJCBZbOuDVqDxzpU',
            },
            menu: [
                {
                    title: translations["Головна"],
                    lref: '/educationfellowship',
                },
                {
                    title: translations["Огляд проектів"],
                    lref: '/educationfellowship/browse',
                },
                {
                    title: translations["Статистика проектів"],
                    lref: '/educationfellowship/stat',
                },
                {
                    title: translations["Донати"],
                    lref: '/educationfellowship/danate',
                },
            ],
        },
        footer: {
            contactPerson: {
                name: translations["Контактна особа:"],
                email: "oncology@2upost.com",
                email2: "paliychuk.oncology@gmail.com",
            },
        }
    }
}
