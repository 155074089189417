
import { LOCALES } from '../../i18n/locales';

import RI_1 from '../../assets/content/home/RI_1.png'

export const getAboutD2232Page = (local) => {
    const resData = local === LOCALES.UKRAINIAN ? resData_ukr : resData_eng;
    return resData;
};

const resData_ukr = [
    {
        id: 0,
        header: 'Про Дистрикт 2232',
        
        arrcontent: [
            {
                header: `Об'єднання заради служіння`,
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/LogoD2232_withborders.png',
                content: [
                    {LandingMessage: `Всі клуби Дистрикту 2232 діляться спільною місією: служінням суспільству через активізм, допомогу та покращення умов життя. Члени Ротарі клубів нашого Дистрикту працюють над вирішенням найактуальніших проблем, таких як боротьба з бідністю, підтримка освіти, здоров'я, захисту довкілля, а також просування культури і миру.`},
                ],
            },
            {
                header: 'Співпраця та товариство',
                content: [
                    {LandingMessage: `Ми віримо в силу спільної праці і важливість товариства. Ротарі Дистрикт 2232 прагне зміцнювати відносини між клубами, сприяти обміну ідеями, досвідом та ресурсами. Цей співробітничий підхід не тільки створює сильнішу мережу Ротарі, але й дозволяє нам реалізувати більші та амбітні проекти на користь наших спільнот.`},
                ],
            },
            {
                header: 'Напрацювання майбутнього',
                content: [
                    {LandingMessage: `Дистрикт 2232 прагне до виховання наступного покоління лідерів через молодіжні програми Ротарі. Ми надаємо молодим людям можливість розвивати свої лідерські навички, розуміння світу та відповідальність за довкілля і суспільство.`},
                ],
            },
        ]
    },
    {
        id: 1,
        header: 'Статистика по Дистрикту',
        arrcontent: [
            {
                content: [
                    // {LandingMessage: `Дистрикт налічує:
                    
                    // 75 Ротарі клубів
                    // > 2500 ратарійців
                    // 27 Ротаракт клубів
                    // 7 Інтеракт клубів`},
                    {LandingMessage: 'Ротарі клуби:'},
                    {LandingImage: "true", link: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/AboutD2232/Clubs.jpg'},
                    {LandingMessage: 'Члени клубів:'},
                    {LandingImage: "true", link: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/AboutD2232/people.jpg'},
                ],
                
            },
        ]
    },
    {
        id: 2,
        content: [
            {LandingMessage: `Ротарі Дистрикт 2232 - це не просто група клубів, а потужна мережа, яка здатна змінювати світ.
            Ми працюємо разом, щоб досягнути спільних цілей, зберігаючи при цьому індивідуальний підхід кожного клубу до реалізації своїх проектів.

            Приєднуйтесь до нас, щоб разом служити, навчатись, спілкуватись і працювати над покращенням світу. Це ваш шанс зробити різницю.`},
        ],
    },
    {
        id: 3,
        img: RI_1,
    }
];

const resData_eng = [
];