import axios from 'axios';

const SERVERURL = 'https://apifs.rotaryd2232ukraine.org/storage';

export const fsFetchFiles = async (path) => {
    console.log('fetchFiles ...');
    const token = localStorage.getItem('tokenFS');
    try {
        const response = await axios.post(`${SERVERURL}/list`, { path }, {
        headers: {
            'Storage-Token': token,
            'Content-Type': 'application/json'
        }
        });
        const arrFiles = response.data.callback;
        if (Array.isArray(arrFiles)) {
        return arrFiles;
        } else {
        console.error('Response data is not an array:', response.data);
        return null;
        }
    } catch (error) {
        console.error('Failed to fetch files:', error);
        return null;
    }
};

export const fsCreateFolder = async (path, newFolderName) => {
    const token = localStorage.getItem('tokenFS');
    try {
        await axios.post(`${SERVERURL}/create-folder`, {
            path,
            dir_name: newFolderName
        }, {
            headers: {
            'Storage-Token': token,
            'Content-Type': 'application/json'
            }
        });
        //onPathChange(path); // Refresh the list
        return true;
    } catch (error) {
        console.error('Failed to create folder:', error);
        return false;
    }
};

export const fsRenameItem = async (path, oldName, newName) => {
    const token = localStorage.getItem('tokenFS');
    try {
      await axios.post(`${SERVERURL}/rename`, {
        path,
        old_name: oldName,
        new_name: newName,
        type: 'folder' // or 'file'
      }, {
        headers: {
          'Storage-Token': token,
          'Content-Type': 'application/json'
        }
      });
      //onPathChange(path); // Refresh the list
      return true;
    } catch (error) {
      console.error('Failed to rename item:', error);
      return false;
    }
  };

export const fsDeleteItem = async (path, oldName) => {
    console.log('deleteItem ...');
    const token = localStorage.getItem('tokenFS');
    try {
        await axios.post(`${SERVERURL}/delete`, {
            path,
            items: [oldName]
        }, {
            headers: {
            'Storage-Token': token,
            'Content-Type': 'application/json'
            }
        });
        
        return true;
    } catch (error) {
        console.error('Failed to delete item:', error);
        return false;
    }
};

export const fsShareFiles = async (path) => {
    console.log('fetchFiles ...');
    const token = localStorage.getItem('tokenFS');
    try {
        const response = await axios.post(`${SERVERURL}/share`, { path }, {
        headers: {
            'Storage-Token': token,
            'Content-Type': 'application/json'
        }
        });
        const sharelink = response.data.link;
        console.error('Response data is not an array:', response.data);
        return sharelink;
    } catch (error) {
        console.error('Failed to fetch files:', error);
        return null;
    }
};

export const fsGetFile = async (path) => {
    console.log('fsGetFile ...');
    const token = localStorage.getItem('tokenFS');
    try {
        const response = await axios.post(`${SERVERURL}/share2`, { path }, {
        headers: {
            'Storage-Token': token,
            'Content-Type': 'application/json'
        }
        });
        const sharelink = response.data;
        console.log('Response link:', response);
        return sharelink;
    } catch (error) {
        console.error('Failed to fetch files:', error);
        return null;
    }
};