
import { LOCALES } from '../../../i18n/locales';

export const getRCLanding = (local) => {
    const cherkasyData = local === LOCALES.UKRAINIAN ? cherkasyData_ukr : cherkasyData_eng;
    return cherkasyData;
};

const cherkasyData_ukr = [
    {
        id: 0,
        header: 'ЩО НОВОГО?',
        content: [
            {LandingMessage: `Клуби Ротарі та Ротаракт тепер можуть шукати партнерів для проекту через Rotary Showcase.`},
            {LandingLink: "Огляд проектів", link: "/project_showcase/2"}, 
        ],
    },
];

const cherkasyData_eng = [
    {
        id: 0,
        header: `WHAT'S NEW?`,
        
        LandingChartProjects: `singapore`,
    },
];

