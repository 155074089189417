import * as React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { MdEmail } from 'react-icons/md';

const useFlexBoxStyle = () => {
    return {
      width: '100%',
      padding: '16px',
      background: 'linear-gradient(0deg, rgba(0, 128, 255, 0.03) 0%, rgba(0, 128, 255, 0.1) 100%)',
    };
  };

const TLandingFooter = ({footer}) => {
  const boxStyle = useFlexBoxStyle();

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => alert('Адресу пошти '+text+' скопійовано в буфер обміну!'))
      .catch((err) => console.error('Помилка копіювання тексту:', err));
  };

  return (
    footer==='nofooter' ? null :
    <Box className="footer" style={{bgcolor: 'darkblue'}}>
        {footer && footer.contactPerson &&
        <Box displey='flex' flexDirection='row' flexWrap='nowrap' pl='24px'>
            {footer.contactPerson.name && 
                <Typography variant='h6' sx={{ whiteSpace: 'nowrap' }}>{footer.contactPerson.name}</Typography>}
            {footer.contactPerson.phone && 
                <><Typography variant='h7' sx={{ whiteSpace: 'nowrap' }}>phone: +38067-340-8272</Typography>   <br/></>}
            {footer.contactPerson.email &&
                <><Typography variant='h7' sx={{ whiteSpace: 'nowrap' }}>email: {footer.contactPerson.email}</Typography> <br/></>}
            {footer.contactPerson.email2 &&
                <Typography variant='h7' sx={{ whiteSpace: 'nowrap' }}>email2: {footer.contactPerson.email2}</Typography>}
        </Box>
        }

        {footer && footer.social &&
        <>
            <Tooltip title={'Пошта'}>
            <IconButton onClick={() => handleCopyToClipboard('oncology@2upost.com')} aria-label="Пошта">
                <MdEmail color="white" size='1.5em' />
            </IconButton>
            </Tooltip>
            <Tooltip title={'Пошта2'}>
            <IconButton onClick={() => handleCopyToClipboard('paliychuk.oncology@gmail.com')} aria-label="Пошта2">
                <MdEmail color="white" size='1.5em' />
            </IconButton>
            </Tooltip>
            
        </>}

        <div style={{ width: '100%' }}></div>
        <Typography variant='body2'>developed by Tyko:DevStudio</Typography>
    </Box>
  );
};

export default TLandingFooter;