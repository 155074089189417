
export const getRotarians = () => {
    return defaultData;
};

const defaultData = [
    {
        img: 'https://bc.rotary.org.ua/assets/site/images/members/GTrvEVYH0O.webp',
        content: [
            {LandingMessage: "- названий Губернатор Дистрикту 2232 в каденції 2023–2024 ротарійського року, чартерний член та Голова комісії професійного служіння Ротарі клубу «Львів», Паст-Президент Ротарі клубу «Львів» у 1996–1997 рр."},
            {LandingMessage: "- лікар стоматолог-ортопед і хірург вищої категорії"},
            {LandingMessage: "- практикуючий стоматолог-імплантолог"},
            {LandingMessage: "- засновник та керівник Центру стоматологічної імплантації та протезування ТзОВ «ММ» https://clinicmm.com.ua/"},
            {LandingMessage: "- засновник видавництва «ГалДент» https://galdent.com.ua/"},
            {LandingMessage: "- ініціатор створення міжнародної ротарійської програми «Коронари» для хворих на ішемічну хворобу серця"},
            {LandingMessage: "- засновник Благодійного фонду «Санація» з організації стоматологічної допомоги для військових, звільнених з полону, дітей із вродженою відсутністю зубів, пацієнтів з онкологією та похилого віку"},
            {LandingMessage: "- організатор стоматологічної допомоги в зоні АТО/ООС та у місцях ведення бойових дій (проєкти «Тризуб Дентал», «Слон»)"},
        ],
        name: "Мирон Угрин",
        phone: "+38000-000-0000",
        email: "Something@gmail.com",
    },
    {
        content: [
            {LandingMessage: `Мене звати Валерій Гусак. Я з Рівного і входжу в Ротарі Клуб Рівне. Власник ПП "Домова служба". Спеціалізація фірми - побутові послуги з гарантією якості. А саме, послуги сантехніка, електрика, столяра, вантажників, прибиральниць, зварювальника, майстрів з ремонту приміщень, майстрів з виконання господарських робіт, майстрів з ремонту великої побутової техніки (холодильник, пральна машина, посудомийна машина, телевізор, газова колонка, газовий котел). Підприємство працює з 2002 року в містах Рівне, Львів, Луцьк, Тернопіль. Контактні телефони 0677175997, 0957414116. Сайт фірми https://domslugba.com/`},
        ],
        name: "Валерій Гусак",
        phone: "0677175997",
        email: "",
    },
    {
        img: 'https://res.cloudinary.com/tyko/image/upload/v1700422603/BetaRotarySite/%D0%A4%D0%BE%D1%82%D0%BE%20%D0%A0%D0%BE%D1%82%D0%B0%D1%80%D1%96%D0%B9%D1%86%D1%96%D0%B2/118745723_3507393812644703_5273247389385127446_n_hspykk.jpg',
        content: [
            {LandingMessage: `Розвиток Культури пралень-хімчисток в Україні з прицілом виходу на європейський ринок.`},
            {LandingMessage: `Засновник мережі пралень-хімчисток "Happy Laundry".
            https://www.happylaundry.com.ua`},
            {LandingMessage: `Продаж франшиз "Happy Laundry", консультування з питань відкриття  пралень-хімчисток та масштабування їх у мережу.
            https://www.happylaundry.com.ua/franshyza`},
        ],
        name: "Євген Заярний",
        rclub: `Ротарі Клуб "Харків"`,
        phone: "0968618616",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Дмитрієв Віталій Олександрович, власник та голова наглядової ради асоціації KONSORT. Учасник полтавського клубу Rotary. Головний напрямок бізнесу компанії KONSORT - це виробництво обладнання для автоматизації виробництва: конвеєрних ліній, сортувальних систем та рішень оптимізації для підприємців. Компанія працює В2В в таких сферах: логістична, промислова, аграрна, екологічна. Зараз KONSORT - це більше 500 людей в команді, 22 закордонних представництва, 17 років безперебійної роботи та більше 4000 успішно реалізованих проектів. Більше інформації про компанію за посиланням: https://konsort.com.ua`},
        ],
        name: "Дмитрієв Віталій Олександрович",
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Сергій Пилипів, засновник Grafix.in.ua - виробництво обладнання для порошкового фарбування та піскоструменевого очищення. 
            Виготовлення металоконструкцій, лазерна порізка, порошкове фарбування.`},
        ],
        name: "Сергій Пилипів",
        rclub: `Президент РК Харків Інтернешнл`,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: ` Михайлів Леся Ротарі Клуб Тернопіль Сіті 0967204051. ФОП та співзасновник фірми НЕОМЕД-ПРО. В медичному бізнесі 10 років. В даний час маємо біля 30 лікарів різних спеціальностей в поліпрофільному медичному центрі та 3 амбулаторії сімейного лікаря та мобільну амбулаторію для виїзду до пацієнтів . Успішно співпрацюємо зі страховими компаніями та НСЗУ. Маємо досвід благодійних проектів, працювали з партнерськими організаціями і на деокупованій Херсонщині. Гасло компанії- якісні медичні послуги за доступною ціною`},
        ],
        name: "Михайлів Леся",
        rclub: `Ротарі Клуб Тернопіль Сіті`,
        phone: "0967204051",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Вітаю спільноту, Ротарі клуб "Uzhhorod-Skala", Головей Василь, радий знайомству.
            Співвласник компанії La Porte двері та вікна.
            Працюємо з 2008 в напрямку будівельних матеріалів, а саме:
            - Міжкімнатні двері
            - Двері прихованого монтажу
            - Металопластикові вікна 
            - Вхідні групи
            Величезне портфоліо завершених об'єктів, географія - захід України та схід ЄС`},
        ],
        name: "Головей Василь",
        rclub: `Ротарі клуб "Uzhhorod-Skala"`,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Добрий день. Мусієнко Гліб, співзасновник бренду одягу the Gentlmen (https://thegentlementracksuits.com/) . Також працюю у сфері будівництва (Париж, Київ) - проекти під ключ. Допомагаю з грошовими переказами по світу в тому числі криптовалютними. Діючий президент РК "Kharkiv First Capital"`},
        ],
        name: "Мусієнко Гліб",
        rclub: `Діючий президент РК "Kharkiv First Capital"`,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Засновник та партнер групи рієлторських компаній з 1993 року.

            Представник (Global Ambassador) NAR США (the National Association of REALTORS®) у Східній Європі 2020-22, міжнародний член Ради директорів NAR 2017-18. Сертифікований тренер Інституту Нерухомості REBI та Ради Агентів Покупця REBAC, Чикаго.
            
            Практичний досвід: 30 років управлінської, професійної агентської та консультаційної практики на ринку нерухомості, активної громадської та викладацької діяльності.`},
        ],
        name: "Артур ОГАНЕСЯН",
        rclub: `Паст-Президент Ротари-Клубів «Черкаси» (2002-3) та «Київ-Софія» (2021-22)`,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Доброго дня всім!
            Колихалова Ольга. 
            068-345-31-40
            Паст Президент РК КИЇВ- СІТІ.
            Працюю в сфері митно-брокерської діяльності з 1993 року. З 2000 року засновник ТОВ «БРОК-СЕРВІС ЗЕД».
            Проводимо митні оформлення по всій території України. 
            З 2010 року Член Київської Громадської ради при  ДМСУ, з 2019 року член Громадської ради ДМСУ.
            Успішно працюємо як з Великими платниками податків так і з малим бізнесом.
            Маємо досвід благодійних проектів. З 2004 року допомагаємо Київському Центральному госпіталю.
            Гасло компанії- якісні послуги та гнучкі розцінки. Консультуємо по Зовнішньоекономічній діяльності цілодобово!))`},
        ],
        name: "Колихалова Ольга",
        rclub: `Паст Президент РК КИЇВ- СІТІ`,
        phone: "068-345-31-40",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Всім привіт ! 
            Василь Гіркий , президент харківського клубу "Kharkiv Rotary Spirit” 
            Власник ювелірного виробництва «UMAX" https://instagram.com/umax.ua?igshid=MzRlODBiNWFlZA== виготовляємо срібні прикраси власного дизайну та індивідуального замовлення та власник компанії «GVS Cosmetics»- спеціалізуємося на прямих постачаннях корейської косметики, власники ексклюзивних прав на 3 корейських бренда в Україні 
            https://instagram.com/gvscosmetics.ukraine?igshid=MzRlODBiNWFlZA==
            
            Співзасновник благодійного фонду «Україна моєї мрії» https://instagram.com/ymm.23?igshid=MzRlODBiNWFlZA==`},
        ],
        name: "Василь Гіркий",
        rclub: ``,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Євген Корольов – український бізнесмен, власник інноваційної освітньої платформи ARBOOK. Харків'янин, засновник та власник групи компаній KRON. Одна з компаній групи – Inegva у 2023 році увійшла до списку Forbes Ukraine у номінації «найперспективніші молоді компаній країни». Меценат, створив та постійно фінансує міський коворкінг для розвитку дітей та підлітків «Злітай». Сім'янин, батько трьох дочок. Захоплюється йогою, дайвінгом, альпінізмом. Учасник першої української експедиції у найвищу точку Антарктиди – Масив Вінсон.`},
        ],
        name: "Євген Корольов",
        rclub: ``,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Сергієнко Андрій!

            Харків, ротарі клуб “Kharkiv spirit”
            ФОП, "Хprom."
            виробництво з металообробки
            холодне штампування,
            упаковка з жерсті,
             - тубуси, баночки для кондитерки та косметики.
            виготовлення каркасів з профілю та труби для меблів.
            зварювальні роботи.
            у виробництві з 2000 р
            «будь-яка фантазія пов'язана з металом нам під силу»
            
            Співзасновник благодійного фонду «Україна моєї мрії» https://instagram.com/ymm.23?igshid=MzRlODBiNWFlZA==`},
        ],
        name: "Сергієнко Андрій",
        rclub: ``,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Ротарі клуб «Полтава»

            Іщейкіна Юлія Олексіївна, співзасновник 
            Готельно-рестораного бізнесу
            
            Розвиток готельного бізнесу, проведення різноманітних івентів, розвиток сучасної української кухні у Полтаві та з масштабуванням по Україні ﻿https://galery.com.ua/?fbclid=PAAaYNjFkeXcUMSgvKhXh9fxnBScEs1Fr3wF0jjvPhEos0WTyLJNS2Vrr3aaA_aem_AQkp5_oBeV2UtY9JfP1T4c2V4goH5Lc2hxJGDDbJbJv0t9HI5-oEVt78ABxiI41eRxA 
            
            Також оренда нерухомості у Полтаві 
            
            Тел 0675307771 (на будь якому месенджері)`},
        ],
        name: "Іщейкіна Юлія Олексіївна",
        rclub: ``,
        phone: "Ротарі клуб «Полтава»",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Дворніков Анатолій,  Харків, ротарі клуб “Kharkiv spirit”.
            Співвласник мережі автосервісів і інтернет магазину автозапчастин АЗР.
           
           https://instagram.com/azr.kharkov?igshid=OGQ5ZDc2ODk2ZA==
           
           https://instagram.com/azr.frantsuz?igshid=OGQ5ZDc2ODk2ZA==
           
           https://instagram.com/azr.yug?igshid=NGVhN2U2NjQ0Yg==
           
           https://www.azr.com.ua/
           
           Співзасновник благодійного фонду «Україна моєї мрії» https://instagram.com/ymm.23?igshid=MzRlODBiNWFlZA==`},
        ],
        name: "Дворніков Анатолій",
        rclub: `Харків, ротарі клуб “Kharkiv spirit”`,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Разінкін Роман, Ізмаїл. РК «Ізмаїл».

            Маю барбершоп «THE KEEN» з 2019 року;
             
            Лабораторія прийому аналізів франшиза від компанії «ДІЛА», з 2023;
            
            Страхування авто та життя.`},
        ],
        name: "Разінкін Роман",
        rclub: `РК «Ізмаїл»`,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Всім привіт . Для знайомства : Павловський Валерій , РК «Дніпро - Сіті»,
            Засновник та директор компанії «Електропромремонт «.
            Ремонт та модернізація крупних Електродвигунів. 
            ЕЛЕКТРОПРОМРЕМОНТ
            https://www.epr.com.ua/`},
        ],
        name: "Павловський Валерій",
        rclub: `РК «Дніпро - Сіті»`,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Вітаю, колеги! 
            Для знайомства:
            Зіновій Свереда. Я є доктором соціальної економіки. Проживав і навчався в Італії. Згодом викладав в Римі в роках 2010-2014 рр. Протягом останніх років займався тренінгами з соціального підприємництва та соціально-відповідального бізнесу згідно європейських стандартів та норм. Є засновником кількох компаній по освіті. Переможцем кількох грантів по підприємництву і зараз отримав грант від Ватикану по написанні дослідження про війну, етику та економіку в Україні. Додатково, є представником італійської асоціації РМІ – об’єднання малого і середнього бізнесу. Зараз готуємо проект по дистрибуції якісних сертифікованих італійських продуктів з серттифікатом DOC I DOCG IGT   і т д в Україну і вивчаємо усю документацію для експорту деяких видів продукції з України. Додатково, проводжу тренінги для розвитку місцевих громад, соціального підприємництва. У лютому 2024, готуємо велику конференцію в Венеції при співпраці з мерією і клубами Ротарі в Італії.  Тел. 0972832089`},
        ],
        name: "Зіновій Свереда",
        rclub: ``,
        phone: "0972832089",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Вітаю , друзі! 
            Для знайомства : Тетяна Андрусь, Паст- президент Ротарі Клубу Ужгород. Працювала 16 років сільським  головою Ратівецької сільської ради .
            Зараз займаю посаду декана гуманітарного - економічного факультету, директор коледжу Карпатського університету імені Августина Волошина.
            Викладач вищої категорії юридичних дисциплін .
            На даний  період часу є членом правління Ротарійської  спілки Дистрикту 2232 .
            Багато років поспіль є членом  Громадської ради при Закарпатській ОДА.
            Нещодавно заснувала сімейний бізнес . Разом із родиною відкрили сучасну стоматологічну клініку із широким спектром стоматологічних послуг.
            На даний час в ній працює десять  лікарів професіоналів своєї справи. Плануємо відкрити стоматологічні клініки ще в інших містах України.
            https://www.facebook.com/profile.php?id=61551025783656`},
        ],
        name: "Тетяна Андрусь",
        rclub: `Паст- президент Ротарі Клубу Ужгород`,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Вітаю, друзі. 
            Для знайомства:
            Євген Чумак
            Чартерний член Ротарі клубу Харків New Level. Елект президент. 
            Співвласник маркетингового агентства Дефанс. Ми допомагаємо компаніям в2В і в2С сектора збільшувати прибуток і частку ринку завдяки використанню величезного арсеналу маркетингових інструментів. Ми шукаємо точки зростання всередині компанії, аналізуємо конкурентів, досліджуємо аудиторію, створюємо торгові марки, допомагаємо упакувати бізнес, підбираємо комплекс ефективних інструментів просування. Робимо це понад 14 років. за нашими плечима понад 500 проєктів у 60 нішах бізнесу. Наша кваліфікація підтверджена ЄБРР. Ми акредитовані консультанти з маркетингу.`},
        ],
        name: "Євген Чумак",
        rclub: `Чартерний член Ротарі клубу Харків New Level. Елект президент. `,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Вітаю друзі 🙋‍♂️.
            Для знайомства:
            Володимир Ващук!
            +38 097-238-35-06 
            Ротарі Клуб “Kharkiv spirit”
            Співвласник виробничої рекламної компанії “РЕКЛАМНИЙ ЕКСПЕРТ» 
            м. Київ.
            https://promoexpert.com.ua/
            Виготовляємо рекламно-торгівельне обладнання.
            Можливості нашого виробництва та конструктивних рішень нашої команди.
            Дають нам можливості розробляти та втілювати в життя рекламні
            елементи, рекламні конструкції. Виготовляємо рекламно-торгівельне обладнання, світлові стійки, вивіски, рекламні
            інсталяції, презентаційні стопери, тощо. Здійснюємо монтування рекламного обладнання та оформлення торгівельних приміщень.`},
        ],
        name: "Володимир Ващук",
        rclub: `Ротарі Клуб “Kharkiv spirit”`,
        phone: "+38 097-238-35-06",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Мої вітання! Для знайомства🖐️:
            Пархомей Олена, член та скарбник Ротарі клубу « Лучеськ»; засновниця та власниця бухгалтерської компанії, якій вже більше чотирнадцяти років. Обслуговуємо, відновлюємо, надаємо бухгалтерський супровід бізнесу усіх галузей господарювання. Налаштовуємо бухгалтерський, податковий, кадровий облік, фінансове планування та оптимізація.
            місто Луцьк; +380503782556; +380685637584;
            intelektcentr@gmail.com`},
        ],
        name: "Пархомей Олена",
        rclub: `член та скарбник Ротарі клубу « Лучеськ»`,
        phone: "+38050378255",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Вітаю!
            Павло Філіппенко
            Паст президент РК Харків «Нью левел»
            Експерт у будівельній галузі-22 роки у будівництві.
            Знаю про будівництво ВСЕ!
            Керую великими будівельними проектами.🏗️🏢
            Запускаємо інноваційний проект Експрес-школа будівельників.👷‍♂️
            Маємо авторизацію та виконуємо весь спектр робіт та продаємо матеріали, вікна, двері по програмі «Е-відновлення».🧱🪚
            Керую Добробатом у Харківській області. Добровільні будівельні батальони. 💪
            Очолюю у волонтерській раді Харківщини напрямок відбудови.🤝🇺🇦`},
        ],
        name: "Павло Філіппенко",
        rclub: `Паст президент РК Харків «Нью левел»`,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Всім доброго дня!
            Ротарі клуб LVIV-RATUSHA
            
            Смілевський Леонід- засновник групи фірм.
            
            Понад 25 років працюємо на теренах України та Польщі:
            - будівництво промислових та інфраструктурних об'єктів, в т.ч. мости, дороги;
            - аутсорсінгові послуги в бухгалтерському та кадровому обслуговувані фірм та підприємств;
            -міжнародна торгівля;
            -навчання за кордоном;
            -готові надати в оренду коло 700кв.м власних площ під медицину, офіс і т.п.
            
            +380 67 703 24 17
            +48 519 467 312
            Готові до взаємовигідної співпраці`},
        ],
        name: "Смілевський Леонід",
        rclub: `Ротарі клуб LVIV-RATUSHA`,
        phone: "+380 67 703 24 17",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Доброго дня, вирішив теж долучитись! Я Олександр Книга президент Ротарі клуб Херсон, директор- художній керівник Академічного Муздрам театру ім . Миколи Куліша. Сімейний бізнес база зеленого туризму « Чумацька Криниця» наразі в окупації на лівому березі Херсонщини. З моменту звільнення міста знаходжусь в Херсоні займаюсь відновленням творчої - терапевтичної роботи і волонтерською діяльністю. За цей час прийняли і розподілили сотні тон вантажів від Ротарійців України і світу та інших благодійних організацій. Відкритий до діалогу і співпраці. Мій тел + 380505057094.`},
        ],
        name: "Олександр Книга",
        rclub: `президент Ротарі клуб Херсон`,
        phone: "+380505057094",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Вітаю друзі Ротарійці! 
            Для знайомства:
            Костянтин Мікічук!
            +38 066-666-93-19
            Паст- президент Ротарі Клуб Чернівці. 
            Співвласник «ОКВ Сервіс», надання послуг по обслуговуванню авто, шиномонтаж та основним є:
            - продаж БУ та нових шин, великий асортимент розмірів, (самі возимо із-за кордону, опт та роздріб); 
            м. Чернівці!`},
        ],
        name: "Костянтин Мікічук",
        rclub: `Паст- президент Ротарі Клуб Чернівці`,
        phone: "+38 066-666-93-19",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Друзі, доброго дня! 
            Нікіта Гущенко, РК Миколаїв (терріторіально Київ).
            
            Підприємець у сфері мобільного зв‘язку: Співпраця з операторами, дистрибуція обладнання для підсилення 4G, e-commerce Itcentre.net.ua
            
            Буду радий знайомству та відкритий до ідей. 
            +380631110000`},
        ],
        name: "Нікіта Гущенко",
        rclub: `РК Миколаїв (терріторіально Київ)`,
        phone: "+380631110000",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Усім привіт!
            Влад Собко, РК "Черкаси Інтернешнл".
            
            Спеціалізація - допомога у створенні "Організацій майбутнього", а саме:
            
            🧭 Стратегування. Мріяння. Виставлення цілей вдовгу. Робота з існуючою стратегію за підходом Red Team.
            
            🧬 Створення People Model під бізнес-модель компанії. Це про організаційну модель, орієнтовану на синергію бізнес-цілей та енергії команд компанії, для її "бусту" та довгострокового розвитку.
            
            👉Завтра, 28.10, на Форумі у Львові буду представляти "Модель Команд" - ядро системи People Model. 
            
            Кому цікаво це обговорити, контактуйте🙂.`},
        ],
        name: "Влад Собко",
        rclub: `РК "Черкаси Інтернешнл"`,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Доброго здоров'я друзі!
            Моє ім'я Олександр Лісовський і завтра буду розказувати про найбільший імпакт проєкт "Сімейні молочні ферми "🌱🐄
            Будемо разом з ротарійцями клубу Дубно і командою проєкту і буде доступна дегустація та продаж сирів крафтового виробництва!) І тет-а-тет  консультація як ми працюємо!
            
            Передбачайте торбинки ))🤲 купити натуральні смаколики.
            
            В проєкті можна бути інвестором!💪☝️
            До зустрічі!👀`},
        ],
        name: "Олександр Лісовський",
        rclub: ``,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Вітаю!
            Олексій Михайлов
            Ротарі клуб "Харків Мультінешнл"
            Власник дистриб'юторської компанії Гермес Трейд. Сегмент HoReCa.
            вже 15 років компанія є постачальником продуктів для громадського харчування.
            Сфера інтересів імпорт-експорт.
            Завжди відкритий до пропозицій та спілкування.
            +380675400700
            Всім гарного вечора)`},
        ],
        name: "Олексій Михайлов",
        rclub: `Ротарі клуб "Харків Мультінешнл"`,
        phone: "+380675400700",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Всім доброго дня. 
            Для знайомства: Данилюк Ярослав, член клубу Ротарі Львів. Я є засновником підприємства «Крес» у місті Львові у 1994 році. Перше приватне підприємство в Україні у галузі Pest Control (контроль шкідників / знане як дезінфекція, дезінсекція та дератизації ) на підприємствах  харчової промисловості та у всіх сферах життєдіяльності людини. Ми перші в Україні розпочали дані послуги за європейськими та світовими стандартами (HACCP, BRC/BRS, ISO 22000 і т.д.). Нашими контрагентами є  багато відомих компаній в Україні. За 30 років роботи, ми не підводили жодного разу наших клієнтів, отримуючи тільки позитивні рішення як українських так і міжнародних аудитів, які проводилися на підприємствах наших контрагентів.
            Більше інформації на сайті: https://www.kres.com.ua`},
        ],
        name: "Данилюк Ярослав",
        rclub: `член клубу Ротарі Львів`,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Доброго дня, Мене звуть Костянтин та моя дружина Альона ми з Ротарі клубу Rotary Club Kharkiv Multinational 
 
            Наша будівельна компанія 'ЗА.БУДОВА' 👋  
            Ми пропонуємо: 
            🔸 Ремонт вікон, захист плитами OSB  
            🔸 Реставрація рам, регулювання, заміна фурнітури  
            🔸 Скління, заміна склопакетів, нові вікна  
            🔸 Ремонт дахів та водостоків 
            🔸 Внутрішній та зовнішній ремонт житлових та комерційних приміщень 'під ключ' 
              
            Підписуйтесь на нас в інстаграм та фейсбук🤗 
              
            Давайте будувати майбутнє!  
              
            З повагою🇺🇦  
            066 919 31 39
            +380 (68) 528 28 98
              
              
            Ми в  інстаграмм  - https://instagram.com/za.budova.kh
            Ми у фейсбук  -  https://www.facebook.com/za.budova.kh`},
        ],
        name: "Костянтин",
        rclub: `Rotary Club Kharkiv Multinational `,
        phone: "066 919 31 39",
        email: "",
        insta: "https://instagram.com/za.budova.kh",
        fb: "https://www.facebook.com/za.budova.kh",
    },
    {
        img: "https://res.cloudinary.com/tyko/image/upload/v1700424358/BetaRotarySite/%D0%A4%D0%BE%D1%82%D0%BE%20%D0%A0%D0%BE%D1%82%D0%B0%D1%80%D1%96%D0%B9%D1%86%D1%96%D0%B2/photo_2023-11-19_22-05-37_yv70yr.jpg",
        content: [
            {LandingMessage: `Ротарі Клуб Трускавець 
            Розора Катерина 
            Готель Клуб рН Трускавець`},
        ],
        name: "Розора Катерина",
        rclub: `Ротарі Клуб Трускавець`,
        phone: "",
        email: "",
    },
    {
        img: "https://res.cloudinary.com/tyko/image/upload/v1700424457/BetaRotarySite/%D0%A4%D0%BE%D1%82%D0%BE%20%D0%A0%D0%BE%D1%82%D0%B0%D1%80%D1%96%D0%B9%D1%86%D1%96%D0%B2/photo_2023-11-19_22-07-22_xdeiql.jpg",
        content: [
            {LandingMessage: ``},
        ],
        name: "Розора Сергій ",
        rclub: `Ротарі Клуб Трускавець`,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Вітаю! Я Катерина Стрельченко, режисер-документаліст, продюсер, член Спілки кінематографістів України, клуб "Ротарі регіон".
            +380677622742, katnew@ukr.net
            Авторка низки фільмів про історію, видатних українців, війну та її наслідки. Презентуємо наші роботи як в Україні, так і за кордоном. Вже на цьому тижні рушаємо разом з президентом клубу "Ротарі регіон" Анною Бабенко в черговий тур у Німеччину, щоб презентувати нову стрічку "Шукачі загублених душ", створену за підтримки DW Akademie (Німеччина). Головні герої фільму волонтери-кінологи, які, ризикуючи життям, розшукують разом зі своїми собаками безвісті зниклих.
            Звертайтеся, будь ласка, якщо буде необхідність у якісному відео контенту.`},
        ],
        name: "Катерина Стрельченко",
        business: `режисер-документаліст, продюсер, член Спілки кінематографістів України`,
        rclub: `клуб "Ротарі регіон"`,
        phone: "+380677622742",
        email: "katnew@ukr.net",
    },
    {
        img: "https://res.cloudinary.com/tyko/image/upload/v1700424683/BetaRotarySite/%D0%A4%D0%BE%D1%82%D0%BE%20%D0%A0%D0%BE%D1%82%D0%B0%D1%80%D1%96%D0%B9%D1%86%D1%96%D0%B2/photo_2023-11-19_22-11-13_jc0dir.jpg",
        content: [
            {LandingMessage: `Вітаю. 
            Я Сухина Сергій Анатолійович, член Ротарі клубу Kharkiv Direct Help International.
             20 років займаюсь виробництвом та продажем заморожених овочів, фруктів  та ягід.  Власник ТМ "Артика"  Компанія до 24.02 постачала продукцію і всі національні мережі. Завод був повністю зруйнований в 2022р. З осені минулого року почали відновлення. На сьогодні маємо потужності по зберіганню,фасуванню та логістиці заморожених продуктів.
             https://www.artika.com.ua`},
        ],
        name: "Сухина Сергій Анатолійович",
        rclub: `член Ротарі клубу Kharkiv Direct Help International`,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Дорогі друзі, ми зараз працюємо над розвитком медової галузі. Пропонуємо медові крафтові продукти високої якості для ротарійців. Чекаємо результатів кількох грантів,  серед них реабілітація воїнів ато, убд, ветеранів на основі меду та дієти, розвитком соціального підприємства з меду. За кілька місяців будемо мати усі документи для експорту за кордон.  Зараз пропонуємо іменні корпоративні та клубні подарунки для ротарійців зі знижками. Значна частина коштів піде для реалізації інших проектів. Свій до свого по своє. З повагою, Зіновій Свереда.`},
        ],
        name: "Зіновій Свереда",
        rclub: ``,
        phone: "",
        email: "",
    },
    {
        content: [
            {LandingMessage: `Доброго здоров'я, друзі. Я Володимир Бєліков, РК Дніпро Регіон. Співзасновник компанії BVB. Я розробник усієї лінійки продуктів для здоров'я.  https://bestvitabeauty.com  Запатентував Програму мінімізації посттравматичного синдрому.`},
        ],
        name: "Володимир Бєліков",
        rclub: `РК Дніпро Регіон`,
        phone: "",
        email: "",
    },
    /*{
        img: "",
        content: [
            {LandingMessage: ``},
        ],
        name: "",
        rclub: ``,
        business: ``,
        phone: "",
        email: "",
        insta: "https://instagram.com/za.budova.kh",
        fb: "https://www.facebook.com/za.budova.kh",
    },*/
    
]
//insta: "https://instagram.com/za.budova.kh",
//fb: "https://www.facebook.com/za.budova.kh",
//rclub: ``,
//business: `режисер-документаліст, продюсер, член Спілки кінематографістів України, клуб "Ротарі регіон"`