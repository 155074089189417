import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Stack } from '@mui/material';
import { gapi } from 'gapi-script';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import additionalEvents from './events.json';

import './Calendar.css'; // Імпортуємо CSS

const CLIENT_ID = '190006217350-nuafqanruc4qhpvuov3astrp0g971cfm.apps.googleusercontent.com';
const API_KEY = 'AIzaSyArcEXk6t0Hr0_roXcVY9-BDDKIMduSCNc';
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
const SCOPES = "https://www.googleapis.com/auth/calendar.readonly";


const GoogleCalendar = () => {
    const [events, setEvents] = useState([]);
    const [isSignedIn, setIsSignedIn] = useState(false);
  
    useEffect(() => {
      const initClient = () => {
        gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        }).then(() => {
          const authInstance = gapi.auth2.getAuthInstance();
          setIsSignedIn(authInstance.isSignedIn.get());
          authInstance.isSignedIn.listen(setIsSignedIn);
          if (authInstance.isSignedIn.get()) {
            listUpcomingEvents();
          }
        }).catch(error => {
          console.error('Error during gapi client initialization:', error);
        });
      };
  
      gapi.load('client:auth2', initClient);
    }, []);
  
    const handleAuthClick = () => {
      gapi.auth2.getAuthInstance().signIn().then(() => {
        listUpcomingEvents();
      }).catch(error => {
        console.error('Error during sign-in:', error);
      });
    };
  
    const handleSignoutClick = () => {
      gapi.auth2.getAuthInstance().signOut().then(() => {
        setEvents([]);
        setIsSignedIn(false);
      }).catch(error => {
        console.error('Error during sign-out:', error);
      });
    };
  
    const listUpcomingEvents = () => {
      const oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  
      gapi.client.calendar.events.list({
        'calendarId': 'primary', // Замініть на потрібний календарний ідентифікатор, якщо потрібно
        'timeMin': oneYearAgo.toISOString(),
        'timeMax': oneYearFromNow.toISOString(),
        'showDeleted': false,
        'singleEvents': true,
        'orderBy': 'startTime'
      }).then(response => {
        const googleEvents = response.result.items.map(event => ({
          title: event.summary,
          start: event.start.dateTime || event.start.date,
          end: event.end.dateTime || event.end.date,
          description: event.description
        }));
  
        // Об'єднання подій з Google Calendar і JSON
        const combinedEvents = [...googleEvents, ...additionalEvents];
        setEvents(combinedEvents);
  
      }).catch(error => {
        console.error('Error fetching events:', error);
      });
    };
  
    const addEventsToGoogleCalendar = () => {
      additionalEvents.forEach(event => {
        const eventDetails = {
          summary: event.title,
          start: {
            dateTime: event.start,
          },
          end: {
            dateTime: event.end,
          },
          description: event.description,
        };
  
        gapi.client.calendar.events.insert({
          calendarId: 'primary',
          resource: eventDetails,
        }).then(response => {
          console.log('Event created: ', response);
          listUpcomingEvents(); // Оновлення подій після додавання
        }).catch(error => {
          console.error('Error adding event: ', error);
        });
      });
    };
  
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" gutterBottom>
          Google Calendar Events
        </Typography>
        {!isSignedIn ? (
          <Button variant="contained" color="primary" onClick={handleAuthClick}>
            Sign In
          </Button>
        ) : (
          <>
            <Button variant="contained" color="secondary" onClick={handleSignoutClick}>
              Sign Out
            </Button>
            <Button variant="contained" color="primary" onClick={addEventsToGoogleCalendar} sx={{ ml: 2 }}>
              Add JSON Events to Google Calendar
            </Button>
            <Box sx={{ mt: 2 }}>
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                events={events}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                buttonText={{
                  today: 'Today',
                  month: 'Month',
                  week: 'Week',
                  day: 'Day'
                }}
              />
            </Box>
          </>
        )}
      </Box>
    );
  };
  
  export default GoogleCalendar;