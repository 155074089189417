
export const getBusinessLanding2 = (isUkraine) => {
    return defaultData_ukr;
};

const defaultData_ukr = [
    {
        id: 6,
        header: 'Майбутня подія',
        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/committee_businesscouncil/events/28.04.2024/afisha.jpg',
        arrcontent: [
            {
                id: 0,
                LandingCardEvent: 2,
            }
        ]
    },
];

