
import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from '@mui/material'
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';
import Tooltip from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom';
import '../Pages.css';

import { Tabs, Tab } from '@mui/material';

import MainPage from '../../modules/MYMain';
import LandingBlock from '../../components/LandingBlock';
import {titles1, getGovernors, getOverDistrict, getPastGovernors, getAssistents, getCommittees} from '../../api/d2232Team';
import {titles2, getGovernors2, getOverDistrict2, getPastGovernors2, getAssistents2, getCommittees2} from '../../api/d2232Team_2425';

const TabPanel = ({ children, value, index }) => {
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

const CommitteesPage = () => {
    const [titles, setTitles] = useState([]);
    const [governors, setGovernors] = useState([]);
    const [overDistrict, setOverDistrict] = useState([]);
    const [pastGovernors, setPastGovernors] = useState([]);
    const [assistents, setAssistents] = useState([]);
    const [committees, setCommittees] = useState([]);

   
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');

    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
            loadTeam(activeTab);
          
          //console.log("IccPage get ICC list success. const="+icc.length);
        } catch (error) {
          console.log("IccPage get ICC list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadTeam(activeTab);
    }, [activeTab]);

    const loadTeam = async (atab) => {
        if (atab === 0) {

            setTitles(titles1);

            const data1 = await getGovernors();
            setGovernors(data1);

            const data2 = await getOverDistrict();
            setOverDistrict(data2);


            const data3 = await getPastGovernors();
            setPastGovernors(data3);

            const data4 = await getAssistents();
            setAssistents(data4);

            const data5 = await getCommittees();
            setCommittees(data5);
          
        } else {
            setTitles(titles2);

            const data1 = await getGovernors2();
            setGovernors(data1);

            const data2 = await getOverDistrict2();
            setOverDistrict(data2);


            const data3 = await getPastGovernors2();
            setPastGovernors(data3);

            const data4 = await getAssistents2();
            setAssistents(data4);

            const data5 = await getCommittees2();
            setCommittees(data5);
          
        }
    }

    const showOfficerTables = () => {
        return (
            <>                  
                    <Typography variant="h4" className="page-header">{titles.title1}</Typography>
                    
                    <h3>{titles.title2}</h3>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow sx={{ backgroundColor: "#98c8ff" }}>
                            <TableCell></TableCell>
                            <TableCell>Function</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Rotary Club</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>E-mail</TableCell>
                            <TableCell>My rotary account</TableCell>
                            <TableCell>Learning center</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {governors.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{index+1}</TableCell>
                              <TableCell>{row.Function}</TableCell>
                              <TableCell>{row.Name}</TableCell>
                              <TableCell>{row["Rotary Club"]}</TableCell>
                              <TableCell>{row.Phone}</TableCell>
                              <TableCell>{row["E-mail"]}</TableCell>
                              <TableCell>{row["My rotary account"]}</TableCell>
                              <TableCell><a href={row["Learning center"]}>link</a></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
    
                    <br/><br/><h3>ROTARIANS SERVING ON OVER-DISTRICT LEVEL - Офіцери, які служать на поза-Дистриктному рівні</h3>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow sx={{ backgroundColor: "#98c8ff" }}>
                            <TableCell></TableCell>
                            <TableCell>Function</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Rotary Club</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>E-mail</TableCell>
                            <TableCell>My rotary account</TableCell>
                            <TableCell>Learning center</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {overDistrict.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{index+1}</TableCell>
                              <TableCell>{row.Function}</TableCell>
                              <TableCell>{row.Name}</TableCell>
                              <TableCell>{row["Rotary Club"]}</TableCell>
                              <TableCell>{row.Phone}</TableCell>
                              <TableCell>{row["E-mail"]}</TableCell>
                              <TableCell>{row["My rotary account"]}</TableCell>
                              <TableCell><a href={row["Learning center"]}>link</a></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
    
                    <br/><br/><h3>PAST DISTRICT GOVERNOR (INCLUDING SERVED FOR RID 2230) - Паст Губернатори Д2232 (Д2230)</h3>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow sx={{ backgroundColor: "#98c8ff" }}>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Rotary Club</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>E-mail</TableCell>
                            <TableCell>My rotary account</TableCell>
                            <TableCell>Learning center</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pastGovernors.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{index+1}</TableCell>
                              <TableCell>{row.Name}</TableCell>
                              <TableCell>{row["Rotary Club"]}</TableCell>
                              <TableCell>{row.Phone}</TableCell>
                              <TableCell>{row["E-mail"]}</TableCell>
                              <TableCell>{row["My rotary account"]}</TableCell>
                              <TableCell><a href={row["Learning center"]}>link</a></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
    
                    <br/><br/><h3>GOVERNOR ASSISTANTS - Асистенти Губернатора</h3>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow sx={{ backgroundColor: "#98c8ff" }}>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Rotary Club</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>E-mail</TableCell>
                            <TableCell>My rotary account</TableCell>
                            <TableCell>Learning center</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {assistents.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{index+1}</TableCell>
                              <TableCell>{row.Name}</TableCell>
                              <TableCell>{row["Rotary Club"]}</TableCell>
                              <TableCell>{row.Phone}</TableCell>
                              <TableCell>{row["E-mail"]}</TableCell>
                              <TableCell>{row["My rotary account"]}</TableCell>
                              <TableCell><a href={row["Learning center"]}>link</a></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>  
    
                    <br/><br/><h3>DISTRICT COMMITTEES</h3>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow sx={{ backgroundColor: "#98c8ff" }}>
                            <TableCell></TableCell>
                            <TableCell>Committee</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Rotary Club</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>E-mail</TableCell>
                            <TableCell>My rotary account</TableCell>
                            <TableCell>Learning center</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {committees.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {index+1} 
                                  <Tooltip title={"Перейти на сайт комітету"}>
                                  {row.website && <IconButton onClick={() => window.open(row.website, '_blank')} aria-label="Open Website">
                                  <LanguageIcon color="primary" />
                                  </IconButton>}
                                  </Tooltip>
                                </div>
                              </TableCell>
                              <TableCell>{row.Committee}</TableCell>
                              <TableCell>{row.Role}</TableCell>
                              <TableCell>{row.Name}</TableCell>
                              <TableCell>{row.Club}</TableCell>
                              <TableCell>{row.Phone}</TableCell>
                              <TableCell>{row["E-mail"]}</TableCell>
                              <TableCell>{row["My rotary account"]}</TableCell>
                              <TableCell>{row["Learning center"] && <a href={row["Learning center"]}>link</a>}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
            </>
      );
    }

    return (
        <>
          <MainPage>
            <LandingBlock mode='light'>
            <Box className="section text-left" >
            
            <Container>
            <div>
                <Tabs value={activeTab} onChange={handleTabChange} centered>
                    <Tab label="23-24" />
                    <Tab label="24-25" />
                </Tabs>

                <TabPanel value={activeTab} index={0}>
                    {showOfficerTables()}
                </TabPanel>

                <TabPanel value={activeTab} index={1}>
                    {showOfficerTables()}
                </TabPanel>
            </div>
                

            </Container>
            </Box>
            </LandingBlock>
            
          </MainPage>
        </>
  );
}

export default CommitteesPage;