
const LandingLink = ({link, text, br}) => {
    return (
        <>
        <a href={link} >{text}</a>
        {br && <br/>}
        </>
    );
};

export default LandingLink;
