import React from 'react';
import Typography from '@mui/material/Typography';
import LAccordionItem from './LAccordionItem';
import LandingContentItems from './LandingContentItems';

const LandingAccordionItem = ({ title, text, itemArr }) => {
    return (
        <LAccordionItem title={title}>
            {text && <Typography variant='h1'>{text}</Typography>}
            {itemArr && <LandingContentItems content={itemArr} />}
        </LAccordionItem>
    );
  };

export default LandingAccordionItem;
