import HOME1_4 from '../assets/content/business_council/business6.jpg';

export const getEvent1Landing = (isUkraine) => {
    return isUkraine ? defaultData_ukr : defaultData_eng;
};

export const getPhotoForLanding = (isUkraine) => {
    return imgArr;
};

const arrList1_ukr = [
    {"LandingListItem": "16.00 – 17.00 - для бажаючих пішохідна екскурсія історичним центром міста Черкаси (вартісь 150 грн. за попереднім замовленням)", url: ""},
    {"LandingListItem": "17.00 – 18.00 – відвідини музея єдиної книги Кобзаря Тараса Шевченка", url: ""},
    {"LandingListItem": "18.00 – 19.00 збір учасників та дружня вечеря в ресторані “Стумарі” на базі готелю “Optima Черкаси” (власні кошти)", url: ""},
    {"LandingListItem": "19.00 – 20.00 – міжнародна онлайн подія “Ukraine Culture&Spirit” (готель “Optima Черкаси”, малий конференц зал (гібридний формат онлайн та офлайн)", url: ""},
];

const arrList2_ukr = [
    {"LandingListItem": "9.00 - 10.30 - сніданок в готелі", url: ""},
    {"LandingListItem": "10.30 – 12.00 - Козацький пікнік біля Монастирського ставка Матроновського монастиря (Х-ХI сторіччя) на Чигиринщині, екскурсія в історико-культурному заповіднику в Холодному Яру під час патріотичного фестивалю до Дня українського козацтва та Св.Покрови, дегустація наливок, фотозони в етно-стилі (вартість участі 700 грн., в разі необхідності надати транспорт – одне місце в автобусі 350 грн.)", url: ""},
    {"LandingListItem": "14.00 – 15.00 - обідня перерва в музейно-етнографічному комплексі “Дикий Хутір” (власні витрати)", url: ""},
];

const defaultData_ukr = [
    {
        id: 0,
        header: 'Місце проведення:',
        img: 'https://optimahotels.com.ua/media/filer_public_thumbnails/filer_public/07/53/07537ff2-937d-4c41-bb37-59e714584f5a/vkhod_v_otel.jpg__1200x1200_q80_ALIAS-fancybox_subsampling-2.jpg',
        content: [
            {LandingMessage: "м. Черкаси, готель «Optima Черкаси» - вулиця Лазарєва, 6, малий конференц зал"},
            {LandingMessage: "Номери:  (1-місний номер зі сніданком 1575 грн., 2-місний – 1916 грн., знижка при бронюванні в системі Reikartz Hotel Group з 10% знижкою за кодом Rotary International - K$TFV%W@SP)"},
            {LandingLink: "Бронювання готелю на сайті", link: "https://optimahotels.com.ua/uk/hotels/cherkasy/"},
        ],
    },
    {
        id: 1,
        header: 'Програма - П’ятниця, 29 вересня 2023 р. ',
        arrcontent: [
            {
                img: 'https://res.cloudinary.com/tyko/image/upload/v1695369126/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/363762439_758208159638090_1422775922704319850_n_lxk3c9.jpg',
                content: [
                    {LandingMessage: "15.00-16.00 екскурсія в творчу майстерню «Telizhenko Fashion House» та учать в патріотичному флеш-мобі за Перемогу з рушником «Зеніт Українського Сонця»"},
                    {LandingLink: "Фейсбук сторінка", link: "https://www.facebook.com/tvorcha.maysternya.oleksandry.telizhenko"},
                ],
            },
            {
                img: 'https://optimahotels.com.ua/media/filer_public_thumbnails/filer_public/07/53/07537ff2-937d-4c41-bb37-59e714584f5a/vkhod_v_otel.jpg__1200x1200_q80_ALIAS-fancybox_subsampling-2.jpg',
                content: [
                    {LandingList: arrList1_ukr},
                ],
            }
        ],
    },
    {
        id: 2,
        header: 'Програма - Субота, 30 вересня 2023 р. ',
        arrcontent: [
            {
                img: 'https://optimahotels.com.ua/media/filer_public_thumbnails/filer_public/8a/bb/8abbda17-d41c-48b1-80ed-cb5e5515c7de/2016-01-26_16-45-20.jpg__1200x1200_q80_ALIAS-fancybox_subsampling-2.jpg',
                content: [
                    {LandingMessage: "Навчальний семінар з питань міжнародної діяльності Д2232 “Україна – вільна та єдина”"},
                    {LandingMessage: "Обов'язкові учасники Семінару: Голови Клубних комісій з міжнародного служіння, Голови Комітетів Д2232, робота яких пов’язана з міжнародною ротарійською співпрацею, та члени МДК. "},
                    {LandingMessage: "Рекомендована участь виключно офлайн: Президентів Ротарі Клубів, Асистентів Губернатора, Голів Дистриктних Комітетів, Ротарійців, зацікавлених в питаннях міжнародного служіння."},
                    {LandingMessage: "Онлайн формат – для ротарійців, які перебувають за кордоном."},
                    {LandingButtonLink: "Реєстрація учасників", link: "https://forms.gle/WMWTurcijYVXTYjPA"},
                ],            
            },
            {
                img: 'https://optimahotels.com.ua/media/filer_public_thumbnails/filer_public/8e/e8/8ee8b2c8-e21c-46ae-8bdc-d94233cab357/2016-01-26_16-36-32.jpg__1200x1200_q80_ALIAS-fancybox_subsampling-2.jpg',
                content: [
                    {LandingMessage: "Семінар буде транслюватися засобами інтерактивного Інтернет-зв’язку"},
                    {LandingMessage: "Мови семінару – англійська, українська (синхронний переклад протягом 30.09.2023 р. другої пленарної секції під час участі іноземних доповідачів)."},
                    {LandingMessage: "Посилання для підключення буде отримане на вашу електронну пошту, після реєстрації."},
                ],            
            },
            {
                img: 'https://res.cloudinary.com/tyko/image/upload/v1696917097/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/0-02-05-e406e08ccae1fc6af87dc8da96a787d2c64b1352a3c171ab11e9efc5fd348330_71a3d600a1c4faeb_avybai.jpg',
                content: [
                    {LandingMessage: "«Етновечір в музеї» (вартість участі 1500 грн.) – культурна подія з виставками, цікавими екскурсіями, традиційними українськими майстер класами, показом колекції моделей жіночих та чоловічих вишиванок від Творчої майстерні “Telizhenko Fashion House”"},
                    {LandingLink: "Facebook сторінка", link: "https://www.facebook.com/tvorcha.maysternya.oleksandry.telizhenko" },
                    {LandingMessage: "музичними класичними та українськими патріотичними номерами, вітаннями та подарунками, вечеря-фуршет, а також - благодійна акція зі збором коштів на виставці-продажі етно-виробів, що будуть надані українськими Ротарі клубами, з метою закупівлі турнікетів для військових частин ротарійців, які служать в лавах ЗСУ та ТРО"},
                    {LandingMessage: "Черкаський обласний художній музей, 3 поверх, центральна урочиста Біла зала, адреса: вул. Хрещатик, 259, урочиста Біла зала, адреса: вул. Хрещатик, 259, "},
                    {LandingLink: "Вебсайт", link: "https://muzey.ck.ua/" },
                ],            
            },
        ],
    },
    {
        id: 3,
        header: 'Програма - Неділя, 1 жовтня 2023 р.',
        img: 'https://res.cloudinary.com/tyko/image/upload/v1694631504/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/0-02-05-e4b07a4cf5c9af47af3d2076531a9724eef04acc6754111da9048deebebf1e99_b3b87c72b3390148_fihksu.jpg',
        content: [
            {LandingMessage: "Неділя, 1 жовтня 2023 р."},
            {LandingList: arrList2_ukr},
            {LandingLink: "Вебсайт “Дикий Хутір”", link: "http://hutirbuda.com/", variant: "contained" },
            {LandingMessage: "."},
            {LandingImage: "true", link: "https://res.cloudinary.com/tyko/image/upload/v1694632624/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/0-02-05-2e4d454aa1adedb416ef87eeb09a41aa0004392eb09fb2e937f06b6df33c6205_d73606122c452998_hmyfrh.jpg"},
        ],
    },
    {
        id: 4,
        header: 'Організатори:',
        
        cards: [
            {
                img: 'https://res.cloudinary.com/tyko/image/upload/v1694626154/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/0-02-05-40adf15051962d25039acf525d6a44dd1fb146ac8af89c7556d1d646b96621cf_74cba8599964860e_e3qacy.jpg',
                content: [
                    {LandingMessage: "Комітет міжнародного служіння Д2232"},
                    {LandingMessage: "Голова Комітету міжнародного служіння "},
                ],
                name: "Ольга Палійчук",
                phone: "+38050-52-073-52",
                email: "oncology@2upost.com",
            },
            {
                img: 'https://res.cloudinary.com/tyko/image/upload/v1694626167/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/0-02-05-9de172b55ffe1486ea1968bb57d2a3f2df927172a1cd6f4a1cae531c616fc489_39de34e4c6bed6d4_vgskaj.jpg',
                content: [
                    {LandingMessage: "Ротарі клуб Черкаси"},
                    {LandingMessage: "Президент РК Черкаси "},
                ],
                name: "Віктор Вовк",
                phone: "+38067-470-28-66",
                email: "viktor-vovk@ukr.net",
            },
            {
                img: 'https://res.cloudinary.com/tyko/image/upload/v1694626177/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/0-02-05-796684f5e977157fee78ed362be50808d511f93917bababd9b845842539a5f03_da1d03fe35a9da02_ntwteo.jpg',
                content: [
                    {LandingMessage: "Ротарі клуб Черкаси"},
                    {LandingMessage: "Скарбник РК Черкаси"},
                    {LandingButtonIcon: "картка Приват банку", copyText: "5457 0822 7285 5367", action: "copyToClipboard", desc: ""}
                ],
                name: "Валерій Бегменко",
                phone: "+38067-470-80-12",
                email: "v.begmenko@gmail.com",
            },
        ],
    },
]


const arrList1_eng = [
    {"LandingListItem": "16.00 – 17.00 - For those interested, a walking tour of the historical center of Cherkasy (cost 150 UAH. by prior arrangement)", url: ""},
    {"LandingListItem": '17.00 – 18.00 – Visit to the museum of the single book "Kobzar" by Taras Shevchenko', url: ""},
    {"LandingListItem": "18.00 – 19.00 Gathering of participants and friendly dinner at the “Stumari” restaurant based in the “Optima Cherkasy” hotel (own expenses)", url: ""},
    {"LandingListItem": "19.00 – 20.00 – International online event “Ukraine Culture&Spirit” (Optima Cherkasy hotel, small conference hall (hybrid format online and offline)", url: ""},
];

const arrList2_eng = [
    {"LandingListItem": "9.00 - 10.30 - Breakfast at the hotel", url: ""},
    {"LandingListItem": "10.30 – 12.00 - Cossack picnic near the Monastyrsky pond of Matronovsky monastery (X-XI centuries) in Chyhyrynshchyna, a tour in the historical and cultural reserve in Kholodny Yar during the patriotic festival for the Day of Ukrainian Cossacks and St. Pokrova, tasting of liqueurs, photo zones in ethno style (participation cost 700 UAH., if necessary to provide transport – one seat in the bus is 350 UAH.)", url: ""},
    {"LandingListItem": "14.00 – 15.00 - Lunch break at the “Wild Farmstead” museum-ethnographic complex (own expenses)", url: ""},
];

const defaultData_eng = [
    {
        id: 0,
        header: 'Venue:',
        img: 'https://optimahotels.com.ua/media/filer_public_thumbnails/filer_public/07/53/07537ff2-937d-4c41-bb37-59e714584f5a/vkhod_v_otel.jpg__1200x1200_q80_ALIAS-fancybox_subsampling-2.jpg',
        content: [
            {LandingMessage: "Cherkasy city, “Optima Cherkasy” hotel - Lazarieva Street, 6, small conference hall"},
            {LandingMessage: "Rooms: (single room with breakfast 1575 UAH., double room – 1916 UAH., discount when booking in the Reikartz Hotel Group system with a 10% discount using the Rotary International code - K$TFV%W@SP)"},
            {LandingButtonLink: "Hotel booking on the website", link: "https://optimahotels.com.ua/uk/hotels/cherkasy/"},
        ],
    },
    {
        id: 1,
        header: 'Program - Friday, September 29, 2023',
        arrcontent: [
            {
                img: 'https://res.cloudinary.com/tyko/image/upload/v1695404105/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/Untitled-1_zoy0j6.jpg',
                content: [
                    {LandingMessage: "15.00-16.00 екскурсія в творчу майстерню «Telizhenko Fashion House» та учать в патріотичному флеш-мобі за Перемогу з рушником «Зеніт Українського Сонця»"},
                    {LandingLink: "Фейсбук сторінка", link: "https://www.facebook.com/tvorcha.maysternya.oleksandry.telizhenko"},
                ],
            },
            {
                img: 'https://optimahotels.com.ua/media/filer_public_thumbnails/filer_public/07/53/07537ff2-937d-4c41-bb37-59e714584f5a/vkhod_v_otel.jpg__1200x1200_q80_ALIAS-fancybox_subsampling-2.jpg',
                content: [
                    {LandingList: arrList1_eng},
                ],
            }
        ],
    },
    {
        id: 2,
        header: 'Program - Saturday, September 30, 2023',
        arrcontent: [
            {
                img: 'https://optimahotels.com.ua/media/filer_public_thumbnails/filer_public/8a/bb/8abbda17-d41c-48b1-80ed-cb5e5515c7de/2016-01-26_16-45-20.jpg__1200x1200_q80_ALIAS-fancybox_subsampling-2.jpg',
                content: [
                    {LandingMessage: "Educational seminar on international activities D2232 “Ukraine - free and united”"},
                    {LandingMessage: "Mandatory seminar participants: Heads of Club Commissions for International Service, Heads of D2232 Committees, whose work is related to international rotary cooperation, and members of the MDC."},
                    {LandingMessage: "Recommended offline-only participation: Rotary Club Presidents, Governor's Assistants, District Committee Heads, Rotarians interested in international service issues."},
                    {LandingMessage: "Online format – for Rotarians abroad."},
                    {LandingButtonLink: "Participant registration", link: "https://forms.gle/WMWTurcijYVXTYjPA"},
                ],            
            },
            {
                img: 'https://optimahotels.com.ua/media/filer_public_thumbnails/filer_public/8e/e8/8ee8b2c8-e21c-46ae-8bdc-d94233cab357/2016-01-26_16-36-32.jpg__1200x1200_q80_ALIAS-fancybox_subsampling-2.jpg',
                content: [
                    {LandingMessage: "The seminar will be broadcasted using interactive Internet communication"},
                    {LandingMessage: "Seminar languages - English, Ukrainian (synchronous translation during 30.09.2023 of the second plenary session during the participation of foreign reporters)."},
                    {LandingMessage: "The connection link will be sent to your email after registration."},
                ],            
            },
            {
                img: 'https://res.cloudinary.com/tyko/image/upload/v1696917097/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/0-02-05-e406e08ccae1fc6af87dc8da96a787d2c64b1352a3c171ab11e9efc5fd348330_71a3d600a1c4faeb_avybai.jpg',
                content: [
                    {LandingMessage: "“Ethno-evening in the museum” (participation cost 1500 UAH) - a cultural event with exhibitions, interesting tours, traditional Ukrainian master classes, showing a collection of models of women's and men's embroideries from the Creative workshop “Telizhenko Fashion House”"},
                    {LandingLink: "Facebook page", link: "https://www.facebook.com/tvorcha.maysternya.oleksandry.telizhenko" },
                    {LandingMessage: "with musical classical and Ukrainian patriotic performances, greetings and gifts, dinner-buffet, as well as - a charity event with fundraising at the ethno-products exhibition-sale, which will be provided by Ukrainian Rotary clubs, with the aim of purchasing tourniquets for military units of Rotarians, who serve in the ranks of the Armed Forces and TRW"},
                    {LandingMessage: "Cherkasy Regional Art Museum, 3rd floor, central ceremonial White hall, address: Khreshchatyk St, 259, ceremonial White hall, address: Khreshchatyk St, 259,"},
                    {LandingLink: "Website", link: "https://muzey.ck.ua/" },
                ],            
            },
            {
                id: 3,
                header: 'Program - Sunday, October 1, 2023',
                img: 'https://res.cloudinary.com/tyko/image/upload/v1694631504/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/0-02-05-e4b07a4cf5c9af47af3d2076531a9724eef04acc6754111da9048deebebf1e99_b3b87c72b3390148_fihksu.jpg',
                content: [
                    {LandingMessage: "Sunday, October 1, 2023"},
                    {LandingList: arrList2_eng},
                    {LandingLink: "“Wild Farmstead” Website", link: "http://hutirbuda.com/", variant: "contained" },
                    {LandingMessage: "."},
                    {LandingImage: "true", link: "https://res.cloudinary.com/tyko/image/upload/v1694632624/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/0-02-05-2e4d454aa1adedb416ef87eeb09a41aa0004392eb09fb2e937f06b6df33c6205_d73606122c452998_hmyfrh.jpg"},
                ],
            },
        ]
    },
    {
        id: 4,
        header: 'Organizers:',
        
        cards: [
            {
                img: 'https://res.cloudinary.com/tyko/image/upload/v1694626154/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/0-02-05-40adf15051962d25039acf525d6a44dd1fb146ac8af89c7556d1d646b96621cf_74cba8599964860e_e3qacy.jpg',
                content: [
                    {LandingMessage: "International Service Committee D2232"},
                    {LandingMessage: "Head of the International Service Committee "},
                ],
                name: "Olha Paliichuk",
                phone: "+38050-52-073-52",
                email: "oncology@2upost.com",
            },
            {
                img: 'https://res.cloudinary.com/tyko/image/upload/v1694626167/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/0-02-05-9de172b55ffe1486ea1968bb57d2a3f2df927172a1cd6f4a1cae531c616fc489_39de34e4c6bed6d4_vgskaj.jpg',
                content: [
                    {LandingMessage: "Rotary Club Cherkasy"},
                    {LandingMessage: "President of RC Cherkasy"},
                ],
                name: "Viktor Vovk",
                phone: "+38067-470-28-66",
                email: "viktor-vovk@ukr.net",
            },
            {
                img: 'https://res.cloudinary.com/tyko/image/upload/v1694626177/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F/0-02-05-796684f5e977157fee78ed362be50808d511f93917bababd9b845842539a5f03_da1d03fe35a9da02_ntwteo.jpg',
                content: [
                    {LandingMessage: "Rotary Club Cherkasy"},
                    {LandingMessage: "Treasurer of RC Cherkasy"},
                    {LandingMessage: "Privat bank card 5457 0822 7285 5367"},
                ],
                name: "Valerii Begmenko",
                phone: "+38067-470-80-12",
                email: "v.begmenko@gmail.com",
            },
            
        ],
    },
    
]

const imgArr = [
    "https://res.cloudinary.com/tyko/image/upload/v1696917322/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-d8697827a460c7f9808a7b603fd4ce587f19095f07f4fa7fe69b9475a2e37de6_6e78c3c21f7c493d_jmdmje.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917326/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-358a3741f979a3abcf2c1c5b3dcfce3c5cead6e0a1d6b43eb7023c85a906dc19_b0ea5031fc3a30ff_bchfzn.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917337/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-77b59002422d5236384d402c48b41d39b184a2f28dbdaebd49dbe98565ebbe8b_e7e04b70aa4886c9_ob7hk7.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917341/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-5139a586a85a2f44f97dc1e70731ca2c0479d3bb60f307efb3bb8d6d83193606_2b4d64f05e12e8f7_xzk39z.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917345/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-815c972981507b4a2f3e5602a6b74cfe8230c7f841ff9e99acebca2272e9befb_82e6133bb311d5df_zaj5oc.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917349/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-850eeab5e2a4c1da61b51deefc9f32a76b7ce795f05fc700e098a83226deecc4_4835bb2acab801ff_zecxrj.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917354/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-352f88e21f7bfa59428c58618a826fbae5f411a0ff5e0d256f5b0ae9c0b59807_f707cc6f1ee6e9da_fwwo4f.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917357/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-6d597b1e9d8e86f62ab3d84df3aa92a334a1ff0419331e6168b0b1b4bf5e9888_a86951ec288261fd_hk3sgd.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917359/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-6b58ca47828da9c256ef7da9e24a335893090da0a8a03e16abbfe6ab02f7a7bd_30609a331a3834c4_jdjh7r.jpg",
    
    "https://res.cloudinary.com/tyko/image/upload/v1696917361/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-95c5dc50a54eb256fb2a33827a7fd91484f79d7af7487c54e788c181f486cc77_14d0c7f03e6f5f3d_qo9dnh.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917363/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-55f5f1a9eadf636a3348175074b3a4909f3fe66e8b12ab902c2ce86f40593930_10c8dd83e6cdac65_wdjb62.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917366/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-f29e68fcbb921f60c301839a834216a678e47e81c3374ecc6b8192e76a4af8be_b92747f1f6874e5c_gmt6wu.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917369/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-5eea248ca037a1dfb2b1e9ebf6a9cb1ce167629161a197365ff3d2ea0807e349_be717965f601f504_jkcyqp.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917371/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-e2c906d2ac4a91b7cbd0b002b83a420af52bfa65f0d748c89eed5d03f9cf9db1_c27851ed00aa9da_mdgwbe.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917386/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-86e6a91a76b93cc97f8919c558f82a857ec001050e73f45cc81d3133ec5c5b0d_e3aad3debd4377d2_wbbypr.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917394/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-34e8b54713f5316dd8bedc8791c85c05c7ee68234b47261447cb270280900048_8136fea3bd6c8d43_honmhr.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917397/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-e0618fed339861d42f7d4c785bbeb86c05058ddcc7684bc42e5af65018c9a763_1c76696c0c62ec19_vd1lqz.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917399/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-2698f0636a63e341fa2a5ff977736b7da8ec18bf8b50b82fc9047b065e5b11da_ae504763bb2fbd56_deicry.jpg",

    "https://res.cloudinary.com/tyko/image/upload/v1696917401/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-18e877e287206a6d88cf3d9a9d3036fdacb45b0b52d952954b3b68e9e5efaa53_5747ae7d3fd7400d_nq03hz.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917403/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-9d07d2ab2f70cfdb2127f9b381dad23d913a7527d1de8d6b3a885bb81d6f809c_639870a95f2066c6_orkjmf.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917406/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-8f34954710d61164a9d537bf5d53e9112f3c122d91d8b8e5be5acef4b016bcc5_bdb940485dc474e5_achdju.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917408/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-e1ffa1f3abe8fc301ce0edc02a11105c75d958e1e5cee4bfd34919b6dd746b14_45917aa41587d5c3_wouxpc.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917410/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-53ec3e92321ccc4702c5906b2203829eb38e75b5c1de4dc6570a8c52a4230ba9_9a3a6c6a31ebf8fa_b36esg.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917412/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-4cf50739ab300d4deb6d8c6dfc5a543bf89fbe2c2d755756c787e95bc5b9240b_ca18cfa4d4346bed_bwf9ok.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917414/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-4b1d30eb3aed62f24318bce2f4733f511ef6fab5c6fe76639c15663121bd7e9f_d8dbd495a1ede87b_fmfetn.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917415/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-e47e7f0529da03ea4ed89eebd8e3d413cd863c4dd016b37d4dbad66205a08597_54e1d631a24e48e2_i4xzq0.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917418/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-097236955b49e9c855fa06c2699867477c0ba64a98e18791fb9991966da9cff1_a2e3eadd4b0234bc_su0qoo.jpg",

    "https://res.cloudinary.com/tyko/image/upload/v1696917421/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-4edc41c59eda7d325b5958c0e7e77999b8127bcb3e650a0e03242338c81bc9ca_eba035368849e78b_vckwmu.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917423/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-f2029b59f93dc968bf3e5f19dd8334f74373d5212764c4a12d07f9ef3eeed76e_5838a142f34fc6df_yy5br1.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1696917427/BetaRotarySite/%D0%9A%D0%BE%D0%BC%D1%96%D1%82%D0%B5%D1%82%20%D0%9C%D1%96%D0%B6%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%A1%D0%BB%D1%83%D0%B6%D1%96%D0%BD%D0%BD%D1%8F%20-%202/%D1%84%D0%BE%D1%82%D0%BE/0-02-05-bbb656cd613fc55f2eb8f84385bc64a7d239f24abb7fa863d429646910f13ddf_9ecd95f54af8ae17_t6yjma.jpg",

]