import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Container, Typography } from '@mui/material';
import TextFieldFile from '../../components/TextFieldFile';

const defaultItem = (nextId) => {
  return {
    data: '...',
    number: `Комюніке №${nextId}`,
    title: `Заголовок Комюніке`,
    url: 'https://rotary.org.ua/assets/site/documents/TPzULYST2K.pdf',
    urls: [
      ///{ title: 'Назва додаткового файлу', url: 'https://drive.google.com/file/d/1Bfx8ztWDmhvt09lk590LfswHvshFIDpL/view?usp=drive_link' },
    ]
  }
};


const DocumentsPageEditor = ({item, isAdded, nextId, onCancel, onSubmit}) => {
  const [structure, setStructure] = useState(item && !isAdded ? item : defaultItem(nextId));
  const [isEdited, setIsEdited] = useState(false);

  const setStructureFunc = (structure) => {
    setStructure(structure);
    setIsEdited(true);
  };

  const handleAddUrl = () => {
    const newUrl = { title: '', url: '' };
    setStructure((prevStructure) => ({
      ...prevStructure,
      urls: prevStructure.urls ? [...prevStructure.urls, newUrl] : [newUrl]
    }));
  };

  const handleDeleteUrl = (index) => {
    setStructure((prevStructure) => {
      const updatedUrls = [...prevStructure.urls];
      updatedUrls.splice(index, 1);
      return {
        ...prevStructure,
        urls: updatedUrls
      };
    });
  };

  const handleUrlChange = (index, field, value) => {
    setStructure((prevStructure) => {
      const updatedUrls = [...prevStructure.urls];
      updatedUrls[index][field] = value;
      return {
        ...prevStructure,
        urls: updatedUrls
      };
    });
  };

  const handleSubmit = () => {
    onSubmit(structure);
  };
  const handleCancel = () => {
    onCancel();
  };

  const renderUrls = () => {
    return structure && structure.urls && structure.urls.length > 0 && structure.urls.map((url, index) => (
      <Box key={index} sx={{ display: 'flex', gap: '16px', alignItems: 'center', marginBottom: '8px' }}>
        <TextField
          label="Інші файли"
          value={url.title}
          onChange={(e) => handleUrlChange(index, 'title', e.target.value)}
          fullWidth
        />
        <TextField
          label="URL"
          value={url.url}
          onChange={(e) => handleUrlChange(index, 'url', e.target.value)}
          fullWidth
        />
        <Button variant="contained" onClick={() => handleDeleteUrl(index)}>Видалити</Button>
      </Box>
    ));
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
    <Container
        sx={{
          backgroundColor: 'white',
          padding: '16px',
          borderRadius: '8px',
        }}>
        <Button variant="contained" disabled={!isEdited} onClick={handleSubmit} sx={{ marginBottom: '16px' }}>Застосувати</Button>
        <Button variant="contained" onClick={handleCancel} sx={{ marginBottom: '16px' }}>Відмінити</Button>

        <Typography variant='h4'>{isAdded?"Додати Комюніке":"Редагувати Комюніке"}</Typography>
      
      <TextField
        label="Номер"
        value={structure.number}
        onChange={(e) => setStructureFunc({ ...structure, number: e.target.value })}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Заголовок"
        value={structure.title}
        onChange={(e) => setStructureFunc({ ...structure, title: e.target.value })}
        fullWidth
        margin="normal"
      />
      <TextField
        label="URL файлу комуніке"
        value={structure.url}
        onChange={(e) => setStructureFunc({ ...structure, url: e.target.value })}
        fullWidth
        margin="normal"
      />

      <TextFieldFile
        label="URL файлу комуніке"
        value={structure.url}
        onChange={(newValue) => setStructure({ ...structure, url: newValue })}
      />
        
      <Button variant="contained" onClick={handleAddUrl} sx={{ marginBottom: '16px' }}>Додати інший файл</Button>
      {renderUrls()}
    </Container>
    </Box>
  );
};


export default DocumentsPageEditor;
