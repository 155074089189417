import { Table, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material';
import { Dialog, TextField, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const TableComponent = ({communiques, communiqueTableTitles, setSelectedCommunique, handleDelete, handleSelectUrls, hasChildren}) => {
    return (
        <>
        {
            //JSON.stringify(communiques)
        }
        {
            //JSON.stringify(communiqueTableTitles)
        }
        <Table>
            <TableHead>
                <TableRow>
                    {communiqueTableTitles && Object.values(communiqueTableTitles).map((item) => (
                        <TableCell key={item}>{item}</TableCell>
                    ))}
                    <TableCell key={-1}>buttons</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {communiques && communiques.map(communique => (
                    <TableRow key={communique.id}>
                        {communiqueTableTitles && Object.keys(communiqueTableTitles).map((item) => (
                            <TableCell 
                                key={item}
                            >
                                {communique[item] && communique[item].includes('http') ? 
                                    <a href={communique[item]} >link</a> : 
                                    communique[item]
                                }
                            </TableCell>
                        ))}
                        <TableCell>
                            <Button variant="outlined" color="primary" onClick={() => setSelectedCommunique(communique)}>Edit</Button>
                            <Button variant="outlined" color="error" onClick={() => handleDelete(communique.id)}>Delete</Button>
                            {hasChildren && <Button variant="outlined" color="error" onClick={() => handleSelectUrls(communique)}>
                                Urls
                            </Button>}
                            {communique.urls && communique.urls.length>0 && "("+communique.urls.length+")"}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        </>
    )
}

export default TableComponent;