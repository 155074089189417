import {fetchSitePage} from '../../../../api/api_sitecreator_rc_eclub';
export const getPagesContent = async (local) => {
    return data_eng;
    //return await fetchSitePage(1);
}

const data_eng = [
    {
        id: 0,
        _header: 'Photo collage',
        arrcontent: [
            {
                PhotoCarusel_15x10: [
                    'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage/колаж 1.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage/колаж 2.jpeg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage/колаж 3.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage/колаж 4.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage/колаж 5.jpeg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage/колаж 6.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage/колаж 7.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage/колаж 8.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage/колаж 9.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage/колаж 10.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage/колаж 11.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage/колаж 12.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage/колаж 13.jpg',
                ],
            },
        ],
    },
    {
        id: 1,
        img: "https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/first_image.jpg",
        header: 'Welcome to the Rotary E-Club of Ukraine!',
        content: [
            {LandingMessage: `We are Ukraine's first fully electronic Rotary club, founded in 2015 by Rotarians from temporarily occupied Crimea and Donbas. Despite all the challenges, we conduct impressive projects nationwide, uniting Ukrainians and supporters worldwide. Join us in promoting goodwill and humanitarian efforts locally and globally. Welcome to our Rotary family!`},
        ],
    },
    {
        id: 2,
        header: 'Numbers',
        LandingStatisticsBlock: [
            {LandingStistcsItem: 'type1', number: `650`, line2: `thousand dollars`, text: `Over the past two years, we have implemented projects worth more than USD 650,000` },
            {LandingStistcsItem: 'type2', number: `21`, line2: `Club members`, text: `We are proud of each of our 21 regular and honorary members` },
            {LandingStistcsItem: 'type3', number: `100+`, line2: `Partners`, text: `Over the past 2 years, more than a hundred clubs, districts and volunteers have joined the implementation of our projects` },
        ],
    },
    {
        id: 3,
        header: 'Magic of Rotary in Ukraine',
        LandingAreaOfClubFocusBlock: [
            {
                LandingMessage: `Mental Health`, 
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage2/mental health.jpg',
                url: '/rc_eclubofukraine/projects?filter&carea=1',
            },
            {
                LandingMessage: `Internally displaced people`, 
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage2/IDP.jpg',
                url: '/rc_eclubofukraine/projects?filter&carea=2',
            },
            {
                LandingMessage: `Demining`, 
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage2/demining.jpg',
                url: '/rc_eclubofukraine/projects?filter&carea=3',
            },
            {
                LandingMessage: `Medical`, 
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage2/Medical.jpg',
                url: '/rc_eclubofukraine/projects?filter&carea=4',
            },
            {
                LandingMessage: `Community development`, 
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage2/comunity devel.jpg',
                url: '/rc_eclubofukraine/projects?filter&carea=5',
            },
            {
                LandingMessage: `Humanitarian`, 
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage2/humanit.jpg',
                url: '/rc_eclubofukraine/projects?filter&carea=6',
            },
            {
                LandingMessage: `Children and Education`, 
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage2/children edu.jpg',
                url: '/rc_eclubofukraine/projects?filter&carea=7',
            },
            {
                LandingMessage: `Others`, 
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/photocolage2/others.jpg',
                url: '/rc_eclubofukraine/projects?filter&carea=8',
            },
        ]
    }
];
