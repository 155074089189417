
import { Button } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import IMG_PNG_1 from '../../../assets/img/icons/pdf_icon.png';

const LcButtonPdf = ({url,size}) => {

    return (
        <Tooltip title={'PDF file'}>
        <Button onClick={() => window.open(url, '_blank')}>
            <img src={IMG_PNG_1} alt="Add" height='30px' className="icon-image" />
        </Button>
        </Tooltip>
    );
}

export default LcButtonPdf;