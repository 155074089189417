import React, { useState } from "react";
import { Box, Typography, Container } from '@mui/material';
import LAccordionItem from '../../components/LAccordionItem';
import LandingItemSelector from './LandingItemSelector';

const LandingContentShema = ({ data, handlerSelectItem }) => {
    const showLandingBlocks = (landItem) => (
        <Box style={{border: '1px solid yellow', padding: '4px', margin: '4px'}}>
        {landItem && landItem.map((item, index) => (
            <LAccordionItem 
                key={index} 
                title={'lending id='+item.id}
                defaultExpanded={false}
            >
            {showContentProps(item,index)}
            </LAccordionItem>
        ))}
        </Box>
    )
    const showContentBlock = (landItem) => (
        <>
        {(landItem.content || landItem.arrcontent || landItem.cards) && 
            <LAccordionItem 
                    title='arr'
                    defaultExpanded={false}
                >
                {landItem && landItem.content && showContentItem(landItem.content)}
                {landItem && landItem.cards && <>landItem.cards</>}
                {landItem && landItem.arrcontent && showContentItem(landItem.arrcontent)}
    
            </LAccordionItem>
        }
        </>
    )
    const showContentProps = (item, index) => (
        <Box key={index} style={{border: '1px solid green', padding: '4px', margin: '4px'}}>
            {Object.keys(item).map((keyName, keyIndex) => (
                <p key={keyIndex}>{keyName}
                
                {keyName=='id' && <># id={item.id}</>}
                {keyName=='img' && <># url=...</>}
                {keyName=='header' && <># url=...</>}
                {keyName=='LandingMessage' && <LandingItemSelector clickHandler={handlerSelectItem}># text=...</LandingItemSelector>}
                {keyName=='LandingList' && <># list=...</>}
                {keyName=='tablecontent' && <># table=...</>}
    
                </p>
            ))}
            {showContentBlock(item)}
        </Box>
    )
    
    const showContentItem = (landItem) => (
        <Box style={{border: '1px solid yellow', padding: '4px', margin: '4px'}}>
        {landItem && landItem.map((item, index) => (
            <>
            {showContentProps(item,index)}
            </>
        ))}
        </Box>
    )


    return (
        <Box height='100%'>
            <div 
            >
            {data && showLandingBlocks(data)}
            </div>

            
        </Box>
    );
    
};

export default LandingContentShema;
