import { LOCALES } from './locales'

export const messages = {
    [LOCALES.UKRAINIAN]: {
        menuTitle: 'Ротарі в Україні',
        menuHome: 'Головна',
        menuAboutRI: 'Ротарі Інтернешнл',
        menuAboutD2232: 'Дистрикт 2232',
        menuCommittees: 'Комітети',
        menuProjects: 'Проєкти',
        menuEvents: 'Події',
        menuNews: 'Новини на Фейсбук',
        menuDonate: 'Донати',

        home_h1: 'Хто ми',
        home_c1: "Ротарі - це міжнародна добровільна клубна організація, що об'єднує 1,4 мільйона членів - лідерів, що вирішують різноманітні проблеми і називаються Ротарійцями – які об'єднують людей та діють, створюючи тривалі позитивні зміни. Ми щодня вкладаємо свою пристрасть, доброчесність та розум у проєкти, що матимуть тривалу дію.",
        home_h2: 'Наші основні сфери уваги',
        home_c2: "Члени Ротарі вірять в спільну відповідальність в необхідності діяти щодо вирішення найважливіших проблем у світі. Понад 46 000 наших клубів об'єднуються для спільної роботи з такими напрямками:",
        home_h3: 'Наша місія',
        home_c3: "Ми надаємо послуги іншим, заохочуємо доброчесність і сприяємо світовому взаєморозумінню, доброзичливості та миру через наше товариство ділових, професійних і громадських лідерів».",
        home_h4: 'Наша візія',
        home_c4: `РАЗОМ ми бачимо світ, в якому ЛЮДИ об'єднуються і діють, щоб СТВОРЮВАТИ тривалі ЗМІНИ у всьому світі, в наших громадах та в нас самих. У стратегії розвитку до червня 2026 року в українському суспільстві ми бачимо Ротарі одним з ключових партнерів в подоланні гуманітарної кризи та відбудови країни; плануємо, що Дистрикт 2232 налічуватиме понад 2500 членів у Ротарі та Ротаракт Клубах різних типів, що активно працюють у всіх Ротарійських напрямках служіння. 
                    В більш як 60% клубів активно діє програма з Менторства, яка дозволяє утримувати нових членів. 
                    Дистрикт продовжуватиме підготовку до повного інкорпорування. 
                    Бізнес середовище Ротарійців здійснюватиме заходи професійної орієнтації молоді та стажування в компаніях та організаціях Ротарійців. 
                    Завдяки діяльності з публічного іміджу бренд Ротарі стане ще більш пізнаваним державою, серед лідерів бізнесу та громадянського суспільства. 
                    Дистрикт регулярно реалізуватиме молодіжні та дружні обміни, програми RYLA, дитячі табори. 
                    Ротарійці та Ротарактівці продовжують приймати активну участь в багатьох Міждержавних комітетах та Ротарійських об‘єднаннях за інтересами, що забезпечує реалізацію вагомих міжнародних проектів.`,
        home_h5: "Історія Ротарі",
        home_c5: `Ротарі почався з бачення одного чоловіка — Пола Гарріса. Чиказький адвокат створив Ротарі Клуб Чикаго 23 лютого 1905 року, щоб професіонали з різноманітними досвідом могли обмінюватись ідеями та укладати значущі та довготривалі дружби. З плином часу, діяльність Ротарі поширилась і її бачення поступово розширилося на гуманітарну службу. Члени організації мають довгий шлях вирішення проблем у своїх спільнотах та по всьому світу.
                    
                    23 лютого 1905 року 37-річний юрист з Чикаго обідав зі своїм близьким другом, Сільвестром Шіле, вугільним дилером. Після обіду вони пішли прогулятись до Unity, офісного центру за кілька вулиць від того місця. Вони піднялись в кімнату 711, офіс Густавуса Лоєра, гірського інженера. Там був також Гірам Шорей, торговець тканинами.
                    Вчотирьох вони обговорювали ідею Пола Харріса заснувати клуб бізнесменів та лідерів у різних професіях. Вони виробили кілька простих правил. Члени клубу повинні мати різні профілі, розробляти цікаві і корисні проекти для суспільства, і кожен член клубу по черзі буде приймати засідання членів клубу в себе вдома чи в офісі. Основною ж метою цієї ініціативи був обмін ідеями та розробка системи членства. Всі вони підтримали саму ідею і запланували наступну зустріч на 9 березня в офісі Сільвестра Шіле, куди потрібно було запросити трьох нових друзів і прийняти їх до клубу.
                    Сьогодні ми не можемо сказати, що знаємо реальне пояснення успіху ідеї Пола Харріса та ініціативи чотирьох перших засновників. Кількість Ротарі клубів почала зростати в геометричній прогресії в численних містечках по всій Америці. В 1910 була створена національна спілка клубів – Національна Асоціація Ротарі Клубів, яка згодом була перейменована в Ротарі Інтернешнл. В 1912 перші клуби почали з’являтись поза межами США, в Канаді та Великій Британії.
                    В 1925 у світі нараховувалось вже більше 2000 клубів.`,
        home_h6: "Інформація про клуби",
        home_c6: "Зараз в Дистрикті 2232 маємо 75 Ротарі клубів в більшості міст України.",
        home_h7: "Найактуальніші новини",
        home_c7: "...",
        home_h8: "Термінові потреби зараз",
        home_c8: "...",
        home_h9: "Допомогти",
        home_c9: "кнопка донату",
        home_h10: "Стати членом клубу",
        home_h11: "Міжнародні зв'язки",
        home_directions1: "Сприяння миру та розв'язання конфліктів",
        home_directions2: "Запобігання та лікування хвороб",
        home_directions3: "Забезпечення чистою водою, санітарією та гігієною",
        home_directions4: "Здоров'я матері та дитини",
        home_directions5: "Підтримання базової освіти та грамотності",
        home_directions6: "Розвиток місцевої економіки",
        home_directions7: "Навколишнє середовище",

        //buttons
        btn_more: "Розгорнути...",
        btn_less: "Згорнути...",
        btn_details: "Детальніше...",
        btn_details_ri: "Більше інформації на РІ...",

        //========= авто переклад
        'Програма заходів Дистрикту 2232': `Програма заходів Дистрикту 2232`,
        'на 29 вересня – 1 жовтня, 2023 р., м. Черкаси': `на 29 вересня – 1 жовтня, 2023 р., м. Черкаси`,
        'Реєстрація учасників': `Реєстрація учасників`,
        'Ротарі в Україні': `Ротарі в Україні`,
        'Про Комітет': `Про Комітет`,
        'Події': `Події`,
        'Семінар Комітету міжнародного служіння': `Семінар Комітету міжнародного служіння`,
        'Зміст скопійовано в буфер обміну': 'Зміст скопійовано в буфер обміну',
        "Стати членом Ділової Ради": "Стати членом Ділової Ради",
        'Презентації': 'Презентації',
        'Фотогалерея': 'Фотогалерея',
        'РОТАРІЙСЬКИЙ ДІЛОВИЙ ФОРУМ ІННОВАЦІЙ, СТАРТАПІВ ТА МОЖЛИВОСТЕЙ': 'РОТАРІЙСЬКИЙ ДІЛОВИЙ ФОРУМ ІННОВАЦІЙ, СТАРТАПІВ ТА МОЖЛИВОСТЕЙ',
        '28 жовтня 2023 року, м. Львів': '28 жовтня 2023 року, м. Львів',
        'Особиста сторінка комітету': 'Особиста сторінка комітету',
        'Ділова Рада Ротарі D2232': 'Ділова Рада Ротарі D2232',
    },
    [LOCALES.ENGLISH]: {
        menuTitle: `Rotary in Ukraine`,
        menuHome: 'Home',
        menuAboutRI: 'About RI',
        menuAboutD2232: 'About RD2232',
        menuCommittees: 'Committees',
        menuProjects: 'Projects',
        menuEvents: 'Events',
        menuNews: 'News',
        menuDonate: 'Donate',
        
        home_h1: 'Who We Are',
        home_c1: 'Rotary is a global network of 1.4 million neighbors, friends, leaders, and problem-solvers who see a world where people unite and take action to create lasting change – across the globe, in our communities, and in ourselves.',
        home_h2: 'What we do',
        home_c2: 'Rotary members believe that we have a shared responsibility to take action on our world’s most persistent issues. Our 46,000+ clubs work together to:',
        home_h3: 'Our mission',
        home_c3: 'We provide service to others, promote integrity, and advance world understanding, goodwill, and peace through our fellowship of business, professional, and community leaders.',
        home_h4: 'Vision statement',
        home_c4: 'Together, we see a world where people unite and take action to create lasting change — across the globe, in our communities, and in ourselves.',
        home_h5: "History",
        home_c5: "Rotary started with the vision of one man — Paul Harris. The Chicago attorney formed the Rotary Club of Chicago on 23 February 1905, so professionals with diverse backgrounds could exchange ideas and form meaningful, lifelong friendships. Over time, Rotary’s reach and vision gradually extended to humanitarian service. Members have a long track record of addressing challenges in their communities and around the world.",
        home_h6: "Clubs information",
        home_c6: "Now in District 2232 we have 70 clubs",
        home_h7: "The most relevant news",
        home_c7: "",
        home_h8: "Urgent needs now",
        home_c8: "",
        home_h9: "Help",
        home_c9: "",
        home_h10: "Become a member of the club",
        home_h11: "International relations",
        home_directions1: "Promote peace",
        home_directions2: "Fight disease",
        home_directions3: "Provide clean water, sanitation, and hygiene",
        home_directions4: "Save mothers and children",
        home_directions5: "Support education",
        home_directions6: "Grow local economies",
        home_directions7: "Protect the environment",

        //buttons
        btn_more: "Expand...",
        btn_less: "Collapse....",
        btn_details: "Детальніше...",
        btn_details_ri: "Більше інформації на РІ...",

        //========= Auto translate
        'Програма заходів Дистрикту 2232': `Program of events of District 2232`,
        'на 29 вересня – 1 жовтня, 2023 р., м. Черкаси': `For September 29 - October 1, 2023, Cherkasy city`,
        'Реєстрація учасників': `Participant registration`,
        'Ротарі в Україні': `Rotary in Ukraine`,
        'Про Комітет': `About the Committee`,
        'Події': `Events`,
        'Семінар Комітету міжнародного служіння': `International Service Committee Seminar`,
        'Зміст скопійовано в буфер обміну': 'Content copied to clipboard',
        "Стати членом Ділової Ради": "Become a member of the Business Council",
        'Презентації': 'Presentations',
        'Фотогалерея': 'Photo Gallery',
        'РОТАРІЙСЬКИЙ ДІЛОВИЙ ФОРУМ ІННОВАЦІЙ, СТАРТАПІВ ТА МОЖЛИВОСТЕЙ': 'ROTARY BUSINESS FORUM OF INNOVATIONS, STARTUPS, AND OPPORTUNITIES',
        '28 жовтня 2023 року, м. Львів': 'October 28, 2023, Lviv',
        'Особиста сторінка комітету': "Committee's Personal Page",
        'Ділова Рада Ротарі D2232': 'Business Council Rotary D2232',
    },
    
}
