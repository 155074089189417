
import {IconButton} from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';

const LandingLinkIcon = ({link, text, br}) => {
    return (
        <>
        
        <IconButton aria-label="Open WEB" color="primary"  
            onClick={() => window.open(link, '_blank')}>
        <PublicIcon fontSize="large" style={{ marginRight: '4px' }} />
        
        <div style={{textAlign: 'left', verticalAlign: 'top', display: 'inlineBlock', whiteSpace: 'preWrap"'}}>
        {text}
        </div>
        </IconButton>

        {/* <a href={link} >{text}</a>
        {br && <br/>} */}
        </>
    );
};

export default LandingLinkIcon;
