
import { LOCALES } from '../../../i18n/locales';

export const getCherkasyProjects2 = (local) => {
    //const cherkasyData = local === LOCALES.UKRAINIAN ? cherkasyData_ukr : cherkasyData_eng;
    return cherkasyData;
};

const cherkasyData = [
    {
        id: 0,
        header: 'Проєкти Ротарі Клубу Черкаси',
        content: [
            {LandingMessage: `Переглядайте або шукайте проекти за допомогою одного або кількох фільтрів у списку.`},
        ],
    },
    {
        id: 1,
        LandingProjects2: `rc_cherkasy`,
    }
];

  