import axios from 'axios';

//const BASE_URL = 'http://localhost:3001/apibd';
const BASE_URL = 'https://api.rotary.org.ua/apibd';

export const fetchProjects = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/projects`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchProject = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/project/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createProject = async (projectData) => {
    console.log('=== createProject...');
    try {
        const response = await axios.post(`${BASE_URL}/projects`, projectData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateProject = async (projectId, projectData) => {
    console.log('=== updateProject...');
    try {
        console.log('=== updateProject url'+`${BASE_URL}/projects/${projectId}`);
        const response = await axios.put(`${BASE_URL}/projects/${projectId}`, projectData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteProject = async (projectId) => {
    try {
        await axios.delete(`${BASE_URL}/projects/${projectId}`);
    } catch (error) {
        throw error;
    }
};
