import React from 'react';

import { IUserProfile, MultiAccountMenuProps } from './DataTypes';
import GoogleAccountMenu from '../GoogleLogin/GoogleAccountMenu';
import RotaryAccountMenu from '../RotaryLogin/RotaryAccountMenu';

const MultiAccountMenu: React.FC<MultiAccountMenuProps> = ({ userProfile, onLoginSuccess, onLogoutSuccess }) => {
    // Визначаємо, який компонент меню потрібно відображати
    switch (userProfile?.logintype) {
        //case "rotary.org.ua":
        //    return <GoogleAccountMenu userProfile={userProfile} onLoginSuccess={onLoginSuccess} onLogoutSuccess={onLogoutSuccess} />;
        default:
            return <RotaryAccountMenu userProfile={userProfile} onLoginSuccess={onLoginSuccess} onLogoutSuccess={onLogoutSuccess} />;
    }
}

export default MultiAccountMenu;
