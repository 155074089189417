import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from '@mui/material'
import '../Pages.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import MyMainPage from "../../modules/MYMain";
import LandingBlock from '../../components/LandingBlock';
import { getEventsData } from "../../api/eventsData";

const MyEventsPage = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const events = await getEventsData();
          //console.log('getEventsData = '+JSON.stringify(events.data, null, 2));
          setData(events.data);
        } catch (error) {
          // Обробити помилку отримання даних
        }
      };

      fetchData();
    }, []);

    return (
        <>
          <MyMainPage>
            <LandingBlock mode='light'>
            <Box className="section text-left" >
            
              <Container>
                  <Typography variant="h4" className="page-header">Календар Подій Дистрикту 2232 на 2023-2024 Ротарійський рік</Typography>
                  <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#98c8ff" }}>
                        <TableCell>Дата</TableCell>
                        <TableCell>Назва заходу</TableCell>
                        <TableCell>Клуб організатор та місце проведення</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data && data.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.date}</TableCell>
                          <TableCell>{row.event}</TableCell>
                          <TableCell>{row.club}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                  
              </Container>
            </Box>
            </LandingBlock>
            
          </MyMainPage>
        </>
  );
}

export default MyEventsPage;