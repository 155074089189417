
import React, { useEffect, useState, useContext, useRef  } from "react";

import { Box, Typography, Container, Link, Paper, Button } from '@mui/material';
import { MdEmail } from 'react-icons/md';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import LandingContentItems from '../../../components/LandingContentItems';
import LcButtonPdf from './LcButtonPdf';
import {handleCopyToClipboard} from '../../../modules/CRUD_system/functions/toolsFuncs';
import PhotoCarusel from '../../../modules/PhotoCarusel';
import ProjectPhotoCarusel from './ProjectPhotoCarusel';

import { changeMetafieldLine, getSubfieldValue } from '../../../modules/CRUD_system/functions/MetafieldEditLineTools';
import { 
        showField, 
        showField_json, 
        showField_title, 
        showField_rclub, 
        showField_metafield, 
        showField_metafield_thanks,
        showField_metafield_invitation,
        showFields_contacts, 
        showField_smart, 
        listDocsPages, 
        listVideoPages_metafield,
        listWebSocialPages 
    } from '../../../modules/CRUD_system/functions/ProjectPageShowItemTools';

const LandingProjectPage_Singapore = ({proj, handleBack}) => {
    const tags = "#showcase";
    const clubLogo = "https://res.cloudinary.com/tyko/image/upload/v1704543710/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/share/RC_Cherkasy_project_edlovp.png";
    const districtLogo = "https://res.cloudinary.com/tyko/image/upload/v1704614094/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/share/D2232_project_edlovp_o1oywq.png";
    
    const index = 0;
    const onlyimgs = () => {
        if (typeof proj.avatarimg === 'string') {
            // Розділення строки за комами і видалення пробільних символів
            const imagesArray = proj.imgs.split(',').map(img => img.trim());
    
            // Фільтрація масиву, щоб залишити тільки зображення з певними розширеннями
            return imagesArray;//.filter((img) => /\.(jpg|jpeg|png)$/i.test(img));
        }
        return null;
    };
    const onlydocss = () => {
        if (typeof proj.docs === 'string') {
            // Розділення строки за комами і видалення пробільних символів
            return proj.docs.split(',').map(img => img.trim());
        }
        return null;
    };
    

    const showProject = (proj) => {
        return (
            <>
            
            <Box display="flex" flexDirection='column'  gap={2} alignItems="top" paddingTop='2em' flexWrap='wrap'>
            

                <Paper sx={{p: '16px'}}>
                    
                    <Button variant='contained' onClick={handleBack}>back</Button>

                    <ProjectPhotoCarusel images={onlyimgs()} caruselType="carusel_eclub"/>

                    {showField_title(proj.title)}

                    {showField_rclub(proj)}
                    {showField_json('Rotary Area of Focus:', proj.category)}
                    {showField_json('Project category:', proj.category2)}
                    {showField('Realization Stage:', proj.status)}
                    {showField('Budget:', proj.budget)}

                    {showField_metafield('Donors:', proj, 'youtube:donors')}
                    {showField_metafield('Partners:', proj, 'youtube:partners')}

                    {listDocsPages(proj.docs)}
                    {listWebSocialPages(proj.genlink)}
                    {listVideoPages_metafield(proj, 'youtube:video')}
                    
                    {showField_metafield('Short Description:', proj, 'youtube:fdesc')}
                    {showField_smart('Full description:', proj.description)}

                    
                    {showField_metafield_thanks('THANKS!!!:', proj, 'youtube:thanks')}
                    {showField_metafield_invitation('Invitation:', proj, 'youtube:invitation')}
                
                    {showFields_contacts(proj)}
                </Paper>
            
            </Box>
            </>
        )
    }
    return (
        <>
            {showProject(proj)}
            
        </>
  );
}

export default LandingProjectPage_Singapore;