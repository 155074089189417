import { URL_TO_API_SERVER } from './env';
const API_URL = URL_TO_API_SERVER+"materials_seminar_presentations.php"; // змініть на ваш URL

export type Presentation = {
    id?: number;
    timestamp: string;
    order: number;
    seminar_id: number;
    speaker: string;
    name: string;
    url: string;
};

export async function getPresentations(): Promise<Presentation[]> {
    const response = await fetch(API_URL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseJson = await response.json();
    console.log('Server json:',responseJson);
    return responseJson;
}

export async function getPresentationById(id: number): Promise<Presentation> {
    const response = await fetch(`${API_URL}?id=${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseJson = await response.json();
    console.log('Server getPresentationById json:',responseJson);
    return responseJson;
}

export async function getPresentationsBySemId(seminar_id: number): Promise<Presentation> {
    console.log('getPresentationsBySemId seminar_id='+seminar_id);
    const response = await fetch(`${API_URL}?seminar_id=${seminar_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseJson = await response.json();
    console.log('Server json:',responseJson);
    return responseJson;
}

export async function createPresentation(data: Presentation): Promise<void> {
    console.log('createPresentation '+JSON.stringify(data));
    
    const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableType: 'create',
            data: data,
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    } else {
        console.log('createPresentation OK');
    }
    //const result = response.json();
    //console.log('Server response:', result);
    const responseText = await response.text();
    console.log('Server text:',responseText);
}

export async function updatePresentation(data: Presentation): Promise<void> {
    if (!data.id) throw new Error("ID is required to update presentation.");

    const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableType: 'update',
            data: data,
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
}

export async function deletePresentation(id: number): Promise<void> {
    console.log('deletePresentation id='+id);
    const response = await fetch(`${API_URL}?id=${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableType: 'delete',
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseText = await response.text();
    console.log('Server text:',responseText);
}
