import React, { useEffect, useState, useContext } from "react";
import { Button, Box, Container, Grid, Typography } from '@mui/material'

import MainLanding from '../../pages/LandingPages/MainLanding';
import {getBusinessLanding, getPhotoForLanding} from '../../api/getBusinessLanding'

import { FormattedMessage } from 'react-intl';
import {tr} from '../../i18n/tr';
import LandingContentBlock from '../../components/LandingContentBlock';
import { LOCALES } from '../../i18n/locales';
import {LocalContext} from '../../tools/LanguageContextProvider';

import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import LandingArrContentBlock from '../../components/LandingArrContentBlock';

import Header from "../../modules/Header";

import { getPresentations, getPresentationsBySemId } from '../../api/ts/materials_seminars_presentations';
import { Card, CardContent, Link, CardMedia } from '@mui/material';

import { styled } from '@mui/system';

const NoWrapTypography = styled(Typography)(({ theme }) => ({
    '&.MuiTypography-root': {
      [theme.breakpoints.up('sm')]: {
        whiteSpace: 'nowrap',
      },
    },
}));
  
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    // Застосовуємо стилі тільки для sm екранів
    [theme.breakpoints.down('sm')]: {
        paddingLeft: '2px',
        paddingRight: '0px',
        _border: '1px solid red',
        _color: 'red',
    },
}));

const LandingEditor = () => {
    const { currentLocale, setCurrentLocale } = useContext(LocalContext);
    const [data, setData] = useState([]);
    const [dataEng, setDataEng] = useState([]);
    const [dataUkr, setDataUkr] = useState([]);
    const [presentations, setPresentations] = useState(null);
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const d_eng = await getBusinessLanding(false);
          setDataEng(d_eng);
          const d_ukr = await getBusinessLanding(true);
          setDataUkr(d_ukr);
          setData( currentLocale===LOCALES.UKRAINIAN ? d_ukr : d_eng);
          const f = await getPhotoForLanding();
          setPhotos(f);
          console.log("Event1Page get data list success. const="+d_ukr.length);

            const dt2 = await getPresentationsBySemId(14);
            //const dt2 = await getPresentations();
            setPresentations(dt2);
        } catch (error) {
          console.log("Event1Page get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        setData( currentLocale===LOCALES.UKRAINIAN ? dataUkr : dataEng);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale])
    
    const showCard = (desc, img, url, pwd) => {
        return (
            <Card variant="outlined" sx={{ maxWidth: 345, margin: '20px auto' }}>
                <CardMedia
                    component="img"
                    height="140"
                    image={img}
                    alt="Зображення семінару"
                />
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        Семінар з міжнародної діяльності
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {desc}
                    </Typography>
                    <Button variant="contained" color="primary" href={url} >
                        Перейти до запису
                    </Button>
                    <Typography variant="body1" color="textPrimary" style={{marginTop: '10px'}}>
                        Passcode: {pwd}
                    </Typography>
                </CardContent>
            </Card>
        )
    }

    return (

        <MainLanding noWarningMode="hide" >
            <div style={{ position: 'relative' }}>
                <Header images={["https://res.cloudinary.com/tyko/image/upload/v1697372630/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0/Untitled-1_ro8aul.jpg",
                                    "https://res.cloudinary.com/tyko/image/upload/v1697372063/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0/387458194_1738730159888792_4655458269864975449_n_y0wwhw.jpg",
                                    "https://res.cloudinary.com/tyko/image/upload/v1697373214/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0/Untitled-2_flhr0h.jpg"]} /> 
                
                <Typography 
                variant="h3" 
                style={{
                    zIndex: 1000,
                    position: 'absolute',
                    width: '100%',
                    top: '15%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    padding: 2,
                    paddingTop: '50px',
                    color: 'white', // Задає колір тексту, якщо потрібно
                    backgroundColor: 'rgba(0, 0, 0, 0.2)', // Задає фон, якщо потрібно
                    fontSize: '4vw',
                    fontWeight: 'bold',
                }}
                >
                    <br/><br/> 
                    {tr("ПРОГРАМА РОТАРІЙСЬКОГО ДІЛОВОГО ФОРУМУ ІННОВАЦІЙ, СТАРТАПІВ ТА МОЖЛИВОСТЕЙ")}
                </Typography>
                <Typography 
                variant="h3" 
                style={{
                    zIndex: 1000,
                    position: 'absolute',
                    width: '100%',
                    top: '1%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    padding: 2,
                    paddingTop: '100px',
                    color: 'white', // Задає колір тексту, якщо потрібно
                    backgroundColor: 'rgba(0, 0, 0, 0.0)', // Задає фон, якщо потрібно
                    fontSize: 'max(2vw, 14pt)',
                }}
                >
                {tr("на 28 жовтня 2023 року, м. Львів")}
                </Typography>
                <Button 
                    variant='contained'
                    size='large'
                    sx={{ textTransform: 'none' }} 
                    onClick={() => {
                        
                            window.open('https://docs.google.com/forms/d/e/1FAIpQLScVDa3s08cj4FJTPdn3BVIPoA2BgF0arlPEt3kGz0-GlPTTkA/viewform', '_blank');
                            //window.scrollTo(0, 0);
                            console.log("LandingButtonLink click")
                        
                    }}
                    style={{
                        zIndex: 1000,
                        position: 'absolute',
                        _width: '30%',
                        bottom: '120px',
                        right: '50px',
                        _transform: 'translate(-50%, -50%)',
                        background: 'rgba(255, 215, 0, 0.8)',
                        fontSize: 'max(2vw, 14pt)',
                    }}
                >
                    {tr("Реєстрація учасників")}
                </Button>
                <Button 
                    variant='contained'
                    size='large'
                    sx={{ textTransform: 'none' }} 
                    onClick={() => {
                        
                            window.open('https://docs.google.com/forms/d/e/1FAIpQLSe0I-r0FdkknPG7qAj67JMBa6V_H0WmY9NMRDNnS4OjjIiXeQ/viewform', '_blank');
                            //window.scrollTo(0, 0);
                            console.log("LandingButtonLink click")
                        
                    }}
                    style={{
                        zIndex: 1000,
                        position: 'absolute',
                        _width: '30%',
                        bottom: '30px',
                        right: '50px',
                        _transform: 'translate(-50%, -50%)',
                        background: 'rgba(255, 215, 0, 0.8)',
                        fontSize: 'max(2vw, 14pt)',
                    }}
                >
                    {tr("Реєстрація стартапів")}
                </Button>
            </div>

            {data && data.map((item,index) => (
                <>
                    {(item.content || item.img || item.cards) && <LandingContentBlock id={index} landItem={item} designType='busines' />}
                    {item.arrcontent && <LandingArrContentBlock id={index} arrItem={item} designType='busines' />}
                </>
            ))}
            


        </MainLanding>
  );
}

export default LandingEditor;