
import { LOCALES } from '../../../i18n/locales';

export const getRCLanding3 = (local) => {
    const cherkasyData = local === LOCALES.UKRAINIAN ? cherkasyData_ukr : cherkasyData_eng;
    return cherkasyData;
};

const cherkasyData_ukr = [
    {
        id: 0,
        header: 'Пожертви',
        arrcontent: [
            {
                img: "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/donate/donate.jpg",
                content: [
                    {LandingMessage: `Реквізити для пожертв`},
                    {BR: 'true'},{BR: 'true'},
                    
                    {LandingFileIcon: "pdf", text: "UAH реквізити.pdf", 
                         link: "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/donate/UAH реквізити.pdf"},
                    {BR: 'true'},{BR: 'true'},

                    {LandingFileIcon: "pdf", text: "USD реквізити.pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/donate/USD реквізити.pdfм"},
                    {BR: 'true'},{BR: 'true'},

                    {LandingFileIcon: "pdf", text: "EUR реквізити.pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/donate/EUR реквізити.pdf"},
                ]
            },
        ]
    },
];

const cherkasyData_eng = [
];