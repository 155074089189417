import React, {useState, useEffect} from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { Box, Container, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import {getICC} from '../api/ICC';
import './Pages.css';

import MainPage from '../components/Main';
import LandingBlock from '../components/LandingBlock';
import LandingHeader from '../components/LandingHeader';

import IMG1 from '../assets/content/icc/Vira.jpg'
import IMG2 from '../assets/content/icc/icc.jpg'

const IccPage = () => {
     
    const [data, setData] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const icc = await getICC();
          setData(icc);
          console.log("IccPage get ICC list success. const="+icc.length);
        } catch (error) {
          console.log("IccPage get ICC list error="+error);
        }
      };
      fetchData();
    }, [])

    return (
        <>
          <MainPage>
          <div style={{ position: 'relative' }}>
              <img src={IMG2} height='100%' width='100%' alt="Background" />
              <Typography 
                variant="h3" 
                style={{
                  position: 'absolute',
                  width: '100%',
                  top: '2%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  padding: 2,
                  paddingTop: '50px',
                  color: 'white', // Задає колір тексту, якщо потрібно
                  _backgroundColor: 'rgba(0, 0, 0, 0.3)', // Задає фон, якщо потрібно
                  fontSize: '2vw',
                }}
              >
                Особиста сторінка
              </Typography>
              <Typography 
                variant="h3" 
                style={{
                  position: 'absolute',
                  width: '100%',
                  top: '12%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  padding: 2,
                  paddingTop: '50px',
                  color: 'white', // Задає колір тексту, якщо потрібно
                  backgroundColor: 'rgba(0, 0, 0, 0.3)', // Задає фон, якщо потрібно
                  fontSize: '6vw',
                  fontWeight: 'bold',
                }}
              >
                 <br/>ICC Committes <br/> Міждержавних Комітетів
              </Typography>
            </div>


            <LandingBlock mode='light2'>
            <Box className="section text-left" >
            <Container>
              <LandingHeader><Typography variant="h5" className="page-header">Про Міждержавні Комітети (МДК)</Typography></LandingHeader>
                  
                  <p>
                    Міждержавні Комітети (МДК) розвивають та будують мир, дружбу та 
                    довготермінові відносини між Ротарійцями та не-Ротарійцями у двох 
                    країнах. 
                    Дистрикти заохочуються до створення нових Міждержавних Комітетів 
                    для зміцнення зв’язків між Ротарійцями різних країн, понад кордонами, 
                    континентами та океанами там, де для цього створені відповідні умови 
                    та де РІ має розбудовану свою власну структуру. 
                    Міждержавні Комітети повинні пропагуватись на заходах РІ, Дистрикту 
                    та на Клубних засіданнях. </p>
              
              <LandingHeader><Typography variant="h5" className="page-header">Місія Міждержавних Комітетів</Typography></LandingHeader>
                                      
                                      <p>
                                      Місією Міждержавних Комітетів є покращення міжкультурного 
                                      розуміння шляхом заохочення Ротарійців до об’єднання з Ротарійцями в 
                                      інших країнах у зміцненні братерства та стимуляції Ротарійських 
                                      ініціатив та акцій, які призводять до зміцнення миру у світі. </p>

                    
              <LandingHeader><Typography variant="h5" className="page-header">Мета Міждержавних Комітетів</Typography></LandingHeader>
                                      
                                      <p>
                                      Метою Міждержавних Комітетів є поєднання людей у двох різних 
                                      країнах 
                                        <br/>- для полегшення створення, розвитку та забезпечення прогресу 
                                        ефективної тривалої міжнародної мережі двосторонніх відносин та 
                                        діяльності 
                                        <br/>- для фокусування всіх можливостей, що сприяють доброму клімату, 
                                        необхідному для зміцнення миру та розвитку світового 
                                        порозуміння 
                                        шляхом представлення їхніх країн, Ротарійців, вибраних Дистриктом 
                                        (-ами) в своїй країні, які, як члени конкретного МДК, є персональними 
                                        послами миру. </p>

                    <Box className="section text-left" >
            
            
            
                    <Grid container  direction="row" spacing={2}>
                        <Grid item md={3}>
                          <img src={IMG1} width='100%'/>
                        </Grid>
                        <Grid item md={8}>
                          <h2>Віра Сиряміна</h2>
                          <h2>Національний Координатор Міждержавних комітетів в Україні 2021-2024</h2>
                          
                        </Grid>
                    </Grid>
                    </Box>
                
            </Container>
            </Box>
            </LandingBlock>



            <LandingBlock mode='dark2'>
            <Box className="section text-left" >
            
                <Container>
                <Box>
                      <p>
                        На даний момент в Дистрикті {data.length} комітети.
                      <h2> Перелік ICC комітетів</h2>
                      </p>
                      <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>ICC</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>RC</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{index+1}</TableCell>
                              <TableCell>{row.icc}</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{row.rc}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  
                  </Box>
                  </Container>



                  <Container>
                    <LandingHeader><Typography variant="h5" className="page-header">Документи:</Typography></LandingHeader>
                    
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton aria-label="Open PDF" color="primary"  onClick={() => window.open('https://drive.google.com/file/d/171I637VQcZfVWZDBdAtFD0tRrCexQvOR/view?usp=drive_link', '_blank')}>
                    <PictureAsPdfIcon fontSize="large" style={{ marginRight: '4px' }} />
                    </IconButton>
                    <Typography variant="h5" className="page-header">Інструкції для міждержавних комітетів</Typography>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton aria-label="Open PDF" color="primary"  onClick={() => window.open('https://docs.google.com/presentation/d/16bWM17OPEkKHry2maDT2pNZWfQ4wB7qB/edit?usp=drive_link&ouid=116503399666831052031&rtpof=true&sd=true', '_blank')}>
                      <InsertDriveFileIcon fontSize="large" style={{ marginRight: '4px' }} />
                    </IconButton>
                    <Typography variant="h5" className="page-header">Презентація міждержавних комітетів</Typography>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton aria-label="Open PDF" color="primary"  onClick={() => window.open('https://drive.google.com/file/d/1uADvxqPmSwfplooPFHZENpAhyeW-DXAX/view?usp=drive_link', '_blank')}>
                    <PictureAsPdfIcon fontSize="large" style={{ marginRight: '4px' }} />
                    </IconButton>
                    <Typography variant="h5" className="page-header">Положення про Роботу Міждержавних Комітетів</Typography>
                    </div>

                  </Container>
            </Box>
            </LandingBlock>
            


            
            
          </MainPage>
        </>
  );
}

export default IccPage;