export const getMenuStructure = (local) => {
    return data_eng;
}

const data_eng = {

    navbar: {
        _title: "РеК Україна",
        //logoUrl: "https://res.cloudinary.com/tyko/image/upload/v1704542422/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/share/RC_Cherkasy_trimed_q9pd9g.png",
        logoUrl: 'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/logo/ReC_ofUkraine_trimed.png',
        // languages: [
        //     "en-US",
        //     "ua-UA",
        // ],
        // autorization: "true",
        addlogoborder: true,
        withoutd2232url: true, 
        menu: [
            {
                title: "Адміністрування",
                arr: [
                    { 
                        title: "Ротарійці клубу",
                        lref: '/rc_eclubofukraine/adminmember',
                    },
                    { 
                        title: "Проєкти клубу",
                        lref: '/rc_eclubofukraine/adminprojects',
                    },
                    { 
                        title: "Проєкти Сінгапуру",
                        lref: '/rc_eclubofukraine/adminprojects_singapore',
                    },
                ]
            },
            {
                title: ".",
                lref: '',
            },
        ],
    },
    footer: {
        contactPerson_: {
            name: "Priamry contacts:",
            email: "rotaryeclubua@gmail.com",
        },
    }
}
