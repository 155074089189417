import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from 'react-router-dom';

import { LOCALES } from '../../../i18n/locales';
import {LocalContext} from '../../../tools/LanguageContextProvider';

import TykoLanding from './TykoLanding';
import LandingContent from './LandingContent';
import LandingHeader2 from './LandingHeader2';
import LandingHeaderParalax from '../../../components/LandingHeaderParalax';
import {menuFromLandingBlock} from '../functions/forLandingPageV2';

const breadcrumbs = "ІТ Комітет";

const LandingPageV3 = ({navbarFunc, headerFunc, pageFunc, designType, urlid, pageDublicateMenu}) => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [data, setData] = useState([]);
    const [pageData, setPageData] = useState([]);
    const [headerData, setHeaderData] = useState(null);
    const [navbar, setNavbar] = useState(null);
    const [footer, setFooter] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
            await loadData(currentLocale);
        } catch (error) {
          console.log("getHomeLanding get list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale])

    useEffect(() => {
        setPageData(filterDataByUrl(data, urlid));
        console.log("Change curent langufge то ="+currentLocale);
    }, [urlid])

    const loadData = async(locale, page) => {
        const d = await pageFunc(locale);
        setData( d );
        setPageData(filterDataByUrl(d, urlid));

        if (headerFunc) {
            const hd = await headerFunc(locale);
            setHeaderData( hd );
        }

        
        const pagestruct = navbarFunc ? navbarFunc(locale) : {};
        const menu = menuFromLandingBlock(d);
        if (!pagestruct.navbar) {
            pagestruct.navbar = {};
        }
        pagestruct.navbar.menu = menu;
        setNavbar(pagestruct.navbar);
        setFooter(pagestruct.footer);

        console.log(`DanatePage get data list success. pr=${page} const=`+d.length);
    }

    const filterDataByUrl = (data, url) => {
        // Фільтруємо масив, залишаючи тільки ті елементи, в яких mheader дорівнює заданому url
        const filteredData = url ? data.filter(item => item.murl === url) : (data && data.length>1 ? [data[0]] : []);
    
        return filteredData;
    }

    return (
        <TykoLanding footer={footer} noWarningMode="hide" >
            <LandingHeader2 headerData={headerData} designType='chicago' navbar={navbar} />
            <LandingContent data={pageData} currentLocale={currentLocale} designType={designType}/>
        </TykoLanding> 
  );
}
export default LandingPageV3;