import React from 'react';

import List from '@mui/material/List';

import LandingListItem from './LandingListItem';

const LandingList = ({arr, type}) => {
    const renderedListItems = arr && arr.map((item, index) => (
        <>
        {item.LandingListItem && <LandingListItem key={index} id={item.LandingListItem} type={type} url={item.url} linkText={item.linkText} />}
        {item.LandingLink && <LandingListItem key={index} id={item.LandingLink} url={item.url} type={type} />}
        </>
    ));
  
    return type === 'dot' ? (
      <ul>
        {renderedListItems}
      </ul>
    ) : (
      <List>
        {renderedListItems}
      </List>
    );
  };

export default LandingList;
