export const getProjectFiltres_rc_cherckasy = (local) => {
    return data_eng;
}

const data_eng = [
    {
        title: 'Rotary Area of Focus:',
        bdfield: 'category',
        items: [
            "1. Promoting peace",
            "2. Fighting disease",
            "3. Providing clean water, sanitation, and hygiene",
            "4. Saving mothers and children",
            "5. Supporting education",
            "6. Growing local economies",
            "7. Protecting the environment",
        ],
    },
    {
        title: 'Project category:',
        bdfield: 'category2',
        items: [
            "medicine",
            "rehabilitation",
            "mental care",
            "education/children",
            "WASH",
            "humanitarian demining",
            "IDPs families",
            "community recovery",
            "generators",
            "logistic costs",
        ],
    },
    {
        title: 'Realization Stage:',
        bdfield: 'status',
        items: [
            "Offered, looking for global grant partners",
            "Offered, looking for other partners",
            "In process", 
            "Permanently active",
            "During the war in Ukraine"
        ],
    },
    {
        title: 'Budget:',
        bdfield: 'budget',
        items: [
            "<1000$",
            "1000-2000$",
            "2000-5000$",
            "5000-10,000$",
            "10,000-20,000$",
            "20,000-50,000$",
            ">50,000$"
        ],
    },
//     {
//         title: 'Rotary Club Type:',
//         clubtype: [
//             "Rotary",
//             "Rotaract"
//         ],
//     },
];

