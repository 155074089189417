import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';

const LandingButtonLink = ({link, text, variant}) => {
    const navigate = useNavigate();
    return (
        <Button 
            variant={variant?variant:'contained'}
            disabled={!link}
            size='large'
            sx={{ textTransform: 'none' }} 
            onClick={() => {
                console.log("LandingButtonLink click="+link)
                if (link) {
                    if (link.startsWith('http'))
                    {
                        window.open(link, '_blank');
                        //window.scrollTo(0, 0);
                        console.log("LandingButtonLink click="+link)
                    } else {
                        navigate(link);
                        
                        console.log("LandingButtonLink click="+link)
                    }
                }
            }}
        >
            <FormattedMessage id={text} />
        </Button>
    );
};

export default LandingButtonLink;
