import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Box, Button, Menu, MenuItem, IconButton, Drawer, useMediaQuery, useTheme, Link } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';

import './TLandingNavbar.css';
import { LocalContext } from '../../../tools/LanguageContextProvider';
import MenuIcon from '@mui/icons-material/Menu';
import { LOCALES } from '../../../i18n/locales';
import D2232LOGO from '../../../assets/img/D2232.png';
import MultiAccountMenu from '../../../modules/MultiLogin/MultiAccountMenu';
import GoogleLoginButton from '../../../modules/GoogleLogin/GoogleLoginButton';

const MyTLandingNavbar = ({ navbar, languages, userProfile, onLogined, isClubMember }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [scrollPosition, setScrollPosition] = useState(0);
    const { currentLocale, setCurrentLocale } = useContext(LocalContext);
    const navigate = useNavigate();

    // Стан для контролю підменю
    const [anchorEl, setAnchorEl] = useState({});

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        setCurrentLocale(languages && languages[0]==='Eng' ? LOCALES.ENGLISH : LOCALES.UKRAINIAN);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLanguageClick = () => {
        setCurrentLocale(currentLocale === LOCALES.UKRAINIAN ? LOCALES.ENGLISH : LOCALES.UKRAINIAN);
    };

    const handleScroll = () => {
        setScrollPosition(window.pageYOffset);
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleMenuItemClick = (path) => {
        navigate(path);
    };

    const handleMenuOpen = (event, menuId) => {
        setAnchorEl({ ...anchorEl, [menuId]: event.currentTarget });
    };

    const handleMenuClose = (menuId) => {
        setAnchorEl({ ...anchorEl, [menuId]: null });
    };

    const renderMenuItem = (item, menuId) => (
        <div key={item.title} className="my-menu-button">
            <Button color="inherit" onClick={item.arr ? (e) => handleMenuOpen(e, menuId) : () => handleMenuItemClick(item.lref)}>
                <FormattedMessage id={item.title} />
                {item.arr && (
                    <ArrowDropDownIcon style={{ marginRight: '-10px' }} />
                )}
            </Button>

          {item.arr && (
            <Menu
              anchorEl={anchorEl[menuId]}
              keepMounted
              open={Boolean(anchorEl[menuId])}
              onClose={() => handleMenuClose(menuId)}
            >
              {item.arr.map((subItem, index) => renderMenuItem(subItem, `${menuId}-${index}`))}
            </Menu>
          )}
        </div>
      );

    const renderMenuItems = (menu) => (
        <>
            {menu.map((item, index) => renderMenuItem(item, `menu-${index}`))}
        </>
    );

    const navBarStyle = {
        transition: '0.1s',
        _height: scrollPosition > 0 ? '60px' : '110px',
        height: '40px',
        // backgroundColor і color видалені, оскільки вони тепер в CSS
    };

    const onClose = () => {

    }

    const onLoginSuccess = (userData) => {

        //setUserProfile(userData);
        console.log("====== onLoginSuccess="+(userData && userData.email));
        onLogined(userData);
    }
    const onLogoutSuccess = () => {
        console.log("====== onLogoutSuccess=");
        //setUserProfile(null);
        onLogined(null);
    }
    
    return (
        <AppBar position="sticky" className="my-app-bar" sx={navBarStyle} >
            <div>
                <Box className="navbar-container">
                    <Box >
                        {userProfile && "Вітаю, "+userProfile.userName}
                    </Box>
                    <Box className="text-container">
                        
                        {isMobile ? (
                          <>
                            <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                              <MenuIcon />
                            </IconButton>
                            {navbar.menu && navbar.menu.length>1 &&
                            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                                <Box className="mobile-menu-container">
                                    {navbar.menu && isClubMember && renderMenuItems(navbar.menu)}
                                </Box>
                            </Drawer>}
                          </>
                        ) : (
                            <>
                            {navbar.menu && navbar.menu.length>1 &&
                            <Box className="my-menu-buttons-container" >
                                {navbar.menu && isClubMember && renderMenuItems(navbar.menu)}
                            </Box>}
                            </>
                        )}
                    </Box>
                    <Box className="language-button">
                    <Box display="flex" flexDirection='row' gap={1} >
                        
                        {false && !userProfile &&<GoogleLoginButton onLoginSuccess={onLoginSuccess} />}
                        <MultiAccountMenu userProfile={userProfile} onLoginSuccess={onLoginSuccess} onLogoutSuccess={onLogoutSuccess} />

                        {/*<IconButton onClick={onClose} aria-label="close">
                            <CloseIcon />
                            </IconButton>*/}
                    </Box>
                    </Box>
                </Box>
            </div>
        </AppBar>
    );
    
    
};

export default MyTLandingNavbar;
