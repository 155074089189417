import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, Button } from '@mui/material'
import '../Pages.css';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Tabs, Tab } from '@mui/material';

import MyMainPage from "../../modules/MYMain";
import LandingBlock from '../../components/LandingBlock';
import RotaractClubsPage from '../RotaractClubsPage';
import InteractClubsPage from '../InteractClubsPage';

const TabPanel = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const TabPage = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div>
      <Tabs value={activeTab} onChange={handleTabChange} centered>
        <Tab label="Ротарі Клуби" />
        <Tab label="Ротаракт Клуби" />
        <Tab label="Інтеракт Клуби" />
      </Tabs>

      <TabPanel value={activeTab} index={0}>
        
        <ProjectActivePage />
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        
        <RotaractClubsPage />
      </TabPanel>

      <TabPanel value={activeTab} index={2}>
        <InteractClubsPage />
      </TabPanel>
    </div>
  );
};

const MyRotaryClubsPage  = () => {

  return (
    <MyMainPage>
      <LandingBlock mode='light'>
        <Box className="section text-left" >
            <TabPage />
        </Box>
      </LandingBlock>
    </MyMainPage>
  )
};

const ProjectActivePage = () => {
    const [clubs, setClubs] = useState([]);

    useEffect(() => {
        const token = 'YourSecretToken'; // Replace with your actual token
        fetch('https://rotary.org.ua/template/api/api_rotary_clubs.php', {
            headers: {
                'Authorization': token
            }
        })
        .then(response => response.json())
        .then(data => {setClubs(data);console.log("==================="+clubs)})
        .catch(error => console.error(error));
    }, []);

    return (
        <>

            <Container>
            <Typography variant="h4" className="page-header">Ротарі клуби</Typography>
                  <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#98c8ff" }}>
                        <TableCell></TableCell>
                        <TableCell>clubname</TableCell>
                        <TableCell>clubemail</TableCell>
                        <TableCell>clubdesc</TableCell>
                        <TableCell>clubpphone</TableCell>
                        <TableCell>logo</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clubs.map((row, index) => (
                        index % 2 === 0 && (
                        <TableRow key={index}>
                            <TableCell><Button>{ Math.floor(index / 2)}</Button></TableCell>
                          <TableCell>{row.clubname}</TableCell>
                          <TableCell>{row.clubemail}</TableCell>
                          <TableCell>{row.clubdesc}</TableCell>
                          <TableCell>{row.clubpphone}</TableCell>
                          <TableCell align="right"><img src={"https://rotary.org.ua/assets/site/images/clubs_imgs/"+row.clubimg} height='50px' /></TableCell>
                        </TableRow>
                      )))}
                    </TableBody>
                  </Table>
                </TableContainer>
                  
              </Container>

        </>
  );
}

export default MyRotaryClubsPage ;


const data2 = [
  [13, 'Lviv-Ratusha', '', '', 'info@rc-ratusha.lviv.ua', 'http://www.rc-ratusha.lviv.ua', 'Nataliia Vytrykush', '+380 0984619936', 'Monday at 19:00 <>\r\n4 Mateyka St.Lviv, 79000, Ukraine <>\r\n\r\n', 'rotary', 'Lviv', 'OWqkSkJ1Ig.png', 'png', 'UKR'],
  [15, 'Lviv-Leopolis', '', '', 'lvivleopolis@ukr.net', 'www.rc-leopolis.lviv.ua', 'Mykhaylo Buha\r\n', '+380 676732788', 'Wednesday at 18:00 <>\r\n6 Rynok SquareLviv, 79006, Ukraine\r\n', 'rotary', 'Lviv', 'cdDirZHZ7Q.png', 'png', 'UKR'],
  [17, 'Lviv International', '', '', '', 'http://www.rc-lvivint.com/', 'Nataliia Dolinska', '+380 (67) 6726752', 'Thursday at 18:55 <>\r\nTeatral\'na St, 16Lviv, 79008, Ukraine <>\r\nLeopolis Hotel\r\n', 'rotary', 'Lviv', '5jweg72iVL.png', 'png', 'UKR'],
  [19, 'Lviv', '', '', '', 'http://www.rotary.lviv.ua', 'Oleksiy Dehtyarenko', '+380 67 6711433', 'When Thursday at 19:00 <>\r\nWhere (In-Person) Lviv, Shevchenko Avenue,<> 27 Lviv, 79000, Ukraine\r\n', 'rotary', 'Lviv', 'dBXl0LH4ZD.png', 'png', 'UKR'],
  [21, 'Rivne', '', '', 'rotaryrivne@gmail.com', 'https://www.facebook.com/groups/1640198046252004/', 'Yulia Melianchuk', '+380 678000790', 'Thursday at 18:30 <>\r\n23A, V.Chornovola str.Rivne, 33028, Ukraine <>\r\nCafe "MAPLE"\r\n\r\n', 'rotary', 'rivne', 'UlFURXaLJo.png', 'png', 'UKR'],
]