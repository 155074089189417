import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';

const LandingImgTools = ({link}) => {
    const navigate = useNavigate();
    return (
        <img src={link} width='330px' style={{marginTop: '8px', marginBottom: '8px'}} />
    );
};

export default LandingImgTools;
