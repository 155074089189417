import axios from 'axios';

const API_BASE_URL = 'https://api-sequelize.rotaryd2232ukraine.org/db'; 

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const getAllUsers = async () => {
  console.log('======== Calling getAllUsers');
  try {
    const response = await api.get('/eventusers');
    console.log('======== getAllUsers response:', response);
    return response;
  } catch (error) {
    console.error('Failed to fetch users:', error.response || error.message);
    throw error;
  }
};

export const getUserById = async (id) => {
  try {
    const response = await api.get(`/eventuser/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch user with id ${id}:`, error.response || error.message);
    throw error;
  }
};

export const createUser = async (userData) => {
  try {
    const response = await api.post('/eventuser', userData);
    return response.data;
  } catch (error) {
    console.error('Failed to create user:', error.response || error.message);
    throw error;
  }
};

export const updateUser = async (id, userData) => {
  try {
    const response = await api.put(`/eventuser/${id}`, userData);
    return response.data;
  } catch (error) {
    console.error(`Failed to update user with id ${id}:`, error.response || error.message);
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    await api.delete(`/eventuser/${id}`);
  } catch (error) {
    console.error(`Failed to delete user with id ${id}:`, error.response || error.message);
    throw error;
  }
};
