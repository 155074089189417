import axios from 'axios'; 
import {api_is_logged_in, api_islogin} from '../../modules/RotaryLogin/rotaryAutorize';

export const checkIsLoginedUser = async() => {
    try {
        const res = await api_is_logged_in();
        //setServerResponse(res);
        if (res.status==='success')
            return getMembersInfo(res.email);
    } catch (error) {
        // setServerResponse({
        //     status: "error",
        //     message: "Помилка додатку.",
        //     debug: "api_is_logged_in: error="+error,
        // });
        console.log("DanatePage get data list error="+error);
    }
    return null;
}

const getMembersInfo = async(email) => {
    try {
        const response2 = await axios.get(`https://api.rotary.org.ua/apibd/memberinfo?email=${email}`);
        const userProfile = {
            userName: response2.data && response2.data.length > 0 ? response2.data[0].membername : email,
            email: email,
            imgUrl: null,
            committee: null,
            club: response2.data && response2.data.length > 0 ? response2.data[0].club : null,
            logintype: "rotary.org.ua"
        };
        // Використовуйте userProfile згідно з вашою логікою
        return userProfile;
    } catch (error) {
        console.error('Помилка при запиті інформації про користувача:', error);
    }
    return null;
}