import React, { useContext, useEffect, useState } from "react";
import { IntlProvider } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { LOCALES } from '../i18n/locales';
import { messages } from '../i18n/massages';
import {LocalContext} from '../tools/LanguageContextProvider';

import BetaMessage from './BetaMessage';
import './Main.css';
import NavbarPage from "../modules/Navbar";
import Footer from "../modules/Footer";
import MuiBreadcrumbs from '../modules/MuiBreadcrumbs';

import {getHomeLanding, getMenuHeaders} from '../api/home';

const ga4 = 'G-5P2E208YMM';

const MainPage = (props) => {
    const { currentLocale, setCurrentLocale } = useContext(LocalContext);
    const location = useLocation();
    const [homeMenu, setHomeMenu] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const d = await getHomeLanding();
            const m = await getMenuHeaders(d);
            setHomeMenu(m);
            console.log("MainPage get list success. const="+d.length);
  
          } catch (error) {
            console.log("MainPage get list error="+error);
          }
        };
        fetchData();
      }, [])

    useEffect(() => {
        if (true || process.env.NODE_ENV === 'production') {
            window.gtag('config', ga4, {
            page_path: location.pathname,
            });
        }
    }, [location]);

    const handleIntlError = (err) => {
        if (err.code === 'MISSING_TRANSLATION') {
            //console.warn('Missing translation', err.message);
        return;
        }
        throw err;
    };

    return (
       
        <IntlProvider messages={messages[currentLocale]} 
          locale={currentLocale}
          defaultLocale={LOCALES.ENGLISH} 
          onError={() => {}}>


          <NavbarPage menu={homeMenu} />
          <div style={{minHeight: 'calc(100vh - 150px)', paddingBottom: '24px'}}>
            
          {props.breadcrumbs && <MuiBreadcrumbs struct1={null} titleLast={props.breadcrumbs}/>}

          <BetaMessage noWarningMode={props.noWarningMode} />
            {props.children}
          </div>
          <Footer />
          
      
        </IntlProvider>

  );
}

export default MainPage;