import { useEffect, useState, useContext } from "react";
import { useParams } from 'react-router-dom';

import MainPage from "../components/Main";
import {getAboutD2232Page} from './d2232GetFunctions/getAboutD2232Page';
import {LocalContext} from '../tools/LanguageContextProvider';
import LandingContent from '../pages/RC_Cherkasy/components/LandingContent';

import { Box, Container, Grid, Typography } from '@mui/material'
import './Pages.css';

import LandingBlock from '../components/LandingBlock';
import LandingHeader from '../components/LandingHeader';

import RI_1 from '../assets/content/home/RI_1.png'

const AboutD2232 = () => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [data, setData] = useState([]);
    const  designType='chicago';

    useEffect(() => {
        const fetchData = async () => {
            try {
                await loadData(currentLocale);
            } catch (error) {
                console.log("loadData is error="+error);
            }
        };
        fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale])

    const loadData = async(locale) => {
        const d = await getAboutD2232Page(locale);
        setData( d );
        console.log("loadData is success. const="+d.length);
    }

    return (
        <MainPage>
        <LandingContent data={data} currentLocale={currentLocale} designType={designType} />
        </MainPage>
  );
}

export default AboutD2232;