import {getAll_, getAll_2, getItemById_, createItem_, updateItem_, deleteItem_} from './api_crud';

const tableName = "api_d2232_events.php";

export type D2232Events = {
    id?: number;
    date: string;
    date2: string;
    event: string;
    club: string;
    regLink: string;
    siteLink: string;
    docsLink: string;
};

export const tableTitles = {
    id: "id",
    date: "date",
    date2: "date2",
    event: "event",
    club: "club",
    regLink: "regLink",
    siteLink: "siteLink",
    docsLink: "docsLink",
}

export async function getAll(): Promise<any[]> {
    return await getAll_(tableName);
}

export async function getAll2(date1: string, date2: string): Promise<any[]> {
    console.log(`==============2`);
    return await getAll_2(tableName, date1, date2);
}

export async function getItemById(id: number): Promise<any> {
    return await getItemById_(tableName, id);
}

export async function createItem(data: any): Promise<void> {
    return await createItem_(tableName, data);
}

export async function updateItem(data: any): Promise<void> {
    return await updateItem_(tableName, data);
}

export async function deleteItem(id: number): Promise<void> {
    return await deleteItem_(tableName, id);
}
