import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from 'react-router-dom';

import {getItCommitteeLanding} from '../../../api/getItCommitteeLanding';
import {getItCommitteeMenu} from './getItCommitteeMenu';
import {getItProjectPage} from './getItProjectPage';
import { LOCALES } from '../../../i18n/locales';
import {LocalContext} from '../../../tools/LanguageContextProvider';

import TykoLanding from '../components/TykoLanding';
import LandingContent from '../components/LandingContent';
import TLandingFooter from '../components/TLandingFooter';

const breadcrumbs = "ІТ Комітет";
const pageJson = {
    undefined: getItCommitteeLanding,
    "projects": getItProjectPage,
};
const ItCommitteePage = () => {
    const images = [ "http://watcher.com.ua/wp-content/uploads/1-1-620x414.jpg",
            "https://processer.media/wp-content/uploads/2022/11/digital-smart-contract-isometric-icon-concept-of-electronic-signature-vector-id1313551846.jpg"
            ];
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [data, setData] = useState([]);
    const [navbar, setNavbar] = useState(null);
    const [footer, setFooter] = useState(null);
    const { id } = useParams();
    const menuRefs = useRef([]);
    const  designType='chicago';

    useEffect(() => {
      const fetchData = async () => {
        try {
            await loadData(currentLocale, id);
          
        //   d.map((item, index) => {
        //     menuRefs.current.push(React.createRef());
        //     console.log("========= menuRefs="+menuRefs.current.length);
        //   })
        } catch (error) {
          console.log("getHomeLanding get list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale, id);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale, id])

    const loadData = async(locale, page) => {
        const pageFunc = pageJson[page];
        const d = await pageFunc(locale);
        setData( d );

        const pagestruct = getItCommitteeMenu(locale);
        setNavbar(pagestruct.navbar);
        setFooter(pagestruct.footer);

        console.log(`DanatePage get data list success. pr=${page} const=`+d.length);
    }

    return (
        <>
        {navbar ?
        <TykoLanding navbar={navbar} noWarningMode="hide" designType={"business"} >
        <LandingContent data={data} currentLocale={currentLocale} designType={designType} footer={footer}/>
        </TykoLanding> : "Loading..."}
        </>
  );
}

export default ItCommitteePage;