//import {getProjectFiltres} from '../bdFunctions/getProjectFiltres';
import {getAdminPage} from '../bdFunctions/getAdminPage';

import RcClubPageMain from '../RcClubPageMain';

import {getPageContentByUrl} from '../bdFunctions/getPageContentByUrl';
import {getMenuContentByUrl} from '../bdFunctions/getMenuContentByUrl';

const D2232UkrainePage = () => {
    const  designType='chicago';

    return (
        <RcClubPageMain 
            menufunc={getMenuContentByUrl} 
            pagesContent={getPageContentByUrl}
            getAdminPage={getAdminPage}
            designType={designType}
            //getProjectFiltres={getProjectFiltres}
            tags='rec_ukraine'
        >
        </RcClubPageMain>
    );
}

export default D2232UkrainePage;