import React, { useState, useEffect } from 'react';

import {TextFieldProps } from '@mui/material';
import { DesktopDatePicker  } from '@mui/x-date-pickers';
import { Container, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import {  Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import TableComponent from './TableComponent';
import FormFieldsComponent from './FormFieldsComponent';
import AdminTableComponent from '../../pages/MyAdministrations/AdminTableComponent';

import { getAll, getItemById, createItem, updateItem, deleteItem, tableTitles } from "../../api/ts/api_d2232_events";

export const emptyData = {
    rotaryYear: '',
    date: '',
    sity: '',
    title: '',
    fileUrl: '',
    websiteUrl: '',
}

const AdminEvents = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            setLoading(true);
            const d = await getAll();
            setData(d);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    const refreshData = async () => {
        fetchData();
    };

    return (
        <Container>
        <Paper>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {loading && <p>Завантаження...</p>}
            <AdminTableComponent 
                data={data}
                tableTitles={tableTitles}
                emptyData={emptyData}
                createItem={createItem}
                updateItem={updateItem}
                deleteItem={deleteItem}
                refreshData={refreshData}
            />
        </Paper>
        </Container>
    );
};

export default AdminEvents;
