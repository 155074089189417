import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Container } from '@mui/material';

import MyMainPage from "../../modules/MYMain";
import LandingBlock from '../../components/LandingBlock';

const ProfileRotarian = () => {
  const [editing, setEditing] = useState(true);
  const [rotarianId, setRotarianId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [clubName, setClubName] = useState('');
  const [position, setPosition] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleApplyClick = () => {
    setEditing(false);
    // Додаткова логіка для збереження змін
  };

  const handleCancelClick = () => {
    setEditing(false);
    // Додаткова логіка для відміни змін
  };

  return (
    <MyMainPage>
    <LandingBlock mode='light'>
    <Box className="section text-left" >
    <Container>
    <Box sx={{ maxWidth: 500, margin: 'auto', padding: '20px' }}>
        <Typography variant="h4" className="page-header">Адміністрування особистих даних ротарійця</Typography>

      <Box sx={{ display: 'grid', gap: '20px', mt: '20px' }}>
        <TextField
          label="ІД ротарійця"
          disabled={!editing}
          value={rotarianId}
          onChange={(e) => setRotarianId(e.target.value)}
        />
        <TextField
          label="Ім'я"
          disabled={!editing}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          label="Прізвище"
          disabled={!editing}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          label="Назва клубу"
          disabled={!editing}
          value={clubName}
          onChange={(e) => setClubName(e.target.value)}
        />
        <TextField
          label="Посада в клубі"
          disabled={!editing}
          value={position}
          onChange={(e) => setPosition(e.target.value)}
        />
        <TextField
          label="Емейл"
          disabled={!editing}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Телефон"
          disabled={!editing}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </Box>

      {editing ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '20px' }}>
          <Button variant="contained" onClick={handleApplyClick} sx={{ mr: '10px' }}>
            Застосувати
          </Button>
          <Button variant="contained" onClick={handleCancelClick}>
            Відмінити
          </Button>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '20px' }}>
          <Button variant="contained" onClick={handleEditClick}>
            Редагувати
          </Button>
        </Box>
      )}
    </Box>
    </Container>
    </Box>
    </LandingBlock>
    </MyMainPage>
  );
};

export default ProfileRotarian;
