import React, { useEffect, useState } from "react";
import { Container, Typography } from '@mui/material'
import { Paper } from '@mui/material';
import {  useMediaQuery, useTheme } from '@mui/material';

import MyMainPage from "../modules/MYMain";

const WordTypePage = ({children}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <MyMainPage noWarningMode={true} >
        <div>
            <Container style={{padding:'8px'}}>
            <Paper style={{padding:'12px', paddingLeft: isSmallScreen ? '12px' : '96px', paddingRight: isSmallScreen ? '8px' : '24px'}}>
                {children}
            </Paper>
            </Container>
        </div>
        </MyMainPage>
  );
}

export default WordTypePage;