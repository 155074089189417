import React, { useState } from "react";
import { Box, Typography, Container } from '@mui/material';
import ScrollTrigger from 'react-scroll-trigger';
import './LandingContentBlock2.css';

import LandingBlock from '../../../components/LandingBlock';
import LandingProjects3_eclub from '../components/LandingProjects3_eclub';

const LandingContentBlock_params = ({ id, landItem, designType, careaArray, hasOpened, children }) => {
    
    const isEvenId = id % 2 === 0;
    let mode;
    switch (designType) {
        case 'business':
            mode = isEvenId ? "light2" : "dark2";
            break;
        case 'chicago':
            mode = isEvenId ? "light3" : "dark3";
            break;
        case 'simple':
        case 'simple2':
            mode = isEvenId ? "light5" : "dark3";
            break;
        default:
            mode = isEvenId ? "light2" : "light";
    }
    const imagePosition = (id % 2 === 0 ? "left" : "right");

    return (
        <LandingBlock mode={mode}>
            <Box className="section text-left">
                <Container>
                    

                    {(landItem.LandingProjects3_eclub) 
                        && <LandingProjects3_eclub landItem={landItem} careaArray={careaArray} hasOpened={hasOpened} />
                    }

                </Container>
            </Box>
        </LandingBlock>
    );
    
};

export default LandingContentBlock_params;
