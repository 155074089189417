
export const extractFileNameFromPath = (fullPath) => {
    return null;
    
    if (typeof fullPath !== 'string') {
      throw new Error('The provided path must be a string');
    }
  
    // Перевірка на порожній рядок
    if (fullPath.trim() === '') {
      return '';
    }
  
    // Розділення шляху по слешам (як прямим, так і зворотним)
    const pathSegments = fullPath.split(/[/\\]/);
  
    // Перевірка на наявність сегментів
    if (pathSegments.length === 0) {
      return '';
    }
  
    // Останній елемент масиву сегментів - це ім'я файлу
    const filename = pathSegments[pathSegments.length - 1];
  
    return filename;
}
  