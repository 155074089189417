import {getMenuStructure} from '../bdFunctions/getMenuStructure';
import {getPagesContent} from '../bdFunctions/getPagesContent';
import {getUserPagesContent} from '../bdFunctions/getUserPagesContent';
import {getPagesContent_projects} from '../bdFunctions/getPagesContent_projects';
//import {getProjectFiltres} from '../bdFunctions/getProjectFiltres';
import {getAdminPage} from '../bdFunctions/getAdminPage';

import RcClubPageMain from '../../ClubPageComponents/RcClubPageMain';

const RcEClubOfUkrainePage = () => {
    const  designType='chicago';

    return (
        <RcClubPageMain 
            menufunc={getMenuStructure} 
            pagesContent={getPagesContent}
            getUserPagesContent={getUserPagesContent}
            getPagesContent_projects={getPagesContent_projects}
            getAdminPage={getAdminPage}
            designType={designType}
            //getProjectFiltres={getProjectFiltres}
            tags='rec_ukraine'
        >
        </RcClubPageMain>
    );
}

export default RcEClubOfUkrainePage;