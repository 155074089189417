import React, { useEffect, useState } from "react";
import './Header.css';

const Header = ({images, text, designType}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        let interval;
        if (!isHovered) {
            interval = setInterval(() => {
                handleNext();
            }, 3000);
        }
        
        return () => {
            clearInterval(interval);
        };
    }, [isHovered, activeIndex]);

    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <div 
            className="header" 
            style={{ backgroundColor: designType==='chicago' ? '#019fcb' : 'darkblue' }}
            //onMouseEnter={() => setIsHovered(true)}
            //onMouseLeave={() => setIsHovered(false)}
        >
            <button onClick={handlePrev} className="carousel-button carousel-button-left">←</button>
            
            {images.map((image, index) => (
                <img 
                    key={index}
                    src={image} 
                    className={`header-image ${index === activeIndex ? 'active' : ''}`} 
                    alt={`Header Image ${index + 1}`}  // Альтернативний текст
                />
            ))}
            
            {text && <div className="carousel-text">
                {text}
            </div>}
            
            <div className="carousel-indicators">
                {images.map((_, index) => (
                    <span 
                        key={index}
                        className={`indicator ${index === activeIndex ? 'active' : ''}`} 
                    />
                ))}
            </div>
            
            <button onClick={handleNext} className="carousel-button carousel-button-right">→</button>
        </div>
    );
}

export default Header;
