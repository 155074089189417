import axios from 'axios';

//const BASE_URL = 'http://localhost:3001/apibd';
const BASE_URL = 'https://api.rotary.org.ua/apibd';

export const fetchSitePages = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/sitecreators`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchSitePage = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/sitecreator/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createSitePage = async (sitePageData) => {
    console.log('=== createSitePage...');
    try {
        const response = await axios.post(`${BASE_URL}/sitecreator`, sitePageData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateSitePage = async (id, sitePageData) => {
    console.log('=== updateSitePage...');
    try {
        console.log('=== updateSitePage url'+`${BASE_URL}/sitecreator/${id}`);
        const response = await axios.put(`${BASE_URL}/sitecreator/${id}`, sitePageData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteSitePage = async (id) => {
    try {
        await axios.delete(`${BASE_URL}/sitecreator/${id}`);
    } catch (error) {
        throw error;
    }
};
