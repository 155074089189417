import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from '@mui/material'
import './Pages.css';

import MainPage from "../components/Main";
import LandingBlock from '../components/LandingBlock';

import IMG1 from '../assets/content/news/Myron.png'
import IMG2 from '../assets/content/news/News2.png'

const NewsPage = () => {
    return (
        <>
          <MainPage>
            <LandingBlock mode='light'>
            <Box className="section text-left" >
            
              <Container>
              <Typography variant="h4" className="page-header">Новини</Typography>
                  <br/>
                  <Grid container spacing={2}>
                      <Grid item md={4}>
                        <img src={IMG2} width='100%' />
                      </Grid>
                      <Grid item md={8}>
                        <p>Друзі, презентуємо новий медіа-проєкт "Ротарі  - яка твоя історія?"
Кожен із вас - окремий світ, оповитий власною історією, яка безцінна! Розкажіть її людям і  подаруйте емоції, мотивацію,  шанс зрозуміти  Ротарі через власний досвід. 
Комітет публічного іміджу Дистрикту 2232 запрошує всіх ротарійців до активності. Ставши одним із героєм проєкту,  ви самі переконаєтеся  наскільки магічною силою наділена ваша ротарійська історія! Відкрийте її для себе і створіть надію для світу!
                        </p>
                      </Grid>
                  </Grid>
                    <br/><br/>
                  <Grid container spacing={2}>
                      <Grid item md={4}>
                        <img src={IMG1} width='100%' />
                      </Grid>
                      <Grid item md={8}>
                        <p>Ротарі  - яка твоя історія?
                        Мирон Угрин, Губернатор Дитрикту 2232 в 2023-2024 ротарійському році
                        Зазвичай, коли пишуть про людину, то це правильна вивірена біографія, чіткі якісь параметри та вірні слова...
                        Але інколи трапляються в житті зустрічі задовго до знайомства і ти вже просто відчуваєш необхідність дізнатися про цю людину якомога більше мимоволі.
                        ✔️ "Він такий класний, що ти просто не уявляєш собі"
                        ✔️ "Та вся передова стоматологія України - його справа. Якщо твій стоматолог не знає його прізвища, то навряд чи він провідний в Україні сьогодні"
                        ✔️ "А знаєш, із 2014-го він на передовій не менше інших. Бо зайнятий своєю справою - рятує людей"
                        ✔️ "Він РОТАРІЄЦЬ з усіх великих букв. Таких ще пошукати треба"
                        ✔️ "Друг, яких мало. Що тобі ще сказати?"
                        </p>
                      </Grid>
                  </Grid>
                  
              </Container>
            </Box>
            </LandingBlock>
            
          </MainPage>
        </>
  );
}

export default NewsPage;