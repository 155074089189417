
export const hardcodeRClubs = () => {
   return data;
};

const data = [
    {
        "clubname": "Alushta",
        "rtype": "Rotary Club",
        "id": "31599",
        "assistent": "Nataliya Popel",
        "members": "4"
    },
    {
        "clubname": "Cherkasy",
        "rtype": "Rotaract Club",
        "id": "92032",
        "assistent": "",
        "members": "13"
    },
    {
        "clubname": "Cherkasy",
        "rtype": "Rotary Club",
        "id": "50516",
        "assistent": "Andrii Gyzhko",
        "members": "27"
    },
    {
        "clubname": "Cherkasy International",
        "rtype": "Rotary Club",
        "id": "91147",
        "assistent": "Andrii Gyzhko",
        "members": "27"
    },
    {
        "clubname": "Cherkasy Sky",
        "rtype": "Rotary Satellite Club",
        "id": "85036",
        "assistent": "",
        "members": "hided"
    },
    {
        "clubname": "Cherkasy Smile",
        "rtype": "Rotaract Club",
        "id": "80735",
        "assistent": "",
        "members": "15"
    },
    {
        "clubname": "Cherkasy Synergy",
        "rtype": "Rotary Club",
        "id": "225441",
        "assistent": "Andrii Gyzhko",
        "members": "20"
    },
    {
        "clubname": "Cherkasy-Centre",
        "rtype": "Rotary Club",
        "id": "62578",
        "assistent": "Andrii Gyzhko",
        "members": "24"
    },
    {
        "clubname": "Chernivtsi",
        "rtype": "Rotary Club",
        "id": "82565",
        "assistent": "Yaroslav Dumyn",
        "members": "21"
    },
    {
        "clubname": "Dnipro City",
        "rtype": "Rotary Club",
        "id": "50517",
        "assistent": "Andrii Tarakanov",
        "members": "25"
    },
    {
        "clubname": "Dnipro City Radost",
        "rtype": "Rotaract Club",
        "id": "217234",
        "assistent": "",
        "members": "20"
    },
    {
        "clubname": "Dnipro Novyi",
        "rtype": "Rotary Club",
        "id": "89997",
        "assistent": "Andrii Tarakanov",
        "members": "18"
    },
    {
        "clubname": "Dnipropetrovsk-Dnipro Region",
        "rtype": "Rotary Club",
        "id": "54528",
        "assistent": "Andrii Tarakanov",
        "members": "9"
    },
    {
        "clubname": "Dubno",
        "rtype": "Rotary Club",
        "id": "223045",
        "assistent": "Olha Novytska",
        "members": "13"
    },
    {
        "clubname": "E-Club of Ukraine",
        "rtype": "Rotary Club",
        "id": "86412",
        "assistent": "Nataliya Popel",
        "members": "26"
    },
    {
        "clubname": "E-Club of Ukraine Jointly",
        "rtype": "Rotary Satellite Club",
        "id": "222807",
        "assistent": "",
        "members": "hided"
    },
    {
        "clubname": "Ivano-Frankivsk",
        "rtype": "Rotaract Club",
        "id": "89189",
        "assistent": "",
        "members": "24"
    },
    {
        "clubname": "Ivano-Frankivsk",
        "rtype": "Rotary Club",
        "id": "30749",
        "assistent": "Yaroslav Dumyn",
        "members": "22"
    },
    {
        "clubname": "Ivano-Frankivsk-Stanislaviv",
        "rtype": "Rotary Club",
        "id": "89041",
        "assistent": "Yaroslav Dumyn",
        "members": "10"
    },
    {
        "clubname": "Izmail",
        "rtype": "Rotary Club",
        "id": "89550",
        "assistent": "Nataliya Popel",
        "members": "23"
    },
    {
        "clubname": "Izmail",
        "rtype": "Rotaract Club",
        "id": "8823756",
        "assistent": "",
        "members": "14"
    },
    {
        "clubname": "Kharkiv",
        "rtype": "Rotary Club",
        "id": "30392",
        "assistent": "Oleksandr Chupylko",
        "members": "21"
    },
    {
        "clubname": "Kharkiv Direct Help International",
        "rtype": "Rotary Club",
        "id": "225548",
        "assistent": "Oleksandr Chupylko",
        "members": "20"
    },
    {
        "clubname": "Kharkiv Family",
        "rtype": "Rotary Club",
        "id": "226040",
        "assistent": "",
        "members": "20"
    },
    {
        "clubname": "Kharkiv First Capital",
        "rtype": "Rotary Club",
        "id": "87660",
        "assistent": "Oleksandr Chupylko",
        "members": "14"
    },
    {
        "clubname": "Kharkiv First Capital",
        "rtype": "Rotaract Club",
        "id": "214049",
        "assistent": "",
        "members": "9"
    },
    {
        "clubname": "Kharkiv International",
        "rtype": "Rotaract Club",
        "id": "216853",
        "assistent": "",
        "members": "9"
    },
    {
        "clubname": "Kharkiv Mriya",
        "rtype": "Rotary Club",
        "id": "72134",
        "assistent": "Oleksandr Chupylko",
        "members": "20"
    },
    {
        "clubname": "Kharkiv Mriya",
        "rtype": "Rotaract Club",
        "id": "215713",
        "assistent": "",
        "members": "13"
    },
    {
        "clubname": "Kharkiv Multinational",
        "rtype": "Rotaract Club",
        "id": "212530",
        "assistent": "",
        "members": "7"
    },
    {
        "clubname": "Kharkiv Nadiya",
        "rtype": "Rotary Club",
        "id": "87180",
        "assistent": "Oleksandr Chupylko",
        "members": "15"
    },
    {
        "clubname": "Kharkiv Nadiya Kamianets",
        "rtype": "Rotary Satellite Club",
        "id": "222748",
        "assistent": "",
        "members": "hided"
    },
    {
        "clubname": "Kharkiv Nadiya Skhid",
        "rtype": "Rotary Satellite Club",
        "id": "222732",
        "assistent": "",
        "members": "hided"
    },
    {
        "clubname": "Kharkiv New Level",
        "rtype": "Rotary Club",
        "id": "222650",
        "assistent": "Oleksandr Chupylko",
        "members": "68"
    },
    {
        "clubname": "Kharkiv New Level Alpha",
        "rtype": "Rotary Satellite Club",
        "id": "223042",
        "assistent": "",
        "members": "hided"
    },
    {
        "clubname": "Kharkiv New Level Pyvden'",
        "rtype": "Rotary Satellite Club",
        "id": "222641",
        "assistent": "",
        "members": "hided"
    },
    {
        "clubname": "Kharkiv New Level Sumy",
        "rtype": "Rotary Satellite Club",
        "id": "223032",
        "assistent": "",
        "members": "hided"
    },
    {
        "clubname": "Kharkiv New Level Universe",
        "rtype": "Rotary Satellite Club",
        "id": "222719",
        "assistent": "",
        "members": "hided"
    },
    {
        "clubname": "Kharkiv Phoenix",
        "rtype": "Rotary Club",
        "id": "225719",
        "assistent": "Oleksandr Chupylko",
        "members": "17"
    },
    {
        "clubname": "Kharkiv Renaissance",
        "rtype": "Rotary Club",
        "id": "225038",
        "assistent": "Oleksandr Chupylko",
        "members": "15"
    },
    {
        "clubname": "Kharkiv Shpak",
        "rtype": "Rotaract Club",
        "id": "8824479",
        "assistent": "",
        "members": "15"
    },
    {
        "clubname": "Kharkiv Spirit",
        "rtype": "Rotary Club",
        "id": "225437",
        "assistent": "Oleksandr Chupylko",
        "members": "30"
    },
    {
        "clubname": "Kharkiv Victory",
        "rtype": "Rotary Club",
        "id": "225720",
        "assistent": "Oleksandr Chupylko",
        "members": "18"
    },
    {
        "clubname": "Kharkiv-City",
        "rtype": "Rotary Club",
        "id": "71875",
        "assistent": "Oleksandr Chupylko",
        "members": "29"
    },
    {
        "clubname": "Kharkiv-International",
        "rtype": "Rotary Club",
        "id": "90021",
        "assistent": "Oleksandr Chupylko",
        "members": "23"
    },
    {
        "clubname": "Kharkiv-Multinational",
        "rtype": "Rotary Club",
        "id": "84948",
        "assistent": "Oleksandr Chupylko",
        "members": "27"
    },
    {
        "clubname": "Kharkov",
        "rtype": "Rotaract Club",
        "id": "42581",
        "assistent": "",
        "members": "5"
    },
    {
        "clubname": "Kherson",
        "rtype": "Rotary Club",
        "id": "31908",
        "assistent": "Nataliya Popel",
        "members": "10"
    },
    {
        "clubname": "Kiev",
        "rtype": "Rotaract Club",
        "id": "2115",
        "assistent": "",
        "members": "15"
    },
    {
        "clubname": "Kobelyaki",
        "rtype": "Rotary Club",
        "id": "31600",
        "assistent": "Andrii Gyzhko",
        "members": "8"
    },
    {
        "clubname": "Kolomyia",
        "rtype": "Rotary Club",
        "id": "225800",
        "assistent": "",
        "members": "21"
    },
    {
        "clubname": "Korosten",
        "rtype": "Rotary Club",
        "id": "224764",
        "assistent": "Dirk Lustig",
        "members": "20"
    },
    {
        "clubname": "Kropyvnytskyi",
        "rtype": "Rotary Club",
        "id": "55121",
        "assistent": "Andrii Gyzhko",
        "members": "8"
    },
    {
        "clubname": "Kyiv",
        "rtype": "Rotary Club",
        "id": "28682",
        "assistent": "Dirk Lustig",
        "members": "30"
    },
    {
        "clubname": "Kyiv Advance",
        "rtype": "Rotary Club",
        "id": "85426",
        "assistent": "Dirk Lustig",
        "members": "6"
    },
    {
        "clubname": "Kyiv Community",
        "rtype": "Rotary Club",
        "id": "225860",
        "assistent": "Dirk Lustig",
        "members": "24"
    },
    {
        "clubname": "Kyiv Golden Pectoral",
        "rtype": "Rotary Club",
        "id": "225724",
        "assistent": "Dirk Lustig",
        "members": "20"
    },
    {
        "clubname": "Kyiv International",
        "rtype": "Rotaract Club",
        "id": "218114",
        "assistent": "",
        "members": "60"
    },
    {
        "clubname": "Kyiv International Business",
        "rtype": "Rotary Club",
        "id": "222361",
        "assistent": "Dirk Lustig",
        "members": "12"
    },
    {
        "clubname": "Kyiv Multinational",
        "rtype": "Rotary Club",
        "id": "73318",
        "assistent": "Dirk Lustig",
        "members": "17"
    },
    {
        "clubname": "Kyiv Multinational",
        "rtype": "Rotaract Club",
        "id": "89160",
        "assistent": "",
        "members": "15"
    },
    {
        "clubname": "Kyiv Synergy",
        "rtype": "Rotary Club",
        "id": "222068",
        "assistent": "Dirk Lustig",
        "members": "10"
    },
    {
        "clubname": "Kyiv-Balzac",
        "rtype": "Rotary Club",
        "id": "84749",
        "assistent": "Dirk Lustig",
        "members": "19"
    },
    {
        "clubname": "Kyiv-Capital",
        "rtype": "Rotary Club",
        "id": "50563",
        "assistent": "Dirk Lustig",
        "members": "39"
    },
    {
        "clubname": "Kyiv-Capital",
        "rtype": "Rotaract Club",
        "id": "216854",
        "assistent": "",
        "members": "11"
    },
    {
        "clubname": "Kyiv-Capital Kryvyi Rih",
        "rtype": "Rotary Satellite Club",
        "id": "222715",
        "assistent": "",
        "members": "hided"
    },
    {
        "clubname": "Kyiv-Center",
        "rtype": "Rotaract Club",
        "id": "59798",
        "assistent": "",
        "members": "8"
    },
    {
        "clubname": "Kyiv-Centre",
        "rtype": "Rotary Club",
        "id": "52526",
        "assistent": "Dirk Lustig",
        "members": "9"
    },
    {
        "clubname": "Kyiv-City",
        "rtype": "Rotaract Club",
        "id": "89393",
        "assistent": "",
        "members": "5"
    },
    {
        "clubname": "Kyiv-City",
        "rtype": "Rotary Club",
        "id": "83492",
        "assistent": "Dirk Lustig",
        "members": "26"
    },
    {
        "clubname": "Kyiv-Sophia",
        "rtype": "Rotary Club",
        "id": "84894",
        "assistent": "Dirk Lustig",
        "members": "18"
    },
    {
        "clubname": "Kyiv-Sophia",
        "rtype": "Rotaract Club",
        "id": "212352",
        "assistent": "",
        "members": "4"
    },
    {
        "clubname": "Lutsk Luchesk",
        "rtype": "Rotary Club",
        "id": "224593",
        "assistent": "Olha Novytska",
        "members": "18"
    },
    {
        "clubname": "Lutsk Volyn Pride",
        "rtype": "Rotary Club",
        "id": "224572",
        "assistent": "Olha Novytska",
        "members": "25"
    },
    {
        "clubname": "Lviv",
        "rtype": "Rotary Club",
        "id": "29085",
        "assistent": "Borys Mykola Bodnar",
        "members": "32"
    },
    {
        "clubname": "Lviv",
        "rtype": "Rotaract Club",
        "id": "42361",
        "assistent": "",
        "members": "13"
    },
    {
        "clubname": "Lviv Innovational",
        "rtype": "Rotary Club",
        "id": "222944",
        "assistent": "Borys Mykola Bodnar",
        "members": "21"
    },
    {
        "clubname": "Lviv International",
        "rtype": "Rotaract Club",
        "id": "215880",
        "assistent": "",
        "members": "24"
    },
    {
        "clubname": "Lviv International",
        "rtype": "Rotary Club",
        "id": "87280",
        "assistent": "Borys Mykola Bodnar",
        "members": "60"
    },
    {
        "clubname": "Lviv Kniazhyi",
        "rtype": "Rotary Club",
        "id": "84316",
        "assistent": "Borys Mykola Bodnar",
        "members": "18"
    },
    {
        "clubname": "Lviv-Centre",
        "rtype": "Rotary Club",
        "id": "55252",
        "assistent": "Borys Mykola Bodnar",
        "members": "16"
    },
    {
        "clubname": "Lviv-Leopolis",
        "rtype": "Rotary Club",
        "id": "51067",
        "assistent": "Borys Mykola Bodnar",
        "members": "30"
    },
    {
        "clubname": "Lviv-Ratusha",
        "rtype": "Rotary Club",
        "id": "62579",
        "assistent": "Borys Mykola Bodnar",
        "members": "25"
    },
    {
        "clubname": "Lviv-Zamok",
        "rtype": "Rotary Club",
        "id": "83461",
        "assistent": "Borys Mykola Bodnar",
        "members": "13"
    },
    {
        "clubname": "Mykolaiv",
        "rtype": "Rotary Club",
        "id": "30750",
        "assistent": "Nataliya Popel",
        "members": "21"
    },
    {
        "clubname": "Odesa Glory",
        "rtype": "Rotary Club",
        "id": "225303",
        "assistent": "Nataliya Popel",
        "members": "19"
    },
    {
        "clubname": "Odessa",
        "rtype": "Rotary Club",
        "id": "30751",
        "assistent": "Nataliya Popel",
        "members": "10"
    },
    {
        "clubname": "Odessa",
        "rtype": "Rotaract Club",
        "id": "89765",
        "assistent": "",
        "members": "9"
    },
    {
        "clubname": "Odessa International",
        "rtype": "Rotary Club",
        "id": "90921",
        "assistent": "Nataliya Popel",
        "members": "19"
    },
    {
        "clubname": "Odessa Sea Energy",
        "rtype": "Rotary Club",
        "id": "222249",
        "assistent": "Nataliya Popel",
        "members": "6"
    },
    {
        "clubname": "Odessa-Rishelye",
        "rtype": "Rotary Club",
        "id": "87270",
        "assistent": "Nataliya Popel",
        "members": "14"
    },
    {
        "clubname": "Poltava",
        "rtype": "Rotaract Club",
        "id": "56458",
        "assistent": "",
        "members": "10"
    },
    {
        "clubname": "Poltava",
        "rtype": "Rotary Club",
        "id": "31910",
        "assistent": "Andrii Gyzhko",
        "members": "30"
    },
    {
        "clubname": "Rakhiv-Center of Europe",
        "rtype": "Rotary Club",
        "id": "83416",
        "assistent": "Yaroslav Dumyn",
        "members": "14"
    },
    {
        "clubname": "Rivne",
        "rtype": "Rotaract Club",
        "id": "43605",
        "assistent": "",
        "members": "7"
    },
    {
        "clubname": "Rivne",
        "rtype": "Rotary Club",
        "id": "31786",
        "assistent": "Olha Novytska",
        "members": "21"
    },
    {
        "clubname": "Rivne Lutsk Lubart",
        "rtype": "Rotary Satellite Club",
        "id": "85032",
        "assistent": "",
        "members": "hided"
    },
    {
        "clubname": "Simféropol",
        "rtype": "Rotary Club",
        "id": "31656",
        "assistent": "Nataliya Popel",
        "members": "9"
    },
    {
        "clubname": "Slavyansk",
        "rtype": "Rotary Club",
        "id": "52489",
        "assistent": "Oleksandr Chupylko",
        "members": "8"
    },
    {
        "clubname": "Ternopil City",
        "rtype": "Rotary Club",
        "id": "222619",
        "assistent": "Yaroslav Dumyn",
        "members": "23"
    },
    {
        "clubname": "Truskavets",
        "rtype": "Rotary Club",
        "id": "80412",
        "assistent": "Borys Mykola Bodnar",
        "members": "2"
    },
    {
        "clubname": "Ukraine Unity Passport",
        "rtype": "Rotary Club",
        "id": "91130",
        "assistent": "Borys Mykola Bodnar",
        "members": "39"
    },
    {
        "clubname": "Uzhgorod",
        "rtype": "Rotary Club",
        "id": "30230",
        "assistent": "Yaroslav Dumyn",
        "members": "40"
    },
    {
        "clubname": "Uzhgorod",
        "rtype": "Rotaract Club",
        "id": "58705",
        "assistent": "",
        "members": "6"
    },
    {
        "clubname": "Uzhgorod-Mukachevo",
        "rtype": "Rotary Satellite Club",
        "id": "222458",
        "assistent": "",
        "members": "hided"
    },
    {
        "clubname": "Uzhgorod-Skala",
        "rtype": "Rotary Club",
        "id": "69500",
        "assistent": "Yaroslav Dumyn",
        "members": "24"
    },
    {
        "clubname": "Veliatyno International",
        "rtype": "Rotary Club",
        "id": "224609",
        "assistent": "Yaroslav Dumyn",
        "members": "9"
    },
    {
        "clubname": "Vinnytsia Postup",
        "rtype": "Rotary Club",
        "id": "223132",
        "assistent": "Andrii Gyzhko",
        "members": "21"
    },
    {
        "clubname": "Vinnytsia Postup-Berdychiv",
        "rtype": "Rotary Satellite Club",
        "id": "222978",
        "assistent": "",
        "members": "hided"
    },
    {
        "clubname": "Vizirka",
        "rtype": "Rotary Club",
        "id": "90881",
        "assistent": "Nataliya Popel",
        "members": "20"
    },
    {
        "clubname": "Zaporizhzhia",
        "rtype": "Rotaract Club",
        "id": "8823389",
        "assistent": "",
        "members": "14"
    },
    {
        "clubname": "Zaporizhzhia",
        "rtype": "Rotary Club",
        "id": "79036",
        "assistent": "Andrii Tarakanov",
        "members": "25"
    },
    {
        "clubname": "Zaporizhzhia-Khortytsia",
        "rtype": "Rotary Club",
        "id": "222131",
        "assistent": "Andrii Tarakanov",
        "members": "28"
    },
    {
        "clubname": "Zaporizhzhia-Khortytsia",
        "rtype": "Rotaract Club",
        "id": "8825583",
        "assistent": "",
        "members": "23"
    }
]
