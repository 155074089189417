
import React, { useEffect, useState, useContext, useRef  } from "react";

import { Box, Typography, Container, Link, Paper, Button } from '@mui/material';
import { MdEmail } from 'react-icons/md';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import {handleCopyToClipboard} from '../../../modules/CRUD_system/functions/toolsFuncs';
import PhotoCarusel from '../../../modules/PhotoCarusel';
import ProjectPhotoCarusel from './ProjectPhotoCarusel';

import { changeMetafieldLine, getSubfieldValue } from '../../../modules/CRUD_system/functions/MetafieldEditLineTools';
import { showField, showField_metafield, showField_title, showField_rclub, showField_json, showFields_contacts, showField_smart, listDocsPages, listWebSocialPages } from '../../../modules/CRUD_system/functions/ProjectPageShowItemTools';

const LandingProjectPage_eclub = ({proj, handleBack}) => {

    const onlyimgs = () => {
        if (typeof proj.avatarimg === 'string') {
            // Розділення строки за комами і видалення пробільних символів
            const imagesArray = proj.imgs.split(',').map(img => img.trim());
    
            // Фільтрація масиву, щоб залишити тільки зображення з певними розширеннями
            return imagesArray;//.filter((img) => /\.(jpg|jpeg|png)$/i.test(img));
        }
        return null;
    };

    const showProject = (proj) => {
        return (
            <>
            
            <Box display="flex" flexDirection='column'  gap={2} alignItems="top" paddingTop='2em' flexWrap='wrap'>
            

                <Paper sx={{p: '16px'}}>
                    
                    {handleBack && <Button variant='contained' onClick={handleBack}>back</Button>}

                    <ProjectPhotoCarusel images={onlyimgs() } caruselType="carusel_eclub"/>
                    
                    {showField_title(proj.title)}

                    {showField_rclub(proj)}

                    {showField_json('Rotary Area of Focus:', proj.category)}
                    {showField_json('Project category:', proj.category2)}
                    {showField('Realization Stage:', proj.status)}
                    {showField('Budget:', proj.budget)}

                    {showField_metafield('Donors:', proj, 'youtube:donors')}
                    {showField_metafield('Partners:', proj, 'youtube:partners')}

                    {listDocsPages(proj.docs)}
                    {listWebSocialPages(proj.genlink)}
                    
                    {showField_metafield('Short Description:', proj, 'youtube:fdesc')}
                    {showField_smart('Full description:', proj.description)}
                
                    {showFields_contacts(proj)}
                    
                
                </Paper>
            
            </Box>
            </>
        )
    }
    
    return (
        <>
            {showProject(proj)}
            
        </>
  );
}

export default LandingProjectPage_eclub;