import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams } from 'react-router-dom';

import {  useMediaQuery, useTheme } from '@mui/material';
import { Container, Typography, Paper } from '@mui/material'

import {getRCLanding} from '../RcCherkasy/getRCLanding';
import {getCherkasyProjects2} from '../RcCherkasy/getCherkasyProjects2';
import {getRCCherkasyRotaract} from '../RcCherkasy/getRCCherkasyRotaract';
import {getRCCherkasyPartners} from '../RcCherkasy/getRCCherkasyPartners';
import {getRCCherkasyLeadership} from '../RcCherkasy/getRCCherkasyLeadership';
import {getRCCherkasyBoard} from '../RcCherkasy/getRCCherkasyBoard';
import {getMyCherkasyLanding} from '../RcCherkasy/getMyCherkasyLanding';
import {getMyCherkasyBdRotarians} from '../RcCherkasy/getMyCherkasyBdRotarians';
import {LocalContext} from '../../../tools/LanguageContextProvider';
import LandingContent from '../components/LandingContent';
import RcCherkasyMain from '../RcCherkasy/RcCherkasyMain';

import DocumentsFrame from '../../myrotary/DocumentsFrame';
import EventsFrame from '../../EventsFrame';
import UserCRUD from '../rotariansBD/adminsPages/UserCRUD';
import MyAutorizeFrame from '../RcCherkasy/MyAutorizeFrame';

import Admin_LandingContent from './LandingBlockComponents/Admin_LandingContent';

const pageJson = {
    undefined: getRCLanding,
    "projects": getCherkasyProjects2,
    "rotaract": getRCCherkasyRotaract,
    "partners": getRCCherkasyPartners,
    "leadership": getRCCherkasyLeadership,
    "board": getRCCherkasyBoard,
    //my
    'myhome': getMyCherkasyLanding,
    "bdrotarians": getMyCherkasyBdRotarians,
};

const RcCherkasyPage_Admin = () => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [data, setData] = useState([]);
    const [userProfile, setUserProfile] = useState();
    
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { id } = useParams();

    const  designType='chicago';
    
    const isClubMember = () => {
        return userProfile && ( userProfile.club==='RC Cherckasy' || userProfile.email==='tykogm2@gmail.com');
    } 

    useEffect(() => {
      const fetchData = async () => {
        try {
            await loadData(currentLocale, id);
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale, id);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale, id])

    const loadData = async(locale, page) => {
        const pageFunc = pageJson[page];
        if (pageFunc) {
            const d = await pageFunc(locale);
            setData( d );
            console.log("DanatePage get data list success. const="+d.length);
        }
    }

    const onLogined = (userProfile) => {
        setUserProfile(userProfile);
        console.log("============= onLogined="+(userProfile && userProfile.club));
    }

    return (
        <RcCherkasyMain userProfile={userProfile} onLogined={onLogined} isClubMember={isClubMember()} >
        {(() => {
                switch (id) {
                    case 'events':
                        return (
                            <MyAutorizeFrame userProfile={userProfile} onLogined={onLogined} isClubMember={isClubMember()} >
                            <Container style={{ padding: '8px' }}>
                                <Paper style={{ padding: '12px', paddingLeft: isSmallScreen ? '12px' : '96px', paddingRight: isSmallScreen ? '8px' : '24px' }}>
                                    <EventsFrame />
                                </Paper>
                            </Container>
                            </MyAutorizeFrame>
                        );
                    case 'communique':
                        return (
                            <MyAutorizeFrame userProfile={userProfile} onLogined={onLogined} isClubMember={isClubMember()} >
                            <Container style={{ padding: '8px' }}>
                                <Paper style={{ padding: '12px', paddingLeft: isSmallScreen ? '12px' : '96px', paddingRight: isSmallScreen ? '8px' : '24px' }}>
                                    <DocumentsFrame />
                                </Paper>
                            </Container>
                            </MyAutorizeFrame>
                        );
                    case 'administaration':
                        return (
                            <MyAutorizeFrame userProfile={userProfile} onLogined={onLogined} isClubMember={isClubMember()} >
                            <div style={{padding: '24px'}}>
                                    <UserCRUD tags='РКЧеркаси' />
                            </div>
                            </MyAutorizeFrame>
                        );
                    
                    case 'myhome':
                    case 'bdrotarians':
                        return (
                            <MyAutorizeFrame userProfile={userProfile} onLogined={onLogined} isClubMember={isClubMember()} >
                            {
                                data ? (
                                    <LandingContent data={data} currentLocale={currentLocale} designType={designType} />
                                ) : (
                                    "Loading..."
                                )
                            }
                            </MyAutorizeFrame>
                        );
                    default:
                        return data ? (
                            <Admin_LandingContent data={data} currentLocale={currentLocale} designType={designType} />
                        ) : (
                            "Loading..."
                        );
                }
            })()}
        </RcCherkasyMain>
    );
}

export default RcCherkasyPage_Admin;