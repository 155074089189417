import LandingLink from "../../../../components/LandingLink";

export const getAdminPage = (local) => {
    return data_eng;
}

const data_eng = [
    {
        id: 0,
        header: 'for admin only',
        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/photo/1706191186331-169353556.jpg',
        content: [
            {LandingMessage: `admin membeers`},
            {LandingLink: "go to admin page", link: "/rc_eclubofukraine/adminmember" },
            {LandingMessage: `
            admin club progects`},
            {LandingLink: "go to admin page", link: "/rc_eclubofukraine/adminprojects" },
            {LandingMessage: `
            admin singapore project`},
            {LandingLink: "go to admin page", link: "/rc_eclubofukraine/adminprojects_singapore" },
            {LandingMessage: `
            admin photo library`},
            {LandingLink: "go to admin page ", link: "/rc_eclubofukraine/adminphotos" },
        ],
    },
];

