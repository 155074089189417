import React, {useState, useEffect} from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { Box, Container, Grid, Typography } from '@mui/material'

import MainPage from "../components/Main";
import LandingBlock from '../components/LandingBlock';
import {getRataractClubs} from '../api/rotaract';
import './Pages.css';
import TableLineComponent from '../components/TableLineComponent';

const breadcrumbs = ["Дистрикт 2232", "Ротаракт Клуби"];

const tableTitles = {
    city: "Назва клубу",
    //email: "Контактна пошта",
    City2: "Клуб спонсор",
    //value: "Кількість членів в клубі",
};

const RotaractClubsPage = () => {
     
    const [data, setData] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const d = await getRataractClubs().filter(city => city.status === true);
          setData(d);
          console.log("RotaractClubsPage get data list success. const="+d.length);
        } catch (error) {
          console.log("RotaractClubsPage get data list error="+error);
        }
      };
      fetchData();
    }, [])
    
    return (
        <MainPage noWarningMode='test' breadcrumbs={breadcrumbs} >
        <LandingBlock mode='light'>
        <Container>
            <br/>
            <Typography variant="body1">
                Ротаракт об’єднує молодих лідерів віком від 18 років для активної діяльності у своїх громадах і розвитку лідерських навичок, весело проводячи час.
            </Typography>
            <br/>
            <Typography variant="h6">Ротаракт Клуби</Typography>
            <TableLineComponent 
                data={data}
                tableTitles={tableTitles}
                addNumber
            />
            </Container>
        </LandingBlock>
        </MainPage>
  );
}

export default RotaractClubsPage;