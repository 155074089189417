import {URL_TO_API_SERVER} from './env';
//const API_URL = URL_TO_API_SERVER+"materials_seminars.php"; // змініть на ваш URL

export async function getAll_(tableName: string): Promise<any[]> {
    console.log(`============== false`)
    try {
        const response = await fetch(`${URL_TO_API_SERVER+tableName}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();
    } catch (error: unknown) {
        // Тут перевіряємо, чи помилка має властивість 'message'
        if (typeof error === 'object' && error !== null && 'message' in error) {
            const errorMessage = (error as { message: string }).message;
            console.error("An error occurred: ", errorMessage);
        } else {
            // Якщо у помилки немає властивості 'message' або вона не є об'єктом
            console.error("An unknown error occurred");
        }

        throw error;
    }
}

export async function getAll_2(tableName: string, date1: string, date2: string): Promise<any[]> {
    try {
        console.log(`============== ${URL_TO_API_SERVER+tableName}?date1=${date1}&date2=${date2}`);
        const response = await fetch(`${URL_TO_API_SERVER+tableName}?date1=${date1}&date2=${date2}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();
    } catch (error: unknown) {
        // Тут перевіряємо, чи помилка має властивість 'message'
        if (typeof error === 'object' && error !== null && 'message' in error) {
            const errorMessage = (error as { message: string }).message;
            console.error("An error occurred: ", errorMessage);
        } else {
            // Якщо у помилки немає властивості 'message' або вона не є об'єктом
            console.error("An unknown error occurred");
        }

        throw error;
    }
}

export async function getItemById_(tableName: string, id: number): Promise<any> {
    try {
        const response = await fetch(`${URL_TO_API_SERVER+tableName}?id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();
    } catch (error: unknown) {
        // Тут перевіряємо, чи помилка має властивість 'message'
        if (typeof error === 'object' && error !== null && 'message' in error) {
            const errorMessage = (error as { message: string }).message;
            console.error("An error occurred: ", errorMessage);
        } else {
            // Якщо у помилки немає властивості 'message' або вона не є об'єктом
            console.error("An unknown error occurred");
        }

        throw error;
    }
}




export async function createItem_(tableName: string, data: any): Promise<void> {
    try {
        const response = await fetch(URL_TO_API_SERVER+tableName, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                tableType: 'create',
                data: data,
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        console.log('Server response:', result);
        
    } catch (error: unknown) {
        // Тут перевіряємо, чи помилка має властивість 'message'
        if (typeof error === 'object' && error !== null && 'message' in error) {
            const errorMessage = (error as { message: string }).message;
            console.error("An error occurred: ", errorMessage);
        } else {
            // Якщо у помилки немає властивості 'message' або вона не є об'єктом
            console.error("An unknown error occurred");
        }

        throw error;
    }
}

export async function updateItem_(tableName: string, data: any): Promise<void> {
    if (!data.id) throw new Error("ID is required to update material.");

    const response = await fetch(URL_TO_API_SERVER+tableName, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableType: 'update',
            data: data,
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const result = await response.json();
    console.log('Server response:', result);
}

export async function deleteItem_(tableName: string, id: number): Promise<void> {
    const response = await fetch(`${URL_TO_API_SERVER+tableName}?id=${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableType: 'delete',
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
}
