import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';  // якщо ви використовуєте react-intl

import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, 
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const Admin_LandingMessage = ({ id, intl }) => {

    const textMessage = id;  

    const linkify = (text) => {
        const urlRegex = /(\b(http|https):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
        const parts = text.split(urlRegex);
    
        return parts.map((part, index) => {
            if (part.match(urlRegex)) {
                return <a href={part} target="_blank" rel="noopener noreferrer" key={index}>лінк</a>;
            } else {
                // Перевірка на непотрібні частини URL (http, https)
                if(part !== "http" && part !== "https") {
                    return part;
                }
            }
        });
    };

    return (
        <div className='landing_text'>
            {linkify(textMessage)}
            <TextField
                        margin="dense"
                        id="textMessage"
                        label="textMessage"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={textMessage}
                        //onChange={(e) => setCurrentProject({ ...currentProject, email: e.target.value })}
                        InputProps={{
                            style: {
                              backgroundColor: 'white', // Білий фон для поля вводу
                              borderRadius: 4, // Округлені кути, якщо потрібно
                            },
                          }}
                    />
        </div>
    );
};

export default injectIntl(Admin_LandingMessage); // Огорнуто компонент HOC
