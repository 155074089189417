import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography } from '@mui/material';

const LandingTable = ({ data, columns, onRowClicked }) => {
    return (
        <TableContainer component={Paper}>
        
        {//columns={columns && JSON.stringify(columns)
        }

        {data && columns &&
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={column.field}>{column.headerName}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((user, index) => (
                        <TableRow 
                            key={user.id} 
                            onClick={onRowClicked ? () => onRowClicked(index) : null}
                            sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }} // Add hover style here
                        >
                            {columns.map((column) => {
                                switch (column.type) {
                                    case 'multilineedit':
                                        return (
                                            <TableCell key={column.field}>
                                                <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                                                    {user[column.field]}
                                                </Typography>
                                            </TableCell>
                                        );
                                    case 'img':
                                        return <TableCell key={column.field}><img src={user[column.field]} height='70px' /></TableCell>;
                                    default:
                                        return <TableCell key={column.field}><Typography style={{ whiteSpace: 'pre-line' }}>{user[column.field]}</Typography></TableCell>;
                                }
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
}
        </TableContainer>
                        
    );
};

export default LandingTable;
