import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from '@mui/material'
import './Pages.css';

import MainPage from "../components/Main";
import LandingBlock from '../components/LandingBlock';
import LandingHeader from '../components/LandingHeader';

import IMG3 from '../assets/content/aboutus/Sasha.jpg';

const LeadersD2232Page = () => {
    return (
        <>
          <MainPage>
            
            <LandingBlock mode='light'>
            

            <Box className="section text-left" >
              <Container>
              <LandingHeader><Typography variant="h4" className="page-header">Лідери Д2232</Typography></LandingHeader>
                  <Grid container  direction="row" spacing={2}>
                      <Grid item md={4}>
                        <img src='https://api.rotary.org.ua/uploads/clubsitecreator/D2232/rotariansdb/Khardin.jpg' width='100%'/>
                      </Grid>
                      <Grid item md={8}>
                        <h2>Губернатор</h2>
                        <p>Олександ Хардін</p>
                        <h3>Контактна інформація: </h3>
                        Клуб: РК Полтава<br/>
                        E-mail: okhardin@ukr.net<br/>
                        <h3>Ротарійське служіння:</h3>
                        <p><b>2021-2022:</b> Голова Фінансового комітету Д2232.
                            Забезпечив найвищий показник фінансової дисципліни Ротарі клубів по оплаті
                            членських внесків в ГС та до РІ за весь час існування Д2232 та ефективне системне
                            управління фінансами, в тому числі під час активної фази війни в Україні.
                            </p>
                        <p><b>2021-2022</b>
                            Тренер Д2232 з фінансів.
                            Провів серію регулярних навчань та вебінарів з фінансової та грантової діяльності для
                            Ротарійців Дистрикту, в т.ч. в умовах здійснення гуманітарної місії під час війни в
                            Україні.
                        </p><br/><br/>
                        Відзнака Пола Хариса
                      </Grid>
                  </Grid>
              </Container>
            </Box>
{/* 
            <Box className="section text-left" >
              <Container>
                  <Grid container  direction="row" spacing={2}>
                      <Grid item md={4}>
                        <img src="https://api.rotary.org.ua/uploads/clubsitecreator/D2232/rotariansdb/Myron.jpg" width='100%'/>
                      </Grid>
                      <Grid item md={8}>
                        <h2>Губернатор</h2>
                        <p>Мирон Угрин</p>
                        <h3>Контактна інформація:</h3>
                        Клуб: РК Львів<br/>
                        E-mail: mm_clinic@ukr.net<br/>

                        <h3>Ротарійське служіння:</h3>
                        <p><b>1996-1997</b> Паст-Президент Ротарі Клубу Львів
                            </p>
                        <p><b>2001-2002</b> виконував обов’язки секретаря новоутвореного Д2230 (Білорусь, Польща, Україна) в частині України.
                        </p>
                        <p><b>1997</b> Учасник Конгресу РІ в Глазго.
                        </p><br/><br/>
                        Відзнака Пола Хариса
                      </Grid>
                  </Grid>
              </Container>
            </Box> */}

            <Box className="section text-left" >
              <Container>
                  <Grid container  direction="row" spacing={2}>
                      <Grid item md={4}>
                        <img src="https://api.rotary.org.ua/uploads/clubsitecreator/D2232/rotariansdb/Paliichuk.jpg" width='100%'/>
                      </Grid>
                      <Grid item md={8}>
                        <h2>Губернатор Елект</h2>
                        <p>Ольга Палійчук</p>
                        <h3>Контактна інформація: </h3>
                        Клуб: РК Черкаси<br/>
                        E-mail: oncology@2upost.com<br/>
                        <h3>Ротарійське служіння: </h3>
                        <p>
                        <b>2019-2022</b> – Голова Комітету Ротарійського Служіння місцевим громадам Д2232<br/>
                        <b>2020-2023</b> –  Голова підкомітету Ротарійських Товариств Д2232<br/>
                        <b>2022-2023</b> – Голова Комітету Міжнародного Служіння Д2232<br/><br/>
                        Відзнака Пола Хариса
                        </p>
                      </Grid>
                  </Grid>
              </Container>
            </Box>

            

            <Box className="section text-left" >
              <Container>
                  <Grid container  direction="row" spacing={2}>
                      <Grid item md={4}>
                        <img src="https://api.rotary.org.ua/uploads/clubsitecreator/D2232/rotariansdb/Kozhina.jpg" width='100%'/>
                      </Grid>
                      <Grid item md={8}>
                        <h2>Губернатор Номіні</h2>
                        <p>Ганна Кожина</p>
                        <h3>Контактна інформація: </h3>
                        Клуб: РК Харків-Мрія<br/>
                        E-mail: ridsecretaryua@gmail.com<br/>
                        <h3>Ротарійське служіння: </h3>
                        <p>
                        <b>2017-2018</b> – Секретар РК Харків Мрія<br/>
                        <b>2018-2019</b> – Президент РК Харків Мрія<br/>
                        <b>2019-2021</b> – Асистент Губернатора<br/>
                        <b>2020-2021</b> – Офіцер Дистрикту по роботі з Ротарактом<br/>
                        <b>2023-2025</b> – Офіцер Дистрикту з питань захисту молоді<br/>
                        <b>2021-2024</b> – Секретар Дистрикту<br/><br/>
                        Відзнака Пола Хариса
                        </p>
                      </Grid>
                  </Grid>
              </Container>
            </Box>

            </LandingBlock>
            
          </MainPage>
        </>
  );
}

export default LeadersD2232Page;