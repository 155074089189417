import {getAll_, getItemById_, createItem_, updateItem_, deleteItem_} from './api_crud';

const tableName = "api_d2232_communique.php";

export type Communique = {
    id?: number;
    data: string;
    number: string;
    title: string;
    url: string;
};

export type CommuniqueUrl = {
    id?: number;
    comunique_id: number;
    title: string;
    url: string;
};

export const communiqueTableTitles = {
    id: 'id',
    data: 'data',
    number:'number',
    title: 'title',
    url: 'url',
};

export const communiqueURLsTableTitles = {
    id: 'id',
    comunique_id: 'comunique_id',
    title: 'title',
    url: 'url',
};

export async function getAll(): Promise<any[]> {
    return await getAll_(tableName);
}

export async function getItemById(id: number): Promise<any> {
    return await getItemById_(tableName, id);
}

export async function createItem(data: any): Promise<void> {
    return await createItem_(tableName, data);
}

export async function updateItem(data: any): Promise<void> {
    return await updateItem_(tableName, data);
}

export async function deleteItem(id: number): Promise<void> {
    return await deleteItem_(tableName, id);
}
