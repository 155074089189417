import { LOCALES } from '../../../i18n/locales';

import D2232LOGO from '../../../assets/img/D2232.png';

export const getEven2Menu = (local) => {
    return local===LOCALES.UKRAINIAN ? defaultData_ukr : defaultData_eng;
};

const defaultData_ukr = {
    footer: 'nofooter'
}

const defaultData_eng = {
    footer: 'nofooter'
}