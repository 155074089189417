import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from '@mui/material'
import './Pages.css';

import MainPage from "../components/Main";
import LandingBlock from '../components/LandingBlock';
import LandingHeader from '../components/LandingHeader';
import LandingButtonDetailsRI from '../components/LandingButtonDetailsRI';

import LandingArrContentBlock from '../components/LandingArrContentBlock';
import LandingContentBlock from '../components/LandingContentBlock';

import {getBrandCentrPage} from '../api/getBrandCentrPage';

const BrandCenterPage = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
              const d = await getBrandCentrPage();
              setData(d);
  
              console.log("DanatePage get data list success. length="+d.length);
          } catch (error) {
              console.log("DanatePage get data list error="+error);
          }
        };
        fetchData();
      }, [])

    return (
        <>
        <MainPage>
            
        {data && data.map((item,index) => (
                <>
                {(item.content || item.img || item.cards) && <LandingContentBlock id={index} landItem={item} designType='default' />}
                {item.arrcontent && <LandingArrContentBlock id={index} arrItem={item} designType='default' />}
                </>
            ))}

        </MainPage>
        </>
  );
}

export default BrandCenterPage;