import * as React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

const useFlexBoxStyle = () => {
    return {
      width: '100%',
      padding: '16px',
      background: 'linear-gradient(0deg, rgba(0, 128, 255, 0.03) 0%, rgba(0, 128, 255, 0.1) 100%)',
    };
  };

const LandingFooter = () => {
  const boxStyle = useFlexBoxStyle();

  return (
    <Box className="footer" style={{bgcolor: 'darkblue'}}>
        <Box displey='flex' flexDirection='row' flexWrap='nowrap' pl='24px'>
            <Typography variant='h6' sx={{ whiteSpace: 'nowrap' }}>Oleksiy Dehtyarenko</Typography>
            <Typography variant='h7' sx={{ whiteSpace: 'nowrap' }}>phone: +38067-340-8272</Typography>   <br/>  
            <Typography variant='h7' sx={{ whiteSpace: 'nowrap' }}>email: advokatam@i.ua</Typography>
        </Box>
        <div style={{ width: '100%' }}></div>
        {/*<Typography variant='h7'>developed by Tyko:DevStudio</Typography>*/}
    </Box>
  );
};

export default LandingFooter;