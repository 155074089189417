
import { LOCALES } from '../../../i18n/locales';

export const getMyCherkasyBdRotarians = (local) => {
    //const cherkasyData = local === LOCALES.UKRAINIAN ? cherkasyData_ukr : cherkasyData_eng;
    return cherkasyData;
};

const cherkasyData = [
    {
        id: 0,
        header: 'Ротарійці України',
        content: [
            {LandingMessage: `Переглядайте або шукайте друзів та колег за допомогою одного або кількох фільтрів у списку.`},
        ],
    },
    {
        id: 1,
        LandingBdRotarians: `РКЧеркаси`,
    }
];

  