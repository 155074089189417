import React, { useContext, useEffect, useState } from "react";
import './MyNavbar';
import MyNavbar_TempShare from "./MyNavbar_TempShare";
import Footer from "./Footer";

import { IntlProvider } from 'react-intl';
import { LOCALES } from '../i18n/locales';
import { messages } from '../i18n/massages';
import {LocalContext} from '../tools/LanguageContextProvider';

import { useLocation } from 'react-router-dom';

import BetaMessage from '../components/BetaMessage';
import AutorizePage from '../pages/LoginAndSuccess/AutorizePage';
import {checkIsLoginedUser} from '../api/LoginAndSuccess/login'
import {getMyD2232PageMenuStructure_TempShare} from '../pages/myrotary/getMyD2232PageMenuStructure_TempShare';

const ga4 = 'G-5P2E208YMM';

const MYMain_TempShare = (props) => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [userProfile, setUserProfile] = useState(null);
    const location = useLocation();
    
    const [navbar, setNavbar] = useState(null);
    const [footer, setFooter] = useState(null);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = await checkIsLoginedUser();
                setUserProfile(user);
                
                await loadData(currentLocale);
            } catch (error) {
                console.log("DanatePage get data list error="+error);
            }
        };
        fetchData();
      }, [])

    useEffect(() => {
        if (true || process.env.NODE_ENV === 'production') {
            window.gtag('config', ga4, {
            page_path: location.pathname,
            });
        }
    }, [location]);

    const loadData = async(locale) => {
        const page = getMyD2232PageMenuStructure_TempShare(locale);
        setNavbar(page.navbar);
        setFooter(page.footer);

        console.log("DanatePage get data list success. const="+page.length);
    }

    const onLogined = (userProfile) => {
        setUserProfile(userProfile);
        console.log("============= onLogined="+(userProfile && userProfile.email));
    }

    const handleIntlError = (err) => {
        if (err.code === 'MISSING_TRANSLATION') {
            //console.warn('Missing translation', err.message);
        return;
        }
        throw err;
    };

    return (
       
        <IntlProvider messages={messages[currentLocale]} onError={handleIntlError}
            locale={currentLocale}
            defaultLocale={LOCALES.ENGLISH}
            onError={() => {}}>

            
            <MyNavbar_TempShare 
                navbar={navbar} 
                languages={["Укр"]} 
                //userProfile={userProfile} 
                //onLogined={onLogined} 
            />
            

            <div  style={{minHeight: 'calc(100vh - 150px)', background:'#f3f3f3'}}>
            <BetaMessage noWarningMode={props.noWarningMode} />
            {/*userProfile 
                ? props.children
                : <AutorizePage onLogined={onLogined} />*/
            }
            {props.children}
            </div>
            <Footer />
          
      
        </IntlProvider>

  );
}

export default MYMain_TempShare;