import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import axios from 'axios';

const SERVERURL = 'https://apifs.rotaryd2232ukraine.org/storage';

const FileUpload = ({ path, handleResult }) => {
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

//   const uploadFile = async () => {
//     const token = localStorage.getItem('tokenFS');
//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('path', path);

//     try {
//       await axios.post(`${SERVERURL}/upload`, formData, {
//         headers: {
//           'Storage-Token': token,
//           'Content-Type': 'multipart/form-data'
//         }
//       });
//       handleResult(path); // Refresh the list
//     } catch (error) {
//       console.error('Failed to upload file:', error);
//     }
//   };
    const handleUpload = async () => {
        const token = localStorage.getItem('tokenFS');
        console.log(`handleUpload token=${token}`);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('path', path);

        console.log(`handleUpload filename=${file.name}`);
        console.log(`handleUpload path=${path}`);

        try {
            const response = await axios.post(`${SERVERURL}/upload`, formData, {
                headers: {
                'Storage-Token': token,
                'Content-Type': 'multipart/form-data'
                }
            });
            console.log('File uploaded successfully!');
            handleResult(true); // Refresh the list
        } catch (error) {
            console.error('File upload failed!', error);
        }
    };

    return (
        <Box display="flex" alignItems="center" mb={2}>
        <input type="file" onChange={handleFileChange} />
        <Button variant="contained" onClick={handleUpload} disabled={!file}>Upload File</Button>
        </Box>
    );
};

export default FileUpload;
