import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { injectIntl, FormattedMessage } from 'react-intl';  // якщо ви використовуєте react-intl

const LandingMessage3 = ({ id, intl, noCollapse }) => {

    return (
        <span className='landing_text' style={{color: 'red', fontWeight: 'bold', display: 'inline-block'}}>
            {id }&nbsp;
        </span>
    );
};

export default LandingMessage3;
