
export const getUserPagesContent = (local) => {
    return data_eng;
}

const data_eng = [
    {
        id: 1,
        header: 'Club Officers',
        LandingBdRotarians: 'rec_ukraine',
    }
    // {
    //     id: 3,
    //     header: 'Club Members:',
    //     mheader: 'Club Members',
    //     murl: 'officers',
    //     LandingCardsRotarians_eclub: [
    //         {CardsTitle: 'Club Officers', 
    //             subcards: [
    //                 {
    //                     rotarianId: 104,
    //                 },
    //                 {
    //                     rotarianId: 98,
    //                 },
    //                 {
    //                     rotarianId: 90,
    //                 },
    //                 {
    //                     rotarianId: 100,
    //                 },
    //                 {
    //                     rotarianId: 102,
    //                 },
    //             ]
    //         },
    //         {CardsTitle: 'Club Members',
    //             subcards: [
    //                 {
    //                     rotarianId: 91, 
    //                 },
    //                 {
    //                     rotarianId: 92, 
    //                 },
    //                 {
    //                     rotarianId: 105, 
    //                 },
    //                 {
    //                     rotarianId: 108, 
    //                 },
    //             ]
    //         },
    //         {CardsTitle: 'Club Members of Honor',
    //             subcards: [
    //             {
    //                 rotarianId: 99,
    //             },
    //             {
    //                 rotarianId: 106, 
    //             },
    //             ]
    //         },
    //     ]
    // }

];

