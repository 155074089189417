

export const getICC = () => {
    return defaultData;
};

const defaultData = [
    {
      "icc": "ICC Ukraine-Poland",
      "name": "Yuriy Basarab",
      "rc": "RC Lviv"
    },
    {
      "icc": "ICC Ukraine-Germany",
      "name": "Borys Bodnar",
      "rc": "PRC Ukraine Unity"
    },
    {
      "icc": "ICC Ukraine-Czech & Slovakia",
      "name": "Tetyana Kachala",
      "rc": "RC Uzhgorod Skala"
    },
    {
      "icc": "ICC Ukraine-Hungary",
      "name": "Robert Rojak",
      "rc": "RC Uzhgorod"
    },
    {
      "icc": "ICC Ukraine-Georgia",
      "name": "Nataliya Teslenko",
      "rc": "RC Kharkiv-Multinational"
    },
    {
      "icc": "ICC Ukraine-Romania",
      "name": "Yuriy Fedak",
      "rc": "RC Rakhiv – Center of Europe"
    },
    {
      "icc": "ICC Ukraine-France",
      "name": "Volodymyr Pylypenko",
      "rc": "RC Kyiv-Balzak"
    },
    {
      "icc": "ICC Ukraine-Cyprus",
      "name": "Yuriy Titovets",
      "rc": "RC Lviv-Centre"
    },
    {
      "icc": "ICC Ukraine-Bulgaria",
      "name": "Volodymyr Sara",
      "rc": "RC Izmail"
    },
    {
      "icc": "ICC Ukraine-Belgium & Luxembourg",
      "name": "Julia Lefter",
      "rc": "RC Kyiv-Balzak"
    },
    {
      "icc": "ICC Ukraine-Israel",
      "name": "Nataliya Novikova",
      "rc": "RC Kyiv - City"
    },
    {
      "icc": "ICC Ukraine-Kroatia",
      "name": "Volodymyr Orlyk",
      "rc": "RC Lviv"
    },
    {
      "icc": "ICC Ukraine-Lithuania",
      "name": "Olga Zmiyivska",
      "rc": "RC Kharkiv-Multinational"
    },
    {
      "icc": "ICC Ukraine-Armenia",
      "name": "Olena Sopina",
      "rc": "RC Zaporizhzhya - Khortytsya"
    },
    {
      "icc": "ICC Ukraine-Moldova",
      "name": "Ihor Babayan",
      "rc": "RC Izmail"
    },
    {
      "icc": "ICC Ukraine-Turkey",
      "name": "Lars Vestbierg",
      "rc": "RC Lviv-International"
    },
    {
      "icc": "ICC Ukraine - USA",
      "name": "Irina Timanyuk",
      "rc": "RC Kharkiv Multinational"
    },
    {
      "icc": "ICC Ukraine - Austria",
      "name": "Alfred Praus",
      "rc": "RC Kyiv International Business"
    },
    {
      "icc": "ICC Ukraine - Great Britain&Ireland",
      "name": "Maryna Dolzhenko",
      "rc": "RC Kyiv Synergy"
    },
    {
      "icc": "ICC Ukraine - Latvia",
      "name": "Iryna Odyntsova",
      "rc": "RC Dnipro Novyi"
    },
    {
      "icc": "ICC Ukraine - Italy Malta San Marino",
      "name": "Gianluka Sardelli",
      "rc": "RC Lviv Centre"
    },
    {
      "icc": "ICC Ukraine - Spain",
      "name": "Dmytro Popov",
      "rc": "RC Kharkiv Multinational"
    },
    {
      "icc": "ICC Ukraine - Portugal",
      "name": "Iryna Odyntsova",
      "rc": "RC Dnipro Novyi"
    },
    {
      "icc": "ICC Ukraine - Netherlands",
      "name": "",
      "rc": ""
    },
    {
      "icc": "ICC Ukraine - Swiss",
      "name": "",
      "rc": ""
    },
    {
      "icc": "ICC Ukraine - Canada",
      "name": "",
      "rc": ""
    }
  ]