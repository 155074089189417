
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { MdEmail } from 'react-icons/md';
import {handleCopyToClipboard} from '../../../modules/CRUD_system/functions/toolsFuncs';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

const LcButtonFB = ({url,size}) => {

    return (
        <Tooltip title={'Facebook'}>
        <IconButton onClick={() => window.open(url, '_blank')} aria-label="Facebook">
            <FaFacebookF color="white" fontSize={size} />
        </IconButton>
        </Tooltip>
    );
}

export default LcButtonFB;