import axios from 'axios';

//const SERVER_URL = "http://localhost:3001/api/news";
const SERVER_URL = 'https://fathomless-inlet-58403-132cf80acea5.herokuapp.com/api/communique';

export const getCommuniqueData = () => {
    return axios.get(SERVER_URL);
}

export const addCommuniqueData = (item) => {
    
    return axios.post(SERVER_URL, item);
}

export const updateCommuniqueData = (itemId, item) => {
    return axios.put(SERVER_URL+itemId, item);
}
