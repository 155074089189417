import axios from 'axios';

//const BASE_URL = 'http://localhost:3001/apibd';
const BASE_URL = 'https://apitest.rotaryd2232ukraine.org/apibd';

export const fetchEvents = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/events`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchEvent = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/event/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createEvent = async (eventData) => {
    console.log('=== createEvent...');
    try {
        const response = await axios.post(`${BASE_URL}/event`, eventData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateEvent = async (eventId, eventData) => {
    console.log('=== updateEvent...');
    try {
        console.log('=== updateEvent url'+`${BASE_URL}/event/${eventId}`);
        const response = await axios.put(`${BASE_URL}/event/${eventId}`, eventData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteEvent = async (eventId) => {
    try {
        await axios.delete(`${BASE_URL}/event/${eventId}`);
    } catch (error) {
        throw error;
    }
};
