import React, { useEffect, useState } from "react";

import MainPage from "../components/Main";
import EventsFrame from "./EventsFrame";

import WordTypePage_TempShare from '../components/WordTypePage_TempShare';

const EventsPage_TempShare = () => {
    return (
        <WordTypePage_TempShare>
            <EventsFrame />
        </WordTypePage_TempShare>
  );
}

export default EventsPage_TempShare;