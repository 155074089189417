import { LOCALES } from '../../../i18n/locales';

export const getItCommitteeMenu = (local) => {

    const translations_ukr = {
        "Сторінка ІТ Комітету": "Сторінка ІТ Комітету",
        "Про Клуб": "Про Клуб",
        "Події": "Події",
        "Календар": "Календар",
        "Фотогалерея": "Фотогалерея",
        "Контактна особа:": "Контактна особа:",
        "Проєкти": "Проєкти",
        "Ротаракт": "Ротаракт",
        "Співпрацюючі організації": "Співпрацюючі організації",

        "Головна": "Головна",
        "Керівництво": "Керівництво",
        "Правління та посадові особи": "Правління та посадові особи",
        "Дистрикт 2232": "Дистрикт 2232",
    };

    const translations_eng = {
        "Сторінка ІТ Комітету": "Committee IT Page",
        "Про Клуб": "About the Club",
        "Події": "Events",
        "Календар": "Calendar",
        "Фотогалерея": "Photo Gallery",
        "Контактна особа:": "Primary contact:",
        "Проєкти": "Projects",
        "Ротаракт": "Rotaract",
        "Співпрацюючі організації": "Cooperating organizations",

        "Головна": "Home",
        "Керівництво": "Leadership",
        "Правління та посадові особи": "Board & Officers",
        "Дистрикт 2232": "District 2232",
    };

    const translations = local === LOCALES.UKRAINIAN ? translations_ukr : translations_eng;

    return {
        navbar: {
            title: translations["Сторінка ІТ Комітету"],
            logoUrl: "https://res.cloudinary.com/tyko/image/upload/v1704634818/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/D2232_hpvpzq.png",
            languages: [
                "ua-UA",
                "en-US",
            ],
            menu: [
                {
                    title: translations["Головна"],
                    lref: '/itcommittee',
                },
                {
                    title: translations["Проєкти"],
                    lref: '/itcommittee/projects',
                },
            ],
        },
        footer: {
            contactPerson_: {
                name: translations["Контактна особа:"],
                // email: "oncology@2upost.com",
                // email2: "paliychuk.oncology@gmail.com",
            },
            social: {
                // fb: "fb1",
                // insta: "insta1",
                // linkedIn: "linkedIn1",
            }
        }
    }
}
