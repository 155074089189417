
export const getPagesContent = (local) => {
    return data_eng;
}

const data_eng = [
    {
        id: 0,
        header: 'Foto collage',
        arrcontent: [
            {
                PhotoCarusel: [
                    'https://api.rotary.org.ua/uploads/clubsitecreator/template_photo/defailt_image2.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/template_photo/defailt_image2.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/template_photo/defailt_image2.jpg'
                ],
            },
        ],
    },
    {
        id: 0,
        img: "https://api.rotary.org.ua/uploads/clubsitecreator/template_photo/defailt_image2.jpg",
        header: 'Title',
        content: [
            {LandingMessage: `very brief info about the club`},
        ],
    },
    {
        id: 1,
        header: 'Numbers',
        content: [
            {LandingMessage: `Numbers box`},
        ],
    },
    {
        id: 1,
        header: 'Together to our victory and beyond',
        content: [
            {LandingMessage: `round blocs with project categories or best projects loke on rotary.org`},
            {LandingMessage: `round blocs with project categories or best projects loke on rotary.org`},
            {LandingMessage: `round blocs with project categories or best projects loke on rotary.org`},
        ],
    },
];

