import * as React from 'react';

import { Box, Typography, Container } from '@mui/material';

const useFlexBoxStyle = () => {
    return {
      width: '100%',
      padding: '16px',
      background: 'linear-gradient(0deg, rgba(0, 128, 255, 0.03) 0%, rgba(0, 128, 255, 0.1) 100%)',
    };
  };

  const useFlexBoxStyle_simple2 = () => {
    return {
      width: '100%',
      padding: '16px',
      background: '#019fcb',
      color: 'white',
    };
  };

  const useFlexBoxStyle_chicago = () => {
    return {
      width: '100%',
      padding: '16px',
      _background: '#019fcb',
      _color: 'white',
    };
  };

const LandingHeader = (props) => {
    const {designType} = props;
  const boxStyle = useFlexBoxStyle();
  const boxStyle_simple2 = useFlexBoxStyle_simple2();

  return (
    <Box sx={designType==='simple2' ? boxStyle_simple2 : designType==='chicago' ? useFlexBoxStyle_chicago : boxStyle} {...props}>
        <Typography variant="h4" className="page-header" style={{ fontWeight: 'bold' }} >{props.children}</Typography>
    </Box>
  );
};

export default LandingHeader;