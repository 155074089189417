export const getInteractClubs = () => {
    return defaultData;
};

const defaultData = [
    {
      "ID": 63617,
      "Status": "Active",
      "District": "Cherkasy-Centre",
      "City": "Cherkasy-Centre",
      "ActiveStatus":"Active",
      "Name": "Denys Andrushchenko",
      "Email": "denys.andrushchenko@gmail.com"
    },
    {
      "ID": 225806,
      "Status": "Active",
      "District": "Dnipro-Baltic",
      "City": "Dnipro City",
      "ActiveStatus": "Active",
      "Name": "Andrii Atlantov",
      "Email": "amenhotepov@gmail.com"
    },
    {
      "ID": 225806,
      "Status": "Active",
      "District": "Dnipro-Baltic",
      "City": "Dnipro Novyi",
      "ActiveStatus": "Active",
      "Name": "Igor Clarke",
      "Email": "igorclarke69@gmail.com"
    },
    {
      "ID": 88987,
      "Status": "Active",
      "District": "Kyiv Sch. 155",
      "City": "Kyiv Multinational",
      "ActiveStatus": "Active",
      "Name": "Anatolii Osadchyi",
      "Email": "osadchy.am@gmail.com"
    },
    {
      "ID": 218106,
      "Status": "Active",
      "District": "Rakhiv-Center of Europe",
      "City": "Rakhiv-Center of Europe",
      "ActiveStatus": "Active",
      "Name": "Vasyl Dumyn",
      "Email": "dumin.loci@gmail.com"
    },
    {
      "ID": 219514,
      "Status": "Active",
      "District": "Uzhgorod Royal",
      "City": "Uzhgorod",
      "ActiveStatus": "Active",
      "Name": "Tetiana Andrus",
      "Email": "tanyaandrus10@gmail.com"
    },
    {
      "ID": 223738,
      "Status": "Active",
      "District": "Zaporizhzhia-Khortytsia",
      "City": "Zaporizhzhia-Khortytsia",
      "ActiveStatus": "Active",
      "Name": "Olena Sopina",
      "Email": "es7893109@gmail.com"
    }
  ]
  