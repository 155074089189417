import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import axios from 'axios';

import {fsCreateFolder, fsRenameItem, fsDeleteItem} from './fstools';

const FileActions = ({ path, oldName, newFolder, onPathChange }) => {
    const [newFolderName, setNewFolderName] = useState(newFolder);
    const [newName, setNewName] = useState(oldName);

    const createFolder = async () => {
        if (await fsCreateFolder(path, newFolderName)) {
            onPathChange(path); // Refresh the list
        } 
    };

    const renameItem = async () => {
        if (await fsRenameItem(path, oldName, newName)) {
            onPathChange(path); // Refresh the list
        } 
    };

    const deleteItem = async () => {
        if (await fsDeleteItem(path, oldName)) {
            onPathChange(path); // Refresh the list
        } 
    };

    return (
        <Box>

        {newFolder &&
        <Box display="flex" alignItems="center" mb={2}>
            <TextField
                key={'FileActions1'}
                label="New Folder Name"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
            />
            <Button variant="contained" onClick={createFolder}>Create Folder</Button>
        </Box>}

        {oldName && 
        <Box display="flex" alignItems="center" mb={2}>
            <>oldName='{oldName}'</>
            <TextField
                key={'FileActions3'}
            label="New Name"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            />
            <Button variant="contained" onClick={renameItem}>Rename</Button>
        </Box>}

        {/* {oldName && 
        <Box display="flex" alignItems="center" mb={2}>
            <>Name='{oldName}'</>
            <Button variant="contained" onClick={deleteItem}>Delete</Button>
        </Box>} */}
        
        </Box>
    );
};

export default FileActions;
