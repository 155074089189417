import React from "react";
import { Box } from '@mui/material';
import LandingStatisticsItem from './LandingStatisticsItem';

const LandingStatisticsBlock = ({ landItem }) => {
    return (
        <Box 
            sx={{ 
                backgroundImage: `url('https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/home-concrete-background.jpg')`, 
                backgroundAttachment: 'fixed', 
                backgroundSize: 'cover',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '20px',
                padding: '20px',
            }}
        >
            {landItem && landItem.map((item, index) => (
                <Box 
                    key={index} 
                    sx={{
                        flex: '1 1 auto', // Кожен елемент займає рівну ширину
                        padding: '10px',
                        maxWidth: '300px'
                    }}
                >
                    <LandingStatisticsItem key={index} item={item} />
                </Box>
            ))}
        </Box>


            
    );
}

export default LandingStatisticsBlock;
/* {landItem && landItem.map((item, index) => (
                <LandingStatisticsItem key={index} item={item} />
            ))}
        </Box> */