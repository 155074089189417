
import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from '@mui/material'

import MyMainPage from "../../modules/MYMain";
import LandingBlock from '../../components/LandingBlock';

const LettersCommitteeChairsPage = () => {

    useEffect(() => {
      const fetchData = async () => {
        try {
         
        } catch (error) {
          console.log("LettersCommitteeChairsPage get list error="+error);
        }
      };
      fetchData();
    }, [])

    return (
        <>
          <MyMainPage>
            <LandingBlock mode='light'>
            <Box className="section text-left" >
            <Container>
                
            <Typography variant="h6">Листи Голів Комітетів</Typography>

            </Container>
            </Box>
            </LandingBlock>
          </MyMainPage>
        </>
  );
}

export default LettersCommitteeChairsPage;