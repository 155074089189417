import React, { useEffect, useState, useContext, useRef } from "react";

import { Box } from '@mui/system';

import MuiBreadcrumbs from '../../../modules/MuiBreadcrumbs';
import {getMyClubPageStructure} from './getMyClubPageStructure';
import {LocalContext} from '../../../tools/LanguageContextProvider';
import TykoLanding from '../components/TykoLanding';

const MyRcCherkasyMain = ({children}) => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [navbar, setNavbar] = useState(null);
    const [footer, setFooter] = useState(null);

    const  designType='chicago';

    useEffect(() => {
      const fetchData = async () => {
        try {
            await loadData(currentLocale);
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale])

    const loadData = async(locale) => {
        const page = getMyClubPageStructure(locale);
        setNavbar(page.navbar);
        setFooter(page.footer);

        console.log("DanatePage get data list success. const="+page.length);
    }

    return (
        <>
          {navbar ? (
            <TykoLanding navbar={navbar} noWarningMode="hide" designType={designType} footer={footer} languages={["Укр"]} >
              {/* props.breadcrumbs && <MuiBreadcrumbs struct1={null} titleLast={props.breadcrumbs}/> */}
              
            {children}
            </TykoLanding>
          ) : (
            "Loading..."
          )}
        </>
      );
      
      
}

export default MyRcCherkasyMain;