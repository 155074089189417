
import React, { useEffect, useState, useContext, useRef  } from "react";
import { useParams } from 'react-router-dom';
import { LOCALES } from '../../../i18n/locales';
import { Container, Box, Typography, Avatar, Tab, Tabs, Paper } from '@mui/material';
import LandingProjectPage_eclub from './LandingProjectPage_eclub.jsx';
import { fetchProjects } from '../rotariansBD/api/api_projects';
import LandingBlock from '../../../components/LandingBlock';

const LandingProjectPageById = ({prjId, designType}) => {
    const [data, setData] = useState([]);   
    const [item, setItem] = useState([]);  
    
    useEffect(() => {
        const fetchData = async () => {
        try {
            await loadData();
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
        };
        fetchData();
    }, [])

    const loadData = async() => {
        const d = await fetchProjects();//getRProjects(locale);
        
        //const filteredData = d.filter(item => item.tags === 'rec_ukraine');
        setData( d );

        const item = d && d.find(item => item.project_id === Number(prjId));
        setItem(item);

        console.log("DanatePage get data list success. const="+d.length);
    }

    const isEvenId = false;
    let mode;
    switch (designType) {
        case 'business':
            mode = isEvenId ? "light2" : "dark2";
            break;
        case 'chicago':
            mode = isEvenId ? "light3" : "dark3";
            break;
        case 'simple':
        case 'simple2':
            mode = isEvenId ? "light5" : "dark3";
            break;
        default:
            mode = isEvenId ? "light2" : "light";
    }

    //
    return (
        <LandingBlock mode={mode}>
            <Box className="section text-left">
                <Container>
        {item && 
                <LandingProjectPage_eclub
                    proj={item} 
                    designType={designType} 
                />
        } 
        </Container>
            </Box>
        </LandingBlock>
    )
}

export default LandingProjectPageById;