
import React, { useEffect, useState, useContext, useRef  } from "react";
import { useParams } from 'react-router-dom';
import { LOCALES } from '../../../../i18n/locales';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Box, Typography, Container, Link } from '@mui/material';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button } from '@mui/material';
import { MdEmail } from 'react-icons/md';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import {getRProjects} from '../../R_ProjectsShowcase/getRProjects';
import {LocalContext} from '../../../../tools/LanguageContextProvider';
import LandingFilters from '../../fixcomponents/LandingFilters';
import LandingContentItems from '../../../../components/LandingContentItems';
import LandingHeader from '../../../../components/LandingHeader';
import LAccordionItem from '../../../../components/LAccordionItem';
import LandingCards2List from '../../../../components/LandingCards2List';
import LcButtonPhone from '../LcButtonPhone';
import LcButtonEmail from '../LcButtonEmail';
import LcButtonFB from '../LcButtonFB';
import LcButtonYoutube from '../LcButtonYoutube';
import LcButtonPdf from '../LcButtonPdf';
import LandingRotarianCard from '../LandingRotarianCard';
import SubcardItemComponent from './SubcardItemComponent';

import { fetchUsers } from '../../rotariansBD/api/api_users';
import { fetchUserSkils } from '../../rotariansBD/api/api_user_skils';

const LandingSubcardsRotarians_eclub = ({cards, tags, ispublic, isemailpublic}) => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [selectedProj, setSelectedProj] = useState(-1);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

    const [cardView, setCardView] = useState(0);   
    const [userSkils, setUserSkils] = useState([]);
    

    const cardViewChanged = (v) => {
        setCardView(v);
    }

    function limitString(inputString) {
        const maxChars = 100;
        const maxLines = 6;
      
        // Розділити рядок на масив рядків
        const lines = inputString.split('\n');
      
        let result = '';
      
        for (let i = 0; i < lines.length && i < maxLines; i++) {
          // Змінити поточний рядок, щоб він не перевищував максимальну кількість символів
          const currentLine = lines[i].substring(0, maxChars);
      
          // Додати поточний рядок до результату
          result += currentLine;
      
          // Якщо це не останній рядок і є ще рядки, додайте символ переносу рядка
          if (i < lines.length - 1 && i < maxLines - 1) {
            result += '\n';
          }
        }
      
        return result;
      }
      
    const showCards = (cards) => {
        return (
            <>

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}  gap={2} alignItems="top" paddingTop='2em' flexWrap='wrap'>
            {cards && cards.map((card, index) => (
                <>
                { card.rotarianId &&
                    <>
                    <SubcardItemComponent card={card} index={index} tags={tags} handleRowClick={handleRowClick} currentLocale={currentLocale} ispublic={ispublic} isemailpublic={isemailpublic} />
                    </>
                }
                { card.CardsTitle && 
                <div style={{width: '100%', border: '1px solid #019fcb', borderRadius: '4px'}}>
                    <div style={{backgroundColor: '#019fcb', color: 'white', minHeight: '40px', padding: '4px'}}>
                    
                    <Typography variant="h5" >{card.CardsTitle}</Typography>
                    </div>
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}  gap={1} alignItems="top" paddingTop='2em' margin='8px' flexWrap='wrap'>
                    {card.subcards && card.subcards.map((subcard, index) => (
                        <>
                        
                        <SubcardItemComponent card={subcard} user_drole={subcard.user_drole} index={index} tags={tags} handleRowClick={handleRowClick} currentLocale={currentLocale} ispublic={ispublic} isemailpublic={isemailpublic} />
                        </>
                    ))}
                    </Box>
                </div>}
                </>
            ))}
            </Box>
            </>
        )
    }



    const showTable = (cards) => {
        return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{fontSize: '1vw'}}>Photo</TableCell>
            <TableCell sx={{fontSize: '1vw'}}></TableCell>
            <TableCell sx={{fontSize: '1vw'}}>Club</TableCell>
            <TableCell sx={{fontSize: '1vw'}}>Position</TableCell>
            {(!ispublic || isemailpublic) && <TableCell sx={{fontSize: '1vw'}}>Contact</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {cards.map((card, index) => (
            (
                <TableRow
                    key={index}
                >

                <TableCell 
                    onClick={() => handleRowClick(index)}
                    onMouseEnter={() => setHoveredRowIndex(index)}
                    onMouseLeave={() => setHoveredRowIndex(null)}
                    style={getRowStyle(index)}
                    sx={{fontSize: '1vw'}}
                >
                    <img src={card.avatar_url} alt="" style={{ maxWidth: '100px' }} />
                </TableCell>

                <TableCell>
                {card.first_name} {card.last_name} 
                </TableCell>
                
                <TableCell sx={{fontSize: '1vw'}}>
                    {currentLocale===LOCALES.UKRAINIAN ? 
                        <>Клуб: {card.club_type && card.club_type==="RAC" ? <>РАК</> : card.club_type==="PRC" ? <>ПРК</> : card.club_type==="ReC" ? <>РеК</> : <>РК</>} {card.club_name_ukr}</> 
                        :
                        <>Club: {card.club_type && card.club_type==="RAC" ? <>RAC</> : card.club_type==="PRC" ? <>PRC</> : card.club_type==="ReC" ? <>ReC</> : <>RC</>} {card.club_name_ukr}</> 
                    }
                </TableCell>

            

                <TableCell>
                    
                    {card.other_positions && limitString(card.other_positions)}
                  
                </TableCell>
                {(!ispublic || isemailpublic) &&
                <TableCell>
                    {(!ispublic) && card.primarycontact}
                    <br/>
                    {(!ispublic || isemailpublic) && card.email && (
                        <LcButtonEmail url={card.email} size='default' />
                    )}
                    {(!ispublic) && card.phone && (
                        <LcButtonPhone url={card.phone} size='small'/>
                    )}
                    {(!ispublic) && card.youtube && (
                        <LcButtonYoutube url={card.youtube} size='default' />
                    )}
                    {(!ispublic) && card.fb && (
                        <LcButtonFB url={card.fb} size='default' />
                    )}
                </TableCell>
                }
              </TableRow>
            )
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  
  )
}


    const showList = (cards) => {
        return (
            <>
            <LandingCards2List cards={cards} ispublic />
            </>
        )
    }
    const handleRowClick = (index) => {
        setUserSkils(cards[index].skils)
        setSelectedProj(index);
    };
    const handleBack = () => {
        setSelectedProj(-1);
    }
    const getRowStyle = (index) => {
        if (selectedProj === index) {
          return { backgroundColor: '#f5f5f5' };
        } else if (hoveredRowIndex === index) {
          return { backgroundColor: '#e0e0e077' }; // Світліший колір при наведенні
        }
        return {};
      };


    return (
        <>
            
        {selectedProj>-1 ?
            <LandingRotarianCard people={cards[selectedProj]} handleBack={handleBack} userSkils={userSkils} ispublic={ispublic}/>
            :
        <>
            <LandingHeader designType={'simple2'} >
                <Typography variant="h4" className="page-header">
                    Офіцери Дистрикту 2232:
                </Typography>
            </LandingHeader>
                
            {/*
            <Box display="flex" flexDirection="row" pt='8px'>
                <Box display="flex" flexDirection="row" whiteSpace="nowrap" pr='8px'>
                    <Box whiteSpace="nowrap" pr='8px'>Show as: </Box>
                    <Button variant='contained' onClick={() => cardViewChanged(0)}>cards</Button>
                    <Button variant='contained' onClick={() => cardViewChanged(2)} >table</Button>
                </Box>
                <Box width="100%">
                </Box>
            </Box>
            */}
            {showCards(cards)}
            {/* {cards &&
                cardView === 1 ? (
                    showList(cards)
                ) : cardView === 2 ? (
                    showTable(cards)
                ) : (
                    cardView === 0 && showCards(cards)
                )
            } */}

            {//<LandingContent data={data} currentLocale={currentLocale} designType={designType} />
            }
        </>}
        </>
  );
}

export default LandingSubcardsRotarians_eclub;