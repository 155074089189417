export const getEventBusinesCouncil = () => {
    return data;
}

const data = [
    // {
    //     date1: 'date1',
    //     title: 'title',
    //     text: `text`,
    //     imgs: [],
    // },
    {
        date1: '18 травня 2024',
        title: 'Ротарійський діловий форум молодіжного підприємництва',
        text: `text`,
        imgs: [
            'https://api.rotary.org.ua/uploads/clubsitecreator/committee_businesscouncil/events/28.04.2024/afisha.jpg'
        ],
    },
    {
        date1: '28 квітня 2024',
        title: 'Lviv Craft Fest',
        text: `28 квітня 2024 року Lviv Craft Fest об’єднав понад 60 крафтовиків зі всієї країни та Ротарійців і Ротарактівців Львова, Києва й Харкова

        Команда Ділової Ради Ротарі Інтернешнл Д 2232 спільно з Агенцією регіонального розвитку Львівщини, Департаментом агропромислового розвитку ЛОВА, івент-агенцією “So Good Company” провели на вихідних Lviv Craft Fest.
        
        Це була чудова можливість поспілкуватися з виробниками крафтової продукції та популяризувати власне виробництво серед потенційних споживачів й інвесторів.
        
        На крафтовому ярмарку було представлено сири, ковбаси, м’ясні вироби, сіль, спеції, різноманітні соуси, хліб та хлібобулочні вироби, солодощі, мед, чаї, комбучу, керамічні вироби, прикраси, одяг. Практично кожен локальний виробник пропонував відвідувачам продегустувати свою продукцію та обрати для себе й своєї родини не лише найкраще до великодніх свят, а й те, що найбільше до смаку.
        
        Про наявні програми підтримки крафтового бізнесу та проблеми, з якими зіштовхуються крафтяри, спілкувалися під час конференції «Можливості в часи кризи». Учасники заходу отримали актуальну інформацію про гранти від діючих європейських та американських донорів, а також можливості, спрямовані на підтримку локального виробника від департаментів агропромислового розвитку та економічної політики ЛОВА.
        
        Під час фестивалю проводилися різноманітні майстер-класи: писанкарства, виготовлення ляльки-мотанки, валяної писанки та солом’яної іграшки. Зокрема один із них був проведений за ініціативи Ротарактівців Львова – майстер-клас із розпису великодніх писанок. Відома на Львівщині майстриня Христина Петришин, яка займається писанкарством понад півстоліття, поділилася з членами Ротаракт Клубу Львів та Ротаракт Клубу Львів Інтернешнл секретами виготовлення й розпису великодньої писанки. Всі учасники майстер-класу виготовили власні писанки Миру й Перемоги до світлого свята Великодня.
        
        Lviv Craft Fest відбувся за підтримки фінансованого Урядом Німеччини проєкту Представництва Міжнародної організації з міграції (МОМ) в Україні.`,
        imgs: [
            'https://api.rotary.org.ua/uploads/clubsitecreator/committee_businesscouncil/events/28.04.2024/photo/photo_10_2024-04-30_15-41-27.jpg',
            'https://api.rotary.org.ua/uploads/clubsitecreator/committee_businesscouncil/events/28.04.2024/photo/photo_1_2024-04-30_15-41-27.jpg',
            'https://api.rotary.org.ua/uploads/clubsitecreator/committee_businesscouncil/events/28.04.2024/photo/photo_2_2024-04-30_15-41-27.jpg',
            'https://api.rotary.org.ua/uploads/clubsitecreator/committee_businesscouncil/events/28.04.2024/photo/photo_3_2024-04-30_15-41-27.jpg',
            'https://api.rotary.org.ua/uploads/clubsitecreator/committee_businesscouncil/events/28.04.2024/photo/photo_4_2024-04-30_15-41-27.jpg',
            'https://api.rotary.org.ua/uploads/clubsitecreator/committee_businesscouncil/events/28.04.2024/photo/photo_5_2024-04-30_15-41-27.jpg',
            'https://api.rotary.org.ua/uploads/clubsitecreator/committee_businesscouncil/events/28.04.2024/photo/photo_6_2024-04-30_15-41-27.jpg',
            'https://api.rotary.org.ua/uploads/clubsitecreator/committee_businesscouncil/events/28.04.2024/photo/photo_7_2024-04-30_15-41-27.jpg',
            'https://api.rotary.org.ua/uploads/clubsitecreator/committee_businesscouncil/events/28.04.2024/photo/photo_8_2024-04-30_15-41-27.jpg',
            'https://api.rotary.org.ua/uploads/clubsitecreator/committee_businesscouncil/events/28.04.2024/photo/photo_9_2024-04-30_15-41-27.jpg',
        ],
        docs: [
            {
                title: 'Програма форуму',
                url: 'https://api.rotary.org.ua/uploads/clubsitecreator/committee_businesscouncil/events/28.04.2024/LVIV_CRAFT_FEST_program.pdf',
            },
        ]
    },
    {
        date1: '18 травня 2024',
        title: 'Ротарійський діловий форум молодіжного підприємництва',
        //text: `text`,
        //img: 'https://api.rotary.org.ua/uploads/clubsitecreator/committee_businesscouncil/events/28.04.2024/afisha.jpg',
        docs: [
            {
                title: 'Програма форуму',
                url: 'https://api.rotary.org.ua/uploads/clubsitecreator/committee_businesscouncil/events/28.04.2024/program.pdf',
            },
            {
                title: 'Афіша форуму',
                url: 'https://api.rotary.org.ua/uploads/clubsitecreator/committee_businesscouncil/events/28.04.2024/afisha.jpg',
            }
        ]
    },
    // {
    //     date1: 'date1',
    //     title: '25 січня 2024 року команда Ділової ради спільно з партнерами ПАТ Кредобанк і БФ «Фундація Кредо» провели Ротарійський бізнес-сніданок на тему: «Маркетингові стратегії в умовах війни»',
    //     text: `Правильний бізнес-сніданок у найкрасивішій локації одного з відділень KredoBank у Львові дозволив об’єднати цікаві ідеї, креативні підходи, правильних людей та дружні інституції довкола теми підтримки бізнесу, інноваційних розробок, грантових програм, маркетингу та переваг банківського сервісу Craft Banking.
    //     Спілкувалися про програми підтримки бізнесу від ПАТ «Кредобанк», інноваційні інструменти, маркетингові підходи та особливості введення бізнесу в такий нелегкий для кожного українського підприємця.
    //     До заходу долучилися понад 20 Ротарійців і Ротарактівців. Кожен із учасників поділився власним досвідом запуску маркетологічної стратегії свого бізнесу. Було озвучено чимало проблем й окреслено шляхи їх вирішення.
    //     Приємно, що наші заходи стають цікавими для Ротарійців з різних регіонів і цього разу в нас були представниці Ротарійської родини з Луцька: Єлена Абрамович та Юлія Гринчук, які спеціально приїхали взяти участь у Ротарійському бізнес-сніданку. Це без сумніву додає впевненості у тому, що наша команда робить правильні й потрібні заходи.
    //     Хочемо висловити слова вдячності нашим партнерам – представникам Кредобанку, зокрема Оксані Стецьків, виконавчій директорці ПАТ «Кредобанк», Андрію Мельнику, керівнику маркетингу Кредобанку, Соломії Говорусі, голові БФ «Фундація Кредо», за теплий прийом, цікаві доповіді, смачне частування, красиву й головне безпечну локацію.
    //     Вже традиційно до наших заходів долучається команда Департаменту економічної політики Львівської обласної військової адміністрації. Цього разу Орест Гринів, заступник директора департаменту-начальник управління промисловості, підприємництва та інвестиційної політики, проінформував учасників бізнес-сніданку про програми підтримки бізнесу, актуальні в 2024 році.`,
    //     imgs:  [
    //         'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Event_Pets/1707316214742-907375035.jpg',
    //         'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Event_Pets/1707316214743-655588866.jpg',
    //         'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Event_Pets/1707316214744-561323971.jpg',
    //     ],
    // },
]