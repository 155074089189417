import React, { useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography, RadioGroup, Radio, FormControl, FormLabel } from '@mui/material';
import QRGenerator from './QRGenerator';

const defaultLogo = "https://api.rotary.org.ua/uploads/clubsitecreator/qrcode_generator/img/qrlogo4.png";
const QRTEMPLATE1 = 'https://api.rotary.org.ua/uploads/clubsitecreator/qrcode_generator/img/template1.png';
const QRTEMPLATE2 = 'https://api.rotary.org.ua/uploads/clubsitecreator/qrcode_generator/img/qrtemplate_classic.png';

const QrGeneratorPage = () => {
  const [qrcodeurl, setQrcodeurl] = useState('');
  const [dynamic, setDynamic] = useState(false);
  const [template, setTemplate] = useState('QRTEMPLATE1');
  const [generatedQR, setGeneratedQR] = useState(null);

  const handleGenerate = () => {
    setGeneratedQR(
      <QRGenerator data={qrcodeurl} logo={dynamic ? defaultLogo : null} template={template} />
    );
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 5, p: 2, boxShadow: 3, borderRadius: 2, backgroundColor: '#f9f9f9' }}>
      <Typography variant="h4" component="div" textAlign="center" gutterBottom>
        QR Code Generator
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="URL"
          variant="outlined"
          value={qrcodeurl}
          onChange={(e) => setQrcodeurl(e.target.value)}
          fullWidth
        />
        <FormControlLabel
          control={<Checkbox checked={dynamic} onChange={(e) => setDynamic(e.target.checked)} />}
          label="Динамічний QR-code"
        />
        <FormControl component="fieldset">
          <FormLabel component="legend">Вибір дизайну</FormLabel>
          <RadioGroup
            value={template}
            onChange={(e) => setTemplate(e.target.value)}
          >
            <FormControlLabel
              value="QRTEMPLATE1"
              control={<Radio />}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={QRTEMPLATE1} alt="QRTEMPLATE1" style={{ width: 50, height: 50, marginRight: 8 }} />
                  QRTEMPLATE1
                </Box>
              }
            />
            <FormControlLabel
              value="QRTEMPLATE2"
              control={<Radio />}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={QRTEMPLATE2} alt="QRTEMPLATE2" style={{ width: 50, height: 50, marginRight: 8 }} />
                  QRTEMPLATE2
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>
        <Button variant="contained" color="primary" onClick={handleGenerate}>
          Згенерувати
        </Button>
      </Box>
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        {generatedQR}
      </Box>
    </Box>
  );
};

export default QrGeneratorPage;
