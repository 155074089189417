import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './Footer.css';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LanguageIcon from '@mui/icons-material/Language';
import { MdEmail } from 'react-icons/md';
import { IoLogoYoutube } from 'react-icons/io5';

const Footer = () => {

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => alert('Адресу пошти '+text+' скопійовано в буфер обміну!'))
          .catch((err) => console.error('Помилка копіювання тексту:', err));
      };
      
    return (
        <Box className="footer">
            <Tooltip title={'Facebook'}>
            <IconButton onClick={() => window.open('https://www.facebook.com/RotaryInUkraine', '_blank')} aria-label="Facebook">
                <FaFacebookF color="white" size='1.5em' />
            </IconButton>
            </Tooltip>
            <Tooltip title={'Instagram'}>
            <IconButton onClick={() => window.open('https://www.instagram.com/rotary.ukraine/', '_blank')} aria-label="Facebook">
                <FaInstagram color="white" size='1.5em' />
            </IconButton>
            </Tooltip>
            <Tooltip title={'Пошта'}>
            <IconButton onClick={() => handleCopyToClipboard('d2232@gmail.com')} aria-label="Facebook">
                <MdEmail color="white" size='1.5em' />
            </IconButton>
            </Tooltip>
            <Tooltip title={'Ютуб канал 1'}>
            <IconButton onClick={() => window.open('https://www.youtube.com/@user-gn1qe3se2m/videos', '_blank')} aria-label="Facebook">
                <IoLogoYoutube color="white" size='1.5em' />
            </IconButton>
            </Tooltip>
            <Tooltip title={'Ютуб канал 2'}>
            <IconButton onClick={() => window.open('https://www.youtube.com/@user-jf2dt9ty9g', '_blank')} aria-label="Facebook">
                <IoLogoYoutube color="white" size='1.5em' />
            </IconButton>
            </Tooltip>

            <div style={{ width: '100%' }}></div>
            <Typography variant="white" className="footer-developer">
                developed by Tyko:DevStudio
            </Typography>
        </Box>
    );
}

export default Footer;
