export const getMagazines = (local) => {
    const resData = resData_ukr; //local === LOCALES.UKRAINIAN ? resData_ukr : resData_eng;
    return resData;
};

const resData_ukr = [
    {
        "magazineID": 13,
        "magazinename": "Ротарієць січень лютий",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariets-sichen-liutyi-2019",
        "magazinecat": "2019",
        "magazineimg": "ZFenDf9ASl.png",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 14,
        "magazinename": "Rotary January February",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariets-sichen-liutyi-2019",
        "magazinecat": "2019",
        "magazineimg": "KECgeoi8Or.png",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "ENG"
    },
    {
        "magazineID": 15,
        "magazinename": "Ротарієць березень квітень",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariets-berezen-kviten-2019",
        "magazinecat": "2019",
        "magazineimg": "M56E47lPVf.jpg",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 16,
        "magazinename": "Rotary March April",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariets-berezen-kviten-2019",
        "magazinecat": "2019",
        "magazineimg": "fRRbhPOjgL.jpg",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "ENG"
    },
    {
        "magazineID": 17,
        "magazinename": "Ротарієць травень червень",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariets-traven-cherven-2019",
        "magazinecat": "2019",
        "magazineimg": "cGcFvFP49t.jpg",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 18,
        "magazinename": "Rotarian May June",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariets-traven-cherven-2019",
        "magazinecat": "2019",
        "magazineimg": "PevXUW82lm.jpg",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "ENG"
    },
    {
        "magazineID": 19,
        "magazinename": "Ротарієць липень серпень",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariets-lypen-serpen-2019",
        "magazinecat": "2019",
        "magazineimg": "oIRbNBQZOY.jpg",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 20,
        "magazinename": "Rotarian July August",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariets-lypen-serpen-2019",
        "magazinecat": "2019",
        "magazineimg": "2nC1KdPtao.jpg",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "ENG"
    },
    {
        "magazineID": 21,
        "magazinename": "Ротарієць вересень жовтень",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariets-veresen-zhovten-2019",
        "magazinecat": "2019",
        "magazineimg": "i44znkQtJY.jpg",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 22,
        "magazinename": "Rotarian September October",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariets-veresen-zhovten-2019",
        "magazinecat": "2019",
        "magazineimg": "x1WJcXIDlm.jpg",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "ENG"
    },
    {
        "magazineID": 23,
        "magazinename": "Ротарієць листопад грудень",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariets-lystopad-hruden-2019",
        "magazinecat": "2019",
        "magazineimg": "Vc7V2ThGfc.jpg",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 24,
        "magazinename": "Rotary November December",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariets-lystopad-hruden-2019",
        "magazinecat": "2019",
        "magazineimg": "8Drn5MUnG1.jpg",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "ENG"
    },
    {
        "magazineID": 25,
        "magazinename": "Ротарієць січень лютий",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariets-sichen-liutyi-2020",
        "magazinecat": "2020",
        "magazineimg": "TMGssW8utS.png",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 26,
        "magazinename": "Rotary January February",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariets-sichen-liutyi-2020",
        "magazinecat": "2020",
        "magazineimg": "mNJH2RC00w.png",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "ENG"
    },
    {
        "magazineID": 27,
        "magazinename": "Ротарієць березень квітень",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariets-berezen-kviten-2020",
        "magazinecat": "2020",
        "magazineimg": "cyEeT627fa.png",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 28,
        "magazinename": "Rotary March April",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariets-berezen-kviten-2020",
        "magazinecat": "2020",
        "magazineimg": "gTdXMe7Itn.png",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "ENG"
    },
    {
        "magazineID": 29,
        "magazinename": "Ротарієць травень червень",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/traven-cherven-2020",
        "magazinecat": "2020",
        "magazineimg": "6tOgAT8kaA.jpg",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 30,
        "magazinename": "Rotarian May June",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/traven-cherven-2020",
        "magazinecat": "2020",
        "magazineimg": "9HaNCdEcnt.jpg",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "ENG"
    },
    {
        "magazineID": 31,
        "magazinename": "Ротарієць липень серпень",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariiets-lypen-serpen-2020",
        "magazinecat": "2020",
        "magazineimg": "VBMmqVKAoK.jpg",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 32,
        "magazinename": "Rotarian July August",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotariiets-lypen-serpen-2020",
        "magazinecat": "2020",
        "magazineimg": "u3YHBeFFD2.jpg",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "ENG"
    },
    {
        "magazineID": 33,
        "magazinename": "Ротарієць вересень жовтень",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/veresen-zhovten-2020",
        "magazinecat": "2020",
        "magazineimg": "yEIU7orZl5.png",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 34,
        "magazinename": "Rotarian September October",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/veresen-zhovten-2020",
        "magazinecat": "2020",
        "magazineimg": "l9uvV0ge7N.png",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "ENG"
    },
    {
        "magazineID": 35,
        "magazinename": "Ротарієць листопад грудень",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/lystopad-hruden2020",
        "magazinecat": "2020",
        "magazineimg": "yOrjAdf4NP.jpg",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 36,
        "magazinename": "Rotary November December",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/lystopad-hruden2020",
        "magazinecat": "2020",
        "magazineimg": "sN3m8aaZJW.jpg",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "ENG"
    },
    {
        "magazineID": 37,
        "magazinename": "Ротарієць січень лютий",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/sichen-liutyi-2021",
        "magazinecat": "2021",
        "magazineimg": "N4Afrh3Pzd.png",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 38,
        "magazinename": "Rotary January February",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/sichen-liutyi-2021",
        "magazinecat": "2021",
        "magazineimg": "gTRg5wPbbQ.png",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "ENG"
    },
    {
        "magazineID": 39,
        "magazinename": "Ротарієць березень квітень",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotary-direct-berezen-kviten-2021",
        "magazinecat": "2021",
        "magazineimg": "VEeqct0Fkp.png",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 40,
        "magazinename": "Rotary March April",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/rotary-direct-berezen-kviten-2021",
        "magazinecat": "2021",
        "magazineimg": "FDtmWgiFHz.png",
        "magazinedate": "2021-05-26T21:00:00.000Z",
        "magazinelang": "ENG"
    },
    {
        "magazineID": 41,
        "magazinename": "Ротарієць, травень-червень 2021",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/traven-cherven-2021",
        "magazinecat": "2021",
        "magazineimg": "KhVzRNP8OJ.png",
        "magazinedate": "2023-07-30T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 42,
        "magazinename": "Ротарієць, липень-серпень 2021",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/lypen-serpen-2021",
        "magazinecat": "2021",
        "magazineimg": "07o5STLtjS.jpg",
        "magazinedate": "2023-07-30T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 43,
        "magazinename": "Ротарієць, вересень-жовтень 2021",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/veresen-zhovten-2021",
        "magazinecat": "2021",
        "magazineimg": "xI36EQWlsY.png",
        "magazinedate": "2023-07-30T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 44,
        "magazinename": "Ротарієць, листопад-грудень 2021",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/lystopad-hruden-2021",
        "magazinecat": "2021",
        "magazineimg": "MZ73TRJnFo.jpg",
        "magazinedate": "2023-07-30T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 46,
        "magazinename": "Ротарієць, січень-лютий 2022",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/sichen-liutyi-2022",
        "magazinecat": "2022",
        "magazineimg": "dOomkRnXvp.png",
        "magazinedate": "2023-07-30T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 47,
        "magazinename": "Ротарієць, березень-квітень 2022",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/berezen-kviten-2022",
        "magazinecat": "2022",
        "magazineimg": "tXf2MtuYTF.jpg",
        "magazinedate": "2023-07-30T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 48,
        "magazinename": "Ротарієць, травень-червень 2022",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/traven-cherven-2022",
        "magazinecat": "2022",
        "magazineimg": "MiZ02HLiXB.png",
        "magazinedate": "2023-07-30T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 49,
        "magazinename": "Ротарієць, вересень-жовтень 2022",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/veresen-zhovten-2022",
        "magazinecat": "2022",
        "magazineimg": "J55F3bD0Pe.jpg",
        "magazinedate": "2023-07-30T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 50,
        "magazinename": "Ротарієць, листопад-грудень 2022",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/lystopad-hruden-2022",
        "magazinecat": "2022",
        "magazineimg": "Mr5v4n9Jkt.jpg",
        "magazinedate": "2023-07-30T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 51,
        "magazinename": "Ротарієць, березень-квітень 2023",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/berezen-kviten-2023",
        "magazinecat": "2023",
        "magazineimg": "XaaKmAheW1.png",
        "magazinedate": "2023-07-30T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 52,
        "magazinename": "Ротарієць, січень-лютий 2023",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/sichen-liutyi-2023",
        "magazinecat": "2023",
        "magazineimg": "HJVPN91FyJ.jpg",
        "magazinedate": "2023-07-30T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 53,
        "magazinename": "Ротарієць, липень-серпень 2022",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/lypen-serpen-2022",
        "magazinecat": "2022",
        "magazineimg": "38uMCnKGSp.jpg",
        "magazinedate": "2023-07-30T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 54,
        "magazinename": "Ротарієць, травень-червень 2023",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/traven-cherven-2023",
        "magazinecat": "2023",
        "magazineimg": "rMmKsA91F4.jpg",
        "magazinedate": "2024-04-13T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 55,
        "magazinename": "Ротарієць, липень-серпень 2023",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/lypen-serpen-2023",
        "magazinecat": "2023",
        "magazineimg": "zMsna8NSu9.jpg",
        "magazinedate": "2024-04-13T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 56,
        "magazinename": "Ротарієць, вересень-жовтень 2023",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/veresen-zhovten-2023",
        "magazinecat": "2023",
        "magazineimg": "75detrR75W.jpg",
        "magazinedate": "2024-04-13T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 57,
        "magazinename": "Ротарієць, листопад-грудень 2023",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/lystopad-hruden-2023",
        "magazinecat": "2023",
        "magazineimg": "5k84ljgzm4.jpg",
        "magazinedate": "2024-04-13T21:00:00.000Z",
        "magazinelang": "UKR"
    },
    {
        "magazineID": 58,
        "magazinename": "Ротарієць, січень-лютий 2024",
        "magazinelink": "https://rotarietsmagazineukr.readz.com/sichen-liutyi-2024",
        "magazinecat": "2024",
        "magazineimg": "Xqt3YeBaDm.png",
        "magazinedate": "2024-04-15T21:00:00.000Z",
        "magazinelang": "UKR"
    }
]