
import { LOCALES } from '../../../i18n/locales';

export const getRCCherkasyLeadership = (local) => {
    const cherkasyData = local === LOCALES.UKRAINIAN ? cherkasyData_ukr : cherkasyData_eng;
    return cherkasyData;
};
            
const cherkasyData_ukr = [
    {
        id: 0,
        img: "",
        header: 'Керівництво:',
        content: [
            {LandingMessage: `D2232 Інститут лідерства Ротарі`},
            {LandingLink: "Facebook", link: "https://www.facebook.com/profile.php?id=100068476068080"},
            {LandingList: [
                {"LandingListItem": "тренери Ротарі Інституту Лідерства Д2232", url: ""},
                {"LandingListItem": "випускники", url: ""},
            ]},
        ],
    },
];

const cherkasyData_eng = [
    {
        id: 0,
        img: "",
        header: 'Leadership:',
        content: [
            {LandingMessage: `D2232 Rotary Leadership Institute`},
            {LandingLink: "Facebook", link: "https://www.facebook.com/profile.php?id=100068476068080"},
            {LandingList: [
                {"LandingListItem": "тренери Ротарі Інституту Лідерства Д2232", url: ""},
                {"LandingListItem": "випускники", url: ""},
            ]},
        ],
    },
];