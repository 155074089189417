import React, { useState, useEffect } from 'react';
import { Container, Button, TextField } from '@mui/material';
import {  Paper } from '@mui/material';

import TableComponent from './TableComponent';
import OverlayFormFieldsComponent from './OverlayFormFieldsComponent';

const AdminTableComponent = ({data, tableTitles, emptyData, createItem, updateItem, deleteItem, refreshData, hasChildren}) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    
    const [selectedData, setSelectedData] = useState(null);
    const [openUrlDialog, setOpenUrlDialog] = useState(false);
    
    const handleSelectUrls = (communique) => {
        console.log("handleSelectUrls");
        setSelectedData(communique);
        //setSelectedUrls(communique.urls);
        setOpenUrlDialog(true);
    }

    const handleDelete_ = async (id) => {
        console.log("handleDelete_ id="+id);
        await deleteItem(id);
        refreshData();
        setSelectedData(null);
    };

    const handleSubmit_ = async () => {
        if (selectedData && selectedData.id) {
            console.log("handleSubmit updateItem");
            await updateItem(selectedData);
        } else {
            console.log("handleSubmit_ createItem");
            await createItem(selectedData);
        }
        // Refresh data
        refreshData();
        setSelectedData(null);
    };

    return (
        <Container>
            {
            //JSON.stringify(data)
        }
        <Paper>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {loading && <p>Завантаження...</p>}


            { (!selectedData && !openUrlDialog) && 
                <Button variant="contained" color="primary" onClick={() => setSelectedData(emptyData)}>Add New Item</Button>
            }
            {data && 
            <TableComponent 
                communiques={data}
                communiqueTableTitles={tableTitles}
                setSelectedCommunique={setSelectedData}
                handleDelete={handleDelete_}
                handleSelectUrls={handleSelectUrls}
                hasChildren={hasChildren}
            />}
            
            {selectedData && !openUrlDialog && (
                <OverlayFormFieldsComponent
                    selectedData={selectedData}
                    tableTitles={tableTitles}
                    setSelectedData={setSelectedData}
                    handleSubmit={handleSubmit_}
                />  
            )}
        </Paper>
        </Container>
    );
};

export default AdminTableComponent;
