
import { LOCALES } from '../../../i18n/locales';

export const getItProjectPage = (local) => {
    const cherkasyData = local === LOCALES.UKRAINIAN ? cherkasyData_ukr : cherkasyData_eng;
    return cherkasyData;
};

const cherkasyData_ukr = [
    {
        id: 0,
        header: 'Огляд проектів',
        content: [
            {LandingMessage: `Переглядайте або шукайте проекти за допомогою одного або кількох фільтрів у списку.`},
        ],
    },
    {
        id: 1,
        LandingProjects: `#itprojects`,
    }
];

const cherkasyData_eng = [
    {
        id: 0,
        header: `BROWSE PROJECTS`,
        content: [
            {LandingMessage: `Browse or search projects using one or more of the filters listed.`},
        ],
    },
];

