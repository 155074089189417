import React, { useState, useEffect } from 'react';
import CrudTable from './CrudTable';
import AdminDialogCrud3 from './AdminDialogCrud_SitePage';
import AdminDialogCrud_SitePage from './AdminDialogCrud_SitePage';
import {fetchSitePage, updateSitePage, createSitePage, deleteSitePage} from '../../../../api/api_sitecreator_rc_eclub';

const generateDefaultObject = (columns) => {
    return columns.reduce((acc, column) => {
        acc[column.field] = column.default;
        return acc;
    }, {});
};


const CRUD_SiteConstructor = ({data, adminSitePageDefaultFields, sitePageColumns, }) => {
    const [mprojarr, setMprojarr] = useState([]);
    const [dataUkr, setDataUkr] = useState([]);
    const [dataEng, setDataEng] = useState([]);
    
    const [open, setOpen] = useState(false);
    const [currentSitePage, setCurrentSitePage] = useState(generateDefaultObject(sitePageColumns));
    const [currentSitePage2, setCurrentSitePage2] = useState(generateDefaultObject(sitePageColumns));
    const [isEditMode, setIsEditMode] = useState(false);
    
    useEffect(() => {
        const fetchData = async () => {
        try {
            await loadData();
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
        };
        fetchData();
    }, [])

    const loadData = async(lcale) => {
        // const d = await getDbMProjs(1);
        // setMprojarr( d_ukr );
        const d_ukr = await fetchSitePage(86);
        setDataUkr( d_ukr );
        const d_eng = await fetchSitePage(88);
        setDataEng( d_eng );

        console.log("DanatePage get data list success. const="+d_ukr.length);
    }

    const handleClose = () => {
        setOpen(false);
    };
    const handleSubmit = async () => {
        console.log('=== handleSubmit...');

        const currentSitePage_new = { ...currentSitePage };
        adminSitePageDefaultFields.forEach(({ field, defValue }) => {
            currentSitePage_new[field] = defValue;
        });
        try {
            if (isEditMode) {
                await updateSitePage(currentSitePage_new.id, currentSitePage_new);
            } else {
                await createSitePage(currentSitePage_new);
            }
            handleClose();
            loadData();
        } catch (error) {
            console.error(error);
        }
    };
    const handleDelete = async (sitepageId) => {
        const confirmDelete = window.confirm('Чи дійсно хочете видалити цей проект?');
        if (!confirmDelete) return;
    
        try {
            await deleteSitePage(sitepageId);
            loadData();
        } catch (error) {
            console.error(error);
        }
    };
    const handleEditSitePage = (sitePage = generateDefaultObject(sitePageColumns)) => {
        console.log('handleEditSitePage sitePage='+JSON.stringify(sitePage))
        setCurrentSitePage(sitePage);
        setCurrentSitePage2(dataUkr[0]);
        setIsEditMode(!!sitePage.id);
        setOpen(true);
    };

    return (
        <div>CRUD_SiteConstructor<br/>

            {/* data={JSON.stringify(currentSitePage)}<br/>
            =================<br/>
            data={JSON.stringify(currentSitePage2)}<br/> */}

            <CrudTable 
                data={[...dataEng]} 
                columns={sitePageColumns} 
                onEdit={handleEditSitePage}
                onDelete={handleDelete} 
            />
            
            <AdminDialogCrud_SitePage
                open={open}
                onClose={handleClose}
                onSubmit={handleSubmit}
                isEditMode={isEditMode}
                columns={sitePageColumns}
                currentItem={currentSitePage}
                currentItem2={currentSitePage2}
                setCurrentItem={setCurrentSitePage}
                //filters={filters}
            />
        </div>
    );
}

export default CRUD_SiteConstructor;
