import { LOCALES } from '../../../i18n/locales';

export const getHeader = (local) => {
    return defaultData_ukr;//local===LOCALES.UKRAINIAN ? defaultData_ukr : defaultData_eng;
};

const defaultData_ukr = {
    //title: "Запрошення на PETS, DTTS",
    //smallTitle: "на 15-18 березня 2024 р., м. Полтава",
    imgs: [
        "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Event_Pets/1706737457856-540559079.jpg",
        "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Event_Pets/1706737457856-895603843.jpg",
        "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Event_Pets/1707316214741-307146990.jpg",
    ],
    button: {title: "Реєстрація учасників", url: "https://forms.gle/g5KMEJYshasGqvAK7"},
}
