export const getBrandCentrPage = () => {
    return defaultData;
};

const defaultData = [
    {
        id: 0,
        header: 'Про Бренд Центр Ротарі Інтернейшнл',
        content: [
            {LandingMessage: "Вперше в Brand Center?"},
            {LandingMessage: "Ви знайдете багато ресурсів, які допоможуть вам просувати Ротарі у вашій громаді та показати світові, що ми люди дії. Якщо ви новачок у Brand Center, почніть тут."},
            {LandingButtonDetailsRI: 'true', link: 'https://brandcenter.rotary.org'},
        ],
    },
    {
        id: 1,
        header: 'Про Бренд Центр UA',
        content: [
            {LandingMessage: "Бренд Центр UA створено для полегшення праці ротарійців, що дотичні до брендування."},
            {LandingMessage: "."},
            {LandingMessage: "Шаблони для скачування для дистрикту"},
            {LandingImgTools: "Лого Дистрикту (синьо-жовте)", link: "https://res.cloudinary.com/tyko/image/upload/v1700496887/BetaRotarySite/LogoMakerPhotos/Rotary_District2232_trimed_tzlf2r.png"},
            {LandingButtonLink: "Скачати", link: "h"},
            {LandingMessage: "."},
            {LandingImgTools: "Лого Дистрикту (біло-жовте)", link: "https://res.cloudinary.com/tyko/image/upload/v1700496887/BetaRotarySite/LogoMakerPhotos/D2232_WhiteGold_trimed_qdudzu.png"},
            {LandingButtonLink: "Скачати", link: "h"},
            {LandingMessage: "Шаблони для скачування для клубів"},
            {LandingImgTools: "Лого Дистрикту (синьо-жовте)", link: "https://res.cloudinary.com/tyko/image/upload/v1703413854/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/RC_Cherkasy_hwkr5d.png"},
            {LandingButtonLink: "Скачати", link: "h"},
            {LandingButtonLink: "Перейти до Brand Center UA", variant: 'contained', link: "https://brandcenterua.rotary.org.ua/logoclubcenter"},
            {LandingMessage: "."},
        ],
    },
    {
        id: 2,
        header: 'Онлайн інструменти Бренд Центр UA',
        arrcontent: [
            {
                cards: [
                    {
                        img: 'https://res.cloudinary.com/tyko/image/upload/v1700493727/AvatarMakers/Santa/zzpievezzqg172y6pf12.jpg',
                        content: [
                        ],
                        name: "Святий Санта",
                        button: "Зробити Аватарку",
                        buttonUrl: "https://brandcenterua.rotary.org.ua/avatarmaker5",
                    },
                    {
                        img: 'https://res.cloudinary.com/tyko/image/upload/v1697711690/AvatarMaker/anot965mg9bljkuwmyfw.jpg',
                        content: [
                            {LandingMessage: "в розробці..."},
                        ],
                        name: "Щедрий вівторок",
                        button: "Зробити Аватарку",
                        buttonUrl: "",
                    },
                    {
                        img: 'https://res.cloudinary.com/tyko/image/upload/v1697711690/AvatarMaker/anot965mg9bljkuwmyfw.jpg',
                        content: [
                            {LandingMessage: "- мови: україська та російська"},
                            {LandingMessage: "- є можливість змінити дистрикт"},
                        ],
                        name: "Тема ротарійського року",
                        button: "Зробити Аватарку",
                        buttonUrl: "https://brandcenterua.rotary.org.ua/avatarmaker",
                    },
                    {
                        img: 'https://res.cloudinary.com/tyko/image/upload/v1700420430/AvatarMakers/UkraineIndependsDay/kkaueetwsdetjywaxq2y.jpg',
                        content: [
                            {LandingMessage: "- мови: україська та російська"},
                        ],
                        name: "День Незалежності України",
                        button: "Зробити Аватарку",
                        buttonUrl: "https://brandcenterua.rotary.org.ua/avatarmaker2",
                    },
                    {
                        img: 'https://res.cloudinary.com/tyko/image/upload/v1700420485/AvatarMakers/IzrailTogeser/kfes1jhgbgkasa0hktz5.jpg',
                        content: [
                        ],
                        name: "Україна + Ізраіль",
                        button: "Зробити Аватарку",
                        buttonUrl: "https://brandcenterua.rotary.org.ua/avatarmaker3",
                    },
                    {
                        img: 'https://res.cloudinary.com/tyko/image/upload/v1698084458/AvatarMakers/PolioDay/ofeczpjgadiwh0u9dapt.jpg',
                        content: [
                        ],
                        name: "Підтримка Всесвітньої боротьби з Поліо",
                        button: "Зробити Аватарку",
                        buttonUrl: "https://brandcenterua.rotary.org.ua/avatarmaker4",
                    },
                    
                ]
            }
        ]
    },
    {
        id: 3,
        header: 'Хочете зробити Бренд Центр краще?',
        content: [
            {LandingMessage: "Бренд Цент тільки на єтапі впровадження ідей."},
            {LandingButtonLink: 'Сповістити про помилки чи незручності в роботі', link: 'https://brandcenter.rotary.org'},
            {LandingMessage: "."},
            {LandingMessage: "Маєте бажання долучитися до креативної команди?"},
            {LandingButtonLink: 'Доєднатися до команди розробки', link: ''},
        ],
    },
    {
        id: 4,
        header: 'Наша команда:',
        content: [
            {LandingMessage: "Лариса Коба: голова PR комітету, ідеї та координація"},
            {LandingMessage: "Олег Косьмина: член PR комітету, програмна розробка"},
            {LandingMessage: "Андрій Атлантов: член PR комітету, дизайн автарок"},
            
        ],
    },
]
