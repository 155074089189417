export const data = `
,List of all clubs,,Is Active,,Current Member Count,,Is New,Charter Date,Chartered Member Count,Is Terminated,Termination Date,Termination Reason,# of Members @ Termination,

,Alushta (31599),,X,,4,,,14-May-1996,7,,,,,
,Cherkasy (50516),,X,,27,,,10-Jun-1997,2,,,,,
,Cherkasy-Centre (62578),,X,,24,,,17-Jun-2003,19,,,,,
,Cherkasy International (91147),,X,,26,,,29-Jun-2020,25,,,,,
,Cherkasy Synergy (225441),,X,,21,,,26-Jun-2023,21,,,,,
,Chernihiv (73720),,,,,,,30-Jun-2006,19,X,01-Jan-2011,Termination - Non Payment of Dues,0,
,Chernivtsi (82565),,X,,22,,,30-Jun-2009,20,,,,,
,Dneprodzerzhinsk (31655),,,,,,,30-May-1996,2,X,01-Jan-2013,Termination - Non Payment of Dues,0,
,Dnipro City (50517),,X,,25,,,10-Jun-1997,2,,,,,
,Dnipro Novyi (89997),,X,,16,,,03-Apr-2019,29,,,,,
,Dnipropetrovsk Apriori (87294),,,,,,,19-Feb-2016,25,X,22-May-2019,Termination - Non Payment of Dues,3,
,Dnipropetrovsk-Dnipro Region (54528),,X,,9,,,13-Nov-2000,21,,,,,
,"Donetsk ""Common Way"" (67583)",,,,,,,11-Jan-2005,20,X,26-May-2015,Termination - Non Payment of Dues,0,
,Dubno (223045),,X,,14,,,20-Jul-2021,20,,,,,
,E-Club of Ukraine (86412),,X,,25,,,30-Jan-2015,20,,,,,
,Feodosia (50601),,,,,,,26-Jun-1997,2,X,01-Jul-2012,Termination - Non Payment of Dues,0,
,Ivano-Frankivsk (30749),,X,,22,,,03-May-1995,5,,,,,
,Ivano-Frankivsk-Stanislaviv (89041),,X,,10,,,12-Feb-2018,21,,,,,
,Izmail (89550),,X,,25,,,17-Aug-2018,20,,,,,
,Kalush (59586),,,,,,,06-Sep-2002,20,X,01-Jan-2006,Termination - Non Payment of Dues,0,
,Kamjanka-Buzka (51252),,,,,,,26-May-1998,2,X,01-Jan-2008,Termination - Non Payment of Dues,0,
,Kamyanets-Podilsky (82894),,,,,,,08-Feb-2010,20,X,01-Jan-2012,Termination - Non Payment of Dues,0,
,Kerch (50700),,,,,,,28-Aug-1997,2,X,30-Jun-2012,Termination - Resignation/Other,0,
,Kharkiv (30392),,X,,21,,,23-Sep-1994,5,,,,,
,Kharkiv-City (71875),,X,,41,,,31-Jan-2006,21,,,,,
,Kharkiv Direct Help International (225548),,X,,21,,X,11-Aug-2023,21,,,,,
,Kharkiv First Capital (87660),,X,,14,,,09-Jun-2016,21,,,,,
,Kharkiv-International (90021),,X,,23,,,15-Apr-2019,21,,,,,
,Kharkiv Mriya (72134),,X,,21,,,28-Feb-2006,20,,,,,
,Kharkiv-Multinational (84948),,X,,25,,,12-Mar-2013,20,,,,,
,Kharkiv Nadiya (87180),,X,,14,,,04-Dec-2015,20,,,,,
,Kharkiv New Level (222650),,X,,67,,,27-May-2021,20,,,,,
,Kharkiv Phoenix (225719),,X,,17,,X,15-Nov-2023,20,,,,,
,Kharkiv Renaissance (225038),,X,,18,,,03-Mar-2023,23,,,,,
,Kharkiv Spirit (225437),,X,,30,,,27-Jun-2023,30,,,,,
,Kharkiv Victory (225720),,X,,21,,X,14-Nov-2023,20,,,,,
,Kherson (31908),,X,,11,,,05-Nov-1996,4,,,,,
,Kobelyaki (31600),,X,,7,,,14-May-1996,14,,,,,
,Kolomyia (225800),,X,,21,,X,29-Dec-2023,21,,,,,
,Korosten (224764),,X,,20,,,20-Sep-2022,20,,,,,
,Kropyvnytskyi (55121),,X,,7,,,12-Mar-2001,20,,,,,
,Kyiv (28682),,X,,32,,,10-Apr-1992,9,,,,,
,Kyiv Advance (85426),,X,,6,,,11-Oct-2013,20,,,,,
,Kyiv-Balzac (84749),,X,,18,,,20-Sep-2012,25,,,,,
,Kyiv-Capital (50563),,X,,37,,,18-Jun-1997,2,,,,,
,Kyiv-Centre (52526),,X,,9,,,31-Aug-1999,2,,,,,
,Kyiv-City (83492),,X,,25,,,19-Oct-2010,21,,,,,
,Kyiv Golden Pectoral (225724),,X,,20,,X,17-Nov-2023,20,,,,,
,Kyiv International (90651),,,,,,,27-Dec-2019,20,X,25-Oct-2023,Termination - Failure to Function,59,
,Kyiv International Business (222361),,X,,13,,,15-Mar-2021,22,,,,,
,Kyiv Multinational (73318),,X,,16,,,13-Jun-2006,21,,,,,
,Kyiv-Sophia (84894),,X,,18,,,05-Feb-2013,20,,,,,
,Kyiv Synergy (222068),,X,,10,,,03-Nov-2020,20,,,,,
,Lubny (67295),,,,,,,21-Dec-2004,20,X,01-Jan-2011,Termination - Non Payment of Dues,0,
,Lutsk (31504),,,,,,,16-Apr-1996,2,X,01-Oct-2003,Termination - Non Payment of Dues,0,
,Lutsk Luchesk (224593),,X,,18,,,30-Jun-2022,20,,,,,
,Lutsk Volyn Pride (224572),,X,,24,,,29-Jun-2022,21,,,,,
,Lviv (29085),,X,,32,,,20-Dec-1935,0,,,,,
,Lviv-Centre (55252),,X,,17,,,02-Apr-2001,22,,,,,
,Lviv Innovational (222944),,X,,21,,,30-Jun-2021,20,,,,,
,Lviv International (87280),,X,,59,,,12-Feb-2016,25,,,,,
,Lviv Kniazhyi (84316),,X,,18,,,08-Mar-2012,25,,,,,
,Lviv-Leopolis (51067),,X,,30,,,28-Apr-1998,2,,,,,
,Lviv-Ratusha (62579),,X,,23,,,17-Jun-2003,20,,,,,
,Lviv-Zamok (83461),,X,,12,,,10-Sep-2010,2,,,,,
,Mirgorod (50983),,,,,,,01-Apr-1998,2,X,31-Dec-2020,Termination - Failure to Function,6,
,Mykolaiv (30750),,X,,21,,,03-May-1995,3,,,,,
,Odesa Glory (225303),,X,,24,,,02-Jun-2023,23,,,,,
,Odessa (30751),,X,,10,,,03-May-1995,3,,,,,
,Odessa International (90921),,X,,18,,,29-Apr-2020,23,,,,,
,Odessa-Rishelye (87270),,X,,14,,,08-Feb-2016,20,,,,,
,Odessa Sea Energy (222249),,X,,6,,,12-Feb-2021,20,,,,,
,Okhtyrka (55457),,,,,,,23-Apr-2001,20,X,01-Apr-2004,Termination - Non Payment of Dues,0,
,Poltava (31910),,X,,30,,,05-Nov-1996,4,,,,,
,Rakhiv-Center of Europe (83416),,X,,14,,,22-Jul-2010,20,,,,,
,Rivne (31786),,X,,21,,,08-Jul-1996,1,,,,,
,Rivne-Region (55153),,,,,,,19-Mar-2001,24,X,01-Jan-2007,Termination - Non Payment of Dues,0,
,Sevastopol (52124),,,,,,,20-May-1999,2,X,26-May-2015,Termination - Non Payment of Dues,0,
,Simféropol (31656),,X,,9,,,31-May-1996,2,,,,,
,Simferopol-Taurica (83791),,,,,,,15-Apr-2011,25,X,30-Jun-2015,Termination - Resignation/Other,0,
,Slavyansk (52489),,X,,7,,,27-Aug-1999,2,,,,,
,Sudak (50699),,,,,,,28-Aug-1997,2,X,01-Oct-2003,Termination - Non Payment of Dues,0,
,Sumy (30576),,,,,,,08-Feb-1995,4,X,13-May-2020,Termination - Lack of Members,2,
,Ternopil (30632),,,,,,,14-Mar-1995,4,X,30-Jun-2018,Termination - Failure to Function,14,
,Ternopil City (222619),,X,,23,,,21-May-2021,20,,,,,
,Truskavets (80412),,X,,5,,,30-Sep-2008,20,,,,,
,Ukraine Unity Passport (91130),,X,,36,,,25-Jun-2020,34,,,,,
,Uzhgorod (30230),,X,,31,,,05-Sep-1930,0,,,,,
,Uzhgorod-Skala (69500),,X,,24,,,31-May-2005,20,,,,,
,Veliatyno International (224609),,X,,9,,,30-Jun-2022,21,,,,,
,Vinnytsia (75858),,,,,,,24-Apr-2007,21,X,05-May-2020,Termination - Resignation/Other,7,
,Vinnytsia Postup (223132),,X,,21,,,25-Aug-2021,20,,,,,
,Vizirka (90881),,X,,20,,,06-Apr-2020,20,,,,,
,Yalta (31688),,,,,,,03-Jun-1996,2,X,12-May-2018,Termination - Resignation/Other,3,
,Zaporizhzhia (79036),,X,,25,,,06-May-2008,24,,,,,
,Zaporizhzhia-Khortytsia (222131),,X,,24,,,14-Dec-2020,21,,,,,
,Zhytomyr (90821),,,,,,,28-Feb-2020,21,X,30-Jun-2021,Termination - Non Payment of Dues,14,
,District Total,,,,1519,,,,,,,,108,
`