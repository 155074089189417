import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, Button, Tooltip  } from '@mui/material'
import '../Pages.css';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import { Tabs, Tab } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import MyMainPage from "../../modules/MYMain";
import LandingBlock from '../../components/LandingBlock';

import { getFindocs, getFindocs2 } from "../../api/finance";

import IMG_PNG_1 from '../../assets/img/icons/pdf_icon.png';

const ConferencesPage = () => {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const dt = await getFindocs();
          const dt2 = await getFindocs2();
          //console.log('getEventsData = '+JSON.stringify(events.data, null, 2));
          setData(dt);
          setData2(dt2);
        } catch (error) {
          // Обробити помилку отримання даних
        }
      };

      fetchData();
    }, []);

    return (
        <>
        <MyMainPage>
        <LandingBlock mode='light'>
            <Box className="section text-left" >
            <Container>
            <Typography variant="h5" className="page-header">Конференції дистрикту:</Typography>
                
                <br/>
                    0.
                
              </Container>
              </Box>
        </LandingBlock>
        </MyMainPage>
        </>
  );
}

export default ConferencesPage;