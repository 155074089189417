import HOME1_4 from '../assets/content/business_council/business6.jpg';
import HOME1_1 from '../assets/content/business_council/partners.jpg';

export const getBusinessLanding = (isUkraine) => {
    return isUkraine ? defaultData_ukr : defaultData_eng;
};

export const getPhotoForLanding = (isUkraine) => {
    return imgArr;
};

const arrList1 = [
    {"LandingListItem": "Голови Клубних комісій з професійного служіння", url: ""},
    {"LandingListItem": "члени Ділової Ради РІ Д2232", url: ""},
    {"LandingListItem": "президенти Ротарі та Ротаракт клубів", url: ""},
    {"LandingListItem": "Асистенти Губернатора", url: ""},
    {"LandingListItem": "Голови Дистриктних Комітетів", url: ""},
    {"LandingListItem": "усі Ротарійці та Ротарактівці, зацікавлені у розвитку інноваційних розробок та стартапів", url: ""},
];

const defaultData_ukr = [
    {
        id: 33,
        img: HOME1_1,
    },
    {
        id: 0,
        header: 'Місце проведення:',
        img: 'https://res.cloudinary.com/tyko/image/upload/v1697373888/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0/Untitled-3_v9ipg1.jpg',
        content: [
            {LandingMessage: "м. Львів, площа Князя Святослава, 5, готель TAURUS, конференц-зал № 4 (другий поверх)"},
            {LandingMessage: "При бронюванні готелю всі учасники форуму отримують знижку у розмірі 15% за кодовим словом: Ротарі Інтернешнл"},
            {LandingButtonLink: "Бронювання готелю на сайті", link: "https://hotel-taurus.com/"},
        ],
    },
    {
        id: 1,
        header: 'Субота, 28 жовтня 2023 року ',
        arrcontent: [
            {
                img: 'https://res.cloudinary.com/tyko/image/upload/v1697374557/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0/Untitled-4_pciblu.jpg',
                content: [
                    {LandingMessage: "Рекомендовані учасники форуму:"},
                    {LandingList: arrList1},
                ],
            },
            {
                img: 'https://naimaproject.eu/wp-content/uploads/2020/06/handshake-3382503_1920.jpg',
                content: [
                    {LandingMessage: "Онлайн-формат буде передбачено лише для Ротарійців, які перебувають закордоном (посилання на участь в онлайн-події Вам буде надіслано 27 жовтня на адреси електронних скриньок, вказані при реєстрації)."},
                ],
            }
        ],
    },
    {
        id: 2,
        header: 'Програма - Субота, 28 жовтня 2023 року',
        arrcontent: [
            {
                header: 'Програма Ротарійського ділового форуму інновацій, стартапів та можливостей.',
                tablecontent: [
                    {
                        tablerow: [
                            {LandingMessage: "10:00 – 11:00"},
                            {LandingMessage: "Реєстрація учасників"},                
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "11:00 – 11:05"},
                            {LandingMessage: "Відкриття Форуму, Гімн України та Гімн Ротарі. Хвилина мовчання та вшанування пам’яті загиблих Захисників та Захисниць України"},                
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "11:05 – 11:10"},
                            {LandingMessage: "Привітальне слово начальника Львівської обласної військової адміністрації"},
                            {LandingMessage: "Максим Козицький"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "11:10 – 11:15"},
                            {LandingMessage: "Привітальне слово в.о. голови Львівської обласної ради"},
                            {LandingMessage: "Юрій Холод"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "11:15 – 11:20"},
                            {LandingMessage: "Привітальне слово від Виконавчого Директора Rotary International"},
                            {LandingMessage: "Іван Гевко, RI"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "11:20 – 11:30"},
                            {LandingMessage: "Вступне слово Губернатора Дистрикту 2232 Rotary International"},
                            {LandingMessage: "Мирон Угрин, РК Львів"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "11:30 – 11:40"},
                            {LandingMessage: "Привітальне слово від Голови Ділової Ради Дистрикту 2232 Rotary International"},
                            {LandingMessage: "Олексій Дегтяренко, РК Львів"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "11:40 – 11:50"},
                            {LandingMessage: "Привітальне слово від керівництва Кредобанку – Генерального партнера форуму"},
                            {LandingMessage: "Оксана Стецьків, виконавчий директор ПАТ «Кредобанк»"},
                        ],
                    },
                ],
            },
            {
                header: 'Блок 1. Інноваційні розробки та стартапи ротарійців України та світу',
                tablecontent: [
                    {
                        tablerow: [
                            {LandingMessage: "11:50 – 12:00"},
                            {LandingMessage: "Гідрогелеві засоби медичного призначення Aquior"},
                            {LandingMessage: "Володимир Самарик, Володимир Старчевський, РК Львів Центр"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "12:00 – 12:10"},
                            {LandingMessage: "Вітер змін: механічний вітряк майбутнього"},
                            {LandingMessage: "Сергій Пилипів, РК Харків Інтернешнл"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "12:10 – 12:20"},
                            {LandingMessage: "Сімейні молочні ферми"},
                            {LandingMessage: "Олександр Лісовський, РК Дубно"},
                        ],
                    },
                ],
            },
            {
                header: 'Блок 2. Підтримка інноваційних розробок в Україні та світі',
                tablecontent: [
                    {
                        tablerow: [
                            {LandingMessage: "12:20 – 12:30"},
                            {LandingMessage: "Міжнародні програми та конкурси підтримки інновацій у бізнесі"},
                            {LandingMessage: "Орест Фільц, Агенція регіонального розвитку Львівщини ЛОВА"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "12:30 – 12:40"},
                            {LandingMessage: "Програми підтримки інновацій та стартапів у  Львівській області"},
                            {LandingMessage: "Степан Куйбіда,  Департамент економічної політики ЛОВА"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "12:40 – 12:50"},
                            {LandingMessage: "Програми Українського фонду стартапів у 2023–2024 роках"},
                            {LandingMessage: "Павло Карташов, директор Українського фонду стартапів"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "12:50 – 13:20"},
                            {LandingMessage: "Грантові можливості для бізнесу та інновацій"},
                            {LandingMessage: "Представники USAID, Invest Оffice, GIZ Ukraine"},
                        ],
                    },
                ],
            },
            {
                header: 'Блок 3. Практичні аспекти впровадження інновацій',
                tablecontent: [
                    {
                        tablerow: [
                            {LandingMessage: "13:20 – 13:30"},
                            {LandingMessage: "Сучасні проблеми захисту авторських прав інновацій та стартапів"},
                            {LandingMessage: "Олексій Дегтяренко, РК Львів"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "13:30 – 13:40"},
                            {LandingMessage: "Формування стартап-культури та організація R&D екосистеми"},
                            {LandingMessage: "Олена Лукачук, Ротаракт Клуб Львів"},
                        ],
                    },/*
                    {
                        tablerow: [
                            {LandingMessage: "13:40 – 13:50"},
                            {LandingMessage: "Інноваційні проекти Львівщини: від ідеї до реалізації"},
                            {LandingMessage: "Данило Петрів, Агенція регіонального розвитку Львівщини ЛОВА"},
                        ],
                    }*/,
                    {
                        tablerow: [
                            {LandingMessage: "13:40 – 14:00"},
                            {LandingMessage: "Спільне фото"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "14:00 – 15:00"},
                            {LandingMessage: "Перерва на обід-фуршет"},
                        ],
                    },
                ],
            },
            {
                header: 'Блок 4. Представлення стартапів та інноваційних ідей',
                tablecontent: [
                    {
                        tablerow: [
                            {LandingMessage: "15:00 – 16:30"},
                            {LandingMessage: "Представлення стартапів. «Жива алея стартапів»"},
                            {LandingMessage: `BGN ARMO,
                            Decoder,
                            SECRET,
                            Chameleon Age,
                            НРК «Мінозавр»,
                            Грибна Кава,
                            Сироварня без обмежень,
                            Agrojet,
                            Energy Vision,
                            I3 Engineering,
                            Електричний вакуумний обігрівач
                            Mobile Sanatary Solutions,
                            Система очищення ландшафтної
                            структури басейнів малих річок,
                            Мережа пралень-хімчисток «Happy
                            Laundry»,
                            MedTech-стартап Mark,
                            Адаптивний одяг для хворих або
                            поранених людей, які обмежені в рухах,
                            Innovation Hub,
                            Модель Команд,
                            Голосовий вчитель «Вішунка»`},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "16:30 – 17:00"},
                            {LandingMessage: "Засідання Конкурсної ради Форуму"},
                            {LandingMessage: "Організатори, партнери, представники кластерів та асоціацій"},
                        ],
                    },
                ],
            },
            {
                header: 'Блок 5. Підведення підсумків форуму',
                tablecontent: [
                    {
                        tablerow: [
                            {LandingMessage: "17:00 – 17:30"},
                            {LandingMessage: "Нагородження стартапів грошовими преміями та відзнаками"},
                            {LandingMessage: `На підведенні підсумків форуму буде 
                            вручено грошові премії:
                            Премія І ст. – 100 000 грн. ;
                            Премія ІІ ст. – 50 000 грн. ;
                            Премія ІІІ ст. – 30 000 грн. ;
                            для представлених інноваторами 
                            проектів/стартапів, а також відзнаки від 
                            партнерів заходу. 
                            Ми також відриті до пропозицій щодо 
                            поповнення призового фонду та 
                            персональних відзнак від клубів та 
                            компаній Ротарійців`},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "17:30 – 18:00"},
                            {LandingMessage: "Прикінцеве слово організаторів форуму"},
                            {LandingMessage: `Мирон Угрин, РК Льві;
                            Олексій Дегтяренко, РК Львів;
                            Соломія Говоруха,
                            голова благодійної організації
                            «Благодійний фонд «ФУНДАЦІЯ
                            КРЕДО»;
                            Олена Лукачук, Ротаракт Клуб Львів`},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "18:00 – 18:00"},
                            {LandingMessage: "Благодійний аукціон «Подаруй дрон легендарній 24-ці»"},
                            {LandingMessage: `Оксана Петрух, РК Львів Інтернешнл`},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "18:30 – 22:00"},
                            {LandingMessage: `Дружня Ротарійська вечеря у
                            ресторані Soprano. Roof Terrace
                            адреса закладу: 
                            площа Князя Святослава, 9, м. Львів
                            `},
                            {LandingMessage: ` 
                            Кількість місць обмежена.`},
                        ],
                    },
                ],
            },
        ]
    },
    
    {
        id: 3,
        header: 'Неділя, 29 жовтня 2023 року',
        arrcontent: [
            {
                header: 'ІІ-й день Ротарійського ділового форуму інновацій, стартапів та можливостей',
                tablecontent: [
                    {
                        tablerow: [
                            {LandingMessage: "10:00 – 12:00"},
                            {LandingMessage: `Екскурсія в Національний університет 
                            «Львівська політехніка»: головний 
                            корпус, машинна зала, астрономічна 
                            обсерваторія
                            `},        
                            {LandingMessage: `
                            адреса зустрічі:
                            вул. Степана Бандери 12, м. Львів
                            `},        
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "12:00 – 13:00"},
                            {LandingMessage: `Візит у пілотний цех виготовлення 
                            гідрогелевих засобів медичного 
                            призначення Aquior`},
                            {LandingMessage: "адреса зустрічі: площа Святого Юра 9, м. Львів"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "13:00 – 13:30"},
                            {LandingMessage: "Відвідини Собору Святого Юра – головної святині українських греко-католиків"},
                            {LandingMessage: "адреса зустрічі: площа Святого Юра 5, м. Львів "},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "13:30 – 14:00"},
                            {LandingMessage: "Кава у Святоюрській кав’ярні "},
                            {LandingMessage: "Оплачується самостійно. адреса зустрічі: площа Святого Юра 5, м. Львів"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "14:00 – 15:00"},
                            {LandingMessage: "Прогулянка Митрополичими садами Собору Святого Юра"},
                            {LandingMessage: `
                            адреса зустрічі:
                            площа Святого Юра 5, м. Львів`},
                        ],
                    },
                ],
            },
            {
                content: [
                    {LandingMessage: "*У програмі можливі зміни."},
                ],
            },
        ]
    },
    {
        id: 5,
        header: 'Організатори:',
        
        cards: [
            {
                img: 'https://bc.rotary.org.ua/images/bussiness_councis/1.webp',
                content: [
                    {LandingMessage: "Комітет професійного служіння"},
                    {LandingMessage: "Ділова Рада Ротарі Інтернешнл Д2232"},
                ],
                name: "Олексій Дегтяренко",
                phone: "+380673408272",
                email: "advokatam@i.ua",
            },
        ],
    },
]

const arrList1_eng = [
    {"LandingListItem": "Heads of Club Committees for Professional Service", url: ""},
    {"LandingListItem": "members of the RI D2232 Business Council", url: ""},
    {"LandingListItem": "presidents of Rotary and Rotaract clubs", url: ""},
    {"LandingListItem": "Governor Assistants", url: ""},
    {"LandingListItem": "Heads of District Committees", url: ""},
    {"LandingListItem": "all Rotarians and Rotaractors interested in the development of innovative developments and startups", url: ""},
];

const defaultData_eng = [
    {
        id: 33,
        img: HOME1_1,
    },
    {
        id: 0,
        header: 'Venue:',
        img: 'https://res.cloudinary.com/tyko/image/upload/v1697373888/BetaRotarySite/EventPresentation/BusinessCouncil/Untitled-3_v9ipg1.jpg',
        content: [
            {LandingMessage: "Lviv, Prince Sviatoslav Square, 5, TAURUS hotel, conference hall № 4 (second floor)"},
            {LandingMessage: "When booking a hotel, all forum participants receive a 15% discount with the code word: Rotary International"},
            {LandingButtonLink: "Hotel booking on the website", link: "https://hotel-taurus.com/"},
        ],
    },
    {
        id: 1,
        header: 'Saturday, October 28, 2023',
        arrcontent: [
            {
                img: 'https://res.cloudinary.com/tyko/image/upload/v1697374557/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0/Untitled-4_pciblu.jpg',
                content: [
                    {LandingMessage: "Recommended forum participants:"},
                    {LandingList: arrList1_eng},
                ],
            },
            {
                img: 'https://naimaproject.eu/wp-content/uploads/2020/06/handshake-3382503_1920.jpg',
                content: [
                    {LandingMessage: "An online format will be provided only for Rotarians who are abroad (a link to participate in the online event will be sent to you on October 27 to the email addresses provided during registration)."},
                ],
            }
        ],
    },
    {
        id: 2,
        header: 'Program - Saturday, October 28, 2023',
        arrcontent: [
            {
                header: 'Rotary Business Forum of Innovations, Start-ups, and Opportunities Program.',
                tablecontent: [
                    {
                        tablerow: [
                            {LandingMessage: "10:00 – 11:00"},
                            {LandingMessage: "Participant registration"},                
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "11:00 – 11:05"},
                            {LandingMessage: "Opening of the Forum, the National Anthem of Ukraine, and the Rotary Anthem. A moment of silence in memory of the fallen Defenders of Ukraine"},                
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "11:05 – 11:10"},
                            {LandingMessage: "Welcoming speech by the head of the Lviv Regional Military Administration"},
                            {LandingMessage: "Maksym Kozytskyi"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "11:10 – 11:15"},
                            {LandingMessage: "Welcoming speech by the acting head of the Lviv Regional Council"},
                            {LandingMessage: "Yuriy Kholod"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "11:15 – 11:20"},
                            {LandingMessage: "Welcoming speech from the Executive Director of Rotary International"},
                            {LandingMessage: "Ivan Hevko, RI"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "11:20 – 11:30"},
                            {LandingMessage: "Introductory speech by the Governor of District 2232 Rotary International"},
                            {LandingMessage: "Myron Ugrin, RC Lviv"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "11:30 – 11:40"},
                            {LandingMessage: "Welcoming speech by the head of the Business Council of District 2232 Rotary International"},
                            {LandingMessage: "Oleksiy Dehtyarenko, RC Lviv"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "11:40 – 11:50"},
                            {LandingMessage: "Welcoming speech from the leadership of Kredobank – the main partner of the forum"},
                            {LandingMessage: "Oksana Stets’kiv, Executive Director of PJSC 'Kredobank'"},
                        ],
                    },
                ],
            },
            {
                header: 'Block 1. Innovative developments and startups of Rotarians in Ukraine and the world',
                tablecontent: [
                    {
                        tablerow: [
                            {LandingMessage: "11:50 – 12:00"},
                            {LandingMessage: "Aquior hydrogel medical devices"},
                            {LandingMessage: "Volodymyr Samarik, Volodymyr Starchevsky, RC Lviv Centre"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "12:00 – 12:10"},
                            {LandingMessage: "Wind of change: mechanical windmill of the future"},
                            {LandingMessage: "Sergii Pylypiv, RC Kharkiv International"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "12:10 – 12:20"},
                            {LandingMessage: "Family dairy farms"},
                            {LandingMessage: "Oleksandr Lisovsky, RC Dubno"},
                        ],
                    },
                ],
            },
            {
                header: 'Block 2. Support for innovative developments in Ukraine and the world',
                tablecontent: [
                    {
                        tablerow: [
                            {LandingMessage: "12:20 – 12:30"},
                            {LandingMessage: "International programs and competitions for supporting innovations in business"},
                            {LandingMessage: "Orest Filts, Lviv Region Development Agency LOVA"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "12:30 – 12:40"},
                            {LandingMessage: "Programs for supporting innovations and startups in Lviv Region"},
                            {LandingMessage: "Stepan Kuybida, Department of Economic Policy LOVA"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "12:40 – 12:50"},
                            {LandingMessage: "Programs of the Ukrainian Startup Fund in 2023-2024"},
                            {LandingMessage: "Pavlo Kartashov, director of the Ukrainian Startup Fund"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "12:50 – 13:20"},
                            {LandingMessage: "Grant opportunities for business and innovations"},
                            {LandingMessage: "Representatives of USAID, Invest Office, GIZ Ukraine"},
                        ],
                    },
                ],
            },
            {
                header: 'Block 3. Practical aspects of innovation implementation',
                tablecontent: [
                    {
                        tablerow: [
                            {LandingMessage: "13:20 – 13:30"},
                            {LandingMessage: "Modern problems of intellectual property rights protection for innovations and startups"},
                            {LandingMessage: "Oleksiy Dehtyarenko, RC Lviv"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "13:30 – 13:40"},
                            {LandingMessage: "Formation of startup culture and organization of R&D ecosystem"},
                            {LandingMessage: "Olena Lukachuk, Rotaract Club Lviv"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "13:40 – 14:00"},
                            {LandingMessage: "Group photo"},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "14:00 – 15:00"},
                            {LandingMessage: "Lunch buffet break"},
                        ],
                    },
                ],
            },
            {
                header: 'Block 4. Presentation of startups and innovative ideas',
                tablecontent: [
                    {
                        tablerow: [
                            {LandingMessage: "15:00 – 16:30"},
                            {LandingMessage: "Startup presentations. \"Live startup alley\""},
                            {LandingMessage: `BGN ARMO,
                            Decoder,
                            SECRET,
                            Chameleon Age,
                            NPC "Minosaur",
                            Mushroom Coffee,
                            Unlimited Cheese Factory,
                            Agrojet,
                            Energy Vision,
                            I3 Engineering,
                            Electric Vacuum Heater,
                            Mobile Sanitary Solutions,
                            System for cleaning the landscape
                            structure of basins of small rivers,
                            "Happy Laundry" network of laundries and dry cleaners,
                            MedTech-startup Mark,
                            Adaptive clothing for sick or
                            injured people with limited mobility,
                            Innovation Hub,
                            Team Model,
                            Voice teacher "Vishunka"`},
                        ],
                    },
                    {
                        tablerow: [
                            {LandingMessage: "16:30 – 17:00"},
                            {LandingMessage: "Meeting of the Forum's Competition Council"},
                            {LandingMessage: "Organizers, partners, representatives of clusters and associations"},
                        ],
                    },
                ],
            },
            {
                "header": "Block 5. Summing up the forum results",
                "tablecontent": [
                    {
                        "tablerow": [
                            {"LandingMessage": "17:00 – 17:30"},
                            {"LandingMessage": "Awarding startups with cash prizes and honors"},
                            {"LandingMessage": `At the conclusion of the forum, cash prizes will be awarded:
                                1st Prize - 100,000 UAH;
                                2nd Prize - 50,000 UAH;
                                3rd Prize - 30,000 UAH;
                                for the presented innovators' projects/startups, as well as awards from the event partners. 
                                We are also open to suggestions regarding replenishing the prize fund and personal honors from Rotary clubs and companies.`}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "17:30 – 18:00"},
                            {"LandingMessage": "Final word from the forum organizers"},
                            {"LandingMessage": `Myron Ugrin, RC Lviv;
                                Oleksiy Dehtyarenko, RC Lviv;
                                Solomiya Govorukha,
                                head of the charity organization
                                "Charity Fund 'CREDO FOUNDATION'";
                                Olena Lukachuk, Rotaract Club Lviv`}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "18:00 – 18:00"},
                            {"LandingMessage": "Charity auction 'Gift a drone to the legendary 24th'"},
                            {"LandingMessage": "Oksana Petrukh, RC Lviv International"}
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "18:30 – 22:00"},
                            {"LandingMessage": `Friendly Rotary dinner at
                                Soprano. Roof Terrace
                                restaurant address: 
                                Prince Sviatoslav Square, 9, Lviv city`},
                            {"LandingMessage": `Paid separately with a prior reservation (1600 UAH per person). 
                                The number of places is limited.`}
                        ],
                    },
                ],
            },
        ]
    },
    {
        "id": 3,
        "header": "Sunday, October 29, 2023",
        "arrcontent": [
            {
                "header": "II-day of the Rotary Business Forum of Innovations, Startups, and Opportunities",
                "tablecontent": [
                    {
                        "tablerow": [
                            {"LandingMessage": "10:00 – 12:00"},
                            {"LandingMessage": `Tour to the National University 
                            "Lviv Polytechnic": main building, 
                            machine hall, astronomical observatory`},        
                            {"LandingMessage": `Paid separately.
                            meeting address:
                            Stepana Bandera Street 12, Lviv city
                            `},        
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "12:00 – 13:00"},
                            {"LandingMessage": `Visit to the pilot workshop for 
                            the production of Aquior hydrogel 
                            medical devices`},
                            {"LandingMessage": "meeting address: St. George's Square 9, Lviv city"},
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "13:00 – 13:30"},
                            {"LandingMessage": "Visit to the St. George's Cathedral – the main shrine of the Ukrainian Greek Catholics"},
                            {"LandingMessage": "meeting address: St. George's Square 5, Lviv city "},
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "13:30 – 14:00"},
                            {"LandingMessage": "Coffee at the St. George's cafe"},
                            {"LandingMessage": "Paid separately. meeting address: St. George's Square 5, Lviv city"},
                        ]
                    },
                    {
                        "tablerow": [
                            {"LandingMessage": "14:00 – 15:00"},
                            {"LandingMessage": "Walk in the Metropolitan Gardens of St. George's Cathedral"},
                            {"LandingMessage": `Paid separately.
                            meeting address:
                            St. George's Square 5, Lviv city`},
                        ]
                    },
                ]
            },
            {
                "content": [
                    {"LandingMessage": "*Changes in the program are possible."},
                ]
            }
        ]
    },
    {
        "id": 5,
        "header": "Organizers:",
    
        "cards": [
            {
                "img": "https://bc.rotary.org.ua/images/bussiness_councis/1.webp",
                "content": [
                    {"LandingMessage": "Committee of Professional Service"},
                    {"LandingMessage": "Business Council of Rotary International D2232"},
                ],
                "name": "Oleksiy Dehtyarenko",
                "phone": "+380673408272",
                "email": "advokatam@i.ua",
            },
        ],
    } 
];


const imgArr = [
    "https://res.cloudinary.com/tyko/image/upload/v1698949677/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_9033_p3z223.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949667/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8752_z15xov.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949675/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8778_kmmgsb.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949648/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8745_w0bkic.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949656/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8732_rlnbc7.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949666/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8747_srvjzc.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949666/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_9046_h6vefz.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949658/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8992_1_wg9rv9.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949661/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8888_ugnbab.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949659/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8803_bwzdny.jpg",

    "https://res.cloudinary.com/tyko/image/upload/v1698949674/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8822_svofzx.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949666/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8972_dvtvht.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949661/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8949_kgbuaf.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949661/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8920_ynfete.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949671/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8835_lojxwl.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949677/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8857_shpzp0.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949674/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8822_svofzx.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949674/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8845_sza2ot.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949671/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8835_lojxwl.jpg",
    "https://res.cloudinary.com/tyko/image/upload/v1698949653/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_9096_wvaud0.jpg",

    "https://res.cloudinary.com/tyko/image/upload/v1698949652/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_8813_gnvezj.jpg",
]