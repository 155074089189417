import React, { useState, useEffect } from 'react';
import { getSeminars, getSeminarById, createSeminar, updateSeminar, deleteSeminar } from '../../api/ts/materials_seminars';
import { Container, Button } from '@mui/material';
import { Paper } from '@mui/material';

import AdminTableComponent from '../../pages/MyAdministrations/AdminTableComponent';

export const tableTitles = {
    id: "id",
    rotaryYear: "rotaryYear",
    date: "date",
    sity: "sity",
    title: "title",
    fileUrl: "fileUrl",
    websiteUrl: "websiteUrl",
};
export const emptyData = {
    rotaryYear: '',
    date: '',
    sity: '',
    title: '',
    fileUrl: '',
    websiteUrl: '',
}

const AdminMaterialsSeminars = () => {
    const [semanars, setSemanars] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            setLoading(true);
            const d = await getSeminars();
            setSemanars(d);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    const refreshData = async () => {
        fetchData();
    };

    return (
        <Container>
        <Paper>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {loading && <p>Завантаження...</p>}
            <AdminTableComponent 
                data={semanars}
                tableTitles={tableTitles}
                emptyData={emptyData}
                createItem={createSeminar}
                updateItem={updateSeminar}
                deleteItem={deleteSeminar}
                refreshData={refreshData}
            />
        </Paper>
        </Container>
    );
};

export default AdminMaterialsSeminars;
