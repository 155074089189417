import React, { useEffect, useState } from "react";

import MainPage from "../components/Main";
import EventsFrame from "./EventsFrame";

import WordTypePage_TempShare from '../components/WordTypePage_TempShare';

const TermsofservicePage = () => {
    return (
        <>
        <br/>Terms of Service
        <br/>Introduction
        <br/>These Terms of Service ("Terms") govern your use of our website and services. By accessing or using our website, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use our website.
        <br/>
        <br/>Use of Our Services
        <br/>Eligibility
        <br/>You must be at least [minimum age] years old to use our services. By using our services, you represent and warrant that you meet the eligibility requirements.
        <br/>
        <br/>Account Registration
        <br/>You may need to register for an account to access certain features of our services. You agree to provide accurate, complete, and current information during the registration process and to update such information as necessary. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
        <br/>
        <br/>Prohibited Activities
        <br/>You agree not to engage in any of the following prohibited activities:
        <br/>
        <br/>Violating any applicable laws or regulations
        <br/>Infringing upon or violating our intellectual property rights or the rights of others
        <br/>Using our services to transmit or distribute any harmful or illegal content
        <br/>Attempting to interfere with or disrupt the integrity or performance of our services
        <br/>Engaging in any fraudulent, deceptive, or misleading activities
        <br/>Intellectual Property
        <br/>All content, trademarks, logos, and other intellectual property on our website are the property of [Your Company Name] or its licensors. You may not use, reproduce, modify, or distribute any of our intellectual property without our prior written consent.
        <br/>
        <br/>Termination
        <br/>We may terminate or suspend your access to our services at any time, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use our services will immediately cease.
        <br/>
        <br/>Disclaimer of Warranties
        <br/>Our services are provided "as is" and "as available" without any warranties of any kind, whether express or implied. We do not warrant that our services will be uninterrupted, error-free, or secure.
        <br/>
        <br/>Limitation of Liability
        <br/>To the fullest extent permitted by law, [Your Company Name] and its affiliates, officers, directors, employees, and agents will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
        <br/>
        <br/>Your use or inability to use our services
        <br/>Any unauthorized access to or use of our servers and/or any personal information stored therein
        <br/>Any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through our services
        <br/>Governing Law
        <br/>These Terms will be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
        <br/>
        <br/>Changes to These Terms
        <br/>We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this page. You are advised to review these Terms periodically for any changes. Your continued use of our services after the posting of changes constitutes your acceptance of such changes.</>
  );
}

export default TermsofservicePage;