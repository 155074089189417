import React, { useState } from 'react';
import { TextField, MenuItem, FormControl, Select, Button, Box, Typography, Paper } from '@mui/material';

function SimpleGoogleForm() {
  const [shortAnswer, setShortAnswer] = useState('');
  const [dropdownAnswer, setDropdownAnswer] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Обробка даних форми
    console.log('Short Answer:', shortAnswer);
    console.log('Dropdown Answer:', dropdownAnswer);
  };

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        '& > :not(style)': { m: 1, width: '100%', maxWidth: '500px' },
        backgroundColor: '#e6f1f9',
      }}
    >
      <Typography variant="h5" sx={{ my: 2 }}>
        Приклад Форми
      </Typography>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Коротка відповідь"
            variant="outlined"
            fullWidth
            value={shortAnswer}
            onChange={(e) => setShortAnswer(e.target.value)}
          />
          <FormControl fullWidth>
            <Select
              value={dropdownAnswer}
              onChange={(e) => setDropdownAnswer(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem disabled value="">
                <em>Спадний список</em>
              </MenuItem>
              <MenuItem value="Option 1">Опція 1</MenuItem>
              <MenuItem value="Option 2">Опція 2</MenuItem>
              <MenuItem value="Option 3">Опція 3</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained" type="submit" sx={{ mt: 1, bgcolor: 'primary.main' }}>
            Відправити
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default SimpleGoogleForm;
