import React, { useEffect, useState, useContext, useRef } from "react";
import { Button, Box, Container, Grid, Typography } from '@mui/material'

import {getBusinessCouncil} from '../../api/getBusinessCouncil'
import { LOCALES } from '../../i18n/locales';
import {LocalContext} from '../../tools/LanguageContextProvider';

import MainLanding from '../../pages/LandingPages/MainLanding';
import LandingArrContentBlock from '../../components/LandingArrContentBlock';
import LandingContentBlock from '../../components/LandingContentBlock';
import LandingContentBlock2 from '../RC_Cherkasy/fixcomponents/LandingContentBlock2';
import LandingFooter from '../../components/LandingFooter';
import Header from "../../modules/Header";
import {tr} from '../../i18n/tr';

import HOME1_1 from '../../assets/content/business_council/Lviv1.jpg';
import HOME1_2 from '../../assets/content/business_council/Lviv2.jpg';
import HOME1_3 from '../../assets/content/business_council/Lviv3.jpg';
import HOME1_4 from '../../assets/content/business_council/business5.jpg';
import HOME1_5 from '../../assets/content/business_council/Lviv4.jpg';

const  designType='chicago';

const getMenu = (submenu) => {
    return [
        {title: "Про Комітет",
            arr: submenu
        },
        {title: "Інші сторінки",
            arr: [
                { title: 'Форум Комітету професійного служіння', lref: '/event_BusinessCouncil'}
            ]
        },
    ]
}

const BusinessCouncilPage = () => {
    const images = [ HOME1_4, 
                "https://res.cloudinary.com/tyko/image/upload/v1698949677/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/IMG_9033_p3z223.jpg",
                HOME1_1,
                "https://res.cloudinary.com/tyko/image/upload/v1698954457/BetaRotarySite/EventPresentation/%D0%94%D1%96%D0%BB%D0%BE%D0%B2%D0%B0%D0%A0%D0%B0%D0%B4%D0%B0%D0%A4%D0%BE%D1%82%D0%BE%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B5%D1%8F/photo_2023-11-02_21-44-38_fjbf2j.jpg",
           
                HOME1_3,
                HOME1_5,
                
                HOME1_2,
            ];
    const { currentLocale, setCurrentLocale } = useContext(LocalContext);
    const [data, setData] = useState([]);
    const [landmenu, setLandMenu] = useState([]);
    const [dataEng, setDataEng] = useState([]);
    const [dataUkr, setDataUkr] = useState([]);
    const menuRefs = useRef([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
            const d_eng = await getBusinessCouncil(false);
            setDataEng(d_eng);
            const d_ukr = await getBusinessCouncil(true);
            setDataUkr(d_ukr);
            const d = currentLocale===LOCALES.UKRAINIAN ? d_ukr : d_eng;
            setData( d );
            
            
            const arr = [];
            d.map(() => {
                const ref = React.createRef();
                menuRefs.current.push(ref);
                arr.push(
                    {title: d.title, ancor: ref},
                )
                console.log("========= menuRefs="+menuRefs.current.length);
            })
            setLandMenu(arr);

            console.log("DanatePage get data list success. const="+d_ukr.length);
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        setData( currentLocale===LOCALES.UKRAINIAN ? dataUkr : dataEng);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale])

    return (
        <MainLanding menu={getMenu(landmenu)} noWarningMode="hide">
            <div style={{ position: 'relative' }}>
                <Header images={images} /> 
              <Typography 
                variant="h3" 
                style={{
                    zIndex: 1000,
                  position: 'absolute',
                  width: '100%',
                  top: '2%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  padding: 2,
                  paddingTop: '50px',
                  color: 'white', // Задає колір тексту, якщо потрібно
                  _backgroundColor: 'rgba(0, 0, 0, 0.3)', // Задає фон, якщо потрібно
                  fontSize: '2vw',
                }}
              >
                {tr('Особиста сторінка комітету')}
              </Typography>
              <Typography 
                variant="h3" 
                style={{
                    zIndex: 1000,
                  position: 'absolute',
                  width: '100%',
                  top: '12%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  padding: 2,
                  paddingTop: '50px',
                  color: 'white', // Задає колір тексту, якщо потрібно
                  backgroundColor: 'rgba(0, 0, 0, 0.3)', // Задає фон, якщо потрібно
                  fontSize: '6vw',
                  fontWeight: 'bold',
                }}
              >
                 <br/>{tr('Ділова Рада Ротарі D2232')}
              </Typography>

              <Button 
                    variant='contained'
                    size='large'
                    sx={{ textTransform: 'none' }} 
                    onClick={() => {
                        
                            window.open('https://l.facebook.com/l.php?u=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSeJZx8amGNDKnpLIq4IzQ02U5IkBsYsO5-71utdyxJ3VXYZ4Q%2Fviewform%3Ffbclid%3DIwAR0Ros0negN-SPUksgyX_5RYc5YmZ8KYlCWLQjL0FjvEZVsTmqawaXnPHUI&h=AT0uTQAVkFLFeVqrslyP_k5qYDVds0vDq4MRWOVi0P9Yn2Q9T6qWLPJVAdD_RZ_s2Z2DKu3jvXvRmPoQBcj1HSdnhb1_nnmA7AtGSwrkUANS6AaV3hQFJjAyK3EzzGBtviGM5A', '_blank');
                            //window.scrollTo(0, 0);
                            console.log("LandingButtonLink click")
                        
                    }}
                    style={{
                        zIndex: 1000,
                        position: 'absolute',
                        _width: '30%',
                        bottom: '30px',
                        right: '50px',
                        _transform: 'translate(-50%, -50%)',
                        background: 'rgba(255, 215, 0, 0.8)',
                        fontSize: 'max(2vw, 14pt)',
                    }}
                >
                    {tr("Стати членом Ділової Ради")}
                </Button>

            </div>
          
            {data && data.map((item,index) => (
                <>
                {(item.content || item.img || item.cards || item.cards2) && <LandingContentBlock id={index} landItem={item} designType='busines' />}
                {item.arrcontent && <LandingArrContentBlock id={index} arrItem={item} designType='busines' />}
                </>
            ))}

        <LandingFooter />
        </MainLanding>
  );
}

export default BusinessCouncilPage;