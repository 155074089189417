export const getAdminPageContent = () => {
    return defaultData;
};

const defaultData = [
    {
        id: 1,
        header: 'Адміністрування сторінок Дистрикту 2232',
        content: [
            {LandingLink: "Адміністрування Комюніке", link: "/admin_communique"},
            {LandingMessage: "."},
            {LandingLink: "Адміністрування Подій", link: "/admin_events"},
            {LandingMessage: "."},
            {LandingLink: "Адміністрування Журналів", link: "/admin_magazines"},
            {LandingMessage: "."},
        ],
    },
];