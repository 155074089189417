import React, { useState } from 'react';
  
const LandingVideo = ({ title, link }) => {
  
    return (
        
        <video width="100%"  controls>
            <source src={link} type="video/mp4" />
            Ваш браузер не підтримує відтворення відео.
        </video>
    );
};

export default LandingVideo;
