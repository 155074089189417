import React, { useEffect, useState, useRef } from "react";
import { Box, Container, Grid, Typography } from '@mui/material'

import MainPage from "../../components/Main";
import Header from "../../modules/Header";
import LandingContentBlock from '../../components/LandingContentBlock';

import {getFellowshipsLanding} from '../../api/getFellowshipsLanding';

const breadcrumbs = "Ротарійські Товариства";

const FellowshipsPage = () => {
    const images = [ "https://iyfr.tyko.com.ua/static/media/yacht5.bce343a534077f075fbf.jpg", 
            "https://ichef.bbci.co.uk/news/640/cpsprodpb/121BF/production/_104757147_gettyimages-962094932.jpg",
            "https://w.forfun.com/fetch/c7/c70c2093ccaca394141213a37a922eab.jpeg",
            "https://res.cloudinary.com/tyko/image/upload/v1697914201/BetaRotarySite/SitePages/4%D1%854_h757va.jpg",
            "https://bykvu.com/wp-content/uploads/2015/11/12/05db11a6144f377bd17ed3bf6e1719f3-780x475.jpg"];
    const [data, setData] = useState([]);
    const menuRefs = useRef([]);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const d = await getFellowshipsLanding();
          setData(d);
          console.log("getFellowshipsLanding get list success. const="+d.length);
          
          d.map((item, index) => {
            menuRefs.current.push(React.createRef());
            console.log("========= menuRefs="+menuRefs.current.length);
            //myRefs.current[index].current.value = "Input 1";
          })
        } catch (error) {
          console.log("getHomeLanding get list error="+error);
        }
      };
      fetchData();
    }, [])

    return (
        <MainPage breadcrumbs={breadcrumbs} >
            <Header images={images} text='Ротарійські Товариства' />
            
            {data && data.map((item,index) => (
                <div ref={menuRefs.current[item.id]}>
                <LandingContentBlock landItem={item} image={item.img} key={index} />
                </div>
            ))}
        </MainPage>
  );
}

export default FellowshipsPage;