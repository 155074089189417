import { LOCALES } from '../../../i18n/locales';

export const getClubPageStructure = (local) => {

    const translations_ukr = {
        "Fun Business": "Fun Business",
        "Контактна особа": "Контактна особа",
        "Міссія та цінності": "Міссія та цінності",
        "Напрямки": "Напрямки",
        "Наші курси": "Наші курси",
        "Найближчі заходи": "Найближчі заходи",
        "Наша бібліотека": "Наша бібліотека",
        "Команда": "Команда",
        "Партнери": "Партнери",
        "Консультація": "Консультація",
        "Контакти": "Контакти",
    };

    const translations_eng = {
        "Fun Business": "Fun Business",
        "Контактна особа": "Contact Person",
        "Міссія та цінності": "Mission and Values",
        "Напрямки": "Directions",
        "Наші курси": "Our Courses",
        "Найближчі заходи": "Upcoming Events",
        "Наша бібліотека": "Our Library",
        "Команда": "Team",
        "Партнери": "Partners",
        "Консультація": "Consultation",
        "Контакти": "Contacts",
    };
    

    const translations = local === LOCALES.UKRAINIAN ? translations_ukr : translations_eng;

    return {
        navbar: {
            title: translations["Fun Business"],
            logoUrl: "https://res.cloudinary.com/tyko/image/upload/v1703600300/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/%D0%9B%D0%BE%D0%B3%D0%BE_mewvcu.png",
            languages: [
                "ua-UA",
                "en-US",
            ],
            menu: [
                {
                    title: translations["Міссія та цінності"],
                    lref: '/r_learning',
                },
                {
                    title: translations["Напрямки"],
                    lref: '/r_learning',
                },
                {
                    title: translations["Наші курси"],
                    lref: '/r_learning',
                },
                {
                    title: translations["Найближчі заходи"],
                    lref: '/r_learning',
                },
                {
                    title: translations["Наша бібліотека"],
                    lref: '/r_learning',
                },
                {
                    title: translations["Команда"],
                    lref: '/r_learning',
                },
                {
                    title: translations["Партнери"],
                    lref: '/r_learning',
                },
                {
                    title: translations["Консультація"],
                    lref: '/r_learning',
                },
                {
                    title: translations["Контакти"],
                    lref: '/r_learning',
                },
            ],
        },
        footer: {
            contactPerson: {
                name: translations["Контактна особа"],
                email: "e@e.com",
            },
            club: {
                fb: "fb1",
                insta: "insta1",
                linkedIn: "linkedIn1",
            }
        }
    }
}
