import React, { useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  Container,
  Grid,
  Box
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import axios from 'axios'; 
import {URL_TO_API_SERVER} from '../../api/LoginAndSuccess/var';

function PasswordReset({onResetPwd, onBack, domenUrl}) {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [debugMessage, setDebugMessage] = useState('');

    const navigate = useNavigate();
    const currentHost = `${window.location.protocol}//${window.location.host}`;

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        //onResetPwd();
    };

    const isValidEmail = (email) => {
        // Регулярний вираз для базової перевірки формату емейлу
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return regex.test(email);
        };

    const sendApiPostRequest = async (postData, apiUrl) => {
        // Налаштування запиту
        const config = {
            headers: {
            'Content-Type': 'application/json' // Встановлюємо заголовок Content-Type на JSON
            }
        };
        
        // Відправляємо POST-запит
        const response = await axios.post(apiUrl, postData, config)
            .then(response => {
                // Обробка відповіді
                console.log('Відповідь від сервера:', response.data);
                // Виводимо повідомлення про статус
                setDebugMessage(response.data);
    
                // код обробки відповіді
                if (/*response.data === 'true' || response.data === true || */(response.data && response.data.startsWith("Success")) ) {
                    console.log('forgot = ', email);
                    //onResetPwd(); 
                    setSuccessMessage('Лист з інструкціями для відновлення паролю відправлено на вашу адресу Email.');
                    return true;
                }else {
                    setError('Помилка відправки');
                    setDebugMessage(response.data);
                    return false;
                };
            })
            .catch(error => {
            // Обробка помилок
            console.error('Помилка при відправці запиту:', error);
            setError('Помилка при відправці запиту:'+ error);
            return false;
            });
            return false;
    };

    const clearMessage = () => {
        setSuccessMessage(null);
        setError(null);
        setDebugMessage(null);
    }

    const handleSubmit = async(e) => {
        e.preventDefault();

        clearMessage();

        // Перевірка введеного email
        if (!email) {
        setError('Поле Email не може бути порожнім');
        return;
        }
        if (!isValidEmail(email)) {
            setError('Введіть коректну адресу електронної пошти');
            return;
        }

        // Очистка повідомлень про помилку та успіх
        setError('');
        try {
            const data = {
                email,
                domenUrl: domenUrl,
                loginHost: currentHost,
            };
            sendApiPostRequest(data, URL_TO_API_SERVER+'api_forgetPwd.php');
        } catch (error) {
            console.error('Помилка:', error);
            setError('Помилка:'+error);
        }

        

        // Відправка запиту на сервер для відновлення паролю
        // Ваша логіка тут
    };

    const handleReturnToLogin = () => {
        onBack();
    };

    return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h4" align="center" marginTop='60px' gutterBottom>
            Відновлення паролю
        </Typography>
        {error && (
            <Typography variant="body2" color="error" align="center" paragraph>
            error:{error}
            </Typography>
        )}
        {successMessage && (
            <Typography variant="body2" color="success" align="center" paragraph>
            {successMessage}
            </Typography>
        )}
        {debugMessage && (
            <Typography variant="body2" color="gray" align="center" paragraph>
            debugMessage:{debugMessage}
            </Typography>
        )}
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                fullWidth
                type="email"
                label="Email"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                required
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                >
                Відправити лист
                </Button>
            </Grid>
            </Grid>
        </form>
        <Button
          variant="text"
          color="primary"
          onClick={handleReturnToLogin}
        >
          Повернутися до сторінки авторизації
        </Button>
        </Box>
    </Container>
    );
}

export default PasswordReset;
