import React, { useState, useEffect } from 'react';
import { Container, Button, TextField, } from '@mui/material';
import {  Paper} from '@mui/material';

import AdminTableComponent from './AdminTableComponent';
import { getAll, getItemById, createItem, updateItem, deleteItem, tableTitles } from "../../api/ts/api_magazines";

export const emptyData = {
    id: '',
    order_: '',
    rotarYear: '',
    title: '',
    month: '',
    url: '',
};

const AdminMagazines = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            setLoading(true);
            const d = await getAll();
            setData(d);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    const refreshData = async () => {
        fetchData();
    };

    return (
        <Container>
        <Paper>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {loading && <p>Завантаження...</p>}
            <AdminTableComponent 
                data={data}
                tableTitles={tableTitles}
                emptyData={emptyData}
                createItem={createItem}
                updateItem={updateItem}
                deleteItem={deleteItem}
                refreshData={refreshData}
            />
        </Paper>
        </Container>
    );
};

export default AdminMagazines;
