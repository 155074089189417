import HOME1_1 from '../assets/content/home/AOF_group_circle_color_no_title_EN.jpg';
import HOME1_2 from '../assets/content/temp/dreaming.jpg';
import HOME2_1 from '../assets/content/temp/rotary1.jpg';
import HOME2_2 from '../assets/content/temp/rotary2.jpg';
import HOME2_3 from '../assets/content/temp/rotary3.png';
import HOME2_maps from '../assets/content/temp/maps.jpg';
import HOME2_ukr from '../assets/content/temp/save_ukraine.jpg';
import HOME2_ukr2 from '../assets/content/temp/support_ukraine.jpg';
import HOME2_icc from '../assets/content/temp/word_icc.jpg';
import HOME2_news from '../assets/content/temp/ukraine_news.jpeg';
import HOME2_war from '../assets/content/temp/war.png';
import D2232LOGO from '../assets/img/D2232.png';

export const getHomeLanding = () => {
    return defaultData;
};

export function getMenuHeaders(data) {
    return data.map(item => ({
        id: item.id,
        header: item.header
    }));
}

const arrList1 = [
    /*{"LandingListItem": "Боротьба з поліомієлітом", linkText: "лінк РІ", url: "https://www.rotary.org/en/our-causes/ending-polio"},*/
    {"LandingListItem": "home_directions1", linkText: "лінк РІ", url: "https://www.rotary.org/en/our-causes/promoting-peace"},
    {"LandingListItem": "home_directions2", linkText: "лінк РІ", url: "https://www.rotary.org/en/our-causes/fighting-disease"},
    {"LandingListItem": "home_directions3", linkText: "лінк РІ", url: "https://www.rotary.org/en/our-causes/providing-clean-water"},
    {"LandingListItem": "home_directions4", linkText: "лінк РІ", url: "https://www.rotary.org/en/our-causes/saving-mothers-and-children"},
    {"LandingListItem": "home_directions5", linkText: "лінк РІ", url: "https://www.rotary.org/en/our-causes/supporting-education"},
    {"LandingListItem": "home_directions6", linkText: "лінк РІ", url: "https://www.rotary.org/en/our-causes/growing-local-economies"},
    {"LandingListItem": "home_directions7", linkText: "лінк РІ", url: "https://www.rotary.org/en/our-causes/protecting-environment"},
];
const arrList2 = [
    {"LandingListItem": "Обмін в рамках Служіння новим поколінням", url: ""},
    {"LandingListItem": "Громадські корпуси Ротарі (RCC)", url: ""},
    {"LandingListItem": "Ротарійські дружні обміни", url: ""},
    {"LandingListItem": "Ротарійські молодіжні обміни", url: ""},
    {"LandingListItem": "Ротарійська відзнака для молодих лідерів (RYLA)", url: ""},
    {"LandingListItem": "Ротарійські групи дії", url: ""},
    {"LandingListItem": "Ротарійські Товариства", url: ""},
];
const arrList3 = [
    {"LandingListItem": "Мета Ротарі", url: ""},
    {"LandingListItem": "Основні цінності Ротарі", url: ""},
    {"LandingListItem": "П'ять напрямків служіння", url: ""},
    {"LandingListItem": "Кодекс поведінки ротарійця", url: ""},
    {"LandingListItem": "Тест з 4-ох запитань", url: ""},
];
const arrList4 = [
    {"LandingListItem": "Ліки", url: ""},
    {"LandingListItem": "Генератори", url: ""},
    {"LandingListItem": "Засоби гігієни", url: ""},
];

/*const menuHomeList = [
    {"LandingListItem": "Ліки", url: ""},
    {"LandingListItem": "Генератори", url: ""},
    {"LandingListItem": "Засоби гігієни", url: ""},
];*/

const defaultData = [
    /*{
        id: 0,
        header: 'home_h7',
        img: HOME2_news,
        content: [
            {LandingMessage: "Новина 1"},
            {LandingMessage: "Новина 2"},
            {LandingMessage: "Новина 3"},
            {LandingButtonDetails: "true", link: '/news'}
        ],
    },*/
    {
        id: 1,
        header: 'home_h1',
        img: HOME2_1,
        content: [
            {LandingMessage: 'home_c1'},
        ],
    },
    {
        id: 2,
        header: 'home_h2',//напрямки
        img: HOME1_1,
        content: [
            {LandingMessage: 'home_c2'},
            {LandingList: arrList1},
            {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/our-causes'},
        ],
    },
    {
        id: 3,
        header: 'home_h3',
        img: HOME2_2,
        content: [
            {LandingMessage: 'home_c3'},
            {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/about-rotary'},
        ],
    },
    {
        id: 4,
        header: 'home_h4',
        img: HOME1_2,
        content: [
            {LandingMessage: 'home_c4'},
            {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/about-rotary'},
        ],
    },
    {
        id: 5,
        header: 'home_h5',//історія
        img: HOME2_3,
        content: [
            {LandingMessage: 'home_c5'},
            {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/about-rotary/history'},
        ],
    },
    {
        id: 6,
        header: 'Фундація Ротарі',//Фундація
        img: "https://res.cloudinary.com/tyko/image/upload/v1699441363/BetaRotarySite/SitePages/%D0%A4%D1%83%D0%BD%D0%B4%D0%B0%D1%86%D1%96%D1%8F_xwg57p.jpg",
        content: [
            {LandingMessage: 'Шостий Президент Ротарі Інтернешнл Арч Клампф заснував Фундацію Ротарі у 1917 році на Конгресі РІ в Атланті.'},
            {LandingMessage: 'Фундація Ротарі є неприбутковою корпорацією, що підтримується виключно за рахунок добровільних пожертв Ротарійців і друзів ФР, які поділяють бачення кращого світу запропоноване ФР. '},
            {LandingMessage: 'Місією ФР є надання Ротарійцям інструментів, що просувають глобальне взаєморозуміння, добробут і мир через покращення медичних послуг, підтримку освіти  та подолання бідності. '},
            {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/about-rotary/rotary-foundation'},
        ],
    },
    {
        id: 7,
        header: 'Наші програми',
        img: 'https://res.cloudinary.com/tyko/image/upload/v1697894510/BetaRotarySite/SitePages/programsjpg2_mftg6j.jpg',
        content: [
            {LandingMessage: 'Програми Ротарі це сукупність ресурсів та діяльності, що підтримується Генеральним Секретарем, та орієнтована на забезпечення сервісу Ротарійців, Ротарактівців та інших учасників.'},

            
            {LandingAccordionItem: 'Молодіжні обміни', 
                itemArr: [
                    {LandingMessage: "Молодіжні обміни Rotary сприяють будівництву миру однією молодою особою за раз. Студенти вивчають нову мову, відкривають для себе іншу культуру і справжньо стають глобальними громадянами."},
                    {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/our-programs/youth-exchanges'},
                ]
            },
            {LandingAccordionItem: "Ротарійська нагорода для молодих лідерів (RYLA)", 
                itemArr: [
                    {LandingMessage: 'RYLA - це програма розвитку лідерських навичок для молодих людей, які бажають вивчити нові навички, підвищити свою впевненість та отримати задоволення. Події варіюються від одноденних семінарів до тижневих таборів.'},
                    {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/our-programs/rotary-youth-leadership-awards'},
                ]
            },
            {LandingAccordionItem: 'Товариства миру', 
                itemArr: [
                    {LandingMessage: 'Кожного року Ротарі обирає до 130 фахівців з усього світу для отримання повністю фінансованих академічних стипендій у наших Ротарійських Центрах Миру.'}, 
                    {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/our-programs/peace-fellowships'},
                ]
            },
            {LandingAccordionItem: 'Ротарійські громадські корпуси', 
                itemArr: [
                    {LandingMessage: 'Знаходячи рішення громадських проблем громади, Ротарійський громадський корпусs об’єднує членів Ротарі та тих, хто не є членами, щоб зробити позитивні зміни.'},
                    {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/our-programs/rotary-community-corps'},  
                ]
            },
            {LandingAccordionItem: 'Гранти', 
                itemArr: [
                    {LandingMessage: 'Протягом 100 років Фонд Ротарі претворює ідеї проектів в реальність. Наші клуби отримують фінансування для підтримки гуманітарних проектів, надання стипендій та міжнародних обмінів.'},
                    {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/our-programs/grants'},
                ]
            },
            {LandingAccordionItem: 'Молодіжні програми', 
                itemArr: [
                    {LandingMessage: 'Обмін послугами для молодого покоління - це короткострокова програма, яку можна налаштувати для студентів університету та молодих фахівців віком до 30 років. Учасники можуть розробляти обміни, які поєднують їхні професійні цілі з гуманітарним проектом.'},
                    {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/our-programs/new-generations-service-exchange'},
                ]
            },
            {LandingAccordionItem: 'Дружні обміни', 
                itemArr: [
                    {LandingMessage: 'Дружні обміни — це міжнародна програма обміну для членів Ротарі та друзів, яка дозволяє учасникам по черзі приймати один одного вдома та в клубах. Учасники можуть подорожувати як окремі особи, пари, сім’ї чи групи, і можуть бути членами Ротарі чи ні.'},
                    {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/our-programs/friendship-exchange'},
                ]
            },
            {LandingAccordionItem: 'Стипендії', 
                itemArr: [
                    {LandingMessage: 'Ротарі інвестує понад 7 мільйонів доларів щороку у наших майбутніх лідерів та філантропів, надаючи фінансування стипендій для бакалаврів та аспірантів.'},
                    {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/our-programs/scholarships'},
                ]
            },
            {LandingAccordionItem: 'Сервісні обміни для нового покоління', 
                itemArr: [
                    {LandingMessage: 'Сервісні обміни для нового покоління - це короткострокова програма, яку можна налаштувати для студентів університету та молодих фахівців віком до 30 років. Учасники можуть розробляти обміни, які поєднують їхні професійні цілі з гуманітарним проектом.'},
                    {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/our-programs/new-generations-service-exchange'},
                ]
            },
            {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/our-programs'},
        ],
    },
    {
        id: 8,
        header: 'Наші цінності',
        img: "https://rotary.org.ua/assets/site/images/goals/g2.jpg",
        content: [
            {LandingAccordionItem: 'Мета Ротарі', 
                itemArr: [
                    {LandingMessage: 'Мета Ротарі – заохочувати та сприяти ідеалам служіння, як основи для гідного підприємництва, зокрема сприяти:'},
                    {LandingList: [
                        {"LandingListItem": "Розвитку мережі знайомств, як можливості для служіння;"},
                        {"LandingListItem": "Високим етичним стандартам у бізнесі та професійній діяльності; визнанню важливості усіх корисних професій; визнанню роду занять кожного Ротарійця як можливості для служіння суспільству;"},
                        {"LandingListItem": "Застосуванню ідеалів служіння у особистому, бізнесовому та громадському житті кожного Ротарійця;"},
                        {"LandingListItem": "Покращенню міжнародного взаєморозуміння, доброї волі та миру через світове братство людей бізнесу та професіоналів, об'єднаних ідеалом служіння."},
                    ]},
                ]
            },
            {LandingAccordionItem: 'Основні цінності Ротарі', 
                itemArr: [
                    {LandingMessage: 'У 2007 році Ротарі схвалило такі основні цінності, як частину Стратегічного плану:'},
                    {LandingList: [
                        {"LandingListItem": "Служіння"},
                        {"LandingListItem": "Братство"},
                        {"LandingListItem": "Єдність"},
                        {"LandingListItem": "Різноманітність"},
                        {"LandingListItem": "Лідерство"},
                        {LandingMessage: "Ці п'ять цінностей є основними рисами Ротарійця. Їх підтримують Ротарійці усього світу."},
                    ]},
                ]
            },
            {LandingAccordionItem: "П'ять напрямків служіння", 
                itemArr: [
                    {LandingList: [
                        {"LandingListItem": "Клубне Служіння, перший Напрямок, включає у себе діяльність членів Клубу, якою вони мають займатися, щоб забезпечити успішне функціонування Клубу."},
                        {"LandingListItem": "Професійне Служіння, другий Напрямок, має за мету просування високих етичних стандартів у бізнесі та професійній діяльності, визнання гідності усіх достойних професій та сприяння застосуванню ідеалів служіння у будь-якій професійній діяльності. Роль членів Клубу полягає у тому, щоб поводитись особисто та у бізнесі у відповідності до принципів Ротарі та використовувати свої професійні навички для реалізації клубних проектів, які відповідають проблемам та потребам суспільства."},
                        {"LandingListItem": "Служіння Громаді, третій Напрямок, полягає у різноманітних зусиллях, яких докладають члени Клубів (іноді у співпраці з іншими особами) для покращення якості життя людей, які живуть у місцевості, де розташований Ротарі Клуб."},
                        {"LandingListItem": "Міжнародне служіння, четвертий Напрямок, полягає у діяльності, яку члени Клубів провадять для покращення світового взаєморозуміння, доброї волі та миру шляхом розширення мережі знайомств з людьми у інших країнах, пізнання їхніх культур, звичаїв, досягнень, прагнень та проблем шляхом спілкування та взаємодії у всіх видах клубної діяльності та проектах, які спрямовані на допомогу людям у інших країнах."},
                        {"LandingListItem": "Молодіжне Служіння, п'ятий Напрямок, визнає позитивні зміни, які впроваджуються молоддю та молодими дорослими людьми шляхом розвитку лідерських якостей, залучення до місцевих та міжнародних проектів служіння та програм обмінів, які збагачують їхніх учасників та сприяють світовому миру та культурному взаєморозумінню"},
                    ]},
                ]
            },
            {LandingAccordionItem: 'Кодекс поведінки ротарійця', 
                itemArr: [
                    {LandingMessage: "Як Ротарієць, я зобов'язуюсь:"},
                    {LandingList: [
                        {"LandingListItem": "Діяти чесно та дотримуватись високих етичних стандартів в моєму особистому та професійному житті"},
                        {"LandingListItem": "Взаємодіяти з іншими добропорядно та відноситись з повагою до них та їхніх професійних занять"},
                        {"LandingListItem": "Використовувати мої професійні навички в Ротарі у менторстві для молоді, для допомоги особам з особливими потребами, та для піднесення рівня життя в моїй громаді та у світі"},
                        {"LandingListItem": "Уникати поведінки яка негативно впливає на Ротарі або інших Ротарійців"},
                        {"LandingListItem": "Допомагати у підтриманні на Ротарійських зустрічах, заходах та діяльності вільного від домагань середовища; повідомляти про кожну підозру щодо домагань, та допомагати забезпечити захист від помсти тим, хто повідомив про факт домагань."},
                    ]},
                ]
            },
            {LandingAccordionItem: 'Тест чотирьох питань', 
                itemArr: [
                    {LandingMessage: 'Один з найбільш широко цитованих у світі принципів щодо ділової етики є Ротарійський «Тест Чотирьох Запитань».'},
                    {LandingMessage: 'Це набір з чотирьох простих запитань, які кожен повинен поставити собі перед тим, як почати займатися бізнесом, служінням, чи професійною діяльністю:'},
                    {LandingList: [
                        {"LandingListItem": "Чи в цьому ІСТИНА?"},
                        {"LandingListItem": "Чи СПРАВЕДЛИВО це по відношенню до всіх, кого це стосується?"},
                        {"LandingListItem": "Чи це зміцнить ДОБРУ ВОЛЮ та ДРУЖБУ?"},
                        {"LandingListItem": "Чи буде це НА БЛАГО всім зацікавленим?"},
                    ]},
                    {LandingMessage: 'ОРИГІНАЛ'},
                    {LandingMessage: 'THE 4-WAY TEST'},
                    {LandingMessage: 'Of the things we think, say or do:'},
                    {LandingList: [
                        {"LandingListItem": "Is it the TRUTH?"},
                        {"LandingListItem": "Is it FAIR to all concerned?"},
                        {"LandingListItem": "Will it build GOODWILL and BETTER FRIENDSHIPS?"},
                        {"LandingListItem": "Will it be BENEFICIAL to all concerned?"},
                    ]},
                ]
            },
        ],
    },
    {
        id: 10,
        header: 'home_h6',//"Інформація про клуби"
        img: HOME2_maps,
        content: [
            {LandingMessage: 'home_c6'},
            {BR: "true"},
            {LandingMessage: "Приєднуйтесь до 1,4 мільйонів сусідів, друзів, лідерів та розв'язувачів проблем у Ротарі, які бачать світ, де люди об'єднуються і діють, щоб створити тривалі зміни - на всій планеті, у наших спільнотах і у нас самих."},

            {LandingAccordionItem: 'Ротарі Клуби', 
                itemArr: [
                    {LandingMessage: "Клуби Ротарі об'єднують людей різних поколінь, які діляться ідеалами служіння, лідерства, різноманітності, дружби і чесності, і бажають створити тривалі зміни у світі, у своїх спільнотах і у самих себе."}, 
                    {LandingLink: "Перелік Ротарі Клубів нашого Дистрикту", link: "/rotaryclubs"},
                    {BR: "true"},
                    {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/get-involved/rotary-clubs'},
                ]
            },
            {LandingAccordionItem: 'Ротаракт Клуби', 
                itemArr: [
                    {LandingMessage: "Клуби Ротаракт об'єднують молодь віком від 18 років і старше у спільнотах по всьому світу, щоб організовувати служіння, розвивати навички лідерства і спілкуватися."},
                    {LandingLink: "Перелік Ротаракт Клубів нашого Дистрикту", link: "/rotaractclubs"},
                    {BR: "true"},
                    {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/get-involved/rotaract-clubs'},
                ]
            },
            {LandingAccordionItem: "Інтеракт Клуби", 
                itemArr: [
                    {LandingMessage: "Через Інтеракт клуби особи віком від 12 до 18 років знаходять зв'язок з іншими людьми у своїй спільноті або школі та вчаться про світ завдяки служінню та активностям проектів."},
                    {LandingLink: "Перелік Інтеракт Клубів нашого Дистрикту", link: "/interactclubs"},
                    {BR: "true"},
                    {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/get-involved/interact-clubs'},
                ]
            },
            {LandingAccordionItem: 'Ротарійські Групи Дії', 
                itemArr: [
                    {LandingMessage: "Приєднуйтесь до Ротарійських Активних Груп, щоб спілкуватися з членами Ротарі та друзями Ротарі, які є експертами в певній галузі. Учасники групи діляться своєю експертизою, співпрацюючи з клубами та округами у проведенні службових проектів."},
                    {LandingButtonDetailsRI: 'true', link: 'https://my.rotary.org/en/participate/programs/join-a-rotary-action-group'},
                ]
            },
            {LandingAccordionItem: 'Ротарійські Товариства', 
                itemArr: [
                    {LandingMessage: 'Зацікавлені у підводному плаванні або марафонському бігу? Бажаєте використовувати свої навички лікаря або еколога, щоб змінити світ? Поділіться своїм хобі або професією з іншими членами клубу, їхніми партнерами та друзями Ротарі.'},
                    {LandingLink: "Сторінка Ротарійських Товариств", link: "/fellowships"},
                    {BR: "true"},
                    {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/our-programs/new-generations-service-exchange'},
                ]
            },
            {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/our-programs/more-fellowships'},
        ],
    },
    {
        id: 11,
        header: 'Боротьба з поліомієлітом',
        img: 'https://www.rotary.org/sites/default/files/styles/w_600/public/endpolionow-callout.png?itok=FtWef8v4',
        content: [
            {LandingMessage: "Що таке поліомієліт?"},
            {LandingMessage: "Поліомієліт — це паралізуюче та потенційно смертельне інфекційне захворювання, яке найчастіше вражає дітей віком до 5 років. Вірус передається від людини до людини, як правило, через забруднену воду. Потім він може атакувати нервову систему."},
            {LandingMessage: "Наша мета"},
            {LandingMessage: "Ротарі працює над викоріненням поліомієліту більше 35 років. Наша мета позбавити світ від цієї хвороби ближча, ніж будь-коли. Як партнер-засновник Глобальної ініціативи з ліквідації поліомієліту, ми зменшили випадки поліомієліту на 99,9 відсотка з моменту нашого першого проекту з вакцинації дітей на Філіппінах у 1979 році."},
            {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/our-programs/more-fellowships'},
        ],
    },
    {
        id: 12,
        header: 'home_h9',
        img: HOME2_ukr2,
        content: [
            {LandingMessage: 'Можна сплатити прямо зараз'},
            {LandingButtonLink: 'Перейти до сплати', link: '/donate'},
        ],
    },
    {
        id: 13,
        header: 'home_h10',
        img: D2232LOGO,
        content: [
            {LandingMessage: 'Можна доєднатися до ротарійської родини прямо зараз.'},
            {LandingMessage: 'В залежності від віку можна стати членом: Ротарі клубу, Ротаракт клубу, Інтеракт клубу.'},
            {LandingButtonLink: 'Приєднатися', link: 'https://docs.google.com/forms/d/e/1FAIpQLSeBet7rjnI1RhxJYyRv5mTUu22Yd1aHic2Weh-C6eC4Ho6wEQ/viewform?embedded=true'},
        ],
    },
    {
        id: 13,
        header: 'home_h11',
        img: HOME2_icc,
        content: [
            {LandingMessage: `В структурі Ротарі Дистрикти об'єднуються в Зони. Дистрикт 2232 входить до Зони 21А. `},
            {LandingLink: `З переліком та складом Зон можна ознайомитись тут. `, link: '/aboutrizones'},
            {LandingMessage: `
            Ми маємо тісні відносини з багатьма країнами завдяки роботі Міждержавних Комітетів (Inter Country Committee)`},
            {LandingLink: 'Перейти до сторінки Міждержавних Комітетів', link: '/icc'},
        ],
    },
]
