import React, { useState, useEffect } from 'react';

import { getPresentations, getPresentationById, createPresentation, updatePresentation, deletePresentation } from '../../api/ts/materials_seminars_presentations';
import { Container, Button, TextField, } from '@mui/material';
import {  Paper} from '@mui/material';

import AdminTableComponent from './AdminTableComponent';

export const tableTitles = {
    id: "id",
    seminar_id: "seminar_id",
    order: "order",
    timestamp: "timestamp",
    adv1: "adv1",
    speaker: "speaker",
    name: "name",
    url: "url",
};
export const emptyData = {
    id: '',
    timestamp: '',
    order: '',
    seminar_id: '',
    speaker: '',
    name: '',
    url: '',
    adv1: '',
};

const AdminPresentations = () => {
    const [presentations, setPresentations] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const fetchedPresentations = await getPresentations();
                setPresentations(fetchedPresentations);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    const refreshData = async () => {
        const data = await getPresentations();
        setPresentations(data);
    };

    return (
        <Container>
        <Paper>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {loading && <p>Завантаження...</p>}
            <AdminTableComponent 
                data={presentations}
                tableTitles={tableTitles}
                emptyData={emptyData}
                createItem={createPresentation}
                updateItem={updatePresentation}
                deleteItem={deletePresentation}
                refreshData={refreshData}
            />
        </Paper>
        </Container>
    );
};

export default AdminPresentations;
