import axios from 'axios';

//const BASE_URL = 'http://localhost:3001/apibd';
const BASE_URL = 'https://api.rotary.org.ua/apibd';

export const fetchUserSkils = async (userId) => {
    try {
        const response = await axios.get(`${BASE_URL}/userResumes/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchUserSkilsId = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/userResumes/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createUserSkils = async (userData) => {
    console.log('=== createUser...');
    try {
        const response = await axios.post(`${BASE_URL}/userResumes`, userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateUserSkils = async (skilId, userData) => {
    console.log('=== updateUser...');
    try {
        console.log('=== updateUser url'+`${BASE_URL}/userResumes/${skilId}`);
        const response = await axios.put(`${BASE_URL}/userResumes/${skilId}`, userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteUserSkils = async (userId) => {
    try {
        await axios.delete(`${BASE_URL}/userResumes/${userId}`);
    } catch (error) {
        throw error;
    }
};
