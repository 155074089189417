import React, { useEffect, useState, useContext } from "react";
import { Button, Box, Container, Grid, Typography } from '@mui/material'

import MainLanding from './MainLanding';
import {getBusinessLanding2, getPhotoForLanding} from '../../api/getBusinessLanding2'

import { FormattedMessage } from 'react-intl';
import {tr} from '../../i18n/tr';
import LandingContentBlock from '../../components/LandingContentBlock';
import { LOCALES } from '../../i18n/locales';
import {LocalContext} from '../../tools/LanguageContextProvider';

import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import LandingArrContentBlock from '../../components/LandingArrContentBlock';

import LandingHeader from '../../components/LandingHeader';
import LandingBlock from '../../components/LandingBlock';
import Header from "../../modules/Header";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import PhotoCarusel from '../../modules/PhotoCarusel';

import { getPresentations, getPresentationsBySemId } from '../../api/ts/materials_seminars_presentations';
import { Card, CardContent, Link, CardMedia } from '@mui/material';

import { styled } from '@mui/system';

const NoWrapTypography = styled(Typography)(({ theme }) => ({
    '&.MuiTypography-root': {
      [theme.breakpoints.up('sm')]: {
        whiteSpace: 'nowrap',
      },
    },
}));
  
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    // Застосовуємо стилі тільки для sm екранів
    [theme.breakpoints.down('sm')]: {
        paddingLeft: '2px',
        paddingRight: '0px',
        _border: '1px solid red',
        _color: 'red',
    },
}));

const BusinessCouncilLanding = () => {
    const { currentLocale, setCurrentLocale } = useContext(LocalContext);
    const [data, setData] = useState([]);
    const [dataEng, setDataEng] = useState([]);
    const [dataUkr, setDataUkr] = useState([]);
    const [presentations, setPresentations] = useState(null);
    const [photos, setPhotos] = useState([]);
    const title_img = data && data[0] && data[0].img;
    const title_text = data && data[0] && data[0].title;
    const title_date = data && data[0] && data[0].date;

    useEffect(() => {
      const fetchData = async () => {
        try {
            const d_eng = await getBusinessLanding2(false);
            setDataEng(d_eng);
            const d_ukr = await getBusinessLanding2(true);
            setDataUkr(d_ukr);
            setData( currentLocale===LOCALES.UKRAINIAN ? d_ukr : d_eng);
            // const f = await getPhotoForLanding();
            // setPhotos(f);
            // console.log("Event1Page get data list success. const="+d_ukr.length);

            // const dt2 = await getPresentationsBySemId(16);
            // setPresentations(dt2);


        } catch (error) {
          console.log("Event1Page get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        setData( currentLocale===LOCALES.UKRAINIAN ? dataUkr : dataEng);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale])
    
    
    return (

        <MainLanding noWarningMode="hide" >
            <div style={{ position: 'relative' }}>
                <Header images={[title_img,
                ]} /> 
                
                {title_text &&
                <Typography 
                variant="h3" 
                style={{
                    zIndex: 1000,
                    position: 'absolute',
                    width: '100%',
                    top: '15%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    padding: 2,
                    paddingTop: '50px',
                    color: 'white', // Задає колір тексту, якщо потрібно
                    backgroundColor: 'rgba(0, 0, 0, 0.2)', // Задає фон, якщо потрібно
                    fontSize: '4vw',
                    fontWeight: 'bold',
                }}
                >
                    <br/><br/> 
                    {title_text}
                </Typography>
                }
                {title_date &&
                <Typography 
                variant="h3" 
                style={{
                    zIndex: 1000,
                    position: 'absolute',
                    width: '100%',
                    top: '1%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    padding: 2,
                    paddingTop: '100px',
                    color: 'white', // Задає колір тексту, якщо потрібно
                    backgroundColor: 'rgba(0, 0, 0, 0.0)', // Задає фон, якщо потрібно
                    fontSize: 'max(2vw, 14pt)',
                }}
                >
                {title_date}
                </Typography>
                }

                <Button 
                    variant='contained'
                    size='large'
                    sx={{ textTransform: 'none' }} 
                    onClick={() => {
                        
                            window.open(
            'https://docs.google.com/forms/d/e/1FAIpQLSdcp4qEXppD6YfgAkQZXr1gP8D3rnkpBzEBn-ehYnW19_x3zg/viewform', '_blank');
                            //window.scrollTo(0, 0);
                            console.log("LandingButtonLink click")
                        
                    }}
                    style={{
                        zIndex: 1000,
                        position: 'absolute',
                        _width: '30%',
                        bottom: '30px',
                        right: '50px',
                        _transform: 'translate(-50%, -50%)',
                        background: 'rgba(255, 215, 0, 0.8)',
                        fontSize: 'max(2vw, 14pt)',
                    }}
                >
                    {tr("Зареєструватися")}
                </Button>


            </div>

            {data && data.map((item,index) => (
                <>
                    {(item.content || item.img || item.cards) && <LandingContentBlock id={index} landItem={item} designType='busines' />}
                    {item.arrcontent && <LandingArrContentBlock id={index} arrItem={item} designType='busines' />}
                </>
            ))}
            
            {/* <LandingBlock mode={'light2'}>
            <Box className="section text-left">
                <Container>
                    <LandingHeader>
                        <Typography variant="h4" className="page-header">
                            <FormattedMessage id='Презентації' />
                        </Typography>
                    </LandingHeader>
                    
                    <Table>
                    <TableBody>
                    {presentations && presentations.map((item) => (
                        <TableRow key={item.id}>
                            <StyledTableCell>
                                {item.url 
                                    ? <Link href={item.url} target="_blank" rel="noopener noreferrer">
                                        <InsertDriveFileIcon color="secondary" />
                                    </Link>
                                    : <Typography color="textSecondary">Файл відсутній</Typography>
                                }
                            </StyledTableCell>
                            <StyledTableCell>
                                <NoWrapTypography variant="subtitle2">{item.adv1}</NoWrapTypography>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Typography variant="h7" color="darkblue">{item.name}</Typography>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Typography variant="subtitle1">{item.speaker}</Typography>
                            </StyledTableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                    </Table>

                </Container>
            </Box>
            </LandingBlock>

            <LandingBlock mode={'dark2'}>
            <Box className="section text-left">
                <Container>
                    <LandingHeader>
                        <Typography variant="h4" className="page-header">
                            <FormattedMessage id='Фотогалерея' />
                        </Typography>
                    </LandingHeader>
            
                    <PhotoCarusel images={photos} />
                </Container>
            </Box>
            </LandingBlock> */}

        </MainLanding>
  );
}

export default BusinessCouncilLanding;