
import React, { useEffect, useState, useContext, useRef  } from "react";
import { useParams } from 'react-router-dom';

import MainPage from "../components/Main";
import {getD2232ProjectsPage} from './d2232GetFunctions/getD2232ProjectsPage';
import {LocalContext} from '../tools/LanguageContextProvider';
import LandingContent from '../pages/RC_Cherkasy/components/LandingContent';

const D2232ProjectsPage = () => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [data, setData] = useState([]);
    const { id } = useParams();

    const  designType='chicago';

    useEffect(() => {
      const fetchData = async () => {
        try {
            await loadData(currentLocale);
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale, id])

    const loadData = async(locale) => {
        const d = await getD2232ProjectsPage(locale);
        setData( d );
        console.log("DanatePage get data list success. const="+d.length);
    }

    return (
        <MainPage>
        
        <LandingContent data={data} currentLocale={currentLocale} designType={designType} />

        </MainPage>
  );
}

export default D2232ProjectsPage;