import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams } from 'react-router-dom';

import {  useMediaQuery, useTheme } from '@mui/material';
import { Container, Typography, Paper } from '@mui/material'
import { LOCALES } from '../../../i18n/locales';

import CRUD_ClubUser from '../rotariansBD/adminsPages/CRUD_ClubUser';
import CRUD_ClubProjects from '..//rotariansBD/adminsPages/CRUD_ClubProjects';

import {getRCLanding} from './getRCLanding';
import {getRCLanding2} from './getRCLanding2';
import {getRCLanding3} from './getRCLanding3';
import {getRCLanding4} from './getRCLanding4';
import {getCherkasyProjects2} from './getCherkasyProjects2';
import {getRCCherkasyRotaract} from './getRCCherkasyRotaract';
import {getRCCherkasyPartners} from './getRCCherkasyPartners';
import {getRCCherkasyLeadership} from './getRCCherkasyLeadership';
import {getRCCherkasyBoard} from './getRCCherkasyBoard';
import {getMyCherkasyLanding} from './getMyCherkasyLanding';
import {getMyCherkasyBdRotarians} from './getMyCherkasyBdRotarians';
import {LocalContext} from '../../../tools/LanguageContextProvider';
import LandingContent from '../components/LandingContent';
import RcCherkasyMain from './RcCherkasyMain';

import DocumentsFrame from '../../../pages/myrotary/DocumentsFrame';
import EventsFrame from '../../EventsFrame';
import UserCRUD from '../rotariansBD/adminsPages/UserCRUD';
import MyAutorizeFrame from './MyAutorizeFrame';
import ServerClubFolders from '../../ToolsPages/ServerClubFolders';

const adminUserDefaultFields = [
    { field: 'tags', defValue: 'rc_cherkasy' },
    { field: 'club_type', defValue: 'Rotary' },
    { field: 'club_name', defValue: 'Ukraine' },
    { field: 'club_name_ukr', defValue: 'Черкаси' },
]
const adminProjectDefaultFields = [
    { field: 'tags', defValue: 'rc_cherkasy' },
    { field: 'rtype', defValue: 'Rotary' },
    { field: 'rclub', defValue: 'Черкаси' },
]
const projectColumns = [
    { field: 'project_id', headerName: 'id', type: 'id', default: '' },
    { field: 'category', headerName: 'Rotary Area of Focus:', type: 'combo', default: '' },
    { field: 'category2', headerName: 'Project category:', type: 'combo', default: '' },
    { field: 'status', headerName: 'Realization Stage:', type: 'combo', default: '' },
    { field: 'budget', headerName: 'Budget:', type: 'combo', default: '' },
    
    //{ field: 'avatarimg', headerName: 'Primary photo', type: 'lineedit', default: '', tablehided: 'true' },
    //{ field: 'imgs', headerName: 'Additional photo list:', type: 'multilineedit', default: '', tablehided: 'true' },
    { field: 'title', headerName: 'Title:', type: 'lineedit', default: '' },
    { field: 'youtube:fdesc', headerName: 'Short Description:', type: 'advmultilineedit', default: '', tablehided: 'true' },
    { field: 'description', headerName: 'Full Description:', type: 'multilineedit', default: '', tablehided: 'true' },
    
    { field: 'genlink', headerName: 'Links of project: website, fb, other (by coma)', type: 'multilineedit', default: '' },
    { field: 'docs', headerName: 'Links of project documents, presentations, other (by coma)', type: 'multilineedit', default: '' },
    
    { field: 'primarycontact', headerName: 'Shared primarycontact: First name and Sirname', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'email', headerName: 'Shared contact: Email', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'phone', headerName: 'Shared contact: Phone', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'fb', headerName: 'Shared contact: Other social platforms: youtube, fb, insta (by coma)', type: 'lineedit', default: '', tablehided: 'true' },

    { field: 'youtube:thanks', headerName: 'THANKS TO OVERSEAS ROTARY MEMBER FOR PARTICIPATION IN PAST PROJECTS', type: 'advmultilineedit', default: '', tablehided: 'true' },
    { field: 'youtube:invitation', headerName: 'Invitation to the interaction of Rotarians in the project', type: 'advmultilineedit', default: '', tablehided: 'true' },
];
const tags = 'rc_cherkasy';

const pageJson = {
    undefined: getRCLanding,
    'club': getRCLanding2,
    "projects": getCherkasyProjects2,
    'donate': getRCLanding3,
    "rotaract": getRCCherkasyRotaract,
    "partners": getRCCherkasyPartners,
    "leadership": getRCCherkasyLeadership,
    "board": getRCCherkasyBoard,
    'news': getRCLanding4,
    //my
    'myhome': getMyCherkasyLanding,
    "bdrotarians": getMyCherkasyBdRotarians,
};

const RcCherkasyPage = () => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [data, setData] = useState([]);
    const [userProfile, setUserProfile] = useState();
    
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { id } = useParams();

    const  designType='chicago';
    
    const isClubMember = () => {
        return userProfile && ( userProfile.club==='RC Cherckasy' || userProfile.email==='tykogm2@gmail.com');
    } 

    useEffect(() => {
        setCurrentLocale(LOCALES.UKRAINIAN);

      const fetchData = async () => {
        try {
            await loadData(currentLocale, id);
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale, id);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale, id])

    const loadData = async(locale, page) => {
        const pageFunc = pageJson[page];
        if (pageFunc) {
            const d = await pageFunc(locale);
            setData( d );
            console.log("DanatePage get data list success. const="+d.length);
        }
    }

    const onLogined = (userProfile) => {
        setUserProfile(userProfile);
        console.log("============= onLogined="+(userProfile && userProfile.club));
    }

    return (
        <RcCherkasyMain userProfile={userProfile} onLogined={onLogined} isClubMember={isClubMember()} >
        {(() => {
                switch (id) {
                    case 'events':
                        return (
                            <MyAutorizeFrame userProfile={userProfile} onLogined={onLogined} isClubMember={isClubMember()} >
                            <Container style={{ padding: '8px' }}>
                                <Paper style={{ padding: '12px', paddingLeft: isSmallScreen ? '12px' : '96px', paddingRight: isSmallScreen ? '8px' : '24px' }}>
                                    <EventsFrame />
                                </Paper>
                            </Container>
                            </MyAutorizeFrame>
                        );
                    case 'communique':
                        return (
                            <MyAutorizeFrame userProfile={userProfile} onLogined={onLogined} isClubMember={isClubMember()} >
                            <Container style={{ padding: '8px' }}>
                                <Paper style={{ padding: '12px', paddingLeft: isSmallScreen ? '12px' : '96px', paddingRight: isSmallScreen ? '8px' : '24px' }}>
                                    <DocumentsFrame />
                                </Paper>
                            </Container>
                            </MyAutorizeFrame>
                        );
                    case 'administaration':
                        return (
                            <MyAutorizeFrame userProfile={userProfile} onLogined={onLogined} isClubMember={isClubMember()} >
                            <div style={{padding: '24px'}}>
                                    <UserCRUD tags='РКЧеркаси' />
                            </div>
                            </MyAutorizeFrame>
                        );
                    case 'myhome':
                    case 'bdrotarians':
                        return (
                            <MyAutorizeFrame userProfile={userProfile} onLogined={onLogined} isClubMember={isClubMember()} >
                            {
                                data ? (
                                    <LandingContent data={data} currentLocale={currentLocale} designType={designType} />
                                ) : (
                                    "Loading..."
                                )
                            }
                            </MyAutorizeFrame>
                        );

                    case 'adminmember':
                        return (
                            <CRUD_ClubUser tags={tags} adminUserDefaultFields={adminUserDefaultFields} />
                        );
                    case 'adminprojects':
                        return (
                            <CRUD_ClubProjects 
                                title={'Administration for Club Projects'}
                                tags={tags} 
                                adminProjectDefaultFields={adminProjectDefaultFields} 
                                projectColumns={projectColumns}
                            />
                        );
                    case 'adminphotos':
                        return (
                            <div style={{padding: '24px'}}>
                                <ServerClubFolders cernelClubFolder='clubsitecreator/rc_cherkasy/photo' />
                            </div>
                        );

                    default:
                        return data ? (
                            <LandingContent data={data} currentLocale={currentLocale} designType={designType} />
                        ) : (
                            "Loading..."
                        );
                }
            })()}
        </RcCherkasyMain>
    );
}

export default RcCherkasyPage;