import React, { useState } from 'react';
import axios from 'axios';

const SERVERURL = 'https://apifs.rotaryd2232ukraine.org/storage';

const Upload = () => {
    const [file, setFile] = useState(null);
    const [path, setPath] = useState('');
    const [token, setToken] = useState(localStorage.getItem('tokenFS'));
  
    const handleFileChange = (e) => {
      setFile(e.target.files[0]);
    };
  
    const handlePathChange = (e) => {
      setPath(e.target.value);
    };
  
    const handleUpload = async () => {
      const token = localStorage.getItem('tokenFS');
      setToken(token);
      console.log(`handleUpload token=${token}`);
  
      const formData = new FormData();
      formData.append('file', file);
      formData.append('path', path);
  
      console.log(`handleUpload filename=${file.name}`);
      console.log(`handleUpload path=${path}`);
  
      try {
        const response = await axios.post(`${SERVERURL}/upload`, formData, {
          headers: {
            'Storage-Token': token,
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('File uploaded successfully!');
      } catch (error) {
        console.error('File upload failed!', error);
      }
    };
  
    return (
      <div>token={token}<br/>
        <input type="file" onChange={handleFileChange} />
        <input type="text" placeholder="Path" value={path} onChange={handlePathChange} />
        <button onClick={handleUpload}>Upload</button>
      </div>
    );
  };

export default Upload;
