import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';

const LandingButtonDetailsRI = ({link}) => {
    const navigate = useNavigate();
    return (
        <Button 
            sx={{ textTransform: 'none' }} 
            onClick={() => {
                if (link) {
                    window.open(link, '_blank');
                }
            }}
        >
            <FormattedMessage id='btn_details_ri' />
        </Button>
    );
};

export default LandingButtonDetailsRI;
