import { LOCALES } from '../../../i18n/locales';

export const getMyClubPageStructure = (local) => {

    const translations_ukr = {
        "Клубна сторінка": "Клубна сторінка",
        "Про Клуб": "Про Клуб",
        "Події": "Події",
        "Календар": "Календар",
        "Фотогалерея": "Фотогалерея",
        "Контактна особа:": "Контактна особа:",
        "Проєкти": "Проєкти",
        "Ротаракт": "Ротаракт",
        "Співпрацюючі організації": "Співпрацюючі організації",

        "Клуб": "Клуб",
        "Дистрикт": "Дистрикт",
        "Правління та посадові особи": "Правління та посадові особи",
        "Дистрикт 2232": "Дистрикт 2232",
    };

    const translations_eng = {
        "Клубна сторінка": "Club Page",
        "Про Клуб": "About the Club",
        "Події": "Events",
        "Календар": "Calendar",
        "Фотогалерея": "Photo Gallery",
        "Контактна особа:": "Primary contact:",
        "Проєкти": "Projects",
        "Ротаракт": "Rotaract",
        "Співпрацюючі організації": "Cooperating organizations",

        "Клуб": "Клуб",
        "Дистрикт": "Дистрикт",
        "Правління та посадові особи": "Board & Officers",
        "Дистрикт 2232": "District 2232",
    };

    const translations = local === LOCALES.UKRAINIAN ? translations_ukr : translations_eng;

    return {
        navbar: {
            title: translations["Клубна сторінка"],
            logoUrl: "https://res.cloudinary.com/tyko/image/upload/v1704542422/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/share/RC_Cherkasy_trimed_q9pd9g.png",
            languages: [
                "ua-UA",
                "en-US",
            ],
            socialsocialNetworks: {
                fb: 'https://www.facebook.com/RotaryClubCherkasy/',
                insta: 'https://www.instagram.com/rotary_club_cherkasy?igsh=MWlhNzRjeW0wa2RuMg%3D%3D&utm_source=qr',
                youtube: 'https://youtube.com/@RotaryclubCherkasy?si=PJCBZbOuDVqDxzpU',
            },
            menu: [
                {
                    title: translations["Клуб"],
                    lref: '/rc_cherkasy/myhome',
                },
                {
                    title: "Про Дистрикт",
                    arr: [
                        { 
                            title: "Події",
                            lref: '/rc_cherkasy/events',
                        },
                        { 
                            title: "Комюніке",
                            lref: '/rc_cherkasy/communique',
                        },
                        { 
                            title: "БД Ротарійців",
                            lref: '/rc_cherkasy/bdrotarians',
                        },
                    ]
                },
                {
                    title: "Адміністрування",
                    arr: [
                        { 
                            title: "Члени Клубу",
                            lref: '/rc_cherkasy/administaration',
                        },
                        { 
                            title: "Проекти Клубу",
                            lref: '/rc_cherkasy/administaration2',
                        },
                    ]
                },
            ],
        },
        footer: {
            contactPerson_: {
                name: translations["Контактна особа:"],
                email: "oncology@2upost.com",
                email2: "paliychuk.oncology@gmail.com",
            },
            social: {
                // fb: "fb1",
                // insta: "insta1",
                // linkedIn: "linkedIn1",
            }
        }
    }
}
