export const getDanateLanding = () => {
    return defaultData;
};

const defaultData = [
    {
        id: 1,
        header: 'Сторінка Донатів для українського Дистрикту 2232',
        img: 'https://dyvensvit.org/wp-content/uploads/2019/08/Money-growth.jpg',
        content: [
            {LandingMessage: `РОТАРІЙЦІ всього світу!
            УКРАЇНА потребує вашої допомоги!`},
        ],
    },
    // {
    //     id: 2,
    //     header: 'Зробити внесок в платіжній системі',
    //     content: [
    //         {LandingButtonLink: 'Сплатити з LiqPay'},
    //     ],
    // },
    {
        id: 3,
        header: 'Зробити внесок за реквізитами',
        content: [
            {LandingAccordionItem: 'UAH', 
                itemArr: [
                    {LandingMatrixPrinterPaper: '.',
                        itemArr: 
`Beneficiary Bank: АТ "Райффайзен Банк" 
Beneficiary: PA RI DISTRICT 2232 
EDRPOU code: 38871769 
Account number/IBAN: 

UA36 3808 0500 0000 0026 0018 4384 4 
Account currency: UAH `
                    }, 
                ]
            },
            {LandingAccordionItem: 'USD', 
                itemArr: [
                    {LandingMatrixPrinterPaper: '.',
                        itemArr:  
`USD ACCOUNT: 
IBAN: UA633808050000000026009627608

SWIFT code: AVALUAUKXXX
Correspondent Account: 890-0260-688
CORRESPONDENT BANK:
The Bank of New York Mellon, New York, USA
SWIFT code: IRVTUS3N`,
                        
                    }                    
                ]
            },
            {LandingAccordionItem: 'EURO', 
                itemArr: [
                    {LandingMatrixPrinterPaper: '.',
                        itemArr: 
`EURO ACCOUNT:
IBAN: UA173808050000000026002771409

SWIFT code: AVALUAUKXXX
Correspondent Account: 55.022.305
CORRESPONDENT BANK:
Raiffeisen Bank International AG
SWIFT code: RZBAAT WW`
                        
                    }
                ]
            },
            {LandingAccordionItem: 'GBP', 
                itemArr: [
                    {LandingMatrixPrinterPaper: '.',
                        itemArr: 
`ACCOUNT STERLING (GBP) 
Account Number IBAN: UA05 3808 0500 0000 0026 0057 7542 4 
NAME OF ORGANIZATION: PA RI DISTRICT 2232
LEGAL ADDRESS: 04050, UKRAINE, Kyiv city, Gercena str, 17-25, 1
BANK OF BENEFICIARY: 
Raiffeisen Bank Joint Stock Company, Leskova street.9, KYIV, 01011, Ukraine 
SWIFT code: AVALUAUKXXX Correspondent Account: 83301931 CORRESPONDENT BANK
Barclays Bank PLC 
SWIFT code: BARCGB22`
                        
                    }
                ]
            },
            {LandingAccordionItem: 'CHF', 
                itemArr: [
                    {LandingMatrixPrinterPaper: '.',
                        itemArr: 
`ACCOUNT SWISS FRANCS (CHF) 
Account Number IBAN: UA79 3808 0500 0000 0026 0067 7542 3 
NAME OF ORGANIZATION: PA RI DISTRICT 2232 
LEGAL ADDRESS: 04050, UKRAINE, Kyiv city, Gercena str, 17-25, 1 
BANK OF BENEFICIARY: 
Raiffeisen Bank Joint Stock Company, Leskova street.9, KYIV, 01011, Ukraine 
SWIFT code: AVALUAUKXXX 
Correspondent Account: 0230-69171.05M 
CORRESPONDENT BANK 
UBS Switzerland AG, Zurich 
SWIFT code: UBSWCHZH80A`
                        
                    }
                ]
            },
            {LandingAccordionItem: 'SEK', 
                itemArr: [
                    {LandingMatrixPrinterPaper: '.',
                        itemArr: 
`BENEFICIARY
Account number/IBAN UA26 3808 0500 0000 0026 0018 3451 8 
Account currency SEK 
Beneficiary PA RI DISTRICT 2232
Legal address of the organization 04050, UKRAINE, Kyiv city, Gercena str, 17-25,
apartment/office 1

BENEFICIARY BANK
RAIFFEISEN BANK JOINT STOCK COMPANY,
KIEV, UKRAINE 
SWIFTcode:
AVALUAUKXXX 
Correspondent Account:
81-55.022.305  

CORRESPONDENT BANK 
Use the settlement instructions below:
RAIFFEISEN BANK INTERNATIONAL AG,
VIENNA, AUSTRIA 
SWIFTcode: RZBAATWWXXX`  
                        
                    }
                ]
            },
            {LandingAccordionItem: 'CAD', 
                itemArr: [
                    {LandingMatrixPrinterPaper: '.',
                        itemArr: 
`BENEFICIARY 
Account number/IBAN UA78 3808 0500 0000 0026 0057 8526 5 
Account currency CAD 
Beneficiary PA RI DISTRICT 2232 
Legal address of the organization 04050, UKRAINE, Kyiv city, Gercena str, 17-25,
apartment/office 1  

BENEFICIARY BANK
RAIFFEISEN BANK JOINT STOCK COMPANY,
KIEV, UKRAINE 
SWIFTcode:
AVALUAUKXXX 
Correspondent Account:
71-55.022.305   

CORRESPONDENT BANK 
Use the settlement instructions below:
RAIFFEISEN BANK INTERNATIONAL AG,
VIENNA, AUSTRIA 
SWIFTcode: RZBAATWWXXX `
                        
                    }
                ]
            },
        ]
    },
    // {
    //     id: 4,
    //     header: 'Якщо в вас є питання, можете задати його керівництву Дистрикта',
    //     content: [
    //         {LandingButtonLink: 'Задати питання'},
    //     ],
    // },
]