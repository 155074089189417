import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Avatar from '@mui/material/Avatar';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

import LandingContentItems from './LandingContentItems';
import { fetchUser } from '../pages/RC_Cherkasy/rotariansBD/api/api_users';

import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button } from '@mui/material';
import { MdEmail } from 'react-icons/md';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';


function ListItemComponent({ card, index }) {
    const [user, setUser] = useState(card);
    const [user2, setUser2] = useState(card);
    const navigate = useNavigate();

    useEffect(() => {
        console.log('=== useEffect2 fetchUser id='+card.rotarianId);

        const fetchData = async (id) => {
            try {
                const fetchedUser = await fetchUser(id);
                const fetchedUser2 = fetchedUser[0];
                console.log('=== fetchedUser id='+id+' / email='+fetchedUser2.email);
                setUser(toCard2(fetchedUser2));
            } catch (error) {
                console.error(error);
            }
        };

        if (card && card.rotarianId) {
            fetchData(card.rotarianId);
        }
    }, []);

    const toCard2 = (rcard) => {
        
        console.log('=== fetchedUser3 json='+JSON.stringify(rcard[0]));

        return {
            img: rcard.avatar_url,
            name: rcard.first_name+' '+rcard.last_name,
            content2: rcard.club_name + '\n\n' + rcard.other_positions,
            email: rcard.email,
            phone: rcard.phone,
        }
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            alert('Зміст скопійовано в буфер обміну');
          })
          .catch((error) => {
            console.error('Помилка при копіюванні в буфер обміну:', error);
          });
    };

    const handleCardClick = (url) => {
        if (url.startsWith('http')) {
            window.open(url, '_blank');
        } else {
            navigate(url);
        }
      };

return (
    <>
    <Card sx={{ maxWidth: 360, minWidth: 200 }} key={index} >
                <CardActionArea onClick={() => handleCardClick(user.buttonUrl) }>
                  <CardMedia
                    component="img"
                    sx={{ maxWidth: 400 }}
                    image={user.img}
                    alt="Без фото"
                  />
                  {(user.name || user.content || user.content2) &&
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                    {user.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {user.content && 
                            <lu>
                            <LandingContentItems content={user.content} />
                            </lu>
                        }
                        {user.content2 && 
                            <Typography style={{ whiteSpace: 'pre-line' }}>
                            {user.content2}
                            </Typography>
                        }
                    </Typography>
                    
                  </CardContent>
                    }
                </CardActionArea>
                {/*<CardActions>
                
                    {user.email && <Tooltip title={'Пошта'}>
                    <IconButton onClick={() => copyToClipboard(user.email)} aria-label="Facebook">
                        <MdEmail color="blue" size='1.5em' />
                    </IconButton>
                    </Tooltip>}

                    {user.phone && <Tooltip title={'Телефон'}>
                    <IconButton onClick={() => copyToClipboard(user.phone)} aria-label="Facebook">
                        <PhoneIcon color="primary" size='1.5em' />
                    </IconButton>
                    </Tooltip>
                    }
                    
                    {user.fb && <IconButton aria-label="facebook" onClick={() => window.open(user.fb, '_blank')}>
                    <FaFacebookF />
                </IconButton>
                    
                </CardActions>*/}
                </Card>
            </>
    );
}

function LandingCardsList({ cards }) {

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            alert('Зміст скопійовано в буфер обміну');
          })
          .catch((error) => {
            console.error('Помилка при копіюванні в буфер обміну:', error);
          });
    };

    return (
        <div style={{_border: '1px solid red'}}>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}  gap={2} alignItems="top" justifyContent='center' paddingTop='2em' flexWrap='wrap'>
            {cards && cards.map((card, index) => (
                <ListItemComponent card={card} index={index} />
            ))}
            </Box></div>
        );
}

export default LandingCardsList;