import React from 'react';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const LandingDownloadButton = ({text,link}) => {
  const handleDownload = () => {
    // URL або шлях до вашого файлу
    const fileUrl = link;
    
    // Створення посилання (тег <a>) для завантаження файлу
    const link2 = document.createElement('a');
    link2.href = fileUrl;
    
    // Встановлення атрибуту "download", який запускає скачування файлу
    link2.download = 'program.pdf'; // Ім'я файлу, яке видається користувачеві
    link2.target = '_blank'; // Відкрити у новому вікні (необов'язково)
    
    // Додавання посилання на сторінку
    document.body.appendChild(link2);
    
    // Натискання посилання
    link2.click();
    
    // Видалення посилання після завершення скачування
    document.body.removeChild(link2);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<CloudDownloadIcon />}
      onClick={handleDownload}
    >
    {text}
    </Button>
  );
};

export default LandingDownloadButton;
