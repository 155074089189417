import React, { useEffect, useState } from "react";
import './PhotoCarusel.css';

import { Button, Box, Container, Grid, Typography } from '@mui/material'

const PhotoCarusel = ({images, text}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        let interval;
        if (!isHovered) {
            interval = setInterval(() => {
                console.log("handleNext");
                    handleNext();
            }, 3000);
        }
        
        return () => {
            clearInterval(interval);
        };
    }, [isHovered, activeIndex]);

    const handleNext = () => {
            setActiveIndex((prevIndex) => images && images.length>0 ? (prevIndex + 1) % images.length : 0);
            //console.log("handleNext prevIndex="+prevIndex+", images.length="+images.length);
        
    };

    const handlePrev = () => {
        if (images && images.length > 0 ) {
            setActiveIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
        }
    };

    const handleMiniatureClick = (index) => {
        setActiveIndex(index);
    }

    return (
        <>
        {images && 
        <>
        
        <div 
            className="carusel" 
            //onMouseEnter={() => setIsHovered(true)}
            //onMouseLeave={() => setIsHovered(false)}
        >
            <button onClick={handlePrev} className="carousel-button carousel-button-left">←</button>
            
            {images.map((image, index) => (
                <img 
                    key={index}
                    src={image} 
                    className={`carusel-image ${index === activeIndex ? 'active' : ''}`} 
                    alt={`carusel Image ${index + 1}`}  // Альтернативний текст
                />
            ))}
            
            {text && <div className="carousel-text">
                {text}
            </div>}
            
            <div className="carousel-indicators">
                {images.map((_, index) => (
                    <Button className="button-miniatures" onClick={() => handleMiniatureClick(index)}>
                    <span 
                        key={index}
                        className={`indicator ${index === activeIndex ? 'active' : ''}`} 
                    />
                    </Button>
                ))}
            </div>
            
            <button onClick={handleNext} className="carousel-button carousel-button-right">→</button>
        </div>

        <Box className="carousel-miniatures">
            {images.map((image, index) => (
                <Button className="button-miniatures" onClick={() => handleMiniatureClick(index)}>
                <img 
                    key={index}
                    src={image} 
                    className={`miniature-image ${index === activeIndex ? 'active' : ''}`} 
                    alt={`carusel Image ${index + 1}`}  // Альтернативний текст
                />
                </Button>
            ))}
        </Box>

        
        </>
        }
        </>
    );
}

export default PhotoCarusel;
