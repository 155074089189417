
import {data} from '../../db/clubs/Rotary_02.2024.js'

export const rotaryClubsFromRiBd = () => {
    const d1 = csvToJson(data);
    const d2 = filterAndSplitClubs(d1);
    return d2;
}

function filterAndSplitClubs(data) {
    // Фільтруємо масив, залишаючи тільки активні клуби
    const activeClubs = data.filter(item => item["Is Active"] === "X");

    // Трансформуємо кожен елемент масиву
    const transformedClubs = activeClubs.map(item => {
        // Розділяємо поле "List of all clubs" на назву клубу та ID
        const match = item["List of all clubs"].match(/^(.*?)\s*\((\d+)\)$/);
        return {
            ...item,
            club: match ? match[1] : item["List of all clubs"], // Записуємо назву клубу
            clubid: match ? match[2] : '', // Записуємо ID клубу
        };
    });

    return transformedClubs;
}
// Функція для конвертації CSV у JSON
function csvToJson(csv) {
    const lines = csv.split('\n').filter(line => line.trim() !== '');
    const result = [];
    const headers = lines[0].split(',').map(header => header.trim());

    for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentline = lines[i].split(',');

        for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j] ? currentline[j].trim() : '';
        }

        result.push(obj);
    }

    return result;
}
/*
function csvToJson(csv) {
    const lines = csv.split('\n').filter(line => line.trim() !== ''); // Видаляємо порожні рядки
    const result = [];
    // Перетворюємо перший рядок на масив назв колонок, видаляючи зайві пробіли
    const headers = lines[0].split(',').map(header => header.trim());

    // Ітеруємо по кожному рядку, починаючи з другого
    for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentline = lines[i].split(',');

        for (let j = 0; j < headers.length; j++) {
            // Присвоюємо кожному ключу відповідне значення, видаляючи зайві пробіли
            obj[headers[j]] = currentline[j] ? currentline[j].trim() : '';
        }

        result.push(obj);
    }

    return result;
}

const csvData = `List of all clubs,Is Active,Current Member Count,Is New,Charter Date,Chartered Member Count,Is Terminated,Termination Date,Termination Reason,# of Members @ Termination,
,Alushta (31599),X,4,,14-May-1996,7,,,,
,Cherkasy (50516),X,27,,10-Jun-1997,2,,,,
...`;

export const test = () => {
    const jsonData = csvToJson(csvData);
    console.log(jsonData);
}*/
