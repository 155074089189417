import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { fetchEvents, createEvent, updateEvent, deleteEvent } from '../api/api_events';

import CrudTable from './CrudTable';
import AdminDialogCrud from './AdminDialogCrud';

const eventColumns = [
    { field: 'id', headerName: 'id', type: 'id', default: '' },
    { field: 'date', headerName: 'date', type: 'lineedit', default: '' },	
    { field: 'date2', headerName: 'date2', type: 'lineedit', default: '' },
    { field: 'event', headerName: 'event', type: 'lineedit', default: '' },
    { field: 'club', headerName: 'Club Name', type: 'lineedit', default: '' },
    { field: 'regLink', headerName: 'regLink', type: 'lineedit', default: '' },
    { field: 'siteLink', headerName: 'siteLink', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'docsLink', headerName: 'docsLink', type: 'lineedit', default: '', tablehided: 'true' },
];

const generateDefaultObject = (columns) => {
    return columns.reduce((acc, column) => {
        acc[column.field] = column.default;
        return acc;
    }, {});
};

const CRUDEvents = ({tags}) => {
    const [events, setEvents] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentEvent, setCurrentEvent] = useState(generateDefaultObject(eventColumns));
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedEvents = await fetchEvents();
                const filteredData = tags ? fetchedEvents.filter(item => item.tags === tags) : fetchedEvents;

                setEvents(filteredData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const handleEditEvent = (event = generateDefaultObject(eventColumns)) => {
        setCurrentEvent(event);
        setIsEditMode(!!event.id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        console.log('=== handleSubmit...');
        try {
            if (isEditMode) {
                await updateEvent(currentEvent.id, currentEvent);
            } else {
                await createEvent(currentEvent);
            }
            handleClose();
            const updatedEvents = await fetchEvents();
            setEvents(updatedEvents);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = async (eventId) => {
        console.log('=== handleDelete... eventId='+eventId);
        const confirmDelete = window.confirm('Чи дійсно хочете видалити цю подію?');
        if (!confirmDelete) return;
    
        try {
            await deleteEvent(eventId);
            const updatedEvents = await fetchEvents();
            setEvents(updatedEvents);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <Box display="flex" flexDirection="row" pt="8px" sx={{ position: 'sticky', top: 0, zIndex: 1000, backgroundColor: 'white' }}>
                <Button variant="contained" color="primary" onClick={() => handleEditEvent()}>
                    Add Event
                </Button>

                <Box width="100%">
                </Box>
            </Box>
            
            <CrudTable data={events} columns={eventColumns} onEdit={handleEditEvent} onDelete={handleDelete} />

            <AdminDialogCrud
                open={open}
                onClose={handleClose}
                onSubmit={handleSubmit}
                isEditMode={isEditMode}
                columns={eventColumns}
                currentItem={currentEvent}
                setCurrentItem={setCurrentEvent}
            />
        </div>
    );
}

export default CRUDEvents;
