import React, { useState, useEffect } from 'react';

import { Table, TableBody, TableCell, TableHead, TableRow, Button, Container, Typography } from '@mui/material';
import { Dialog, TextField, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import TableComponent from './TableComponent';
import FormFieldsComponent from './FormFieldsComponent';

const arr = [
    { name: "Матеріали семінарів", url: "/admin_seminars", },
    { name: "Матеріали презентацій", url: "/admin_presentations", },
    { name: "Комюніке", url: "/documents_communique", },
    { name: "Редагування подій", url: "/admin_events", },
    { name: "Журнали Ротарієць", url: "/admin_magazines", },
];

function AdminsPage() {
    
    useEffect(() => {
        async function fetchData() {
            try {
                
            } catch (error) {
            }
        }
        fetchData();
    }, []);

    return (
        <Container>
            <h2>Admins</h2>
            <Table>
                {arr.map((item) => (
                    <TableRow>
                        <TableCell>{item.name}</TableCell><TableCell><a href={item.url}>{item.url}</a></TableCell>
                    </TableRow>
                ))}
            </Table>

        </Container>
    );
}

export default AdminsPage;
