import { useParams } from 'react-router-dom';

import LandingPageV2 from '../components/LandingPageV2';
import LandingPageV3 from '../components/LandingPageV3';
import {getEven2Menu} from './getEven2Menu';
import {getEvent2Landing} from './getEvent2Landing';
import {getHeader} from './getHeader';

const EventPets24Page = () => {
    const { id } = useParams();
    const  designType='simple';

    return (
        <LandingPageV3 
            designType={designType}
            navbarFunc={getEven2Menu}
            headerFunc={getHeader}
            pageFunc={getEvent2Landing}
            urlid={id}
            pageDublicateMenu={true}
        /> 
  );
}

export default EventPets24Page;