import React, { useEffect, useState, useContext, useRef  } from "react";
import { useParams } from 'react-router-dom';

import {getRCLanding} from './getRCLanding';
import {getRCLanding2} from './getRCLanding2';
import {getClubPageStructure2} from './getClubPageStructure2';
import {getRCLandingHome} from './getRCLandingHome';
import { LOCALES } from '../../../i18n/locales';
import {LocalContext} from '../../../tools/LanguageContextProvider';

import TykoLanding from '../components/TykoLanding';
import LandingContent from '../components/LandingContent';
import TLandingFooter from '../components/TLandingFooter';

const RProjectsShowcase2 = () => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [data, setData] = useState([]);
    const [navbar, setNavbar] = useState(null);
    const [footer, setFooter] = useState(null);
    const { id } = useParams();

    const  designType='chicago';

    useEffect(() => {
      const fetchData = async () => {
        try {
            await loadData(currentLocale);
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale, id])

    const loadData = async(locale) => {
        console.log("loadData idpage="+id);
        let d = null;
        switch (id) {
            case 'browse':
                d = await getRCLanding2(locale);
                break;
    
            case 'stat':
                d = await getRCLanding(locale);
                break;
    
            default:
                d = await getRCLandingHome(locale);
                break;
        }
        setData( d );

        const page = getClubPageStructure2(locale);
        setNavbar(page.navbar);
        setFooter(page.footer);

        console.log("DanatePage get data list success. const="+d.length);
    }

    return (
        <>
        {navbar ?
        <TykoLanding navbar={navbar} noWarningMode="hide" designType={"business"} mandatoryLanguage="Eng" >
        <LandingContent data={data} currentLocale={currentLocale} designType={designType} footer={footer}/>
        </TykoLanding> : "Loading..."}
        </>
  );
}

export default RProjectsShowcase2;