import React, { useState, useEffect } from 'react';

import { Box } from '@mui/material';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, Container, Typography } from '@mui/material';
import { Dialog, TextField, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import TableComponent from './TableComponent';
import FormFieldsComponent from './FormFieldsComponent';

function LandingItemSelector({children, clickHandler}) {
    
    useEffect(() => {
        async function fetchData() {
            try {
                
            } catch (error) {
            }
        }
        fetchData();
    }, []);

    return (
        <Box>
            {children}
            <Button onClick={clickHandler}>x</Button>
        </Box>
    );
}

export default LandingItemSelector;
