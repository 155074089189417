import { LOCALES } from '../../../i18n/locales';

export const getClubPageStructure = (local) => {

    const translations_ukr = {
        "Вітрина Проектів Д2232": "Вітрина Проектів Д2232",
        "Контактна особа": "Контактна особа",

        "Головна": "Головна",
        "Огляд проектів": "Огляд проектів",
    };

    const translations_eng = {
        "Вітрина Проектів Д2232": "D2232 Projects Showcase",
        "Контактна особа": "Contact Person",

        "Головна": "Home",
        "Огляд проектів": "Browse Projects",
    };
    

    const translations = local === LOCALES.UKRAINIAN ? translations_ukr : translations_eng;

    return {
        navbar: {
            title: translations["Вітрина Проектів Д2232"],
            logoUrl: "https://res.cloudinary.com/tyko/image/upload/v1704634818/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/D2232_hpvpzq.png",
            languages: [
                "ua-UA",
                "en-US",
            ],
            menu: [
                {
                    title: translations["Головна"],
                    lref: '/project_showcase',
                },
                {
                    title: translations["Огляд проектів"],
                    lref: '/project_showcase/2',
                },
            ],
        },
        footer: {
            contactPerson: {
                name: translations["Контактна особа:"],
                email: "oncology@2upost.com",
                email2: "paliychuk.oncology@gmail.com",
            },
        }
    }
}
