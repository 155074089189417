import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, Button, Tooltip  } from '@mui/material'
import '../Pages.css';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import MainPage from "../../components/Main";
import LandingBlock from '../../components/LandingBlock';
import DocumentsPageEditor from './DocumentsPageEditor';
import { getCommuniqueData, addCommuniqueData, updateCommuniqueData } from "../../api/communiqueData";

import IMG_PNG_1 from '../../assets/img/icons/pdf_icon.png';

const DocumentsPageEdit = () => {

  return (
    <MainPage>
      <LandingBlock mode='light'>
        <Box className="section text-left" >
            <ProjectActivePage />
        </Box>
      </LandingBlock>
    </MainPage>
  )
};

const ProjectActivePage = () => {
    const [data, setData] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isShowEditor, setIsShowEditor] = useState(false);
    const [isAdded, setIsAdded] = useState(true);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const events = await getCommuniqueData();
          //console.log('getEventsData = '+JSON.stringify(events.data, null, 2));
          setData(events.data.reverse());
        } catch (error) {
          // Обробити помилку отримання даних
        }
      };

      fetchData();
    }, []);
  
    const toFileClick = (url) => {
      console.log("toFileClick");
      window.open(url, '_blank');
    }

    const handleAddClick = () => {
        // Обробник натискання кнопки "Додати"
      };
    
      const handleEditClick = () => {
        // Обробник натискання кнопки "Редагувати"
      };
    
      const handleDeleteClick = () => {
        // Обробник натискання кнопки "Видалити"
      };

      const handleRowClick = (row) => {
        setSelectedRow(row);
      };

      const handleContainerClick = () => {
        //setSelectedRow(null);
      };

      const onCancel = () => {
        setIsShowEditor(false);
      };
      const onSubmit = (newItem) => {
        if(isAdded) {
          setData(prevData => [newItem, ...prevData]);
        } else {
          setData(prevData => {
            let newData = [...prevData];
            newData[2] = newItem;
            return newData;
          });
        }
        setIsShowEditor(false);
        //setSelectedRow(null);
      };
      const handleAddComClick = () => {
        setIsAdded(true);
        setIsShowEditor(true);
        if(data && data.length>0) {
          //addCommuniqueData(item);
        }
      };
      const handleEditComClick = () => {
        setIsAdded(false);
        setIsShowEditor(true);
        if(data && data.length>0) {
          //updateCommuniqueData(selectedRow, data[selectedRow]);
        }
      };
      const handleDeleteComClick = () => {
        setData(prevData => prevData.filter((item, index) => index !== selectedRow));
      }; 

    return (
        <>

            <Container onClick={handleContainerClick}>
              {selectedRow}
            <Typography variant="h4" className="page-header">Комюніке 23-24</Typography>
                  <TableContainer component={Paper}>

                <div>
                    <Button startIcon={<AddIcon />} onClick={handleAddComClick}>
                        Додати
                    </Button>
                    <Button disabled={!selectedRow} startIcon={<EditIcon />} onClick={handleEditComClick}>
                        Редагувати
                    </Button>
                    <Button disabled={!selectedRow} startIcon={<DeleteIcon />} onClick={handleDeleteComClick}>
                        Видалити
                    </Button>
                </div>


                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#98c8ff" }}>
                        <TableCell></TableCell>
                        <TableCell><Typography variant="h6">Номер</Typography></TableCell>
                        <TableCell><Typography variant="h6">Назва комуніке</Typography></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data && data.map((row, index) => (
                        <TableRow key={index}
                            onClick={() => handleRowClick(index)}
                            selected={selectedRow === index}
                            hover>
                          <TableCell>
                            
                          </TableCell>
                          <TableCell>{row.number}</TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <Button onClick={() => toFileClick(row.url)}>
                                <img src={IMG_PNG_1} alt="Add" height='40px' className="icon-image" />
                              </Button>
                              <Typography variant="h6">{row.title}</Typography>
                            </Box>
                            
                            <Box display="flex" alignItems="center">
                            {row.urls && row.urls.map((r,i) => (
                              <Tooltip key={r.url} title={r.title} arrow>
                                <Box display="flex" alignItems="center">
                              <Button onClick={() => toFileClick(r.url)}>
                                <img src={IMG_PNG_1} alt="Add" height='24px' className="icon-image" />
                              </Button>
                                <Typography variant="subtitle2">{r.title.length > 15 ? `${r.title.slice(0, 15)}...` : r.title}</Typography>
                                </Box>
                              </Tooltip>
                            ))}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                  
              </Container>

              {isShowEditor && data && data.length > 0 &&
               <DocumentsPageEditor item={data[selectedRow]} isAdded={isAdded} nextId={data.length+1} onCancel={onCancel} onSubmit={onSubmit} /> 
              }

        </>
  );
}

export default DocumentsPageEdit;