import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Box, Typography, Card, CardContent, CardActions, Button, Paper } from '@mui/material';
import moment from 'moment';

const API_BASE_URL = 'https://api-sequelize.rotaryd2232ukraine.org/db';

const CalendarCards = ({ filters, setEventCounts }) => {
  const [events, setEvents] = useState([]);
  const lastPastEventRef = useRef(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(API_BASE_URL + '/gevents');
        const fetchedEvents = response.data;

        // Обробка та фільтрація подій
        const filteredEvents = fetchedEvents.filter(event => {
          if (filters.district && event.colorId === '1') return true;
          if (filters.club && event.colorId === '2') return true;
          if (filters.governor && event.colorId === '3') return true;
          return false;
        });

        // Сортування подій за датою початку
        filteredEvents.sort((a, b) => new Date(a.start) - new Date(b.start));

        setEvents(filteredEvents);

        // Оновлення кількості подій кожного типу
        const eventCounts = {
          district: fetchedEvents.filter(event => event.colorId === '1').length,
          club: fetchedEvents.filter(event => event.colorId === '2').length,
          governor: fetchedEvents.filter(event => event.colorId === '3').length,
        };
        setEventCounts(eventCounts);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, [filters, setEventCounts]);

  useEffect(() => {
    if (lastPastEventRef.current) {
      lastPastEventRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [events]);

  const formatDate = (date) => moment(date).format('DD.MM.YYYY');
  const isPastEvent = (endDate) => moment(endDate).isBefore(moment(), 'day');

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {events.map((event, index) => {
          const pastEvent = isPastEvent(event.end);
          const isLastPastEvent = pastEvent && (index === events.length - 1 || !isPastEvent(events[index + 1]?.end));

          return (
            <Card
              key={event.id}
              ref={isLastPastEvent ? lastPastEventRef : null}
              sx={{
                width: 300,
                backgroundColor: pastEvent ? 'gray' : 'white',
                color: pastEvent ? 'lightgray' : 'black'
              }}
            >
              <CardContent>
                <Typography variant="h6" sx={{ color: pastEvent ? 'lightgray' : 'orange' }}>
                  {event.title}
                </Typography>
                <Typography variant="body2" sx={{ color: pastEvent ? 'lightgray' : 'blue' }}>
                  {formatDate(event.start)} - {formatDate(event.end)}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                  {event.description || 'Без опису'}
                </Typography>
                {event.organizer && (
                  <Typography variant="body2" sx={{ marginTop: 1 }}>
                    Організатор: {event.organizer.displayName}
                  </Typography>
                )}
              </CardContent>
              {event.htmlLink && (
                <CardActions>
                  <Button
                    size="small"
                    href={event.htmlLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: pastEvent ? 'lightgray' : 'blue' }}
                  >
                    Посилання на подію
                  </Button>
                </CardActions>
              )}
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};

export default CalendarCards;
