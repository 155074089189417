import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from '@mui/material'
import './Pages.css';

import MainPage from "../components/Main";
import LandingBlock from '../components/LandingBlock';
import LandingHeader from '../components/LandingHeader';

import IMG1 from '../assets/content/aboutus/PresidentRI.jpg'
import IMG2 from '../assets/content/aboutus/TrusteeChairRI.jpg'
import IMG3 from '../assets/content/aboutus/GeneralSecretaryRI.jpg'

const LeadersRIPage = () => {
    return (
        <>
          <MainPage>
            
            <LandingBlock mode='light'>
            <Box className="section text-left" >
            
              <Container>
              <LandingHeader><Typography variant="h4" className="page-header">Лідери Ротарі Інтернешнл</Typography></LandingHeader>
                  <Grid container  direction="row" spacing={2}>
                      <Grid item md={4}>
                        <img src={IMG1} width='100%'/>
                      </Grid>
                      <Grid item md={8}>
                        <h2>President</h2>
                        <p>
                            Rotary’s president presides over the Board of Directors and is elected to a one-year term.
                        </p>
                        <h3>MEET OUR PRESIDENT</h3>
                        <p>R. Gordon R. McInally is president of Rotary International. He was educated at the Royal High School in Edinburgh and at the University of Dundee, where he earned his graduate degree in dental surgery. He operated his own dental practice in Edinburgh until 2016. Gordon was chair of the East of Scotland branch of the British Paedodontic Society and has held various academic positions. He has also served as a presbytery elder, chair of the Queensferry parish congregational boar
                        </p>
                      </Grid>
                  </Grid>
                  
              </Container>
            </Box>
            <Box className="section text-left" >
            
              <Container>
                  <Grid container  direction="row" spacing={2}>
                      <Grid item md={4}>
                        <img src={IMG2} width='100%'/>
                      </Grid>
                      <Grid item md={8}>
                        <h2>Trustee chair</h2>
                        <p>The trustee chair is elected by the Board of Trustees and leads The Rotary Foundation for one year.</p>
                        <h3>MEET OUR TRUSTEE CHAIR</h3>
                        <p>Barry Rassin is a director and former president of Doctors Hospital Health System in Nassau, Bahamas, where he retired after a 38-year career. He was the first fellow of the American College of Healthcare Executives in the Bahamas and was honored with the National Award of Health Hero by the Bahamas Ministry of Health and the Pan American Health Organization.
                        </p>
                      </Grid>
                  </Grid>
                  
              </Container>
            </Box>
            <Box className="section text-left" >
            
              <Container>
                  <Grid container  direction="row" spacing={2}>
                      <Grid item md={4}>
                        <img src={IMG3} width='100%'/>
                      </Grid>
                      <Grid item md={8}>
                        <h2>General Secretary and Chief Executive Officer</h2>
                        <p>Rotary’s general secretary and executive team oversee a staff of more than 800, who serve Rotary members from our world headquarters in Evanston, Illinois, USA, and seven international offices. The general secretary is a member of the Rotary International Board of Directors and The Rotary Foundation Board of Trustees..</p>
                        <h3>MEET OUR GENERAL SECRETARY AND CHIEF EXECUTIVE OFFICER</h3>
                        <p>John Hewko has served as Rotary International’s general secretary and chief executive officer since 2011. A charter member of the Rotary Club of Kyiv, Ukraine, Hewko lives out Rotary’s mission, whether inside or outside the office. He’s immunized children against polio in India, represented Rotary at the World Economic Forum, and bicycled 104 miles (167 km) with Rotary members in Arizona, USA, to help raise over $50 million for polio eradication. "It’s an honor to go the extra mile in serving this great organization. I see Rotary as the original social network formed to exchange ideas and resources so we can transform lives. We’re a bold NGO that believed it could end a disease, and we’ve stuck to the cause for over three decades. That thought inspires me every morning."</p>
                      </Grid>
                  </Grid>
                  
              </Container>
            </Box>
            </LandingBlock>
            
            
          </MainPage>
        </>
  );
}

export default LeadersRIPage;