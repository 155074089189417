import React from 'react';
import {IconButton} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const LandingFileIcon = ({text, link}) => {

    return (
        <IconButton aria-label="Open PDF" color="primary"  
            onClick={() => window.open(link, '_blank')}>
        <PictureAsPdfIcon fontSize="large" style={{ marginRight: '4px' }} />
        <div style={{textAlign: 'left', verticalAlign: 'top', display: 'inlineBlock', whiteSpace: 'preWrap"'}}>
        {text}
        </div>
        </IconButton>
    );
};

export default LandingFileIcon;
