import { Box, TextField, Button, Typography } from '@mui/material';

import FormFieldsComponent from '../../pages/MyAdministrations/FormFieldsComponent';

const OverlayFormFieldsComponent = ({selectedData, tableTitles, setSelectedData, handleSubmit}) => {
    return (
        <>
            <Box sx={{ 
                position: 'fixed', 
                top: 0, 
                left: 0, 
                height: '100%', 
                width: '100%', 
                display: 'flex', 
                flexDirection: 'column-reverse' 
            }}>
            <Box sx={{ width: '100%', bgcolor: 'background.paper', }}>
            
                <Typography variant="h5">
                    {selectedData && (selectedData.id ? "Edit Communique" : "Add Communique")}
                </Typography>
                <FormFieldsComponent
                    selectedCommunique={selectedData}
                    communiqueTableTitles={tableTitles}
                    setSelectedCommunique={setSelectedData}
                    handleSubmit={handleSubmit}
                />
                
            </Box>

            {/* Чорна прозора частина */}
            <Box sx={{ flex: 1, backgroundColor: 'rgba(0, 0, 0, 0.5)' }} />
            </Box>
        </>
    )
}

export default OverlayFormFieldsComponent;