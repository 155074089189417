import React, { useEffect, useState, useRef } from 'react';
import { Box, Card, CardContent, Typography, Avatar, Grid, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Button } from '@mui/material';
import QRGenerator from './QRGenerator';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import successSound from '../../assets/audio/fanfara.mp3'; 

import { getAllUsers, createUser } from '../../api_sequelize/api_eventusers';

const data = "https://rotaryd2232ukraine.org/rotarians/vira";
const logo = "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/LogoD2232_withborders.png";
const photo = 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Zone21/0-02-05-a70321ba5d5e89115aedceebe09c2999cdcb0feda8f5bdbe2a3ed3160fcbeb55_cb415fdebb97dbae.jpg';
const rylaLogo = 'https://api.rotary.org.ua/uploads/clubsitecreator/SharedImages/RYLA_Logo_large_v3.png';

const RylaCard = () => {
  const [isRegistered, setIsRegistered] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    const checkRegistration = async () => {
      try {
        const response = await getAllUsers();
        const foundUser = response.data.find(user => user.first_name === 'Віра');
        if (foundUser) {
          setIsRegistered(true);
          return true; // Return true if the user is found
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
      return false; // Return false if the user is not found or an error occurred
    };

    const initCheck = async () => {
      const isUserRegistered = await checkRegistration();
      if (!isUserRegistered) {
        const intervalId = setInterval(async () => {
          const isUserRegistered = await checkRegistration();
          if (isUserRegistered) {
            setDialogOpen(true);
            clearInterval(intervalId);
          }
        }, 10000); // 10 seconds
        return () => clearInterval(intervalId);
      }
    };

    initCheck();
  }, []);

  const handleClose = () => {
    setDialogOpen(false);
    audioRef.current.play(); // Play the success sound after closing the dialog
  };

  return (
    <Card sx={{ maxWidth: 400, mx: 'auto', mt: 5, p: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          textAlign="center"
          gutterBottom
          sx={{ fontWeight: 'bold', color: '#0D47A1' }}
        >
          Візитівка учасника RYLA
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <img src={rylaLogo} alt="RYLA Logo" style={{ width: '40%', marginRight: '16px' }} />
          <Avatar src={photo} alt="Participant Photo" sx={{ width: 150, height: 150 }} />
        </Box>
        <Typography
          variant="h3"
          component="div"
          textAlign="center"
          gutterBottom
          sx={{ fontWeight: 'bold', color: 'orange' }}
        >
          Віра Сиряміна
        </Typography>

        <Typography
          variant="h5"
          component="div"
          textAlign="center"
          gutterBottom
          sx={{ color: '#0D47A1' }}
        >
          RYLA Registration Qr-code
        </Typography>
        <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
          <Grid item xs={12} md={6} textAlign="center">
            <QRGenerator data={data} logo={logo} />
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, pl: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
            <Typography>Онлайн реєстрація на ячасть в події - зарестровано.</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isRegistered ? (
              <>
                <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                <Typography>Офлайн реєстрація на рецепшн - зареєстровано.</Typography>
              </>
            ) : (
              <>
                <CancelIcon sx={{ color: 'red', mr: 1 }} />
                <Typography>Офлайн реєстрація на рецепшн - не зареєстровано.</Typography>
              </>
            )}
          </Box>
        </Box>
      </CardContent>

      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>Реєстрація</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ви успішно зарестровані.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">OK</Button>
        </DialogActions>
      </Dialog>

      <audio ref={audioRef} src={successSound} />
    </Card>
  );
};

export default RylaCard;
