
import { LOCALES } from '../../../i18n/locales';

export const getRCLanding4 = (local) => {
    const cherkasyData = local === LOCALES.UKRAINIAN ? cherkasyData_ukr : cherkasyData_eng;
    return cherkasyData;
};

const cherkasyData_ukr = [
    {
        id: 0,
        header: 'Блок Новин',
        arrcontent: [
            {
                //img: "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/donate/donate.jpg",
                content: [
                    {LandingMessage: `Новина 1`},
                    {BR: 'true'},{BR: 'true'},
                   
                ]
            },
        ]
    },
];

const cherkasyData_eng = [
];