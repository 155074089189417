import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TextField, Button, Typography } from '@mui/material';
import {URL_TO_API_SERVER} from '../../api/LoginAndSuccess/var';

function ChangePasswordFrame() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');

    const location = useLocation();
    // Функція для отримання параметрів запиту
    const getQueryParams = (query) => {
        const params = new URLSearchParams(query);
        return Array.from(params.entries()).reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {});
    };
    const queryParams = getQueryParams(location.search);
    const memberId = queryParams.id;  // Отримуємо значення id
    const domenUrl = queryParams.domenUrl;

    const handleSubmit = async () => {
        const data = new FormData();
        data.append('id', memberId);
        data.append('email', email);
        data.append('password', password);
        data.append('domenUrl', domenUrl);
        // Assuming the memberID is passed via a URL parameter. Adjust as necessary.
        const response = await fetch(URL_TO_API_SERVER+`api_change_password.php`, {
            method: 'POST',
            body: data
        });
        const result = await response.json();
        setMessage(result.message);
        setStatus(result.status);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '300px', margin: 'auto' }}>
            <div>domenUrl={domenUrl}</div>
            <Typography variant="h5">Change Password</Typography>
            <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ marginBottom: '16px' }}
            />
            <TextField
                type="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ marginBottom: '16px' }}
            />
            <TextField
                type="password"
                label="Confirm Password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                style={{ marginBottom: '16px' }}
            />
            <Button variant="contained" color="primary" onClick={handleSubmit}>Change Password</Button>
            {message && <Typography color="error">{message}</Typography>}
            domenUrl={domenUrl}
            {status === 'success' && domenUrl.includes('https://rotary.org.ua') ? <a href='https://rotary.org.ua/template/Main/inside/login.php'>перейти на сайт</a> : <a href={domenUrl+'/myhome'}>перейти на сайт</a>}
        </div>
    );
}

export default ChangePasswordFrame;
