import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from '@mui/material'
import '../Pages.css';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import MainPage from '../../modules/MYMain';
import LandingBlock from '../../components/LandingBlock';

const data = [
    { name: "Олександр Хардін", status: "Голова Координаційного Комітету", info: "Губернатор-елект Дистрикту 2232"},
    { name: "Борис Боднар", status: "Головаа підкомітету Логістика",  info: "Асистент Губернатора Д2232, Голова Комітету з підготовки Конференції Д2232 у 2024р"},
    { name: "...", status: "...",  info: ".."},
];

const CoordinationCommitteePage = () => {

    return (
        <>
          <MainPage>
            <LandingBlock mode='light'>
            <Box className="section" >
            
            <Container>
                  <Typography variant="h4" className="page-header">Склад Координаційного Комітету 23-24</Typography>
                  <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#98c8ff" }}>
                        <TableCell></TableCell>
                        <TableCell>Ім'я, Приізвище</TableCell>
                        <TableCell>Позиція в комітеті</TableCell>
                        <TableCell>Інформація</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{index}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.status}</TableCell>
                          <TableCell>{row.info}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                  
              </Container>
            </Box>
            </LandingBlock>
            
          </MainPage>
        </>
  );
}

export default CoordinationCommitteePage;