import axios from 'axios';

//const SERVER_URL = "http://localhost:3001/api/news";
const SERVER_URL = 'https://fathomless-inlet-58403-132cf80acea5.herokuapp.com/api/events';

export const getEventsData = () => {
    return axios.get(SERVER_URL);
}

export const addEventsData = (item) => {
    return axios.post(SERVER_URL, item);
}

export const updateEventsData = (itemId, item) => {
    return axios.put(SERVER_URL+itemId, item);
}
