import React, {useState, useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

import LandingContentItems from './LandingContentItems';
import { fetchUser } from '../pages/RC_Cherkasy/rotariansBD/api/api_projects';

function ListItemComponent({ item }) {

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            alert('Зміст скопійовано в буфер обміну');
          })
          .catch((error) => {
            console.error('Помилка при копіюванні в буфер обміну:', error);
          });
    };

return (
    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" gap={2}>

        <Box display='flex' flexDirection='column' alignItems='center' pt='12px' minWidth='150px'>

            {/* Перша колонка - зображення */}
            <Avatar src={item.img} alt={item.name} sx={{ width: 100, height: 100 }} />

            {/* Друга колонка - інформація */}
            <Box display="flex" flexDirection="column">
                <Typography variant="h6">{item.name}</Typography>
                <Typography variant="subtitle2">{item.rclub}</Typography>
                <Typography variant="body2">{item.business}</Typography>
            </Box>

            <Box display="flex" flexDirection="row" alignItems="flex-start">
                {item.phone && <IconButton aria-label="phone" onClick={() => copyToClipboard(item.phone)}>
                <PhoneIcon />
                </IconButton>}
                {item.email && <IconButton aria-label="email" onClick={() => copyToClipboard(item.email)}>
                <EmailIcon />
                </IconButton>}
                {item.fb && <IconButton aria-label="facebook" onClick={() => window.open(item.fb, '_blank')}>
                <FaFacebookF />
                </IconButton>}
                {item.insta && <IconButton aria-label="instagram" onClick={() => window.open(item.insta, '_blank')}>
                <FaInstagram />
                </IconButton>}
            </Box>

        </Box>

        {/* Третя колонка - текст */}
        <Box display="flex" flexDirection="column" alignItems='flex-start' pt='12px'>
            <LandingContentItems content={item.content} />
        </Box>
    </Box>
  );
}

function LandingCards2List({ cards }) {
    const [page, setPage] = useState(1);
    const itemsPerPage = 4;
    const count = Math.ceil(cards.length / itemsPerPage);
    const topRef = useRef(null);

    const handleChange = (event, value) => {
        setPage(value);
        topRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    return (
        <React.Fragment>
            
            <div ref={topRef}>Кількість: {cards.length}</div> 


            <Stack spacing={2} alignItems="center" marginTop={2}>
                <Pagination count={count} page={page} onChange={handleChange} />
            </Stack>
            <List>
                {cards && cards.slice(start, end).map((item, index) => (
                    <React.Fragment key={index}>
                        <ListItem disablePadding>
                            <ListItemComponent item={item} />
                        </ListItem>
                        {index < itemsPerPage - 1 && <Divider />}
                    </React.Fragment>
                ))}
            </List>
            <Stack spacing={2} alignItems="center" marginTop={2}>
                <Pagination count={count} page={page} onChange={handleChange} />
            </Stack>
        </React.Fragment>
        );
}

export default LandingCards2List;