
import { LOCALES } from '../../../i18n/locales';

export const getMyCherkasyLanding = (local) => {
    //const cherkasyData = local === LOCALES.UKRAINIAN ? cherkasyData_ukr : cherkasyData_eng;
    return cherkasyData_ukr;
};

const cherkasyData_ukr = [
    {
        id: 0,
        header: 'Ротарі Клуб Черкаси',
        content: [
            {LandingMessage: `Дорогі одноклубники, раді вас вітати в нашому клубі!`},
            {LandingMessage: `(в розробці)`},
        ],
    },
];
