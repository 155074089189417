import React from 'react';
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import LandingContentItems from './LandingContentItems';

const LandingMatrixPrinterPaper = ({ itemArr }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Box 
            sx={{
                background: isMobile ? 'none' : `url('https://admin.designsnprint.com/img/products/large/dnp-18962062-96968073.jpg') no-repeat center center`,
                backgroundSize: 'cover',
                maargin: '0px',
                padding: isMobile ? '10px -12% 10px 2%' : '10px 12% 10px 12%',
                maxWidth: 'calc(100vw - 100px)',
                overflow: 'hidden',
            }}
        >
            
            {itemArr && 
            <Box sx={{ 
                overflowX: 'auto', 
            }}>
                <Typography sx={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                    {itemArr}
                </Typography>
            </Box>
            }
        </Box>
    );
  };

export default LandingMatrixPrinterPaper;
