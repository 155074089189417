import React, { useState, useEffect } from 'react';
import { Paper, Container } from '@mui/material';

import AdminTableComponent from '../../pages/MyAdministrations/AdminTableComponent';

import { getAll, getItemById, createItem, updateItem, deleteItem, communiqueTableTitles } from "../../api/ts/api_d2232_communiques";

export const emptyData = {
    data: '',
    number: '',
    title: '',
    url: '',
}

function AdminCommunique() {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            setLoading(true);
            const d = await getAll();
            setData(d.reverse());
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    const refreshData = async () => {
        fetchData();
    };

    return (
        <Container>
        <Paper>
            <h2>Admin Communique (new)</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {loading && <p>Завантаження...</p>}
            <AdminTableComponent 
                data={data}
                tableTitles={communiqueTableTitles}
                emptyData={emptyData}
                createItem={createItem}
                updateItem={updateItem}
                deleteItem={deleteItem}
                refreshData={refreshData}
                hasChildren={true}
            />
        </Paper>
        </Container>
    );
}

export default AdminCommunique;
