

export const getInternationalCommittee = () => {
    return defaultData;
};

const defaultData = [
    {
        role: "Комітет з питань міжнародного служіння",
        name: "Ольга Палійчук",
        rc: "РК Черкаси",
        site: "https://beta-rotary.tyko.com.ua/international",
        email: "oncology@2upost.com",
        phone: "+38050-52-073-52",
    },
    {
        role: "IRFHP - International Rotary Fellowship of Healthcare Professionals ",
        name: "Роман Міцода",
        rc: "РК Ужгород-Скала",
        site: "https://rotaryhealthprofessionals.org/",
        email: "rmitsoda@gmail.com",
        phone: "+380 (50) 529 13 36",
    },
    {
        role: ["IYFR - International Yachting Fellowship of Rotarians", "website - IYFR Region Ukraine"],
        name: "Андрій Черепанов",
        rc: "РК Миколаїв",
        site: ["https://www.iyfr.net/", "https://iyfr.tyko.com.ua/region"],
        email: "cherepanov12a@yahoo.co.uk 	",
        phone: "+380 (50) 318 31 38",
    },
    {
        role: "IFFR - International Fellowship of Flying Rotarians ",
        name: "Ігор Зембович",
        rc: "РК Львів",
        site: "https://www.iffr.org/",
        email: "izembovych@gmail.com",
        phone: "+380 (67) 670 82 92",
    },
    {
        role: "інші Ротарійські Товариства - International Rotary Fellowships",
        name: "Тарас Гергелюк",
        rc: "РК Івано-Франківськ",
        site: "https://www.rotary.org/en/our-programs/more-fellowships",
        email: "",
        phone: "",
    },
    {
        role: "бізнес та МДК",
        name: "Артем Ващиленко",
        rc: "РК Миколаів",
        email: "rotaryif@ukr.net",
        phone: "+380 (50) 538 24 84",
    },
    {
        role: "культурні міжнародні зв’язки",
        name: "Олеся Телиженко",
        rc: "РК Черкаси",
        email: "artem.biz.mk@gmail.com",
        phone: "+380 (67) 234 06 77",
    },
    {
        role: "дружні міжнародні зв’язки",
        name: "Світлана Белоус",
        rc: "РК Черкаси",
        email: "vetabel2@gmail.com",
        phone: "+380 (93) 461 02 22",
    }, 
    {
        name: "Ірина Кобець",
        role: "навчальні Ротарійські програми за кордоном",
        rc: "РК Черкаси",
        email: "ceo@iceainternational.com",
        phone: "+1 (437) 351-2244",
    }, 
    {
        name: "Ігор Гайда",
        role: "професійні міжнародні програми",
        rc: "РК Черкаси",
        email: "igorulia88@gmail.com",
        phone: "+380 (93) 922 99 28",
    }, 
    {
        name: "Христина Лоза",
        role: "молодіжні міжнародні зв’язки, допомога у комунікаціях з Ротаракт та Інтеракт клубами за кордоном",
        rc: "РК Тернопіль",
        email: "doctorchristinet@gmail.com",
        phone: "+380 (98) 954 52 22",
    }, 
    {
        name: "Леся Джадан",
        role: "залучення міжнародних донорів для програм післявоєнної розбудови, медійна підтримка роботи комітету",
        rc: "РК Київ-Столиця",
        email: "l.dzhadan@ukr.net",
        phone: "+380 (50) 410 22 58",
    }, 
  ]