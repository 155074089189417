import React, { useState } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import axios from 'axios';
import {uploadFiles} from '../../modules/ServerFileSystem/uploadFiles';

const SERVER_UPLOAD_URL = 'https://api.rotary.org.ua/upload';

// Стилизований контейнер для drag-and-drop
const DropZone = styled('div')(({ theme, isDragging }) => {
  const borderColor = theme?.palette?.primary?.main || '#000';
  const backgroundColor = isDragging ? (theme?.palette?.action?.hover || '#ddd') : (theme?.palette?.background?.default || '#fff');

  return {
    border: `2px dashed ${borderColor}`,
    padding: theme?.spacing(4) || '16px',
    borderRadius: theme?.shape?.borderRadius || '4px',
    backgroundColor: backgroundColor,
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };
});

const FileUpload = ({ currSubFolderUrl, handleUploadedFiles }) => {
    const theme = useTheme();
    const [isDragging, setIsDragging] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(null);
  
    const handleDragOver = (event) => {
      event.preventDefault();
      setIsDragging(true);
    };
  
    const handleDragLeave = () => {
      setIsDragging(false);
    };
  
    const handleDrop = (event) => {
      event.preventDefault();
      setIsDragging(false);
      const files = Array.from(event.dataTransfer.files);
      setSelectedFiles([...selectedFiles, ...files]);
    };
  
    const handleFileChange = (event) => {
      const files = Array.from(event.target.files);
      setSelectedFiles([...selectedFiles, ...files]);
    };
  
    const handleUpload = async () => {
        setUploading(true);
        setUploadSuccess(null);
    
        const formData = new FormData();
        formData.append('path', currSubFolderUrl);
        formData.append('title', 'uploadTitle');
        selectedFiles.forEach(file => formData.append('photos', file));
    
        // try {
        //     const response = await axios.post(SERVER_UPLOAD_URL, formData, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //     },
        //     });
        //     setUploadSuccess(true);
        //     handleUploadedFiles(selectedFiles); // Передаємо завантажені файли
        //     setSelectedFiles([]); // Очищаємо список вибраних файлів після завантаження
        // } catch (error) {
        //     console.error('Upload failed:', error);
        //     setUploadSuccess(false);
        // } finally {
        //     setUploading(false);
        // }

        const apiUrl = 'https://api.rotary.org.ua/upload';
        fetch(apiUrl, {
            method: 'POST',
            body: formData,
        })
        .then((response) => response.text())
        .then((data) => {
            console.log(data);
            setSelectedFiles([]);
            setUploadSuccess(true);
            //finally
            setUploading(false);
            handleUploadedFiles(selectedFiles);
        })
        .catch((error) => {
            console.error('Помилка завантаження: ', error);
            setUploadSuccess(false);
        });

    };
  
    return (
      <Box>
        currSubFolderUrl={currSubFolderUrl}
        <DropZone
          theme={theme}
          isDragging={isDragging}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <Typography variant="h6">Drag & Drop files here or click to select files</Typography>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="file-input"
          />
          <label htmlFor="file-input">
            <Button variant="contained" component="span">
              Select Files
            </Button>
          </label>
        </DropZone>
        {selectedFiles.length > 0 && (
          <Box mt={2}>
            <Typography variant="subtitle1">Selected Files:</Typography>
            <ul>
              {selectedFiles.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={uploading}
            >
              {uploading ? <CircularProgress size={24} /> : 'Upload'}
            </Button>
          </Box>
        )}
        {uploadSuccess !== null && (
          <Box mt={2}>
            {uploadSuccess ? (
              <Typography variant="subtitle1" color="success.main">
                Files uploaded successfully!
              </Typography>
            ) : (
              <Typography variant="subtitle1" color="error.main">
                Failed to upload files.
              </Typography>
            )}
          </Box>
        )}
      </Box>
    );
  };

export default FileUpload;
