import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import './Calendar.css'; // Імпортуємо CSS

const API_BASE_URL = 'https://api-sequelize.rotaryd2232ukraine.org/db';

const GoogleCalendar3 = ({ filters, setEventCounts }) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(API_BASE_URL + '/gevents');
        const fetchedEvents = response.data;
        console.log(`fetchEvents count=${fetchedEvents.length}`)

        // Обробка та фільтрація подій
        const filteredEvents = fetchedEvents.filter(event => {
          if (filters.district && event.colorId === '1') return true;
          if (filters.club && event.colorId === '2') return true;
          if (filters.governor && event.colorId === '3') return true;
          return false;
        });

        setEvents(filteredEvents);

        // Оновлення кількості подій кожного типу
        const eventCounts = {
          district: fetchedEvents.filter(event => event.colorId === '1').length,
          club: fetchedEvents.filter(event => event.colorId === '2').length,
          governor: fetchedEvents.filter(event => event.colorId === '3').length,
        };
        setEventCounts(eventCounts);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, [filters, setEventCounts]);

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ mt: 2 }}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={events.map(event => ({
            id: event.id,
            title: event.title,
            start: event.start,
            end: event.end,
            description: event.description,
            color: event.colorId === '1' ? 'blue' : event.colorId === '2' ? 'green' : 'orange',
            extendedProps: {
              organizer: event.organizer,
              htmlLink: event.htmlLink
            }
          }))}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          buttonText={{
            today: 'Today',
            month: 'Month',
            week: 'Week',
            day: 'Day'
          }}
          eventContent={renderEventContent} // Використання кастомного контенту для події
        />
      </Box>
    </Box>
  );
};

const renderEventContent = (eventInfo) => {
  const { organizer, htmlLink } = eventInfo.event.extendedProps;
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
      {organizer && <p>{organizer.displayName}</p>}
      {htmlLink && (
        <a href={htmlLink} target="_blank" rel="noopener noreferrer">Event Link</a>
      )}
    </>
  );
};

export default GoogleCalendar3;
