import { Table, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material';

const TableLineComponent = ({data, tableTitles, addNumber}) => {
    return (
        <>
        {
            //JSON.stringify(communiques)
        }
        {
            //JSON.stringify(tableTitles)
        }
        <Table>
            <TableHead>
                <TableRow sx={{ backgroundColor: "#98c8ff" }}>
                    {addNumber && <TableCell key={-1}>№</TableCell>}
                    {tableTitles && Object.values(tableTitles).map((item) => (
                        <TableCell key={item}>{item}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data && data.map((dataItem, index) => (
                    <TableRow key={dataItem.id} >
                        {addNumber && <TableCell key={index}>{index+1}</TableCell>}
                        {tableTitles && Object.keys(tableTitles).map((item) => (
                            <TableCell 
                                key={item}
                            >
                                { 
                                    
                                    dataItem[item]
                                }
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        </>
    )
}

export default TableLineComponent;