import { LOCALES } from '../../../i18n/locales';

export const getHeaderV4 = (local) => {
    return defaultData_ukr;//local===LOCALES.UKRAINIAN ? defaultData_ukr : defaultData_eng;
};

const defaultData_ukr = {
    //title: "Збірка Документів та Посилань 2024",
    smallTitle: "Збірка Документів та Посилань 2024",
    imgs: [
        "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Event_Pets/1707316214742-907375035.jpg",
        'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/photos/0-02-05-7596656d7b5f27bf13ddefe6862e1b76280433484bf8be7684e914528271e7a2_6e7df32ce8bd3a03.jpg',
        'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/photos/0-02-05-efd2ae080b1fcf0009d7b06d148c212bca8502328a2b4313ef3fa5bb1865d5a3_fd0d2ed0fff86ee5.jpg',
    ],
    //button: {title: "Реєстрація учасників", url: "https://forms.gle/g5KMEJYshasGqvAK7"},
}