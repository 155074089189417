import {getAll_, getItemById_, createItem_, updateItem_, deleteItem_} from './api_crud';

const tableName = "api_magazines.php"; 

export type Magazines = {
    id?: number;
    order_: number;
    rotarYear: string;
    title: string;
    month: string;
    url: string;
};

export const tableTitles = {
    id: "id",
    order_: "order_",
    rotarYear: "Рік",
    title: "title",
    month: "month",
    url: "url",
}

export async function getAll(): Promise<any[]> {
    return await getAll_(tableName);
}

export async function getItemById(id: number): Promise<any> {
    return await getItemById_(tableName, id);
}

export async function createItem(data: any): Promise<void> {
    return await createItem_(tableName, data);
}

export async function updateItem(data: any): Promise<void> {
    return await updateItem_(tableName, data);
}

export async function deleteItem(id: number): Promise<void> {
    return await deleteItem_(tableName, id);
}