import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Grid, Box, List, ListItem, ListItemText, ListItemIcon, IconButton } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileActions from './FileActions';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'; 
import FileUpload from './FileUpload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload'; 
import ShareIcon from '@mui/icons-material/Share';

import {fsFetchFiles, fsCreateFolder, fsRenameItem, fsDeleteItem, fsShareFiles, fsGetFile} from './fstools';

const SERVERURL = 'https://apifs.rotaryd2232ukraine.org/storage';
const root_folder = '/clubsitecreator/rec_ofukraine/donate';

const FileExplorer = () => {
    const [path, setPath] = useState(root_folder);
    const [selectedFile, setSelectedFile] = useState();
    const [files, setFiles] = useState([]);
    const [newFolder, setNewFolder] = useState();
    const [isUpload, setIsUpload] = useState(false);
    const [shareLinks, setShareLinks] = useState({});

    useEffect(() => {
        fetchFiles();
    }, [path]);

    const fetchFiles = async () => {
        const arrFiles = await fsFetchFiles(path);
        if (arrFiles) {
            fetchShareLinks(arrFiles);
            setFiles(arrFiles);
        }
    };

    const fetchShareLinks = async (files) => {
        const links = {};
        for (const file of files) {
            if (!file.dir && file.media==='image') {
                const sharelink = await fsGetFile(file.url);
                links[file.name] = sharelink;
            }
        }
        setShareLinks(links);
    };

    const handlePathChange = (newPath, name) => {
        console.log(`handlePathChange newPath=${newPath} name=${name}`);

        function getParentPath(currentPath) {
            const segments = currentPath.split('/').filter(Boolean); // Розбиваємо шлях на сегменти

            // Якщо поточний шлях вже дорівнює root_folder, не дозволяємо підніматися вище
            if ('/' + segments.join('/') === root_folder) {
                return root_folder;
            }

            segments.pop(); // Видаляємо останній сегмент
            const newPath = '/' + segments.join('/'); // Об'єднуємо сегменти назад у рядок

            // Якщо новий шлях стає меншим за root_folder, повертаємо root_folder
            return newPath.startsWith(root_folder) ? newPath : root_folder;
        }

        if (name === '..') {
            setPath(getParentPath(newPath));
        } else {
            setPath(newPath);
        }
        setSelectedFile(null);
    };

    const onFileSelected = (selFile) => {
        console.log(`onFileSelected selFile=${selFile}`);
        setSelectedFile(selFile);
    };

    const handleResult = (res) => {
        console.log(`handleResult res=${res}`);
        
        setNewFolder(null);
        setSelectedFile(null);
        setIsUpload(false);

        fetchFiles();
    };

    const handleItemClick = (item) => {
        setNewFolder(null);
        if (item.dir) {
            handlePathChange(item.path, item.name);
        } else {
            onFileSelected(item.name);
        }
        setIsUpload(false);
        setNewFolder(null);
    };

    const handleEdit = (item) => {
        if (item.dir) {
            setSelectedFile(item.name);
        } else {
            setSelectedFile(item.name);
        }
        setIsUpload(false);
    };

    const handleDelete = async (file) => {
        const success = await fsDeleteItem(path, file.name);
        if (success) {
            fetchFiles();
        }
    };

    const handleNewFolder = async () => {
        setNewFolder('new folder');
        setSelectedFile(null);
        setIsUpload(false);
    }

    const handleBtnUpload = async () => {
        setIsUpload(true);
        setNewFolder(null);
        setSelectedFile(null);
    }

    const handleShare = async () => {
        setIsUpload(true);
        setNewFolder(null);
        setSelectedFile(null);
    }

    const BASE_URL='https://7fc65ea596c38701.cdn.express';
    return (
        <Container>
            <Typography variant="h4" gutterBottom>File Explorer</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                        <TextField
                            fullWidth
                            label="Current Path"
                            value={path}
                            onChange={(e) => setPath(e.target.value)}
                        />
                        <Button variant="contained" onClick={() => handlePathChange(root_folder)}>Go to Root</Button>
                    </Box>
                    <IconButton edge="end" onClick={() => handleNewFolder()}>
                        <CreateNewFolderIcon />
                    </IconButton>
                    <IconButton edge="end" onClick={() => handleBtnUpload()}>
                        <CloudUploadIcon />
                    </IconButton>        
                </Grid>
                <Grid item xs={12}>
                    <List>
                        {files.map(file => (
                            <ListItem key={file.name}  >
                                {/* <img src={file.url} height='30px' /> */}
                                {/* {shareLinks[file.name]} */}
                                {/* {BASE_URL+file.url} */}

                                <ListItemIcon onClick={() => handleItemClick(file)}>
                                    {file.dir ? <FolderIcon /> : <InsertDriveFileIcon />}
                                </ListItemIcon>
                                <ListItemText primary={file.name}  onClick={() => handleItemClick(file)}/>
                                {!file.dir &&
                                <>

                                {file.share &&
                                <IconButton edge="end" onClick={() => handleShare(file)}>
                                    <ShareIcon />
                                </IconButton>}

                                <IconButton edge="end" onClick={() => handleEdit(file)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton edge="end" onClick={() => handleDelete(file)}>
                                    <DeleteIcon />
                                </IconButton>
                                </>}
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={12}>
                    <FileActions path={path} oldName={selectedFile} newFolder={newFolder} onPathChange={handleResult} />
                </Grid>
                {isUpload && <>
                <Grid item xs={12}>
                    <FileUpload path={path} handleResult={handleResult} />
                </Grid></>}
            </Grid>
        </Container>
    );
};

export default FileExplorer;

