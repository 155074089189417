import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { getAllUsers, createUser, updateUser, deleteUser } from '../../../../api_sequelize/api_users';
import CrudTable from './CrudTable';
import AdminDialogCrud from './AdminDialogCrud';
import UserSlilsCRUD from './UserSlilsCRUD';

const userColumns = [
    { field: 'user_id', headerName: 'id', type: 'id', default: '' },
    { field: 'avatar_url', headerName: 'Avatar', type: 'img', default: '' },

    { field: 'first_name', headerName: 'First Name (eng)', type: 'lineedit', default: '' },
    { field: 'last_name', headerName: 'Last Name (eng)', type: 'lineedit', default: '' },
    { field: 'first_name_ukr', headerName: 'First Name (ukr)', type: 'lineedit', default: '' },
    { field: 'last_name_ukr', headerName: 'Last Name (ukr)', type: 'lineedit', default: '' },

    { field: 'club_position', headerName: 'Сlub position (eng)', type: 'multilineedit', default: '' },
    { field: 'other_positions', headerName: 'Roles description (eng)', type: 'multilineedit', default: '' },
    { field: 'club_position_ukr', headerName: 'Сlub position (ukr)', type: 'multilineedit', default: '' },
    { field: 'other_positions_ukr', headerName: 'Roles description (ukr)', type: 'multilineedit', default: '' },
    { field: 'email', headerName: 'Email', type: 'lineedit', default: '' },
    { field: 'phone', headerName: 'Phone', type: 'lineedit', default: '' },
    { field: 'additional_info', headerName: 'Public social ', type: 'lineedit', default: '', tablehided: 'true'  },
    //{ field: 'professional_directions', headerName: '', type: 'multilineedit', default: '' },
    //{ field: 'tags', headerName: 'Tags', type: 'lineedit', default: '' },
];

const generateDefaultObject = (columns) => {
    return columns.reduce((acc, column) => {
        acc[column.field] = column.default;
        return acc;
    }, {});
};

const CRUD_ClubUser = ({tags, adminUserDefaultFields}) => {
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(generateDefaultObject(userColumns));
    const [rowClicked, setRowClicked] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [openSkils, setOpenSkils] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        const fetchData = async () => {
            try {
                const fetchedUsers = await getAllUsers();
                const filteredData = tags ? fetchedUsers.filter(item => item.tags === tags) : fetchedUsers;

                setUsers(filteredData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }

    const handleEditUser = (user = generateDefaultObject(userColumns)) => {
        setCurrentUser(user);
        setIsEditMode(!!user.user_id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // const handleSubmit = async () => {
    //     console.log('=== handleSubmit...');

    //     const currentUserNew = { ...currentUser };
    //     adminUserDefaultFields.forEach(({ field, defValue }) => {
    //         currentUserNew[field] = defValue;
    //     });
    //     console.log('adminUserDefaultFields user='+JSON.stringify(currentUserNew))
    const handleSubmit = async () => {
        console.log('=== handleSubmit...');
        const currentUser_new = { ...currentUser };
        adminUserDefaultFields.forEach(({ field, defValue }) => {
            currentUser_new[field] = defValue;
        });
        console.log('adminUserDefaultFields user='+JSON.stringify(currentUser_new))
        // let currentUser_new = currentUser;
        // if (tags==='rec_ukraine') { 
        //     currentUser_new.tags = 'rec_ukraine';
        //     currentUser_new.club_type = 'ReC';
        //     currentUser_new.club_name = 'Ukraine';
        //     //currentUser_new.club_name_ukr = 'Україна';
            
        //     console.log('handleSubmit default tags = '+tags);
        // }
        try {
            if (isEditMode) {
                await updateUser(currentUser_new.user_id, currentUser_new);
            } else {
                await createUser(currentUser_new);
            }
            handleClose();
            loadData();
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = async (userId) => {
        console.log('=== handleDelete... userId='+userId);
        const confirmDelete = window.confirm('Чи дійсно хочете видалити картку ротарійця?');
        if (!confirmDelete) return;
    
        try {
            await deleteUser(userId);
            loadData();
        } catch (error) {
            console.error(error);
        }
    };

    const onRowClicked = (row) => {
        setRowClicked(row)
    }

    const handleEditUserSlils = () => {
        setOpenSkils(true);
    }

    const handleSkilsClose = () => {
        setOpenSkils(false);
    };

    return (
        <div>
            <Box
                display="flex"
                flexDirection="row"
                pt="8px"
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000, // для впевненості, що елемент буде зверху інших
                    backgroundColor: '#fff', // фон щоб перекрити підлеглі елементи під час прокручування
                    borderBottom: '1px solid #ddd', // опціонально, щоб було видно розмежування
                    padding: '8px', // опціонально, щоб було більше простору навколо кнопок
                }}
            >
                <Button variant="contained" color="primary" onClick={() => handleEditUser()}>
                    Add User
                </Button>

                <Box width="100%"></Box>

                {rowClicked && (
                    <Button variant="contained" color="primary" onClick={() => handleEditUserSlils()}>
                        Edit Skills
                    </Button>
                )}
            </Box>

            <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' }}>
                <CrudTable data={users} columns={userColumns} onEdit={handleEditUser} onDelete={handleDelete} onRowClicked={onRowClicked} />

                <AdminDialogCrud
                    open={open}
                    onClose={handleClose}
                    onSubmit={handleSubmit}
                    isEditMode={isEditMode}
                    columns={userColumns}
                    currentItem={currentUser}
                    setCurrentItem={setCurrentUser}
                />

                {openSkils &&
                <>
                    userId={users && rowClicked && users[rowClicked] && users[rowClicked].user_id}
                    <UserSlilsCRUD userId={users[rowClicked].user_id} handleSkilsClose={handleSkilsClose} />
                </>
                }
            </Box>
        </div>
    );
}

export default CRUD_ClubUser;
